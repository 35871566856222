import { Button, Modal } from 'flowbite-react'
import eyeIcon from '../../assets/svgs/eye_icon.svg'
import crossIcon from '../../assets/svgs/cross.svg'
import blueEyeIcon from '../../assets/svgs/blue_eye.svg'
import redEyeIcon from '../../assets/svgs/red_eye.svg'
import { revealProfile } from '../../services/revealProfile'
import RevealCandidateProfile from './modals/RevealCandidateProfile'
import useToastMessage from '../../hooks/useToastMessage'
import { useSelector } from 'react-redux'
import { selectCompany } from '../../redux/currentUserSlice'
import { GET_IN_TOUCH_EMAIL } from '../../config/constants'

export function RevealModal({
  openModal,
  setOpenModal,
  userId,
  creditScore,
  revealedCandidates,
  candidate,
  alreadyRevealed,
  searchHistory,
  fetchAndUpdateData,
  setModalOpenReveal,
  modalOpenReveal,
  fetchCurrentUser,
  isCandidateRevealed,
  historyResults,
  setHistoryResults,
  fetchOnCandidateSave
}) {
  const toastMessage = useToastMessage()
  const company = useSelector(selectCompany)
  const revealUserProfile = async payload => {
    try {
      const response = await revealProfile(payload)
      if (response.status === 200) {
        toastMessage(response.data.message, 'success')
      }
    } catch (error) {
      toastMessage('Error Revealing Profile', 'error')
    }
  }

  const redeemCredit = async () => {
    const queryParams = new URLSearchParams()
    queryParams.append('user_id', userId)
    const payload = {
      user_id: userId,
      company_id: company.id,
      search_history_id: searchHistory?.id,
      category: 1,
    }
    await revealUserProfile(payload)
    fetchAndUpdateData()
    setOpenModal(false)
    setModalOpenReveal(true)
  }

  return (
    <>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <div className="relative flex flex-col items-center justify-center pt-10 pb-2">
          <button
            className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
            onClick={() => setOpenModal(false)}
          >
            <img src={crossIcon} alt="cross" className="cursor-pointer p-1" />
          </button>
          <h2 className="text-xl font-semibold text-[#111928] text-center">
            View Full Profile?
          </h2>
          <span>
            <p className="text-base font-normal leading-relaxed text-[#6B7280] text-center">
              To view candidate’s full profile, you have to use Reveal credits
            </p>
          </span>
        </div>
        <Modal.Body>
          <div className="flex flex-col items-center space-y-6">
            <p className="flex items-center md:text-base text-xs leading-relaxed text-[#4B5563] bg-[#EBF5FF] rounded-md md:pl-10 pl-2 py-2 w-full">
              Candidate profiles will stay under{' '}
              <span className="flex flex-row">
                <img
                  src={eyeIcon}
                  className="md:px-2 px-1 md:mr-0 mr-4"
                  alt="eye"
                />
                <p className="text-[#111928] md:mr-0 mr-6">Revealed Profiles</p>
              </span>
            </p>
            <p className="flex items-center md:text-base text-xs leading-relaxed text-[#4B5563] bg-[#EBF5FF] rounded-md md:pl-10 pl-2 py-2 w-full">
              Your subscription has to stay active to access{' '}
              <span className="flex flex-row">
                <img
                  src={eyeIcon}
                  className="md:px-2 px-1 md:mr-0 mr-4"
                  alt="eye"
                />
                <p className="text-[#111928] md:mr-0 mr-7">Revealed Profiles</p>
              </span>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="w-auto">
          <div className="flex flex-row justify-between w-full">
            <p>
              <span className="flex flex-row text-blue-600">
                {creditScore > 0 ? (
                  <img src={blueEyeIcon} className="px-2" alt="eye" />
                ) : (
                  <img src={redEyeIcon} className="px-2" alt="eye" />
                )}
                {creditScore > 0 ? (
                  <p className="text-[#1C64F2] px-2">
                    {creditScore} reveal credit(s) left
                  </p>
                ) : (
                  <p className="text-red-500 md:text-base text-xs px-2">
                    {creditScore} reveal credit(s) left
                  </p>
                )}
              </span>
            </p>
            <Button
              color="blue"
              onClick={() => {
                creditScore > 0
                  ? redeemCredit()
                  : window.open(`mailto:${GET_IN_TOUCH_EMAIL}`)
                setOpenModal(false)
              }}
            >
              {creditScore > 0 ? <p>Redeem Credit</p> : <p>Get in Touch</p>}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {modalOpenReveal && (
        <RevealCandidateProfile
          openModal={modalOpenReveal}
          setOpenModal={setModalOpenReveal}
          candidate={candidate}
          fetchAndUpdateData={fetchAndUpdateData}
          fetchCurrentUser={fetchCurrentUser}
          isCandidateRevealed={isCandidateRevealed}
          historyResults={historyResults}
          setHistoryResults={setHistoryResults}
          fetchOnCandidateSave={fetchOnCandidateSave}
        />
      )}
    </>
  )
}
