import React, { useState,useRef } from 'react'
import { useDispatch } from 'react-redux'
import { ReactComponent as CrossedIcon } from '../../assets/svgs/passed.svg'
import UploadFileIcon from '../generics/Icons/UploadFileIcon'
import BlueworkIcon from 'components/generics/Icons/BlueworkIcon'
import UndoOutlineArrowIcon from 'components/generics/Icons/UndoOutlineArrowIcon'
import { TruncatedText } from 'utilis/helpers'
import passedBag from '../../assets/svgs/passed_bag.svg'
import { ReactComponent as Crossicon } from '../../assets/svgs/x-circle.svg'
import { Button } from 'flowbite-react'
import { formatCandidateData, getBodyForAttachment } from '../../utilis/helpers'
import { VALID_PROFILE_STATUSES } from 'config/constants'
import { setRevealedCandidates } from '../../redux/talentSearchSlice'
import {
  startConversation,
} from '../../services/messagingService'
import {getRevealedCandidatesForCompany} from '../../services/userService'
const DecisionButtons = ({
  currentUser,
  selectedChat,
  handleHireCandidate,
  handleSetOffered,
  handleSetPassed,
  profileStatus,
  offerLetter,
  handleChangeDecision,
  handleRemoveOfferLetter,
}) => {
  const dispatch = useDispatch()
  const fileInputRef = useRef(null)
  const [showOfferUpload, setShowOfferUpload] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  const handleMakeOffer = () => {
    setShowOfferUpload(prevState => !prevState)
    setSelectedFile(null)
  }

  const handleSendOffer = async () => {
    await handleSetOffered(selectedFile)
    handleSendMessage()
    resetOfferUploadState()
  }
  const getRevealedCandidates = async () => {
    const response = await getRevealedCandidatesForCompany()
    const { data, status } = response
    if (status === 200) {
      const formattedDataArray = formatCandidateData(data)
      dispatch(setRevealedCandidates(formattedDataArray || []))
    }
  }

  const handleSendMessage = async () => {
    if (selectedFile) {
      const formData = new FormData()
      formData.append(
        'recipient_id',
        selectedChat?.user?.id || selectedChat?.users?.[0]?.user_id,
      )
      formData.append('subject', 'Chat Message')
      if (selectedFile) {
        formData.append(`attachment`, selectedFile)
        const receiverName = `${selectedChat?.user?.first_name ?? ''} ${selectedChat?.user?.last_name ?? ''}`?.trim();
        const companyName = selectedChat?.company?.name
        let body ='';
        if(currentUser.role === 'company_admin'){
           body = getBodyForAttachment(companyName, receiverName,currentUser, '')
        }
        else{
          body = getBodyForAttachment(currentUser?.first_name, `${selectedChat?.user?.first_name ?? ''} ${selectedChat?.user?.last_name ?? ''}`?.trim(),currentUser, '')
        }
        formData.append('body', body)
      }
      try {
        const response = await startConversation(formData)
        if (response.status === 201) {
          setSelectedFile(null)
          currentUser?.role === 'company_admin' && getRevealedCandidates()
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
        }
      } catch (error) {
        console.error('Error sending message:', error)
      }
    }
  }

  

  const handleRejectCandidate = async () => {
    await handleSetPassed()
    resetOfferUploadState()
  }

  const handleFileChange = event => {
    setSelectedFile(event.target.files[0])
  }

  const resetOfferUploadState = () => {
    setShowOfferUpload(false)
    setSelectedFile(null)
  }

  const renderOfferLetters = () => {
    if (
      !offerLetter ||
      (Array.isArray(offerLetter) && offerLetter.length === 0)
    ) {
      return <p className="text-sm text-gray-900">No offer letter uploaded</p>
    }

    const letters = Array.isArray(offerLetter) ? offerLetter : [offerLetter]
    const mostRecentLetterId = Math.max(...letters.map(letter => letter.id))

    return (
      <div
        className={`flex flex-col ${letters.length > 1 ? 'max-h-10 overflow-y-auto' : ''} w-full`}
      >
        {letters.map(letter => (
          <div
            key={letter.id}
            className="flex items-center justify-between mb-2 last:mb-0"
          >
            <div className="flex items-center mt-2">
              <div className="w-6 h-6 border-4 border-blue-500 rounded-full mr-2"></div>
              <span className="text-sm text-gray-500">
                {letter.filename}
                {selectedFile &&
                  selectedFile.name === letter.filename &&
                  letter.id === mostRecentLetterId && (
                    <span className="text-xs text-green-500 ml-2">
                      Upload complete
                    </span>
                  )}
              </span>
            </div>
            <div className='mr-2 mt-1'>
            <button
              onClick={() => handleRemoveOfferLetter(letter.id)}
              className="text-gray-500 hover:text-red-500"
            >
              <Crossicon />
            </button>
            </div>
          </div>
        ))}
      </div>
    )
  }

  const ActionButton = ({ onClick, text, icon, className }) => (
    <button
      onClick={onClick}
      className={`flex-grow px-5 flex items-center justify-center text-sm font-medium rounded border focus:outline-none h-10 ${className}`}
    >
      {icon && <span className="mr-2">{icon}</span>}
      <span className="text-14 font-medium leading-5">{text}</span>
    </button>
  )

  const StatusBadge = ({ text, icon, color }) => (
    <span
      className={`flex items-center justify-center px-3 py-1.5 bg-gray-100 border border-gray-200 text-${color} text-sm rounded-lg w-40`}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {text}
    </span>
  )

  return (
    <div>
      {!profileStatus && (
        <>
          <div className="font-bold text-14 text-gray-800 self-start mt-4 mb-3">
            What's your decision?
          </div>
          <div className="flex gap-2 w-full">
            <ActionButton
              onClick={handleMakeOffer}
              text="Make an Offer"
              icon={
                <BlueworkIcon color={showOfferUpload ? 'white' : '#1C64F2'} />
              }
              className={`w-1/2 ${showOfferUpload ? 'bg-primary-700 text-white border-primary-700' : 'text-primary-700 border-primary-700'}`}
            />
            <ActionButton
              onClick={handleRejectCandidate}
              text="Reject Candidate"
              icon={<CrossedIcon />}
              className="w-1/2 text-red-700 border-red-700"
            />
          </div>

          {showOfferUpload && (
            <div className="pt-4 pb-2 rounded-lg">
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm font-medium">Upload Offer Letter</span>
                <button
                  onClick={handleMakeOffer}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              <div className="flex items-center">
                <label className="flex-grow flex">
                  <span className="px-3 py-2 text-sm bg-gray-800 rounded-l-md border border-gray-300 text-white flex items-center">
                    Choose file
                  </span>
                  <span className="flex-grow px-3 py-2 text-sm bg-white border-t border-b border-r border-gray-300">
                    {selectedFile ? (
                      <span className="flex">
                        <TruncatedText
                          text={selectedFile.name.split('.')[0]}
                          limit={30}
                        />
                        .{selectedFile.name.split('.').pop()}
                      </span>
                    ) : (
                      'No file chosen'
                    )}
                  </span>
                  <input
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                    accept=".pdf,.doc,.docx"
                  />
                </label>
                <button
                  className={`flex items-center ml-2 px-4 py-2 text-sm text-white rounded-md ${
                    selectedFile
                      ? 'bg-blue-600 hover:bg-blue-700 cursor-pointer'
                      : 'bg-blue-400 cursor-not-allowed'
                  }`}
                  disabled={!selectedFile}
                  onClick={handleSendOffer}
                >
                  <UploadFileIcon color="white" width={12} height={12} />
                  <span className="ml-2">Send Offer</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {VALID_PROFILE_STATUSES.includes(profileStatus) && (
        <div className="mt-4">
          <div className="flex items-center justify-between mb-4">
            <StatusBadge
              text={
                profileStatus === 'offered'
                  ? 'Offer Made'
                  : profileStatus === 'hired'
                    ? 'Hired'
                    : 'Rejected'
              }
              icon={
                profileStatus === 'offered' || profileStatus === 'hired' ? (
                  <BlueworkIcon color="#0E9F6E" size={16} />
                ) : (
                  <img src={passedBag} alt="Passed" width={16} height={16} />
                )
              }
              color={profileStatus === 'passed' ? 'red-600' : 'green-600'}
            />
            <button
              className="text-gray-500 text-sm flex items-center"
              onClick={handleChangeDecision}
            >
              <UndoOutlineArrowIcon className="mr-2" width={18} height={16} />
              Change decision
            </button>
          </div>
          {profileStatus !== 'passed' && (
            <div className="mb-4">
              <p className="text-sm font-medium mb-2">Uploaded Documents</p>
              {renderOfferLetters()}
            </div>
          )}
          {profileStatus === 'offered' && (
            <>
              <p className="text-sm font-medium mb-2">
                Did the candidate accept the offer?
              </p>
              <div className="flex gap-2 w-full">
                <Button
                  onClick={handleHireCandidate}
                  className="w-1/2 text-blue-700 border-blue-700"
                >
                  <BlueworkIcon className="mr-2 w-4 h-4 mt-1" />
                  Yes, hired
                </Button>

                <Button
                  onClick={handleRejectCandidate}
                  className="w-1/2 text-red-700 border-red-700"
                >
                  <CrossedIcon className="mr-2 mt-1" />
                  No, rejected
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default DecisionButtons
