import React from 'react'

const CandidateTests = ({qualiTests, displayedTests, handleViewAll, showAll}) => {
  return (
    <div className="mt-6">
      <div className="flex justify-between items-center">
        <p className="text-gray-900 text-14 font-[500]">
          Quali Test ({qualiTests?.length})
        </p>
        <button
          onClick={handleViewAll}
          className="text-[#1A56DB] text-12 font-[500]"
        >
          {showAll ? 'Show Less' : 'View All'}
        </button>
      </div>

      {displayedTests.length > 0 ? (
        <ul
          className="list-disc list-inside mt-4 pl-4 overflow-y-auto overflow-x-hidden max-h-[65px]"
        >
          {displayedTests?.map((test, index) => (
            <li key={index} className="text-gray-600 text-14 font-[500]">
              {test?.title}{' '}
              <span className="text-[#1A56DB] text-14 font-[500]">
                [{test?.test_marks ? `${test.test_marks}%` : 'In Progress'}]
              </span>
            </li>
          ))}
        </ul>
      ) : (
        <div className="flex justify-center items-center py-10 mb-10">
          <span className="text-gray-400 text-14 font-[500]">
            No Quali Test taken, yet.
          </span>
        </div>
      )}
    </div>
  )
}

export default CandidateTests
