import { useState } from 'react'
import { Modal, Button } from 'flowbite-react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import useToastMessage from '../../hooks/useToastMessage'
import { useNavigate } from 'react-router-dom'
import { logoutUser } from '../../lib/api'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/currentUserSlice'
import HoverDropdown from '../Helper/HoverDropdown'
import { industryDropdownmenuItems } from '../../config/constants'

function Navbar({ selectedItem }) {
  const [activeItem, setActiveItem] = useState(selectedItem)
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const [logoutModalOpen, setLogoutModalOpen] = useState(false)
  const dispatch = useDispatch()
  const toastMessage = useToastMessage()
  const loggedIn = useSelector(selectCurrentUser)

  const handleClick = item => {
    const active = item === activeItem ? null : item
    setActiveItem(active)
  }

  const isActive = item => {
    return activeItem === item
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }
  const handleLogoutClick = () => {
    setLogoutModalOpen(true)
  }

  const handleLogoutConfirmed = () => {
    const isSuperAdmin = loggedIn?.currentUser?.role === 'super_admin'
    logoutUser(dispatch, navigate, toastMessage, isSuperAdmin)
    setLogoutModalOpen(false)
  }

  const navLinkClasses = 'px-4 py-2'
  const activeNavLinkClasses =
    'border border-primary-500 bg-primary-500 text-white rounded-lg'

  return (
    <div className="flex w-full flex-col bg-primary-600 text-white">
      <div className="mt-1 mb-1 ml-8 flex items-center">
        <div className="flex flex-grow">
          <NavLink className="mr-4 px-4 py-2">Quali</NavLink>
          <NavLink
            to="/admin/companies"
            className={`mr-4 ${navLinkClasses} ${isActive('companies') ? activeNavLinkClasses : ''}`}
            onClick={() => handleClick('companies')}
          >
            Companies
          </NavLink>
          <HoverDropdown
            toggleDropdown={toggleDropdown}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            menuItems={industryDropdownmenuItems}
            title="Industry"
          />
          <NavLink
            to="/admin/candidates"
            className={`mr-4 ${navLinkClasses} ${isActive('candidates') ? activeNavLinkClasses : ''}`}
            onClick={() => handleClick('candidates')}
          >
            Candidates
          </NavLink>
          <NavLink
            to="/admin/revealed-candidates"
            className={`${navLinkClasses} ${isActive('revealed-candidates') ? activeNavLinkClasses : ''}`}
            onClick={() => handleClick('revealed-candidates')}
          >
            Revealed Candidates
          </NavLink>
          <NavLink
            to="/admin/search-result-histories"
            className={`${navLinkClasses} ${isActive('search-result-histories') ? activeNavLinkClasses : ''}`}
            onClick={() => handleClick('search-result-histories')}
          >
            Search Result Histories
          </NavLink>
          <NavLink
            to="/admin/tests"
            className={`${navLinkClasses} ${isActive('tests') ? activeNavLinkClasses : ''}`}
            onClick={() => handleClick('tests')}
          >
            Tests
          </NavLink>
          <NavLink
            to="/admin/manage-test"
            className={`px-4 py-2 ${isActive('manage-test') ? 'border border-primary-500 bg-primary-500 text-white rounded-lg' : ''}`}
            onClick={() => handleClick('manage-test')}
          >
            Manage Test
          </NavLink>
          <NavLink
            to="/admin/contacted-candidates"
            className={`px-4 py-2 ${isActive('contacted-candidates') ? 'border border-primary-500 bg-primary-500 text-white rounded-lg' : ''}`}
            onClick={() => handleClick('contacted-candidates')}
          >
            Contacted Candidates
          </NavLink>
          <NavLink
            to="/admin/account-deletion-requests"
            className={`px-4 py-2 ${isActive('deletion-requests') ? 'border border-primary-500 bg-primary-500 text-white rounded-lg' : ''}`}
            onClick={() => handleClick('deletion-requests')}
          >
            Deletion Requests
          </NavLink>
        </div>
        <div className="ml-auto mr-9">
          <NavLink className="px-4 py-2" onClick={handleLogoutClick}>
            Logout
          </NavLink>
        </div>
      </div>
      <Modal
        show={logoutModalOpen}
        size="md"
        onClose={() => setLogoutModalOpen(false)}
        popup
        className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-50"
      >
        <div className="p-8 bg-white rounded shadow-md">
          <p className="text-2xl text-center font-bold mb-4">
            Log Out Confirmation
          </p>
          <p className="text-lg text-center mb-8">
            Are you sure you want to log out of your account?
          </p>
          <div className="flex  justify-end">
            <Button
              onClick={() => setLogoutModalOpen(false)}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 mr-2 px-4 rounded"
            >
              Cancel
            </Button>
            <Button
              onClick={handleLogoutConfirmed}
              className="bg-red-500 hover:bg-red-700 text-white mr-8 font-bold py-2 px-4 mr-14 rounded "
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Navbar
