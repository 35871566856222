import 'rc-slider/assets/index.css'
import React, { useState, useEffect } from 'react'
import {
  filterCandidates,
  getInitialFilters,
  deleteFilter,
} from '../../services/candidateService'
import useToastMessage from './../../hooks/useToastMessage'
import {
  setTalentSearchData,
  selectTalentSearchData,
} from '../../redux/talentSearchSlice'
import { useDispatch, useSelector } from 'react-redux'
import FilterContent from 'components/Filters/FilterContent'
import { Modal } from 'flowbite-react'
import 'assets/css/search-results.css'
import { replaceUnderscoreWithSpace } from 'utilis/helpers'

export function FilterPanel({
  openFilterModal,
  setOpenFilterModal,
  historyResults,
  setHistoryResults,
  searchHistory,
  setFilterResult,
  setCompanySearchHistory,
  resetPageOnFilterReset,
  fetchAndUpdateData,
}) {
  const toastMessage = useToastMessage()
  const dispatch = useDispatch()
  const searchData = useSelector(selectTalentSearchData)

  const initialFilters = {
    experienceRange: [0, 10],
    salaryRange: '',
    languagePreference: [],
    certificationRequired: false,
    accommodationOffered: null,
    visaRequired: null,
    transportationOffered: null,
    additionalBenefitsOffered: null,
    annualFlightOffered: null,
    citizenship: '',
    residence_country: ''
  }

  const [filters, setFilters] = useState(initialFilters)

  useEffect(() => {
    fetchInitialFilters()
  }, [])

  const fetchInitialFilters = async () => {
    try {
      const response = await getInitialFilters(searchHistory?.id)
      if (response.status === 200 && response.data.search_filters) {
        setFilters(prevFilters => ({
          ...prevFilters,
          ...response.data.search_filters,
        }))
      }
    } catch (error) {
      toastMessage(
        error.response?.data?.error || 'Error fetching initial filters',
        'error',
      )
      console.error('Error fetching initial filters:', error)
    }
  }

  const handleExperienceChange = values => {
    setFilters({ ...filters, experienceRange: values })
  }

  const handleNationalityChange = value => {
    setFilters({ ...filters, citizenship: value })
  }

  const handleResidenceCountryChange = value => {
    setFilters({ ...filters, residence_country: value })
  }

  const handleSalaryChange = e => {
    setFilters({ ...filters, salaryRange: e.target.value })
  }

  const handleCheckboxChange = (filterName, value) => {
    setFilters({
      ...filters,
      [filterName]: value,
    })
  }

  const handleLanguageChange = value => {
    const updatedLanguagePreferences = filters.languagePreference.includes(
      value,
    )
      ? filters.languagePreference.filter(lang => lang !== value)
      : [...filters.languagePreference, value]

    setFilters({
      ...filters,
      languagePreference: updatedLanguagePreferences,
    })
  }

  const deleteSearchFilter = async () => {
    try {
      await deleteFilter(searchHistory?.id)
      const queryParams = new URLSearchParams()
      const candidatesList = historyResults.map(candidate => candidate.user.id)
      if (candidatesList.length !== 0) {
        queryParams.append('candidates', candidatesList)
      }
      queryParams.append('search_id', searchHistory?.id)
      fetchAndUpdateData?.(true, true)
      resetPageOnFilterReset?.()
      toastMessage('Candidates Filtered', 'success')
    } catch (error) {
      toastMessage(
        error.response?.data?.error || 'Error fetching initial filters',
        'error',
      )
      console.error('Error fetching initial filters:', error)
    }
  }

  const handleReset = () => {
    setFilters(initialFilters)
    deleteSearchFilter()
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const queryParams = new URLSearchParams()
      const candidatesList = historyResults.map(candidate => candidate.user.id)
      if (candidatesList.length !== 0) {
        queryParams.append('candidates', candidatesList)
      }
      delete filters?.filtered_candidates
      Object.keys(filters).forEach(key => {
        const value = filters[key]
        if (value !== null && value !== '' && value.length !== 0) {
          queryParams.append(key, value)
        }
      })
      queryParams.append('search_id', searchHistory?.id)
      queryParams.append('industry_id', searchHistory?.attributes?.industry?.id)
      const response = await filterCandidates(queryParams)
      if (response.status === 200) {
        resetPageOnFilterReset?.()
        setHistoryResults(response.data.res?.search_results || [])
        setCompanySearchHistory(response.data.res?.search_history)
        dispatch(setTalentSearchData(response.data.res || []))
        setFilterResult(response.data.search_filter)
        toastMessage(response.data.message, 'success')
        setOpenFilterModal?.(false)
      }
    } catch (error) {
      toastMessage(error.response?.data?.error, 'error')
      console.error('Error in Filtering Candidates :', error)
    }
  }

  return (
    <>
      {openFilterModal ? (
        <Modal
          show={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
          className="custom-modal"
        >
          <div className="flex flex-col gap-4 p-4 border border-gray-300 h-full w-full bg-white shadow-md rounded-lg mb-5">
            <FilterContent
              filters={filters}
              handleExperienceChange={handleExperienceChange}
              handleSalaryChange={handleSalaryChange}
              handleLanguageChange={handleLanguageChange}
              handleCheckboxChange={handleCheckboxChange}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
              handleNationalityChange={handleNationalityChange}
              openFilterModal={openFilterModal}
              setOpenFilterModal={setOpenFilterModal}
              handleResidenceCountryChange={handleResidenceCountryChange}
            />
          </div>
        </Modal>
      ) : (
        <div className="flex flex-col gap-4 p-4 border border-gray-300 h-full w-full md:w-1/5 bg-white shadow-md rounded-lg mx-auto md:mx-4 laptop-lg:block hidden">
          <FilterContent
            filters={filters}
            handleExperienceChange={handleExperienceChange}
            handleSalaryChange={handleSalaryChange}
            handleLanguageChange={handleLanguageChange}
            handleCheckboxChange={handleCheckboxChange}
            handleSubmit={handleSubmit}
            handleReset={handleReset}
            handleNationalityChange={handleNationalityChange}
            openFilterModal={openFilterModal}
            setOpenFilterModal={setOpenFilterModal}
            handleResidenceCountryChange={handleResidenceCountryChange}
          />
        </div>
      )}
    </>
  )
}
