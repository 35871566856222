import React from 'react'
const TestCardAdminInfo = ({ test, isOverview }) => (
  <div className="flex justify-between mt-2">
    <div className="text-right mt-2 text-sm">
      {isOverview ? (
        <>
          <span className="font-bold">{test.completed_test_count}</span> out of
          <span className="font-bold"> {test.test_sent_count}</span> sent
        </>
      ) : (
        <>
          <span className="font-bold">{test.tests_sent_count}</span> Candidates invited
        </>
      )}
    </div>
  </div>
);

export default TestCardAdminInfo;
