import React from 'react'
const TestCardStatus = ({ test }) => {
  const testStatus = test.status.toLowerCase()
  const isCompleted = testStatus === 'completed'
  const isSent = testStatus === 'sent'

  return (
    <div className="flex flex-col items-start gap-2 sm:flex-row sm:gap-0 sm:justify-end mt-2 sm:items-center">
      <div
        className={`py-1 px-4 rounded-md ${isCompleted
          ? 'bg-green-100 text-green-800'
          : 'bg-yellow-100 text-yellow-800'
          }`}
      >
        {isSent ? 'Pending' : isCompleted ? 'Completed' : 'In Progress'}
      </div>
    </div>
  )
}

export default TestCardStatus
