import React from 'react'
import eyeImage from 'assets/svgs/eye_bgcolored.svg'
import searchImage from 'assets/svgs/search_bgcolored.svg'
import userImage from 'assets/svgs/user-outline_bgcolore.svg'
import textImage from 'assets/svgs/Icon Shapes.svg'
import pendingIcon from 'assets/svgs/pendingTestIcon.svg'
import { Link, useLocation } from 'react-router-dom'
import { roundPercentage } from 'utilis/helpers'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../redux/currentUserSlice'

const TestOverview = ({ data, Testname }) => {
  const { currentUser } = useSelector(selectCurrentUser)
  const location = useLocation()
  const isOverviewPage = location.pathname === '/overview'
  const isCandidateList = location.pathname === '/candidate-list-of-test'
  const isAllTestsList = location.pathname === '/all-quali-tests'
  const isCandidate = currentUser.role === 'candidate'

  const overviewStats = [
    {
      to: '/search',
      imgSrc: userImage,
      alt: 'User Image',
      value: data?.unique_candidates_invited,
      label: 'Candidates Invited',
    },
    {
      to: '/search',
      imgSrc: textImage,
      alt: 'Text Image',
      value: data?.assessment_submitted,
      label: 'Assessment Submitted',
    },
    {
      to: '#',
      imgSrc: eyeImage,
      alt: 'Eye Image',
      value: data?.candidates_invited > 0 ? roundPercentage(
        (data?.assessment_submitted / data?.candidates_invited) * 100,
      ) : "0%",
      label: 'Completion Rate',
    },
  ]

  const defaultStats = [
    {
      to: '#',
      imgSrc: userImage,
      alt: 'Search Image',
      value: data?.candidates_invited,
      label: 'Candidates Invited',
    },
    {
      to: '#',
      imgSrc: textImage,
      alt: 'Text Image',
      value: data?.test_sent,
      label: 'Quali Test Sent',
    },
  ]

  const candidateOverviewStats = [
    {
      imgSrc: pendingIcon,
      alt: 'Pending Test Icon',
      value: currentUser?.pending_test_count || 0,
      label: 'Pending Tests',
    },
    {
      imgSrc: textImage,
      alt: 'Completed Test Icon',
      value: currentUser?.attempted_tests?.length || 0,
      label: 'Completed Tests',
    },
  ]

  const stats = isCandidate ? candidateOverviewStats : (isCandidateList ? overviewStats : defaultStats)

  return (
    <div className={`mx-2 sm:ml-8 mt-8 sm:mr-5`}>
      <h1 className={` ${!isCandidate && 'mb-1'} text-20 md:text-left text-center text-black font-semibold`}>
        {isCandidate ? "Test Overview" :(isCandidateList ? `${Testname && Testname +' - '} Overview` :"Quali Test Assessment")}
      </h1>
      {isCandidate && (
        <>
          <span className='text-gray-600 text-14 font-normal underline'>
            Multiple companies may ask you to do the same test.
          </span>
          <span className='text-gray-500 text-14 font-normal'>
            Increase your chance to be hired by completing these tests.
          </span>
        </>
      )}
      <div className={`flex flex-col lg:flex-row gap-8 py-4 mb-2`}>
        {stats.map((stat, index) => (
          <div key={index} className="flex-1 bg-white rounded-lg shadow p-4">
            <div className="flex flex-col xl:flex-row items-center">
              <img
                className="mt-1 w-14 h-14"
                src={stat.imgSrc}
                alt={stat.alt}
              />
              <div className="ml-4 text-center xl:text-start">
                <p className="font-bold text-center xl:text-start text-20 mt-1 text-[#111928]">
                  {stat.value || 0 }
                </p>
                <p className="text-16 text-[#6B7280]">{stat.label}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TestOverview
