import React from 'react'
import { Card } from 'flowbite-react'
import { useSelector } from 'react-redux'
import { selectCompany } from '../../redux/currentUserSlice'

const WelcomeCard = ({ selectedCard, setSelectedCard }) => {
  const getClasses = id => {
    return `h-20 font-normal cursor-pointer ${selectedCard === id ? 'text-blue-700 border-blue-700 bg-blue-100 hover:bg-blue-200' : 'text-gray-700 border-[#E5E7EB]'}`
  }

  const companyData = useSelector(selectCompany)

  return (
    <>
      <Card className="w-1/4 h-fit cursor-default">
        <h5 className="text-xl font-semibold tracking-tight text-gray-900">
          Welcome to Quali
        </h5>
        <Card onClick={() => setSelectedCard(0)} className={getClasses(0)}>
          <p>Complete company profile</p>
          <p className="text-sm -mt-3">
            {!companyData.completion_status && <>Not </>}Completed
          </p>
        </Card>
        <Card onClick={() => setSelectedCard(1)} className={getClasses(1)}>
          <p>Select your plan</p>
          <p className="text-sm -mt-3">Not Completed</p>
        </Card>
      </Card>
    </>
  )
}

export default WelcomeCard
