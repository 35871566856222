import React from 'react';

const TextButton = ({
  text,
  onClick,
  size = 'sm',
  color = 'primary',
  disabled = false,
  className = '',
}) => {
  const colorClasses = {
    primary: 'text-blue-500 hover:text-blue-700',
    secondary: 'text-gray-500 hover:text-gray-700',
    success: 'text-green-500 hover:text-green-700',
    danger: 'text-red-500 hover:text-red-700',
  };

  const sizeClasses = {
    sm: 'text-sm py-1 px-2',
    md: 'text-base py-2 px-4',
    lg: 'text-lg py-3 px-6',
  };

  return (
    <button
      onClick={onClick}
      className={className ? className : `${sizeClasses[size]} ${colorClasses[color]}`}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default TextButton;
