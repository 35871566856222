import axiosInstance from './axiosConfig'
import { CandidateFeedEndpoint, CompanyFeedEndpoint } from './constants'

export const getCandidateFeeds = async () => {
  return await axiosInstance.get(CandidateFeedEndpoint.getCandidateFeeds)
}

export const getCompanyFeeds = async () => {
  return await axiosInstance.get(CompanyFeedEndpoint.getCompanyFeeds) 
}



export const deleteFeedForUser = async () => {
  return await axiosInstance.delete(CandidateFeedEndpoint.clearFeedForUser)
   
}
