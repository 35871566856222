import React from 'react'
import eyeImage from 'assets/svgs/eye_bgcolored.svg'
import searchImage from 'assets/svgs/search_bgcolored.svg'
import textImage from 'assets/svgs/Icon Shapes.svg'
import { Link } from 'react-router-dom'

const RecruitmentOverview = ({ data }) => {
  return (
    <div className="mx-2 sm:ml-8 mt-8 sm:mr-5">
      <h1 className="mb-4 text-20 text-black font-semibold">
        Recruitment overview
      </h1>

      <div className="flex flex-col lg:flex-row gap-8 sm:p-4">
        <Link to="/search" className="flex-1 bg-white rounded-lg shadow p-4">
          <div className="flex flex-col xl:flex-row items-center">
            <img className="mt-1 w-14 h-14" src={searchImage} alt="Image 1" />
            <div className="ml-4 text-center xl:text-start">
              <p className="font-bold text-center xl:text-start text-20 mt-1 text-[#111928]">
                {data?.active_searches_count} Searches
              </p>
              <p className="text-16 text-[#6B7280]">Active Search</p>
            </div>
          </div>
        </Link>

        <div className="flex-1 bg-white rounded-lg shadow p-4">
          <div className="flex flex-col xl:flex-row items-center">
            <img className="mt-1 w-14 h-14" src={textImage} alt="Image 2" />
            <div className="ml-4 text-center xl:text-start">
              <p className="font-bold text-20 text-center xl:text-start mt-1 text-[#111928]">
                {data?.complete_tests_count} Tests Completed
              </p>
              <p className="text-16 text-[#6B7280]">Tests Completed</p>
            </div>
          </div>
        </div>

        <Link
          to="/revealed-profiles"
          className="flex-1 bg-white rounded-lg shadow p-4"
        >
          <div className="flex flex-col xl:flex-row items-center">
            <img className="mt-1 w-14 h-14" src={eyeImage} alt="Image 3" />
            <div className="ml-4 text-center xl:text-start">
              <p className="font-bold text-20 text-center xl:text-start mt-1 text-[#111928]">
                {data?.revealed_candidates_count} Revealed
              </p>
              <p className="text-16 text-[#6B7280]">Candidates Revealed</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default RecruitmentOverview
