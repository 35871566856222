const PASSWORD_REGEX =
/^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>~`\-_=+\[\]\\|;:'"/<>\^%]).{8,}$/;
const PHONE_REGEX = /^\+[0-9]{12}$/
const FILE_SIZE = 5 * 1024 * 1024
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

const FACEBOOK_REGEX =
  /(?:http:\/\/|https:\/\/)?(?:www\.)?facebook\.com\/[A-Za-z0-9_.]+/
const TWITTER_REGEX = /^@?(\w){1,15}$/
const INSTAGRAM_REGEX = /^@?(\w){1,30}$/
const NAME_REGEX = /^[A-Za-zÀ-ÿ]+([-' ][A-Za-zÀ-ÿ]+)*$/
const PARAGRAPH_REGEX = /^[A-Za-zÀ-ÿ]+([-' ][A-Za-zÀ-ÿ]+)*([.,]?['-]?[ ]?[A-Za-zÀ-ÿ]*)*$/
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
const COMPANY_WEBSITE_REGEX =
  /^((http|https):\/\/)?(www\.)?(?!.*(http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})*(\/[a-zA-Z0-9-_]+)*(\/)?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-_%]*)?(&[a-zA-Z0-9-_]+=[a-zA-Z0-9-_%]*)*(#[a-zA-Z0-9-_]*)?$/

export {
  PASSWORD_REGEX,
  PHONE_REGEX,
  FILE_SIZE,
  SUPPORTED_FORMATS,
  FACEBOOK_REGEX,
  TWITTER_REGEX,
  INSTAGRAM_REGEX,
  NAME_REGEX,
  EMAIL_REGEX,
  COMPANY_WEBSITE_REGEX,
  PARAGRAPH_REGEX
}
