import React from 'react'
import { Card } from 'flowbite-react'
import { HomeAccordian } from 'components/Accordion/HomeAccordian'
import qualiLogo from '../../assets/logo.png'

const HomePageCard = () => {
    return (
      <div className="flex justify-center items-center h-screen">
        <Card className="w-full max-w-md xs:pt-5 pb-7 px-5">
          {/* Logo */}
          <div className="flex justify-center items-center xs:mb-4">
            <img src={qualiLogo} alt="Logo" className="xs:h-70 xs:w-80 w-20 mr-2" />
          </div>
          {/* Horizontal Line */}
          <hr className="border-gray-300 xs:mb-4" />
          {/* Content */}
          <div>
            <HomeAccordian
            />
          </div>
        </Card>
      </div>
    );
  }
  
  
  

export default HomePageCard
