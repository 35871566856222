import React, { useState, useEffect } from 'react'
import { Modal, Label } from 'flowbite-react'
import { testsInitialState } from '../../../config/constants'
import useToastMessage from '../../../hooks/useToastMessage'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { testValidationSchema } from '../../../validations/TestValidationSchema'
import {
  updateTestListing,
  sendTestToCandidate,
  createTestListing,
} from '../../../services/testService'
import DropdownSearch from 'components/generics/dropdown/DropdownSearch'

const TestEditModal = ({
  isModalOpen,
  closeModal,
  title,
  testsData,
  getTests,
  testsListingForEdit,
}) => {
  const [data, setData] = useState(testsInitialState)
  const [email, setEmail] = useState('')
  const [description, setDescription] = useState('')
  const [jobDetails, setJobDetails] = useState({
    job_title: '',
    job_description: '',
  })
  const [testId, setTestId] = useState(null)
  const toastMessage = useToastMessage()
  let titleToDisplay = title?.replace(/\bListing\b/g, '')

  useEffect(() => {
    if (testsData) {
      setEmail(testsData.candidate_email)
      setDescription(testsData?.description)
      setData({
        id: testsData.id,
        title: testsData.title,
        test_link: testsData.test_link,
        candidate_name: testsData.candidate_name,
        test_id: '',
        job_title: '',
        job_description: '',
      })
    } else {
      setData(testsInitialState)
    }
  }, [testsData])

  const handleChangeTest = selected => {
    setTestId(selected?.id)
    data['test_id'] = selected?.id
  }

  const handleDescription = event => {
    setDescription(event?.target?.value)
  }

  const handleJobDetailsChange = event => {
    const { name, value } = event.target
    setJobDetails(prevDetails => ({ ...prevDetails, [name]: value }))
    data[name] = value
  }

  const handleAssessment = async source => {
    const test = testsListingForEdit.filter(obj => obj.id === Number(testId))
    try {
      if (
        test[0]?.title &&
        test[0]?.test_link &&
        jobDetails?.job_title &&
        jobDetails?.job_description
      ) {
        data['email'] = email
        data['title'] = test[0].title
        data['test_link'] = test[0].test_link
        data['medium'] = source
        data['candidate_id'] = testsData?.candidate_id
        data['company_id'] = testsData?.company_id
        data['company_name'] = testsData?.company_name
        data['job_title'] = jobDetails?.job_title
        data['job_description'] = jobDetails?.job_description
        await sendTestToCandidate(data)
        toastMessage('Email sent successfully!', 'success')
        closeModal()
      }
    } catch (error) {
      console.error('Error in handle test:', error)
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors.join(', ')
        alert(errorMessage)
      }
    }
  }
  const handleSubmit = async (values, { resetForm }) => {
    try {
      values['description'] = description
      if (title === 'Create Test Listing') {
        const response = await createTestListing(values)

        if (response?.status === 201) {
          toastMessage('Test created successfully!', 'success')
          closeModal()
          getTests()
          resetForm()
        }
      } else if (title === 'Edit Test Listing') {
        const response = await updateTestListing(values)

        if (response?.status === 200) {
          toastMessage('Test updated successfully!', 'success')
          closeModal()
          getTests()
          resetForm()
        }
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors.join(', ')
        alert(errorMessage)
      }
    }
  }
  return (
    <Modal
      show={isModalOpen}
      onClose={() => closeModal(false)}
      theme={{
        content: {
          base: 'bg-transparent w-3/4',
          inner: 'bg-transparent',
        },
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={data}
        validationSchema={
          title === 'Edit Test'
            ? testValidationSchema.pick([
                'test_id',
                'job_title',
                'job_description',
              ])
            : testValidationSchema.pick(['test_link', 'title'])
        }
        onSubmit={handleSubmit}
      >
        {({ handleChange, values, touched, errors }) => (
          <Form className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {titleToDisplay}
              </h3>
              <button
                onClick={() => closeModal()}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-6 space-y-6">
              <div className="grid grid-cols-6 gap-6">
                {title === 'Edit Test' && (
                  <div className="ml-20 col-span-6 sm:col-span-5 space-y-3">
                    <div className="col-span-6 sm:col-span-3">
                      <Label className="block text-sm font-medium text-gray-900 dark:text-white">
                        Job Title
                      </Label>
                      <Field
                        onChange={handleJobDetailsChange}
                        type="text"
                        name="job_title"
                        id="job_title"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Test Title"
                        value={jobDetails?.job_title}
                      />
                      <ErrorMessage
                        name="job_title"
                        id="job_title"
                        component="div"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <Label className="block text-sm font-medium text-gray-900 dark:text-white">
                        Job Description
                      </Label>

                      <Field
                        as="textarea"
                        id="job_description"
                        name="job_description"
                        className="block w-full px-4 py-3 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg"
                        placeholder="Enter job description"
                        onChange={handleJobDetailsChange}
                        value={jobDetails?.job_description}
                      />
                      <ErrorMessage
                        id="job_description"
                        name="job_description"
                        component="div"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                    <div className="w-full">
                      <DropdownSearch
                        title="test"
                        options={testsListingForEdit}
                        onSelectTest={handleChangeTest}
                        isSuperAdmin={true}
                      />
                      {touched.test_id && errors.test_id && (
                        <div className="text-red-500 text-xs mt-1">
                          {errors.test_id}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {title !== 'Edit Test' && (
                  <>
                    <div className="col-span-6 sm:col-span-3">
                      <Label className="block text-sm font-medium text-gray-900 dark:text-white">
                        Test Title
                      </Label>
                      <Field
                        onChange={handleChange}
                        type="text"
                        name="title"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Test Title"
                        value={values?.title}
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <Label className="block text-sm font-medium text-gray-900 dark:text-white">
                        Test Link
                      </Label>

                      <Field
                        onChange={handleChange}
                        type="text"
                        name="test_link"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Test Link"
                        value={values?.test_link}
                      />
                      <ErrorMessage
                        name="test_link"
                        component="div"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <Label className="block text-sm font-medium text-gray-900 dark:text-white">
                        Description
                      </Label>

                      <Field
                        onChange={handleDescription}
                        type="text"
                        name="description"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Description"
                        value={description}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="flex items-center p-6 space-x-3 rtl:space-x-reverse border-t border-gray-200 rounded-b dark:border-gray-600">
              {title !== 'Create Test Listing' &&
                title !== 'Edit Test Listing' && (
                  <>
                  <button
                    onClick={() => handleAssessment('email')}
                    className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${errors?.test_id || errors?.job_title || errors?.job_description ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                    disabled={errors?.test_id || errors?.job_title || errors?.job_description}
                  >
                    Send Email
                  </button>
                  <button
                    onClick={() => handleAssessment('whatsapp')}
                    className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${errors?.test_id || errors?.job_title || errors?.job_description ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                    disabled={errors?.test_id || errors?.job_title || errors?.job_description}
                  >
                    Send Whatsapp
                  </button>

                  </>
                )}

              {title !== 'Edit Test' && (
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  {title.includes('Create') ? 'Create' : 'Update'}
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default TestEditModal
