import axiosInstance from './axiosConfig'
import { CertificationsEndPoint, CompanySearchHistory } from './constants'

export const createCertifications = async (userId, body) => {
  const response = await axiosInstance.post(
    CertificationsEndPoint.createCertifications(userId),
    body,
  )
  return response
}

export const fetchCertifications = async userId => {
  const response = await axiosInstance.get(
    CertificationsEndPoint.fetchCertifications(userId),
  )
  return response
}

export const deleteCertification = async (userId, certificationId) => {
  const response = await axiosInstance.delete(
    CertificationsEndPoint.deleteCertification(userId, certificationId),
  )
  return response
}

export const verifyCertification = async (searchId, certificate) => {
  return await axiosInstance.post(
    CompanySearchHistory.verifyCertification(searchId),
    { certificate },
  )
}
