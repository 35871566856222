import React, { useEffect } from 'react'
import { Modal } from 'flowbite-react'
import { Card } from 'flowbite-react'
import Lottie from 'lottie-react'
import animationData from '../../../assets/Animation/Animation - 1719438462788.json'

const EmailSentModal = ({
  successModalOpen,
  setSuccessModalOpen,
  ContactMode,
  message,
}) => {
  useEffect(() => {
    if (successModalOpen) {
      const timer = setTimeout(() => {
        setSuccessModalOpen(false)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [successModalOpen, setSuccessModalOpen])

  return (
    <Modal
      show={successModalOpen}
      size="2xl"
      onClick={() => setSuccessModalOpen(false)}
      onClose={() => setSuccessModalOpen(false)}
      popup
      className="flex justify-center items-center"
    >
      <Card className="text-gray-900 text-center text-2xl font-extrabold leading-tight self-stretch p-6 gap-8 h-[425px]">
        <div className="flex justify-center mb-5 w-46 h-40 overflow-hidden">
          <Lottie animationData={animationData} loop={true} />
        </div>
        <h5 className="text-gray-900 text-center text-2xl font-extrabold leading-tight">
          {message} {ContactMode}!
        </h5>
        <p className="text-gray-500 text-center text-base font-normal leading-relaxed">
          We will inform the candidate and keep you updated.
        </p>
      </Card>
    </Modal>
  )
}

export default EmailSentModal
