import React, { useState, useEffect } from 'react'
import { Modal, Label } from 'flowbite-react'
import { industryInitialState } from '../../../config/constants'
import { editIndustry, createIndustry } from '../../../services/industryService'
import useToastMessage from '../../../hooks/useToastMessage'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { industryValidationSchema } from '../../../validations/IndustryValidationSchema'

const IndustryEditModal = ({
  industryData,
  isModalOpen,
  closeModal,
  title,
  getIndustries,
}) => {
  const [data, setData] = useState(industryInitialState)
  const toastMessage = useToastMessage()

  useEffect(() => {
    if (industryData) {
      setData({
        id: industryData?.id,
        title: industryData.title,
        details: industryData.details,
      })
    } else {
      setData(industryInitialState)
    }
  }, [industryData])

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (title === 'Edit Industry') {
        const response = await editIndustry(values)

        if (response?.status === 200) {
          toastMessage('Industry updated successfully!', 'success')
          closeModal()
          getIndustries()
          resetForm()
        }
      } else if (title === 'Create Industry') {
        const response = await createIndustry(values)
        if (response?.status === 201) {
          toastMessage('Industry created successfully!', 'success')
          closeModal()
          getIndustries()
          resetForm()
        }
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors.join(', ')
        alert(errorMessage)
      }
    }
  }
  return (
    <Modal
      show={isModalOpen}
      onClose={() => closeModal(false)}
      theme={{
        content: {
          base: 'bg-transparent w-3/4',
          inner: 'bg-transparent',
        },
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={data}
        validationSchema={industryValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleSubmit, values, isSubmitting }) => (
          <Form className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {title}
              </h3>
              <button
                onClick={() => closeModal()}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-6 space-y-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <Label className="block text-sm font-medium text-gray-900 dark:text-white">
                    Industry
                  </Label>
                  <Field
                    onChange={handleChange}
                    value={values?.title}
                    type="text"
                    name="title"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Industry"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-red-500 text-xs mt-1"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <Label className="block text-sm font-medium text-gray-900 dark:text-white">
                    Details
                  </Label>
                  <Field
                    onChange={handleChange}
                    value={values?.details}
                    type="text"
                    name="details"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Details"
                  />
                  <ErrorMessage
                    name="details"
                    component="div"
                    className="text-red-500 text-xs mt-1"
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center p-6 space-x-3 rtl:space-x-reverse border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {title.includes('Create') ? 'Create' : 'Update'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default IndustryEditModal
