import { List, TextInput } from 'flowbite-react'
import React, { useState } from 'react'
import eyeicon from 'assets/eye.svg'
import eyeSlashIcon from 'assets/eye-slash.svg'
import { PASSWORD_REGEX } from 'validations/constants'
import { PasswordNotRequiredValidation } from 'validations/registerValidationSchema'

const ChangePassword = ({
  profileData,
  handleChange,
  errors,
  setErrors,
  submitting,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showrepeatedPassword, setshowrepeatedPassword] = useState(false)
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const togglePasswordVisibility = (setState, prevState) => {
    setState(prevState => !prevState)
  }

  const isPasswordMatch = () => {
    return profileData.password === profileData.repeatPassword
  }

  const isCorrectPassword = () => {
    return PASSWORD_REGEX.test(profileData.repeatPassword)
  }

  const handleBlur = async event => {
    const { name, value } = event.target

    if (isPasswordMatch()) {
      setErrors(prevErrors => {
        const { saveChanges, ...restErrors } = prevErrors
        return restErrors
      })
    }

    try {
      await PasswordNotRequiredValidation.validate(profileData, { abortEarly: false })
      setErrors(prevErrors => {
        const { password, repeatPassword, ...restErrors } = prevErrors
        return restErrors
      })
    } catch (error) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: error.errors[0],
      }))
    }
  }
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col md:flex-row justify-between gap-4">
        <div className="w-full">
          <div className="pb-0.5">
            <label className="text-[#111928] text-[14px] font-[500] ">
              Current Password
            </label>
          </div>
          <div className="relative ">
            <TextInput
              name="currentPassword"
              value={profileData.currentPassword}
              className="mb-1"
              id="currentPassword"
              type={showCurrentPassword ? 'text' : 'password'}
              placeholder="********"
              onChange={handleChange}
              errors={errors}
              autoComplete="new-password"
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-auto"
              onClick={() => togglePasswordVisibility(setShowCurrentPassword)}
            >
              <img
                src={showCurrentPassword ? eyeicon : eyeSlashIcon}
                alt={showCurrentPassword ? 'Hide password' : 'Show password'}
                className="h-4 w-4 text-gray-400"
              />
            </button>
          </div>
          {errors.currentPassword && (
            <p className="text-14 text-red-500">{errors.currentPassword}</p>
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between gap-4">
        <div className="w-full">
          <div className="pb-0.5">
            <label
              htmlFor="password"
              className="block text-sm font-[500] text-gray-700"
            >
              New Password
            </label>
          </div>
          <div className="relative">
            <TextInput
              name="password"
              value={profileData?.password}
              className="mb-1"
              id="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="********"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-auto"
              onClick={() => togglePasswordVisibility(setShowPassword)}
            >
              <img
                src={showPassword ? eyeicon : eyeSlashIcon}
                alt={showPassword ? 'Hide password' : 'Show password'}
                className="h-4 w-4"
              />
            </button>
          </div>
          {errors.password && (
            <p className="text-sm font-semibold text-red-500">
              {errors.password}
            </p>
          )}
          <div className="text-sm py-1">
            <p className="font-semibold text-slate-500">Password guideline:</p>
            <List>
              <List.Item>At least 8 characters</List.Item>
              <List.Item>Include special character</List.Item>
              <List.Item>Include capitalized characters</List.Item>
            </List>
          </div>
        </div>
        <div className="w-full">
          <div className="pb-0.5">
            <label
              htmlFor="repeatPassword"
              className="block text-sm font-[500] text-gray-700"
            >
              Repeat New Password
            </label>
          </div>
          <div className="relative">
            <TextInput
              value={profileData?.repeatPassword}
              name="repeatPassword"
              id="repeatPassword"
              type={showrepeatedPassword ? 'text' : 'password'}
              placeholder="********"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!profileData?.password}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-auto"
              onClick={() => togglePasswordVisibility(setshowrepeatedPassword)}
            >
              <img
                src={showrepeatedPassword ? eyeicon : eyeSlashIcon}
                alt={showrepeatedPassword ? 'Hide password' : 'Show password'}
                className="h-4 w-4"
              />
            </button>
          </div>
          {!submitting && errors.repeatPassword ? (
            <p className="text-sm font-semibold text-red-500 py-2">
              {errors.repeatPassword}
            </p>
          ) : profileData?.repeatPassword.length ? (
            <p
              className={`text-xs ${isPasswordMatch() && isCorrectPassword() ? 'text-green-700' : 'text-red-700'}`}
            >
              {!submitting && !isCorrectPassword() ? (
                <p className="text-sm font-semibold text-red-500 py-2">
                  Password must match the guidelines
                </p>
              ) : !submitting && isPasswordMatch() ? (
                <p className="text-sm font-semibold text-[#0E9F6E] py-2">
                  Password matched!
                </p>
              ) : (
                !submitting && (
                  <p className="text-sm font-semibold text-red-500 py-2"></p>
                )
              )}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
