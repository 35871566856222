import React from 'react'
import crossIcon from 'assets/svgs/cross.svg'

export const DeleteConfirmationModal = ({
  onClose,
  submitDeleteAccountRequest = () => {},
}) => {
  return (
    <div className="bg-[#FDFDFD] flex flex-col p-8 gap-20 text-black min-w-[630px] min-h-80 border rounded-lg relative pt-20 items-center">
      <button onClick={onClose} className="absolute top-4 right-5">
        <img
          src={crossIcon}
          alt="Close"
          className="cursor-pointer h-[24px] w-[24px]"
        />
      </button>

      <div className="flex flex-col gap-0.5">
        <span className="text-2xl font-bold">
          Are you sure you want to perform this action
        </span>
        <span className="text-base font-normal text-[#6B7280]">
          This action is irreversible and will permanently delete the Employer
          account
        </span>
      </div>

      <div className="flex flex-row gap-8 justify-center">
        <button
          type="button"
          onClick={onClose}
          className="text-sm font-medium py-2 lg:px-3 px-7 rounded-lg mb-2 lg:mb-0 min-w-40 bg-[#1A56DB] text-white border"
        >
          Cancel
        </button>

        <button
          type="button"
          onClick={submitDeleteAccountRequest}
          className="text-sm font-medium py-2 lg:px-3 px-7 rounded-lg mb-2 lg:mb-0 min-w-40 bg-[#C81E1E] text-white border border-[#C81E1E]"
        >
          Submit and Close
        </button>
      </div>
    </div>
  )
}
