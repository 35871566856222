import { clearCurrentUser } from '../redux/currentUserSlice'
import axiosInstance from '../services/axiosConfig'

export const loginUser = async body => {
  const response = await axiosInstance.post('/login', {
    user: body,
  })
  return await response.json()
}

export const registerUser = async body => {
  const response = await axiosInstance.post('/signup', {
    user: body,
  })
  response.headers.authorization && localStorage.setItem('accessToken', response.headers.authorization)
  return response.data.data
}

export const  logoutUser = async (dispatch, navigate, toastMessage, isSuperAdmin = false, isCandidate = false, isEmployeer = false) => {
  try {
    const response = await axiosInstance.delete('/logout')
    if (response.status === 200) {
      isSuperAdmin ? navigate('/admin/login') : isCandidate ? navigate('/user/login') : navigate('/login')
      localStorage.clear('accessToken')
      toastMessage('Logged out successfully!', 'success')
    } else {
      toastMessage('Something went wrong!', 'error')
    }
  } catch (error) {
    console.error('Eror logging out', error)
  }
}

export const registerCompany = async body => {
  const response = await axiosInstance.post('/api/v1/companies', {
    company: body,
  })
  return response.data.company
}

export const updateCompany = async (id, body) => {
  const response = await axiosInstance.patch(`/api/v1/companies/${id}`, body)
  return response.data
}
