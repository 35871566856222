import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import 'assets/css/user-settings-tab.css'
import { candidateDeactivateOtherValidationSchema } from 'validations/UserSettingsTabValidationSchema'

export const DeactivationThoughts = ({
  feedback,
  handleUserThoughtsChange,
  updateFormValidity,
}) => {
  const formik = useFormik({
    initialValues: {
      feedback: feedback || '',
    },
    validationSchema: candidateDeactivateOtherValidationSchema,
    onSubmit: () => {},
    validateOnChange: true,
    validateOnBlur: true,
  })

  const { values, errors, touched, handleChange, handleBlur } = formik

  useEffect(() => {
    updateFormValidity(Object.keys(errors).length === 0 && feedback.length > 0)
    handleUserThoughtsChange({ target: { value: values.feedback } })
  }, [errors, values, updateFormValidity, handleUserThoughtsChange])

  return (
    <div className="flex flex-col min-w-[555px] max-w-[555px] min-h-[118px] max-h-[118px] gap-y-2 main-deactivate-thoughts ">
      <span className="text-sm font-medium text-[#585C5F]">
        Share your experience and tell us what happened
      </span>

      <textarea
        id="deactivation-reason"
        rows="4"
        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 resize-none"
        placeholder="Write text here ..."
        value={values.feedback}
        onChange={handleChange}
        onBlur={handleBlur}
        name="feedback"
        maxLength={300}
      ></textarea>
      {touched.feedback && errors.feedback && (
        <p className="text-xs text-red-500">{errors.feedback}</p>
      )}
    </div>
  )
}
