import React from 'react'
import { Accordion } from 'flowbite-react'
import { NavLink } from 'react-router-dom'

export function HomeAccordian() {
  return (
    <Accordion collapseAll>
      <Accordion.Panel>
        <Accordion.Title className="xs:p-5 p-2 ">Company</Accordion.Title>
        <Accordion.Content>
          <div className="flex flex-col xs:px-0 px-6">
            <NavLink
              to="/login"
              className="xs:px-4 px-3 xs:py-2 py-1 mb-2 bg-gray-200 rounded-md"
            >
              Sign In
            </NavLink>
            <NavLink
              to="/register"
              className="xs:px-4 px-2 xs:py-2 py-1 mb-2 bg-gray-200 rounded-md"
            >
              Register
            </NavLink>
          </div>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title className="xs:p-5 p-2">Candidate</Accordion.Title>
        <Accordion.Content>
          <div className="flex flex-col">
            <NavLink
              to="/user/login"
              className="xs:px-4 px-3 xs:py-2 py-1 mb-2 bg-gray-200 rounded-md"
            >
              Sign In
            </NavLink>
            <NavLink
              to="/candidate-register"
              className="xs:px-4 px-3 xs:py-2 py-1 mb-2 bg-gray-200 rounded-md"
            >
              Register
            </NavLink>
          </div>
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
  )
}
