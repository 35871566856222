import React from 'react'
import { FileItemsView } from './FileItemsView'
import redCrossCircle from 'assets/svgs/red-x-circle.svg'
import redTrashIcon from 'assets/svgs/red-trash-icon.svg'
import { InitialLetters } from 'components/generics/Initials'

export const CandidateMessageSenderInfo = ({
  senderName,
  companyName,
  country,
  profilePicture,
  filesList = [],
  selectedConversation = [],
  handleDeleteConversation,
}) => {
  const attachments = selectedConversation?.filter(
    message => message.attachments,
  )

  const onDeleteClick = () => {
    handleDeleteConversation?.()
  }

  return (
    <div className="flex flex-col gap-3.5 p-4 max-w-[380px] min-w-[380px] bg-white">
      <div className="flex flex-col gap-2 items-center">
         {profilePicture ? (
            <img
              src={profilePicture}
              alt="User Avatar"
              className="rounded-full w-20 h-20 md:w-20 md:h-20"
            />
          ) : (
            <div className="flex justify-center items-center p-5 rounded-full text-2xl bg-gray-100 border-gray-100 h-20 w-20 md:h-20 md:w-20">
              <InitialLetters
                Name={senderName}
              />
            </div>
          )}
        <span className="text-lg font-bold">{senderName}</span>
        <span className="text-sm font-medium text-[#4B5563]">
          From <span className="text-[#1F2A37]">{companyName}</span>
        </span>
        <span className="text-sm font-medium text-[#4B5563]">{country}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-sm font-medium">Files</span>
        <div className="flex flex-col gap-1 files-item overflow-y-auto max-h-60">
          {attachments.map((message, index) => (
            <FileItemsView
              key={`${message.id}-${index}`}
              fileName={message.attachments.filename}
              fileSize={`${message.attachments.file_size} MB`}
              fileType={message.attachments.filename
                .split('.')
                .pop()
                .toUpperCase()}
            />
          ))}
          {attachments.length === 0 && (
            <span className="text-sm text-gray-500">No files shared yet.</span>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-2 items-center text-red-700">
        <img
          src={redCrossCircle}
          alt="Cross icon"
          className="rounded-full w-4 h-4 md:w-4 md:h-4"
        />
        <button className="text-sm font-medium">Block user</button>
      </div>
      <div className="flex flex-row gap-2 items-center text-red-700">
        <img
          src={redTrashIcon}
          alt="Trash icon"
          className="rounded-full w-4 h-4 md:w-4 md:h-4"
        />
        <button className="text-sm font-medium" onClick={onDeleteClick}>
          Delete Chat
        </button>
      </div>
    </div>
  )
}
