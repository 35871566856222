import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  updateSearchHistory,
  deleteSearchHistory,
} from '../services/searchResult'
import { setSearchHistory } from '../redux/currentUserSlice'

import showAlert from '../components/generics/Alert'

const useSearchHistoryActions = setCompanySearchHistory => {
  const [selectedId, setSelectedId] = useState(null)
  const [searchName, setSearchName] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState({ searchName: '' })
  const dispatch = useDispatch()

  const handleHistoryUpdate = async (action, event) => {
    event.preventDefault()
    const formData = new FormData()
    if (action === 'rename') {
      formData.append('search_title', searchName.trim())
    }
    formData.append('performed_action', action)
    formData.append('search_id', selectedId)

    try {
      const response = await updateSearchHistory(selectedId, formData)
      if (response.status === 200) {
        const updatedSearchHistories = JSON.parse(
          response.data?.company_search_history,
        )
        const updatedAllSearchHistories = JSON.parse(
          response.data?.all_search_histories,
        )
        setCompanySearchHistory(updatedAllSearchHistories.data)
        setOpenModal(false)
        setOpenConfirmModal(false)
        dispatch(setSearchHistory(updatedAllSearchHistories))
      } else {
        const errorData = await response.data.json()
        showAlert({
          icon: 'error',
          title: 'Error',
          text: errorData.error,
          showConfirmButton: false,
          showCloseButton: true,
        })
      }
    } catch (error) {
      setErrorMessage({
        searchName: error?.response?.data?.error
      })
      console.error('Error:', error)
    }
  }

  const handleDeleteSearchHistory = async event => {
    event.preventDefault()

    try {
      const response = await deleteSearchHistory(selectedId)
      if (response.status === 200) {
        const updatedSearchHistories = JSON.parse(
          response.data?.company_search_history,
        )
        const updatedAllSearchHistories = JSON.parse(
          response.data?.all_search_histories,
        )
        setCompanySearchHistory(updatedSearchHistories.data)
        setOpenModal(false)
        setOpenConfirmModal(false)
        dispatch(setSearchHistory(updatedAllSearchHistories))
      } else {
        const errorData = await response.data.json()
        showAlert({
          icon: 'error',
          title: 'Error',
          text: errorData.error,
          showConfirmButton: false,
          showCloseButton: true,
        })
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  return {
    selectedId,
    setSelectedId,
    searchName,
    setSearchName,
    openModal,
    setOpenModal,
    openConfirmModal,
    setOpenConfirmModal,
    handleHistoryUpdate,
    handleDeleteSearchHistory,
    errorMessage,
    setErrorMessage,
  }
}

export default useSearchHistoryActions
