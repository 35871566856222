import React from 'react'
import { TruncatedText, isMobileDevice } from 'utilis/helpers'
import { InitialLetters } from 'components/generics/Initials'

export const ChatPreview = ({
  senderName,
  senderLogo,
  lastMessage,
  messageTime,
  onChatClick,
  count,
  typing,
  item,
  currentUser,
  selectedChat
}) => {
  const userId = currentUser?.role === "company_admin" ? item?.user?.id : item?.users?.[0]?.user_id
  const isTyping = typing?.[userId]
  const isMobile = isMobileDevice();

  return (
    <div
      onClick={onChatClick}
      className={`flex flex-row gap-2.5 p-3 items-center justify-between ${!selectedChat ? '': 'max-w-[296px]'}  max-h-[45px] cursor-pointer`}
    >
      <div className="flex flex-row gap-2.5 items-center">
        <div className="relative flex-shrink-0">
          {senderLogo ? (
              <img
                src={senderLogo}
                alt="User Logo"
                className="w-10 h-10 sm:w-10 sm:h-10 rounded-full object-cover border-2 border-[#E5E7EB]"
              />
            ) : (
              <div className="flex justify-center items-center p-5 rounded-full text-lg bg-gray-100 border-[#E5E7EB] h-10 w-10 sm:w-10 sm:h-10">
                <InitialLetters
                  Name={senderName}
                />
              </div>
          )}
          <span className="absolute top-1 right-1 sm:top-0 sm:right-3 w-2 h-2 sm:w-3 sm:h-3 bg-green-400 rounded-full"></span>
        </div>
        <div className="flex flex-col items-start">
          <span className="text-base font-medium text-grey-900">
          <TruncatedText text={senderName} limit={isMobile ? 15 : 24} />
          </span>
          <span className="text-[#6B7280] text-sm font-medium">
            { isTyping ? <>
            <span className='text-sm leading-[150%] text-primary-700'>
              Typing...
            </span>
            </> : <div className={`${count ? 'text-black':'text-gray-600' }`}>
              <TruncatedText  text={lastMessage} limit={isMobile ? 15 : 25} />
            </div>}
          </span>
        </div>
      </div>
      <span className={`${count ? 'bg-blue-100 w-[6%] rounded-md text-center text-blue-700' : 'text-gray-500'} text-xs  font-medium`}>{count || messageTime}</span>
    </div>
  )
}
