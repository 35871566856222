import React from 'react'
import Chart from 'react-apexcharts'
import heartIconCard from '../../assets/heart_icon_card.svg'
import savedHeartIcon from '../../assets/heart.svg'
import { Button } from 'flowbite-react'
import { roundPercentage } from 'utilis/helpers'
import '../../assets/css/search-results.css'

const RadialBar = ({
  series,
  onSaveCandidate,
  isCandidateSaved,
  disableSave,
  fromRevealProfile,
}) => {
  const options = {
    colors: ['#1C64F2', '#16BDCA'],
    labels: ['Skills', 'Preference'],
    legend: {
      show: true,
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
      position: 'right',
      floating: false,
      fontSize: '12px',
      offsetX: 23,
      offsetY: 23,
      formatter: function (seriesName) {
        return `<span class="font-satoshi text-[#6B7280]">${seriesName}</span>`
      },
    },
    plotOptions: {
      radialBar: {
        size: undefined,
        inverseOrder: false,
        startAngle: 0,
        endAngle: 360,
        offsetX: 0,
        offsetY: 0,
        hollow: {
          margin: 5,
          size: '20%',
          background: 'transparent',
          image: undefined,
          imageWidth: 100,
          imageHeight: 100,
          imageOffsetX: 0,
          imageOffsetY: 0,
          imageClipped: true,
          position: 'front',
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: '#E6E7EB',
          strokeWidth: '95%',
          opacity: 1,
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        dataLabels: {
          show: false,
          name: {
            show: false,
            fontSize: '12px',
            fontFamily: undefined,
            color: undefined,
            offsetY: -10,
          },
          value: {
            show: true,
            fontSize: '12px',
            fontFamily: undefined,
            color: undefined,
            offsetY: 16,
            formatter: function (val) {
              return val + '%'
            },
          },
          total: {
            show: false,
            label: 'Match Score',
            color: '#373d3f',
            formatter: function (w) {
              return (
                w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0) /
                  w.globals.series.length +
                '%'
              )
            },
          },
        },
      },
    },
  }
  const totalMatchPercentage = series[0]
  return (
    <div className="w-full ml-auto flex flex-row items-center">
      <div
        className={`below-917:ml-4  ${fromRevealProfile ? 'mb-5' : ''} w-1/2 md:w-1/3 lg:1/4`}
      >
        <p className="text-14 font-medium text-gray-600">Match Score</p>
        <h1 className="text-24 font-extrabold text-black">
          {roundPercentage(totalMatchPercentage)}
        </h1>
      </div>
      <div className="w-2/3 sm-445:hidden">
        <Chart options={options} series={series} type="radialBar" width="280" />
      </div>
      {!fromRevealProfile && (
        <div className="flex h-32 w-14 sm-445:w-20 mt-2 sm-445:mt-0 mr-2 justify-end sm-445:items-center">
          <Button
            className="h-5 heart-button"
            onClick={onSaveCandidate}
            disabled={disableSave}
          >
            <img
              src={isCandidateSaved ? savedHeartIcon : heartIconCard}
              alt="heartIcon"
              className="w-5 h-5"
            />
          </Button>
        </div>
      )}
    </div>
  )
}

export default RadialBar
