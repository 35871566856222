import React from 'react';

const BlueworkIcon = ({ color = '#1A56DB', width = 15, height = 14, className = '' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 15 14" fill="none" className={className}>
      <path d="M13.2777 3.28513H11.1777V2.60969C11.1777 2.07228 10.9565 1.55688 10.5627 1.17687C10.1688 0.79686 9.63469 0.583374 9.07773 0.583374H6.27773C5.72078 0.583374 5.18664 0.79686 4.79281 1.17687C4.39898 1.55688 4.17773 2.07228 4.17773 2.60969V3.28513H2.07773C1.70643 3.28513 1.35034 3.42745 1.08778 3.68079C0.825234 3.93413 0.677734 4.27773 0.677734 4.63601V12.0658C0.677734 12.4241 0.825234 12.7677 1.08778 13.021C1.35034 13.2744 1.70643 13.4167 2.07773 13.4167H13.2777C13.649 13.4167 14.0051 13.2744 14.2677 13.021C14.5302 12.7677 14.6777 12.4241 14.6777 12.0658V4.63601C14.6777 4.27773 14.5302 3.93413 14.2677 3.68079C14.0051 3.42745 13.649 3.28513 13.2777 3.28513ZM5.57773 2.60969C5.57773 2.43055 5.65148 2.25875 5.78276 2.13208C5.91404 2.00541 6.09208 1.93425 6.27773 1.93425H9.07773C9.26339 1.93425 9.44143 2.00541 9.57271 2.13208C9.70398 2.25875 9.77773 2.43055 9.77773 2.60969V3.28513H5.57773V2.60969ZM13.2777 4.63601V7.02976C10.673 9.25465 4.68243 9.25465 2.07773 7.02976V4.63601H13.2777ZM2.07773 12.0658V8.69269C3.79116 9.60223 5.72256 10.0604 7.67773 10.0213C9.63289 10.0603 11.5643 9.60211 13.2777 8.69269V12.0658H2.07773Z" fill={color} />
      <path d="M7.67773 7.33776C8.06433 7.33776 8.37773 7.03536 8.37773 6.66232C8.37773 6.28929 8.06433 5.98688 7.67773 5.98688C7.29113 5.98688 6.97773 6.28929 6.97773 6.66232C6.97773 7.03536 7.29113 7.33776 7.67773 7.33776Z" fill={color} />
    </svg>
  );
};

export default BlueworkIcon;
