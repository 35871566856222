import * as Yup from 'yup'

const envVarsSchema = Yup.object().shape({
  REACT_APP_API_BASE_URL: Yup.string().required(
    'REACT_APP_API_BASE_URL is required',
  ),
  REACT_APP_FIREBASE_API_KEY: Yup.string().required(
    'REACT_APP_FIREBASE_API_KEY is required',
  ),
  REACT_APP_FIREBASE_AUTH_DOMAIN: Yup.string().required(
    'REACT_APP_FIREBASE_AUTH_DOMAIN is required',
  ),
  REACT_APP_FIREBASE_PROJECT_ID: Yup.string().required(
    'REACT_APP_FIREBASE_PROJECT_ID is required',
  ),
  REACT_APP_FIREBASE_STORAGE_BUCKET: Yup.string().required(
    'REACT_APP_FIREBASE_STORAGE_BUCKET is required',
  ),
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: Yup.string().required(
    'REACT_APP_FIREBASE_MESSAGING_SENDER_ID is required',
  ),
  REACT_APP_FIREBASE_APP_ID: Yup.string().required(
    'REACT_APP_FIREBASE_APP_ID is required',
  ),
  REACT_APP_FIREBASE_MEASUREMENT_ID: Yup.string().required(
    'REACT_APP_FIREBASE_MEASUREMENT_ID is required',
  ),
  REACT_APP_VIDEO_URL: Yup.string().required('REACT_APP_VIDEO_URL is required'),
  REACT_APP_INTERCOM_APP_ID: Yup.string().required(
    'REACT_APP_INTERCOM_APP_ID is required',
  ),
})

export const validateEnvVars = () => {
  envVarsSchema
    .validate(process.env, { abortEarly: false })
    .then(() => {
      console.info(
        '%cENVs validation successful',
        'background: green; color: white; padding: 2px 4px; border-radius: 3px;',
      )
    })
    .catch(err => {
      console.error('ENVs validation error:', err.errors)
      throw new Error('Environment variables validation failed.')
    })
}
