import { Badge, Button, Modal } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  selectCompany,
  setSearchHistory,
} from '../../../redux/currentUserSlice'
import { fetchSearchHistory, updateSearchHistory } from 'services/searchResult'
import {
  setTalentSearchData,
  setTalentSearchQueryParam,
} from '../../../redux/talentSearchSlice'
import { selectSearch, setSearch } from '../../../redux/searchSlice'
import { searchCandidates } from 'services/candidateService'
import { getIndustries } from 'services/industryService'
import { getSkills } from 'services/skillService'
import { getExpertises } from 'services/expertiseService'
import InfoIcon from 'assets/info.svg'
import DropdownSearch from 'components/generics/dropdown/DropdownSearch'
import useToastMessage from 'hooks/useToastMessage'

const StartNewSearch = ({ modalOpen, setModalOpen, isNewSearch, search }) => {
  const companyIndustry = useSelector(selectCompany)
  const [selectedIndustry, setSelectedIndustry] = useState(
    (!isNewSearch && search?.industry?.id) || (companyIndustry?.industry_id),
  )

  const [searchName, setSearchName] = useState(
    (!isNewSearch && search?.name) || '',
  )

  const [industryOptions, setIndustryOptions] = useState([])
  const [selectedSkill, setSelectedSkill] = useState(
    (!isNewSearch && search?.skill?.id) || '',
  )
  const [skillsOptions, setSkillsOptions] = useState([])
  const [expertises, setExpertises] = useState([])
  const [selectedExpertises, setSelectedExpertises] = useState(
    (!isNewSearch && search?.expertise) || [],
  )
  const [jobPreferences] = useState({
    years_of_experience: '',
    salary_range: '',
    certification_required: false,
    preferred_languages: '',
    accommodation: false,
    visa_sponsorship: false,
    transportation: false,
    benefits: false,
  })
  const [errorMessage, setErrorMessage] = useState({
    searchName: '',
    selectedIndustry: '',
    selectedSkill: '',
    selectedExpertises: '',
  })
  const navigate = useNavigate()
  const toastMessage = useToastMessage()
  const dispatch = useDispatch()

  useEffect(() => {
    !isNewSearch && selectedSkill && handleSkillChange(selectedSkill, false)
  }, [search])
  const fetchIndustries = async () => {
    try {
      const response = await getIndustries()
      if (response.status === 200) {
        setIndustryOptions(response.data || [])
      }
    } catch (error) {
      console.error('Error fetching industries:', error)
    }
  }
  useEffect(() => {
    fetchIndustries()
    fetchSearchHistory(dispatch, setSearchHistory)
  }, [])
    useEffect(() => {
      if (selectedIndustry) {
        fetchSkills(selectedIndustry)
      }
    }, [selectedIndustry])
  const fetchSkills = async industry => {
    try {
      const response = await getSkills(`industry=${industry}`)
      if (response.status === 200) {
        setSkillsOptions(response.data || [])
      }
    } catch (error) {
      console.error('Error fetching skills:', error)
    }
  }

  const handleInputChange = e => {
    const value = e.target.value
    const trimmedValue = value.trim()
    setSearchName(value)
    if (trimmedValue) {
      if (trimmedValue.length > 50) {
        setErrorMessage(prevState => ({
          ...prevState,
          searchName: 'Search Name cannot exceed 50 characters',
        }))
      } else {
        setErrorMessage(prevState => ({
          ...prevState,
          searchName: '',
        }))
      }
    } else {
      setErrorMessage(prevState => ({
        ...prevState,
        searchName: 'Search Name is required',
      }))
    }
  }

  const handleIndustryChange = selectedOption => {
    const selectedValue = selectedOption.id
    setSelectedIndustry(selectedValue)
    setSelectedSkill('')
    setExpertises([])
    setSelectedExpertises([])
    if (selectedValue) {
      setErrorMessage(prevState => ({
        ...prevState,
        selectedIndustry: '',
      }))
    }
  }
  const handleSkillChange = async (skill, clear) => {
    setSelectedSkill(skill)
    if (skill) {
      try {
        const response = await getExpertises(`skill=${skill}`)
        if (response.status === 200) {
          setExpertises(response.data || [])
          clear && setSelectedExpertises([])
          setErrorMessage(prevState => ({
            ...prevState,
            selectedSkill: '',
          }))
        }
      } catch (error) {
        console.error('Error fetching expertises:', error)
      }
    } else {
      setExpertises([])
    }
  }

  const handleExpertiseChange = e => {
    const { value, checked } = e.target
    !isNewSearch && setSelectedExpertises([])
    const updatedExpertises = checked
      ? [...selectedExpertises, value]
      : selectedExpertises.filter(expertise => expertise !== value)
    setSelectedExpertises(updatedExpertises)
    if (updatedExpertises.length > 0) {
      setErrorMessage(prevState => ({
        ...prevState,
        selectedExpertises: '',
      }))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const errors = {}
    const skill = skillsOptions.filter((data)=> data?.id === selectedSkill)
    const trimmedSearchName = searchName.trim()
    if (trimmedSearchName === '') {
      errors.searchName = 'Search Name is required'
    } else if (trimmedSearchName.length > 50) {
      errors.searchName = 'Search Name cannot exceed 50 characters'
    }
    if (!selectedIndustry) {
      errors.selectedIndustry = 'Industry selection is required'
    }
    if (!selectedSkill) {
      errors.selectedSkill = 'Skill selection is required'
    }
    if (selectedExpertises.length === 0) {
      errors.selectedExpertises = 'At least one expertise must be selected'
    }
    if (Object.keys(errors).length > 0) {
      setErrorMessage(errors)
      return
    }
    const queryParams = new URLSearchParams()
    queryParams.append('skill',  skill[0]?.title)
    queryParams.append('search_name', trimmedSearchName)
    queryParams.append('skill_id', skill[0]?.id)
    
    queryParams.append('industry_id', selectedIndustry)
    selectedExpertises.forEach(expertise => {
      queryParams.append('expertises[]', expertise)
    })
    Object.keys(jobPreferences).forEach(key => {
      queryParams.append(`job_preferences[${key}]`, jobPreferences[key])
    })
    queryParams.append(`page`, 1)
    queryParams.append(`per_page`, 10)
    try {
      const response = await searchCandidates(queryParams.toString())
      if (response.status === 200) {
        queryParams.append('is_view_search', true)
        queryParams.append('search_id', response?.data?.results?.search_history?.attributes?.id)
        dispatch(setTalentSearchQueryParam(queryParams.toString()))
        dispatch(setSearch(response.data.results.search_history.attributes))
        setSkillsOptions(response.data.results || [])
        dispatch(setTalentSearchData(response?.data?.results || []))
        fetchSearchHistory(dispatch, setSearchHistory)
        toastMessage('Fetched candidates list successfully!', 'success')
        setErrorMessage({
          searchName: ''
        })
      }
      navigate('/search-results')
    } catch (error) {
      setErrorMessage({
        searchName: error?.response?.data?.error
      })
      console.error('Error searching candidates:', error)
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setSearchName('')
    setSelectedIndustry((!isNewSearch && search?.industry?.id) || '')
    setSelectedSkill('')
    setExpertises([])
    setSelectedExpertises([])
    setErrorMessage({
      searchName: '',
      selectedIndustry: '',
      selectedSkill: '',
      selectedExpertises: '',
    })
  }

  const updateSearch = async e => {
    try {
      e.preventDefault()
      const formData = new FormData()
      const skill = skillsOptions.filter((data)=> data?.id === selectedSkill)
      formData.append('search_title', searchName.trim())
      formData.append('skill', skill[0]?.title)
      formData.append('skill_id', skill[0]?.id)
      formData.append('industry_id', selectedIndustry)
      selectedExpertises.forEach(expertise => {
        formData.append('expertises[]', expertise)
      })
      Object.keys(jobPreferences).forEach(key => {
        formData.append(`job_preferences[${key}]`, jobPreferences[key])
      })
      formData.append('performed_action', 'edit')
      formData.append('search_id', search?.id)
      const queryParams = new URLSearchParams()
      queryParams.append('skill',  skill[0]?.title)
      queryParams.append('search_name', searchName.trim())
      queryParams.append('skill_id', skill[0]?.id)
      queryParams.append('industry_id', selectedIndustry)
      queryParams.append('is_view_search', true)
      queryParams.append('search_id', search?.id)

      selectedExpertises.forEach(expertise => {
        queryParams.append('expertises[]', expertise)
      })
      Object.keys(jobPreferences).forEach(key => {
        queryParams.append(`job_preferences[${key}]`, jobPreferences[key])
      })
      queryParams.append(`page`, 1)
      queryParams.append(`per_page`, 10)
      const response = await updateSearchHistory(search?.id, formData)
      if (response?.status === 200) {
        setModalOpen(false)
        const response = await searchCandidates(queryParams.toString())
        if (response.status === 200) {
          const actions = [
            dispatch(setTalentSearchQueryParam(queryParams.toString())),
            dispatch(setSearch(response.data.results.search_history.attributes)),
            dispatch(setTalentSearchData(response?.data?.results || [])),
            fetchSearchHistory(dispatch, setSearchHistory) 
          ];
          await Promise.all(actions);

          toastMessage('Fetched candidates list successfully!', 'success')
          navigate('/search-results');
        }
      }
    } catch (error) {
      setErrorMessage({
        searchName: error?.response?.data?.error
      })
    }
  }
  return (
    <Modal
      show={modalOpen}
      onClose={handleCloseModal}
      size="5xl"
      className="font-global-styling"
    >
      <Modal.Header>
        <span className="text-lg font-semibold text-[#111928]">
          Search Quali Talent Directory
        </span>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={!isNewSearch ? updateSearch : handleSubmit}
          className="space-y-4"
        >
          <label className="block">
            <div className="flex">
              <p className="text-sm font-medium text-[#111928] mb-1">
                {' '}
                Search Name{' '}
              </p>
              <img
                src={InfoIcon}
                className="w-3 h-3 text-lightgray-600 ml-1 mt-1"
                alt="Info Icon"
              />
            </div>
            <input
              type="text"
              name="search_name"
              value={searchName}
              onChange={handleInputChange}
              placeholder="Type search name"
              className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
            />
            {errorMessage.searchName && (
              <p className="text-red-500 text-sm mt-1">
                {errorMessage.searchName}
              </p>
            )}
          </label>
          <div className="col-span-2">
            <DropdownSearch
              title="Industry"
              options={industryOptions}
              onSelectTest={handleIndustryChange}
              isSuperAdmin={false}
              value={search?.industry?.title || companyIndustry?.industry_name}
            />
            {errorMessage.selectedIndustry && (
              <p className="text-red-500 text-sm mt-1">
                {errorMessage.selectedIndustry}
              </p>
            )}
          </div>
          <div>
              <span className="block mb-2 block text-sm font-medium text-[#111928]">
                Broadly speaking, what skill are you hiring for?
              </span>
              <div className="grid md:grid-cols-4 grid-cols-2 gap-4">
                {skillsOptions.map((skill, index) => (
                  <Badge
                    key={index}
                    color={selectedSkill === skill.id ? 'blue' : 'gray'}
                    onClick={() => handleSkillChange(skill.id, true)}
                    className={`flex justify-center items-center w-full cursor-pointer p-2 text-xs rounded-lg border border-gray-300 ${selectedSkill === skill.id ? 'text-blue-600' : 'text-gray-600'}`}
                  >
                    {skill.title ? skill.title : `Skill ${index + 1}`}
                  </Badge>
                ))}
              </div>
              {errorMessage.selectedSkill && (
                <p className="text-red-500 text-sm mt-1">
                  {errorMessage.selectedSkill}
                </p>
              )}
            </div>
          <div>
            <span className="block mb-1 block text-sm font-medium text-[#111928]">
              What expertise are you looking for?
            </span>
            <p className="from-muted/10 select-more-color">
              You can select more than 1
            </p>
            <div className="flex flex-wrap gap-3">
              {expertises.map((expertise, index) => (
                <label key={index} className="flex items-center">
                  <input
                    type="checkbox"
                    value={expertise.title}
                    checked={selectedExpertises.includes(expertise.title)}
                    onChange={handleExpertiseChange}
                    className="mr-2 checkmark rounded border border-gray-300 bg-gray-50 focus:ring-0"
                  />
                  <span className="text-gray-900 text-sm font-medium leading-none">
                    {expertise.title}
                  </span>
                </label>
              ))}
            </div>
            {errorMessage.selectedExpertises && (
              <p className="text-red-500 text-sm mt-1">
                {errorMessage.selectedExpertises}
              </p>
            )}
          </div>
          <div className="flex justify-center">
            <Button
              type="submit"
              color={'blue'}
              className="w-80 py-0.5 mt-5 shadow-2xl drop-shadow shadow hover:shadow-lg"
            >
              Start new search
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default StartNewSearch
