import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import CandidateAccountTab from './CandidateAccountTab'
import { Tabs } from 'flowbite-react'
import { ProfileCard } from './profile-card/ProfileCard'
import ExperienceQualificationForm from './CandidateExperienceTab/ExperienceQualificationForm'
import PreferencesForm from './CandidatePreferenceTab/PreferencesForm'
import { CandidateSettingTab } from './CandidateSettingTab/CandidateSettingTab'
import { selectCurrentUser } from '../../../redux/currentUserSlice'

export const CandidateProfileOverview = () => {
  const [activeTab, setActiveTab] = useState('Overview')
  const { currentUser } = useSelector(selectCurrentUser)
  const tabsRef = useRef(null)

  const handleTabClick = tab => {
    setActiveTab(tab)
  }

  const getCurrentTab = () => {
    return activeTab
  }
  return (
    <div className="border-b-2 border-gray rounded-lg bg-white min-h-[690px] shadow mr-3 pb-4">
      <div className=" flex flex-col md:flex-row justify-between items-center mx-4">
        <div className="w-full flex flex-col mt-5 px-2">
          <h1 className="mb-2 text-24 font-[700] text-gray-900">
            My Quali Profile
          </h1>
          <hr className="border-gray-200" />
          <Tabs
            ref={tabsRef}
            // eslint-disable-next-line react/style-prop-object
            style="underline"
            aria-label="Tabs with underline"
            className="tabs-no-outline tabs-no-bottom-border gap-8 mt-1 justify-start gap-y-1"
          >
            <Tabs.Item
              title={
                <span
                  className="pr-2 pl-2 text-16 font-[500]"
                  onClick={() => handleTabClick('Overview')}
                >
                  Overview
                </span>
              }
              className="tabs-item"
            >
              <ProfileCard tabsRef={tabsRef} />
            </Tabs.Item>
            <Tabs.Item
              title={
                <span
                  className="pr-2 pl-2 text-16 font-[500]"
                  onClick={() => handleTabClick('Account')}
                >
                  Account
                </span>
              }
              className="tabs-item"
            >
              <CandidateAccountTab
                candidateData={currentUser}
                activeTab={activeTab}
                tabsRef={tabsRef}
                getCurrentTab={getCurrentTab}
              />
            </Tabs.Item>
            <Tabs.Item
              title={
                <span
                  className="pr-3 pl-3 text-16 font-[500]"
                  onClick={() => handleTabClick('Experience')}
                >
                  Experience
                </span>
              }
              className="tabs-item"
            >
              <ExperienceQualificationForm tabsRef={tabsRef} />
            </Tabs.Item>
            <Tabs.Item
              title={
                <span
                  className="pr-2 pl-2 text-16 font-[500]"
                  onClick={() => handleTabClick('Account')}
                >
                  Preferences
                </span>
              }
              className="tabs-item"
            >
              <PreferencesForm />
            </Tabs.Item>
            <Tabs.Item
              title={
                <span
                  className="pr-2 pl-2 text-16 font-[500]"
                  onClick={() => handleTabClick('Settings')}
                >
                  Settings
                </span>
              }
              className="tabs-item"
            >
              <CandidateSettingTab />
            </Tabs.Item>
          </Tabs>
        </div>
      </div>
    </div>
  )
}
