import { useEffect, useState } from 'react'
import { Modal } from 'flowbite-react'
import RadialBar from '../RevealProfileChart'
import useToastMessage from '../../../hooks/useToastMessage'
import {
  getAllTestsListings,
  sendPendingTestReminderEmail,
  sendTestsToCandidate,
} from '../../../services/testService'
import {
  selectCompany,
  selectCurrentUser,
} from '../../../redux/currentUserSlice'
import { useSelector } from 'react-redux'
import EmailSentModal from './ContactAlert'
import { roundPercentage } from 'utilis/helpers'
import { verifyCertification } from 'services/certificationService'
import { selectSearch } from '../../../redux/searchSlice'
import 'assets/css/reveal-candidate-card.css'
import { CongratulationsModal } from './CongratulationsModal'
import { NotAMatchForm } from './NotaMatchModal'
import { removeOfferLetter, setProfileStatus } from 'services/userService'
import { sendHiredEmailToCandidate } from 'services/candidateService'
import MessagesIcon from 'components/generics/Icons/MessagesIcon'
import CandidateProfile from '../CandidateProfile'
import CertificationsList from '../CertificationsList'
import ExperienceTimeline from '../ExperienceTimeLine'
import CompanyActionInfo from '../CompanyActionInfo'
import DecisionButtons from '../DecisionButtons'
import TestResults from '../TestsResults'
import AdditionalActions from '../AdditionalActions'
import { useNavigate } from 'react-router-dom'
import { selectRevealedCandidates } from '../../../redux/talentSearchSlice'
import { PersistentInbox } from 'components/Messaging/PersistentMinimizableInbox'
import ArchiveModal from '../modals/ArchiveModal'

export default function PipelineCandidateProfile({
  openModal,
  setOpenModal,
  candidate,
  updatedStatus,
  setCandidateStatusUpdate,
  searchId,
  candidateStatusUpdated,
}) {
  const navigateto = useNavigate()
  const { currentUser } = useSelector(selectCurrentUser)
  const toastMessage = useToastMessage()
  const [fullName, setFullName] = useState({
    firstName: '',
    lastName: '',
  })
  const [tests, setTests] = useState([])
  const [selectedTest, setSelectedTest] = useState(null)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [selectCertificate, setSelectedCertificate] = useState({})
  const [showMoreCertifications, setShowMoreCertifications] = useState(false)
  const search = useSelector(selectSearch)
  const [hired, setHired] = useState(false)
  const [passed, setPassed] = useState(false)
  const [selectedReason, setSelectedReason] = useState('')
  const companyData = useSelector(selectCompany)
  const [moreTests, setMoreTests] = useState(false)
  const [candidateProfileStatus, setCandidateProfileStatus] = useState(
    candidateStatusUpdated === true ? updatedStatus : candidate?.profile_status,
  )
  const [offerLetters, setOfferLetters] = useState(
    candidate?.user?.offer_letters?.filter(
      offerLetter =>
        offerLetter.company_id === currentUser?.company?.id &&
        offerLetter.search_history_id === search?.id,
    ) || [],
  )
  const [candidateTests, setCandidateTests] = useState(
    candidate?.user?.all_tests?.filter(
      test => test.company_id === currentUser?.company?.id,
    ) || [],
  )
  const [contactInfo, setContactInfo] = useState({})
  const [moreCertificateVerification, setMoreCertificateVerification] =
    useState(false)
  const [showCongratulationsModal, setShowCongratulationsModal] =
    useState(false)
  const [showArchiveModal, setshowArchiveModal] = useState(false)
  const [openInbox, setOpenInbox] = useState(false)

  const extractNames = () => {
    setFullName({
      firstName: candidate?.user?.first_name,
      lastName: candidate?.user?.last_name,
    })
  }

  const fetchAndSetTests = async () => {
    try {
      const testListing = await getAllTestsListings()
      setTests(testListing?.data || [])
    } catch (error) {
      console.error('Error fetching tests:', error)
    }
  }
  
  const chatListing = useSelector(selectRevealedCandidates)
  const specificChat = chatListing.find(
    item => item?.user.id === parseInt(candidate?.user?.id),
  )
  const [selectedChat, setSelectedChat] = useState(specificChat || null)

  const getContactInfo = () => {
    if (candidateProfileStatus) {
      setContactInfo({
        action: 'hiring_decision',
        actionDate: candidate?.user?.latest_hired_passed?.updated_at,
        decision: candidateProfileStatus,
        candidateName: `${candidate?.user?.first_name} ${candidate?.user?.last_name}`,
        searchName: search?.name,
      })
    } else if (candidateTests && candidateTests?.length > 0) {
      const latestTest = candidateTests.sort(
        (a, b) => new Date(b.sent_date) - new Date(a.sent_date),
      )[0]
      setContactInfo({
        action:
          latestTest?.test_status !== 'completed'
            ? 'test_sent'
            : 'test_completed',
        actionDate:
          latestTest?.test_status !== 'completed'
            ? latestTest?.sent_date
            : latestTest?.updated_at,
        testName: latestTest.title,
        score: latestTest.test_score,
        candidateName: `${candidate?.user?.first_name} ${candidate?.user?.last_name}`,
        searchName: '',
      })
    } else if (candidate?.user?.first_contacted) {
      setContactInfo({
        action: 'contacted',
        actionDate: candidate?.user?.first_contacted,
        candidateName: `${candidate?.user?.first_name} ${candidate?.user?.last_name}`,
        searchName: search?.name,
      })
    } else {
      setContactInfo({})
    }
  }

  useEffect(() => {
    extractNames()
    fetchAndSetTests()
    getContactInfo()
    setCandidateStatusUpdate()
  }, [candidate, candidateProfileStatus, candidateTests])

  const onSelectTest = selected => {
    setSelectedTest(selected)
  }

  const onSelectCertificate = selected => {
    setSelectedCertificate(selected)
  }

  const handleSubmit = async () => {
    try {
      const data = {
        company_name: currentUser?.company?.name,
        company_id: currentUser?.company?.company_id,
        candidate_name: `${candidate?.user?.first_name} ${candidate?.user?.last_name}`,
        medium: 'email',
        phone_no: candidate?.user?.phone_no,
        candidate_id: candidate?.user?.id,
        selected_tests: selectedTest?.map(test => ({
          test_id: test.id,
          title: test.title,
          test_link: test.test_link,
        })),
      }
      if (selectedTest && selectedTest?.length > 0) {
        const response = await sendTestsToCandidate(data)
        if (response.status === 200) {
          setCandidateTests(response?.data?.tests)
          toastMessage('Email sent successfully!', 'success')
          setSuccessModalOpen(true)
          setCandidateStatusUpdate(true)
        }
      }
      if (selectCertificate && selectCertificate.length > 0) {
        for (const certificate of selectCertificate) {
          try {
            const certificateResponse = await verifyCertification(
              search.id,
              certificate,
            )
            if (certificateResponse.status === 200) {
              toastMessage(
                `Verification sent for ${certificate.name}!`,
                'success',
              )
            }
          } catch (error) {
            console.error(
              `Error verifying certificate ${certificate.name}:`,
              error,
            )
            toastMessage(`Failed to verify ${certificate.name}`, 'error')
          }
        }
        setCandidateStatusUpdate(true)
      }
      selectedTest?.length > 0 && setSelectedTest([])
      selectCertificate?.length > 0 && setSelectedCertificate([])
    } catch (error) {
      console.error('Error sending email:', error)
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handlenavigate = () => {
    navigateto(
      `/company-inbox?candidateId=${candidate?.user?.id}&searchHistoryId=${searchId}`,
    )
    setOpenModal(false)
  }
  const handleSetHired = async e => {
    setHired(true)
    try {
      const data = {
        search_history_id: searchId,
        profile_status: 'hired',
        user_id: candidate?.user?.id,
        company_id: companyData?.id,
        user_name: `${candidate?.user?.first_name} ${candidate?.user?.last_name}`,
        company_name: companyData?.name,
      }
      const response = await setProfileStatus(data)
      if (response.status === 200) {
        setCandidateProfileStatus('hired')
        setshowArchiveModal(true)
        setCandidateStatusUpdate(true)
      }
    } catch (error) {
      console.error('Error hiring candidate', error)
    }
  }

  const handleSetOffered = async offerLetter => {
    try {
      const formData = new FormData()
      formData.append('revelation[search_history_id]', searchId)
      formData.append('revelation[profile_status]', 'offered')
      formData.append('revelation[user_id]', candidate?.user?.id)
      formData.append('revelation[company_id]', companyData?.id)
      formData.append(
        'revelation[user_name]',
        `${candidate?.user?.first_name} ${candidate?.user?.last_name}`,
      )
      formData.append('revelation[company_name]', companyData?.name)
      if (offerLetter) {
        formData.append('revelation[offer_letters]', offerLetter)
      }
      const response = await setProfileStatus(formData)
      if (response?.status === 200) {
        setOfferLetters(response?.data?.offer_letters)
        setCandidateProfileStatus('offered')
        setCandidateStatusUpdate(true)
        setShowCongratulationsModal(true)
      }
    } catch (error) {
      alert('Something went wrong', 'error')
      console.error('Error setting offer status:', error)
    }
  }

  const handleSetPassed = async () => {
    try {
      const data = {
        search_history_id: searchId,
        profile_status: 'passed',
        user_id: candidate?.user?.id,
        user_name: `${candidate?.user?.first_name} ${candidate?.user?.last_name}`,
        company_name: companyData?.name,
        company_id: companyData?.id,
      }

      const response = await setProfileStatus(data)
      if (response?.status === 200) {
        setCandidateProfileStatus('passed')
        setCandidateStatusUpdate(true)
        setPassed(true)
      }
    } catch (error) {
      console.error('Error passing candidate', error)
    }
  }

  const handleChangeDecision = async e => {
    try {
      const data = {
        search_history_id: searchId,
        profile_status: null,
        user_id: candidate?.user?.id,
        company_id: companyData?.id,
        profile_feedback: selectedReason,
        user_name: `${candidate?.user?.first_name} ${candidate?.user?.last_name}`,
        company_name: companyData?.name,
      }
      const response = await setProfileStatus(data)
      if (response?.status === 200) {
        setCandidateProfileStatus(response?.data?.record?.profile_status)
        setCandidateStatusUpdate(true)
      }
    } catch (error) {
      console.error('Error passing candidate', error)
    }
  }

  const sendReminderEmail = async test => {
    const data = {
      email: candidate?.user?.email,
      assessment_link: test?.test_link,
      candidate_name: `${candidate?.user?.first_name} ${candidate?.user?.last_name}`,
      company_name: companyData?.name,
      test_name: test?.name,
    }
    const response = await sendPendingTestReminderEmail(data)
    if (response?.status === 200) {
      toastMessage('Email sent successfully!', 'success')
    } else {
      toastMessage('Something went wrong!', 'error')
    }
  }

  const onHandleSubmit = async e => {
    try {
      const data = {
        search_history_id: searchId,
        profile_status: 'passed',
        user_id: candidate?.user?.id,
        company_id: companyData?.id,
        profile_feedback: selectedReason,
        user_name: `${candidate?.user?.first_name} ${candidate?.user?.last_name}`,
        company_name: companyData?.name,
      }
      const response = await setProfileStatus(data)
      if (response?.status === 200) {
        setCandidateProfileStatus('passed')
        setCandidateStatusUpdate(true)
      }
    } catch (error) {
      console.error('Error passing candidate', error)
    }
  }

  const handleRemoveOfferLetter = async attachmentId => {
    try {
      const response = await removeOfferLetter({
        user_id: candidate?.user?.id,
        company_id: companyData?.id,
        attachment_id: attachmentId,
      })
      if (response?.status === 200) {
        const updatedOfferLetters = offerLetters.filter(
          letter => letter.id !== attachmentId,
        )
        setOfferLetters(updatedOfferLetters)
      }
    } catch (error) {
      console.error('Error removing offer letter:', error)
    }
  }

  return (
    <>
      <Modal
        show={openModal}
        size="6xl"
        onClose={() => {
          handleCloseModal()
        }}
        className={`flex justify-center items-center`}
        popup
      >
        <Modal.Header />
        <Modal.Body
          className={`grid lg:grid-cols-2 gap-4 h-auto overflow-y-hidden`}
        >
          <div
            className={`flex flex-col font-satoshi lg:w-full h-auto overflow-y-scroll max-h-[90vh]`}
          >
            <CandidateProfile
              candidate={candidate}
              fullName={fullName}
              isPipelineView={true}
              roundPercentage={roundPercentage}
            />
            <p className="md:text-lg text-sm text-gray-600 mt-4">
              Worked in{' '}
              <span className="font-semibold text-gray-900">
                {candidate?.user?.work_histories.find(wh => wh.is_working)
                  ?.company_name ||
                  candidate?.user?.work_histories[0]?.company_name}
              </span>{' '}
              as{' '}
              <span className="font-semibold text-blue-500">
                {candidate?.user?.work_histories.find(wh => wh.is_working)
                  ?.job_title || candidate?.user?.work_histories[0]?.job_title}
              </span>
            </p>

            <div className="mt-3">
              <button
                onClick={() => handlenavigate()}
                className="flex justify-center px-2 py-2 rounded-md border bg-[#1A56DB] w-52 items-center"
              >
                <MessagesIcon className="mr-2" fill="#ffffff" />
                <span className="text-white text-14 font-medium leading-5">
                  Chat with Candidate
                </span>
              </button>
              {openInbox && <PersistentInbox />}
            </div>
            <hr className="mt-4 special-lg:-mb-5" />
            <div className="transform scale-10 md:scale-70 md: text-blue-500 my-3">
              <RadialBar
                className="scale-10"
                series={[
                  candidate?.expertise_match_score,
                  candidate?.job_preference_match_score,
                ]}
                legendXoffset={16}
                legendYoffset={5}
                legendFontSize={10}
                radialXoffset={5}
                trackMargin={3}
              />
            </div>
            <hr className="mb-4" />
            <div className="p-4">
              <div className="grid md:grid-cols-2 gap-4">
                <CertificationsList
                  certifications={candidate?.user?.certifications}
                  showMoreCertifications={showMoreCertifications}
                  setShowMoreCertifications={setShowMoreCertifications}
                />
              </div>
              <hr className="my-4" />
              <ExperienceTimeline
                workHistories={candidate?.user?.work_histories}
                isPipelineView={true}
              />
            </div>
          </div>
          <div
            className={`flex flex-col font-satoshi h-auto max-h-[90vh] sticky top-0`}
          >
            <h3 className="text-xl text-[#111928] font-semibold mb-4">
              Candidate Pipeline
            </h3>
            <div className="sm:p-6 lg:bg-[#F9FAFB] rounded-lg overflow-auto h-full">
              <CompanyActionInfo
                action={contactInfo.action}
                actionDate={contactInfo.actionDate}
                searchName={contactInfo.searchName}
                candidateName={contactInfo.candidateName}
                testName={contactInfo.testName}
                score={contactInfo.score}
                decision={contactInfo.decision}
              />
              <DecisionButtons
                currentUser={currentUser}
                selectedChat={selectedChat}
                handleHireCandidate={handleSetHired}
                handleSetPassed={() => setPassed(true)}
                handleSetOffered={handleSetOffered}
                profileStatus={candidateProfileStatus}
                offerLetter={offerLetters}
                handleChangeDecision={handleChangeDecision}
                setOfferLetter={setOfferLetters}
                handleRemoveOfferLetter={handleRemoveOfferLetter}
              />
              <TestResults
                tests={candidateTests}
                handleSendReminderEmail={sendReminderEmail}
              />
              <AdditionalActions
                moreTests={moreTests}
                setMoreTests={setMoreTests}
                tests={tests}
                onSelectTest={onSelectTest}
                moreCertificateVerification={moreCertificateVerification}
                setMoreCertificateVerification={setMoreCertificateVerification}
                onSelectCertificate={onSelectCertificate}
                certifications={candidate?.user?.certifications}
                handleSubmit={handleSubmit}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <CongratulationsModal
        openModal={showCongratulationsModal}
        setOpenModal={setShowCongratulationsModal}
        isOfferModal={true}
      />
      <ArchiveModal
        show={showArchiveModal}
        setShowModal={setshowArchiveModal}
        searchID={searchId}
      />
      <NotAMatchForm
        openModal={passed}
        setOpenModal={setPassed}
        setSelectedReason={setSelectedReason}
        selectedReason={selectedReason}
        handleSetPassed={onHandleSubmit}
        isPipelineView
      />
      {successModalOpen && (
        <EmailSentModal
          successModalOpen={successModalOpen}
          setSuccessModalOpen={setSuccessModalOpen}
          ContactMode={'email'}
          message={`You just shared candidate test/tests test via`}
        />
      )}
    </>
  )
}
