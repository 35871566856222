import React from 'react'
import Chart from 'react-apexcharts'
import { roundPercentage } from 'utilis/helpers'

const RadialBar = ({ series, legendXoffset=0, legendYoffset=10, legendFontSize="14px", radialXoffset=0, trackMargin=5}) => {
  const options = {
    colors: ['#1C64F2', '#16BDCA'],
    labels: ['Skills', 'Preference'],
    legend: {
      show: true,
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
      position: 'right',
      floating: false,
      fontSize: legendFontSize,
      offsetX: legendXoffset,
      offsetY: legendYoffset,
    },
    plotOptions: {
      radialBar: {
        size: undefined,
        inverseOrder: false,
        startAngle: 0,
        endAngle: 360,
        offsetX: radialXoffset,
        offsetY: 0,
        hollow: {
          margin: 5,
          size: '25%',
          background: 'transparent',
          image: undefined,
          imageWidth: 100,
          imageHeight: 100,
          imageOffsetX: 0,
          imageOffsetY: 0,
          imageClipped: true,
          position: 'front',
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: '#E6E7EB',
          strokeWidth: '95%',
          opacity: 1,
          margin: trackMargin,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        dataLabels: {
          show: false,
          name: {
            show: false,
            fontSize: '12px',
            fontFamily: undefined,
            color: undefined,
            offsetY: -10,
          },
          value: {
            show: true,
            fontSize: '12px',
            fontFamily: undefined,
            color: undefined,
            offsetY: 16,
            formatter: function (val) {
              return val + '%'
            },
          },
          total: {
            show: false,
            label: 'Match Score',
            color: '#373d3f',
            formatter: function (w) {
              return (
                w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0) /
                  w.globals.series.length +
                '%'
              )
            },
          },
        },
      },
    },
  }

  return (
    <div className="flex flex-row mt-1 items-center px-4">
      <div className="basis-1/4">
        <p className="text-[14px] font-medium text-[#6B7280]">Skill Match</p>
        <h1 className="text-[24px] font-bold text-[#111928]">
          {roundPercentage(series[0])}
        </h1>
      </div>
      <div className="w-1/2 mt-2">
        <Chart
          options={options}
          series={series}
          type="radialBar"
          width="100%"
        />
      </div>
      <div className="basis-1/4">
        <p className="text-[14px] font-medium text-[#6B7280] ">
          Preference Match
        </p>
        <h1 className="text-[24px] font-bold text-[#111928]">
          {roundPercentage(series[1])}
        </h1>
      </div>
    </div>
  )
}

export default RadialBar
