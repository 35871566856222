import React from 'react'
import moment from 'moment'
import StatusButton from 'components/generics/StatusButton'
import BellActiveOutlineIcon from 'components/generics/Icons/BellActiveOutlineIcon'

const TestResults = ({ tests, handleSendReminderEmail }) => {
  const isFiveDaysAgo = test =>
    moment().diff(moment(test?.sent_date), 'days') >= 5

  const showReminder = test =>
    isFiveDaysAgo(test) && test?.test_status !== 'completed'

  return (
    <div>
      <p className="text-gray-900 font-medium text-14 leading-5 mt-6">
        Quali Test Results
      </p>
      <div className="flex gap-3 flex-col max-h-[344px] overflow-auto mt-3">
        {tests?.length > 0 ? (
          tests.map((test, index) => (
            <div
              className={`flex p-4 items-center flex-shrink-0 rounded-lg shadow-md w-full bg-white ${index === tests.length - 1 ? 'mb-4' : ''}`}
              key={index}
            >
              <div className="flex flex-row w-full justify-between">
                <div className="flex flex-col leading-6">
                  <p className="text-14 text-gray-900 font-medium">
                    {test?.title}
                  </p>
                  <p className="text-12 text-gray-500 font-medium leading-6">
                    {'Sent ' +
                      moment.utc(test?.sent_date)?.format('D MMM YYYY')}
                  </p>
                  <p className="text-12 text-primary-500 font-medium leading-6">
                    Score{' '}
                    {test?.test_status === 'completed' && test?.test_score
                      ? `${test?.test_score}%`
                      : 'N/A'}
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <StatusButton status={test?.test_status} />
                  {showReminder(test) && (
                    <button
                      onClick={() => {
                        handleSendReminderEmail(test)
                      }}
                      className="px-2 gap-1 flex items-center justify-center text-primary-700 lg:text-sm text-s rounded border border-primary-700 focus:outline-none focus:border-primary-700 h-6"
                    >
                      <BellActiveOutlineIcon />
                      <span className="text-12 font-medium">Remind</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-14 text-gray-500 font-medium mb-8">
            No Quali Test taken, yet.
          </p>
        )}
      </div>
    </div>
  )
}

export default TestResults
