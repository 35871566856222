import React, { useState, useEffect, useCallback } from 'react'
import { debounce } from 'lodash'
import searchIcon from 'assets/svgs/searchicon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as ChevronArrow } from '../../assets/svgs/angle-down-outline.svg'
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { excludedColumns } from '../../utilis/helpers/tableHelper'
import { isMobileDevice, getStatusText, getStatusColor } from 'utilis/helpers'
import PaginationFooter from './PaginationFooter'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  selectCurrentUser,
} from '../../redux/currentUserSlice'
import { Tooltip } from 'flowbite-react'
import TableFilters from './TableFilters'
import BriefcaseIcon from '../../assets/svgs/briefcase.svg'
import Loader from 'components/generics/loader/Loader'

const Table = ({
  columns,
  sortState,
  handleSort,
  data,
  onSearch,
  currentPage,
  totalPages,
  totalCount,
  setCurrentPage,
  handleCompareAction,
  onFilterChange,
  isLoading,
  handleViewProgress
}) => {
  const location = useLocation()
  const [sortstate, setsortstate] = useState(sortState || 'none')
  const [searchQuery, setSearchQuery] = useState('')
  const { currentUser } = useSelector(selectCurrentUser)
  const isMobile = isMobileDevice()
  const [selectedRows, setSelectedRows] = useState([])
  const [shortlist, setShortlist] = useState([])
  const [disableButton, setDisableButton] = useState(true)

  const handleCheckboxChange = (item) => {
    setSelectedRows(prev => {
      const isSelected = prev.some(selectedItem => JSON.stringify(selectedItem) === JSON.stringify(item))
      if (isSelected) {
        return prev.filter(selectedItem => JSON.stringify(selectedItem) !== JSON.stringify(item))
      } else if (prev.length < 3) {
        return [...prev, item]
      } else {
        alert("Choose your top three hires and decide which ones you want to keep for interviews. You can easily reject a candidate and add a new candidate.")
        return prev
      }
    })
  }

  const isCheckboxDisabled = (item) => {
    return !selectedRows.some(selectedItem => JSON.stringify(selectedItem) === JSON.stringify(item)) && selectedRows.length >= 3
  }

  useEffect(() => {
    setDisableButton(!(selectedRows.length >= 2 && selectedRows.length <= 3))
  }, [selectedRows])

  const handleSortChange = (column, order) => {
    handleSort(order, column)
  }

  const handleFilterChange = (e) => {
    const value = e.target.value
    setsortstate(value)
    onFilterChange(value)
  }

  const debouncedSearch = useCallback(
    debounce((value) => {
      setCurrentPage(1)
      onSearch(value)
    }, 300),
    [onSearch]
  )

  const handleSearchChange = (e) => {
    const value = e.target.value
    setSearchQuery(value)
    debouncedSearch(value)
  }


  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  const handlePageClick = page => {
    setCurrentPage && setCurrentPage(page)
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage && setCurrentPage(currentPage - 1)
    }
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage && setCurrentPage(currentPage + 1)
    }
  }

  return (
    <div className={`relative overflow-x-scroll md:overflow-x-auto shadow-md sm:rounded-lg ${currentUser?.role === 'super_admin' ? "mt-10 ml-5" : "mt-5 bg-white ml-0 overflow-y-hidden h-auto"} mr-4`}>
      <TableFilters
        searchQuery={searchQuery}
        handleSearchChange={handleSearchChange}
        sortstate={sortstate}
        handleFilterChange={handleFilterChange}
        disableButton={disableButton}
        handleCompareAction={handleCompareAction}
        selectedRows={selectedRows}
      />
      {isLoading ?
      <div className='mb-10 mt-5'>
        <Loader /> 
      </div>
      : (
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-500 uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <tr scope='col' className='py-4 px-3'>
                <span >
                </span>
              </tr>
              {columns.map(
                (column, index) =>
                  !excludedColumns.includes(column.header) && (
                    <th key={index} scope="col" className={`${isMobile ? 'md:px-6 py-3 md:pr-0 md:pl-0 pl-2 px-2 pr-20' : 'py-3'}`}>
                      <div className="flex items-center mb-4">
                        <span>{column.header}</span>
                        {column.field !== 'candidate_name' && column.field !== 'from_search' && (
                          <div className="flex flex-col">
                            <button
                              onClick={() => handleSortChange(column.field, 'asc')}
                              className="flex items-center justify-center"
                            >
                              <ChevronArrow className="rotate-180 ml-2 w-2 h-2" />
                            </button>
                            <button
                              onClick={() => handleSortChange(column.field, 'desc')}
                              className="flex items-center justify-center"
                            >
                              <ChevronArrow className="ml-2 w-2 h-2" />
                            </button>
                          </div>
                        )}
                      </div>
                    </th>
                  ),
              )}
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan={columns.length} className="py-4 text-center text-gray-500">
                  <div className="flex flex-col items-center justify-center space-y-2">
                    <p className="text-xl font-medium py-16">No candidates found</p>
                  </div>
                </td>
              </tr>
            ) : (
              data?.map((item, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td>
                    <div className='ml-5'>
                      {getStatusText(item['status']) === 'In Progress' && (<input
                        type="checkbox"
                        checked={selectedRows.some(selectedItem => JSON.stringify(selectedItem) === JSON.stringify(item))}
                        onChange={() => handleCheckboxChange(item)}
                        disabled={isCheckboxDisabled(item)}
                        className={`rounded border border-gray-300 ${isCheckboxDisabled(item)
                            ? selectedRows.some(selectedItem => JSON.stringify(selectedItem) === JSON.stringify(item))
                              ? 'bg-blue-500 border-blue-500'
                              : 'bg-gray-200'
                            : selectedRows.some(selectedItem => JSON.stringify(selectedItem) === JSON.stringify(item))
                              ? 'bg-blue-500 border-blue-500'
                              : 'bg-gray-50'
                          }`}
                      />
                      )}
                    </div>
                  </td>
                  {columns?.map(
                    (column, idx) =>
                      !excludedColumns?.includes(column.header) && (
                        <td
                          key={idx}
                          className="py-4 text-gray-900 break-words"
                        >
                          <div className="flex items-center space-x-2">
                            {column.field === 'status' && (
                              <div
                                className={`w-2.5 h-2.5 rounded-full ${getStatusColor(item[column.field])}`}
                              ></div>
                            )}
                            <div className={`md:ml-0 text-14 ${column.field === 'from_search' ? 'text-blue-500' : 'md:text-black text-gray-700'}`}>
                              {column.field === 'status' ? (
                                getStatusText(item[column.field])
                              ) : column.field === 'from_search' ? (
                                <span className="font-medium">{item[column.field]}</span>
                              ) : column.render ? (
                                column.render(item[column.field])
                              ) : (
                                item[column.field]
                              )}
                            </div>
                          </div>
                        </td>
                      )
                  )}
                  <td>
                    <div>
                      <button
                        type="submit"
                        className="bg-blue-700 mb-2 md:text-sm text-xs mt-1 py-2 px-4 rounded-lg text-white flex items-center"
                        onClick={() => handleViewProgress(item?.pipeline_id)}
                      >
                        <img
                          src={BriefcaseIcon}
                          alt="Briefcase"
                          className="w-4 h-4 mr-2"
                        />
                        View Progress
                      </button>
                    </div>
                  </td>
                </tr>
              )))}

          </tbody>
        </table>
      )}
      {totalCount > 10 && <PaginationFooter
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        handlePageClick={handlePageClick}
        data={data}
      />
      }
    </div>
  )
}

export default Table
