import React from 'react'
import { useState } from 'react'
import { Button, Modal } from 'flowbite-react'
import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/currentUserSlice'
import { logoutUser } from '../../lib/api'
import { useDispatch } from 'react-redux'
import useToastMessage from '../../hooks/useToastMessage'
import { ReactComponent as TestAssessmentsIcon } from '../../assets/test-assessments-icon.svg'
import { ReactComponent as RedLogoutIcon } from '../../assets/red-logout-icon.svg'
import { ReactComponent as FilledStarIcon } from '../../assets/filled-star-icon.svg'
import { ReactComponent as UserProfileSettings } from '../../assets/svgs/user-profile-settings.svg'
import { ReactComponent as InboxMessageIcon } from 'assets/svgs/inbox-messages-outline.svg'
import { InitialLetters } from '../generics/Initials'
import { TruncatedText } from 'utilis/helpers'
export const CandidateAppSideBar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const toastMessage = useToastMessage()
  const { currentUser } = useSelector(selectCurrentUser)
  const location = useLocation()
  const pathname = location.pathname

  const [logoutModalOpen, setLogoutModalOpen] = useState(false)
  const handleLogoutClick = () => {
    setLogoutModalOpen(true)
  }

  const handleLogoutConfirmed = () => {
    setLogoutModalOpen(false)
    const isCandidate = currentUser?.role === 'candidate'
    logoutUser(dispatch, navigate, toastMessage, false, isCandidate)
  }

  return (
    <div className="flex flex-col h-fit items-center ml-8 p-4 bg-white shadow-md rounded-lg w-48 md:w-60 mb-4 md:mb-0">
      <div className="border-b border-gray w-full">
        <div className="flex flex-row items-center p-1.5">
          {currentUser && currentUser.profile_picture_url ? (
            <img
              src={currentUser.profile_picture_url}
              alt="User Logo"
              className="rounded-full mb-2 w-20 h-20 md:w-12 md:h-12"
            />
          ) : (
            <div className="flex justify-center items-center p-5 rounded-full text-2xl bg-gray-100 border-gray-100 h-20 w-20 md:h-12 md:w-12 mb-6">
              <InitialLetters
                Name={`${currentUser?.first_name} ${currentUser?.last_name}`}
              />
            </div>
          )}

          <div className="flex flex-col ml-4">
            <p className="text-md font-bold text-gray-900 max-w-32 text-wrap break-all">
              <TruncatedText
                text={`${currentUser.first_name} ${currentUser.last_name}`}
                limit={50}
              />
            </p>
            <div className="border border-blue-600 flex justify-center items-center rounded-md w-10/12 mt-2">
              <Button
                outline
                size="xs"
                className=" bold  text-[#1a56db] font-semibold w-full max-w-[200px] rounded-[4px] border-sm "
                color={'white'}
                onClick={() => {
                  navigate('/candidate-account-overview')
                }}
              >
                <p className="text-blue-700 text-sm font-medium whitespace-nowrap ">
                  Edit Profile{' '}
                </p>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 w-full">
        <ul className="space-y-2">
          <Link to="/overview">
            <li
              className={`flex items-center space-x-2 p-2 rounded-lg ${pathname === '/overview' && ' bg-blue-100'}`}
            >
              <span className="material-icons">
                <FilledStarIcon />
              </span>
              <span>Overview</span>
            </li>
          </Link>
          <Link to="/candidate-account-overview">
            <li
              className={`flex items-center space-x-2 p-2 rounded-lg ${pathname === '/candidate-account-overview' && ' bg-blue-100'}`}
            >
              <span className="material-icons">
                <UserProfileSettings />
              </span>
              <span>Quali Profile</span>
            </li>
          </Link>
          <Link to="/test-list-of-candidiate">
            <div className="border-b border-gray w-full">
              <li
                className={`flex justify-between items-center p-2 rounded-lg mb-6 ${(pathname === '/search' || pathname === '/search-results' || pathname === '/test-list-of-candidiate') && ' bg-blue-100'}`}
              >
                <span className="flex items-center space-x-2">
                  <TestAssessmentsIcon />
                  <span>Quali Test</span>
                </span>
                <div className="flex justify-between">
                  <span className="bg-white text-blue-600 text-sm px-2 rounded-full">
                    {currentUser.pending_test_count}
                  </span>
                </div>
              </li>
            </div>
          </Link>
          <Link to="/my-inbox">
            <li
              className={`flex justify-between items-center p-2 rounded-lg mt-6 ${pathname === '/my-inbox' && ' bg-blue-100'}`}
            >
              <span className="flex items-center space-x-2">
                <InboxMessageIcon />
                <span>My Inbox</span>
              </span>
              <div className="flex justify-between">
                <span className="bg-white text-blue-600 text-sm px-2 rounded-full">
                  {currentUser.unread_chats}
                </span>
              </div>
            </li>
          </Link>
          <li className="flex items-center space-x-2 text-red-600 p-2">
            <span className="material-icons">
              <RedLogoutIcon />
            </span>
            <div>
              <button onClick={handleLogoutClick}>Log out</button>
            </div>
          </li>
        </ul>
      </div>
      <Modal
        show={logoutModalOpen}
        size="md"
        onClose={() => setLogoutModalOpen(false)}
        popup
        className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-50"
      >
        <div className="p-8 bg-white rounded shadow-md">
          <p className="text-2xl text-center font-bold mb-4">
            Log Out Confirmation
          </p>
          <p className="text-lg text-center mb-8">
            Are you sure you want to log out?
          </p>
          <div className="flex  justify-end">
            <Button
              onClick={() => setLogoutModalOpen(false)}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 mr-2 rounded"
            >
              Cancel
            </Button>
            <Button
              onClick={handleLogoutConfirmed}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mr-14 rounded "
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
