import React from 'react'
import { Modal, Button } from 'flowbite-react'

const DeleteActionModal = ({title, isModalOpenForDelete, setIsModalOpenForDelete, onDelete}) => {
  return (
    <Modal
      show={isModalOpenForDelete}
      onClose={() => setIsModalOpenForDelete(false)}
      className="font-global-styling"
      theme={{
        content: {
          base: 'bg-transparent',
        },
      }}
    >
      <Modal.Header>
        <div className="mx-7 text-[#111928] font-bold ">
          Delete this {title}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="px-5 mx-2 text-lg text-[#374151] text-center font-medium">
          Are you sure you want to delete this {title}?
          <div className="flex justify-center mt-7">
            <Button
              className="py-2"
              onClick={event => {
                onDelete()
              }}
              type="submit"
              color={'blue'}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteActionModal
