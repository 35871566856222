import axiosInstance from './axiosConfig'
import { SkillsEndpoints } from './constants'

export const getSkills = async queryParams => {
  const response = await axiosInstance.get(
    `${SkillsEndpoints.getSkills}?${queryParams}`,
  )
  return response
}

export const allSkills = async () => {
  const response = await axiosInstance.get(SkillsEndpoints.allSkills)
  return response
}

export const editSkill = async data => {
  const response = await axiosInstance.patch(
    SkillsEndpoints.editSkill(data?.id),
    { skills: data },
  )
  return response
}

export const createSkill = async data => {
  const response = await axiosInstance.post(SkillsEndpoints.allSkills, {
    skills: data,
  })
  return response
}

export const deleteSkill = async id => {
  const response = await axiosInstance.delete(SkillsEndpoints.deleteSkill(id))
  return response
}

export const getPaginatedSkillsList = async (page, query) => {
  const response = await axiosInstance.get(
    SkillsEndpoints.getPaginatedSkills(page, query),
  )
  return response
}
