import React from 'react'
import { useSelector } from 'react-redux'
import Toast from './Toast'
import { isSmallMobileDevice } from 'utilis/helpers'

const ToastContainer = () => {
  const isSmallMobile = isSmallMobileDevice()
  const toasts = useSelector(state => state.toast)

  return (
    <div
      className={`fixed top-10 ${!isSmallMobile ? 'right-10' : 'right-1'} space-y-2 z-50`}
    >
      {(toasts || []).map(toast => (
        <Toast key={toast.id} message={toast.message} type={toast.type} />
      ))}
    </div>
  )
}

export default ToastContainer
