import React, { useEffect, useRef, useState } from 'react'
import paperclipicon from 'assets/svgs/paper-clip.svg'
import { SelfChatTextBubble } from './SelfChatTextBubble'
import { InitialLetters } from 'components/generics/Initials'
import { ClientChatTextBubble } from './ClientChatTextBubble'
import {
  fetchPreviousConversation,
  startConversation,
} from 'services/messagingService'
import { useDispatch, useSelector } from 'react-redux'
import { addMessage, selectIsTyping, selectMessages, selectTypingStatus, setTypingStatus } from '../../../../redux/messageSlice'
import { formatCandidateData, getBodyForAttachment } from 'utilis/helpers'
import { getRevealedCandidatesForCompany } from 'services/userService'
import { setRevealedCandidates } from '../../../../redux/talentSearchSlice'

export const CandidateOpenMessageView = ({
  avatar,
  chatName,
  selectedChat,
  hide = false,
  isChatExpanded = false,
  setSelectedConversation,
  selectedConversation,
  currentUser,
  setCandidateStatusUpdate,
  handleSetstatus,
}) => {
  const [message, setMessage] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const [sortedMessages, setSortedMessages] = useState(null)
  const dispatch = useDispatch()
  const previousConversation = useSelector(selectMessages)
  const fileInputRef = useRef(null)
  const isTyping = useSelector(selectIsTyping)
  const typingTimeoutRef = useRef(null)
  const [showDecisionButtons , setShowDecisionButtons] = useState(false)
  const typingStatus = useSelector(selectTypingStatus);
  const senderIsTyping = typingStatus?.[selectedChat?.user?.id];
  const recipientId =
    selectedChat?.user?.id || selectedChat?.users?.[0]?.user_id
  const userName = selectedChat?.user ? `${selectedChat?.user?.first_name} ${selectedChat?.user?.last_name}` : selectedChat?.company_name;
  const messagesEndRef = useRef(null)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }
  useEffect(() => {
    scrollToBottom();
  }, [sortedMessages]);

  useEffect(() => {
    recipientId && getMessages()
  }, [selectedChat])

  const getMessages = async () => {
    try {
      const response = await fetchPreviousConversation(recipientId)
      if (response?.status === 200) {
        dispatch(addMessage(response?.data))
      }
    } catch (error) {
      dispatch(addMessage([]))
      setSortedMessages([])
      setSelectedConversation?.([])
    }
  }

  const handleMessageChange = event => {
    setMessage(event?.target?.value)
    
    dispatch(setTypingStatus({ isTyping: true, partnerId: recipientId }))
    
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      dispatch(setTypingStatus({ isTyping: false, partnerId: recipientId }))
    }, 2000)
  }

  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current)
      }
    }
  }, [])

  const handleSendMessage = async () => {
    if (message.trim() || selectedFile) {
      const formData = new FormData()
      formData.append(
        'recipient_id',
        selectedChat?.user?.id || selectedChat?.users?.[0]?.user_id,
      )
      formData.append('subject', 'Chat Message')
      formData.append('body', message)
      if (selectedFile) {
        formData.append(`attachment`, selectedFile)
        const receiverName = `${selectedChat?.user?.first_name ?? ''} ${selectedChat?.user?.last_name ?? ''}`?.trim();
        const companyName = selectedChat?.company?.name
        let body ='';
        if(currentUser.role === 'company_admin'){
           body = getBodyForAttachment(companyName, receiverName,currentUser, message)
           handleSetstatus('offered')
           setCandidateStatusUpdate(true)
           setShowDecisionButtons(true)
        }
        else{
          body = getBodyForAttachment(currentUser?.first_name, `${selectedChat?.user?.first_name ?? ''} ${selectedChat?.user?.last_name ?? ''}`?.trim(),currentUser, message)
        }
        formData.append('body', body)
      }
      
      try {
        const response = await startConversation(formData)
        if (response.status === 201) {
          setMessage('')
          setSelectedFile(null)
          // Optionally, clear the file input
          currentUser?.role === 'company_admin' && getRevealedCandidates()
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
        }
      } catch (error) {
        console.error('Error sending message:', error)
      }
    }
  }

  const handleFileSelect = event => {
    const file = event.target.files[0]
    if (file && file.type === 'application/pdf') {
      setSelectedFile(file)
    } else {
      alert('Please select a PDF file.')
    }
  }

  const handlePaperclipClick = () => {
    fileInputRef.current.click()
  }

  const getRevealedCandidates = async () => {
    const response = await getRevealedCandidatesForCompany()
    const { data, status } = response
    if (status === 200) {
      const formattedDataArray = formatCandidateData(data)
      dispatch(setRevealedCandidates(formattedDataArray || []))
    }
  }

  useEffect(() => {
    if (Array.isArray(previousConversation) && previousConversation.length > 0) {
      const messages = previousConversation
        .flatMap(conversation => conversation?.messages || [])
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
      setSortedMessages(messages)
      setSelectedConversation?.(messages)
    }
    else {
      setSortedMessages([])
      setSelectedConversation?.([])
    }
  }, [previousConversation, setSelectedConversation])

  return (
    <div
      className={`flex flex-col ${!hide ? 'max-w-[484px] min-w-[484px]' : ''} border-r border-l border-gray-200 bg-white h-full`}
    >
      {!hide && (
        <div className="header-1 border-b border-gray-200 flex flex-row gap-2 items-baseline w-full p-4">
          <div className="logo">
            {(currentUser.role==='candidate' && avatar)  || (currentUser.role==='company_admin' && selectedChat?.user?.profile_picture_url) ? (
                <img
                  src={currentUser.role==='company_admin' ? selectedChat?.user?.profile_picture_url : avatar}
                  alt="User Logo"
                  className="rounded-full w-8 h-8 md:w-8 md:h-8"
                />
              ) : (
                <div className="flex justify-center items-center p-5 rounded-full text-lg bg-gray-100 border-gray-100 h-8 w-8 md:h-8 md:w-8">
                  <InitialLetters
                    Name={userName}
                  />
                </div>
            )}
          </div>
          <span className="text-base font-medium">
            {userName}
          </span>
        </div>
      )}
      <div
        className={`chat-place bg-[#F9FAFB] overflow-y-auto h-96 ${!hide ? 'max-h-[600px] min-h-[600px]' : isChatExpanded ? 'max-h-[500px] min-h-[500px]' : 'max-h-[300px] min-h-[300px]'}`}
      >
 <div className="flex flex-col p-4 gap-4 ">
      {sortedMessages?.map((message) => {
        const isSelf = message.sender_id === recipientId;
        const MessageBubble = isSelf ? ClientChatTextBubble :SelfChatTextBubble ;
        return (
          <MessageBubble
            key={message.id}
            name={
              isSelf
                ? selectedChat?.user?.first_name
                  ? `${selectedChat?.user?.first_name} ${selectedChat?.user?.last_name}`
                  : selectedChat?.company_name
                : chatName
            }
            time={new Date(message.created_at).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
            messageText={message.body}
            userAvatar={
              currentUser.role === "company_admin"
                ? currentUser?.company?.logo_url
                : currentUser?.profile_picture_url
            }
            isMessageFile={message?.attachments?.url}
            attachment={message?.attachments}
            selectedChat={selectedChat}
          />
        );
      })}
      {/* This empty div ensures that we can scroll to the bottom */}
      <div ref={messagesEndRef} />
    </div>
      </div>
      <div className="flex flex-col w-full">
        <div className="border-t border-b border-gray-200">
          <textarea
            id="send-chat"
            rows="4"
            className="block border-none p-4 w-full text-sm text-gray-900 resize-none max-h-[72px]"
            placeholder="Write a reply ..."
            value={message}
            onChange={handleMessageChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) { 
                e.preventDefault()
                handleSendMessage()
              }
            }}
            name="send-chat"
            maxLength={300}
          ></textarea>
        </div>
        <div className="flex flex-row p-4 items-center justify-between border-b border-gray-200">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept=".pdf"
            className="hidden"
            />
          <img
            src={paperclipicon}
            alt="File icon"
            className="rounded-full w-4 h-4 md:w-4 md:h-4 cursor-pointer"
            onClick={handlePaperclipClick}
          />
          {selectedFile && <span className="text-xs">{selectedFile.name}</span>}
          <button
            type="button"
            onClick={handleSendMessage}
            className={`text-xs font-medium px-3 py-2 rounded-lg mb-2 lg:mb-0 min-w-[80px] bg-[#1A56DB] text-white border`}
          >
            Send message
          </button>
        </div>
      </div>
    </div>
  )
}
