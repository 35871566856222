import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectCurrentUser } from '../../../redux/currentUserSlice'
import { editCandidateTest } from '../../../services/manageTestService'
import { Link, useLocation } from 'react-router-dom'
import TestCard from './TestCard'
import { getCurrentUser } from 'services/userService'
import { setCurrentUser } from '../../../redux/currentUserSlice'
import { Loader } from '../../../utilis/AppLoader' 

const QualiTest = ({ data, handleCompleteTest }) => {
  const { currentUser } = useSelector(selectCurrentUser)
  const dispatch = useDispatch()
  const location = useLocation()
  const isCandidate = currentUser.role === 'candidate'
  const isOverview = location.pathname === '/overview'
  const [tests, setTests] = useState(data.tests || data.test || [])
  const [testUpdate, setTestUpdate] = useState(false)
  const [offButton, setOffButton] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    setTests(data.tests || data.test || [])
    if (tests.length === 0) {
      setOffButton(false)
    } else {
      setOffButton(true)
    }
    setIsLoading(false)
  }, [data, testUpdate, offButton])

  const renderTests = () => {
    if (isOverview && currentUser.role === 'candidate') {
      const activeTests = tests.filter(test => test.status === 'sent')
      return activeTests.length === 0 ? (
        <div className="flex items-center justify-center py-3 md:py-12 h-full w-full">
          {currentUser.role !== 'candidate' ? (
            <p className="text-gray-500 mb-6 text-2xl">No Active Tests</p>
          ) : (
            <>
              <div className="text-center">
                <p className="text-gray-600 text-14 font-normal">
                  You currently have no invitation to do Quali Test.
                </p>
                <p className="text-gray-500 text-14 font-normal">
                  Check Quali regularly to make sure you don’t miss out on
                  anything!
                </p>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
          {activeTests.slice(0, 3).map(test => (
            <TestCard
              key={test.id}
              test={test}
              currentUser={currentUser}
              isOverview={isOverview}
              handleCompleteTest={handleCompleteTest}
            />
          ))}
        </div>
      )
    }
    return (isOverview && data?.tests?.length === 0) || (!isOverview && data?.test_sent === 0) ? (
      <div className="flex items-center justify-center h-full w-full">
        {currentUser.role !== 'candidate' &&
          <p className="text-gray-500 mb-6 text-2xl">No Test Found</p>
        }
      </div>
    ) : (
      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
        {tests.map(test => (
          <TestCard
            key={test.id}
            test={test}
            currentUser={currentUser}
            isOverview={isOverview}
            handleCompleteTest={handleCompleteTest}
          />
        ))}
      </div>
    )
  }

  return (
    <div className="mx-2 sm:ml-8 mt-4 sm:mr-5 md:p-0 p-2">
      <div className="sm:mt-4 flex items-center justify-between mb-2">
        <div className="flex flex-col">
          <h1
            className={`mb-1 ${isOverview ? 'text-20' : 'text-xl'} text-[#111928] font-semibold`}
          >
            {isOverview ? 'Quali Test' : 'Quali Test and Assessment'}
          </h1>
          {isOverview && currentUser.role === 'candidate' && (
            <span className="text-14 font-normal">
              <span className="text-gray-600">
                Multiple companies may ask you to do the same test.
              </span>
              <span className="text-gray-500 ml-2">
                Increase your chance to be hired by completing these tests.
              </span>
            </span>
          )}
        </div>
        {isOverview ? (
          <Link to={!isCandidate ? '#' : '/test-list-of-candidiate'}>
            {isLoading ? (
              <div className="w-24 h-10 flex items-center justify-center">
                <Loader size="small" color="blue" />
              </div>
            ) : (
              tests.length !== 0 && (
                <button className="md:text-sm text-xs bg-white px-4 py-2 mb-9 text-blue-700 rounded-lg font-bold">
                  View All
                </button>
              )
            )}
          </Link>
        ) : (
          location.pathname !== '/all-quali-tests' && (
            <Link to="/all-quali-tests">
              {isLoading ? (
                <div className="w-24 h-10 flex items-center justify-center">
                  <Loader size="small" color="blue" />
                </div>
              ) : (
                tests.length !== 0 && (
                  <button className="text-sm bg-white px-4 py-2 mb-3 text-blue-700 rounded-lg font-bold">
                    Show All
                  </button>
                )
              )}
            </Link>
          )
        )}
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-40">
          <Loader size="large" color="blue" />
        </div>
      ) : (
        renderTests()
      )}
    </div>
  )
}

export default QualiTest
