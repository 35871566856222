import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Sidebar from './Sidebar'
import 'assets/css/candidate-profile.css'
import AppBar from 'components/Dashboard/AppBar'
import { PersonalDetailsForm } from 'components/candidateRegister/candidate-personal-details/PersonalDetailsForm'
import { CandidateExperienceDetails } from 'components/candidateRegister/candidate-experience-details/CandidateExperienceDetails'
import { CandidatePreferenceDetails } from 'components/candidateRegister/candidate-preference-details/CandidatePreferenceDetails'
import { selectCompany } from '../../redux/currentUserSlice'

const CompleteCandidateProfile = () => {
  const [selectedCard, setSelectedCard] = useState(1)
  const companyData = useSelector(selectCompany)

  return (
    <div className="min-h-screen w-full bg-[#F9FAFB]">
      <AppBar companyData={companyData} />
      <div className="main-container">
        <Sidebar
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
        />

        {selectedCard === 1 && (
          <PersonalDetailsForm setSelectedCard={setSelectedCard} />
        )}
        {selectedCard === 2 && (
          <CandidateExperienceDetails
            setSelectedCard={setSelectedCard}
            isSignUpView={true}
          />
        )}
        {selectedCard === 3 && (
          <CandidatePreferenceDetails
            setSelectedCard={setSelectedCard}
            isSignUpView={true}
          />
        )}
      </div>
    </div>
  )
}

export default CompleteCandidateProfile
