import React from 'react';

const MessagesIcon = ({ fill = '#1A56DB', className = '', width = 14, height = 14 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_4433_3064)">
        <path
          d="M12.6 3.4351H11.2V6.99992C11.2 7.75628 10.905 8.48166 10.3799 9.01648C9.8548 9.55131 9.14261 9.85177 8.4 9.85177H6.3L4.7922 11.0032C5.02595 11.1799 5.30892 11.276 5.6 11.2777H8.1669L10.78 13.274C10.9012 13.3666 11.0485 13.4166 11.2 13.4166C11.3857 13.4166 11.5637 13.3415 11.695 13.2078C11.8263 13.0741 11.9 12.8927 11.9 12.7036V11.2777H12.6C12.9713 11.2777 13.3274 11.1275 13.5899 10.8601C13.8525 10.5926 14 10.2299 14 9.85177V4.86103C14 4.48285 13.8525 4.12016 13.5899 3.85275C13.3274 3.58533 12.9713 3.4351 12.6 3.4351Z"
          fill={fill}
        />
        <path
          d="M8.4 0.583252H1.4C1.0287 0.583252 0.672601 0.733483 0.41005 1.0009C0.1475 1.26831 0 1.631 0 2.00918V6.99992C0 7.3781 0.1475 7.74079 0.41005 8.0082C0.672601 8.27561 1.0287 8.42584 1.4 8.42584H2.1V9.85177C2.1 9.98418 2.1362 10.114 2.20454 10.2266C2.27289 10.3392 2.37068 10.4303 2.48695 10.4895C2.60322 10.5487 2.73339 10.5737 2.86286 10.5619C2.99234 10.55 3.116 10.5016 3.22 10.4221L5.8331 8.42584H8.4C8.7713 8.42584 9.1274 8.27561 9.38995 8.0082C9.6525 7.74079 9.8 7.3781 9.8 6.99992V2.00918C9.8 1.631 9.6525 1.26831 9.38995 1.0009C9.1274 0.733483 8.7713 0.583252 8.4 0.583252Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4433_3064">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MessagesIcon;
