import React from 'react';

const UndoOutlineArrowIcon = ({ color = '#6B7280', width="18", height="17", className = '' }) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" className={className}>
  <path d="M4.88552 8.49998L1.7998 5.29998L4.88552 2.09998M4.88552 14.9H11.5712C12.7988 14.9 13.9761 14.3943 14.8441 13.4941C15.7122 12.5939 16.1998 11.373 16.1998 10.1C16.1998 8.82694 15.7122 7.60604 14.8441 6.70586C13.9761 5.80569 12.7988 5.29998 11.5712 5.29998H2.82838" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
};

export default UndoOutlineArrowIcon;
