import { Button, Card } from 'flowbite-react'
import DeleteIcon from 'assets/svgs/deleteIcon'
import modifyIcon from 'assets/svgs/modify_icon.svg'
import 'assets/css/experience-form.css'
import { isMediumMobileDevice } from 'utilis/helpers'

export const PreviousExperienceCard = ({
  userId,
  work,
  setIsModifyRequested,
  setModifyThisExperience,
  setIsDeleted,
  setDeleteThisExperience,
  setEditingIndex,
  editingIndex,
  showForm,
  isModifyRequested,
  setIsCurrentWorking,
}) => {
  const formatDateToMonthYear = dateString => {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })
  }
  const isMediumMobile = isMediumMobileDevice()
  return (
    <>
      <Card className="max-w-sm mb-2">
        <div className="flex flex-row gap-2">
          <div className="flex-grow">
            <h5
              className={`${isMediumMobile ? 'text-xl' : 'text-2xl'} font-bold tracking-tight text-gray-900 dark:text-white`}
            >
              {work.jobRole} <span className="text-gray-500 mr-2">at</span>
              {work.companyName}
            </h5>
          </div>
          <div className=" flex flex-row work-exp-cert-container">
            <div>
              <Button
                className="p-1 mr-1 mb-2 text-black hover:bg-transparent border-none shadow-none outline-none focus:outline-none focus:ring-0"
                size={'xs'}
                onClick={() => {
                  setIsModifyRequested(true)
                  setModifyThisExperience(work)
                  setEditingIndex(editingIndex)
                }}
                disabled={showForm || isModifyRequested}
              >
                <img src={modifyIcon} alt="eye" />
                <span className="ml-1 font-bold text-md">Modify</span>
              </Button>
            </div>
            <div>
              <Button
                className="p-1 mr-1 mb-2 text-black hover:bg-transparent border-none shadow-none outline-none focus:outline-none focus:ring-0"
                size={'xs'}
                onClick={async () => {
                  work.currentWorking === 'Yes' && setIsCurrentWorking(false)
                  setIsDeleted(true)
                  setDeleteThisExperience(work)
                }}
                disabled={showForm || isModifyRequested}
              >
                <DeleteIcon color="grey" />
                <span className="ml-1 font-bold text-md">Remove</span>
              </Button>
            </div>
          </div>
        </div>

        <p className="font-medium text-gray-700">
          {formatDateToMonthYear(work?.jobJoinDate)} -{' '}
          {work.currentWorking === 'Yes'
            ? 'Present'
            : formatDateToMonthYear(work?.jobEndDate)}{' '}
          <span className="mr-1 ml-1">|</span>
          {work.jobCity}, {work.jobCountry}{' '}
        </p>

        <p className="font-normal text-gray-400">
          {work.jobDescription
            ? work.jobDescription
            : 'No Description Provided'}
        </p>
      </Card>
    </>
  )
}
