import React, { useState, useEffect } from 'react'
import { Card, Button, Badge, Tooltip } from 'flowbite-react'
import { Loader } from '../../utilis/AppLoader'
import avatarImg from '../../assets/avatar.png'
import RadialBar from '../Search/MatchChart'
import {
  TruncatedText,
  formatText,
  formatPreferenceText,
} from '../../utilis/helpers'
import revealIcon from '../../assets/svgs/eye.svg'
import useToastMessage from '../../hooks/useToastMessage'
import CardFooter from './CardFooter'
import { revealProfile } from '../../services/revealProfile'
import imageSaved from '../../assets/svgs/image-1.svg'
import '../../assets/css/search-results.css'
import messagesIcon from '../../assets/svgs/messages_outline.svg'
import greyMessagesIcon from '../../assets/svgs/grey_messages_outline.svg'
import checkMarkIcon from '../../assets/svgs/completed_test.svg'
import { selectSearch } from '../../redux/searchSlice'
import { useSelector } from 'react-redux'
import StartNewSearch from 'components/Search/modals/StartNewSearch'
import whiteEyelIcon from '../../assets/svgs/white-eye.svg'
import editSearch from '../../assets/svgs/Vector.svg'
import { formatLastActive } from '../../utilis/helpers/index'

const CandidateDetailsCard = ({
  data,
  onSaveCandidate,
  isCandidateSaved,
  disableSave,
  handleOpenModal,
  isCandidateRevealed,
  fromTab,
  handleSetHired,
  handleSetPassed,
  loader,
  fromRevealProfile,
  isShowingRevealed,
  isShowingSaved,
  filterResult,
  fetchAndUpdateData,
  searchHistory,
  handleSeePipeline,
}) => {
  const toastMessage = useToastMessage()
  const [fullName, setFullName] = useState([])
  const [showMoreData, setShowMoreData] = useState({})
  const search = useSelector(selectSearch)
  const [forceRenderKey, setForceRenderKey] = useState(0)
  const truncate = (str, length) => {
    return str?.length > length ? str.substring(0, length) + '...' : str
  }
  const [openModal, setOpenModal] = useState(false)
  const allExpertises = candidate =>
    candidate?.user?.skill_expertise?.reduce((acc, skill) => {
      return acc.concat(skill.selected_expertise || [])
    }, [])

  const sortedExpertises = candidate => {
    const expertises = allExpertises(candidate)
    const matched = []
    const unmatched = []
    expertises?.forEach(expertise => {
      const isMatched = candidate?.expertises?.some(
        selectedExpertise =>
          selectedExpertise.expertise?.title === expertise &&
          selectedExpertise.matched,
      )
      if (isMatched) {
        matched.push(expertise)
      } else {
        unmatched.push(expertise)
      }
    })
    return [...matched, ...unmatched]
  }

  const getPreferencesToShow = (candidate, index) => {
    const preferences = Object.entries(candidate?.candidate_preferences || {})
    const sortedPreferences = preferences.sort(([, valueA], [, valueB]) => {
      const isPreferenceAMatched = candidate?.preferences?.some(
        pref => pref.key === valueA?.key && pref.matched,
      )
      const isPreferenceBMatched = candidate?.preferences?.some(
        pref => pref.key === valueB?.key && pref.matched,
      )
      return (isPreferenceBMatched ? 1 : -1) - (isPreferenceAMatched ? 1 : -1)
    })
    return showMoreData[index]?.showMorePreferences
      ? sortedPreferences
      : sortedPreferences.slice(0, 4)
  }

  const extractNames = () => {
    const extractedNames = data?.map(candidate => ({
      firstName: candidate?.user?.first_name,
      lastName: candidate?.user?.last_name,
    }))
    setFullName(extractedNames)
  }

  const toggleShowMore = (index, field) => {
    setShowMoreData(prevState => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [field]: !prevState[index]?.[field],
      },
    }))
  }

  useEffect(() => {
    extractNames()
  }, [data])

  const handleEditSearch = tab => {
    setOpenModal(true)
    setForceRenderKey(prevKey => prevKey + 1)
  }
  const revealUserProfile = async payload => {
    try {
      const response = await revealProfile(payload)
      handleSeePipeline()
      if (response.status === 200) {
        toastMessage('Added to Pipeline', 'success')
      }
    } catch (error) {
      toastMessage('Error Revealing Profile', 'error')
    }
  }
  const handleAddToPipeline = async candidate => {
    const queryParams = new URLSearchParams()
    queryParams.append('user_id', candidate.user.id)
    const payload = {
      user_id: candidate.user.id,
      company_id: searchHistory?.attributes?.company_id,
      search_history_id: searchHistory?.id,
      category: 1,
    }
    await revealUserProfile(payload)
    handleSeePipeline(true)
  }

  return (
    <div>
      {loader ? (
        <div className="flex justify-center items-center mt-10 mb-10">
          <Loader size="large" color="white" />
        </div>
      ) : !data || (Array.isArray(data) && data.length === 0) ? (
        <div className="min-w-fit p-4 my-4 mr-3">
          <div className="flex flex-col items-center w-full">
            <h2 className="text-2xl font-bold mb-4">
              {fromRevealProfile || isShowingRevealed ? (
                ' No candidates revealed'
              ) : isShowingSaved ? (
                'No candidates saved'
              ) : (
                <img src={imageSaved} className="px-2 mt-5" alt="Reveal Icon" />
              )}
            </h2>
            {!fromRevealProfile && !isShowingRevealed && !isShowingSaved && (
              <>
                <p className="text-black">
                  Sorry, we could not find candidates that match your criteria.
                </p>
                <div className="mt-4 mb-80">
                  <Button
                    onClick={() => handleEditSearch()}
                    color={'blue'}
                    size={'md'}
                  >
                    <img src={editSearch} className="px-2" alt="search Icon" />
                    Edit Search
                  </Button>
                </div>
              </>
            )}
            <StartNewSearch
              key={forceRenderKey}
              modalOpen={openModal}
              setModalOpen={setOpenModal}
              isNewSearch={false}
              search={search}
            />
          </div>
        </div>
      ) : (
        data?.map((candidate, index) => {
          const CandidatePreferences = getPreferencesToShow(candidate, index)
          return (
            <React.Fragment key={index}>
              <Card
                className={`w-full sm:w-auto px-2 pt-4 md:pt-0 pb-4 pl-5 mt-5 ${index === data.length - 1 && !fromTab && 'mb-11'} custom-card`}
                key={index}
              >
                <div className="flex flex-row items-center pb-0 mb-0 w-full">
                  <div className="flex xl2:flex-col flex-row w-full">
                    <div className="flex flex-row items-center xl2:w-full w-1/2">
                      <div className="relative flex-shrink-0 below-tablet-xl:mt-5">
                        <img
                          className="w-16 h-16 sm:w-20 sm:h-20 rounded-full object-cover border-2 border-[#E5E7EB]"
                          src={
                            candidate?.user?.profile_picture_url ?? avatarImg
                          }
                          alt={`${fullName[index]?.firstName} ${fullName[index]?.lastName}`}
                        />
                        <span className="absolute top-1 right-1 sm:top-0 sm:right-3 w-2 h-2 sm:w-3 sm:h-3 bg-green-400 rounded-full"></span>
                      </div>
                      <div className="ml-2 flex-grow below-tablet-xl:mt-5">
                        <p className="text-lg font-extrabold text-gray-900 lg:whitespace-nowrap whitespace-wrap">
                          <TruncatedText
                            text={`${fullName[index]?.firstName} ${fullName[index]?.lastName}`}
                            limit={25}
                          />
                        </p>
                        <p className="text-14 font-medium text-gray-600 lg:whitespace-nowrap">
                          {candidate?.user?.years_of_experience} years of
                          experience
                        </p>
                        <p className="text-14 font-medium text-gray-800 mr-0">
                          <span className="text-gray-600">From </span>
                          {candidate?.user?.citizenship || 'N/A'}
                          <span className="text-gray-600">, Living in </span>
                          {candidate?.user?.residence_country || 'N/A'}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row xl2:w-full w-1/2 mt-2 lg:mt-0 below-tablet-xl:justify-start md:justify-end items-center">
                      <div className="w-full 2xl:w-auto md:w-full">
                        <RadialBar
                          series={[
                            candidate?.expertise_match_score,
                            candidate?.job_preference_match_score,
                          ]}
                          onSaveCandidate={() => onSaveCandidate(candidate)}
                          isCandidateSaved={
                            isCandidateSaved && isCandidateSaved(candidate)
                          }
                          disableSave={disableSave}
                          fromTab={fromTab}
                          fromRevealProfile={fromRevealProfile}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="-mt-5 pr-8">
                  {candidate?.user?.work_histories?.length > 0 && (
                    <p className="work-history">
                      Worked in{' '}
                      <span className="text-gray-900 font-extrabold text-base">
                        {candidate.user.work_histories.find(wh => wh.is_working)
                          ?.company_name ||
                          candidate.user.work_histories[0]?.company_name}
                      </span>{' '}
                      as{' '}
                      <span className="text-blue-500 font-bold text-base">
                        {' '}
                        {candidate.user.work_histories.find(wh => wh.is_working)
                          ?.job_title ||
                          candidate.user.work_histories[0]?.job_title}
                      </span>
                    </p>
                  )}
                </div>

                <div className="pr-8">
                  <div className="flex md:flex-row flex-col gap-2 mb-4 mt-2">
                    <div className="w-full sm:w-1/2">
                      <span className="block text-sm text-black mb-2 font-semibold">
                        Expertise Matched
                      </span>
                      <div className="flex flex-wrap gap-2">
                        {(showMoreData[index]?.showMoreExpertise
                          ? sortedExpertises(candidate)
                          : sortedExpertises(candidate)?.slice(0, 4)
                        )?.map((expertise, expertiseIndex) => {
                          const matchedExpertise = candidate?.expertises?.find(
                            selectedExpertise =>
                              selectedExpertise.expertise?.title ===
                                expertise && selectedExpertise.matched,
                          )
                          return (
                            <Badge
                              key={expertiseIndex}
                              className={`bg-${matchedExpertise ? 'blue-100' : 'gray-100'} py-1 rounded-md`}
                            >
                              <div className="flex items-center">
                                <img
                                  src={
                                    matchedExpertise
                                      ? messagesIcon
                                      : greyMessagesIcon
                                  }
                                  className="w-3 h-3 mr-1"
                                  alt="message-outline"
                                />

                                <p
                                  className={`text-${matchedExpertise ? 'blue-800' : 'gray-900'} lg:text-sm text-xs  font-medium`}
                                >
                                  {' '}
                                  {expertise}
                                </p>
                              </div>
                            </Badge>
                          )
                        })}
                        {sortedExpertises(candidate)?.length > 4 && (
                          <Badge
                            className="bg-white py-1 text-sm font-medium text-gray-500 cursor-pointer"
                            onClick={() =>
                              toggleShowMore(index, 'showMoreExpertise')
                            }
                          >
                            {!showMoreData[index]?.showMoreExpertise
                              ? `+${sortedExpertises(candidate)?.length - 4} more`
                              : 'Show Less'}
                          </Badge>
                        )}
                      </div>
                    </div>

                    <div className="w-full sm:w-1/2 pl-2 :pl-14">
                      <span className="block text-sm font-bold text-black mb-2">
                        Preference Matched
                      </span>
                      <div className="flex flex-wrap gap-2">
                        {CandidatePreferences.map(([key, value], idx) => (
                          <React.Fragment key={idx}>
                            {value?.key !== 'languagePreference' ? (
                              <Badge
                                key={idx}
                                className={`bg-${candidate?.preferences?.find(pref => pref.key === value?.key)?.matched ? 'blue-100' : 'gray-100'} py-1 flex rounded-md`}
                              >
                                <div className="flex items-center">
                                  <p
                                    className={`text-${candidate?.preferences?.find(pref => pref.key === value?.key)?.matched ? 'blue-800' : 'gray-900'} lg:text-sm text-xs font-medium`}
                                  >
                                    {formatPreferenceText(
                                      value?.key,
                                      value.value,
                                    )}
                                  </p>
                                </div>
                              </Badge>
                            ) : (
                              value?.key === 'languagePreference' &&
                              value?.value?.map((langValue, langIdx) => (
                                <Badge
                                  key={langIdx}
                                  className={`bg-${candidate?.preferences?.find(pref => pref.key === value?.key)?.matched ? 'blue-100' : 'gray-100'} py-1 flex rounded-md`}
                                >
                                  <div className="flex items-center">
                                    <p
                                      className={`text-${candidate?.preferences?.find(pref => pref.key === value?.key)?.matched ? 'blue-800' : 'gray-900'} lg:text-sm text-xs font-medium`}
                                    >
                                      {formatText(langValue)}
                                    </p>
                                  </div>
                                </Badge>
                              ))
                            )}
                          </React.Fragment>
                        ))}

                        {Object.entries(candidate?.candidate_preferences)
                          ?.length > 4 && (
                          <Badge
                            className="bg-white py-1 text-sm font-medium text-gray-500 cursor-pointer"
                            onClick={() =>
                              toggleShowMore(index, 'showMorePreferences')
                            }
                          >
                            {!showMoreData[index]?.showMorePreferences
                              ? `+${Object.entries(candidate?.candidate_preferences)?.length - 4} more`
                              : 'Show Less'}
                          </Badge>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col gap-2 mb-4 mt-2">
                    <div className="w-full sm:w-1/2">
                      <span className="block text-sm text-black mb-2 font-semibold">
                        Certifications
                      </span>
                      <div className="flex flex-row flex-wrap gap-2">
                        {(showMoreData[index]?.showMoreCertifications
                          ? candidate.user.certifications
                          : candidate.user.certifications?.slice(0, 3)
                        )?.map(certification => (
                          <Badge className="bg-gray-100 py-1">
                            <div className="flex">
                              <img
                                src={greyMessagesIcon}
                                className="mr-1"
                                alt="message-outline"
                              />

                              <Tooltip
                                content={certification.title}
                                style={{ backgroundColor: 'light' }}
                              >
                                <p className="text-gray-900 lg:text-sm text-xs font-medium">
                                  {' '}
                                  {truncate(certification.title, 25)}
                                </p>
                              </Tooltip>
                            </div>
                          </Badge>
                        ))}
                        {candidate.user.certifications.length > 3 && (
                          <Badge
                            className="bg-white py-1 text-sm font-medium text-gray-500 cursor-pointer"
                            onClick={() =>
                              toggleShowMore(index, 'showMoreCertifications')
                            }
                          >
                            {!showMoreData[index]?.showMoreCertifications
                              ? `+${candidate.user.certifications.length - 3} more`
                              : 'Show Less'}
                          </Badge>
                        )}
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2 pl-2 :pl-14">
                      <span className="block text-sm text-black mb-2 font-semibold">
                        Quali Test
                      </span>
                      <div className="flex flex-wrap gap-2">
                        {(showMoreData[index]?.showMoreTests
                          ? candidate.user.attempted_tests
                          : candidate.user.attempted_tests?.slice(0, 4)
                        )?.map((test, idx) => (
                          <Badge
                            key={idx}
                            className="bg-gray-100 py-2 rounded-md"
                          >
                            <div className="flex">
                              <img
                                src={checkMarkIcon}
                                className="mr-1"
                                alt="check-mark"
                              />
                              <p className="text-gray-900 lg:text-sm text-xs  font-medium">
                                {'Passed ' + test.title}
                              </p>
                            </div>
                          </Badge>
                        ))}
                        {candidate.user.attempted_tests?.length > 4 && (
                          <Badge
                            className="bg-white py-1 text-sm font-medium text-gray-500 cursor-pointer"
                            onClick={() =>
                              toggleShowMore(index, 'showMoreTests')
                            }
                          >
                            {!showMoreData[index]?.showMoreTests
                              ? `+${candidate.user.attempted_tests.length - 4} more`
                              : 'Show Less'}
                          </Badge>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {!fromTab ? (
                  <div className="mt-2 pr-8 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
                    <p className="leading-tight text-sm font-medium">
                      Last active:{' '}
                      {formatLastActive(candidate?.user?.last_sign_in_at)}
                    </p>
                    {isCandidateRevealed(candidate) ? (
                      <>
                        <div className="flex flex-col gap-2">
                          <Tooltip
                            content={
                              <div className="text-center">
                                <p className="text-gray-400">
                                  This candidate was revealed in a previous
                                  search.
                                </p>
                                <p>
                                  You can still add them to the pipeline for
                                  this search.
                                </p>
                              </div>
                            }
                            placement="top"
                            className="rounded-md"
                          >
                            <Button
                              onClick={() => handleAddToPipeline(candidate)}
                              size="sm"
                              className="border-none mt-2"
                            >
                              <p className="px-1 md:px-2 whitespace-nowrap font-bold text-blue-700">
                                Add to Pipeline
                              </p>
                            </Button>
                          </Tooltip>
                          <Button
                            onClick={() => handleOpenModal(candidate)}
                            color="blue"
                            size="sm"
                            className="view-profile-btn"
                          >
                            <img
                              src={whiteEyelIcon}
                              className="px-2"
                              alt="Reveal Icon"
                            />
                            <p className="px-1 md:px-2 whitespace-nowrap text-white">
                              View Full Profile
                            </p>
                          </Button>
                        </div>
                      </>
                    ) : (
                      <Tooltip
                        content={
                          <div className="text-center">
                            <p>Use 1 Reveal Credit(s)</p>
                            <p className="text-gray-400">
                              View full profile, contact candidates, and share
                              test
                            </p>
                          </div>
                        }
                        placement="bottom"
                        className="rounded-md"
                      >
                        <Button
                          onClick={() => handleOpenModal(candidate)}
                          color="light"
                          size="sm"
                          className="view-profile-btn"
                        >
                          <img
                            src={revealIcon}
                            className="px-2"
                            alt="Reveal Icon"
                          />
                          <p className="px-1 md:px-2 whitespace-nowrap">
                            Reveal Full Profile
                          </p>
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                ) : (
                  <div className="mt-2 pr-8 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
                    <p className="leading-tight text-sm font-medium">
                      Last Active: {candidate?.lastSignInAt}
                    </p>
                    <Button
                      onClick={() => handleOpenModal(candidate)}
                      color={'light'}
                      size="sm"
                      className="view-profile-btn"
                    >
                      <img
                        src={revealIcon}
                        className="lg:px-2"
                        alt="Reveal Icon"
                      />
                      <p className="lg:px-2">View Full Profile</p>
                    </Button>
                  </div>
                )}
              </Card>
              {fromTab && (
                <CardFooter
                  searchHistory={candidate?.search_history?.name}
                  key={index}
                  setHired={() => handleSetHired(candidate)}
                  setPassed={() => handleSetPassed(candidate)}
                  hired={candidate.profile_status === 'hired'}
                  passed={candidate.profile_status === 'passed'}
                />
              )}
            </React.Fragment>
          )
        })
      )}
    </div>
  )
}

export default CandidateDetailsCard
