import React, { useState } from 'react'
import avatarImg from '../../assets/avatar.png'
import { AvatarPopUp } from './modals/AvatarPopUp'

const CandidateProfile = ({
  candidate,
  fullName,
  isPipelineView,
  roundPercentage,
}) => {
  const [showAvatarPopup, setShowAvatarPopup] = useState(false)

  return (
    <div className="flex flex-row md:w-full w-25">
      <div className="relative flex-shrink-0">
        <img
          className="w-16 h-16 sm:w-20 sm:h-20 rounded-full object-cover border-2 border-[#E5E7EB]"
          src={candidate?.user?.profile_picture_url ?? avatarImg}
          alt={`${fullName?.firstName} ${fullName?.lastName}`}
          onClick={() => setShowAvatarPopup(true)}
        />
        <span className="absolute top-1 right-1 sm:top-0 sm:right-3 w-2 h-2 sm:w-3 sm:h-3 bg-green-400 rounded-full"></span>
      </div>
      <div className="sm:ml-6 ml-1 flex special-lg:mr-20 flex-col gap-1">
        <p className="md:text-[18px] font-satoshi text-sm font-bold text-[#111928] whitespace-wrap">
          {fullName?.firstName} {fullName?.lastName}
        </p>
        <p className="text-[14px] font-medium text-[#4B5563]">
          {candidate?.user?.years_of_experience} years of experience
        </p>
        <p className="md:text-sm text-xs font-medium text-gray-700 laptop-lg:mr-40 sm:mr-48 w-full">
          <span className="text-[#4B5563]">From</span>
          <span className="pl-2 font-bold">{candidate?.user?.citizenship}</span>
          <span>, Living in</span>
          <span className="px-2 font-bold">
            {candidate?.user?.residence_country}
          </span>
        </p>
      </div>
      {!isPipelineView && (
        <div>
          <p className="text-[14px] md:ml-0 ml-3 font-medium text-[#6B7280]">
            Match Score
          </p>
          <h1 className="md:text-2xl md:ml-0 ml-3 font-bold text-[#111928]">
            {roundPercentage(candidate.expertise_match_score)}
          </h1>
        </div>
      )}
      <AvatarPopUp
        avatarUrl={candidate?.user?.profile_picture_url ?? avatarImg}
        show={showAvatarPopup}
        onClose={() => setShowAvatarPopup(false)}
      />
    </div>
  )
}

export default CandidateProfile
