import React, { useEffect, useState } from 'react'
import { Tabs } from 'flowbite-react'
import CompanyProfile from './CompanyProfile'
import Plans from './Plans'

const MobileTabs = ({ selectedCard, setSelectedCard, handleTabChange }) => {
  const [tab, setTab] = useState(selectedCard || 0)

  const handleTabSelect = newTab => {
    handleTabChange(tab)
    setSelectedCard(newTab)
  }

  useEffect(() => {
    setSelectedCard(tab)
  }, [tab, selectedCard])

  return (
    <Tabs aria-label="Default tabs" variant="default">
      <Tabs.Item
        active={selectedCard === 0}
        title="Complete company profile"
        className="tabs-item"
        onClick={() => handleTabSelect(0)}
      >
        <CompanyProfile
          setSelectedCard={setTab}
          fromTab={true}
          handleTabSelect={handleTabSelect}
        />
      </Tabs.Item>

      <Tabs.Item
        active={selectedCard === 1}
        title="Select your plan"
        className="tabs-item"
        onClick={() => handleTabSelect(1)}
      >
        <Plans />
      </Tabs.Item>
    </Tabs>
  )
}

export default MobileTabs
