import { useEffect, useState, useRef } from 'react'
import { Modal } from 'flowbite-react'
import avatarImg from '../../../assets/avatar.png'
import RadialBar from '../RevealProfileChart'
import CertificationIcon from '../../../assets/svgs/certificate.svg'
import EditUserIcon from '../../../assets/svgs/edit_user.svg'
import WorkIcon from '../../../assets/svgs/work.svg'
import BlueworkIcon from '../../../assets/svgs/work_blue.svg'
import MessageBlueIcon from '../../../assets/svgs/msg_blue.svg'
import MessageWhiteIcon from '../../../assets/svgs/msg_white.svg'
import WhatsappIcon from '../../../assets/svgs/messages.svg'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { contactCandidateSchema } from '../../../validations/ContactCandidateSchema'
import useToastMessage from '../../../hooks/useToastMessage'
import DropdownSearch from '../../generics/dropdown/DropdownSearch'
import {
  getAllTestsListings,
  sendTestToCandidate,
} from '../../../services/testService'
import {getCurrentUser} from 'services/userService'
import { selectCurrentUser ,setCurrentUser} from '../../../redux/currentUserSlice'
import { useSelector,useDispatch } from 'react-redux'
import EmailSentModal from './ContactAlert'
import { WhatsappModal } from './WhatsappModal'
import {
  startConversation,
} from 'services/messagingService'
import moment from 'moment'
import { roundPercentage } from 'utilis/helpers'
import { verifyCertification } from 'services/certificationService'
import { selectSearch } from '../../../redux/searchSlice'
import 'assets/css/reveal-candidate-card.css'
import { AvatarPopUp } from './AvatarPopUp'

export default function RevealCandidateProfile({
  openModal,
  setOpenModal,
  candidate,
  fetchAndUpdateData,
  fetchCurrentUser,
  historyResults,
  setHistoryResults,
  fetchOnCandidateSave,
}) {
  const dispatch = useDispatch()
  const { currentUser } = useSelector(selectCurrentUser)
  const toastMessage = useToastMessage()
  const [fullName, setFullName] = useState({
    firstName: '',
    lastName: '',
  })
  const [tests, setTests] = useState([])
  const [selectedTest, setSelectedTest] = useState(null)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [WhatsappModalOpen, setWhatsappModalOpen] = useState(false)
  const [contactPayload, setContactPayload] = useState({})
  const [selectCertificate, setSelectedCertificate] = useState({})
  const [showMoreCertifications, setShowMoreCertifications] = useState(false)
  const [showMoreTests, setshowMoreTests] = useState(true)
  const [testtoggle, settesttoggle] = useState(false)
  const [certificatetoggle, setcertificatetoggle] = useState(false)
  const search = useSelector(selectSearch)
  const [isLastElementInView, setIsLastElementInView] = useState(false)
  const [showAvatarPopup, setShowAvatarPopup] = useState(false)

  const onScroll = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      setIsLastElementInView(true)
    } else {
      isLastElementInView && setIsLastElementInView(false)
    }
  }

  const updateTestCount = async () => {
    try {
      const response = await getCurrentUser()
      dispatch(setCurrentUser(response?.data))
    } catch (err) {
      console.error('Error', err)
    }
  }
  const extractNames = () => {
    setFullName({
      firstName: candidate?.user?.first_name,
      lastName: candidate?.user?.last_name,
    })
  }
  const fetchAndSetTests = async () => {
    try {
      const testListing = await getAllTestsListings()
      setTests(testListing?.data || [])
    } catch (error) {
      console.error('Error fetching tests:', error)
    }
  }
  const modalRef = useRef(null)
  const handleClickOutside = event => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCloseModal()
    }
  }
  useEffect(() => {
    extractNames()
    fetchAndSetTests()
  }, [candidate])
  const CustomToggleSwitch = ({ id, checked, onChange, disabled }) => {
    if (id === 'tests') {
      if (checked === true) {
        settesttoggle(true)
      } else {
        settesttoggle(false)
      }
    }
    if (id === 'certifications') {
      if (checked === true) {
        setcertificatetoggle(true)
      } else {
        setcertificatetoggle(false)
      }
    }
    return (
      <div
        onClick={!disabled ? onChange : null}
        className={`relative inline-flex items-center h-6 w-11 rounded-full cursor-pointer transition-colors duration-200 ease-in-out
    ${checked ? (disabled ? 'bg-gray-400' : 'bg-[#1A56DB]') : 'bg-gray-200'}
    ${disabled && 'opacity-50 cursor-not-allowed'}`}
      >
        <span
          className={`inline-block h-5 w-5 transform rounded-full bg-white shadow transition-transform duration-200 ease-in-out
      ${checked ? 'translate-x-5' : 'translate-x-1'}
      ${disabled && 'bg-gray-300'}`}
        />
      </div>
    )
  }
  const onSelectTest = selected => {
    setSelectedTest(selected)
  }
  const onSelectCertificate = selected => {
    setSelectedCertificate(selected)
  }
  const InitiateChat =async values =>{
    try {
      const data = {
        body: `Hey ${fullName?.firstName} ${fullName?.lastName}, ${currentUser?.company?.name} is interested in talking to you about ${values?.jobTitle}. \n ${values?.jobDescription} \n Respond here to let the company know you are interested`,
        recipient_id: candidate?.user?.id,
        subject: 'Chat Message'
      }
      const response = await startConversation(data)
    } catch (error) {
      console.error('Error initiating chat:', error)
    }
}
  const handleSubmit = async values => {
    try {
      const data = {
        email: candidate?.user?.email,
        job_title: values?.jobTitle,
        company_name: currentUser?.company?.name,
        candidate_name: `${fullName?.firstName} ${fullName?.lastName}`,
        job_description: values?.jobDescription,
        medium: values.contactMethod,
        test_id: selectedTest?.id,
        title: selectedTest?.title,
        test_link: selectedTest?.test_link,
        candidate_id: candidate?.user?.id,
      }
      setContactPayload(data)
      data?.medium === 'whatsapp' && setWhatsappModalOpen(true)
      if (data?.medium === 'email') {
        
        if ((currentUser?.company?.remaining_test_count > 0) || !selectedTest?.test_link) {
          const response = await sendTestToCandidate(data)
          if (response.status === 200) {
            updateTestCount()
            toastMessage('Email sent successfully!', 'success')
            data?.medium === 'email' && setSuccessModalOpen(true)
          }
      }
        else
        {
          toastMessage('Test Limit Reached')
        }
        
      }
      if (selectCertificate && selectCertificate.id) {
        const certificateResponse = await verifyCertification(
          search.id,
          selectCertificate,
        )
        if (certificateResponse.status === 200) {
          toastMessage('Certification Verification Sent!', 'success')
        }
      }
    } catch (error) {
      console.error('Error sending email:', error)
    }
    if (!selectedTest?.test_link) {
      InitiateChat(values)
    }
  }
  const handleToggleChange = (field, setFieldValue, values) => {
    field === 'sendTest' && values[field] && setSelectedTest(null)
    setFieldValue(field, !values[field])
  }
  const handleCloseModal = () => {
    fetchOnCandidateSave?.(candidate, true)
    setOpenModal(false)
    fetchCurrentUser?.()
  }

  const handleWhatsapp = async () => {
    try {
      if((currentUser?.company?.remaining_test_count >0) || !selectedTest?.test_link )
        {
        await sendTestToCandidate(contactPayload)
        setWhatsappModalOpen(false)
        setSuccessModalOpen(true)
        }
        else{
          toastMessage('Test Limit Reached')
        }
      
    } catch (error) {}
  }
  return (
    <>
      <Modal
        show={openModal}
        size="7xl"
        onClose={() => {
          handleCloseModal()
        }}
        ref={modalRef}
        onClick={handleClickOutside}
        className={`flex justify-center items-center`}
        popup
      >
        <Modal.Header />
        <Modal.Body
          className={`grid lg:grid-cols-2 gap-4 h-auto overflow-y-hidden`}
        >
          <div
            className={`flex flex-col font-satoshi lg:w-full h-auto overflow-y-scroll ${testtoggle === true && certificatetoggle === true ? ' laptop-lg:max-h-[85vh]' : testtoggle === true || certificatetoggle === true ? 'special-lg:max-h-[85vh] laptop-lg:max-h-[80vh] xl:max-h-[90vh]' : ' 2xl:max-h-[75vh] lg:max-h-[70vh] laptop-lg:max-h-[70vh]'}`}
            onScroll={onScroll}
          >
            <div className="flex flex-row md:w-full w-25 justify-between items-start">
              <div className="flex flex-grow">
                <div className="relative flex-shrink-0">
                  <img
                    className="w-16 h-16 sm:w-20 sm:h-20 rounded-full object-cover border-2 border-[#E5E7EB]"
                    src={candidate?.user?.profile_picture_url ?? avatarImg}
                    alt={`${fullName?.firstName} ${fullName?.lastName}`}
                    onClick={() => setShowAvatarPopup(true)}
                  />
                  <span className="absolute top-1 right-1 sm:top-0 sm:right-3 w-2 h-2 sm:w-3 sm:h-3 bg-green-400 rounded-full"></span>
                </div>
                <div className="sm:ml-6 ml-1 flex special-lg:mr-20 flex-col gap-1">
                  <p className="md:text-[18px] font-satoshi text-sm font-bold text-[#111928] white-space: normal whitespace-wrap">
                    {fullName?.firstName} {fullName?.lastName}
                  </p>
                  <p className="text-[14px] font-medium text-[#4B5563]">
                    {candidate.user.years_of_experience} years of experience
                  </p>
                  <p className="md:text-sm text-xs font-medium text-gray-700">
                    <span className="text-[#4B5563]">From </span>
                    <span className="font-bold">
                      {candidate.user.citizenship}
                    </span>
                    <span className="text-[#4B5563]">, Living in </span>
                    <span className="font-bold">
                      {candidate.user.residence_country}
                    </span>
                  </p>
                </div>
              </div>
              <div className="text-right flex-shrink-0 mx-5">
                <p className="text-[14px] font-medium text-[#6B7280]">
                  Match Score
                </p>
                <h1 className="md:text-2xl font-bold text-[#111928]">
                  {roundPercentage(candidate.expertise_match_score)}
                </h1>
              </div>
            </div>
            <p className="md:text-lg text-sm text-gray-600 mt-4">
              Worked in{' '}
              <span className="font-semibold text-gray-900">
                {candidate.user.work_histories.find(wh => wh.is_working)
                  ?.company_name ||
                  candidate.user.work_histories[0]?.company_name}
              </span>{' '}
              as{' '}
              <span className="font-semibold text-blue-500">
                {candidate.user.work_histories.find(wh => wh.is_working)
                  ?.job_title || candidate.user.work_histories[0]?.job_title}
              </span>
            </p>
            <hr className="mt-4 special-lg:-mb-5" />
            <div className="transform scale-10 md:scale-70 md: text-blue-500">
              <RadialBar
                className="scale-10"
                series={[
                  candidate.expertise_match_score,
                  candidate.job_preference_match_score,
                ]}
              />
            </div>
            <hr className="mb-4" />
            <div className="p-4">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="col-span-1">
                  <div className="flex my-1">
                    <img
                      src={CertificationIcon}
                      alt="Certificate Icon"
                      className="my-auto"
                    />
                    <h3 className="text-[16px] font-medium text-[#4B5563] px-1">
                      Certifications
                    </h3>
                  </div>
                  {candidate.user.certifications
                    ?.slice(0, 2)
                    .map(certificate => {
                      return (
                        <ul className="list-none py-1">
                          <li className="mb-1 text-black text-14 font-semibold">
                            {certificate.title}
                          </li>
                          <li className="mb-1 text-[#4B5563] text-[14px] font-medium">
                            {certificate.issued_by}{' '}
                            {certificate.file_url ? (
                              <a
                                href={certificate.file_url}
                                className="text-[#1A56DB] font-medium cursor-pointer"
                              >
                                [View]
                              </a>
                            ) : null}
                          </li>
                        </ul>
                      )
                    })}
                  {candidate.user.certifications.length > 2 ? (
                    <>
                      {showMoreCertifications ? (
                        <>
                          {candidate.user.certifications
                            .slice(2)
                            .map(certificate => {
                              return (
                                <ul className="list-none py-1">
                                  <li className="mb-1 text-black font-semibold">
                                    {certificate.title}
                                  </li>
                                  <li className="mb-1 text-[#4B5563] text-[14px] font-medium">
                                    {certificate.issued_by}{' '}
                                    {certificate.file_url ? (
                                      <a
                                        href={certificate.file_url}
                                        className="text-[#1A56DB] font-medium cursor-pointer"
                                      >
                                        [View]
                                      </a>
                                    ) : null}
                                  </li>
                                </ul>
                              )
                            })}
                          <span
                            className="text-[#1A56DB] font-bold cursor-pointer"
                            onClick={() =>
                              setShowMoreCertifications(!showMoreCertifications)
                            }
                          >
                            See Less
                          </span>
                        </>
                      ) : (
                        <span
                          className="text-[#1A56DB] font-bold cursor-pointer"
                          onClick={() =>
                            setShowMoreCertifications(!showMoreCertifications)
                          }
                        >
                          See More
                        </span>
                      )}
                    </>
                  ) : candidate.user.certifications.length === 0 ? (
                    <span className="text-14 font-[500] text-gray-900">
                      No certifications available.
                    </span>
                  ) : null}
                </div>
                <div
                  className={`col-span-1 ${candidate.user.certifications.length === 0 && candidate.user.attempted_tests?.length === 0 ? 'mb-[100px]' : ''}`}
                >
                  <div className="flex my-1">
                    <img
                      src={EditUserIcon}
                      alt="Certificate Icon"
                      className="my-auto"
                    />
                    <h3 className="text-[16px] font-medium text-[#4B5563] px-1">
                      Quali Test Taken
                    </h3>
                  </div>
                  {candidate.user.attempted_tests?.length === 0 ? (
                    <span className="text-14 font-[500] text-gray-900">
                      No tests have been sent.{' '}
                    </span>
                  ) : [
                      ...new Set(
                        candidate.user.attempted_tests?.map(test => test.title),
                      ),
                    ].length > 3 ? (
                    <>
                      {showMoreTests ? (
                        <>
                          <div className="mb-1">
                            {[
                              ...new Set(
                                candidate.user.attempted_tests?.map(
                                  test => test.title,
                                ),
                              ),
                            ]
                              ?.slice(0, 3)
                              .map((uniqueTitle, index) => (
                                <ul key={index} className="list-none py-1">
                                  <li className="mb-2 text-black font-semibold text-[14px]">
                                    {uniqueTitle}
                                  </li>
                                </ul>
                              ))}
                          </div>
                          <span
                            className="text-[#1A56DB] font-bold cursor-pointer"
                            onClick={() => setshowMoreTests(!showMoreTests)}
                          >
                            See More
                          </span>
                        </>
                      ) : (
                        <>
                          {[
                            ...new Set(
                              candidate.user.attempted_tests?.map(
                                test => test.title,
                              ),
                            ),
                          ]?.map((uniqueTitle, index) => (
                            <ul key={index} className="list-none py-1">
                              <li className="mb-2 text-black font-semibold text-[16px]">
                                {uniqueTitle}
                              </li>
                            </ul>
                          ))}
                          <span
                            className="text-[#1A56DB] font-bold cursor-pointer"
                            onClick={() => setshowMoreTests(!showMoreTests)}
                          >
                            See Less
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {[
                        ...new Set(
                          candidate.user.attempted_tests?.map(
                            test => test.title,
                          ),
                        ),
                      ]?.map((uniqueTitle, index) => (
                        <ul key={index} className="list-none py-1">
                          <li className="mb-2 text-black font-semibold text-[16px]">
                            {uniqueTitle}
                          </li>
                        </ul>
                      ))}
                    </>
                  )}
                </div>
              </div>
              <hr className="my-4" />
              <div className="experience mb-4">
                <div className="flex my-1">
                  <img
                    src={WorkIcon}
                    alt="Certificate Icon"
                    className="my-auto"
                  />
                  <h3 className="text-md font-medium text-[#4B5563] px-1 py-2">
                    Experience
                  </h3>
                </div>
                {candidate.user.work_histories?.map(experience => {
                  return (
                    <>
                      <div className="flex items-start py-2 mb-5">
                        <div className="bg-blue-100 p-2 rounded-full">
                          <img
                            src={BlueworkIcon}
                            alt="Certificate Icon"
                            className="m-auto h-3 w-3"
                          />
                        </div>
                        <div className="flex flex-col items-start gap-4 relative w-full px-2">
                          <div className="flex flex-col items-start gap-2 relative w-full">
                            <div className="inline-flex items-center gap-2.5">
                              <p className="font-bold text-base leading-6 md:whitespace-nowrap text-[#111928]">
                                {experience.job_title}{' '}
                                <span className="text-gray-500">at</span>{' '}
                                {experience.company_name}
                              </p>
                            </div>
                            <p className="text-sm font-medium text-gray-500">
                              {moment(experience.start_date).format('MMM YYYY')}{' '}
                              -{' '}
                              {experience.end_date
                                ? moment(experience.end_date).format('MMM YYYY')
                                : 'Present'}
                              <span className="text-gray-400"> | </span>
                              <span className="text-gray-700">
                                {experience.city}, {experience.country}
                              </span>
                            </p>
                            <p className="font-medium text-[#6B7280] text-sm">
                              <ul class="list-disc pl-8">
                                <li>{experience.description}</li>
                              </ul>
                            </p>
                          </div>
                        </div>
                      </div>
                      {!isLastElementInView && (
                        <div className="hidden lg:block cloudy-effect"></div>
                      )}
                    </>
                  )
                })}
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col font-satoshi h-auto ${testtoggle === true && certificatetoggle === true ? 'special-lg:max-h-[90%] laptop-lg:max-h-[95%]' : testtoggle === true || certificatetoggle === true ? 'special-lg:max-h-[85%] laptop-lg:max-h-[85%] xl:max-h-[90%] ' : ' 2xl:max-h-[75%] lg:max-h-[70%] laptop-lg:max-h-[70%]'} sticky top-0`}
          >
            <h3 className="text-xl text-[#111928] font-semibold mb-4">
              Contact Candidate
            </h3>
            <Formik
              initialValues={{
                contactMethod: '',
                jobTitle: '',
                jobDescription: '',
                sendTest: false,
                verifyCertificate: false,
                customTest: '',
                qualiTest: '',
              }}
              validationSchema={contactCandidateSchema}
              onSubmit={values => {
                handleSubmit(values)
              }}
            >
              {({ values, setFieldValue, handleChange, isSubmitting }) => (
                <Form className="sm:p-6 lg:bg-[#F9FAFB] rounded-lg">
                  <p className="mb-4 text-lg">
                    How do you want to contact {fullName?.firstName}{' '}
                    {fullName?.lastName}?
                  </p>
                  <div className="flex">
                    <label className="flex items-center mr-2">
                      <Field
                        type="radio"
                        name="contactMethod"
                        value="email"
                        className="hidden"
                      />
                      <div
                        className={`flex px-7 text-14 py-2 rounded-md cursor-pointer border border-[#1A56DB] ${values.contactMethod === 'email' ? 'bg-[#1A56DB]/10' : 'bg-white text-[#1A56DB]'}`}
                      >
                        <img
                          src={MessageBlueIcon}
                          alt="Email Icon"
                          className="my-auto px-2"
                        />
                        <span className="text-[#1A56DB] text-14 whitespace-nowrap ">
                          E-mail
                        </span>
                      </div>
                    </label>
                    <label className="flex items-center">
                      <Field
                        type="radio"
                        name="contactMethod"
                        value="whatsapp"
                        className="hidden"
                      />
                      <div
                        className={`flex cursor-pointer text-14 px-4 py-2 rounded-md border border-[#1A56DB] ${values.contactMethod === 'whatsapp' ? 'bg-[#1A56DB]/10' : 'bg-white text-[#1A56DB]'}`}
                      >
                        <img
                          src={WhatsappIcon}
                          alt="WhatsApp Icon"
                          className="my-auto -ml-1 px-2"
                        />
                        <span className="text-[#1A56DB]">Whatsapp</span>
                      </div>
                    </label>
                  </div>
                  <ErrorMessage
                    name="contactMethod"
                    component="div"
                    className="text-red-500"
                  />
                  <div className="mt-4">
                    <label
                      htmlFor="jobTitle"
                      className="block mb-2 text-md font-medium text-black"
                    >
                      Job Title
                    </label>
                    <Field
                      type="text"
                      id="jobTitle"
                      name="jobTitle"
                      className="block w-full px-4 py-3 border border-gray-300 rounded-lg"
                      placeholder="Enter Job Title"
                    />
                    <ErrorMessage
                      name="jobTitle"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="jobDescription"
                      className="block mb-2 text-md font-medium text-black"
                    >
                      Job Description/Message to Candidate
                    </label>
                    <Field
                      as="textarea"
                      id="jobDescription"
                      name="jobDescription"
                      className="block w-full px-4 py-3 border border-gray-300 rounded-lg"
                      placeholder="Enter job description"
                    />
                    <ErrorMessage
                      name="jobDescription"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="my-4 flex justify-between">
                    <label className="text-gray-900 text-14 font-[500]">
                      Send test to candidate
                    </label>
                    <CustomToggleSwitch
                      id="tests"
                      checked={values.sendTest}
                      onChange={() =>
                        handleToggleChange('sendTest', setFieldValue, values)
                      }
                    />
                  </div>
                  {values.sendTest && (
                    <div className="flex justify-center items-center">
                      <DropdownSearch
                        title="test"
                        options={tests}
                        onSelectTest={onSelectTest}
                      />
                    </div>
                  )}
                  <div className="my-4  flex justify-between">
                    <label className="text-gray-900 text-14 font-[500]">
                      Verify Candidate Document
                    </label>
                    <CustomToggleSwitch
                      disabled={candidate.user.certifications.length === 0}
                      id="certifications"
                      checked={values.verifyCertificate}
                      onChange={() =>
                        handleToggleChange(
                          'verifyCertificate',
                          setFieldValue,
                          values,
                        )
                      }
                    />
                  </div>
                  {values.verifyCertificate && (
                    <div className="flex justify-center items-center">
                      <DropdownSearch
                        title="Select a candidate document to verify"
                        options={candidate.user.certifications}
                        onSelectTest={onSelectCertificate}
                      />
                    </div>
                  )}
                  <div className="mt-16 md:mb-5 mb-28 md:pb-0 pb-16">
                    <button
                      type="submit"
                      className="flex justify-center px-2 py-2 xs:mb-5 rounded-md border bg-[#1A56DB] w-full"
                    >
                      <img
                        src={MessageWhiteIcon}
                        alt="Contact Icon"
                        className="my-auto px-2 "
                      />
                      <span className="text-white">Contact Candidate</span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      {successModalOpen && (
        <EmailSentModal
          successModalOpen={successModalOpen}
          setSuccessModalOpen={setSuccessModalOpen}
          ContactMode={contactPayload?.medium}
          message={
            selectedTest?.test_link
              ? 'You just shared candidate a test via'
              : 'You just Contacted a candidate via'
          }
        />
      )}
      {WhatsappModalOpen && (
        <WhatsappModal
          openModal={WhatsappModalOpen}
          setOpenModal={setWhatsappModalOpen}
          handleWhatsapp={handleWhatsapp}
          testName={selectedTest?.title}
          testLink={selectedTest?.test_link}
          candidateName={`${fullName?.firstName}`}
          jobtitle={contactPayload.job_title}
          jobdetail={contactPayload?.job_description}
        />
      )}
      <AvatarPopUp
        avatarUrl={candidate?.user?.profile_picture_url ?? avatarImg}
        show={showAvatarPopup}
        onClose={() => setShowAvatarPopup(false)}
      />
    </>
  )
}
