import { useState, useEffect } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import showAlert from '../components/generics/Alert'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../redux/currentUserSlice'

const candidateUser = role => ['candidate', 'admin'].includes(role)

const CompanyUserRoutes = () => {
  const { currentUser } = useSelector(selectCurrentUser)
  const [alertShown, setAlertShown] = useState(false)

  useEffect(() => {
    if (currentUser && candidateUser(currentUser.role)) {
      showAlert({
        icon: 'error',
        title: 'Unauthorized',
        text: 'You are not authorized to access this page.',
        showConfirmButton: false,
        showCloseButton: true,
      })
      setAlertShown(true)
    }
  }, [currentUser])

  return (
    <>
      {!alertShown &&
        (currentUser && !candidateUser() ? (
          <Outlet />
        ) : (
          <Navigate to="/login" />
        ))}
    </>
  )
}

export default CompanyUserRoutes
