import React from 'react'
import { Button } from 'flowbite-react'
import umbrellaImg from '../../../assets/umbrella.jpg'
import { useNavigate } from 'react-router-dom'
import { InitialLetters } from '../../generics/Initials'
import { TruncatedText } from 'utilis/helpers'
export const UserProfile = ({ currentUser, isCompanyAdmin, company }) => {
  const navigate = useNavigate()

  return (
    <div className="border-b border-gray w-full my-3">
      <div className="flex flex-row items-center md:p-1.5 my-4 w-full">
        {currentUser?.profile_picture_url || company?.logo_url ? (
          <img
            src={
              isCompanyAdmin
                ? company?.logo_url
                : currentUser.role === 'candidate'
                  ? currentUser?.profile_picture_url
                  : umbrellaImg
            }
            alt="User Logo"
            className="mb-2 w-12 h-12 object-cover rounded-full"
          />
        ) : (
          <div className="flex justify-center text-black items-center p-5 rounded-full text-2xl bg-gray-100 border-gray-100 h-12 w-12 md:h-12 md:w-12 mb-6">
            <InitialLetters
              Name={
                currentUser.role === 'candidate'
                  ? `${currentUser?.first_name} ${currentUser?.last_name}`
                  : company?.name
              }
            />
          </div>
        )}

        <div className="flex flex-col ml-4">
          <p className="text-md font-bold text-gray-900 text-wrap break-all">
            <TruncatedText
              text={
                isCompanyAdmin
                  ? `${company?.name}`
                  : `${currentUser.first_name} ${currentUser.last_name}`
              }
              limit={50}
            />
          </p>
          {currentUser.role === 'candidate' && (
            <div className="border border-blue-600 flex justify-center items-center rounded-md w-10/12 mt-2">
              <Button
                outline
                size="xs"
                className="bold  text-[#1a56db] font-semibold w-full max-w-[200px] rounded-[4px] border-sm"
                color="white"
                onClick={() => navigate('/candidate-account-overview')}
              >
                <p className="text-blue-700 text-sm font-medium whitespace-nowrap">
                  Edit Profile
                </p>
              </Button>
            </div>
          )}
          {isCompanyAdmin && (
            <div>
              <div className="border border-blue-600 flex justify-center items-center rounded-md w-10/12 mt-2">
                <Button
                  outline
                  size="xs"
                  className=" bold  text-[#1a56db] font-semibold w-full max-w-[200px] rounded-[4px] border-sm px-3 py-1"
                  color="white"
                  onClick={() => navigate('/manage-profile')}
                >
                  <p className="text-blue-700 text-sm font-medium whitespace-nowrap">
                    Manage Profile
                  </p>
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
