import { createSlice } from '@reduxjs/toolkit'

export const talentSearchSlice = createSlice({
  name: 'talentSearch',
  initialState: {
    searchData: [],
    talentSearchQueryParam: '',
    revealedCandidates: [],
  },
  reducers: {
    setTalentSearchData: (state, action) => {
      return { ...state, searchData: action.payload }
    },
    setTalentSearchQueryParam: (state, action) => {
      return { ...state, talentSearchQueryParam: action.payload }
    },
    setRevealedCandidates: (state, action) => {
      return { ...state, revealedCandidates: action.payload }
    },
  },
})

export const {
  setTalentSearchData,
  setTalentSearchQueryParam,
  setRevealedCandidates,
} = talentSearchSlice.actions
export const selectTalentSearchQueryParam = state =>
  state.talent.talentSearchQueryParam
export const selectRevealedCandidates = state => state.talent.revealedCandidates
export const selectTalentSearchData = state => state.talent
export const talentSearchReducer = talentSearchSlice.reducer
