import React, { useEffect, useState } from 'react'
import AdminNavbar from './AdminNavbar'
import Table from '../table/Table'
import { generateColumns } from '../../utilis/helpers/tableHelper'
import { companySearchHistory } from '../../services/searchResult'

const SearchResultHistoriesList = () => {
  const [searchResults, setSearchResults] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState('')

  const getSearchResults = async () => {
    try {
      const response = await companySearchHistory(currentPage, query)
      if (response.status === 200) {
        const mappedData = response.data.records.map(item => {
          const { id, name, skill, expertise, candidates, is_archive } =
            item?.attributes
          return {
            id: id,
            name: name,
            skill: skill,
            expertise: expertise,
            candidates: candidates,
            archive: is_archive ? 'True' : 'False',
          }
        })
        setSearchResults(mappedData || [])
        setTotalPages(response?.data?.pagy?.total_pages)
        setTotalCount(response?.data?.pagy?.total_count)
      }
    } catch (error) {
      console.error('Error fetching tests:', error)
    }
  }

  useEffect(() => {
    getSearchResults()
  }, [currentPage, query])

  const onSearchResultHistories = (search, value) => {
    setCurrentPage(1)
    setQuery(value)
  }

  const columns = generateColumns(searchResults)

  return (
    <div className="flex h-screen w-full flex-col bg-[#f9fafb]">
      <AdminNavbar selectedItem="search-result-histories" />
      <Table
        columns={columns}
        data={searchResults}
        hideCreateButton={true}
        hideEditButton={true}
        hideDeleteButton={true}
        onSearch={onSearchResultHistories}
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        setCurrentPage={setCurrentPage}
      />
    </div>
  )
}

export default SearchResultHistoriesList
