import React, { useState } from 'react'
import AppBar from '../components/Dashboard/AppBar'
import WelcomeCard from '../components/CompanyReister/WelcomeCard'
import CompanyProfile from '../components/CompanyReister/CompanyProfile'
import Plans from '../components/CompanyReister/Plans'
import { useSelector } from 'react-redux'
import { selectCompany } from '../redux/currentUserSlice'
import { isMobileDevice } from 'utilis/helpers'
import MobileTabs from 'components/CompanyReister/MobileTabs'

const CompleteCompanyProfile = () => {
  const [selectedCard, setSelectedCard] = useState(0)
  const companyData = useSelector(selectCompany)
  const [forceRenderKey, setForceRenderKey] = useState(0)
  const isMobile = isMobileDevice()
  const handleTabChange = tab => {
    setSelectedCard(tab)
    setForceRenderKey(prevKey => prevKey + 1)
  }
  return (
    <div className="min-h-screen w-full bg-[#F9FAFB]">
      <AppBar companyData={companyData} />
      {companyData && (
        <div className="flex justify-center gap-6 lg:py-6 lg:px-10">
          {isMobile ? (
            <MobileTabs
              key={forceRenderKey}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              handleTabChange={handleTabChange}
            />
          ) : (
            <>
              <WelcomeCard
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
              />
              {selectedCard === 0 ? (
                <CompanyProfile setSelectedCard={setSelectedCard} />
              ) : (
                <Plans />
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default CompleteCompanyProfile
