import React, { useCallback, useState, useEffect } from 'react'
import { SearchBox } from './SearchBox'
import { ChatPreview } from './ChatPreview'
import { getRevealedCandidatesForCompany } from '../../../../services/userService'
import { formatCandidateData } from '../../../../utilis/helpers'
import { useSelector } from 'react-redux'
import { setRevealedCandidates } from '../../../../redux/talentSearchSlice'
import { useDispatch } from 'react-redux'
import { selectCurrentUser } from '../../../../redux/currentUserSlice'
import { selectTypingStatus, selectLastMessage } from '../../../../redux/messageSlice'
import { debounce } from 'lodash';

export const CandidateMessagingList = ({
  avatar,
  chatListing,
  setSelectedChat,
  setSearchQuery,
  searchQuery,
  selectedChat,
  setSearchId
}) => {
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState('')
  const [query, setQuery] = useState('')
  const { currentUser } = useSelector(selectCurrentUser)
  const typing = useSelector(selectTypingStatus)
  const lastMessage = useSelector(selectLastMessage);

  const getCandidates = async (searchQuery) => {
    try {
      const response = await getRevealedCandidatesForCompany(searchQuery)
      const { data, status } = response
      if (status === 200) {
        const formattedDataArray = formatCandidateData(data)
        chatListing=formattedDataArray;
        dispatch(setRevealedCandidates(formattedDataArray || []))
      }
    } catch (error) {
      console.error('Error fetching candidates')
    }
  }

  const handleSearchValueChange = useCallback(event => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    setQuery(newValue)
  }, [])

  const handleChatClick = async item => {
  (currentUser?.role === 'company_admin') && setSearchId(null)
   setSelectedChat?.(item) 
  }

  const getUnreadCount = (item, currentUser) => {
    if (currentUser?.role === 'company_admin') {
      return item?.unreadMessageCount ?? 0;
    }
    return item?.users?.[0]?.unread_count ?? 0;
  };

  const formatTime = timeString => {
    if (!timeString) return '';
    const date = new Date(timeString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  };

  const getChatId = (userId1, userId2) => {
    return [Number(userId1), Number(userId2)].sort((a, b) => a - b).join('_');
  };

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      if (currentUser.role === 'company_admin') {
        getCandidates(searchQuery)
      }
    }, 500),
    [currentUser.role]
  )

  useEffect(() => {
    debouncedSearch(query);

    return () => {
      debouncedSearch.cancel()
    }
  }, [query, debouncedSearch])

  return (
    <div className={`flex flex-col gap-4 border-b border-gray-200 pb-5 ${!selectedChat ?'min-w-[520px]':'max-w-[320px] min-w-[320px]'} bg-white h-full`}>
      <SearchBox value={searchValue} handleChange={handleSearchValueChange} />
      <div className="overflow-y-auto max-h-[700px] min-h-[700px]">
        {chatListing?.map(item => {
            const userId = currentUser?.role === "company_admin" ? item?.user?.id : item?.users?.[0]?.user_id
            const messageDetails = lastMessage[getChatId(currentUser.id, userId)] || lastMessage[getChatId(userId, currentUser?.id)];
            const messageContent = messageDetails?.lastMessage || item?.last_message ||  '';
            const messageTime = (messageDetails && formatTime(messageDetails?.time)) || item?.time || '';

            return (
              <ChatPreview
                key={item.id}
                senderName={
                  item?.user?.first_name
                    ? `${item?.user?.first_name} ${item?.user?.last_name}`
                    : item?.company_name
                }
                senderLogo={item?.logo_url || item?.user?.profile_picture_url}
                lastMessage={messageContent}
                messageTime={messageTime}
                onChatClick={() => handleChatClick(item)}
                count={getUnreadCount(item, currentUser)}
                typing={typing}
                item={item}
                currentUser={currentUser}
                selectedChat={selectedChat}
              />
            );
          })}
        </div>
    </div>
  )
}
