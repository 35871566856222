import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import { CalendarDaysIcon } from '@heroicons/react/24/outline'
import '../../../assets/css/datepicker.css'

export const ReactDatePickerComponent = ({
  id,
  name,
  value,
  setFieldValue,
  minDate,
  maxDate,
  futureDisable,
  pastDisable,
  placeholder,
  dateFormat,
  isDisabled,
  showClearButton,
  showTodayButton,
}) => {
  const [selectedDate, setSelectedDate] = useState(
    value ? new Date(value) : null,
  )

  useEffect(() => {
    if (value) {
      setSelectedDate(new Date(value))
    } else {
      setSelectedDate(null)
    }
  }, [value])

  useEffect(() => {
    if (isDisabled) {
      setSelectedDate(null)
    } else if (value) {
      setSelectedDate(new Date(value))
    }
  }, [isDisabled, value])

  const handleDateChange = date => {
    if (date) {
      const formattedDate = moment(date).format(
        dateFormat === 'MM/YYYY' ? 'YYYY-MM' : 'YYYY-MM-DD',
      )
      setSelectedDate(date)
      setFieldValue(name, formattedDate)
    } else {
      setSelectedDate(null)
      setFieldValue(name, '')
    }
  }

  const formatDateForDisplay = date => {
    const format = dateFormat === 'MM/YYYY' ? 'MMMM/YYYY' : 'DD MMMM YYYY'
    return moment(date).format(format)
  }

  const determinedMaxDate = futureDisable ? new Date(futureDisable) : maxDate
  const determinedMinDate = pastDisable ? new Date(pastDisable) : minDate

  return (
    <div className={`relative ${isDisabled ? 'pointer-events-none' : ''}`}>
      <div className="flex items-center relative">
        <DatePicker
          id={id}
          name={name}
          onChange={handleDateChange}
          minDate={determinedMinDate}
          maxDate={determinedMaxDate}
          value={selectedDate}
          format={dateFormat === 'MM/YYYY' ? 'MM/yyyy' : 'dd/MM/yyyy'}
          placeholderText={placeholder}
          disabled={isDisabled}
          clearIcon={null}
          todayButton={showTodayButton ? 'Today' : null}
          yearPlaceholder="YYYY"
          monthPlaceholder="MM"
          dayPlaceholder="DD"
          calendarIcon={<CalendarDaysIcon className="calendar-icon" />}
          className="w-full bg-transparent"
        />
        <span
          className={`absolute inset-0 pl-3 pr-10 flex items-center text-sm font-medium ${
            dateFormat === 'MM/YYYY' ? 'text-gray-600' : 'text-gray-900'
          } pointer-events-none`}
          style={{ pointerEvents: 'none' }}
        >
          {selectedDate ? formatDateForDisplay(selectedDate) : placeholder}
        </span>
      </div>
    </div>
  )
}

ReactDatePickerComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  futureDisable: PropTypes.string,
  pastDisable: PropTypes.string,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.oneOf(['DD/MM/YYYY', 'MM/YYYY']),
  isDisabled: PropTypes.bool,
  showClearButton: PropTypes.bool,
  showTodayButton: PropTypes.bool,
}

ReactDatePickerComponent.defaultProps = {
  value: '',
  minDate: new Date('1950-01-01'),
  maxDate: new Date(),
  futureDisable: null,
  pastDisable: null,
  placeholder: 'DD/MM/YYYY',
  dateFormat: 'DD/MM/YYYY',
  isDisabled: false,
  showClearButton: true,
  showTodayButton: true,
}
