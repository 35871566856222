import * as Yup from 'yup'
import {
  FACEBOOK_REGEX,
  FILE_SIZE,
  SUPPORTED_FORMATS,
  TWITTER_REGEX,
  INSTAGRAM_REGEX,
  COMPANY_WEBSITE_REGEX,
  NAME_REGEX,
} from './constants'

export const companyProfileSchema = Yup.object({
  companyLogo: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      'fileType',
      'Unsupported File Format. Accepts only .jpg, .jpeg, .png.',
      value => {
        if (typeof value === 'string') return true
        return !value || SUPPORTED_FORMATS.includes(value.type)
      },
    )
    .test('fileSize', 'File too large', value => {
      if (typeof value === 'string') return true
      return !value || value.size <= FILE_SIZE
    }),
  companyDescription: Yup.string()
    .max(500, 'Description cannot exceed 500 characters')
    .nullable()
    .notRequired(),
  companyWebsite: Yup.string()
    .matches(COMPANY_WEBSITE_REGEX, 'Website should be a valid URL')
    .nullable()
    .notRequired(),
  facebook: Yup.string()
    .matches(FACEBOOK_REGEX, 'Enter a valid Facebook page URL')
    .nullable()
    .notRequired(),
  twitter: Yup.string()
    .matches(TWITTER_REGEX, 'Enter a valid Twitter username')
    .nullable()
    .notRequired(),
  instagram: Yup.string()
    .matches(INSTAGRAM_REGEX, 'Enter a valid Instagram ID')
    .nullable()
    .notRequired(),
})

export const companyEditSchema = Yup.object().shape({
  name: Yup.string()
    .required('Company name is required')
    .min(2, 'Company name must consist of at least 2 characters')
    .max(50, 'Company name must not exceed 50 characters')
    .test(
      'isValidLastName',
      'Please Enter a valid name',
      value => !value || NAME_REGEX.test(value),
    ),
  description: Yup.string()
    .max(500, 'Description cannot exceed 500 characters')
    .nullable()
    .notRequired(),
  contact_email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  contact_number: Yup.string().required('Contact number is required'),
  country: Yup.string().required('Country is required'),
  city: Yup.string().required('City is required'),
  industry_id: Yup.string().required('Main industry is required'),
  company_size: Yup.string().required('Company size is required'),
  website_link: Yup.string()
    .matches(COMPANY_WEBSITE_REGEX, 'Website should be a valid URL')
    .nullable()
    .notRequired(),
  social_network_links: Yup.object().shape({
    facebook: Yup.string()
      .matches(FACEBOOK_REGEX, 'Enter a valid Facebook page URL')
      .nullable()
      .notRequired(),
    twitter: Yup.string()
      .matches(TWITTER_REGEX, 'Enter a valid Twitter username')
      .nullable()
      .notRequired(),
    instagram: Yup.string()
      .matches(INSTAGRAM_REGEX, 'Enter a valid Instagram ID')
      .nullable()
      .notRequired(),
  }),
})

export const companyManageProfileSchema = Yup.object({
  companyName: Yup.string()
    .min(2, 'Company name must be at least 2 characters')
    .max(50, 'Company name cannot be more than 50 characters')
    .required('Company name is required')
    .test(
      'isValidLastName',
      'Please Enter a valid name',
      value => !value || NAME_REGEX.test(value),
    ),
  country: Yup.string().required('Country is required'),
  city: Yup.string().required('City is required'),

  mainIndustry: Yup.string().required('Main industry is required'),

  companySize: Yup.string().required('Company size is required'),

  companyDescription: Yup.string()
    .max(500, 'Description cannot exceed 500 characters')
    .nullable()
    .notRequired(),
    companyWebsite: Yup.string()
    .nullable()
    .notRequired()
    .test(
      'is-valid-website',
      'Website should be a valid URL',
      (value) => !value || COMPANY_WEBSITE_REGEX.test(value)
    ),
  facebook: Yup.string()
    .test('is-valid-facebook', 'Enter a valid Facebook page URL', value => {
      return !value || FACEBOOK_REGEX.test(value)
    })
    .nullable()
    .notRequired(),
  twitter: Yup.string()
    .test('is-valid-twitter', 'Enter a valid Twitter username', value => {
      return !value || TWITTER_REGEX.test(value)
    })
    .nullable()
    .notRequired(),
  instagram: Yup.string()
    .test('is-valid-instagram', 'Enter a valid Instagram ID', value => {
      return !value || INSTAGRAM_REGEX.test(value)
    })
    .nullable()
    .notRequired(),
})
