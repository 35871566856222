import React from 'react'
import './SearchCandidates.css'
import talentSearch from '../../assets/talentSearch.png'
import arrowImage from '../../assets/arrow.png'

const EmptySearchResults = () => {
  return (
    <div className="border-b-2 w-90 mx-5">
      <div className="flex">
        <div className="basis-1/3"></div>
        <div className="flex basis-1/3">
          <img
            src={talentSearch}
            className="pt-10 m-auto"
            alt="Talent Arrow Search"
          />
          <img src={arrowImage} alt="Talent Arrow Search" />
        </div>
      </div>
      <div className="flex flex-col p-10 justify-between items-center">
        <div className="flex flex-col items-center justify-center text-center mt-5">
          <p className="text-lg font-bold text-gray-900">
            Start your new recruitment journey now
          </p>
          <p className="text-base font-medium text-gray-600 my-3">
            Tap ‘Add new search’ button to make use of our verified trade
            workers database
          </p>
        </div>
      </div>
    </div>
  )
}

export default EmptySearchResults
