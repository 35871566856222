import React from 'react'
import { Badge, Modal } from 'flowbite-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { TruncatedText } from 'utilis/helpers'

const MoreDetailsModal = ({ isModalOpen, closeModal, title, moreData }) => {
  const dataEntries = moreData ? Object.entries(moreData) : []

  const arrayEntries = dataEntries.filter(([, value]) => Array.isArray(value))
  const otherEntries = dataEntries.filter(([, value]) => !Array.isArray(value))

  const renderValue = value => {
    if (Array.isArray(value)) {
      return (
        <div className="mt-2 mr-2">
          {value.length > 0 ? (
            value.map((item, index) => (
              <div
                key={index}
                className="border border-gray-500 rounded-md mb-2 p-2"
              >
                {typeof item === 'object' ? (
                  <div>
                    {Object.entries(item).map(
                      ([subKey, subValue], subIndex) => (
                        <p
                          key={subIndex}
                          className="font-satoshi text-medium text-gray-900"
                        >
                          <strong>{subKey}:</strong> {subValue}
                        </p>
                      ),
                    )}
                  </div>
                ) : (
                  item
                )}
              </div>
            ))
          ) : (
            <p className="font-satoshi text-medium text-gray-900">
              Not Provided
            </p>
          )}
        </div>
      )
    } else if (typeof value === 'object' && value !== null) {
      return (
        <div className="mr-2">
          {Object.entries(value).map(([subKey, subValue], subIndex) => (
            <p
              key={subIndex}
              className="font-satoshi text-medium text-gray-900"
            >
              <strong>{subKey}:</strong>{' '}
              {typeof subValue === 'object'
                ? JSON.stringify(subValue)
                : subValue}
            </p>
          ))}
        </div>
      )
    } else {
      return (
        <p className="font-satoshi text-medium text-gray-900 mr-2">{value}</p>
      )
    }
  }

  const renderArraySection = (key, value) => {
    if (key === 'Certifications') {
      return (
        <div className="flex items-center flex-wrap space-y-1">
          {Array.isArray(value) && value.length > 0 ? (
            value.map((item, index) => (
              <div key={index} className="mr-2 ml-0">
                {typeof item === 'object' ? (
                  <Badge key={index} className={`bg-blue-100 py-1 rounded-md`}>
                    <a
                      href={item.file_url}
                      className="font-medium text-blue-500 hover:underline flex items-center"
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TruncatedText text={item.title} limit={50} />
                      <FontAwesomeIcon
                        icon={faFileDownload}
                        className="ml-1 text-blue-500"
                      />
                    </a>
                  </Badge>
                ) : (
                  item
                )}
              </div>
            ))
          ) : (
            <p className="font-satoshi text-medium text-gray-900">
              No Certifications Available
            </p>
          )}
        </div>
      )
    } else if (key === 'Quali Tests') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
          {Array.isArray(value) && value.length > 0 ? (
            value.map((item, index) => (
              <div
                key={index}
                className="border border-gray-500 rounded-md p-2"
              >
                {Object.entries(item).map(([subKey, subValue], subIndex) => (
                  <p
                    key={subIndex}
                    className="font-satoshi text-medium text-gray-900"
                  >
                    <strong>{subKey}:</strong> {subValue}
                  </p>
                ))}
              </div>
            ))
          ) : (
            <p className="font-satoshi text-medium text-gray-900">
              No Tests Attempted Yet
            </p>
          )}
        </div>
      )
    }
    return null
  }

  return (
    <Modal
      show={isModalOpen}
      onClose={() => closeModal(false)}
      dismissible
      theme={{
        content: {
          base: 'bg-transparent w-full md:w-3/4',
          inner: 'bg-white p-4 rounded-lg shadow-lg',
        },
      }}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body className="max-h-[350px] overflow-y-auto">
        {otherEntries.length > 0 && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {otherEntries.map(
              ([key, value], index) =>
                key !== 'ID' && (
                  <div key={index} className="flex flex-col mb-2">
                    <h3 className="font-satoshi text-medium font-bold text-black">
                      {key} :
                    </h3>
                    {renderValue(value)}
                  </div>
                ),
            )}
          </div>
        )}
        {arrayEntries.length > 0 && (
          <div className="grid grid-cols-1 gap-2">
            {arrayEntries.map(
              ([key, value], index) =>
                key !== 'ID' && (
                  <div key={index} className="flex flex-col mb-2">
                    <h3 className="font-satoshi text-medium font-bold text-black">
                      {key} :
                    </h3>
                    {renderArraySection(key, value)}
                  </div>
                ),
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
          onClick={() => closeModal(false)}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default MoreDetailsModal
