import React from 'react'
import RegisterForm from './RegisterForm'
import candidateAccountPageImage from '../../assets/images/registration/candidate-account-page.png'
import AppBar from '../../components/Helper/AppBar'

const RegisterCandidate = () => {
  return (
    <>
      <div className="flex flex-col h-[100%] w-full md:px-20 bg-white lg:bg-[#F9FAFB]">
        <div className="lg:py-7 py-5  md:px-0 px-6 ">
          <AppBar />
        </div>
        <main className="flex">
          <div className="flex w-full items-center lg:bg-gray-50 bg-white justify-evenly">
            <RegisterForm />
            <img
              src={candidateAccountPageImage}
              className="rounded-lg hidden md:ml-5 lg-ml-0 lg:block items-center"
              alt="loginimage"
            />
          </div>
        </main>
      </div>
    </>
  )
}

export default RegisterCandidate
