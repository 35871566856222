import { PASSWORD_REGEX } from './constants'
import * as Yup from 'yup'

const emailLoginSchema = Yup.object().shape({
  login: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .matches(PASSWORD_REGEX, 'Invalid password format')
    .required('Password is required'),
});

const phoneLoginSchema = Yup.object().shape({
  login: Yup.string()
    .min(6, 'Invalid whatsapp number')
    .required('Whatsapp number is required'),
  password: Yup.string()
    .matches(PASSWORD_REGEX, 'Invalid password format')
    .required('Password is required'),
});

export const getLoginValidationSchema = (isEmail) => {
  return isEmail ? emailLoginSchema : phoneLoginSchema;
};
