import React, { useEffect, useState } from 'react'
import AdminNavbar from './AdminNavbar'
import { generateColumns, changeTitle } from '../../utilis/helpers/tableHelper'
import { getPaginatedSkillsList } from '../../services/skillService'
import Table from '../table/Table'
import { getIndustries } from '../../services/industryService'

const SkillsList = () => {
  const [skills, setSkills] = useState([])
  const [industries, setIndustries] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState('')

  const fetchSkills = async () => {
    try {
      const response = await getPaginatedSkillsList(currentPage, query)
      const industries = await getIndustries()
      if (response.status === 200) {
        const sortedData = response?.data?.records?.sort((a, b) => b.id - a.id)
        const skills = getSkillWithTitle(sortedData, industries?.data)
        setIndustries(industries?.data)
        setSkills(skills || [])
        setTotalPages(response?.data?.pagy?.total_pages)
        setTotalCount(response?.data?.pagy?.total_count)
      }
    } catch (error) {
      console.error('Error fetching skills:', error)
    }
  }

  useEffect(() => {
    fetchSkills()
  }, [currentPage, query])

  const getSkillWithTitle = (skills, industries) => {
    const skillsWithTitle = skills.map(skill => {
      const industry = industries.find(ind => ind.id === skill.industry_id)
      return {
        ...skill,
        industry: industry ? industry.title : null,
      }
    })

    return skillsWithTitle
  }

  const onSearchSkills = (search, value) => {
    setCurrentPage(1)
    setQuery(value)
  }
  const headers = generateColumns(skills)
  const columns = changeTitle(headers, 'skill')
  return (
    <div className="flex h-screen w-full flex-col bg-[#f9fafb]">
      <AdminNavbar selectedItem="skills" />
      <Table
        columns={columns}
        data={skills}
        title="Skills"
        getSkills={fetchSkills}
        industries={industries}
        onSearch={onSearchSkills}
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        setCurrentPage={setCurrentPage}
      />
    </div>
  )
}

export default SkillsList
