import React, { useEffect, useState } from 'react'
import AdminNavbar from './AdminNavbar'
import { generateColumns } from '../../utilis/helpers/tableHelper'
import Table from '../table/Table'
import { getSentTests } from '../../services/manageTestService'

const ManageTestList = () => {
  const [sentTests, setSentTests] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState('')

  const processTest = item => {
    const {
      id,
      attributes: {
        title: test_name,
        user: {
          first_name: candidate_first_name,
          last_name: candidate_last_name,
        } = {},
        company: { name: company, email: manage_test_user_email, company_user_name } = {},
        test_status: status,
        test_marks: marks = 0,
        test_send_date,
        assessment_result_url,
      } = {},
    } = item || {}

    return {
      id,
      test_name,
      candidate_first_name,
      candidate_last_name,
      company,
      status,
      marks,
      manage_test_user_email,
      company_user_name,
      test_send_date,
      result_url: assessment_result_url || undefined,
    }
  }

  const fetchSentTests = async () => {
    try {
      const response = await getSentTests(currentPage, query)
      if (response.status === 200) {
        const mappedData = response?.data?.records?.map(processTest)
        setSentTests(mappedData || [])
        setTotalPages(response?.data?.pagy?.total_pages)
        setTotalCount(response?.data?.pagy?.total_count)
      }
    } catch (error) {
      console.error('Error fetching industries:', error)
    }
  }
  useEffect(() => {
    fetchSentTests()
  }, [currentPage, query])

  const onSearchTest = (search, value) => {
    setCurrentPage(1)
    setQuery(value)
  }

  const columns = generateColumns(sentTests)
  return (
    <div className="flex h-screen w-full flex-col bg-[#f9fafb]">
      <AdminNavbar selectedItem="manage-test" />
      <Table
        columns={columns}
        data={sentTests}
        title="Manage Test"
        getManageTests={fetchSentTests}
        hideCreateButton={true}
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        setCurrentPage={setCurrentPage}
        hideDeleteButton={true}
        hasFileUpload={true}
        onSearch={onSearchTest}
      />
    </div>
  )
}

export default ManageTestList
