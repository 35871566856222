import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const NavItem = ({ to, icon, text, badge }) => {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <Link to={to}>
      <li className={`flex items-center space-x-2 p-2 rounded-lg ${pathname === to && 'bg-blue-100'}`}>
        {icon}
        <span className="text-black">{text}</span>
        {badge && <span className="bg-white text-blue-600 text-sm px-2 rounded-full">{badge}</span>}
      </li>
    </Link>
  );
};

