import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { TextInput } from 'flowbite-react'
import 'assets/css/user-settings-tab.css'
import { candidateDeactivateHiredValidationSchema } from 'validations/UserSettingsTabValidationSchema'

export const DeactivationEmployerFormFields = ({
  handleHiredCompanyChange,
  updateFormValidity,
}) => {
  const formik = useFormik({
    initialValues: {
      company_name: '',
      salary: '',
    },
    validationSchema: candidateDeactivateHiredValidationSchema,
    onSubmit: () => {},
    validateOnChange: true,
    validateOnBlur: true,
  })

  const { values, errors, touched, handleChange, handleBlur } = formik

  useEffect(() => {
    updateFormValidity(Object.keys(errors).length === 0)
    handleHiredCompanyChange({
      target: { name: 'company_name', value: values.company_name },
    })
    handleHiredCompanyChange({
      target: { name: 'salary', value: values.salary },
    })
  }, [errors, values, updateFormValidity, handleHiredCompanyChange])

  return (
    <div className="flex flex-row gap-x-4 mb-1 deactivate-form-fields">
      <div className="flex flex-col min-w-52 main-deactivate-form-field">
        <div>
          <label className="text-xs text-[#6B7280] font-medium">
            Employer Name
          </label>
        </div>
        <TextInput
          id="company_name"
          name="company_name"
          type="text"
          placeholder="Company"
          value={values.company_name}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={30}
        />
        {touched.company_name && errors.company_name && (
          <p className="text-xs text-red-500">{errors.company_name}</p>
        )}
      </div>

      <div className="flex flex-col min-w-52 main-deactivate-form-field">
        <div>
          <label className="text-xs text-[#6B7280] font-medium">
            Accepted Yearly Offer
          </label>
        </div>
        <TextInput
          id="salary"
          name="salary"
          type="text"
          placeholder="Salary"
          value={values.salary}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={10}
        />
        {touched.salary && errors.salary && (
          <p className="text-xs text-red-500">{errors.salary}</p>
        )}
      </div>
    </div>
  )
}
