import React, { useState } from 'react'
import { downloadCandidatesCSV } from 'services/userService'
import CustomSpinner from '../Spinner/CustomSpinner'

const CSVDownloadButton = () => {
  const [loading, setLoading] = useState(false)

  const handleCSVDownload = async () => {
    setLoading(true)

    try {
      const response = await downloadCandidatesCSV()
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'text/csv' }),
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'candidates.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="relative inline-block ml-auto">
      <button
        onClick={handleCSVDownload}
        type="button"
        className={`ml-auto mr-5 mb-2 text-sm mt-1 py-2 px-4 rounded-lg ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-700'} text-white flex items-center justify-center space-x-2`}
        disabled={loading}
      >
        {loading ? (
          <div className="flex flex-row items-center justify-center space-x-2">
            <span>Exporting...</span>
            <CustomSpinner size="sm" />
          </div>
        ) : (
          'Export as CSV'
        )}
      </button>
    </div>
  )
}

export default CSVDownloadButton
