import React , { useEffect , useState} from 'react'
import AppBar from 'components/Dashboard/AppBar'
import { AppSideBar } from 'components/Dashboard/AppSideBar'
import { EmployerInboxView } from 'components/Messaging/EmployerMessaging/EmployerInboxView/EmployerInboxView'
import { useLocation } from 'react-router-dom'
export const EmployerFullScreenInboxView = () => {
  const location = useLocation()
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      candidateId: params.get('candidateId'),
      searchHistoryId: params.get('searchHistoryId')
    }
  }

  const { candidateId ,searchHistoryId } = getQueryParams()
  const [searchId, setSearchId] = useState(searchHistoryId|| null)
  const [updateFlag, setUpdateFlag] = useState(false)
  useEffect(() => {
    updateFlag && setSearchId(null)
  },
  [updateFlag])
  return (
    <div className="h-full bg-[#f9fafb] font-satoshi-regular">
      <AppBar />
      <div className="flex flex-col md:flex-row p-4 md:p-10 min-h-screen">
        <div className="hidden md:block">
          <AppSideBar />
        </div>
        <div className="flex flex-col w-full md:w-5/6 sm:pl-4">
          <EmployerInboxView defaultId={candidateId} searchId= {searchId} setSearchId ={setSearchId}  />
        </div>
      </div>
    </div>
  )
}
