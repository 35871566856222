import React, { useEffect, useState } from 'react'
import AppBar from '../AppBar'
import { TopHeader } from '../../Search/TopHeader'
import { useSelector, useDispatch } from 'react-redux'
import { CandidateAppSideBar } from '../CandidateSideBar'
import { editCandidateTest } from '../../../services/manageTestService'
import { Breadcrumb } from '../../../../src/utilis/helpers/breadcrumbHelper'
import TestOverview from '../../../components/Dashboard/Overview/TestOverview'
import '../../../assets/css/cborder.css'
import { useLocation } from 'react-router-dom'
import {ensureHttpsUrl} from 'utilis/helpers/index'
import Table from 'components/table/Table'
import { getCurrentUser } from 'services/userService'
import {
  selectCurrentUser,
  setCurrentUser,
} from '../../../redux/currentUserSlice'
import { PersistentInbox } from 'components/Messaging/PersistentMinimizableInbox'
import { generateColumns } from '../../../utilis/helpers/tableHelper'
import { GetPaggyCandidateStoredInformation } from 'services/testService'
import { useNavigate } from 'react-router-dom'
import { isMobileDevice } from 'utilis/helpers'
const TestListofCandidate = () => {
  const { state } = useLocation()
  const { Testname = '', _pathname = '', TestId } = state || {}
  const { currentUser } = useSelector(selectCurrentUser)
  const [data, setData] = useState({})
  const [testUpdate, setTestUpdate] = useState(false)
  const [candidates, setCandidates] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [sortState, setSortState] = useState('')
  const [status, setStatus] = useState('sent')
  const [order, setOrder] = useState('')
  const dispatch = useDispatch()
  const isMobile = isMobileDevice()

  const location=useLocation()
  const navigate = useNavigate()

  const getTestData = async () => {
    setIsLoading(true)
    const response = await GetPaggyCandidateStoredInformation(
      currentPage,
      status,
      sortState,
      order,
    )
    if (response.status === 200) {
      const { test = [], pagy = {} } = response?.data
      const mappedData = test.map(
        ({
          title,
          test_link,
          id,
          description,
          test_score,
          sent_date,
          test_status,
        }) => {
          return {
            'Test Name': title,
            'Test Link': test_link,
            'Test id': id,
            'Test Description': description,
            [isMobile ? 'Score' : 'Overall Score']:test_score,
            'SENT date': sent_date,
            Status: test_status,
          }
        },
      )

      setData(response.data)
      setCandidates(mappedData)
      setTotalPages(pagy.total_pages)
      setTotalCount(pagy.total_count)
    }
    setIsLoading(false)
  }

  const fetchCurrentUser = async () => {
    try {
      const response = await getCurrentUser()
      dispatch(setCurrentUser(response?.data))
    } catch (err) {
      if (err?.response?.status === 422) {
        localStorage.removeItem('accessToken')
        navigate('/')
      }
      console.error('Error while fetching user:', err)
    }
  }
  const handleCompleteTest = async (link, test) => {
    try {

      await editCandidateTest(test)
      await Promise.all ([setTestUpdate(prev => !prev),
      getTestData(),
      fetchCurrentUser()])
    } catch (error) {
      console.error('Error updating test', error)
    }

    link = ensureHttpsUrl(link)
    try {
      setTimeout(() => {
        if (isMobile || !(navigator.userAgent.search("Chrome"))) {
          window.location.href = link;
        } else {
          window.open(link, '_blank');
        }
      }, 100)
      } catch (error) {
      console.error('Error completing test or navigating to link:', error);
      }
  }
  useEffect(() => {
    fetchCurrentUser()
    setData(data || [])
  }, [data, testUpdate])

  const onSort = value => {
    value ? setStatus(value) : setStatus('all')
    value !== status && setCurrentPage(1)
  }

  const handleSort = (order, column) => {
    if (column === 'Overall Score'|| column==='Score') {
      setSortState('test_score')
    }
    if (column === 'SENT date') {
      setSortState('sent_date')
    }
    if (column.toLowerCase() === 'test name') {
      setSortState('test_title')
    }
    setOrder(order)
  }

  useEffect(() => {
    getTestData()
  }, [currentPage, status, sortState, order, TestId])

  const breadcrumbItems = ['Test and Assessment']

  const columns = generateColumns(candidates, location, isMobile)
  return (
    <div className="h-full bg-[#f9fafb] font-satoshi-regular">
      <AppBar />
      <div className="flex flex-col md:flex-row p-2 md:p-10">
        <div className="hidden md:block">
          <CandidateAppSideBar />
        </div>
        <div className="flex flex-col w-full md:w-3/4 sm:pl-4">
          <TopHeader />
          <div className="border-1.5 border-gray-400 rounded-lg bg-white mt-5 mr-4 shadow-md">
            <div className="mt-5 ml-6">
              <Breadcrumb items={breadcrumbItems} />
            </div>
            <TestOverview data={data} />
          </div>
          <div className="mb-10">
            <Table
              columns={columns}
              data={candidates}
              title="Candidate"
              onSort={onSort}
              sortState={status}
              getCandidates={getTestData}
              currentPage={currentPage}
              totalPages={totalPages}
              totalCount={totalCount}
              setCurrentPage={setCurrentPage}
              handleSort={handleSort}
              handleCompleteTest={handleCompleteTest}
              hideEditButton
              hideDeleteButton
              hideCreateButton
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
      <PersistentInbox />
    </div>
  )
}

export default TestListofCandidate
