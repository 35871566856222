import React, { memo } from 'react'
import { useState } from 'react'
import { Resizable } from 're-resizable'
import crossIcon from 'assets/svgs/black-x-outline.svg'
import paperclipicon from 'assets/svgs/paper-clip.svg'
import expandGreyIcon from 'assets/svgs/arrow-expand-grey-outline.svg'
import { usePersistantModule } from '../PersistantModuleContext'
import { CandidateOpenMessageView } from 'components/Messaging/CandidateMessaging/CandidateOpenMessageView/CandidateOpenMessageView'
import { InitialLetters } from 'components/generics/Initials'
import { useSelector } from 'react-redux'
import { setProfileStatus } from 'services/userService'
import { selectCurrentUser } from '../../../../redux/currentUserSlice'

const ChatHeader = memo(
  ({
    avatar,
    chatName,
    handleExpandChat,
    handleCloseChat,
    handleAvatarClick,
    selectedChat
  }) => (
    <div className="flex flex-row p-4 justify-between items-center bg-[#E1EFFE] rounded-t-lg min-w-[443px] min-h-[67px]">
      <div className="flex flex-row gap-4 items-center">
        <button onClick={()=>handleAvatarClick(selectedChat)}>
          {avatar ? (
            <img
              src={avatar}
              alt="avatar logo"
              className="rounded-full w-8 h-8"
            />
          ) : (
            <div className="flex justify-center items-center p-5 rounded-full text-2xl bg-gray-100 border-gray-100 h-8 w-8">
              <InitialLetters
                Name={chatName}
              />
            </div>
          )}
        </button>
        <span className="text-xl font-medium text-[#374151]">{chatName}</span>
      </div>
      <div className="flex flex-row gap-2">
        <button onClick={handleExpandChat}>
          <img src={expandGreyIcon} alt="expand icon" className="w-5 h-5" />
        </button>
        <button onClick={handleCloseChat}>
          <img
            src={crossIcon}
            alt="close icon"
            className="w-5 h-5 text-black"
          />
        </button>
      </div>
    </div>
  ),
)

const ChatInput = memo(
  ({ userMessage, handleTextChange, handleSendMessage }) => (
    <div className="flex flex-col w-full">
      <div className="border-t border-b border-gray-200">
        <textarea
          id="send-chat"
          rows="4"
          className="block border-none p-4 w-full text-sm text-gray-900 resize-none max-h-[72px] focus:ring-0"
          placeholder="Write a reply ..."
          value={userMessage}
          onChange={handleTextChange}
          name="send-chat"
          maxLength={300}
        ></textarea>
      </div>
      <div className="flex flex-row p-4 items-center justify-between border-b border-gray-200">
        <img
          src={paperclipicon}
          alt="File icon"
          className="rounded-full w-4 h-4"
        />
        <button
          type="button"
          onClick={handleSendMessage}
          className="text-xs font-medium px-3 py-2 rounded-lg bg-[#1A56DB] text-white border"
        >
          Send message
        </button>
      </div>
    </div>
  ),
)

export const ChatPopupViewModal = memo(
  ({
    avatar,
    chatName,
    userMessage,
    isChatExpanded,
    handleExpandChat = () => {},
    handleCloseChat = () => {},
    handleSendMessage = () => {},
    handleTextChange = () => {},
    selectedChat,
  }) => {
    const { handleAvatarClick } = usePersistantModule()
    const [candidateStatusUpdated, setCandidateStatusUpdate] = useState(false)
    const { currentUser } = useSelector(selectCurrentUser);
    const handleSetstatus = async (status) => {
        const data = {
          profile_status: status,
          user_id: selectedChat?.user?.id,
          company_id: currentUser?.company?.id,
          profile_feedback:'',
          user_name: `${selectedChat?.user?.first_name} ${selectedChat?.user?.last_name}`,
          company_name: currentUser?.company?.name,
          search_history_id: selectedChat?.search_history?.id
        }
        const response = await setProfileStatus(data)
        if (response.status !== 200) {
          console.error('Error hiring candidate')
        }
    }
    return (
      <Resizable
        defaultSize={{
          width: 500,
          height: isChatExpanded ? 700 : 500,
        }}
        minWidth={500}
        minHeight={isChatExpanded ? 700 : 500}
        lockAspectRatio={isChatExpanded}
      >
        <div className="border border-gray-300 rounded-t-lg h-full flex flex-col mr-4 shadow-sm bg-white">
          <ChatHeader
            avatar={currentUser.role==='company_admin' ? selectedChat?.user?.profile_picture_url :avatar}
            chatName={currentUser.role==='company_admin' ?  `${selectedChat.user.first_name} ${selectedChat.user.last_name}` : chatName }
            handleExpandChat={handleExpandChat}
            handleCloseChat={handleCloseChat}
            handleAvatarClick={handleAvatarClick}
            selectedChat={selectedChat}
          />
          <CandidateOpenMessageView
            avatar={avatar}
            chatName={currentUser?.role === 'company_admin' ? currentUser?.company?.name : `${currentUser?.first_name} ${currentUser?.last_name}`}
            selectedChat={selectedChat}
            hide={true}
            isChatExpanded={isChatExpanded}
            currentUser={currentUser}
            handleSetstatus={handleSetstatus}
            setCandidateStatusUpdate={setCandidateStatusUpdate}
          />
        </div>
      </Resizable>
    )
  },
)
