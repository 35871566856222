import React, { createContext, useState, useContext } from 'react'

const SearchContext = createContext(null)

export const SearchProvider = ({ children }) => {
  const [searchData, setSearchData] = useState([])

  return (
    <SearchContext.Provider value={{ searchData, setSearchData }}>
      {children}
    </SearchContext.Provider>
  )
}

export const useSearch = () => useContext(SearchContext)
