import { createSlice } from '@reduxjs/toolkit'

export const candidateFeedsSlice = createSlice({
  name: 'feeds',
  initialState: [],
  reducers: {
    replaceCandidateFeeds: (state, action) => {
      return action.payload
    },
    appendCandidateFeeds: (state, action) => {
      return [...action.payload, ...state];
    },
  },
})

export const { replaceCandidateFeeds, appendCandidateFeeds } = candidateFeedsSlice.actions

export const candidateFeedReducer = candidateFeedsSlice.reducer
export const selectCandidateFeeds = state => state.candidateFeed
