import React, { useState } from 'react'
import EditActionModal from './EditActionModal'
import searchIcon from 'assets/svgs/searchicon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import pasteicon from '../../assets/svgs/paste.svg'
import { ReactComponent as ChevronArrow } from '../../assets/svgs/angle-down-outline.svg'
import { ReactComponent as Exclamation } from '../../assets/svgs/exclamation-black.svg'
import icon from '../../assets/svgs/icon.svg'
import CreateActionModal from './CreateActionModal'
import DeleteActionModal from './DeleteActionModal'
import FileUploadModal from './FileUploadModal'
import { excludedColumns } from '../../utilis/helpers/tableHelper'
import { deleteCompany } from '../../services/companyService'
import { TruncatedText, formatText } from 'utilis/helpers'
import { deleteIndustry } from '../../services/industryService'
import { deleteSkill } from '../../services/skillService'
import { isMobileDevice } from 'utilis/helpers'
import { deleteExpertise } from '../../services/expertiseService'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'
import {
  deleteTest,
  uploadTestResult,
  uploadTestsCSV,
} from '../../services/testService'
import useToastMessage from '../../hooks/useToastMessage'
import PaginationFooter from './PaginationFooter'
import { deleteUser } from '../../services/userService'
import MoreDetailsModal from './MoreDetailsModal'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/currentUserSlice'
import CSVDownloadButton from 'components/generics/CSV/CSVDownloadButton'
import { Tooltip } from 'flowbite-react'
import Loader from 'components/generics/loader/Loader'

const Table = ({
  columns,
  onSort,
  sortState,
  handleSort,
  data,
  getCompanies,
  title,
  getIndustries,
  industries,
  getSkills,
  getExpertise,
  skills,
  hideCreateButton,
  getTests,
  hideEditButton,
  hideDeleteButton,
  onSearch,
  currentPage,
  totalPages,
  totalCount,
  setCurrentPage,
  getCandidates,
  testsListingForEdit,
  getManageTests,
  hasFileUpload,
  showBulkUploadButton,
  hasMoreDetails,
  moreDetails,
  handleCompleteTest,
  downloadCSV,
  isLoading,
}) => {
  const location = useLocation()
  const [sortstate, setsortstate] = useState(sortState || '')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [isModalOpenForCreate, setIsModalOpenForCreate] = useState(false)
  const [isModalOpenForDelete, setIsModalOpenForDelete] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false)
  const [uploadType, setUploadType] = useState('')
  const [viewMore, setViewMore] = useState(false)
  const { currentUser } = useSelector(selectCurrentUser)
  const toastMessage = useToastMessage()
  const isMobile = isMobileDevice()

  const getTagColor = status => {
    switch (status.toLowerCase()) {
      case 'progress':
        return 'bg-blue-100 text-blue-800'
      case 'completed':
      case 'hired':
        return 'bg-green-100 text-green-800'
      case 'passed':
        return 'bg-red-100 text-red-800'
      case 'sent':
        return 'bg-yellow-100 text-yellow-800'
      default:
        return 'bg-yellow-100 text-yellow-800'
    }
  }

  const StatusButton = ({ status }) => {
    const colorClass = getTagColor(status)
    return (
      <button
        className={`${colorClass} text-xs font-medium py-1 rounded w-20 text-center`}
      >
        {status?.toLowerCase() !== 'progress'
          ? location.pathname === '/test-list-of-candidiate' &&
            status === 'sent'
            ? 'Pending'
            : formatText(status)
          : 'In Progress'}
      </button>
    )
  }

  const handleOnBulkUpload = () => {
    setUploadType('csv_tests')
    setIsFileUploadModalOpen(true)
  }

  const handleOpenFileUploadModal = id => {
    setSelectedId(id)
    setIsFileUploadModalOpen(true)
  }

  const handleCloseFileUploadModal = () => {
    setIsFileUploadModalOpen(false)
  }

  const handleSortChange = e => {
    const value = e.target.value
    setsortstate(value)
    onSort(value)
  }
  const handleFileUpload = async file => {
    const formData = new FormData()
    formData.append('file', file)

    try {
      let response
      if (uploadType === 'csv_tests') {
        response = await uploadTestsCSV(formData)
      } else {
        response = await uploadTestResult(selectedId, formData)
      }
      if (response.status === 200) {
        toastMessage('File uploaded successfully!', 'success')
        if (uploadType === 'csv_tests') {
          getTests()
        } else {
          getManageTests()
        }
      } else {
        const errorData = await response.json()
        toastMessage(
          `File upload failed: ${errorData.errors.join(', ')}`,
          'error',
        )
      }
    } catch (error) {
      console.error('Error uploading file:', error)
      toastMessage(
        uploadType === 'csv_tests'
          ? error?.response?.data?.errors
          : 'File upload failed',
        'error',
      )
    }
  }

  const openModal = itemId => {
    const selectedItem = data.find(item => item.id === itemId)
    setSelectedItem(selectedItem)
    setIsModalOpen(true)
  }

  const openViewMoreModal = itemId => {
    const selectedItem = moreDetails?.find(item => item.ID === itemId)
    setSelectedItem(selectedItem)
    setViewMore(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleOnCreateModal = () => {
    setIsModalOpenForCreate(true)
  }

  const handleCloseCreateModal = () => {
    setIsModalOpenForCreate(false)
  }

  const handleOnDeleteModal = id => {
    setSelectedId(id)
    setIsModalOpenForDelete(true)
  }

  const onDelete = async () => {
    try {
      if (title.includes('Company')) {
        const response = await deleteCompany(selectedId)

        if (response?.status === 200) {
          toastMessage('Company deleted successfully!', 'success')
          setIsModalOpenForDelete(false)
          getCompanies()
        }
      } else if (title.includes('Industry')) {
        const response = await deleteIndustry(selectedId)

        if (response?.status === 200) {
          toastMessage('Industry deleted successfully!', 'success')
          setIsModalOpenForDelete(false)
          getIndustries()
        }
      } else if (title.includes('Skills')) {
        const response = await deleteSkill(selectedId)

        if (response?.status === 200) {
          toastMessage('Skill deleted successfully!', 'success')
          setIsModalOpenForDelete(false)
          getSkills()
        }
      } else if (title.includes('Test')) {
        const response = await deleteTest(selectedId)

        if (response?.status === 200) {
          toastMessage('Test deleted successfully!', 'success')
          setIsModalOpenForDelete(false)
          getTests()
        }
      } else if (title.includes('Expertise')) {
        const response = await deleteExpertise(selectedId)

        if (response?.status === 200) {
          toastMessage('Expertise deleted successfully!', 'success')
          setIsModalOpenForDelete(false)
          getExpertise()
        }
      } else if (title.includes('Candidate')) {
        const response = await deleteUser(selectedId)

        if (response?.status === 200) {
          toastMessage('User deleted successfully!', 'success')
          setIsModalOpenForDelete(false)
          getCandidates()
        }
      }
    } catch (error) {
      console.error('Error while deletion', error)
    }
  }

  const handleSearchChange = e => {
    if (location.pathname !== '/candidate-list-of-test') {
      const value = e.target.value
      setSearchQuery(value)

      const filtered = data.filter(item =>
        Object.values(item).some(
          attr =>
            typeof attr === 'string' &&
            attr.toLowerCase().includes(value.toLowerCase()),
        ),
      )
      onSearch && onSearch(filtered, value)
    } else {
      const value = e.target.value
      setSearchQuery(value)
      onSearch(value)
    }
  }

  const handlePageClick = page => {
    setCurrentPage && setCurrentPage(page)
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage && setCurrentPage(currentPage - 1)
    }
  }
  function formatDate(dateString) {
    const date = new Date(dateString)
    const options = { day: '2-digit', month: 'short', year: 'numeric' }
    return date.toLocaleDateString('en-GB', options)
  }
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage && setCurrentPage(currentPage + 1)
    }
  }
  const iscandidatelist = (location.pathname === '/candidate-list-of-test')
  const isTestList = (location.pathname==='/test-list-of-candidiate')
  return (
    <div
      className={`relative overflow-x-auto shadow-md sm:rounded-lg ${currentUser?.role === 'super admin' ? 'mt-10 ml-5' : 'mt-5 bg-white ml-0'} mr-4`}
    >
      <div className="flex flex-row items-center justify-left ml-2">
        <div className={`flex flex-col ${!location.pathname.includes('admin') ? 'w-full mr-5': ''}`}>
          {!isTestList && <div className="relative flex pt-6 pl-2">
            <div className="absolute inset-y-0 left-0 pt-6 flex items-center pl-6 pointer-events-none">
              <img src={searchIcon} alt="Search Icon" className="w-4 h-4" />
            </div>
            <input
              type="text"
              id="table-search"
              className="block pl-10 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-l-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder={`${iscandidatelist ||isTestList ? 'Search candidate': 'Search'}`}
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {location.pathname === '/candidate-list-of-test' && (
              <button
                onClick={handleSearchChange}
                className="bg-blue-600 text-white px-4 py-2 text-sm rounded-r-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800"
              >
                Search
              </button>
            )}
          </div>}
          {(location.pathname === '/candidate-list-of-test' || location.pathname === '/test-list-of-candidiate') && (

            <div className={`flex flex-wrap items-center ${location.pathname === '/candidate-list-of-test' ? 'border-t mt-4 ml-2 md:mb-2' : 'md:pb-6'}  space-x-4 `}>
              <span className="text-sm  ml-3 font-medium mt-4 text-gray-900 dark:text-gray-300">
                Show Only :
              </span>
              <label className="flex items-center mt-4">
                <input
                  type="radio"
                  name="sort"
                  value=""
                  className="radio"
                  onChange={handleSortChange}
                  checked={sortstate === ''}
                />
                <span className="ml-4 text-sm  text-gray-900 peer-checked:text-blue-600 peer-checked:font-medium cursor-pointer">
                  All
                </span>
              </label>

              <label className="flex items-center mt-4">
                <input
                  type="radio"
                  name="sort"
                  value="completed"
                  className="radio"
                  onChange={handleSortChange}
                  checked={sortstate === 'completed'}
                />
                <span className="ml-2 text-sm text-gray-900 peer-checked:text-blue-600 peer-checked:font-medium cursor-pointer">
                  Completed
                </span>
              </label>
              <label className="flex mt-4 items-center">
                <input
                  type="radio"
                  name="sort"
                  value="progress"
                  className="radio"
                  onChange={handleSortChange}
                  checked={sortstate === 'progress'}
                />
                <span className="ml-2 text-sm text-gray-900 peer-checked:text-blue-600 peer-checked:font-medium cursor-pointer">
                  In Progress
                </span>
              </label>
              <label className="flex mt-4 items-center">
                <input
                  type="radio"
                  name="sort"
                  value="sent"
                  className="radio"
                  onChange={handleSortChange}
                  checked={sortstate === 'sent'}
                />
                <span className="ml-2 text-sm text-gray-900 peer-checked:text-blue-600 peer-checked:font-medium cursor-pointer">
                  {currentUser?.role === 'candidate' ? 'Pending' : 'Sent'}
                </span>
              </label>
            </div>
          )}
        </div>

        {!hideCreateButton && (
          <button
            onClick={handleOnCreateModal}
            type="submit"
            className=" bg-blue-700  mt-6 text-sm mt-1 py-2 px-4 rounded-r-lg text-white"
          >
            Create
          </button>
        )}
        {showBulkUploadButton && (
          <button
            onClick={handleOnBulkUpload}
            type="submit"
            className="ml-2 bg-blue-700 mb-2 text-sm mt-1 py-2 px-4 rounded-lg text-white"
          >
            Upload CSV
          </button>
        )}
        {downloadCSV && <CSVDownloadButton />}
      </div>

      {data?.length === 0 && !isLoading && (
        <>
          {location.pathname === '/candidate-list-of-test' && (
            <div className="h-20 my-8">
              <p className="text-gray-500 text-center md:px-0 px-2 mt-4 text-[16px] font-medium leading-10">
                {sortstate === 'completed' ? (
                  <>
                    No Quali Test completed for this test yet.
                    <br />
                    If you send the test, remind the candidate by contacting
                    them directly.
                  </>
                ) : (
                  <>
                    No Quali Test{' '}
                    {sortstate
                      ? 'is in progress or pending'
                      : 'has been sent to the candidate'}{' '}
                    for this test yet.
                    <br />
                    When you share a test with a candidate, it will appear here.
                  </>
                )}
              </p>
            </div>
          )}

          {location.pathname === '/test-list-of-candidiate' && (
            <div className="mb-48">
              <div className="h-20 my-8">
                <p className="text-gray-500 text-center mt-4 text-[16px] font-medium leading-10">
                  {!sortstate
                    ? 'Nothing to show yet.'
                    : `No ${sortstate === 'sent' ? 'pending' : ''} Quali Test ${sortstate !== 'sent' ? (sortstate === 'progress' ? 'in progress' : sortstate) : ''} yet.`}
                  <br />
                  Companies will invite you to complete assessment. Check your
                  Quali inbox regularly.
                </p>
              </div>
            </div>
          )}
        </>
      )}

      {isLoading ?
      <div className='mb-10'>
        <Loader color="blue"/>
        </div>
        :
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-500 uppercase bg-white border-b dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {columns.map(
                (column, index) =>
                  !excludedColumns.includes(column.header) && (
                    <>
                      <th
                        key={index}
                        scope="col"
                        className={`${isMobile ? 'md:px-6 py-3 md:pr-0 md:pl-0 pl-2 px-2 pr-20' : 'px-6 py-3'}`}
                      >
                        <div className="flex items-center">
                          <span>{column.header}</span>
                          {currentUser?.role === 'super_admin' ? (
                            <img src={icon} alt="icon" className="ml-2" />
                          ) : (
                            (column.header?.toLowerCase() === 'overall score' ||
                              column.field === 'Score' ||
                              column.header?.toLowerCase() === 'sent date' ||
                              column.header?.toLowerCase() === 'test name') && (
                              <div className="flex flex-col">
                                <button
                                  onClick={() =>
                                    handleSort && handleSort('asc', column.header)
                                  }
                                  className="flex items-center justify-center"
                                >
                                  <ChevronArrow className="rotate-180 ml-2 w-2 h-2" />
                                </button>
                                <button
                                  onClick={() =>
                                    handleSort &&
                                    handleSort('desc', column.header)
                                  }
                                  className="flex items-center justify-center"
                                >
                                  <ChevronArrow className="ml-2 w-2 h-2" />
                                </button>
                              </div>
                            )
                          )}
                        </div>
                      </th>
                    </>
                  ),
              )}

              {location.pathname === '/candidate-list-of-test' && (
                <>
                  <span> </span>
                </>
              )}

              {hasMoreDetails && (
                <th scope="col" className="px-6 py-3">
                  Others
                </th>
              )}

              {!(hideEditButton && hideDeleteButton) && (
                <th scope="col" className="px-6 py-3">
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr
                key={index}
                className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`}
              >
                {columns?.map(
                  (column, idx) =>
                    !excludedColumns?.includes(column.header) && (
                      <td
                        key={idx}
                        className={`md:px-6 px-3 py-4 text-gray-900 ${data.field === 'Test Description' && data.field === 'SENT date' && 'hidden md:block'}  break-words max-w-[200px] lg:max-w-none`}
                      >
                        <div className="md:text-black text-gray-700 md:ml-0 ml-2">
                          {column?.field === 'status' ||
                            [
                              'sent',
                              'completed',
                              'progress',
                              'In Progress',
                            ].includes(item[column.field]) ? (
                            <StatusButton status={item[column.field]} />
                          ) : column.field === 'Sent Date' ||
                            column.field === 'SENT date' ? (
                            formatDate(item[column.field])
                          ) : column.header === 'Test Description' ? (
                            <TruncatedText
                              text={item[column.field]}
                              limit={50}
                              location={location}
                            />
                          ) : column.field === 'Test Name' ||
                            column.header === 'Test Description' ? (
                            <div className="flex items-center md:text-sm text-xs">
                              {item[column.field]}
                              <span />
                              <Tooltip
                                style="light"
                                content={item['Test Description']}
                                placement="right"
                                className="text-gray-500 w-1/2"
                              >
                                <FontAwesomeIcon
                                  icon={faCircleQuestion}
                                  className="  lg:hidden block ml-2 w-4 h-4 text-black cursor-pointer"
                                />
                              </Tooltip>
                            </div>
                          ) : column.field === 'Overall Score' ||
                            column.field === 'Score' ? (
                            item[column.field] === 0 ? (
                              '-'
                            ) : (
                              <div className="flex items-center gap-2">
                                <span
                                  className={`flex items-center ${item[column.field] < 50 ? 'text-red-800' : 'text-black'}`}
                                >
                                  {`${item[column.field]}%`}
                                  {item[column.field] < 50 &&
                                    currentUser?.role === 'candidate' && (
                                      <span className="ml-2">
                                        <Tooltip
                                          style="light"
                                          content="Failed: your score falls below the passing grade"
                                          className="text-gray-500"
                                        >
                                          <Exclamation className="w-5 h-5 text-red-800" />
                                        </Tooltip>
                                      </span>
                                    )}
                                </span>
                              </div>
                            )
                          ) : column.render ? (
                            column.render(item[column.field])
                          ) : (
                            item[column.field]
                          )}
                        </div>
                      </td>
                    ),
                )}

                {hasMoreDetails && (
                  <td className="px-6 py-4">
                    <button
                      onClick={() => openViewMoreModal(item.id)}
                      type="button"
                      className="ml-1 font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      More Details
                    </button>
                  </td>
                )}
                {currentUser?.role !== 'super_admin' && (
                  <td className="md:px-2 ml-20 -mr-15">
                    {location.pathname === '/candidate-list-of-test' && (
                      <button
                        type="submit"
                        className="ml-8 bg-blue-700 mb-2 md:text-sm text-xs mt-1 py-2 px-4 rounded-lg text-white flex items-center"
                      >
                        <FontAwesomeIcon
                          icon={faBriefcase}
                          style={{ color: '#fcfcfc' }}
                          className="mr-2"
                        />
                        See Pipeline
                      </button>
                    )}

                    {location.pathname === '/test-list-of-candidiate' &&
                      (item['Status'] === 'sent' ||
                        item['Status'] === 'progress') && (
                        <>
                          {item['Status'] === 'progress' && (
                            <Tooltip
                              style="light"
                              content={
                                'If you have completed this test, you do not need to click'
                              }
                              placement="right"
                              className={`text-gray-500 w-1/3`}
                            >
                              <button
                                type="submit"
                                className="md:ml-8 md: -ml-16 -ml-20 bg-blue-700 mb-2 md:text-sm text-xs mt-1 py-2 md:px-4 px-2 rounded-lg text-white flex items-center"
                                onClick={() =>
                                  handleCompleteTest?.(item['Test Link'], {
                                    id: item['Test id'],
                                    test_status: 'progress',
                                  })
                                }
                              >
                                <img
                                  src={pasteicon}
                                  alt={'Paste icon'}
                                  className="md:mr-2 mr-1"
                                />
                                Complete Test
                              </button>
                            </Tooltip>
                          )}
                          {item['Status'] !== 'progress' && (
                            <button
                              type="submit"
                              className="md:ml-8 md:-ml-16 -ml-20 bg-blue-700 mb-2 md:text-sm text-xs mt-1 py-2 md:px-4 px-3 rounded-lg text-white flex items-center"
                              onClick={() =>
                                handleCompleteTest?.(item['Test Link'], {
                                  id: item['Test id'],
                                  test_status: 'progress',
                                })
                              }
                            >
                              <img
                                src={pasteicon}
                                alt={'Paste icon'}
                                className="md:mr-2 mr-1"
                              />
                              Complete Test
                            </button>
                          )}
                        </>
                      )}
                  </td>
                )}

                {location.pathname !== '/candidate-list-of-test' && (
                  <td className="md:px-6 py-4">
                    {!hideEditButton && (
                      <button
                        onClick={() => openModal(item.id)}
                        type="button"
                        className="ml-1 font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        {title === 'Test'
                          ? 'Send Test'
                          : title === 'Manage Test'
                            ? 'Edit | '
                            : 'Edit'}
                      </button>
                    )}
                    {hasFileUpload &&
                      (item.result_url ? (
                        <a
                          href={item.result_url}
                          className="ml-1 font-medium text-black hover:underline"
                          download
                          target="blank"
                        >
                          Download file
                        </a>
                      ) : (
                        <button
                          type="button"
                          className="ml-1 font-medium text-black hover:underline"
                          onClick={() => handleOpenFileUploadModal(item.id)}
                        >
                          File Upload
                        </button>
                      ))}
                    {!hideDeleteButton &&
                      (title === 'Test' ? (
                        item.test_id && (
                          <>
                            <span className="ml-1 text-gray-600">|</span>
                            <button
                              onClick={() => {
                                handleOnDeleteModal(item.test_id)
                              }}
                              type="button"
                              className="ml-1 font-medium text-red-600 dark:text-red-500 hover:underline"
                            >
                              Delete
                            </button>
                          </>
                        )
                      ) : (
                        <>
                          <span className="ml-1 text-gray-600">|</span>
                          <button
                            onClick={() => {
                              handleOnDeleteModal(item.id)
                            }}
                            type="button"
                            className="ml-1 font-medium text-red-600 dark:text-red-500 hover:underline"
                          >
                            Delete
                          </button>
                        </>
                      ))}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      }

      {totalPages > 1 && (
        <PaginationFooter
          currentPage={currentPage}
          totalPages={totalPages}
          totalCount={totalCount}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          handlePageClick={handlePageClick}
          data={data}
        />
      )}

      {viewMore && (
        <MoreDetailsModal
          isModalOpen={viewMore}
          closeModal={() => setViewMore(!viewMore)}
          title={`${title} Details`}
          moreData={selectedItem}
        />
      )}

      {isModalOpen && (
        <EditActionModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          data={selectedItem}
          headers={columns}
          title={`Edit ${title}`}
          getCompanies={getCompanies}
          getIndustries={getIndustries}
          industries={industries}
          getSkills={getSkills}
          getExpertise={getExpertise}
          skills={skills}
          getTests={getTests}
          getCandidates={getCandidates}
          testsListingForEdit={testsListingForEdit}
          getManageTests={getManageTests}
        />
      )}
      {isModalOpenForCreate && title !== 'Company' && (
        <EditActionModal
          isModalOpen={isModalOpenForCreate}
          closeModal={handleCloseCreateModal}
          headers={columns}
          title={`Create ${title}`}
          getIndustries={getIndustries}
          industries={industries}
          getSkills={getSkills}
          getExpertise={getExpertise}
          skills={skills}
          getCandidates={getCandidates}
          getTests={getTests}
        />
      )}
      {isModalOpenForCreate && title === 'Company' && (
        <CreateActionModal
          isModalOpen={isModalOpenForCreate}
          closeModal={handleCloseCreateModal}
          title={title}
          getCompanies={getCompanies}
        />
      )}
      <DeleteActionModal
        title={title}
        isModalOpenForDelete={isModalOpenForDelete}
        setIsModalOpenForDelete={setIsModalOpenForDelete}
        onDelete={onDelete}
      />

      <FileUploadModal
        isOpen={isFileUploadModalOpen}
        onClose={handleCloseFileUploadModal}
        onUpload={handleFileUpload}
        acceptType={uploadType === 'csv_tests' ? '.csv' : 'application/pdf'}
      />
    </div>
  )
}

export default Table
