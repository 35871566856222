import { GCC_COUNTRIES, STATUS_COLOR_MAP, STATUS_MAP } from 'config/constants'
import { format } from 'date-fns'
import _ from 'lodash'
import moment from 'moment'
import { SessionExpiredResponseData } from 'services/constants'
import { PASSWORD_REGEX } from 'validations/constants'
import { Tooltip } from 'flowbite-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'

export const formatText = key => {
  return key
    ?.replace(/([A-Z])/g, ' $1')
    .replace(/^ /, '')
    .replace(/\b\w/g, char => char.toUpperCase())
}

export const formatPreferenceText = (key, value) => {
  const formatExperience = val =>
    formatText(`${val} year${val > 1 ? 's' : ''} experience`)

  const formats = {
    salaryRange: value ? `${value} SAR` : 'No Expected Salary Provided',
    visaRequired: value ? 'Iqama Available' : 'No Iqama',
    experienceRange: value ? formatExperience(value) : 'No Experience Provided',
    certificationRequired: value
      ? 'Certifications Provided'
      : 'No Certifications Provided',
    transportationOffered: value ? 'Transport Needed' : 'Transport Optional',
    accommodationOffered: value ? 'Housing Needed' : 'Housing Optional',
    annualFlightOffered: value ? 'Flight Needed' : 'Flight Optional',
  }

  const specialKeys = [
    'annualFlightOffered',
    'transportationOffered',
    'accommodationOffered',
  ]

  if (specialKeys.includes(key)) {
    return formats[key]
  }

  return formats[key] || formatText(typeof value === 'string' ? value : key)
}

export const formatCandidateData = data => {
  if (!data || !Array.isArray(data.records)) {
    throw new Error(
      'Invalid data format. Expected an object with records array.',
    )
  }

  const formattedDataArray = data.records.map(record => {
    const candidate = record.attributes.candidate || {}
    const searchHistory = record.attributes.search_history || {}
    const company = record.attributes.company || {}

    const user = {
      id: candidate.id,
      role: candidate.role,
      email: candidate.email,
      all_tests: candidate.all_tests,
      first_name: candidate.first_name,
      last_name: candidate.last_name,
      phone_no: candidate.phone_no,
      citizenship: candidate.citizenship,
      available_start_date: candidate.available_start_date,
      years_of_experience: candidate.years_of_experience,
      expected_compensation: candidate.expected_compensation,
      age_range: candidate.age_range,
      open_to_work_in_countries: candidate.open_to_work_in_countries,
      is_working: candidate.is_working,
      current_job_title: candidate.current_job_title,
      highest_education: candidate.highest_education,
      residence_country: candidate.residence_country,
      certifications: candidate.certifications,
      speaks_languages: candidate.speaks_languages || [],
      active_visa_in_GCC_country: candidate.active_visa_in_GCC_country,
      additional_benefits: candidate.additional_benefits,
      skill_expertise: candidate?.skill_expertise || [],
      work_histories: candidate?.work_histories || [],
      tests: candidate?.tests,
      profile_picture_url: candidate?.profile_picture_url,
      attempted_tests: candidate?.attempted_tests,
    }

    const formattedRecord = {
      user,
      unreadMessageCount: record.attributes?.unread_message_count,
      last_message: record.attributes?.last_message?.last_message,
      time: record.attributes?.last_message?.sent_at,
      expertises: record.attributes?.expertises,
      credit_score: candidate.credit_score,
      candidate_preferences: record.attributes?.candidate_preferences,
      preferences: record.attributes?.preferences,
      expertise_match_score: record.attributes.expertise_match_score || 0,
      job_preference_match_score:
        record.attributes.job_preference_match_score || 0,
      reveal_or_like_status: record.attributes.category,
      profile_status: record.attributes.profile_status,
      search_history: searchHistory,
      lastSignInAt: formatLastActive(record?.attributes?.last_sign_in_at),
      company: {
        id: company.id,
        name: company.name,
        contact_email: company.contact_email,
        contact_number: company.contact_number,
        country: company.country,
        city: company.city,
        industry_id: company.industry_id,
        company_size: company.company_size,
        website_link: company.website_link,
      },
    }

    return formattedRecord
  })

  return formattedDataArray
}

export function formatLastActive(lastSignInAt) {
  if (!lastSignInAt) {
    return 'Not active'
  }

  const lastActiveDate = new Date(lastSignInAt)
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)

  if (isSameDate(lastActiveDate, today)) {
    return 'Today'
  } else if (isSameDate(lastActiveDate, yesterday)) {
    return 'Yesterday'
  } else {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
    return lastActiveDate.toLocaleDateString(undefined, options)
  }
}

function isSameDate(date1, date2) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  )
}

export const roundPercentage = percentage => {
  let roundedValue = Math.round(percentage * 10) / 10

  if (roundedValue < 0) {
    roundedValue = 0
  } else if (roundedValue > 100) {
    roundedValue = 100
  }

  return roundedValue + '%'
}

export function isMobileDevice() {
  const mobileWidthThreshold = 1280

  return window.innerWidth <= mobileWidthThreshold
}

export function extractFileName(url) {
  const segments = url?.split('/')
  const fileName = segments && segments[segments?.length - 1]
  return fileName
}

export function isSmallMobileDevice() {
  const mobileWidthThreshold = 320

  return window.innerWidth <= mobileWidthThreshold
}

export function isMediumMobileDevice() {
  const mobileWidthThreshold = 420

  return window.innerWidth <= mobileWidthThreshold
}

export const getStatusText = status => STATUS_MAP[status] || status

export const getStatusColor = status =>
  STATUS_COLOR_MAP[status] || STATUS_COLOR_MAP.default

export const TruncatedText = ({ text, limit, location }) => {
  const truncatedText = _.truncate(text, {
    length: limit,
    separator: ' ',
    omission: '...',
  })

  return location?.pathname === '/test-list-of-candidiate' ? (
    <div className="flex items-center">
      <div className="font-normal">{truncatedText}</div>
      {text.length > limit && (
        <Tooltip
          content={text}
          placement="top"
          arrow={true}
          style="light"
          className="relative text-sm text-gray-700 bg-white border w-1/3 border-gray-200 rounded-lg shadow-md p-2"
        >
          <FontAwesomeIcon
            icon={faCircleQuestion}
            className="ml-1 w-4 h-4 text-black cursor-pointer"
          />
          <div className="absolute top-full left-1/2 transform -translate-x-1/2 w-2.5 h-2.5 bg-white rotate-45 border-t border-l border-gray-200"></div>
        </Tooltip>
      )}
    </div>
  ) : (
    <div className="font-normal"> {truncatedText}</div>
  )
}

/*
- This function handles the filename extraction from a given URL.
- The URL has two cases:
  - Case 1: The URL is from Google APIs, then we need contentDisposition to extract the filename.
  - Case 2: The URL is from Active Storage, then we need to split it on '/'.
*/
export const getFileNameFromGoogleAPIsUrl = url => {
  try {
    const urlObj = new URL(url)
    const contentDisposition = urlObj.searchParams.get(
      'response-content-disposition',
    )
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(
        /filename\*?=['"]?([^;'" ]+['"]?[^;]*)/,
      )
      if (fileNameMatch) {
        let fileName = fileNameMatch[1]
        fileName = fileName.replace(/^['"]|['"]$/g, '')
        if (fileName.startsWith("UTF-8''")) {
          fileName = fileName.replace("UTF-8''", '')
        }
        return decodeURIComponent(fileName)
      }
    }
    const pathSegments = urlObj.pathname.split('/')
    const activeStorageFileName = pathSegments[pathSegments.length - 1]
    return activeStorageFileName.includes('.')
      ? decodeURIComponent(activeStorageFileName)
      : 'Unknown'
  } catch (error) {
    console.error('Invalid URL:', error)
    return 'Unknown'
  }
}

export const fetchFileFromUrl = async (url, filename) => {
  try {
    const response = await fetch(url)
    const blob = await response.blob()
    return new File([blob], filename, { type: blob.type })
  } catch (error) {
    console.error('Error fetching the file:', error)
    return null
  }
}

export const areObjectsSimilar = (obj1, obj2) => {
  return Object.keys(obj1).every(key => obj1[key] === obj2[key])
}
/*
  Function to return candidate active status text based on last login.
  It categorizes the status as:
  - 'today' if the last login was within the last 7 days.
  - 'Recently Active' if the last login was within the last 30 to 60 days.
  - 'Passive User' if the last login was more than 60 days ago.
*/
export const candidateLastActiveStatus = lastSignInAt => {
  const lastLoginDate = new Date(lastSignInAt)
  const currentDate = new Date()
  const diffTime = Math.abs(currentDate - lastLoginDate)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  if (diffDays <= 7) {
    return 'today'
  } else if (diffDays >= 30 && diffDays <= 60) {
    return 'Recently Active'
  } else if (diffDays > 60) {
    return 'Passive User'
  }
}

export const getCurrentFormattedDate = () => {
  const today = new Date()
  return format(today, 'yyyy-MM-dd')
}

export const areArraysNotEqual = (arr1 = [], arr2 = []) => {
  if (arr1.length !== arr2.length) return true

  const sortedArr1 = [...arr1].sort((a, b) =>
    (a?.value || a) > (b?.value || b) ? 1 : -1,
  )
  const sortedArr2 = [...arr2].sort((a, b) =>
    (a?.value || a) > (b?.value || b) ? 1 : -1,
  )

  return sortedArr1.some(
    (item, index) =>
      item !== sortedArr2[index] && item?.value !== sortedArr2[index]?.value,
  )
}

export const addFlagToCountries = countries => {
  return countries?.map(country => {
    const match = GCC_COUNTRIES.find(c => c.value === country)
    return match
      ? { value: match?.value, flag: match?.flag }
      : { value: country, flag: null }
  })
}

const isObject = value => typeof value === 'object' && value !== null

export const areNestedObjectsSimilar = (obj1, obj2) => {
  if (isObject(obj1) && isObject(obj2)) {
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)
    return (
      keys1.length === keys2.length &&
      keys1.every(key => areNestedObjectsSimilar(obj1[key], obj2[key]))
    )
  }
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    return (
      obj1.length === obj2.length &&
      obj1.every((item, index) => areNestedObjectsSimilar(item, obj2[index]))
    )
  }
  return obj1 === obj2
}

export const getPasswordValidationMessage = isCorrectPassword =>
  isCorrectPassword ? 'Password matched!' : 'Password must match the guidelines'

export const isPasswordMatch = profileData => {
  return profileData?.password === profileData?.repeatPassword
}

export const isCorrectPassword = profileData => {
  return PASSWORD_REGEX.test(profileData?.repeatPassword)
}

export const isSessionOrAuthError = data => {
  return (
    data === SessionExpiredResponseData.message ||
    data?.data === SessionExpiredResponseData.notLoggedIn ||
    data?.error === SessionExpiredResponseData.message ||
    data?.error === SessionExpiredResponseData.notAuthorized ||
    data === SessionExpiredResponseData.revokedToken
  )
}

export const formatDateToUTCTime = date => {
  return moment.utc(date)?.format('MMMM D, YYYY, h:mm A')
}

export const formatFeedDate = dateString => {
  return moment(dateString).format('DD/MM/YYYY')
}

export const extractFeedInfo = text => {
  const regex = /^(.*?) \/ (.*?)( \/ (.*))?$/
  const match = text?.match(regex)

  if (match) {
    const potentialDate = match[2]?.trim()
    const isDate = /^\d{4}-\d{2}-\d{2}$|^\d{1,2} [A-Za-z]+ \d{4}$/.test(
      potentialDate,
    )

    const companyName = isDate ? null : match[1]?.trim()
    const message = isDate ? match[1]?.trim() : match[2]?.trim()
    const date = isDate ? match[2]?.trim() : match[4]?.trim()

    return {
      companyName,
      message,
      date: date,
    }
  }
}

export const ensureHttpsUrl = url => {
  if (!/^https?:\/\//i.test(url)) {
    return 'https://' + url
  }
  return url
}

export const convertArrayToObject = array => {
  return array.reduce((acc, item) => {
    acc[item.key] = item.value
    return acc
  }, {})
}

export const getBodyForAttachment = (
  senderName,
  recieverName,
  currUser,
  message = '',
) => {
  if (message) return message
  return currUser.role === 'company_admin'
    ? `please download, 
    sign, and send it back.`
    : `${senderName} just sent a Signed offer Letter`
}
