import React, { useState } from 'react'
import { ErrorMessage, Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Label, FileInput, Card } from 'flowbite-react'
import { GCC_COUNTRIES } from 'config/constants'
import { updateUser } from 'services/userService'
import { COUNTRY_CITY } from 'data/registerFormData'
import { InitialLetters } from 'components/generics/Initials'
import CountrySelectField from './personal-details-components/CountrySelect'
import { extractFileName, isMobileDevice, TruncatedText } from 'utilis/helpers'
import { candidatePersonalDetailsSchema } from 'validations/CandidateProfileScehma'
import { MultiselectDropdown } from 'components/generics/dropdown/multiselectdropdown'
import { ScrollableDropdown } from 'components/generics/dropdown/ScrollableDropdown'
import 'assets/css/scrollable-dropdown.css'
import {
  setCurrentUser,
  selectCurrentUser,
} from '../../../redux/currentUserSlice'
import { ReactDatePickerComponent } from 'components/generics/reactDatePicker/ReactDatePicker'
import 'assets/css/file-input.css'

export const PersonalDetailsForm = ({ setSelectedCard }) => {
  const isMobile = isMobileDevice()
  const { currentUser } = useSelector(selectCurrentUser)
  const [fileLabel, setFileLabel] = useState(
    extractFileName(currentUser?.profile_picture_name) || 'No file chosen',
  )
  const dispatch = useDispatch()
  const handleSubmit = async values => {
    const user = new FormData()
    user.append('user[citizenship]', values.citizenship)
    user.append('user[date_of_birth]', values.dateOfBirth)
    values.validGccVisa.forEach(visa => {
      user.append('user[active_visa_in_GCC_country][]', visa)
    })
    values.workCountries.forEach(country => {
      user.append('user[open_to_work_in_countries][]', country)
    })
    user.append('user[profile_picture]', values.ProfilePicture)
    const response = await updateUser(currentUser.id, user)
    dispatch(setCurrentUser(response.data))
    setSelectedCard(2)
  }
  return (
    <>
      {currentUser && (
        <Formik
          initialValues={{
            profile_preview: currentUser?.profile_picture_url || null,
            citizenship: currentUser?.citizenship || '',
            validGccVisa: currentUser?.active_visa_in_GCC_country || [],
            dateOfBirth: currentUser?.date_of_birth || '',
            ProfilePicture: currentUser?.profile_picture_url || '',
            workCountries: currentUser?.open_to_work_in_countries || [],
          }}
          validationSchema={candidatePersonalDetailsSchema}
          onSubmit={values => {
            handleSubmit(values)
          }}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <div className="sm:w-4/5">
              <Card className="sm:p-4">
                <div>
                  <h5 className="text-2xl tracking-tight text-gray-900 font-satoshi-variable">
                    Personal Details
                  </h5>
                  <p className="text-gray-600 mt-2 text-base font-medium leading-[1.25]">
                    Let's get you noticed! Fill in your details so we can
                    showcase your profile to potential employers.
                  </p>
                </div>
                <div className="border-dashed border-b-2 border-[#E3E3E3] mt-4 mb-6"></div>
                <div className="flex flex-col xl:flex-row gap-4">
                  <div
                    className={`flex flex-col ${!isMobile ? 'lg:w-1/2' : ''} md:flex-row md:gap-4 items-center`}
                  >
                    {values.profile_preview ? (
                      <div className="flex justify-center items-center h-[80px] w-[90px] below-laptop-xl:h-14 below-laptop-xl:w-16">
                        <div className="h-full w-full overflow-hidden rounded-full">
                          <img
                            src={values.profile_preview}
                            alt={'ProfilePicture'}
                            className="object-cover rounded-full w-full h-full"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className=" flex justify-center items-center p-5 rounded-full text-3xl bg-gray-100 border-gray-100 h-20 w-20 md:h-20 md:w-20">
                        <InitialLetters
                          Name={
                            currentUser?.first_name +
                            ' ' +
                            currentUser?.last_name
                          }
                        />
                      </div>
                    )}
                    <div className="w-full">
                      <Label
                        htmlFor=""
                        value="Upload Profile Picture"
                        className="mb-2 block text-nowrap lg:text-base text-xs"
                      />
                      <div className="relative">
                        <FileInput
                          id="file-upload"
                          name="ProfilePicture"
                          type="file"
                          className="opacity-0 absolute inset-0 w-full cursor-pointer remove-pointer-event"
                          onChange={event => {
                            const file = event.currentTarget.files[0]
                            if (file) {
                              setFileLabel(file.name)
                              setFieldValue('ProfilePicture', file)
                              const reader = new FileReader()
                              reader.onloadend = () => {
                                setFieldValue('profile_preview', reader.result)
                              }
                              reader.readAsDataURL(file)
                            } else {
                              setFileLabel('No file chosen')
                            }
                          }}
                          accept="image/png, image/jpg, image/jpeg"
                        />
                        <div
                          className="border rounded-lg w-full cursor-pointer flex items-center bg-[#F9FAFB] border-[#D1D5DB]"
                        >
                          <button className="bg-gray-900 text-white px-3 py-2 pt-3 rounded-s-lg text-sm">
                            Choose File
                          </button>
                          <span className="ml-5 text-sm">
                            <TruncatedText
                              text={fileLabel}
                              limit={isMobile ? 15 : 25}
                            />
                          </span>
                        </div>
                      </div>
                      <p className="text-xs text-gray-500 mt-2">
                        Note: hiring managers will see them, so make sure it’s
                        clear and professional.
                      </p>
                      <ErrorMessage
                        name="ProfilePicture"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>

                  <div className=" xl:w-1/2 flex flex-col">
                    <Label
                      className="text-gray-900 mb-2 lg:text-base text-xs block"
                      htmlFor="dateOfBirth"
                      value="Date of Birth"
                    />
                    <span>
                      <ReactDatePickerComponent
                        id="dateOfBirth"
                        name="dateOfBirth"
                        value={values.dateOfBirth}
                        setFieldValue={setFieldValue}
                        placeholder={
                          window.innerWidth >= 786
                            ? 'Select Your Date of Birth'
                            : ''
                        }
                      />
                    </span>
                    <ErrorMessage
                      name="dateOfBirth"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <Form>
                  <div className="w-full flex flex-col gap-2 scrollable-dropdown">
                    <Label
                      htmlFor="citizenship"
                      value="Your Nationality"
                      className="text-gray-900 font-medium text-base leading-6"
                    />
                    <ScrollableDropdown
                      name="citizenship"
                      options={Object.keys(COUNTRY_CITY).map(countryName => ({
                        label: countryName,
                        value: countryName,
                      }))}
                      defaultLabel="Select your nationality"
                    />
                    <ErrorMessage
                      name="citizenship"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="w-full flex flex-col gap-2 my-5">
                    <Label
                      htmlFor="validGccVisa"
                      value="Do you have a valid/active VISA in any of the following GCC Countries? "
                      className="text-gray-900 font-medium text-base leading-6"
                    />
                    <MultiselectDropdown
                      placeholder="Select a GCC country only if you currently have a valid visa for that country"
                      dropdownItems={GCC_COUNTRIES}
                      value={values.validGccVisa}
                      onChange={selectedItems =>
                        setFieldValue('validGccVisa', selectedItems)
                      }
                    />
                    <ErrorMessage
                      name="validGccVisa"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="col-span-2">
                    <CountrySelectField />
                    <ErrorMessage
                      name="workCountries"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="my-2 flex gap-4 justify-end next-button">
                    <Button
                      className="bg-blue-700 px-6 py-2"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Next
                    </Button>
                  </div>
                </Form>
              </Card>
            </div>
          )}
        </Formik>
      )}
    </>
  )
}
