export const COUNTRY_CITY = {
  Afghanistan: ['Kabul', 'Kandahar', 'Herat'],
  Albania: ['Tirana', 'Durrës', 'Vlorë'],
  Algeria: ['Algiers', 'Oran', 'Constantine'],
  Andorra: ['Andorra la Vella', 'Escaldes-Engordany', 'Encamp'],
  Angola: ['Luanda', 'Huambo', 'Lobito'],
  Argentina: ['Buenos Aires', 'Córdoba', 'Rosario'],
  Armenia: ['Yerevan', 'Gyumri', 'Vanadzor'],
  Australia: ['Sydney', 'Melbourne', 'Brisbane'],
  Austria: ['Vienna', 'Graz', 'Linz'],
  Azerbaijan: ['Baku', 'Ganja', 'Sumqayit'],
  Bahamas: ['Nassau', 'Freeport', 'West End'],
  Bahrain: ['Manama', 'Riffa', 'Muharraq'],
  Bangladesh: ['Dhaka', 'Chittagong', 'Khulna'],
  Barbados: ['Bridgetown', 'Speightstown', 'Oistins'],
  Belarus: ['Minsk', 'Gomel', 'Mogilev'],
  Belgium: ['Brussels', 'Antwerp', 'Ghent'],
  Belize: ['Belize City', 'Belmopan', 'San Ignacio'],
  Benin: ['Porto-Novo', 'Cotonou', 'Parakou'],
  Bhutan: ['Thimphu', 'Phuntsholing', 'Punakha'],
  Bolivia: ['La Paz', 'Santa Cruz de la Sierra', 'Cochabamba'],
  'Bosnia and Herzegovina': ['Sarajevo', 'Banja Luka', 'Mostar'],
  Botswana: ['Gaborone', 'Francistown', 'Molepolole'],
  Brazil: ['São Paulo', 'Rio de Janeiro', 'Belo Horizonte'],
  Brunei: ['Bandar Seri Begawan', 'Kuala Belait', 'Seria'],
  Bulgaria: ['Sofia', 'Plovdiv', 'Varna'],
  'Burkina Faso': ['Ouagadougou', 'Bobo-Dioulasso', 'Koudougou'],
  Burundi: ['Gitega', 'Bujumbura', 'Ngozi'],
  Cambodia: ['Phnom Penh', 'Siem Reap', 'Battambang'],
  Cameroon: ['Yaoundé', 'Douala', 'Garoua'],
  Canada: ['Toronto', 'Vancouver', 'Montreal'],
  'Cape Verde': ['Praia', 'Mindelo', 'Santa Maria'],
  'Central African Republic': ['Bangui', 'Bimbo', 'Berbérati'],
  Chad: ['NDjamena', 'Moundou', 'Sarh'],
  Chile: ['Santiago', 'Valparaíso', 'Concepción'],
  China: ['Beijing', 'Shanghai', 'Guangzhou'],
  Colombia: ['Bogotá', 'Medellín', 'Cali'],
  Comoros: ['Moroni', 'Mutsamudu', 'Fomboni'],
  'Congo Democratic Republic': ['Kinshasa', 'Lubumbashi', 'Mbuji-Mayi'],
  'Congo Republic': ['Brazzaville', 'Pointe-Noire', 'Dolisie'],
  'Costa Rica': ['San José', 'Alajuela', 'Cartago'],
  Croatia: ['Zagreb', 'Split', 'Rijeka'],
  Cuba: ['Havana', 'Santiago de Cuba', 'Camagüey'],
  Cyprus: ['Nicosia', 'Limassol', 'Larnaca'],
  'Czech Republic': ['Prague', 'Brno', 'Ostrava'],
  Denmark: ['Copenhagen', 'Aarhus', 'Odense'],
  Djibouti: ['Djibouti', 'Ali Sabieh', 'Tadjourah'],
  Dominica: ['Roseau', 'Portsmouth', 'Marigot'],
  'Dominican Republic': ['Santo Domingo', 'Santiago', 'La Romana'],
  'East Timor': ['Dili', 'Baucau', 'Same'],
  Ecuador: ['Quito', 'Guayaquil', 'Cuenca'],
  Egypt: ['Cairo', 'Alexandria', 'Giza'],
  'El Salvador': ['San Salvador', 'Santa Ana', 'San Miguel'],
  'Equatorial Guinea': ['Malabo', 'Bata', 'Ebebiyin'],
  Eritrea: ['Asmara', 'Keren', 'Massawa'],
  Estonia: ['Tallinn', 'Tartu', 'Narva'],
  Eswatini: ['Mbabane', 'Manzini', 'Lobamba'],
  Ethiopia: ['Addis Ababa', 'Dire Dawa', 'Mekelle'],
  Fiji: ['Suva', 'Lautoka', 'Nadi'],
  Finland: ['Helsinki', 'Espoo', 'Tampere'],
  France: ['Paris', 'Marseille', 'Lyon'],
  Gabon: ['Libreville', 'Port-Gentil', 'Franceville'],
  Gambia: ['Banjul', 'Serekunda', 'Brikama'],
  Georgia: ['Tbilisi', 'Batumi', 'Kutaisi'],
  Germany: ['Berlin', 'Hamburg', 'Munich'],
  Ghana: ['Accra', 'Kumasi', 'Tamale'],
  Greece: ['Athens', 'Thessaloniki', 'Patras'],
  Grenada: ["St. George's", 'Gouyave', 'Grenville'],
  Guatemala: ['Guatemala City', 'Mixco', 'Villa Nueva'],
  Guinea: ['Conakry', 'Nzérékoré', 'Kindia'],
  'Guinea Bissau': ['Bissau', 'Bafatá', 'Gabú'],
  Guyana: ['Georgetown', 'Linden', 'New Amsterdam'],
  Haiti: ['Port-au-Prince', 'Cap-Haïtien', 'Les Cayes'],
  Honduras: ['Tegucigalpa', 'San Pedro Sula', 'La Ceiba'],
  Hungary: ['Budapest', 'Debrecen', 'Szeged'],
  Iceland: ['Reykjavik', 'Kopavogur', 'Hafnarfjordur'],
  India: ['New Delhi', 'Mumbai', 'Bangalore'],
  Indonesia: ['Jakarta', 'Surabaya', 'Bandung'],
  Iran: ['Tehran', 'Mashhad', 'Isfahan'],
  Iraq: ['Baghdad', 'Basra', 'Erbil'],
  Ireland: ['Dublin', 'Cork', 'Limerick'],
  Israel: ['Jerusalem', 'Tel Aviv', 'Haifa'],
  Italy: ['Rome', 'Milan', 'Naples'],
  Jamaica: ['Kingston', 'Montego Bay', 'Spanish Town'],
  Japan: ['Tokyo', 'Osaka', 'Nagoya'],
  Jordan: ['Amman', 'Irbid', 'Zarqa'],
  Kazakhstan: ['Nur-Sultan', 'Almaty', 'Shymkent'],
  Kenya: ['Nairobi', 'Mombasa', 'Kisumu'],
  Kiribati: ['Tarawa', 'Betio', 'Bikenibeu'],
  'Korea North': ['Pyongyang', 'Hamhung', 'Chongjin'],
  'Korea South': ['Seoul', 'Busan', 'Incheon'],
  Kosovo: ['Pristina', 'Mitrovica', 'Peja'],
  Kuwait: ['Kuwait City', 'Hawalli', 'Salmiya'],
  Kyrgyzstan: ['Bishkek', 'Osh', 'Jalal-Abad'],
  Laos: ['Vientiane', 'Luang Prabang', 'Pakse'],
  Latvia: ['Riga', 'Daugavpils', 'Liepaja'],
  Lebanon: ['Beirut', 'Tripoli', 'Sidon'],
  Lesotho: ['Maseru', 'Teyateyaneng', 'Mafeteng'],
  Liberia: ['Monrovia', 'Gbarnga', 'Kakata'],
  Libya: ['Tripoli', 'Benghazi', 'Misrata'],
  Liechtenstein: ['Vaduz', 'Schaan', 'Balzers'],
  Lithuania: ['Vilnius', 'Kaunas', 'Klaipeda'],
  Luxembourg: ['Luxembourg City', 'Esch-sur-Alzette', 'Differdange'],
  Madagascar: ['Antananarivo', 'Toamasina', 'Antsirabe'],
  Malawi: ['Lilongwe', 'Blantyre', 'Mzuzu'],
  Malaysia: ['Kuala Lumpur', 'George Town', 'Johor Bahru'],
  Maldives: ['Malé', 'Addu City', 'Fuvahmulah'],
  Mali: ['Bamako', 'Sikasso', 'Mopti'],
  Malta: ['Valletta', 'Birkirkara', 'Mosta'],
  'Marshall Islands': ['Majuro', 'Ebeye', 'Jabor'],
  Mauritania: ['Nouakchott', 'Nouadhibou', 'Kaédi'],
  Mauritius: ['Port Louis', 'Beau Bassin-Rose Hill', 'Vacoas-Phoenix'],
  Mexico: ['Mexico City', 'Guadalajara', 'Monterrey'],
  Micronesia: ['Palikir', 'Weno', 'Kolonia'],
  Moldova: ['Chișinău', 'Tiraspol', 'Bălți'],
  Monaco: ['Monaco', 'Monte Carlo', 'La Condamine'],
  Mongolia: ['Ulaanbaatar', 'Erdenet', 'Darkhan'],
  Montenegro: ['Podgorica', 'Nikšić', 'Herceg Novi'],
  Morocco: ['Rabat', 'Casablanca', 'Marrakesh'],
  Mozambique: ['Maputo', 'Beira', 'Nampula'],
  Myanmar: ['Naypyidaw', 'Yangon', 'Mandalay'],
  Namibia: ['Windhoek', 'Walvis Bay', 'Swakopmund'],
  Nauru: ['Yaren', 'Denigomodu', 'Aiwo'],
  Nepal: ['Kathmandu', 'Pokhara', 'Lalitpur'],
  Netherlands: ['Amsterdam', 'Rotterdam', 'The Hague'],
  'New Zealand': ['Wellington', 'Auckland', 'Christchurch'],
  Nicaragua: ['Managua', 'León', 'Granada'],
  Niger: ['Niamey', 'Zinder', 'Maradi'],
  Nigeria: ['Lagos', 'Abuja', 'Kano'],
  'North Macedonia': ['Skopje', 'Bitola', 'Kumanovo'],
  Norway: ['Oslo', 'Bergen', 'Stavanger'],
  Oman: ['Muscat', 'Salalah', 'Sohar'],
  Pakistan: ['Islamabad', 'Karachi', 'Lahore'],
  Palau: ['Ngerulmud', 'Koror', 'Airai'],
  Panama: ['Panama City', 'Colón', 'David'],
  'Papua New Guinea': ['Port Moresby', 'Lae', 'Mount Hagen'],
  Paraguay: ['Asunción', 'Ciudad del Este', 'San Lorenzo'],
  Peru: ['Lima', 'Arequipa', 'Trujillo'],
  Philippines: ['Manila', 'Quezon City', 'Davao City'],
  Poland: ['Warsaw', 'Krakow', 'Łódź'],
  Portugal: ['Lisbon', 'Porto', 'Braga'],
  Qatar: ['Doha', 'Al Rayyan', 'Umm Salal'],
  Romania: ['Bucharest', 'Cluj-Napoca', 'Timișoara'],
  Russia: ['Moscow', 'Saint Petersburg', 'Novosibirsk'],
  Rwanda: ['Kigali', 'Butare', 'Gisenyi'],
  'Saint Kitts and Nevis': ['Basseterre', 'Charlestown'],
  'Saint Lucia': ['Castries', 'Soufrière', 'Vieux Fort'],
  'Saint Vincent and the Grenadines': ['Kingstown', 'Georgetown'],
  Samoa: ['Apia', 'Vaitele', 'Faleula'],
  'San Marino': ['San Marino', 'Serravalle', 'Borgo Maggiore'],
  'Sao Tome and Principe': ['São Tomé', 'Santo Amaro'],
  'Saudi Arabia': ['Riyadh', 'Jeddah', 'Mecca'],
  Senegal: ['Dakar', 'Saint-Louis', 'Ziguinchor'],
  Serbia: ['Belgrade', 'Novi Sad', 'Niš'],
  Seychelles: ['Victoria', 'Beau Vallon', 'Anse Boileau'],
  'Sierra Leone': ['Freetown', 'Bo', 'Kenema'],
  Singapore: ['Singapore'],
  Slovakia: ['Bratislava', 'Košice', 'Prešov'],
  Slovenia: ['Ljubljana', 'Maribor', 'Celje'],
  'Solomon Islands': ['Honiara', 'Gizo', 'Auki'],
  Somalia: ['Mogadishu', 'Hargeisa', 'Bosaso'],
  'South Africa': ['Johannesburg', 'Cape Town', 'Durban'],
  'South Sudan': ['Juba', 'Malakal', 'Wau'],
  Spain: ['Madrid', 'Barcelona', 'Valencia'],
  'Sri Lanka': ['Colombo', 'Kandy', 'Galle'],
  Sudan: ['Khartoum', 'Omdurman', 'Port Sudan'],
  Suriname: ['Paramaribo', 'Lelydorp', 'Nieuw Nickerie'],
  Sweden: ['Stockholm', 'Gothenburg', 'Malmö'],
  Switzerland: ['Bern', 'Zurich', 'Geneva'],
  Syria: ['Damascus', 'Aleppo', 'Homs'],
  Taiwan: ['Taipei', 'Kaohsiung', 'Taichung'],
  Tajikistan: ['Dushanbe', 'Khujand', 'Bokhtar'],
  Tanzania: ['Dodoma', 'Dar es Salaam', 'Mwanza'],
  Thailand: ['Bangkok', 'Chiang Mai', 'Pattaya'],
  Togo: ['Lomé', 'Sokodé', 'Kara'],
  Tonga: ['Nukuʻalofa', 'Neiafu', 'Haveluloto'],
  'Trinidad and Tobago': ['Port of Spain', 'San Fernando', 'Chaguanas'],
  Tunisia: ['Tunis', 'Sfax', 'Sousse'],
  Turkey: ['Ankara', 'Istanbul', 'Izmir'],
  Turkmenistan: ['Ashgabat', 'Türkmenabat', 'Daşoguz'],
  Tuvalu: ['Funafuti', 'Fongafale'],
  Uganda: ['Kampala', 'Gulu', 'Lira'],
  Ukraine: ['Kyiv', 'Kharkiv', 'Odessa'],
  'United Arab Emirates': ['Abu Dhabi', 'Dubai', 'Sharjah'],
  'United Kingdom': ['London', 'Birmingham', 'Manchester'],
  'United States': ['New York City', 'Los Angeles', 'Chicago'],
  Uruguay: ['Montevideo', 'Salto', 'Paysandú'],
  Uzbekistan: ['Tashkent', 'Samarkand', 'Bukhara'],
  Vanuatu: ['Port Vila', 'Luganville', 'Norsup'],
  'Vatican City': ['Vatican City'],
  Venezuela: ['Caracas', 'Maracaibo', 'Valencia'],
  Vietnam: ['Hanoi', 'Ho Chi Minh City', 'Da Nang'],
  Yemen: ["Sana'a", 'Aden', 'Taiz'],
  Zambia: ['Lusaka', 'Kitwe', 'Ndola'],
  Zimbabwe: ['Harare', 'Bulawayo', 'Chitungwiza'],
}
