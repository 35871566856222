import React from 'react'
import { Button } from 'flowbite-react'
import hiredIcon from '../../assets/svgs/hired.svg'
import passedIcon from '../../assets/svgs/passed.svg'
import hiredBag from '../../assets/svgs/hired_bag.svg'
import passedBag from '../../assets/svgs/passed_bag.svg'
import { isMobileDevice } from 'utilis/helpers'

const CardFooter = ({
  key,
  setHired,
  setPassed,
  hired,
  passed,
  searchHistory,
}) => {
  const isTabletOrMobile = isMobileDevice()
  return (
    <div key={key} className={`w-full mt-3 mb-5 flex lg:flex-row flex-col`}>
      <div className="text-medium text-gray-600 below-tablet-xl:self-center">
        <span className="text-gray-800">From</span>
        <span className={`ml-1 ${searchHistory ? 'text-blue-500 font-bold' : 'text-gray-500 font-[700]'}`}>{searchHistory || "Deleted Search"}</span>
      </div>
      {hired && (
        <Button
          className={`lg:mr-0 laptop-lg:mr-0 mt-2 sm:mt-0 sm:mr-10 xs:mr-12 mr-6 ml-auto flex bg-gray-100 items-center text-green-600 text-sm px-2 py-1 rounded-xl border border-gray-200 focus:outline-none focus:border-gray-200`}
        >
          <img
            src={hiredBag}
            alt="Green Icon"
            className="w-4 md:mr-2 mr-1"
          />
          Candidate hired
        </Button>
      )}
      {passed && (
        <Button
          className={`lg:mr-0 laptop-lg:mr-0 mt-2 sm:mt-0 sm:mr-10 xs:mr-12 mr-6 ml-auto flex bg-gray-100 items-center text-red-600 text-sm px-2 py-1 rounded-xl border border-gray-200 focus:outline-none focus:border-gray-200`}
          >
          <img
            src={passedBag}
            alt="Green Icon"
            className="w-4 md:mr-2 mr-1"
          />
          Candidate passed
        </Button>
      )}

      {!hired && !passed && (
        <>
          <div
            className={`font-bold text-medium text-gray-800 ${isTabletOrMobile ? 'ml-4' : 'ml-auto mr-5'} self-center`}
          >
            How was the result?
          </div>
          <div
            className={`${isTabletOrMobile ? 'xs:ml-16 lg:mr-0 laptop-lg:mr-0 -mr-15 mt-2' : ''} flex gap-2`}
          >
            <Button
              onClick={setHired}
              className="flex items-center lg:ml-0 laptop-lg:ml-0 xs:-ml-6 sm:-ml-6 ml-1 text-green-600 lg:text-sm text-s lg:px-2 px-1 rounded-xl border border-green-600 focus:outline-none focus:border-green-700 w-24 md:h-10 h-8"
            >
              <img
                src={hiredIcon}
                alt="Green Icon"
                className="w-4 mr-1 text-sm font-medium"
              />
              Hired
            </Button>
            <Button
              onClick={setPassed}
              className="lg:ml-2 laptop-lg:ml-2 flex items-center text-red-600 text-sm font-medium rounded-xl border border-red-600 focus:outline-none focus:border-red-700 w-24 md:h-10 h-8"
            >
              <img src={passedIcon} alt="Red Icon" className="w-4 mr-1" />
              Passed
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default CardFooter
