import React, { useEffect, useState } from 'react'
import crossIcon from 'assets/svgs/cross.svg'
import useToastMessage from 'hooks/useToastMessage'
import { PasswordInputField } from './PasswordInputField'
import { verifyPasswordForUser } from 'services/userService'
import { AccountActionButton } from '../../account-action-button/AccountActionButton'
import 'assets/css/user-settings-tab.css'

export const CurrentPasswordPrompt = ({
  onClose,
  setShowPasswordPopup = () => {},
  setDeleteAccount = () => {},
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatedPassword, setShowRepeatedPassword] = useState(false)
  const [currentPasswordValue, setCurrentPasswordValue] = useState('')
  const [repeatPasswordValue, setRepeatPasswordValue] = useState('')
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true)
  const [error, setError] = useState('')
  const toastMessage = useToastMessage()

  const togglePasswordVisibility = setter => {
    setter(prev => !prev)
  }

  const checkPasswordsMatch = () => currentPasswordValue === repeatPasswordValue

  const handleDeleteProfile = async () => {
    if (checkPasswordsMatch()) {
      setError('')
      try {
        const passwordCheck = await verifyPasswordForUser(currentPasswordValue)
        if (passwordCheck?.status === 200) {
          setDeleteAccount(true)
        }
      } catch (e) {
        if (e?.response?.status === 401) {
          setShowPasswordPopup(false)
          toastMessage('Incorrect Password. Please try again', 'error')
        } else {
          toastMessage(
            'An unexpected error occurred. Please try again later.',
            'error',
          )
        }
      }
    } else {
      setError('Passwords do not match.')
    }
  }

  useEffect(() => {
    setIsDeleteButtonDisabled(!(currentPasswordValue && repeatPasswordValue))

    if (checkPasswordsMatch()) {
      setError('')
    }
  }, [currentPasswordValue, repeatPasswordValue])

  return (
    <div className="bg-[#FDFDFD] flex flex-col p-8 gap-8 text-black w-[630px] min-w-[630px] min-h-[390px] border rounded-lg relative main-deactivate-popup">
      <button onClick={onClose} className="absolute top-4 right-5">
        <img
          src={crossIcon}
          alt="Close"
          className="cursor-pointer h-[24px] w-[24px]"
        />
      </button>

      <div className="flex flex-col gap-0.5">
        <span className="text-2xl font-bold main-deactivate-heading">
          Delete Quali account
        </span>
        <span className="text-base font-normal text-[#6B7280] main-deactivate-sub-heading">
          By continuing, you will delete your entire Quali profile and history
        </span>
      </div>

      <div className="flex flex-col gap-x-8 gap-y-4 justify-center account-action-buttons">
        <PasswordInputField
          id="currentpassword"
          label="Current Password"
          value={currentPasswordValue}
          showPassword={showPassword}
          onChange={e => setCurrentPasswordValue(e.target.value)}
          onToggle={() => togglePasswordVisibility(setShowPassword)}
        />
        <PasswordInputField
          id="repeatpassword"
          label="Repeat Password"
          value={repeatPasswordValue}
          showPassword={showRepeatedPassword}
          onChange={e => setRepeatPasswordValue(e.target.value)}
          onToggle={() => togglePasswordVisibility(setShowRepeatedPassword)}
        />
        {error && <span className="text-sm text-red-600">{error}</span>}
      </div>

      <div className="flex justify-end">
        <AccountActionButton
          label="Delete my profile"
          onClick={handleDeleteProfile}
          variant="danger"
          minWidth="40"
          fontSize="14"
          isDisabled={isDeleteButtonDisabled}
        />
      </div>
    </div>
  )
}
