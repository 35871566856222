import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { appendCandidateFeeds } from '../redux/candidateFeedSlice'
import { selectCurrentUser } from '../redux/currentUserSlice'
import {
  appendMessages,
  updateTypingStatus,
  updateUnreadCount,
  selectCurrentPartnerId,
  selectIsTyping,
  updateLastMessage,
} from '../redux/messageSlice'

const WebSocketProvider = ({ children }) => {
  const dispatch = useDispatch()
  const loggedIn = useSelector(selectCurrentUser)
  const { currentUser } = loggedIn
  const isTyping = useSelector(selectIsTyping)
  const currentPartnerId = useSelector(selectCurrentPartnerId)
  const cableRef = useRef(null)

  useEffect(() => {
    if (!currentUser?.id) {
      return
    }

    const fullToken = localStorage.getItem('accessToken')
    const token = fullToken?.replace('Bearer ', '')
    const baseURL = process.env.REACT_APP_API_BASE_URL
    const wsURL = baseURL.replace(/^https?/, match =>
      match === 'https' ? 'wss' : 'ws',
    )
    const cable = new WebSocket(`${wsURL}/cable?token=${token}`)
    cableRef.current = cable
    let messagesSubscription

    cable.onopen = () => {
      cable.send(
        JSON.stringify({
          command: 'subscribe',
          identifier: JSON.stringify({
            channel: 'CandidateFeedsChannel',
            candidate_id: currentUser?.id,
          }),
        }),
      )

      messagesSubscription = cable.send(
        JSON.stringify({
          command: 'subscribe',
          identifier: JSON.stringify({
            channel: 'MessagesChannel',
            user_id: currentUser.id,
          }),
        }),
      )
    }

    cable.onmessage = event => {
      try {
        const { data } = event
        const parsedData = JSON.parse(data)

        if (
          parsedData.type === 'ping' ||
          parsedData.type === 'welcome' ||
          parsedData.type === 'confirm_subscription'
        ) {
          return
        }

        if (parsedData?.message?.candidate_feed) {
          let candidateFeeds = parsedData?.message?.candidate_feed
          if (!Array.isArray(candidateFeeds)) {
            candidateFeeds = [candidateFeeds]
          }
          dispatch(appendCandidateFeeds([candidateFeeds[0]]))
        }

        if (parsedData?.message?.action === 'new_message') {
          const conversation = parsedData?.message?.conversation
          dispatch(appendMessages(conversation))
          dispatch(
            updateLastMessage({
              conversationId: parsedData.message.chat_id,
              lastMessage: conversation?.messages[0]?.body,
              time: conversation.messages[0]?.created_at,
            }),
          )
        }

        if (parsedData?.message?.action === 'update_unread_count') {
          dispatch(updateUnreadCount(parsedData.message.unread_count))
        }

        if (parsedData?.message?.action === 'typing_status') {
          dispatch(
            updateTypingStatus({
              userId: parsedData.message.user_id,
              isTyping: parsedData.message.is_typing,
            }),
          )
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error)
      }
    }

    cable.onerror = error => {
      console.error('WebSocket error:', error)
    }

    cable.onclose = () => {
      console.log('WebSocket connection closed')
    }

    return () => {
      if (messagesSubscription) {
        cable.send(
          JSON.stringify({
            command: 'unsubscribe',
            identifier: messagesSubscription,
          }),
        )
      }
      cable.close()
    }
  }, [currentUser, dispatch])

  useEffect(() => {
    if (
      cableRef.current &&
      cableRef.current.readyState === WebSocket.OPEN &&
      currentPartnerId
    ) {
      console.log(
        'Sending typing status:',
        isTyping,
        'to partner:',
        currentPartnerId,
      )
      cableRef.current.send(
        JSON.stringify({
          command: 'message',
          identifier: JSON.stringify({
            channel: 'MessagesChannel',
            user_id: currentUser.id,
          }),
          data: JSON.stringify({
            action: 'typing_status',
            partner_id: currentPartnerId,
            is_typing: isTyping,
          }),
        }),
      )
    }
  }, [isTyping, currentPartnerId, currentUser?.id])

  return children
}

export default WebSocketProvider
