export const excludedColumns = [
  'id',
  'user_id',
  'created_at',
  'updated_at',
  'deletion_status',
]

export const columnMappings = {
  feedback: 'Feedback',
  user_email: 'User Email',
}

export const processTableData = (data, excludedColumns, columnMappings) => {
  const columns = Object.keys(data[0] || {})
    .filter(key => !excludedColumns.includes(key))
    .map(key => ({
      key,
      header: columnMappings[key] || key,
    }))

  return { columns, data }
}
