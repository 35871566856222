import React, { useState } from 'react'
import { Card, Badge, Tooltip } from 'flowbite-react'
import avatarImg from '../../../../assets/avatar.png'
import messagesIcon from '../../../../assets/svgs/messages_outline.svg'
import greyMessagesIcon from '../../../../assets/svgs/grey_messages_outline.svg'
import checkMarkIcon from '../../../../assets/svgs/completed_test.svg'
import {
  formatText,
  TruncatedText,
  candidateLastActiveStatus,
  isMobileDevice,
} from '../../../../utilis/helpers'
import '../../../../assets/css/search-results.css'
import { InitialLetters } from 'components/generics/Initials'

export const CandidateProfileDetails = ({ candidate = {} }) => {
  const [showMoreExpertises, setShowMoreExpertises] = useState(false)
  const [showMoreCertifications, setShowMoreCertifications] = useState(false)
  const [showMoreTests, setShowMoreTests] = useState(false)
  const isMobile = isMobileDevice()

  const candidateExpertises = candidate =>
    candidate?.skill_expertise?.reduce((acc, skill) => {
      return acc.concat(skill.selected_expertise || [])
    }, [])

  const sortedExpertises = candidate => {
    const expertises = candidateExpertises(candidate)
    return expertises
  }

  const candidateActiveStatus = candidateLastActiveStatus(
    candidate?.last_sign_in_at,
  )

  const extractUserPreferences = candidate => {
    if (!candidate.job_preference) {
      return {
        additional_benefits: {},
        preferred_languages: [],
        salary_range: 'Not specified',
      }
    }

    const { job_preference } = candidate
    return {
      additional_benefits: {
        accommodation: job_preference.accommodation || false,
        annual_flight: job_preference.annual_flight || false,
        transportation: job_preference.transportation || false,
      },
      preferred_languages: job_preference.preferred_languages || [],
      salary_range: job_preference.salary_range || 'Not specified',
    }
  }

  const user_preferences = extractUserPreferences(candidate)

  return (
    <Card
      className={`w-full sm:w-auto px-2 pt-4 md:pt-0 pb-4 pl-5 mt-5 mb-11 custom-card`}
    >
      <div className="flex flex-row items-center pb-0 mb-0 w-full mt-3">
        <div className="flex flex-col 2xl:flex-row w-full">
          <div className="flex flex-row items-center w-full lg:w-1/2">
            <div className="relative flex-shrink-0 below-tablet-xl:mt-5">
              {candidate?.profile_picture_url ? (
                <img
                  className="w-16 h-16 sm:w-20 sm:h-20 rounded-full object-cover border-2 border-[#E5E7EB]"
                  src={candidate?.profile_picture_url ?? avatarImg}
                  alt={`${candidate?.first_name} ${candidate?.last_name}`}
                />
              ) : (
                <div className="flex justify-center items-center p-5 rounded-full text-2xl bg-gray-100 border-gray-100 h-16 w-16">
                  <InitialLetters
                    Name={`${candidate?.first_name} ${candidate?.last_name}`}
                  />
                </div>
              )}
              {candidateActiveStatus === 'today' && (
                <span className="absolute top-1 right-1 sm:top-0 sm:right-3 w-2 h-2 sm:w-3 sm:h-3 bg-green-400 rounded-full"></span>
              )}
            </div>
            <div className="ml-2 flex-grow below-tablet-xl:mt-5">
              <p className="text-lg font-extrabold text-gray-900 whitespace-nowrap">
                {`${candidate?.first_name} ${candidate?.last_name}`}
              </p>
              <p className="text-14 font-medium text-gray-600 whitespace-nowrap">
                {candidate?.years_of_experience
                  ? `${candidate?.years_of_experience} years of experience`
                  : `No experience added`}
              </p>
              {candidate?.citizenship ? (
                <p className="text-14 font-medium text-gray-800 mr-0">
                  <span className="text-gray-600">From </span>
                  {candidate?.citizenship}
                </p>
              ) : (
                <p className="text-14 font-medium text-gray-600 whitespace-nowrap">
                  No citizenship selected
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={`mt-2 ${isMobile ? 'pr-1' : 'pr-8'}`}>
        {candidate?.work_histories?.length > 0 && (
          <p className="work-history">
            Worked in{' '}
            <span className="text-gray-900 font-extrabold text-base">
              {candidate.work_histories.find(wh => wh.is_working)
                ?.company_name || candidate.work_histories[0]?.company_name}
            </span>{' '}
            as{' '}
            <span className="text-blue-500 font-bold text-base">
              {candidate.work_histories.find(wh => wh.is_working)?.job_title ||
                candidate.work_histories[0]?.job_title}
            </span>
          </p>
        )}
      </div>

      {/* Expertise and preferences */}
      <div className={`${isMobile ? 'px-1' : 'pr-8'}`}>
        <div className="flex flex-row gap-2 mb-4 mt-2">
          <div className="w-full sm:w-1/2">
            <span className="block text-sm text-black mb-2 font-semibold">
              Expertise Matched
            </span>
            <div className="flex flex-wrap gap-2">
              {(() => {
                const expertises = sortedExpertises(candidate)
                if (!expertises?.length) {
                  return (
                    <p className="text-gray-500 text-xs font-medium">
                      No Expertise Added
                    </p>
                  )
                }

                const displayedExpertises = showMoreExpertises
                  ? expertises
                  : expertises.slice(0, 4)
                const badges = displayedExpertises.map((expertise, idx) => {
                  const isBlue = idx < 2
                  return (
                    <Badge
                      key={idx}
                      className={`bg-${isBlue ? 'blue-100' : 'gray-100'} py-1 rounded-md`}
                    >
                      <div className="flex items-center">
                        <img
                          src={isBlue ? messagesIcon : greyMessagesIcon}
                          className="w-3 h-3 mr-1"
                          alt="message-outline"
                        />
                        <p
                          className={`text-${isBlue ? 'blue-800' : 'gray-900'} ${isMobile ? 'pr-2 text-xs' : 'text-sm'} font-medium`}
                        >
                          {expertise}
                        </p>
                      </div>
                    </Badge>
                  )
                })

                if (expertises.length > 4) {
                  badges.push(
                    <Badge
                      key="more-expertises"
                      className="bg-white py-1 text-sm font-medium text-gray-500 cursor-pointer"
                      onClick={() => setShowMoreExpertises(!showMoreExpertises)}
                    >
                      {showMoreExpertises
                        ? 'Show Less'
                        : `+${expertises.length - 4} more`}
                    </Badge>,
                  )
                }

                return badges
              })()}
            </div>
          </div>

          {/* Preferences */}
          <div className="w-full sm:w-1/2 pl-2 :pl-14">
            <span className="block text-sm font-bold text-black mb-2">
              Preference Matched
            </span>
            <div className="flex flex-wrap gap-2">
              {user_preferences.preferred_languages.length > 0 ||
              Object.keys(user_preferences.additional_benefits).length > 0 ||
              user_preferences.salary_range !== 'Not specified' ? (
                <>
                  {user_preferences.preferred_languages.map((language, idx) => (
                    <Badge
                      key={`lang-${idx}`}
                      className="bg-gray-100 py-1 flex rounded-md"
                    >
                      <div className="flex items-center">
                        <img
                          src={greyMessagesIcon}
                          alt="message outline"
                          className="mr-1 w-3 h-3"
                        />
                        <p
                          className={`text-grey-900 ${isMobile ? 'pr-2 text-xs' : 'text-sm'} font-medium`}
                        >
                          {formatText(language)}
                        </p>
                      </div>
                    </Badge>
                  ))}
                  <Badge
                    key="salary-range"
                    className="bg-gray-100 py-1 flex rounded-md"
                  >
                    <div className="flex items-center">
                      <img
                        src={greyMessagesIcon}
                        alt="message outline"
                        className="mr-1 w-3 h-3"
                      />
                      <p
                        className={`text-grey-900 ${isMobile ? 'pr-2 text-xs' : 'text-sm'} font-medium`}
                      >
                        Salary Range: {user_preferences.salary_range}
                      </p>
                    </div>
                  </Badge>
                  {Object.entries(user_preferences.additional_benefits)
                    .filter(([_, value]) => value)
                    .map(([key, value], idx) => (
                      <Badge
                        key={`benefit-${idx}`}
                        className="bg-gray-100 py-1 flex rounded-md"
                      >
                        <div className="flex items-center">
                          <img
                            src={greyMessagesIcon}
                            alt="message outline"
                            className="mr-1 w-3 h-3"
                          />
                          <p
                            className={`text-grey-900 ${isMobile ? 'pr-2 text-xs' : 'text-sm'} font-medium`}
                          >
                            {formatText(key)}
                          </p>
                        </div>
                      </Badge>
                    ))}
                </>
              ) : (
                <p className="text-gray-500 text-xs font-medium">
                  No preferences
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Certifications */}
        <div className="flex flex-row gap-2 mb-4 mt-2">
          <div className="w-full sm:w-1/2">
            <span className="block text-sm text-black mb-2 font-semibold">
              Certifications
            </span>
            <div className="flex flex-row flex-wrap gap-2">
              {candidate?.certifications.length > 0 ? (
                <>
                  {(showMoreCertifications
                    ? candidate.certifications
                    : candidate.certifications.slice(0, 3)
                  ).map((certification, idx) => {
                    const isBlue = idx < 2
                    return (
                      <Badge
                        key={idx}
                        className={`bg-${isBlue ? 'blue-100' : 'gray-100'} py-1 rounded-md`}
                      >
                        <div className="flex">
                          <img
                            src={isBlue ? messagesIcon : greyMessagesIcon}
                            className="mr-1"
                            alt="message-outline"
                          />
                          {/* eslint-disable-next-line react/style-prop-object */}
                          <Tooltip style="light" content={certification.title}>
                            <div
                              className={`text-${isBlue ? 'blue-800' : 'gray-900'} ${isMobile ? 'pr-2 text-xs' : 'text-sm'} font-medium`}
                            >
                              <TruncatedText
                                text={certification.title}
                                limit={25}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </Badge>
                    )
                  })}
                  {candidate.certifications.length > 3 && (
                    <Badge
                      className="bg-white py-1 text-sm font-medium text-gray-500 cursor-pointer"
                      onClick={() =>
                        setShowMoreCertifications(!showMoreCertifications)
                      }
                    >
                      {showMoreCertifications
                        ? 'Show Less'
                        : `+${candidate.certifications.length - 3} more`}
                    </Badge>
                  )}
                </>
              ) : (
                <p className="text-gray-500 text-xs font-medium">
                  No certifications added yet
                </p>
              )}
            </div>
          </div>

          {/* Quali Tests */}
          <div className="w-full sm:w-1/2 pl-2 :pl-14">
            <span className="block text-sm text-black mb-2 font-semibold">
              Quali Test
            </span>
            <div className="flex flex-wrap gap-2">
              {candidate?.attempted_tests.length > 0 ? (
                <>
                  {(showMoreTests
                    ? candidate.attempted_tests
                    : candidate.attempted_tests.slice(0, 4)
                  ).map((test, idx) => (
                    <Badge key={idx} className="bg-gray-100 py-2 rounded-md">
                      <div className="flex">
                        <img
                          src={checkMarkIcon}
                          className="mr-1"
                          alt="check-mark"
                        />
                        <p
                          className={`text-grey-900 ${isMobile ? 'pr-2 text-xs' : 'text-sm'} font-medium`}
                        >
                          {'Passed ' + test.title}
                        </p>
                      </div>
                    </Badge>
                  ))}
                  {candidate.attempted_tests.length > 4 && (
                    <Badge
                      className="bg-white py-1 text-sm font-medium text-gray-500 cursor-pointer"
                      onClick={() => setShowMoreTests(!showMoreTests)}
                    >
                      {showMoreTests
                        ? 'Show Less'
                        : `+${candidate.attempted_tests.length - 4} more`}
                    </Badge>
                  )}
                </>
              ) : (
                <p className="text-gray-500 text-xs font-medium">
                  Candidate hasn't completed any Quali Test yet.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Last active */}
      <div className="mt-2 pr-8 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
        <p className="leading-tight text-sm font-medium">
          Last Active: {candidateActiveStatus}
        </p>
      </div>
    </Card>
  )
}
