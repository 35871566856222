import React, { useState } from 'react'
import PaginationFooter from 'components/table/PaginationFooter'

export const AdminEmployerAccountDeletion = ({
  columns,
  data,
  handleDelete,
  handleSearch,
  currentPage,
  totalPages,
  totalCount,
  handleNextPage,
  handlePrevPage,
  handlePageClick,
  handleAccountRequestDelete,
}) => {
  const [searchQuery, setSearchQuery] = useState('')

  const handleSearchChange = e => {
    const value = e.target.value
    setSearchQuery(value)
    handleSearch && handleSearch(value)
  }

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <div className="flex justify-between p-4">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search..."
          className="block pl-10 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
      <div className="overflow-x-auto">
        <table className="w-full table-fixed text-sm text-left text-black">
          <thead className="text-xs text-gray-500 uppercase bg-white border-b">
            <tr>
              {columns.map((column, index) => (
                <th
                  key={index}
                  className="px-6 py-2"
                  style={{ width: column.width || 'auto' }}
                >
                  {column.header}
                </th>
              ))}
              <th className="px-6 py-2 pl-20" style={{ width: '25%' }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, rowIndex) => (
              <tr key={rowIndex} className="bg-white border-b hover:bg-gray-50">
                {columns.map((column, colIndex) => (
                  <td
                    key={colIndex}
                    className="px-6 py-2 break-words"
                    style={{ width: column.width || 'auto' }}
                  >
                    {item[column.key]}
                  </td>
                ))}
                <td
                  className="flex felx-row px-6 py-2 gap-3"
                  style={{ width: '15%' }}
                >
                  <button
                    onClick={() => handleDelete(item.user_id)}
                    className="font-medium lg:px-4 px-7 lg:mb-0 py-2 rounded-lg mb-2 account-action-button-xs account-action-button-sm bg-white text-[#C81E1E] border border-[#C81E1E] min-w-20 hover:bg-[#C81E1E] hover:text-white transition-colors duration-200"
                  >
                    Delete
                  </button>

                  <button
                    onClick={() => handleAccountRequestDelete(item.id)}
                    className="font-medium lg:px-4 px-7 lg:mb-0 py-2 rounded-lg mb-2 account-action-button-xs account-action-button-sm bg-white text-[#1A56DB] border border-[#1A56DB] min-w-20 hover:bg-[#1A56DB] hover:text-white transition-colors duration-200"
                  >
                    Reject
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {totalPages > 1 && (
        <PaginationFooter
          currentPage={currentPage}
          totalPages={totalPages}
          totalCount={totalCount}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          handlePageClick={handlePageClick}
        />
      )}
    </div>
  )
}
