import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/currentUserSlice'
import '../../assets/css/sidebar-component.css'
import crossIcon from '../../assets/svgs/cross.svg'
import homeIcon from '../../assets/svgs/content-home.svg'

const SidebarCard = ({
  id,
  title,
  subtitle,
  selectedCard,
  setSelectedCard,
  disabled = false,
}) => {
  const cardClasses = `sidebar-card ${
    disabled
      ? 'sidebar-card-disabled'
      : selectedCard === id
        ? 'sidebar-card-selected'
        : ''
  }`

  return (
    <div
      onClick={() => !disabled && setSelectedCard(id)}
      className={cardClasses}
    >
      <p className="sidebar-card-title">{title}</p>
      <p className="sidebar-card-subtitle">{subtitle}</p>
    </div>
  )
}

const Sidebar = ({ selectedCard, setSelectedCard }) => {
  const { currentUser } = useSelector(selectCurrentUser)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className="flex flex-row flex-grow justify-center">
        <button className="sidebar-toggle" onClick={() => setIsOpen(!isOpen)}>
          {!isOpen && (
            <>
              <img src={homeIcon} alt="Menu" className="w-4 mr-1" />
              <p className="font-medium text-sm text-[#4B5563]">
                Edit
              </p>
            </>
          )}
        </button>
      </div>

      <div className={`sidebar-container ${isOpen ? 'active' : ''}`}>
        <div className="sidebar-items">
          {isOpen ? (
            <div className="flex flex-row items-center w-full justify-center">
              <h5 className="text-base font-black flex-1">
                Welcome to Quali
              </h5>
              <button
                className="sidebar-toggle flex-none"
                onClick={() => setIsOpen(!isOpen)}
              >
                <img src={crossIcon} alt="Close Menu" className="w-4 mr-1" />
              </button>
            </div>
          ) : (
            <h5 className="text-base font-black">
              Welcome to Quali
            </h5>
          )}

          <SidebarCard
            id={1}
            title="Personal Details ✍🏻"
            subtitle={
              currentUser.personal_details_active_status
                ? 'Completed'
                : 'Not Completed'
            }
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
          />
          <SidebarCard
            id={2}
            title="Experience and Qualifications 💼"
            subtitle={
              currentUser.experience_active_status
                ? 'Completed'
                : 'Not Completed'
            }
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            disabled={!currentUser.personal_details_active_status}
          />
          <SidebarCard
            id={3}
            title="Your Preferences 👌🏻"
            subtitle={
              currentUser.preferences_active_status
                ? 'Completed'
                : 'Not Completed'
            }
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            disabled={!currentUser.skill_expertise}
          />
        </div>
      </div>
    </>
  )
}

export default Sidebar
