import React from 'react'
import searchIcon from 'assets/svgs/searchicon.svg'

export const SearchBox = ({ value, handleChange }) => {
  return (
    <div className="relative justify-between p-4">
      <div className="absolute inset-y-0 left-0 flex items-center pl-8 pt-1 pointer-events-none text-gray-900">
        <img src={searchIcon} alt="Search Icon" className="w-4 h-4" />
      </div>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        placeholder="Search messages"
        className="block pl-12 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
      />
    </div>
  )
}
