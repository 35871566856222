import React from 'react'
import Image from '../components/Image'
import AppBar from '../components/Helper/AppBar'
import PasswordFields from '../components/CompanyReister/PasswordFields'
import { useLocation, useParams } from 'react-router-dom'

const ResetPassword = () => {
  const { id } = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const user = searchParams.get('user')
  return (
    <>
      <div className="flex h-screen w-full flex-col px-20 bg-white sm:bg-[#f9fafb]">
        <div className="sm:px-10 sm:pt-8 ">
          <AppBar />
        </div>
        <main className="flex flex-grow items-center">
          <div className="flex w-full items-center justify-center gap-20">
            <PasswordFields userId={id} user={user}/>

            <Image user={user}/>
          </div>
        </main>
      </div>
    </>
  )
}

export default ResetPassword
