import React, { useState, useCallback } from 'react'
import crossIcon from 'assets/svgs/cross.svg'
import {
  deactivateAccountList,
  deactivateAccountOptions,
} from './deactivate-account-constant'
import { DeactivationRadio } from './deactivation-radio/DeactivationRadio'
import { DeactivationEmployerFormFields } from './deactivation-childrens/DeactivationEmployerFormFields'
import { DeactivationThoughts } from './deactivation-childrens/DeactivationThoughts'
import 'assets/css/user-settings-tab.css'
import { getDeactivateAccountReason } from './deactivate-account-helper'

export const DeactivateAccountPopup = ({
  setDeactivateAccount,
  setCandidateFeedback,
  onClose = () => {},
}) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [userThoughts, setUserThoughts] = useState('')
  const [hiredCompany, setHiredCompany] = useState({
    company_name: '',
    salary: '',
  })
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  const handleRadioChange = useCallback(event => {
    setSelectedOption(event.target.id)
  }, [])

  const handleUserThoughtsChange = useCallback(event => {
    setUserThoughts(event.target.value)
  }, [])

  const handleHiredCompanyChange = useCallback(event => {
    const { name, value } = event.target
    setHiredCompany(prev => ({
      ...prev,
      [name]: value,
    }))
  }, [])

  const updateFormValidity = useCallback(isValid => {
    setIsSubmitDisabled(!isValid)
  }, [])

  const handleDeactivatePopUpSubmit = useCallback(() => {
    const reason_deactivating = getDeactivateAccountReason(selectedOption)
    const candidate_feedback =
      selectedOption === deactivateAccountOptions.HIRED_QUALI
        ? `Company: ${hiredCompany.company_name}, Salary: ${hiredCompany.salary}`
        : userThoughts

    setCandidateFeedback(prevState => ({
      ...prevState,
      reason_deactivating,
      feedback: candidate_feedback,
    }))
    setDeactivateAccount(true)
  }, [
    selectedOption,
    hiredCompany,
    userThoughts,
    setCandidateFeedback,
    setDeactivateAccount,
  ])

  return (
    <div className="bg-[#FDFDFD] flex flex-col p-8 gap-8 text-black min-w-[630px] min-h-80 border rounded-lg relative main-deactivate-popup">
      <button onClick={onClose} className="absolute top-4 right-5">
        <img
          src={crossIcon}
          alt="Close"
          className="cursor-pointer h-[24px] w-[24px]"
        />
      </button>

      <div className="flex flex-col gap-0.5">
        <span className="text-2xl font-bold main-deactivate-heading">
          Temporarily deactivate your Quali profile
        </span>
        <span className="text-base font-normal text-[#6B7280] main-deactivate-sub-heading">
          This action will hide your profile from employers, tell us the reason:
        </span>
      </div>

      <div className="flex flex-col gap-2">
        {deactivateAccountList.map(option => (
          <DeactivationRadio
            key={option.ID}
            id={option.ID}
            name={option.NAME}
            label={option.LABEL}
            isChecked={selectedOption === option.ID}
            onChange={handleRadioChange}
          >
            {option.ID === deactivateAccountOptions.HIRED_QUALI && (
              <DeactivationEmployerFormFields
                handleHiredCompanyChange={handleHiredCompanyChange}
                updateFormValidity={updateFormValidity}
              />
            )}
          </DeactivationRadio>
        ))}
      </div>

      {selectedOption &&
        selectedOption !== deactivateAccountOptions.HIRED_QUALI && (
          <DeactivationThoughts
            feedback={userThoughts}
            handleUserThoughtsChange={handleUserThoughtsChange}
            updateFormValidity={updateFormValidity}
          />
        )}

      <div className="flex justify-end">
        <button
          type="button"
          onClick={handleDeactivatePopUpSubmit}
          disabled={isSubmitDisabled}
          className={`text-sm font-medium py-2 lg:px-3 px-7 rounded-lg mb-2 lg:mb-0 min-w-40 bg-[#1A56DB] text-white border ${
            isSubmitDisabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          Submit and Close
        </button>
      </div>
    </div>
  )
}
