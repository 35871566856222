import React from 'react'

export const InfoButtonSidePanel = ({
  iconSrc,
  text,
  className,
  onClick,
  ...props
}) => (
  <button
    className={` ${text === 'Sign the offer'? ' px-1': 'py-2.5 px-5 '}   ${text === 'View Full Profile'? 'rounded-lg': 'rounded '} border flex flex-row gap-2 items-center justify-center ${className}`}
    onClick={onClick}
    {...props}
  >
    <img src={iconSrc} alt={`${text} icon`} className="w-3.5 h-3.5" />
    <span className={`${text === 'Sign the offer' || text === 'Reject this offer'? 'text-xs': 'text-sm'}  font-medium`}>{text}</span>
  </button>
)
