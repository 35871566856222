import React from 'react'
import registerImage from '../assets/register-image.png'
import candidateAccountPageImage from '../assets/images/registration/candidate-account-page.png'
import '../assets/css/image.css'
const Image = ({ src = registerImage, user }) => {
  return (
    <img
      className="responsive-image hidden lg:flex"
      src={user === 'candidate' ? candidateAccountPageImage : src}
      alt="loginimage"
    />
  )
}

export default Image
