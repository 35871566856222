import React from 'react'
import logoImg from '../../assets/logo.png'
import { Link } from 'react-router-dom'

const AppBar = () => {
  return (
    <div className="w-full bg-white  lg:bg-[#F9FAFB] flex items-center">
      <Link to="/">
        <img src={logoImg} className="mr-3 xs:mt-0 -mt-4 h-6 sm:h-9" alt="Qualiii Logo" />
      </Link>
    </div>
  )
}

export default AppBar
