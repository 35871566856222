import { getIndustries } from '../../services/industryService'

export const fetchAllIndustries = async () => {
  try {
    const response = await getIndustries()
    return response
  } catch (error) {
    console.error('Error fetching industries:', error)
  }
}
