import React from 'react'
import { ReactComponent as FilledStarIcon } from '../../../assets/filled-star-icon.svg'
import { ReactComponent as TestAssessmentsIcon } from '../../../assets/test-assessments-icon.svg'
import { ReactComponent as UserIconQualiDirectory } from '../../../assets/users-icon.svg'
import { ReactComponent as UserProfileSettings } from '../../../assets/svgs/user-profile-settings.svg'
import { ReactComponent as BlackRevealIcon } from '../../../assets/black-reveal-icon.svg'
import { ReactComponent as InboxIcon } from '../../../assets/inbox-icon.svg'
import { ReactComponent as RedLogoutIcon } from '../../../assets/red-logout-icon.svg'
import { ReactComponent as ManageProfile } from 'assets/svgs/manage-profile.svg'
import { AdminOptions } from './AdminOptions'
import { NavItem } from './NavLink'
import { UserProfile } from './UserProfile'

export const DrawerContent = ({
  currentUser,
  isCompanyAdmin,
  company,
  searchHistoryCount,
  handleLogoutClick,
}) => {
  return (
    <>
      <UserProfile
        currentUser={currentUser}
        isCompanyAdmin={isCompanyAdmin}
        company={company}
      />
      <AdminOptions isCompanyAdmin={isCompanyAdmin} currentUser={currentUser} />
      <div className="mt-4 w-full flex-1">
        <ul className="space-y-2">
          <NavItem to="/overview" icon={<FilledStarIcon />} text="Overview" />
          {currentUser.role === 'candidate' && (
            <>
              <NavItem
                className="flex justify-between"
                to="/candidate-account-overview"
                icon={<TestAssessmentsIcon />}
                text={<span className="mr-16">{'Quali Profile'}</span>}
                badge={<span> {currentUser.pending_test_count}</span>}
              />
              <NavItem
                to="/test-list-of-candidiate"
                icon={<UserProfileSettings />}
                text="Quali Test"
              />
            </>
          )}
          {isCompanyAdmin && (
            <>
              <NavItem
                to="/search"
                icon={<UserIconQualiDirectory />}
                text={<span className="mr-14">{'Quali Search'}</span>}
                badge={searchHistoryCount?.length}
              />
              <NavItem
                to="/revealed-profiles"
                icon={<BlackRevealIcon />}
                text="Revealed Profiles"
              />
              <NavItem to="#" icon={<InboxIcon />} text="Inbox" />
              <NavItem
                to="/test-and-assessment"
                icon={<TestAssessmentsIcon />}
                text="Test and Assessment"
              />
              <NavItem
                to="/manage-profile"
                icon={<ManageProfile />}
                text="Manage Pofile"
              />
            </>
          )}
          <li className="flex items-center space-x-2 text-red-600 p-2">
            <RedLogoutIcon />
            <button onClick={handleLogoutClick} className="text-red-600">
              Log out
            </button>
          </li>
        </ul>
      </div>
    </>
  )
}
