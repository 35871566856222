import React, { useState, useEffect } from 'react'
import { Button } from 'flowbite-react'
import './SearchCandidates.css'
import AppBar from '../Dashboard/AppBar'
import { TopHeader } from './TopHeader'
import { AppSideBar } from '../Dashboard/AppSideBar'
import { useSelector, useDispatch } from 'react-redux'
import { fetchSearchHistory } from 'services/searchResult'
import { selectSearchHistory,  setSearchHistory,selectCurrentUser } from '../../redux/currentUserSlice'
import SearchTabs from './SearchHistory/SearchTabs'
import EmptySearchResults from './EmptySearchResults'
import StartNewSearch from './modals/StartNewSearch'
import useScrollToTop from 'hooks/useScrollToTop'
import { setRevealedCandidates } from '../../redux/talentSearchSlice'
import { formatCandidateData } from '../../utilis/helpers'
import { getRevealedCandidatesForCompany } from '../../services/userService'
import { Loader } from '../../utilis/AppLoader'
import { PersistentInbox } from 'components/Messaging/PersistentMinimizableInbox'
const SearchCandidates = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const  dispatch = useDispatch()
  const { currentUser } = useSelector(selectCurrentUser)

  const [activeHistories, setActiveHistories] = useState([])
  const [archivedHistories, setArchivedHistories] = useState([])
  const searchHistories = useSelector(selectSearchHistory)
  const [loading, setLoading] = useState(true)
  
  const fetchData = async () => {
    try {
      await fetchSearchHistory(dispatch, setSearchHistory)
    } catch (error) {
      console.error('Error fetching search histories:', error)
    } finally {
      setLoading(false) 
    }
  }
  useEffect(() => {
    getCandidates()
  }, [])
  
  const getCandidates = async (searchQuery) => {
    try {
      const response = await getRevealedCandidatesForCompany(searchQuery)
      const { data, status } = response
      if (status === 200) {
        const formattedDataArray = formatCandidateData(data)
        dispatch(setRevealedCandidates(formattedDataArray || []))
      }
    } catch (error) {
      console.error('Error fetching candidates')
    }
  }


  useEffect(() => {
    if (currentUser.role === 'company_admin') {
      fetchData()
    }
  }, [currentUser.role, dispatch])
  
  useEffect(() => {
    if (searchHistories && Array.isArray(searchHistories)) {
      const filteredActiveHistories = []
      const filteredArchivedHistories = []
  
      searchHistories.forEach(history => {
        if (history.attributes.is_archive) {
          filteredArchivedHistories.push(history)
        } else {
          filteredActiveHistories.push(history)
        }
      })
  
      setActiveHistories(filteredActiveHistories)
      setArchivedHistories(filteredArchivedHistories)
    }
  }, [searchHistories])
  
  useScrollToTop();

  return (
       <div className="h-full bg-[#f9fafb]">
      <AppBar />
      <div className="flex flex-col md:flex-row p-4 md:p-10">
        <div className="hidden md:block">
          <AppSideBar />
        </div>
        <div className="flex flex-col w-full md:w-3/5 pl-4">
          <TopHeader />
          {loading && !searchHistories ? (
                  <div className="flex justify-center items-center h-40">
                  <Loader size="large" color="blue" />
                </div>
                ) : (
          <div className="border-1.5 border-gray-400 rounded-md bg-white mt-5 mr-4 shadow-md">
            <div className="flex flex-col md:flex-row justify-between items-center border-b border-grey-20 mx-4">
              <div className="flex flex-col mt-5 px-2">
                <h1 className="mb-2 text-3xl font-bold">
                  Quali Talent Directory
                </h1>
                {searchHistories?.length > 0 ? (
                  <p className="mb-4 text-md text-gray-600">
                    {activeHistories.length} active search,{' '}
                    {archivedHistories.length} archive search
                  </p>
                ) : (
                  <p className="mb-6 text-md text-gray-600">No search yet</p>
                )}
              </div>
              <div className="mb-6 justify-end lg:ml-auto mr-5 mt-7">
                <Button
                  onClick={() => setModalOpen(true)}
                  color={'blue'}
                  size={'md'}
                >
                  + Add new search
                </Button>
              </div>
            </div>
            {searchHistories?.length > 0 ? (
              <SearchTabs
                activeSearches={activeHistories}
                archiveSearches={archivedHistories}
              />
            ) : (
              <EmptySearchResults />
            )}
          </div>
                )
          }
        </div>
        <div className="flex flex-col border border-white h-12 w-full md:w-1/6 opacity-inherit bg-white shadow-md rounded-lg mx-4 hidden lg:block">
          <p className="xl:text-base lg:text-sm text-xs font-medium text-gray-600 p-3">
            Filters unavailable
          </p>
        </div>
      </div>

      <StartNewSearch
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isNewSearch={true}
      />
      <PersistentInbox />
        </div>

  )
}

export default SearchCandidates
