import { useFormikContext } from 'formik'
import { Dropdown } from 'flowbite-react'
import { useEffect, useState } from 'react'

export const ScrollableDropdown = ({
  name,
  options,
  defaultLabel,
  value,
  onChange,
  setSelectedCountry = () => {},
  applyClass = false,
}) => {
  const formik = useFormikContext()
  const [selectedValue, setSelectedValue] = useState(
    formik ? formik.values[name] : value || '',
  )

  useEffect(() => {
    const newValue = formik ? formik.values[name] : value
    if (newValue !== undefined) {
      setSelectedValue(newValue)
    }
  }, [formik, value, formik?.values[name]])

  const handleSelect = ({ value }) => {
    formik?.setFieldValue(name, value)
    setSelectedValue(value)
    setSelectedCountry(value)
    onChange?.(value)
  }

  return (
    <Dropdown
      label={selectedValue || defaultLabel}
      className={`${applyClass ? 'custom-dropdown' : ''}`}
    >
      <div className="max-h-60 overflow-y-auto w-full">
        <Dropdown.Item
          key="default"
          onClick={() =>
            handleSelect({
              label: 'Select Nationality',
              value: '',
            })
          }
          className="font-bold text-gray-500"
        >
          Choose Country
        </Dropdown.Item>

        {options.map(option => (
          <Dropdown.Item
            key={option.value}
            onClick={() => handleSelect(option)}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </div>
    </Dropdown>
  )
}
