import React from 'react'
import { Button } from 'flowbite-react'
import hireIcon from 'assets/svgs/passed_bag.svg'
import offeredIcon from 'assets/svgs/hired_bag.svg'

const ComparisonCardFooter = ({ rejected, offered }) => {
  return (
    <div className="mt-3 mb-5 flex justify-between gap-4 md:flex-nowrap flex-wrap">
      {rejected && (
        <Button
          color={'light'}
          size="sm"
          className="w-full bg-gray-100 mt-2 px-6 below-767:px-4 flex items-center"
        >
          <img
            src={hireIcon}
            className="w-4 h-4 mr-2"
            alt="View Profile Icon"
          />
          <p className="text-14 font-[500] text-red-600 whitespace-nowrap">
            Candidate Rejected
          </p>
        </Button>
      )}

      {offered && (
        <Button
          color={'light'}
          size="sm"
          className="w-full bg-gray-100 mt-2 px-6 below-767:px-4 flex items-center"
        >
          <img
            src={offeredIcon}
            className="w-4 h-4 mr-2"
            alt="View Profile Icon"
          />
          <p className="text-14 font-[500] text-green-500 whitespace-nowrap">
            Offer Made
          </p>
        </Button>
      )}
    </div>
  )
}

export default ComparisonCardFooter
