import { FileInput, Label, Select, TextInput } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { COUNTRY_CITY } from 'data/registerFormData'
import { InitialLetters } from 'components/generics/Initials'
import { useSelector } from 'react-redux'
import { selectCompany } from '../../../redux/currentUserSlice'
import 'assets/css/file-input.css'
import { getIndustries } from 'services/industryService'
import DropdownSearch from 'components/generics/dropdown/DropdownSearch'
import { BUSINESS_SIZES } from 'config/constants'
import { TruncatedText, extractFileName, isMobileDevice } from 'utilis/helpers'
import { FaTwitter, FaInstagram, FaFacebookF } from 'react-icons/fa'
import '../../../assets/css/CompanyProfile.css'

const CompanyProfileTab = ({
  profileData,
  handleChange,
  errors,
  cities,
  setProfilePicture,
  profilePicture,
  setDataChanged,
}) => {
  const companyData = useSelector(selectCompany)
  const [industries, setIndustries] = useState([])
  const [preview, setPreview] = useState('')
  const isMobile = isMobileDevice()
  const [fileLabel, setFileLabel] = useState(
    extractFileName(profileData?.logo_name) || 'No file chosen',
  )

  useEffect(() => {
    fetchIndustries()
  }, [])

  const fetchIndustries = async () => {
    try {
      const response = await getIndustries()
      if (response.status === 200) {
        setIndustries(response.data || [])
      }
    } catch (error) {
      console.error('Error fetching industries:', error)
    }
  }
  return (
    <div className="mt-5">
      <div className="pb-0.5">
        <Label htmlFor="companyName" value="Company Name" />
      </div>
      <TextInput
        id="companyName"
        type="text"
        onChange={handleChange}
        name="companyName"
        value={profileData.companyName}
        placeholder="Your company name"
      />
      {errors.companyName && (
        <p className="text-14 text-red-500 font-[500]">{errors.companyName}</p>
      )}

      <div className="flex flex-col md:flex-row justify-between gap-4 mt-5">
        <div className="w-full">
          <DropdownSearch
            title="Country"
            options={Object.keys(COUNTRY_CITY).map(countryName => ({
              title: countryName,
            }))}
            handleChange={handleChange}
            isSuperAdmin={false}
            onSelectTest={() => {}}
            value={profileData.country}
          />
          {errors.country && (
            <p className="text-14 text-red-500 font-[500] mt-2">
              {errors.country}
            </p>
          )}
        </div>
        <div className="w-full">
          <DropdownSearch
            title="City"
            options={(COUNTRY_CITY[profileData.country] || []).map(city => ({
              title: city,
            }))}
            handleChange={handleChange}
            isSuperAdmin={false}
            onSelectTest={() => {}}
            value={profileData.city}
          />
          {errors.city && (
            <p className="text-14 text-red-500 font-[500] mt-2">
              {errors.city}
            </p>
          )}
        </div>
      </div>

      <div className={`flex flex-col md:flex-row md:gap-4 items-center mt-5`}>
        {profileData?.logo_url || preview ? (
          <div className="flex justify-center items-center h-20 w-20 md:h-20 md:w-20">
            <div className=" h-full w-full overflow-hidden rounded-full">
              <img
                src={preview || profileData?.logo_url}
                alt="Company Logo Preview"
                className="object-cover h-full w-full rounded-full"
              />
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center p-5 rounded-full text-3xl bg-gray-100 border-gray-100 h-20 w-20  mb-3">
            <InitialLetters Name={companyData?.name} />
          </div>
        )}
        <div className="w-full">
          <Label value="Company Logo" className="mb-2 block" />
          <div className="relative">
            <FileInput
              id="file-upload"
              name="ProfilePicture"
              type="file"
              className="opacity-0 absolute inset-0 w-full cursor-pointer remove-pointer-event"
              onChange={event => {
                const file = event.currentTarget.files[0]
                if (file) {
                  setDataChanged(true)
                  setFileLabel(file.name)
                  setProfilePicture(file)
                  const reader = new FileReader()
                  reader.onloadend = () => {
                    setPreview(reader.result)
                  }
                  reader.readAsDataURL(file)
                } else {
                  setFileLabel('No file chosen')
                }
              }}
              accept="image/png, image/jpg, image/jpeg"
            />
            <div
              className="border rounded-lg w-full cursor-pointer flex items-center bg-[#F9FAFB] border-[#D1D5DB]"
            >
              <button className="bg-gray-900 text-white px-3 py-2 pt-3 rounded-s-lg text-sm">
                Choose File
              </button>
              <span className="ml-5 text-sm">
                <TruncatedText text={fileLabel} limit={isMobile ? 15 : 25} />
              </span>
            </div>
            <p className="text-xs text-gray-500 mt-2">Maximum 5 MB</p>
          </div>
        </div>
      </div>

      <div className="w-full mt-5">
        <div className="pb-0.5">
          <Label htmlFor="companyDescription" value="Company Description" />
        </div>
        <TextInput
          id="companyDescription"
          type="text"
          onChange={handleChange}
          name="companyDescription"
          value={profileData?.companyDescription}
          placeholder="Your Company Description"
        />
      </div>

      <div className="w-full mt-5">
        <div className="pb-0.5">
          <Label htmlFor="companyWebsite" value="Company Website" />
        </div>
        <TextInput
          id="companyWebsite"
          type="text"
          onChange={handleChange}
          name="companyWebsite"
          value={profileData.companyWebsite}
          placeholder="Your Company Website"
        />
        {errors.companyWebsite && (
          <p className="text-14 text-red-500 font-[500] mt-2">
            {errors.companyWebsite}
          </p>
        )}
      </div>

      <div className="w-full mt-5">
        <div className="pb-0.5">
          <Label htmlFor="industry" value="Company Industry" />
        </div>
        <Select
          id="mainIndustry"
          onChange={handleChange}
          name="mainIndustry"
          value={profileData?.mainIndustry}
        >
          <option value="">Select Industry</option>
          {industries.map(industry => (
            <option key={industry.id} value={industry.id}>
              {industry.title}
            </option>
          ))}
        </Select>
      </div>

      <div className="w-full mt-5">
        <div className="pb-0.5">
          <Label htmlFor="industry" value="Company Size" />
        </div>
        <Select
          id="companySize"
          name="companySize"
          onChange={handleChange}
          value={profileData.companySize}
        >
          <option value="">Select Quantity</option>
          {BUSINESS_SIZES.map(size => (
            <option key={size.value} value={size.value}>
              {size.label}
            </option>
          ))}
        </Select>
      </div>

      <div className="w-full mt-5">
        <div className="pb-0.5">
          <Label value="Social Media" />
        </div>
        <TextInput
          id="twitter"
          type="text"
          onChange={handleChange}
          name="twitter"
          value={profileData.twitter}
          placeholder="Twitter Username (@username)"
          icon={FaTwitter}
          className="custom-icon-size"
        />
        {errors.twitter && (
          <p className="text-14 text-red-500 font-[500]  mt-2">
            {errors.twitter}
          </p>
        )}
        <TextInput
          id="facebook"
          type="text"
          onChange={handleChange}
          name="facebook"
          value={profileData.facebook}
          placeholder="Facebook Page (https://www.facebook.com/link)"
          className="mt-3 custom-icon-size"
          icon={FaFacebookF}
        />
        {errors.facebook && (
          <p className="text-14 text-red-500 font-[500]  mt-2">
            {errors.facebook}
          </p>
        )}
        <TextInput
          id="instagram"
          type="text"
          onChange={handleChange}
          name="instagram"
          value={profileData.instagram}
          placeholder="Instagram ID (@username)"
          className="mt-3 custom-icon-size"
          icon={FaInstagram}
        />
        {errors.instagram && (
          <p className="text-14 text-red-500 font-[500]  mt-2">
            {errors.instagram}
          </p>
        )}
      </div>
    </div>
  )
}

export default CompanyProfileTab
