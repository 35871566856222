import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import './tailwind.css'
import { store, persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import WebSocketProvider from 'providers/WebSocketProvider'
import { validateEnvVars } from 'validations/envs-validation'
const container = document.getElementById('root')
const root = createRoot(container)
if (window.location.hostname === 'app.qualime.ai') {
  console.log = () => {} // Disable console.log
  console.debug = () => {} // Optionally disable other console methods
  console.info = () => {}
  console.warn = () => {}
  console.error = () => {}
} else {
  validateEnvVars()
}
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <WebSocketProvider>
            <App />
          </WebSocketProvider>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
