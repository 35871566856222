import React, { useEffect, useState } from 'react'
import { CandidateMessagingList } from '../CandidateMessagingList/CandidateMessagingList'
import { CandidateOpenMessageView } from '../CandidateOpenMessageView/CandidateOpenMessageView'
import { CandidateMessageSenderInfo } from '../CandidateMessageSenderInfo/CandidateMessageSenderInfo'

import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../redux/currentUserSlice'
import { deleteConversation, getConversation } from 'services/messagingService'
import { addMessage } from '../../../../redux/messageSlice'

export const CandidateInboxView = ({defaultId}) => {
  const { currentUser } = useSelector(selectCurrentUser)
  const [chatListing, setChatListing] = useState([])
  const [query,setQuery]=useState('')
  const [selectedConversation, setSelectedConversation] = useState([])
  const [selectedChat, setSelectedChat] = useState();
  const dispatch = useDispatch()

  useEffect(() => {
    (currentUser.role==='candidate') && fetchConversation()
  }, [])

  const fetchConversation = async () => {
    const response = await getConversation()
    if (response?.status === 200) {
      setChatListing(response?.data)
      setSelectedChat(response?.data.find(item => item?.users[0]?.user_id === parseInt(defaultId)))
    }
  }

  const handleDeleteConversation = async () => {
    try {
      const { user_id } = selectedChat?.users?.[0]
      await deleteConversation(user_id)
      dispatch(addMessage([]))
      setSelectedConversation([])
    } catch (error) {}
  }

  return (
    <div className="border-b-2 border-gray rounded-lg bg-white min-h-[690px] min-w-[1024px] max-w-[1034px] shadow mr-3 pb-4">
      <div className=" flex flex-col md:flex-row gap-1 mx-4 pt-2">
        <CandidateMessagingList
          chatListing={chatListing}
          setSelectedChat={setSelectedChat}
          setSearchQuery={setQuery}
          searchQuery={query}
          selectedChat={selectedChat}
        />{' '}
        {selectedChat &&
          <>
            <CandidateOpenMessageView
              avatar={selectedChat.logo_url}
              chatName={`${currentUser?.first_name} ${currentUser?.last_name}`}
              selectedChat={selectedChat}
              setSelectedConversation={setSelectedConversation}
              currentUser={currentUser}
            />
            <CandidateMessageSenderInfo
              senderName={selectedChat?.users?.[0]?.user_name}
              companyName={selectedChat?.company_name}
              country={selectedChat?.country}
              profilePicture={selectedChat?.logo_url}
              filesList={[]}
              selectedConversation={selectedConversation}
              handleDeleteConversation={handleDeleteConversation}
            />
          </>
        }
      </div>
    </div>
  )
}
