import React, { createContext, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectCurrentUser } from '../../../redux/currentUserSlice'

const PersistantModuleContext = createContext()

export const PersistantProvider = ({ children }) => {
  const navigate = useNavigate()
  const { currentUser } = useSelector(selectCurrentUser)

  const handleAvatarClick = (candidate) => {
    navigate(currentUser.role === 'candidate' ? `/my-inbox?candidateId=${candidate?.users[0]?.user_id}` : `/company-inbox?candidateId=${candidate?.user?.id}`)
  }

  return (
    <PersistantModuleContext.Provider value={{ handleAvatarClick }}>
      {children}
    </PersistantModuleContext.Provider>
  )
}

export const usePersistantModule = () => useContext(PersistantModuleContext)
