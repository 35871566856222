import React from 'react'
import { Modal } from 'flowbite-react'

export const AvatarPopUp = ({ avatarUrl, show, onClose }) => {
  return (
    <Modal show={show} onClose={onClose} size="2xl" dismissible>
      <Modal.Body className="flex flex-col p-0 items-center justify-center bg-black rounded-md">
        <div className="relative w-full items-center justify-center">
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-white text-2xl bg-black bg-opacity-50 rounded-full w-8 h-8 flex items-center justify-center"
            aria-label="Close"
          >
            &times;
          </button>
          <div className="flex items-center justify-center overflow-hidden my-6 mx-4 sm:mx-10 md:my-11">
            <img
              src={avatarUrl}
              alt="Avatar"
              className="w-[200px] h-[200px] sm:w-[300px] sm:h-[300px] md:w-[350px] md:h-[350px] object-cover rounded-full border-4 border-gray-700 bg-white"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
