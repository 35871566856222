import { Button, Card } from 'flowbite-react'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import crossIcon from '../../assets/svgs/cross.svg'
import { verifyOtpForUser } from 'services/userService'
import useToastMessage from 'hooks/useToastMessage'

const OTPConfirmation = ({
  confirmationResult,
  handleSubmit,
  resendOtp,
  ForgotPasswordForm,
  userId,
  phoneNo,
  setOpenModal,
  resetWithEmail,
  email,
  handleEmail,
  user,
  setRedirectionOpenModal,
  handleOtpVerified,
  fromManageProfile,
}) => {
  const [length] = useState(6)
  const [otp, setOtp] = useState(new Array(length).fill(''))
  const inputRefs = useRef([])
  const navigate = useNavigate()
  const toastMessage = useToastMessage()

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus()
    }
  }, [])

  const verifyOtp = async () => {
    try {
      await confirmationResult.confirm(otp.join(''))
      if (fromManageProfile) {
        handleOtpVerified?.()
        setOpenModal(false)
        return
      }
      handleSubmit()
      console.log('Phone number verified!')
      setRedirectionOpenModal && setRedirectionOpenModal(true)
      ForgotPasswordForm && navigate(`/reset-password/${userId}?user=${user}`)
    } catch (error) {
      console.error('Error verifying OTP:', error)
      alert('The OTP is incorrect. Please try again.')
    }
  }

  const handleChange = (index, e) => {
    const value = e.target.value
    if (isNaN(value)) return

    const newOtp = [...otp]
    newOtp[index] = value.substring(value.length - 1)
    setOtp(newOtp)

    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus()
    }
  }

  const handleClick = index => {
    inputRefs.current[index].setSelectionRange(1, 1)

    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf('')].focus()
    }
  }

  const handleKeyDown = (index, e) => {
    if (
      e.key === 'Backspace' &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus()
    }
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleResetEmail = async () => {
    try {
      const response = await verifyOtpForUser(email, otp.join(''))
      const { id } = response?.data
      if (response.status === 200) {
        navigate(`/reset-password/${id}?user=${user}`)
      } else {
        toastMessage('The OTP is incorrect. Please try again', 'error')
      }
    } catch (error) {
      console.error('Error verifying otp', error)
      toastMessage('The OTP is incorrect. Please try again', 'error')
    }
  }

  return (
    <div className="flex flex-col h-screen font-global-styling">
      <main className="flex flex-grow items-center justify-center">
        <div className="flex flex-col md:flex-row w-full items-center justify-center gap-6 md:gap-20 p-4">
          <Card className="w-full md:w-auto px-4">
            <button
              className="flex justify-end cursor-pointer"
              onClick={handleClose}
            >
              <img src={crossIcon} alt="cross" className="cursor-pointer p-1" />
            </button>
            <p className="font-bold text-2xl">Verify your account</p>
            <p className="text-gray-500">
              We have sent you a six-digit code to{' '}
              <span className="font-semibold text-black">
                {phoneNo ? phoneNo : email}
              </span>
              . Enter the code below to confirm.
            </p>
            <div className="flex gap-2 md:gap-4 justify-center items-center my-10">
              {otp.map((value, index) => {
                return (
                  <input
                    key={index}
                    type="text"
                    ref={input => (inputRefs.current[index] = input)}
                    value={value}
                    onChange={e => handleChange(index, e)}
                    onClick={() => handleClick(index)}
                    onKeyDown={e => handleKeyDown(index, e)}
                    className="w-12 h-12 md:w-20 md:h-20 text-center text-2xl md:text-4xl font-bold rounded shadow-lg border-slate-300"
                  />
                )
              })}
            </div>
            <div className="flex justify-between bg-[#F9FAFB] py-2 px-4 rounded">
              <p className="text-[#6B7280] text-md">Didn't find the code?</p>
              <button
                className="text-[#1C64F2]"
                onClick={() =>
                  resetWithEmail ? handleEmail(true) : resendOtp?.()
                }
              >
                Resend Code
              </button>
            </div>
            <Button
              className="bg-blue-700 py-2 my-2 w-full"
              onClick={() =>
                resetWithEmail ? handleResetEmail() : verifyOtp()
              }
            >
              Verify Account
            </Button>
          </Card>
        </div>
      </main>
    </div>
  )
}

export default OTPConfirmation
