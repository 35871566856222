import React, { useEffect, useState } from 'react'
import AdminNavbar from './AdminNavbar'
import Table from '../table/Table'
import { generateColumns } from '../../utilis/helpers/tableHelper'
import { getContactedCandidates } from '../../services/userService'

const ContactedCandidates = () => {
  const [candidates, setCandidates] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState('')

  const getRevealedCandidates = async () => {
    try {
      const response = await getContactedCandidates(currentPage, query)
      const mappedData = response.data.records.map(item => {
        const { id, profile_status, profile_feedback } = item?.attributes
        return {
          id: id,
          candidate_name: `${item?.attributes?.candidate?.first_name} ${item?.attributes?.candidate?.last_name}`,
          company_name: item?.attributes?.company.name,
          status: profile_status?.toUpperCase(),
          feedback: profile_feedback ? profile_feedback : 'N/A',
        }
      })
      setCandidates(mappedData)
      setTotalPages(response?.data?.pagy?.total_pages)
      setTotalCount(response?.data?.pagy?.total_count)
    } catch (error) {
      console.error('Error fetching companies:', error)
    }
  }

  useEffect(() => {
    getRevealedCandidates()
  }, [currentPage, query])

  const columns = generateColumns(candidates)

  const onSearchCandidates = (search, value) => {
    setCurrentPage(1)
    setQuery(value)
  }
  return (
    <div className="flex h-screen w-full flex-col bg-[#f9fafb]">
      <AdminNavbar selectedItem="contacted-candidates" />
      <Table
        columns={columns}
        data={candidates}
        title={'Contacted Candidates'}
        getCandidates={getRevealedCandidates}
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        setCurrentPage={setCurrentPage}
        hideDeleteButton={true}
        hideEditButton={true}
        hideCreateButton={true}
        onSearch={onSearchCandidates}
      />
    </div>
  )
}

export default ContactedCandidates
