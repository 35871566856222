import axiosInstance from './axiosConfig'
import { AccountDeletionRequestEndpoint } from './constants'

export const createAccountDeletionRequest = async body => {
  return await axiosInstance.post(
    AccountDeletionRequestEndpoint.createAccountDeletionRequest,
    body,
  )
}

export const getPaginatedDeletionRequests = async (page, query) => {
  return await axiosInstance.get(
    AccountDeletionRequestEndpoint.getPaginatedDeletionRequests(page, query),
  )
}

export const deleteAccountDeletionRequest = async id => {
  return await axiosInstance.delete(
    AccountDeletionRequestEndpoint.deleteAccountDeletionRequest(id),
  )
}
