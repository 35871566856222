import React, { memo } from 'react'
import { TruncatedText } from 'utilis/helpers'
import { InitialLetters } from 'components/generics/Initials'

const MessageStatus = memo(({ isTyping, message }) => (
  <>
    {isTyping ? (
      <span className="flex text-sm text-[#1A56DB] font-normal">Typing...</span>
    ) : (
      <span className="flex text-sm text-[#111928] font-medium">
        <TruncatedText text={message} limit={30} />
      </span>
    )}
  </>
))

const UnreadMessagesBadge = memo(({ unreadCount }) => (
  <div className="flex rounded-full w-[16px] h-[16px] bg-[#EBF5FF] justify-center">
    <span className="text-[#1C64F2] text-[10px] font-medium">
      {unreadCount}
    </span>
  </div>
))

export const InboxMessageBubble = memo(
  ({
    avatar,
    senderName,
    message,
    sentTime,
    isTyping = false,
    isOnline = true,
    unreadCount = 3,
    handleMessageClick = () => {},
    chat,
  }) => {
    return (
      <button
        className="flex flex-row w-full flex-grow"
        onClick={() => handleMessageClick(chat)}
      >
        <div className="flex flex-row w-full gap-2.5 px-3 py-1.5 bg-white flex-grow items-center">
          <div className="relative">
            {avatar ? (
              <img
                src={avatar}
                alt="avatar icon"
                className="rounded-full w-8 h-8"
              />
            ) : (
              <div className="flex justify-center items-center p-5 rounded-full text-lg bg-gray-100 border-[#E5E7EB] h-8 w-8">
                <InitialLetters
                  Name={senderName}
                />
              </div>
            )}
            {isOnline && (
              <span className="absolute right-0 top-0 sm:top-[-1px] sm:right-[-3px] w-3 h-3 bg-green-400 rounded-full border-[1.5px] border-white"></span>
            )}
          </div>
          <div className="flex flex-col flex-grow">
            <div className="flex flex-row justify-between items-center">
              <span className="text-base font-medium">{senderName}</span>
              <span className="text-xs font-normal text-[#6B7280]">
                {sentTime}
              </span>
            </div>
            <div className="flex flex-row justify-between items-center">
              <MessageStatus isTyping={isTyping} message={message} />
              <UnreadMessagesBadge unreadCount={unreadCount} />
            </div>
          </div>
        </div>
      </button>
    )
  },
)
