import React from 'react'
import 'assets/css/user-settings-tab.css'
import crossIcon from 'assets/svgs/cross.svg'
import { useFormik } from 'formik'
import { employerDeleteAccountValidationSchema } from 'validations/UserSettingsTabValidationSchema'

export const EmployerDeleteAccountPopup = ({
  onClose,
  handleDeleteAccount = () => {},
}) => {
  const formik = useFormik({
    initialValues: {
      feedback: '',
    },
    validationSchema: employerDeleteAccountValidationSchema,
    onSubmit: values => {
      handleDeleteAccount(values)
    },
    validateOnChange: true,
    validateOnBlur: true,
  })

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = formik

  return (
    <div className="bg-[#FDFDFD] flex flex-col p-8 gap-8 text-black min-w-[630px] min-h-80 border rounded-lg relative main-deactivate-popup">
      <button onClick={onClose} className="absolute top-4 right-5">
        <img
          src={crossIcon}
          alt="Close"
          className="cursor-pointer h-[24px] w-[24px] employer-cross-icon"
        />
      </button>

      <div className="flex flex-col gap-0.5">
        <span className="text-2xl font-bold employer-deactivate-heading">
          Request to delete employer account
        </span>
        <span className="text-base font-normal text-[#6B7280] employer-deactivate-sub-heading">
          We regret to hear that you are leaving Quali.
        </span>
      </div>

      <div className="flex flex-col min-w-[555px] max-w-[555px] min-h-[154px] max-h-[154px] gap-y-2 main-deactivate-thoughts">
        <span className="text-sm font-medium text-[#585C5F] employer-deactivate-sub-heading">
          We would appreciate it if you could share the reasons for your
          departure.
        </span>

        <textarea
          id="deletion-reason"
          rows="4"
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 resize-none flex-grow employer-popup-text-area"
          placeholder="Write text here ..."
          value={values.feedback}
          onChange={handleChange}
          onBlur={handleBlur}
          name="feedback"
          maxLength={300}
        ></textarea>
        {touched.feedback && errors.feedback && (
          <p className="text-xs text-red-500">{errors.feedback}</p>
        )}
      </div>

      <div className="flex justify-end">
        <button
          type="button"
          onClick={handleSubmit}
          className={`text-sm font-medium py-2 lg:px-3 px-7 rounded-lg mb-2 lg:mb-0 min-w-40 bg-[#1A56DB] text-white border ${
            isSubmitting || !values.feedback || errors.feedback
              ? 'opacity-50 cursor-not-allowed'
              : ''
          }`}
          disabled={
            isSubmitting || !values.feedback || Boolean(errors.feedback)
          }
        >
          Submit and Close
        </button>
      </div>
    </div>
  )
}
