import React from 'react'
import { Button, Card } from 'flowbite-react'
import { Link } from 'react-router-dom'
import OTPConfirmation from '../components/CompanyReister/OTPConfirmation'

const OTP = ({ confirmationResult }) => {
  return (
    <>
      <div className="flex flex-col">
        <main className="flex flex-grow items-center justify-center">
          <div className="flex w-full items-center justify-center gap-20">
            <Card className="w-full">
              <p className="font-bold text-2xl">Verify your account</p>
              <p>
                We emailed you a six-digit code to{' '}
                <span className="font-semibold">+65xxx</span>. Enter the code
                below to confirm.
              </p>
              <OTPConfirmation confirmationResult={confirmationResult} />
              <div className="flex justify-between bg-gray-100 text-slate-500 py-2 px-4 rounded">
                <p className="text-gray-100">Didn't get the code?</p>
                <p className="text-blue-700">Resend Code</p>
              </div>
              <Link to="">
                <Button className="bg-blue-700 py-2 my-2 w-full">
                  Verify Account
                </Button>
              </Link>
            </Card>
          </div>
        </main>
      </div>
    </>
  )
}

export default OTP
