import React, { useState, useEffect } from 'react'
import { Modal, Select, Label } from 'flowbite-react'
import { companyInitialState } from '../../../config/constants'
import { COUNTRY_CITY } from '../../../data/registerFormData'
import { BUSINESS_SIZES } from '../../../config/constants'
import { editCompany } from '../../../services/companyService'
import useToastMessage from '../../../hooks/useToastMessage'
import { PhoneInput } from 'react-international-phone'
import 'assets/css/CompanyProfile.css'
import 'react-international-phone/style.css'
import { updateUser } from 'services/userService'
import { companyEditSchema } from 'validations/CompanyProfileSchema'
import { ErrorMessage, Form, Formik } from 'formik'

const CompanyEditModal = ({
  companyData,
  isModalOpen,
  closeModal,
  title,
  industries,
  getCompanies,
}) => {
  const [data, setData] = useState(companyInitialState)
  const [cities, setCities] = useState([])
  const toastMessage = useToastMessage()

  useEffect(() => {
    setData(companyData)
    setCities(COUNTRY_CITY[companyData?.country] || [])
  }, [companyData])
  const handleChange = (e, setFieldValue) => {
    const { name, value } = e.target

    if (name === 'country') {
      setCities(COUNTRY_CITY[value] || [])
      setFieldValue(name, value)
      setData(prevState => ({
        ...prevState,
        [name]: value,
      }))
    }
    if (name.startsWith('social_network_links.')) {
      const key = name.split('.')[1]
      setFieldValue(key, value)
      setData(prevState => ({
        ...prevState,
        social_network_links: {
          ...prevState.social_network_links,
          [key]: value,
        },
      }))
    } else {
      setFieldValue(name, value)
      setData(prevState => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handlePhoneInputChange = (phone, meta) => {
    if (phone !== '+1') {
      setData(prevState => ({
        ...prevState,
        contact_number: phone,
        country_code: `+${meta?.country?.dialCode}`,
      }))
    }
  }

  const handleSubmit = async values => {
    const user = new FormData()
    user.append('user[email]', data?.contact_email)
    user.append('user[phone_no]', data?.contact_number)
    user.append('user[country_code]', data?.country_code)

    try {
      if (title === 'Edit Company') {
        const response = await editCompany(values)

        if (response?.status === 200) {
          const resp = await updateUser(data.user_id, user)
          if (resp?.status === 200) {
            toastMessage('Company updated successfully!', 'success')
            closeModal()
            getCompanies()
          }
        }
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors.join(', ')
        alert(errorMessage)
      }
    }
  }
  return (
    <Modal
      show={isModalOpen}
      onClose={() => closeModal(false)}
      theme={{
        content: {
          base: 'bg-transparent w-3/4',
          inner: 'bg-transparent',
        },
      }}
    >
      <div>
        <div className="relative w-full max-w-2xl max-h-full">
          <Formik
            initialValues={data}
            enableReinitialize
            validationSchema={companyEditSchema}
            onSubmit={handleSubmit}
            validateOnChange
          >
            {({ setFieldValue, values }) => (
              <Form className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    {title}
                  </h3>
                  <button
                    onClick={() => closeModal()}
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="p-6 space-y-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label className="block text-sm font-medium text-gray-900 dark:text-white">
                        Company Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Your Company Name"
                        onChange={event => handleChange(event, setFieldValue)}
                        value={values.name}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label className="block text-sm font-medium text-gray-900 dark:text-white">
                        Description
                      </label>
                      <input
                        type="text"
                        name="description"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Description"
                        onChange={event => handleChange(event, setFieldValue)}
                        value={values.description}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label className="block text-sm font-medium text-gray-900 dark:text-white">
                        Contact Email
                      </label>
                      <input
                        type="text"
                        name="contact_email"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Email"
                        onChange={event => handleChange(event, setFieldValue)}
                        value={values.contact_email}
                      />
                      <ErrorMessage
                        name="contact_email"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label className="block text-sm font-medium text-gray-900 dark:text-white">
                        Contact Number
                      </label>
                      <PhoneInput
                        key={values?.id}
                        defaultCountry="us"
                        value={values?.contact_number || ''}
                        onChange={(phone, country) =>
                          handlePhoneInputChange(phone, country)
                        }
                        placeholder="123 4567 890"
                      />
                      <ErrorMessage
                        name="contact_number"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <div className="w-full">
                        <div className="flex items-center pb-0.5">
                          <Label htmlFor="country" value="Country" />
                        </div>
                        <Select
                          id="country"
                          name="country"
                          onChange={event => handleChange(event, setFieldValue)}
                          value={values.country}
                        >
                          <option value="">Select Country</option>
                          {Object.keys(COUNTRY_CITY).map(countryName => (
                            <option key={countryName} value={countryName}>
                              {countryName}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <ErrorMessage
                        name="country"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <div className="w-full">
                        <div className="flex pb-0.5">
                          <Label htmlFor="city" value="City" />
                        </div>
                        <Select
                          id="city"
                          name="city"
                          onChange={event => handleChange(event, setFieldValue)}
                          value={values.city}
                        >
                          <option value="">Select City</option>
                          {cities.map(city => (
                            <option key={city} value={city}>
                              {city}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <div className="w-full">
                        <div className="flex pb-0.5">
                          <Label htmlFor="industry" value="Industry" />
                        </div>
                        <Select
                          id="industry"
                          onChange={event => handleChange(event, setFieldValue)}
                          name="industry_id"
                          value={values.industry_id}
                        >
                          <option value="">Select Industry</option>
                          {industries.map(industry => (
                            <option key={industry.id} value={industry.id}>
                              {industry.title}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <ErrorMessage
                        name="industry_id"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <div className="w-full">
                        <div className="flex items-center pb-0.5">
                          <Label htmlFor="companySize" value="Company Size" />
                        </div>
                        <Select
                          id="companySize"
                          name="company_size"
                          onChange={event => handleChange(event, setFieldValue)}
                          value={values.company_size}
                        >
                          <option value="">Select Quantity</option>
                          {BUSINESS_SIZES.map(size => (
                            <option key={size.value} value={size.value}>
                              {size.label}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <ErrorMessage
                        name="company_size"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6">
                      <label className="block  text-sm font-medium text-gray-900 dark:text-white">
                        Website
                      </label>
                      <input
                        type="text"
                        name="website_link"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Website Link"
                        onChange={event => handleChange(event, setFieldValue)}
                        value={values.website_link}
                      />
                      <ErrorMessage
                        name="website_link"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label className="block  text-sm font-medium text-gray-900 dark:text-white">
                        Social Links
                      </label>
                      <input
                        type="text"
                        name="social_network_links.twitter"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Twitter"
                        onChange={event => handleChange(event, setFieldValue)}
                        value={values.social_network_links.twitter}
                      />
                      <ErrorMessage
                        name="social_network_links.twitter"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 mt-5">
                      <input
                        type="text"
                        name="social_network_links.facebook"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Facebook"
                        onChange={event => handleChange(event, setFieldValue)}
                        value={values.social_network_links.facebook}
                      />
                      <ErrorMessage
                        name="social_network_links.facebook"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 ">
                      <input
                        type="text"
                        name="social_network_links.instagram"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Instagram"
                        onChange={event => handleChange(event, setFieldValue)}
                        value={values.social_network_links.instagram}
                      />
                      <ErrorMessage
                        name="social_network_links.instagram"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center p-6 space-x-3 rtl:space-x-reverse border-t border-gray-200 rounded-b dark:border-gray-600">
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    {title.includes('Create') ? 'Create' : 'Update'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}

export default CompanyEditModal
