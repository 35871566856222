import React from 'react'
import RegisterCandidate from '../../components/candidateRegister'

const CandidateRegister = () => {
  return (
    <div>
      <RegisterCandidate />
    </div>
  )
}

export default CandidateRegister
