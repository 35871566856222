import axiosInstance from './axiosConfig'
import { ExpertisesEndpoints } from './constants'

export const getExpertises = async queryParams => {
  const response = await axiosInstance.get(
    `${ExpertisesEndpoints.getExpertises}?${queryParams}`,
  )
  return response
}

export const getAllExpertise = async () => {
  const response = await axiosInstance.get(ExpertisesEndpoints.allexpertises)
  return response
}

export const editExpertise = async data => {
  const response = await axiosInstance.patch(
    ExpertisesEndpoints.editExpertise(data?.id),
    { expertise: data },
  )
  return response
}

export const createExpertise = async data => {
  const response = await axiosInstance.post(
    ExpertisesEndpoints.allexpertises,
    { expertise: data },
  )
  return response
}

export const deleteExpertise = async id => {
  const response = await axiosInstance.delete(
    ExpertisesEndpoints.deleteExpertise(id),
  )
  return response
}

export const getPaginatedExtertiseList = async (page, query) => {
  const response = await axiosInstance.get(
    ExpertisesEndpoints.getPaginatedExpertise(page, query),
  )
  return response
}
