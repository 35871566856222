import React from 'react'

const deleteIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M13.6111 3.15789H10.5556V1.57895C10.5556 1.16018 10.3946 0.758573 10.1081 0.462463C9.82157 0.166353 9.43297 0 9.02778 0H5.97222C5.56703 0 5.17843 0.166353 4.89192 0.462463C4.60541 0.758573 4.44444 1.16018 4.44444 1.57895V3.15789H1.38889C1.18629 3.15789 0.991995 3.24107 0.848738 3.38913C0.705481 3.53718 0.625 3.73799 0.625 3.94737C0.625 4.15675 0.705481 4.35756 0.848738 4.50561C0.991995 4.65367 1.18629 4.73684 1.38889 4.73684H2.15278V13.4211C2.15278 13.8398 2.31374 14.2414 2.60025 14.5375C2.88677 14.8336 3.27536 15 3.68056 15H11.3194C11.7246 15 12.1132 14.8336 12.3997 14.5375C12.6863 14.2414 12.8472 13.8398 12.8472 13.4211V4.73684H13.6111C13.8137 4.73684 14.008 4.65367 14.1513 4.50561C14.2945 4.35756 14.375 4.15675 14.375 3.94737C14.375 3.73799 14.2945 3.53718 14.1513 3.38913C14.008 3.24107 13.8137 3.15789 13.6111 3.15789ZM5.97222 1.57895H9.02778V3.15789H5.97222V1.57895ZM11.3194 13.4211H3.68056V4.73684H11.3194V13.4211Z"
        fill={color}
      />
      <path
        d="M5.97222 5.52632C5.76963 5.52632 5.57533 5.60949 5.43207 5.75755C5.28881 5.9056 5.20833 6.10641 5.20833 6.31579V11.8421C5.20833 12.0515 5.28881 12.2523 5.43207 12.4003C5.57533 12.5484 5.76963 12.6316 5.97222 12.6316C6.17482 12.6316 6.36912 12.5484 6.51237 12.4003C6.65563 12.2523 6.73611 12.0515 6.73611 11.8421V6.31579C6.73611 6.10641 6.65563 5.9056 6.51237 5.75755C6.36912 5.60949 6.17482 5.52632 5.97222 5.52632Z"
        fill={color}
      />
      <path
        d="M9.02778 5.52632C8.82518 5.52632 8.63088 5.60949 8.48763 5.75755C8.34437 5.9056 8.26389 6.10641 8.26389 6.31579V11.8421C8.26389 12.0515 8.34437 12.2523 8.48763 12.4003C8.63088 12.5484 8.82518 12.6316 9.02778 12.6316C9.23037 12.6316 9.42467 12.5484 9.56793 12.4003C9.71119 12.2523 9.79167 12.0515 9.79167 11.8421V6.31579C9.79167 6.10641 9.71119 5.9056 9.56793 5.75755C9.42467 5.60949 9.23037 5.52632 9.02778 5.52632Z"
        fill={color}
      />
    </svg>
  )
}

export default deleteIcon
