import React from 'react'
import { Routes, Route } from 'react-router-dom'
import HomePage from './components/HomePage'
import './App.css'
import './assets/css/satoshi.css'
import EmployerLogin from './pages/session/employerLogin'
import Register from './pages/Register'
import OTP from './pages/OTP'
import CompleteCompanyProfile from './pages/CompleteCompanyProfile'
import SearchCandidates from './components/Search/SearchCandidates'
import SearchResults from './components/Search/SearchResults'
import SearchedCandidateList from './components/Search/CandidatePipelineDashboard'
import Overview from './pages/Overview'
import TestAndAssessment from './pages/TestAndAssessment'
import AllQualiTest from './components/Dashboard/Overview/AllQualiTest'
import { UserProvider } from './lib/UserContext'
import CompanyUserRoutes from './routes/CompanyUserRoutes'
import { SearchProvider } from './contexts/SearchDataContext'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import ToastContainer from './components/generics/toast'
import ProtectedRoutes from './routes/ProtectedRoutes'
import CandidateRegister from './pages/candidateRegister'
import CandidateLogin from './pages/session/candidateLogin'
import AdminLogin from './pages/admin/AdminLogin'
import CandidatesList from './components/admin/CandidatesList'
import CandidateListofTest from './components/Dashboard/Overview/CandidateListofTest'
import TestListofCandidate from 'components/Dashboard/Overview/TestListofCandidates'
import CompaniesList from './components/admin/CompaniesList'
import IndustriesList from './components/admin/IndustriesList'
import TestsList from './components/admin/TestsList'
import SearchResultHistoriesList from './components/admin/SearchResultHistoriesList'
import SkillsList from './components/admin/SkillsList'
import ExpertiseList from './components/admin/ExpertiseList'
import RevealedProfiles from './pages/RevealedProfiles'
import CreateTestsListing from './components/admin/CreateTestsListing'
import ManageTestList from './components/admin/ManageTestList'
import ContactedCandidates from './components/admin/ContactedCandidates'
import ManageProfile from 'pages/ManageProfile'
import { CandidateAccountOverview } from 'pages/candidateAccountOverview/CandidateAccountOverview'
import CompleteCandidateProfile from 'pages/completeCandidateProfile'
import { EmployerAccountDeletionRequests } from 'components/admin/employer-account-deletion/EmployerAccountDeletionRequests'
import Comparison from 'pages/Pipeline/CandidateComparison'
import { CandidateFullScreenInboxView } from 'pages/candidateFullScreenInbox/CandidateFullScreenInboxView'
import { EmployerFullScreenInboxView } from 'pages/employerFullScreenInbox/EmployerFullScreenInbox'

function App() {
  return (
    // <Router>
    <UserProvider>
      <div className="app-container font-global-styling">
        <div className="content">
          <SearchProvider>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/login" element={<EmployerLogin />} />
              <Route exact path="/admin/login" element={<AdminLogin />} />
              <Route element={<ProtectedRoutes isAdminRoute={true} />}>
                <Route
                  exact
                  path="/admin/companies"
                  element={<CompaniesList />}
                />
                <Route
                  exact
                  path="/admin/candidates"
                  element={<CandidatesList />}
                />
                <Route
                  exact
                  path="/admin/industries"
                  element={<IndustriesList />}
                />
                <Route
                  exact
                  path="/admin/revealed-candidates"
                  element={<TestsList />}
                />
                <Route
                  exact
                  path="/admin/search-result-histories"
                  element={<SearchResultHistoriesList />}
                />
                <Route exact path="/admin/skills" element={<SkillsList />} />
                <Route
                  exact
                  path="/admin/expertise"
                  element={<ExpertiseList />}
                />
                <Route
                  exact
                  path="/admin/tests"
                  element={<CreateTestsListing />}
                />
                <Route
                  exact
                  path="/admin/manage-test"
                  element={<ManageTestList />}
                />
                <Route
                  exact
                  path="/admin/contacted-candidates"
                  element={<ContactedCandidates />}
                />
                <Route
                  exact
                  path="/admin/account-deletion-requests"
                  element={<EmployerAccountDeletionRequests />}
                />
              </Route>
              <Route exact path="/user/login" element={<CandidateLogin />} />
              <Route exact path="/register" element={<Register />} />
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/candidate-register"
                element={<CandidateRegister />}
              />
              <Route
                exact
                path="/complete-company-profile/:tab"
                element={<CompleteCompanyProfile />}
              />
              <Route
                exact
                path="/reset-password/:id"
                element={<ResetPassword />}
              />
              <Route
                exact
                path="/test-list-of-candidiate"
                element={<TestListofCandidate />}
              />

              <Route element={<ProtectedRoutes isAdminRoute={false} />}>
                <Route element={<CompanyUserRoutes />}>
                  <Route
                    exact
                    path="/complete-company-profile/:tab"
                    element={<CompleteCompanyProfile />}
                  />
                  <Route exact path="/search" element={<SearchCandidates />} />
                  <Route
                    exact
                    path="/search-results"
                    element={<SearchResults />}
                  />
                  <Route
                    exact
                    path="/searched-candidates"
                    element={<SearchedCandidateList />}
                  />
                  <Route
                    exact
                    path="/revealed-profiles"
                    element={<RevealedProfiles />}
                  />
                  <Route
                    exact
                    path="/test-and-assessment"
                    element={<TestAndAssessment />}
                  />
                  <Route
                    exact
                    path="/all-quali-tests"
                    element={<AllQualiTest />}
                  />
                  <Route
                    exact
                    path="/candidate-list-of-test"
                    element={<CandidateListofTest />}
                  />
                  <Route exact path="/confirmation" element={<OTP />} />
                  <Route
                    exact
                    path="/company-inbox"
                    element={<EmployerFullScreenInboxView />}
                  />
                </Route>
                <Route path="/overview" element={<Overview />} />
                <Route
                  exact
                  path="/manage-profile"
                  element={<ManageProfile />}
                />

                <Route
                  exact
                  path="/user-profile-edit"
                  element={<CompleteCandidateProfile />}
                />
                <Route
                  exact
                  path="/candidate-account-overview"
                  element={<CandidateAccountOverview />}
                />
                <Route
                  exact
                  path="/compare-candidates"
                  element={<Comparison />}
                />
                <Route
                  exact
                  path="/my-inbox"
                  element={<CandidateFullScreenInboxView />}
                />
              </Route>
            </Routes>
          </SearchProvider>
        </div>
      </div>
      <ToastContainer />
    </UserProvider>
    // </Router>
  )
}

export default App
