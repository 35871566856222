import React, { useEffect } from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik'
import { Label, TextInput } from 'flowbite-react'
import { PhoneInput } from 'react-international-phone'

export const LoginForm = ({
  showPassword,
  togglePasswordVisibility,
  eyeicon,
  eyeSlashIcon,
  useWhatsApp,
  handleToggleLoginMethod,
}) => {
  const {
    setFieldValue,
    setFieldTouched,
    validateField,
    values,
    errors,
    touched,
  } = useFormikContext()

  useEffect(() => {
    if (!useWhatsApp) {
      setFieldValue('login', '')
    }
  }, [useWhatsApp, setFieldValue])

  const handlePhoneInputChange = phone => {
    setFieldValue('login', phone, true);
    setFieldTouched('login', true, true);
    setTimeout(() => {
      validateField('login');
    }, 0);
  }

  return (
    <>
      <div>
        <label className="inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={useWhatsApp}
            onChange={() =>
              handleToggleLoginMethod(setFieldValue, validateField)
            }
          />
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
            Login with WhatsApp Number
          </span>
        </label>
      </div>
      {useWhatsApp ? (
        <div>
          <div className="block mb-1 text-lg">
            <Label value="WhatsApp Number" />
          </div>
          <PhoneInput
            defaultCountry="sa"
            value={values.login}
            onChange={handlePhoneInputChange}
            placeholder="123 4567 890"
          />
          <ErrorMessage name="login" component="div" className="text-red-500" />
        </div>
      ) : (
        <div>
          <div className="block mb-1 text-lg">
            <Label htmlFor="login" value="E-mail address" />
          </div>
          <Field
            as={TextInput}
            id="login"
            type="text"
            name="login"
            placeholder="Your registered e-mail address"
            className="text-base block"
          />
          <ErrorMessage name="login" component="div" className="text-red-500" />
        </div>
      )}
      <div>
        <div className="mt-2 mb-1 block">
          <Label htmlFor="password" value="Password" />
        </div>
        <div className="relative">
          <Field
            as={TextInput}
            id="password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="********"
            className="text-base block"
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-auto"
            aria-label={showPassword ? 'Hide password' : 'Show password'}
          >
            <img
              src={showPassword ? eyeicon : eyeSlashIcon}
              alt={showPassword ? 'Hide password' : 'Show password'}
              className={`h-4 w-4 ${showPassword ? 'text-gray-400' : 'text-gray-0'}`}
            />
          </button>
          <ErrorMessage
            name="password"
            component="div"
            className="text-red-500 absolute"
          />
        </div>
      </div>
    </>
  )
}
