import React from 'react'
import { Field } from 'formik'
import { Label, Checkbox as FlowbiteCheckbox } from 'flowbite-react'
import ReactCountryFlag from 'react-country-flag'

const CountrySelect = () => {
  return (
    <>
      <Label
        htmlFor="countrylist"
        value="What country are you open to work in GCC?"
        className="text-gray-900 font-medium text-base leading-6"
      />
      <label className="block text-base font-medium text-gray-500 mt-1 mb-5 leading-6">
        You can select more than one country
      </label>
      <div className="space-y-2">
        {[
          // { name: 'None', flag: null },
          { name: 'Saudi Arabia', flag: 'SA' },
          { name: 'United Arab Emirates', flag: 'AE' },
          { name: 'Kuwait', flag: 'KW' },
          { name: 'Bahrain', flag: 'BH' },
          { name: 'Oman', flag: 'OM' },
          { name: 'Qatar', flag: 'QA' },
          { name: 'all', flag: null },
        ].map(country => (
          <div key={country.name} className="flex items-center space-x-2">
            <Field
              name="workCountries"
              className="text-gray-900 text-sm font-medium leading-none"
            >
              {({ field, form }) => {
                const isDisabled =
                  country.name !== 'None' && field.value.includes('None')
                return (
                  <>
                    <FlowbiteCheckbox
                      id={country.name}
                      checked={
                        country.name === 'all'
                          ? [
                              'Saudi Arabia',
                              'United Arab Emirates',
                              'Kuwait',
                              'Bahrain',
                              'Oman',
                              'Qatar',
                            ].every(c => field.value.includes(c))
                          : field.value.includes(country.name)
                      }
                      onChange={() => {
                        const set = new Set(field.value)
                        if (country.name === 'None') {
                          form.setFieldValue(
                            'workCountries',
                            set.has('None') ? [] : ['None'],
                          )
                        } else if (country.name === 'all') {
                          if (set.has('all') || field.value.length === 6) {
                            form.setFieldValue('workCountries', [])
                          } else {
                            const allCountries = [
                              'Saudi Arabia',
                              'United Arab Emirates',
                              'Kuwait',
                              'Bahrain',
                              'Oman',
                              'Qatar',
                            ]
                            form.setFieldValue('workCountries', allCountries)
                          }
                        } else {
                          if (set.has('None')) {
                            set.delete('None')
                          }
                          if (set.has(country.name)) {
                            set.delete(country.name)
                          } else {
                            set.add(country.name)
                          }
                          form.setFieldValue('workCountries', Array.from(set))
                          const allCountries = [
                            'Saudi Arabia',
                            'United Arab Emirates',
                            'Kuwait',
                            'Bahrain',
                            'Oman',
                            'Qatar',
                          ]
                          if (allCountries.every(c => set.has(c))) {
                            set.add('all')
                          } else {
                            set.delete('all')
                          }
                          form.setFieldValue('workCountries', Array.from(set))
                        }
                      }}
                      disabled={isDisabled}
                    />
                    <label htmlFor={country.name}>
                      <span
                        className={
                          isDisabled ? 'text-gray-400' : 'text-[#111928]'
                        }
                      >
                        {country.name === 'all'
                          ? 'All of the above'
                          : country.name}{' '}
                        {country.flag && (
                          <ReactCountryFlag
                            className="ml-1"
                            countryCode={country.flag}
                            svg
                          />
                        )}
                      </span>
                    </label>
                  </>
                )
              }}
            </Field>
          </div>
        ))}
      </div>
    </>
  )
}

export default CountrySelect
