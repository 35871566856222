import React from 'react'
import { Modal } from 'flowbite-react'
import '../../../assets/css/video_m.css'
const VideoModal = ({ isModalOpen, closeModal, videoSrc }) => {
  return (
    <Modal show={isModalOpen} onClose={closeModal} size="5xl">
      <Modal.Header className=" font-extrabold tracking-wide text-center"></Modal.Header>
      <Modal.Body>
        <div className="relative pt-[56.25%] bg-black overflow-hidden">
          <iframe
            className="responsive-iframe"
            src={videoSrc}
            title="YouTube video player"
            width="100%"
            height="100%"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default VideoModal
