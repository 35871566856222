import React, { useEffect, useState } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import redCrossIcon from 'assets/svgs/red-x-outline.svg'
import redCrossCircle from 'assets/svgs/red-x-circle.svg'
import passedBag from '../../../../assets/svgs/passed_bag.svg'
import BlueworkIcon from 'components/generics/Icons/BlueworkIcon'
import redTrashIcon from 'assets/svgs/red-trash-icon.svg'
import PipelineCandidateProfile from '../../../../components/Search/modals/PipelineCandidateProfile'
import {
  selectTalentSearchData
} from '../../../../redux/talentSearchSlice'
import { selectCurrentUser } from '../../../../redux/currentUserSlice'
import userOutlineIcon from 'assets/svgs/black-user-outline.svg'
import blueBriefcaseIcon from 'assets/svgs/blue-briefcase-outline.svg'
import { InfoButtonSidePanel } from './InfoButtonSidePanel'
import { FileItemsView } from 'components/Messaging/CandidateMessaging/CandidateMessageSenderInfo/FileItemsView'
import { InitialLetters } from 'components/generics/Initials'
export const EmployerMessageCandidateInfo = ({
  candidate,
  yearsOfExperience,
  country,
  profilePicture,
  selectedChat,
  filesList = [],
  selectedConversation = [],
  handleDeleteConversation,
  setSelectedChat,
  candidateStatusUpdated,
  setCandidateStatusUpdate,
  fetchStatus,
  handleSetstatus,
  profileStatus,
  searchId

}) => {
  const talentSearch = useSelector(selectTalentSearchData)
  const { currentUser } = useSelector(selectCurrentUser)
  const [openDetailModal, setOpenDetailModal] = useState(false)
  const [candidatetoshow, setCandidatetoshow] = useState({})
  const attachments = selectedConversation.filter(
    message => message.attachments,
  )
  const handleViewProgress = (id )=> {
    const selectedCandidate = talentSearch?.searchData?.search_results?.find(
      candidatee => candidatee?.user?.id === id,
    )
    setCandidatetoshow(selectedCandidate)
    setOpenDetailModal(true)
  }
  useEffect(() => {


    fetchStatus(searchId)
  }, [candidate, candidateStatusUpdated ,openDetailModal])

  const onDeleteClick = () => {
    handleDeleteConversation?.()
  }
  const StatusBadge = ({ text, icon, color }) => (
    <span
      className={`flex items-center justify-center px-3 py-1.5 bg-gray-100 border border-gray-200 text-${color} text-sm rounded-lg w-40`}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {text}
    </span>
  )

  return (
    <div className="flex flex-col gap-3.5 p-4 max-w-[380px] min-w-[380px] bg-white">
      <div className="flex flex-col gap-2 items-center">
        {profilePicture ? (
          <img
            src={profilePicture}
            alt="User Avatar"
            className="rounded-full w-20 h-20"
          />
        ) : (
          <div className="flex justify-center items-center p-5 rounded-full text-2xl bg-gray-100 border-[#E5E7EB] h-20 w-20">
            <InitialLetters
              Name={`${candidate?.user?.first_name} ${candidate?.user?.last_name}`}
            />
          </div>
        )}
        <span className="text-lg font-bold">{`${candidate?.user?.first_name} ${candidate?.user?.last_name}`}</span>
        <span className="text-sm font-medium text-[#4B5563]">
          <span>{yearsOfExperience}</span> years of experience
        </span>
        <span className="text-sm font-medium text-[#4B5563]">
          From <span className="text-[#1F2A37]">{country}</span>
        </span>
      </div>

      <div className="flex flex-row justify-center">
        <InfoButtonSidePanel
          iconSrc={userOutlineIcon}
          text="View Full Profile"
          className="border-gray-200 min-w-[179px]"
          onClick={()=>handleViewProgress(candidate?.user?.id)}
        />
      </div>
      <div>
        {openDetailModal && candidate && (
            <PipelineCandidateProfile
              openModal={openDetailModal}
              setOpenModal={setOpenDetailModal}
              candidate={candidatetoshow || candidate}
              updatedStatus={profileStatus}
              setCandidateStatusUpdate={setCandidateStatusUpdate}
              searchId={searchId}
              candidateStatusUpdated={openDetailModal}
            />
          )}
      </div>
      {profileStatus === null ?
        <div className="flex flex-row justify-between px-8">
          <InfoButtonSidePanel
            iconSrc={blueBriefcaseIcon}
            text="Offer"
            className="border-blue-700 text-[#1A56DB] min-w-[139px]"
            onClick={() => {handleViewProgress(candidate?.user?.id)}}
          />
          <InfoButtonSidePanel
                    iconSrc={redCrossIcon}
            text="Reject"
            className="border-red-700 text-[#C81E1E] min-w-[139px]"
            onClick={() => { handleSetstatus('passed') }}

          />
        </div>
        :
        (
          <div className='flex justify-center -mt-4'>
          <StatusBadge
              text={
                profileStatus === 'offered'
                  ? 'Offer Made'
                  :profileStatus  === 'hired'
                    ? 'Hired'
                    : 'Rejected'
              }
              icon={
                profileStatus  === 'offered' || profileStatus  === 'hired' ? (
                  <BlueworkIcon color="#0E9F6E" size={16} />
                ) : (
                  <img src={passedBag} alt="Passed" width={16} height={16} />
                )
              }
              color={profileStatus === 'passed' ? 'red-600' : 'green-600'}
            />
            </div>
        )
      }

      {/* Files list below */}
      <div className="flex flex-col gap-2">
        <span className="text-sm font-medium">Files</span>
        <div className="flex flex-col gap-1 files-item overflow-y-auto max-h-60">
          {attachments.map((message, index) => (
            // console.log(message)
            <FileItemsView
              key={`${message.id}-${index}`}
              fileName={message.attachments?.filename}
              fileSize={`${message.attachments?.file_size} MB`}
              fileType={message.attachments?.filename
                .split('.')
                .pop()
                .toUpperCase()}
            />
          ))}
          {attachments.length === 0 && (
            <span className="text-sm text-gray-500">No files shared yet.</span>
          )}
        </div>
      </div>

      <div className="flex flex-row gap-2 items-center text-red-700">
        <img src={redCrossCircle} alt="Cross icon" className="w-4 h-4" />
        <button className="text-sm font-medium">Block user</button>
      </div>

      <div className="flex flex-row gap-2 items-center text-red-700">
        <img src={redTrashIcon} alt="Trash icon" className="w-4 h-4" />
        <button className="text-sm font-medium" onClick={onDeleteClick}>
          Delete Chat
        </button>
      </div>
    </div>
  )
}
