import React from 'react'
import { CandidateOpenMessageView } from 'components/Messaging/CandidateMessaging/CandidateOpenMessageView/CandidateOpenMessageView'

export const EmployerOpenMessageView = ({
  avatar,
  chatName,
  selectedChat,
  setSelectedConversation,
  selectedConversation,
  currentUser,
  setCandidateStatusUpdate,
  handleSetstatus,
}) => {
  return (
    <CandidateOpenMessageView
      avatar={avatar}
      chatName={chatName}
      selectedChat={selectedChat}
      setSelectedConversation={setSelectedConversation}
      selectedConversation={selectedConversation}
      currentUser={currentUser}
      setCandidateStatusUpdate={setCandidateStatusUpdate}
      handleSetstatus={handleSetstatus}
    />
  )
}
