import React, { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Card, Button, Label, Checkbox } from 'flowbite-react'
import { Formik, Form, Field } from 'formik'
import { LoginForm } from './LoginForm'
import { useAuth } from '../../hooks/useAuth'
import eyeSlashIcon from '../../assets/eye-slash.svg'
import eyeicon from '../../assets/eye.svg'
import {
  getLoginValidationSchema,
} from '../../validations/LoginValidationSchema'

const LoginFields = ({
  heading,
  registerNavigate,
  isAdmin = false,
  isCandidate = false,
  isEmployer = false,
}) => {
  const { initialValues, handleSubmit } = useAuth({
    isAdmin,
    isCandidate,
    isEmployer,
  })
  const [showPassword, setShowPassword] = useState(false)
  const [useWhatsApp, setUseWhatsApp] = useState(false)
  const [isEmail, setIsEmail] = useState(true)
  const [validationSchema, setValidationSchema] = useState(
    getLoginValidationSchema(isEmail),
  )
  const [formKey, setFormKey] = useState(Date.now())
  const togglePasswordVisibility = useCallback(
    () => setShowPassword(prev => !prev),
    [],
  )

  const handleToggleLoginMethod = (setFieldValue, validateField) => {
    setUseWhatsApp(prev => {
      const newUseWhatsApp = !prev
      setFieldValue('login', '', false)
      validateField('login')
      setIsEmail(!newUseWhatsApp)
      setValidationSchema(getLoginValidationSchema(!newUseWhatsApp))
      setFormKey(Date.now())
      return newUseWhatsApp
    })
  }

  const handleFormSubmit = (values, actions) => {
    const { useWhatsApp, ...filteredValues } = values
    handleSubmit(filteredValues, actions, isEmail)
  }

  return (
    <Card className="w-full border sm:border-gray-200 sm:shadow-md sm:dark:border-gray-700 border-white sm:dark:bg-gray-800 sm:mr-10 sm:pt-2 sm:pb-7 sm:px-5">
      <div className="flex flex-col xs:gap-2">
        <p className="text-gray-900 text-2xl font-bold leading-tight">
          {heading}
        </p>
        {!isAdmin && (
          <p className="text-sm font-normal text-gray-600">
            Not signed up yet?
            <Link to={registerNavigate} className="px-1 text-blue-700">
              Register here
            </Link>
          </p>
        )}
      </div>
      <Formik
        key={formKey}
        initialValues={{ ...initialValues, useWhatsApp: false }}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, setFieldValue, validateField }) => (
          <Form className="flex flex-col gap-4">
            <LoginForm
              showPassword={showPassword}
              togglePasswordVisibility={togglePasswordVisibility}
              eyeSlashIcon={eyeSlashIcon}
              eyeicon={eyeicon}
              useWhatsApp={useWhatsApp}
              handleToggleLoginMethod={() =>
                handleToggleLoginMethod(setFieldValue, validateField)
              }
            />
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center gap-2">
                <Field as={Checkbox} id="remember" name="remember" />
                <Label
                  htmlFor="remember"
                  className="text-sm font-medium text-gray-500"
                >
                  Remember me
                </Label>
              </div>
                <div>
                  <Link
                    to={{
                      pathname: '/forgot-password',
                      search: `?user=${heading === 'Quali Employer Login' ? 'employer' : heading === 'Quali Candidate Login'? 'candidate': 'admin'}`,
                    }}
                    htmlFor="forgot-password"
                    className="text-blue-700 text-right text-sm font-medium leading-[1.5]"
                  >
                    Forgot Password?
                  </Link>
                </div>
            </div>
            <Button
              type="submit"
              className="bg-blue-700 xs:mt-10 sm:mt-2 py-2 text-lg"
              disabled={isSubmitting}
            >
              Login
            </Button>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

export default LoginFields
