import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Badge, Checkbox, Label, Select, TextInput } from 'flowbite-react'
import { ErrorMessage, Field, useFormikContext } from 'formik'
import { getSkills } from 'services/skillService'
import { getExpertises } from 'services/expertiseService'
import { getExpertise } from 'services/userService'
import 'assets/css/experience-form.css'
import { selectCurrentUser } from '../../../../../redux/currentUserSlice'

const SkillExpertise = ({
  industries,
  values,
  handleChange,
  onSkillSelection,
}) => {
  const { currentUser } = useSelector(selectCurrentUser)
  const [industry, setIndustry] = useState(currentUser?.industry_id || null)
  const [skills, setSkills] = useState([])
  const [selectedSkills, setSelectedSkills] = useState([])
  const [expertises, setExpertises] = useState({})
  const [selectedExpertises, setSelectedExpertises] = useState({})
  const { errors } = useFormikContext()

  useEffect(() => {
    if (industry) {
      fetchSkills()
      setExpertises({})
      setSelectedExpertises({})
      setSelectedSkills([])
      onSkillSelection(skills, expertises, [])
    }
  }, [industry])

  const fetchSkills = async () => {
    try {
      const response = await getSkills(`industry=${industry}`)
      if (response.status === 200) {
        setSkills(response.data || [])
      } else {
        console.error('Failed to fetch skills:', response.status)
      }
    } catch (error) {
      console.error('Error fetching skills:', error)
    }
  }

  useEffect(() => {
    if (selectedSkills.length > 0) {
      fetchExpertises()
    } else {
      setExpertises({})
    }
  }, [selectedSkills])

  const fetchExpertises = async () => {
    try {
      const promises = selectedSkills.map(async skillId => {
        const response = await getExpertises(`skill=${skillId}`)
        return response.data
      })

      Promise.all(promises).then(results => {
        const expertisesObj = {}
        results.forEach((experts, index) => {
          const skillId = selectedSkills[index]
          expertisesObj[skillId] = experts
        })
        setExpertises(expertisesObj)
      })
    } catch (error) {
      console.error('Error fetching expertises:', error)
    }
  }

  const handleSkillClick = skillId => {
    if (Object.keys(selectedExpertises).length > 0) {
      onSkillSelection(skills, expertises, [])
    }

    if (selectedSkills.includes(skillId)) {
      setSelectedSkills(prevSkills => prevSkills.filter(id => id !== skillId))
      setSelectedExpertises(prevExpertises => {
        const newState = { ...prevExpertises }
        delete newState[skillId]
        return newState
      })
    } else {
      if (selectedSkills.length < 3) {
        setSelectedSkills(prevSkills => [...prevSkills, skillId])
      }
    }
  }

  const handleExpertiseClick = (skillId, expertise) => {
    setSelectedExpertises(prevExpertises => {
      const currentExpertises = prevExpertises[skillId] || []
      if (currentExpertises.includes(expertise)) {
        return {
          ...prevExpertises,
          [skillId]: currentExpertises.filter(item => item !== expertise),
        }
      } else if (currentExpertises.length < 3) {
        return {
          ...prevExpertises,
          [skillId]: [...currentExpertises, expertise],
        }
      }
      return prevExpertises
    })
  }

  const checkExpertiseObjectArray = expertises => {
    for (const key in expertises) {
      if (expertises.hasOwnProperty(key)) {
        if (Array.isArray(expertises[key]) && expertises[key].length !== 0) {
          return false
        }
      }
    }
    return true
  }

  const handleSkillSelection = (skills, expertises) => {
    const isExpertiseEmpty = checkExpertiseObjectArray(selectedExpertises)
    if (isExpertiseEmpty) {
      onSkillSelection(selectedSkills, selectedExpertises, [])
    } else {
      if (skills.length > 0 && expertises) {
        if (Object.keys(expertises).length > 0) {
          const skillExpertiseArray = skills.map(skill => ({
            skills: [skill],
            expertises: { [skill]: expertises[skill] || [] },
          }))
          onSkillSelection(skills, expertises, skillExpertiseArray)
        } else {
          onSkillSelection(skills, expertises, [])
        }
      }
    }
  }

  useEffect(() => {
    handleSkillSelection(selectedSkills, selectedExpertises)
  }, [selectedSkills, selectedExpertises])

  useEffect(() => {
    const fetchSavedData = async () => {
      try {
        const response = await getExpertise(currentUser.id)
        if (response.status === 200) {
          const data = response.data
          const { skill_expertise } = data
          const selectedSkills = Object.keys(skill_expertise)
          const selectedExpertises = {}
          selectedSkills.forEach(skillId => {
            selectedExpertises[skillId] = skill_expertise[skillId]
          })
          setSelectedSkills(selectedSkills)
          setSelectedExpertises(selectedExpertises)
        } else {
          console.error('Failed to fetch saved data:', response.status)
        }
      } catch (error) {
        console.error('Error fetching saved data:', error)
      }
    }

    fetchSavedData()
  }, [currentUser])

  return (
    <>
      <div className="w-full flex flex-col gap-2">
        <div className="flex items-center mt-2 pb-0.5">
          <Label
            htmlFor="industry"
            value="Select your main industry"
            className="text-gray-900 text-base font-medium leading-6"
          />
        </div>
        <div>
          <Select
            id="industry"
            name="industry"
            onChange={e => {
              setIndustry(e.target.value)
              handleChange(e)
            }}
            value={industry || ''}
          >
            <option value="">Select Industry</option>
            {industries.map(industry => (
              <option key={industry.id} value={industry.id}>
                {industry.title}
              </option>
            ))}
          </Select>
          <ErrorMessage
            name="industry"
            component="div"
            className="text-red-500"
          />
        </div>
      </div>

      <div className="w-full flex flex-col gap-2 my-5">
        <div className="flex items-center pb-0.5">
          <Label
            htmlFor="yearsOfExperience"
            value="How many years of experience do you have in the industry?"
            className="text-gray-900 text-base font-medium leading-6"
          />
        </div>

        <Field
          as={TextInput}
          id="yearsOfExperience"
          onChange={handleChange}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          name="yearsOfExperience"
          value={values.yearsOfExperience}
          placeholder="Enter year of experience (#)"
          min="0"
        />

        <ErrorMessage
          name="yearsOfExperience"
          component="div"
          className="text-red-500"
        />
      </div>

      <div className="w-full flex flex-col gap-2 my-5">
        {skills.length ? (
          <>
            <Label
              htmlFor="skillList"
              value="Select Skills"
              className="text-gray-900 font-medium text-base leading-6"
            />
            <label className="block text-base font-medium text-gray-500 mb-1 leading-6">
              You can select up to 3 skills
            </label>
            <div className="badge-container">
              {skills.map(skill => (
                <Badge
                  key={skill.id}
                  className={`flex flex-col justify-center items-center gap-1 cursor-pointer py-2 px-4 border rounded-md 
                              ${
                                selectedSkills.includes(skill.id.toString())
                                  ? 'bg-[#E1EFFE] text-[#3F83F8] border-[#3F83F8]'
                                  : 'bg-[#F3F4F6] text-gray-800 border-[#D1D5DB]'
                              } 
                              text-sm font-medium leading-[21px] badge-container-badge`}
                  onClick={() => handleSkillClick(skill.id.toString())}
                >
                  {skill.title}
                </Badge>
              ))}
            </div>
            <ErrorMessage
              name={
                Array.isArray(errors.skillExpertise)
                  ? 'skillExpertise[0]'
                  : 'skillExpertise'
              }
              component="div"
              className="text-red-500"
            />
          </>
        ) : (
          industry && <h6>🔖 No skills available against this category</h6>
        )}
      </div>

      {Object.keys(expertises).map(skillId => {
        const skill = skills.find(s => s.id === parseInt(skillId))
        const skillExpertises = expertises[skillId] || []
        return (
          <div key={skillId} className="w-full flex flex-col gap-2 my-5">
            <Label className="text-gray-900 font-satoshi-variable text-base font-medium leading-6">
              {skill && `Select Expertise from ${skill.title} (max 3)`}
            </Label>
            <div className="flex flex-wrap gap-6">
              {skillExpertises.map(expertise => (
                <label key={expertise.id} className="flex items-center">
                  <Checkbox
                    id={expertise.id}
                    name={expertise.title}
                    value={expertise.title}
                    checked={
                      selectedExpertises[skillId]
                        ? selectedExpertises[skillId].includes(expertise.title)
                        : false
                    }
                    onChange={() =>
                      handleExpertiseClick(skillId, expertise.title)
                    }
                  />
                  <span className="ml-2">{expertise.title}</span>
                </label>
              ))}
            </div>
            <ErrorMessage
              name={`expertises.${skillId} `}
              component="div"
              className="text-red-500"
            />
          </div>
        )
      })}
    </>
  )
}

export default SkillExpertise
