import React, { useEffect, useState } from 'react'
import AppBar from '../AppBar'
import { TopHeader } from '../../Search/TopHeader'
import { AppSideBar } from '../AppSideBar'
import { Breadcrumb } from '../../../../src/utilis/helpers/breadcrumbHelper'
import { GetStoredtestInformation } from '../../../services/testService'
import { PagyQualiTest } from '../../../components'
import TestOverview from '../../../components/Dashboard/Overview/TestOverview'
import '../../../assets/css/cborder.css'
import { Loader } from '../../../utilis/AppLoader'

const AllQualiTest = () => {
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const breadcrumbItems = ['Test and Assessment']
  breadcrumbItems.push('All Tests')

  const gettestData = async () => {
    setIsLoading(true)
    try {
      const response = await GetStoredtestInformation()
      setData(response?.data)
    } catch (error) {
      console.error('Error fetching test data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    gettestData()
  }, [])

  return (
    <div className="h-full bg-[#f9fafb] font-satoshi-regular">
      <AppBar />
      <div className="flex flex-col md:flex-row p-2 md:p-10">
        <div className="hidden md:block">
          <AppSideBar />
        </div>
        <div className="flex flex-col w-full md:w-3/4 sm:pl-4">
          <TopHeader />
          <>
            <div className="border-1.5 border-gray-400 rounded-lg bg-white mt-5 mr-4  shadow-sm">
              <div className='mt-5 ml-6'>
                <Breadcrumb
                  items={breadcrumbItems} />
              </div>
              {isLoading ? (
                <div className="flex justify-center items-center h-40">
                  <Loader size="large" color="blue" />
                </div>
              ) : (
                <TestOverview data={data} />
              )}
            </div>
            <div className='mt-4'>
              {isLoading ? (
                <div className="flex justify-center items-center h-40">
                  <Loader size="large" color="blue" />
                </div>
              ) : (
                <PagyQualiTest />
              )}
            </div>
          </>
        </div>
      </div>
    </div>
  )
}
export default AllQualiTest
