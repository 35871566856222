import React from 'react'
import AppBar from '../../components/Dashboard/AppBar'
import { CandidateAppSideBar } from '../../components/Dashboard/CandidateSideBar'
import { CandidateProfileOverview } from 'components/Dashboard/candidate-profile-overview/CandidateProfileOverview'

export const CandidateAccountOverview = () => {
  return (
    <div className="h-full bg-[#f9fafb] font-satoshi-regular">
      <AppBar />
      <div className="flex flex-col md:flex-row p-4 md:p-10 min-h-screen">
        <div className="hidden md:block">
          <CandidateAppSideBar />
        </div>
        <div className="flex flex-col w-full md:w-3/5 sm:pl-4">
          <CandidateProfileOverview />
        </div>
      </div>
    </div>
  )
}
