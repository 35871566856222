import React, { useState } from 'react'
import { Button } from 'flowbite-react'
import searchIcon from '../../../assets/svgs/search.svg'
import '../../../assets/css/search-results.css'
import debounce from 'lodash/debounce'

const SearchInput = ({ setQuery, query }) => {
  const [search, setSearch] = useState(null)
  const handleInputChange = event => {
    const { value } = event.target
    setSearch(value)
    debouncedHandleInputChange(value)
  }

  const debouncedHandleInputChange = debounce(value => {
    setQuery(value)
  }, 1000)

  const onClick = () => {
    setQuery(search)
  }
  return (
    <div className="relative mt-2 z-10">
      <label htmlFor="input-group-search" className="sr-only">
        Search
      </label>
      <div className="relative flex items-center">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
        <input
          type="text"
          id="input-group-search"
          className="lg:mr-2 w-full lg:h-12 px-5 text-sm font-medium block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Search by search term, candidate name, and others"
          onChange={handleInputChange}
          value={search}
        />
        <div className="lg:ml-2 ml-1 lg:mr-4 mr-1">
          <Button
            onClick={onClick}
            variant="icon"
            className="flex-shrink-0 search-button"
          >
            <img src={searchIcon} alt="Search" className="lg:w-5 h-4 w-10" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SearchInput
