import React from 'react'
import { useLocation ,Link} from 'react-router-dom'
import { Avatar } from 'flowbite-react'
import timeImage from 'assets/svgs/time.svg'
import {
  sendPendingTestReminderEmail,
} from '../../../services/testService'
import useToastMessage from '../../../hooks/useToastMessage'
import BellActiveOutlineIcon from 'components/generics/Icons/BellActiveOutlineIcon'
import { InitialLetters } from 'components/generics/Initials'

const FeedItem = ({
  currentUser,
  candidate,
  notification_message,
  role,
  date,
  showButtons,
  userRole,
  test_id,
  test_title,
  candidate_id }) => {
  const location=useLocation()
  const toastMessage = useToastMessage()
  const sendReminderEmail = async () => {

    const data = {
      email: candidate?.email,
      assessment_link: candidate?.test_link,
      candidate_name: `${candidate?.first_name} ${candidate?.last_name}`,
      company_name: currentUser?.company?.name,
      test_name: test_title,
    }
   const response= await sendPendingTestReminderEmail(data)
   if (response.status === 200) { 
    toastMessage('Reminder email sent successfully!','success')
  } else {
    toastMessage('Failed to send reminder email.', 'error')
  }
  }
  return (
    <div className="flex-1 bg-white rounded-lg shadow p-4 relative">
      <div className="flex items-center mb-2">
      {candidate?.logo_url ? (
                      <div className="flex justify-center items-center h-[50px] w-[50px] below-laptop-xl:h-10 below-laptop-xl:w-12">
                        <div className="h-full w-full overflow-hidden rounded-full">
                          <img
                            src={candidate?.logo_url}
                            alt={'ProfilePicture'}
                            className="object-cover rounded-full w-full h-full"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className=" flex justify-center items-center p-2 rounded-full text-xl bg-gray-100 border-gray-100 h-12 w-12">
                        <InitialLetters
                          Name={
                            candidate.first_name +
                            ' ' +
                            candidate.last_name
                          }
                        />
                      </div>)}
        <div className="ml-4 text-wrap break-all">
          <p className="flex flex-col text-wrap break-all">
            <span className='text-md text-gray-500 text-wrap break-all mr-2' >{notification_message} </span>
          </p>
          <div className="flex items-center">
            <img
              src={timeImage}
              alt="time"
              className="w-3 h-4 mr-2 rounded-full"
            />
            <p className="text-sm text-gray-600">{date}</p>
          </div>
        </div>
      </div>
      {notification_message.includes('have not completed it') && (
        <div className="flex flex-col xl:absolute xl:top-0 xl:right-0 mt-10 mr-2 lg:items-start items-center md:items-end md:flex-row mr-6">
        <button
        className="px-2 gap-1 flex items-center justify-center text-primary-700 lg:text-sm text-s rounded border border-primary-700 focus:outline-none focus:border-primary-700 h-6"
         onClick={()=>sendReminderEmail()}
        >
          <BellActiveOutlineIcon />
          <span className="text-12 font-medium">Remind</span>
        </button>
        </div>
      )}
      {showButtons && (
        <div className="flex flex-col xl:absolute xl:top-0 xl:right-0 mt-6 mr-2 lg:items-start items-center md:items-end md:flex-row mr-6">
          {userRole === 'company_admin' && (
                <Link
            to={`/company-inbox?candidateId=${candidate_id}`}
          >
            <button className="text-14 bg-white text-gray-900 font-bold py-2 px-4 rounded-lg border border-gray-200 mb-2 lg:mb-0 mr-0 md:mr-2">
              Contact Candidate
            </button>
            </Link>
          )}
          <Link
            to={'/candidate-list-of-test'}
            state={{
              Testname: test_title,
              _pathname: location.pathname,
              TestId: test_id,
            }}
          >
            <button className="text-14 bg-[#1A56DB] text-white font-bold py-2 lg:px-6 px-9 rounded-lg mb-2 lg:mb-0">
              View Results
            </button>
          </Link>
        </div>
      )}
    </div>
  )
}
export default FeedItem
