import React, { useEffect, useState, useCallback } from 'react'
import { debounce } from 'lodash'
import AppBar from '../../components/Dashboard/AppBar'
import { AppSideBar } from '../../components/Dashboard/AppSideBar'
import { TopHeader } from '../../components/Search/TopHeader'
import SearchInput from '../../components/generics/search/SearchInput'
import { getRevealedCandidatesForCompany } from '../../services/userService'
import CandidateDetailsCard from '../../components/DetailsCard/CandidateDetailsCard'
import { CongratulationsModal } from '../../components/Search/modals/CongratulationsModal'
import { NotAMatchForm } from '../../components/Search/modals/NotaMatchModal'
import { setProfileStatus } from '../../services/userService'
import { useDispatch, useSelector } from 'react-redux'
import { selectCompany } from '../../redux/currentUserSlice'
import RevealCandidateProfile from '../../components/Search/modals/RevealCandidateProfile'
import { formatCandidateData } from '../../utilis/helpers'
import { saveCandidateForSearch } from '../../services/userService'
import { candidateSavedStatus } from '../../config/constants'
import { sendHiredEmailToCandidate } from '../../services/candidateService'
import { setRevealedCandidates } from '../../redux/talentSearchSlice'

const RevealedProfiles = () => {
  const [candidates, setCandidates] = useState([])
  const [hired, setHired] = useState(false)
  const [passed, setPassed] = useState(false)
  const [selectedReason, setSelectedReason] = useState('')
  const [selectedCandidate, setSelectedCandidate] = useState([])
  const [openRevealModal, setOpenRevealModal] = useState(false)
  const companyData = useSelector(selectCompany)
  const [isShowingSaved, setIsShowingSaved] = useState(false)
  const [disableSave, setDisableSave] = useState(false)
  const [query, setQuery] = useState('')
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()

  const debouncedGetCandidates = useCallback(
    debounce((searchQuery) => {
      getCandidates(searchQuery);
    }, 500),
    []
  )

  useEffect(() => {
    debouncedGetCandidates(query)
    return () => {
      debouncedGetCandidates.cancel()
    }
  }, [query, debouncedGetCandidates])

  const getCandidates = async (searchQuery) => {
    !searchQuery && setLoader(true)
    try {
      const response = await getRevealedCandidatesForCompany(searchQuery)
      const { data, status } = response
      if (status === 200) {
        const formattedDataArray = formatCandidateData(data)
        setCandidates(formattedDataArray || [])
        dispatch(setRevealedCandidates(formattedDataArray || []))
        setLoader(false)
      }
    } catch (error) {
      console.error('Error fetching candidates')
      setLoader(false)
    }
  }

  const handleSetHired = async candidate => {
    setHired(true)
    try {
      const data = {
        profile_status: 1,
        user_id: candidate?.user?.id,
        company_id: companyData?.id,
        user_name: `${candidate?.user?.first_name} ${candidate?.user?.last_name}`,
        company_name: companyData?.name,
        search_history_id: candidate?.search_history?.id,
      }
      const response = await setProfileStatus(data)
      if (response?.status === 200) {
        const hiredCandidate = {
          email: candidate?.user?.email,
          candidate_name: `${candidate?.user?.first_name} ${candidate?.user?.last_name} `,
          company_name: companyData?.name,
        }
        getCandidates()
        await sendHiredEmailToCandidate(hiredCandidate)
      }
    } catch (error) {
      console.error('Error hiring candidate', error)
    }
  }

  const handleSetPassed = async candidate => {
    setPassed(true)
    setSelectedCandidate(candidate)
  }

  const onHandleSubmit = async () => {
    try {
      const data = {
        profile_status: 0,
        user_id: selectedCandidate?.user?.id,
        company_id: companyData?.id,
        profile_feedback: selectedReason,
        user_name: `${selectedCandidate?.user?.first_name} ${selectedCandidate?.user?.last_name}`,
        company_name: companyData?.name,
        search_history_id: selectedCandidate?.search_history?.id,
      }
      await setProfileStatus(data)
      getCandidates()
    } catch (error) {
      console.error('Error passing candidate', error)
    }
  }

  const handleOpenModal = candidate => {
    setSelectedCandidate(candidate)
    setOpenRevealModal(true)
  }

  const onSaveCandidate = async candidate => {
    setIsShowingSaved(false)
    setDisableSave(true)
    try {
      const data = {
        company_id: companyData?.id,
        user_id: candidate?.user?.id,
        search_history_id: candidate?.search_history?.id,
        category: 0,
      }
      const response = await saveCandidateForSearch(data)
      if (response.status === 200 || response.status === 201) {
        getCandidates()
      }
    } catch (error) {
      console.error('Error saving candidate', error)
    } finally {
      setDisableSave(false)
    }
  }

  const isCandidateSaved = candidate => {
    const { reveal_or_like_status } = candidate
    const savedCandidates = candidateSavedStatus.includes(
      reveal_or_like_status?.toLowerCase(),
    )
    return savedCandidates
  }

  return (
    <div className="h-full bg-[#f9fafb] font-satoshi-regular">
      <AppBar />
      <div className="flex flex-col md:flex-row p-4">
        <div className="hidden md:block">
          <AppSideBar />
        </div>{' '}
        <div className="flex flex-col w-full md:w-3/5 pl-4  ">
          <TopHeader />

          <div className="border-b-2 border-gray rounded-lg bg-white mt-5 h-95 shadow md:mr-3">
            <div className=" flex flex-col md:flex-row justify-between items-center border-b border-grey-20 mx-4">
              <div className="w-full flex flex-col mt-5 px-2">
                <h1 className="mb-2 text-3xl font-bold text-gray-900">
                  Revealed Profiles
                </h1>
                <p className="mb-2 text-1xl text-gray-700">
                  The profiles you revealed previously will be displayed here
                </p>
                <SearchInput setQuery={setQuery} query={query} />
                <CandidateDetailsCard
                  data={candidates}
                  fromTab={true}
                  setHired={setHired}
                  setPassed={setPassed}
                  handleSetHired={handleSetHired}
                  handleSetPassed={handleSetPassed}
                  handleOpenModal={handleOpenModal}
                  onSaveCandidate={onSaveCandidate}
                  isCandidateSaved={isCandidateSaved}
                  loader={loader}
                  fromRevealProfile={true}
                />
                <CongratulationsModal
                  openModal={hired}
                  setOpenModal={setHired}
                />
                <NotAMatchForm
                  openModal={passed}
                  setOpenModal={setPassed}
                  setSelectedReason={setSelectedReason}
                  selectedReason={selectedReason}
                  handleSetPassed={onHandleSubmit}
                />
                {openRevealModal && (
                  <RevealCandidateProfile
                    openModal={openRevealModal}
                    setOpenModal={setOpenRevealModal}
                    candidate={selectedCandidate}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col border border-white h-12 w-full md:w-1/6 opacity-inherit bg-white shadow-md rounded-lg mx-4 hidden lg:block">
          <p className="xl:text-base lg:text-sm text-xs font-medium text-gray-600 p-3">
            Filters unavailable
          </p>
        </div>
      </div>
    </div>
  )
}

export default RevealedProfiles
