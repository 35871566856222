import React, { useState, useEffect } from 'react'
import { Card } from 'flowbite-react'
import { searchCandidates } from '../../../services/candidateService'
import { useNavigate } from 'react-router-dom'
import '../SearchCandidates.css'
import prupletag from '../../../assets/svgs/purple-messages-outline.svg'
import {
  avatar,
  eye,
  heart,
  tags,
  filter,
} from '../../../assets/SearchHistoryIcons'
import RenameArchive from './RenameArchiveButton'
import { useDispatch } from 'react-redux'
import { setSearch } from '../../../redux/searchSlice'
import {
  setTalentSearchData,
  setTalentSearchQueryParam,
} from '../../../redux/talentSearchSlice'
import Loader from '../../../utilis/AppLoader'

const jobPreferences = {
  years_of_experience: '',
  salary_range: '',
  certification_required: false,
  preferred_language: '',
  accommodation: false,
  visa_sponsorship: false,
  transportation: false,
  benefits: false,
}

const SearchHistoryCard = ({ searchHistories, isArchive }) => {
  const dispatch = useDispatch()
  const [expandedCards, setExpandedCards] = useState({})
  const [companySearchHistory, setCompanySearchHistory] =
    useState(searchHistories)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [loadingCards, setLoadingCards] = useState({});

  useEffect(() => {
    setCompanySearchHistory(searchHistories)
  }, [searchHistories])

  const toggleShowMore = index => {
    setExpandedCards(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }))
  }

  const handleSubmit = async (value, navigateTo, index) => {
    setLoadingCards(prevState => ({ ...prevState, [index]: true }))
    setLoading(true); 
    const { attributes: data } = value
    const queryParams = new URLSearchParams({
      skill: data?.skill?.title,
      search_name: data.name,
      is_view_search: true,
      search_id: data.id,
    })

    if (navigateTo === '/searched-candidates') {
      queryParams.append('profile_status', 'none')
    }

    data?.expertise?.forEach(expertise => {
      queryParams.append('expertises[]', expertise)
    })

    Object.keys(jobPreferences).forEach(key => {
      queryParams.append(`job_preferences[${key}]`, jobPreferences[key])
    })
    
    queryParams.append(`page`, 1)
    queryParams.append(`per_page`, 10)
    try {
      const response = await searchCandidates(queryParams.toString())
      if (response.status === 200) {
        setLoading(false);
        setLoadingCards(prevState => ({ ...prevState, [index]: false }))
        dispatch(setTalentSearchQueryParam(queryParams.toString()))
        dispatch(setTalentSearchData(response?.data?.results || []))
        dispatch(setSearch(response.data.results.search_history.attributes))
        if (navigateTo === '/searched-candidates') {  
          queryParams.append('profile_status', 'none')
          navigate(navigateTo, { state: { queryParams: queryParams.toString(), searchName: data.name , totalCount:response.data.pagy.total_count , pageCount: response.data.pagy.total_pages} })
        } else {
          navigate(navigateTo)
        }
      }
    } catch (error) {
      console.error('Error searching candidates:', error)
    }
  }

  const renderFilterDiv = (key, value, text) => {
    if (
      !value.attributes.applied_filter?.filters ||
      !value.attributes.applied_filter?.filters[key]
    ) {
      return null
    }
    return (
      <div className="bg-[#E5EDFF] text-[#42389D] rounded-full px-2 py-1 text-sm mr-1 mb-2 flex items-center">
        <img src={filter} alt="filter" className="mr-1" /> {text}
      </div>
    )
  }

  return (
    <>
      {companySearchHistory && companySearchHistory.length > 0 &&
        companySearchHistory.map((value, index) => {
          const isExpanded = expandedCards[index]
          return (
            <Card className="max-w-full mx-3 my-5" key={index}>
              {loadingCards[index] ? (
                <div className="flex items-center justify-center bg-white bg-opacity-50">
                  <Loader/>
                </div>
              ):
              (
              <div>
              <div className="flex justify-between items-start xl:items-center flex-wrap flex-col xl:flex-row">
                <h5 className="text-xl font-bold tracking-tight text-gray-900 break-all text-wrap">
                  {value.attributes.name}
                </h5>
                <RenameArchive
                  isArchive={isArchive}
                  value={value}
                  setCompanySearchHistory={setCompanySearchHistory}
                />
              </div>
              <div className="flex justify-between items-center flex-wrap mt-2">
                <div className="flex items-center mb-2">
                  <img src={avatar} alt="avatar" className="mr-2" />
                  <span className="text-[#1F2A37] text-sm font-medium leading-6">
                    <span className="text-[#1F2A37] text-sm font-bold leading-6">
                    {value.attributes.search_results?.[0]}
                    </span>{' '}
                    results
                  </span>
                </div>
                <div className="flex items-center lg:mr-auto lg:ml-5">
                  <div className="flex items-center mb-2  text-primary-700 text-sm font-medium leading-6">
                    <img src={eye} alt="eye" className="mr-1" />{' '}
                    {value.attributes.revealed_candidates_count} Revealed
                  </div>
                  <div className=" ml-3 flex items-center mb-2  text-primary-700 text-sm font-medium leading-6">
                    <img src={heart} alt="heart" className="mr-1" />{' '}
                    {value.attributes.liked_candidates_count} Saved
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap w-full">
                <div className="w-full sm:w-1/2">
                <div className="flex flex-wrap mb-2">
                    {value?.attributes?.skill?.title && (
                      <div
                        className="bg-[#EDEBFE] text-[#5521B5] rounded-md px-2 py-1 text-xs mr-2 mb-2"
                      >
                        <img src={prupletag} alt="tags" className="inline mr-1" />{' '}
                        {value?.attributes?.skill.title}
                      </div>
                    )}
                    {value?.attributes?.expertise?.map(
                      (expertiseValue, expIndex) => (
                        <div
                          className="bg-[#F3F4F6] rounded-md px-2 py-1 text-xs text-[#111928] mr-2 mb-2"
                          key={expIndex}
                        >
                          <img src={tags} alt="tags" className="inline mr-1" />{' '}
                          {expertiseValue}
                        </div>
                      ),
                    )}
                  </div>
                </div>
                <div className="flex flex-row flex-wrap w-full sm:w-1/2 mt-2 sm:mt-0 justify-center sm:justify-end items-center gap-2">
                  <div className="flex justify-center sm:justify-end max-h-[42px]">
                    <button
                      onClick={() => handleSubmit(value, '/search-results', index)}
                      className="bg-[#1A56DB] border text-white px-5 py-2 rounded-lg"
                    >
                      View Search
                    </button>
                  </div>
                  <div className="flex justify-center sm:justify-end max-h-[42px]">
                    <button
                      onClick={() => handleSubmit(value,'/searched-candidates', index)}
                      className={`bg-white border-[#1A56DB] border text-[#1A56DB] px-5 py-2 rounded-lg ${
                        value?.attributes?.revealed_candidates_count === 0 ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      disabled={value?.attributes?.revealed_candidates_count === 0}
                    >
                      See Pipeline
                    </button>
                  </div>
                </div>
              </div>
              {isExpanded && (
                <div className="flex flex-wrap mb-1">
                  {renderFilterDiv(
                    'experienceRange',
                    value,
                    `${value.attributes.applied_filter?.filters['experienceRange']?.replace(',', '-')} YoE`,
                  )}
                  {renderFilterDiv(
                    'salaryRange',
                    value,
                    `${value.attributes.applied_filter?.filters['salaryRange']}`,
                  )}
                  {renderFilterDiv(
                    'languagePreference',
                    value,
                    `${value.attributes.applied_filter?.filters['languagePreference']?.replace(',', ', ')}`,
                  )}
                  {renderFilterDiv(
                    'certificationRequired',
                    value,
                    'Certification',
                  )}
                  {renderFilterDiv(
                    'accommodationOffered',
                    value,
                    'Accommodation',
                  )}
                  {renderFilterDiv('visaRequired', value, 'Visa Required')}
                  {renderFilterDiv(
                    'transportationOffered',
                    value,
                    'Transportation',
                  )}
                  {renderFilterDiv(
                    'annualFlightOffered',
                    value,
                    'Annual Flight',
                  )}
                </div>
              )}
              {value.attributes.applied_filter?.filters &&
                Object.keys(value.attributes.applied_filter?.filters).filter(
                  key => key !== 'candidates',
                ).length > 0 && (
                  <button
                    onClick={() => toggleShowMore(index)}
                    className="text-blue-600 mt-2"
                  >
                    {isExpanded ? 'Show Less' : 'Show More'}
                  </button>
                )}
              </div>
              )}
            </Card>
          )
        })
       }
    </>
  )
}

export default SearchHistoryCard
