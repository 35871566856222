import {
  Button,
  Card,
  Checkbox,
  Label,
  List,
  Modal,
  Select,
  TextInput,
} from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { registerCompany, registerUser } from '../../lib/api'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { auth } from '../../firebaseConfig'
import OTPConfirmation from './OTPConfirmation'
import { useDispatch } from 'react-redux'
import InfoIcon from '../../assets/info.svg'
import { BUSINESS_SIZES } from '../../config/constants'
import { getIndustries } from '../../services/industryService'
import * as yup from 'yup'
import { registerSchema } from '../../validations/registerValidationSchema'
import { COUNTRY_CITY } from '../../data/registerFormData'
import { PASSWORD_REGEX } from '../../validations/constants'
import { setCompany, setCurrentUser } from '../../redux/currentUserSlice'
import { getUserValidity } from '../../services/userService'
import eyeSlashIcon from '../../assets/eye-slash.svg'
import useToastMessage from '../../hooks/useToastMessage'
import eyeicon from '../../assets/eye.svg'
import Congratulations from './Congratulations'
import { PhoneInput } from 'react-international-phone'
import 'assets/css/CompanyProfile.css'
import 'react-international-phone/style.css'

const RegisterFields = ({ isSuperAdmin, title, onClose, getCompanies }) => {
  const [registerData, setRegisterData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    contactNumber: '',
    country: '',
    city: '',
    mainIndustry: '',
    companySize: '',
    password: '',
    repeatPassword: '',
    countryCode: '',
  })
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [errors, setErrors] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [openRedirectionModal, setRedirectionOpenModal] = useState(false)
  const [confirmationResult, setConfirmationResult] = useState(false)
  const [industries, setIndustries] = useState([])
  const [cities, setCities] = useState([])
  const dispatch = useDispatch()
  const toastMessage = useToastMessage()

  useEffect(() => {
    fetchIndustries()
  }, [])

  const sendOtp = async () => {
    try {
      await registerSchema.validate(registerData, {
        abortEarly: false,
      })
      if (!acceptTerms) {
        setErrors({ acceptTerms: 'Terms acceptance is required' })
        return
      }

      const {
        firstName,
        lastName,
        email,
        password,
        contactNumber,
        countryCode,
      } = registerData
      const response = await getUserValidity({
        first_name: firstName,
        last_name: lastName,
        phone_no: contactNumber,
        email,
        password,
        role: 'company_admin',
        country_code: countryCode,
      })

      if (response.data.valid_user) {
        setOpenModal(true)

        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          'recaptcha-container',
          {
            size: 'invisible',
            callback: () => {
              console.log('recaptcha resolved..')
            },
          },
        )

        const confirmationResult = await signInWithPhoneNumber(
          auth,
          registerData.contactNumber,
          window.recaptchaVerifier,
        )
        setConfirmationResult(confirmationResult)
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors.join(', ')
        alert(errorMessage)
      }
      if (error.inner) {
        const validationErrors = {}
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message
        })
        setErrors(validationErrors)
      } else {
        console.error('Error sending OTP:', error)
      }
    }
  }

  const resendOtp = async () => {
    const appVerifier = window.recaptchaVerifier

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        registerData.contactNumber,
        appVerifier,
      )
      setConfirmationResult(confirmationResult)
      alert('OTP resent successfully!')
    } catch (error) {
      if (error.inner) {
        const validationErrors = {}
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message
        })
        setErrors(validationErrors)
      } else {
        console.error('Error resending OTP:', error)
      }
    }
  }

  const handleChange = event => {
    const { name, value } = event.target
    setRegisterData(prevState => ({ ...prevState, [name]: value }))
    if (name === 'country') {
      setCities(COUNTRY_CITY[value] || [])
      setRegisterData(prevState => ({ ...prevState, city: '' }))
    }
  }

  const handlePhoneInputChange = (phone, meta) => {
    setRegisterData(prevState => ({
      ...prevState,
      contactNumber: phone,
      countryCode: `+${meta?.country?.dialCode}`,
    }))
  }

  const handleAcceptTermsChange = () => {
    setAcceptTerms(!acceptTerms)
    if (errors.acceptTerms) {
      setErrors(prevErrors => ({
        ...prevErrors,
        acceptTerms: '',
      }))
    }
  }

  const handleBlur = async event => {
    const { name, value } = event.target

    try {
      await yup.reach(registerSchema, name).validate(value)
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }))
    } catch (error) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: error.errors[0],
      }))
    }
  }

  const isPasswordMatch = () => {
    return registerData.password === registerData.repeatPassword
  }

  const isCorrectPassword = () => {
    return PASSWORD_REGEX.test(registerData.repeatPassword)
  }

  const handleSubmit = async () => {
    try {
      await registerSchema.validate(registerData, {
        abortEarly: false,
      })
      if (!acceptTerms) {
        setErrors({ acceptTerms: 'Terms acceptance is required' })
        return
      }

      const {
        firstName,
        lastName,
        email,
        password,
        contactNumber,
        countryCode,
      } = registerData
      const user = await registerUser({
        first_name: firstName,
        last_name: lastName,
        phone_no: contactNumber,
        register_only: isSuperAdmin,
        email,
        password,
        citizenship: 130,
        role: 'company_admin',
        country_code: countryCode,
      })
      !isSuperAdmin && dispatch(setCurrentUser(user))

      const { companyName, country, city, companySize, mainIndustry } =
        registerData
      const company = await registerCompany({
        name: companyName,
        contact_email: email,
        contact_number: contactNumber,
        country,
        city,
        company_size: companySize,
        industry_id: mainIndustry,
        user_id: user.id,
        citizenship: 130,
      })

      !isSuperAdmin && dispatch(setCompany(company))
      isSuperAdmin && onClose()
      isSuperAdmin && getCompanies()
      isSuperAdmin && toastMessage('Company created successfully!', 'success')
    } catch (error) {
      if (error.inner) {
        const validationErrors = {}
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message
        })
        setErrors(validationErrors)
      } else {
        console.error('Error submitting form:', error)
        if (
          error.response &&
          error.response.data &&
          error.response.data.status
        ) {
          const errorMessage = error.response.data.status.message
          alert(errorMessage)
        }
      }
    }
  }

  const fetchIndustries = async () => {
    try {
      const response = await getIndustries()
      if (response.status === 200) {
        setIndustries(response.data || [])
      }
    } catch (error) {
      console.error('Error fetching industries:', error)
    }
  }

  const [showPassword, setShowPassword] = useState(false)
  const [showrepeatedPassword, setshowrepeatedPassword] = useState(false)
  const togglePasswordVisibility = (setState, prevState) => {
    setState(prevState => !prevState)
  }

  return (
    <div className="p-2 rounded-lg mx-auto">
      <div id="recaptcha-container"></div>

      <Card>
        {!isSuperAdmin ? (
          <div className="flex flex-col gap-3">
            <p className="text-3xl font-medium">Register as Quali Employer</p>
            <p className="text-[#6B7280]">
              Already have an account?
              <Link to="/login" className="px-1 text-blue-700">
                Login here{' '}
              </Link>
            </p>
          </div>
        ) : (
          <div className="flex items-start justify-between border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Create {title}
            </h3>
            <button
              onClick={() => onClose()}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
        )}
        <div className="flex flex-col gap-2">
          <div className="flex flex-col md:flex-row justify-between gap-4">
            <div className="w-full">
              <div className="pb-0.5">
                <label className="text-[#111928] text-[14px] font-[800] ">
                  First Name
                </label>
              </div>
              <TextInput
                id="firstName"
                name="firstName"
                value={registerData.firstName}
                type="text"
                placeholder="Enter your first name"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.firstName && (
                <p className="text-xs text-red-500">{errors.firstName}</p>
              )}
            </div>
            <div className="w-full">
              <div className="pb-0.5">
                <label className="text-[#111928] text-[14px] font-[800] ">
                  Last Name
                </label>
              </div>

              <TextInput
                id="lastName"
                name="lastName"
                value={registerData.lastName}
                type="text"
                placeholder="Enter your last name"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.lastName && (
                <p className="text-xs text-red-500">{errors.lastName}</p>
              )}
            </div>
          </div>
          <div>
            <div className="w-full">
              <div className="pb-0.5">
                <Label htmlFor="email" value="Work E-mail" />
              </div>
              <TextInput
                id="email"
                type="email"
                name="email"
                value={registerData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Your work e-mail address"
              />
              {errors.email && (
                <p className="text-xs text-red-500">{errors.email}</p>
              )}
            </div>
          </div>
          <div>
            <div className="pb-0.5">
              <Label htmlFor="contactNumber" value="Contact Number" />
            </div>
            <PhoneInput
              defaultCountry="sa"
              value={registerData?.contactNumber}
              onChange={(phone, meta) => handlePhoneInputChange(phone, meta)}
              placeholder="123 4567 890"
            />
            <p className="text-sm text-gray-500">
              We will send confirmation code.
            </p>
            {errors.contactNumber && (
              <p className="text-xs text-red-500">{errors.contactNumber}</p>
            )}
          </div>
          <div>
            <div className="pb-0.5">
              <Label htmlFor="companyName" value="Company Name" />
            </div>
            <TextInput
              id="companyName"
              type="text"
              onChange={handleChange}
              name="companyName"
              value={registerData.companyName}
              onBlur={handleBlur}
              placeholder="Your company name"
            />
            {errors.companyName && (
              <p className="text-xs text-red-500">{errors.companyName}</p>
            )}
          </div>
          <div className="flex flex-col md:flex-row justify-between gap-4">
            <div className="w-full">
              <div className="flex items-center pb-0.5">
                <Label htmlFor="country" value="Country" />
                <img src={InfoIcon} className="h-3 ml-1" alt="Info Icon" />
              </div>
              <Select
                id="country"
                name="country"
                value={registerData.country}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option value="">Select Country</option>
                {Object.keys(COUNTRY_CITY).map(countryName => (
                  <option key={countryName} value={countryName}>
                    {countryName}
                  </option>
                ))}
              </Select>
              {errors.country && (
                <p className="text-xs text-red-500">{errors.country}</p>
              )}
            </div>
            <div className="w-full">
              <div className="flex pb-0.5">
                <Label htmlFor="city" value="City" />
              </div>
              <Select
                id="city"
                name="city"
                onChange={handleChange}
                onBlur={handleBlur}
                value={registerData.city}
              >
                <option value="">Select City</option>
                {cities.map(city => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </Select>
              {errors.city && (
                <p className="text-xs text-red-500">{errors.city}</p>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between gap-4">
            <div className="w-full">
              <div className="flex items-center pb-0.5">
                <Label
                  htmlFor="mainIndustry"
                  value="What is your company main industry?"
                />
                <img src={InfoIcon} className="h-3 ml-1" alt="Info Icon" />
              </div>
              <Select
                id="mainIndustry"
                onChange={handleChange}
                onBlur={handleBlur}
                name="mainIndustry"
                value={registerData.mainIndustry}
              >
                <option value="">Select Industry</option>
                {industries.map(industry => (
                  <option key={industry.id} value={industry.id}>
                    {industry.title}
                  </option>
                ))}
              </Select>
              {errors.mainIndustry && (
                <p className="text-xs text-red-500">{errors.mainIndustry}</p>
              )}
            </div>
            <div className="w-full">
              <div className="flex items-center pb-0.5">
                <Label
                  htmlFor="companySize"
                  value="How many people are in your company?"
                />
              </div>
              <Select
                id="companySize"
                name="companySize"
                onChange={handleChange}
                onBlur={handleBlur}
                value={registerData.companySize}
              >
                <option value="">Select Quantity</option>
                {BUSINESS_SIZES.map(size => (
                  <option key={size.value} value={size.value}>
                    {size.label}
                  </option>
                ))}
              </Select>
              {errors.companySize && (
                <p className="text-xs text-red-500">{errors.companySize}</p>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between gap-4">
            <div className="w-full">
              <div className="pb-0.5">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Enter Password
                </label>
              </div>
              <div className="relative ">
                <TextInput
                  name="password"
                  value={registerData.password}
                  className="mb-1"
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="********"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-auto"
                  onClick={() => togglePasswordVisibility(setShowPassword)}
                >
                  <img
                    src={showPassword ? eyeicon : eyeSlashIcon}
                    alt={showPassword ? 'Hide password' : 'Show password'}
                    className="h-4 w-4 text-gray-400"
                  />
                </button>
              </div>
              {errors.password && (
                <p className="text-xs text-red-500">{errors.password}</p>
              )}
              <div className="text-sm py-1">
                <p className="font-semibold text-slate-500">
                  Password guideline:
                </p>
                <List>
                  <List.Item>At least 8 characters</List.Item>
                  <List.Item>Include special character</List.Item>
                  <List.Item>Include capitalized characters</List.Item>
                </List>
              </div>
            </div>
            <div className="w-full">
              <div className="pb-0.5">
                <label
                  htmlFor="repeatPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  Repeat Password
                </label>
              </div>
              <div className="relative">
                <input
                  type={showrepeatedPassword ? 'text' : 'password'}
                  name="repeatPassword"
                  value={registerData.repeatPassword}
                  id="repeatPassword"
                  placeholder="********"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mb-1`}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-auto"
                  onClick={() =>
                    togglePasswordVisibility(setshowrepeatedPassword)
                  }
                >
                  <img
                    src={showrepeatedPassword ? eyeicon : eyeSlashIcon}
                    alt={
                      showrepeatedPassword ? 'Hide password' : 'Show password'
                    }
                    className="h-4 w-4 text-gray-400"
                  />
                </button>
              </div>
              {registerData.repeatPassword.length ? (
                <p
                  className={`text-xs ${isPasswordMatch() && isCorrectPassword() ? 'text-green-500' : 'text-red-500'}`}
                >
                  {!isCorrectPassword() ? (
                    <>Password must match the guidelines</>
                  ) : isPasswordMatch() ? (
                    <>Password matched!</>
                  ) : (
                    <>Password didn't match.</>
                  )}
                </p>
              ) : (
                errors.repeatPassword && (
                  <p className="text-xs text-red-500">
                    {errors.repeatPassword}
                  </p>
                )
              )}
            </div>
          </div>
          <div>
            <div className="flex gap-2 py-2">
              <Checkbox
                id="acceptTerms"
                name="acceptTerms"
                checked={acceptTerms}
                onChange={handleAcceptTermsChange}
              />
              <Label
                htmlFor="acceptTerms"
                className="text-sm font-normal text-slate-500"
              >
                By signing up, you are creating a Quali account, and you agree
                to our friends{' '}
                <span className="text-blue-700">Terms of Use</span> and{' '}
                <span className="text-blue-700">Privacy Policy</span>.
              </Label>
            </div>
            {errors.acceptTerms && (
              <p className="text-xs text-red-500">{errors.acceptTerms}</p>
            )}
          </div>
          <Button
            type="submit"
            className="bg-blue-700 w-full md:w-auto"
            onClick={() => (isSuperAdmin ? handleSubmit() : sendOtp())}
          >
            Create Account
          </Button>
          <Modal
            show={openModal}
            onClose={() => setOpenModal(false)}
            theme={{
              content: {
                base: 'bg-transparent w-3/4',
                inner: 'bg-transparent',
              },
            }}
          >
            <div className="flex items-center justify-center">
              {!openRedirectionModal ? (
                <>
                  <OTPConfirmation
                    confirmationResult={confirmationResult}
                    handleSubmit={handleSubmit}
                    resendOtp={resendOtp}
                    ForgotPasswordForm={false}
                    phoneNo={registerData.contactNumber}
                    setOpenModal={setOpenModal}
                    setRedirectionOpenModal={setRedirectionOpenModal}
                  />
                </>
              ) : (
                <div className="flex flex-col w-full h-screen font-global-styling">
                  <Congratulations />
                </div>
              )}
            </div>
          </Modal>
        </div>
      </Card>
    </div>
  )
}

export default RegisterFields
