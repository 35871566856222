import React from 'react'
import { Modal } from 'flowbite-react'

export const NotAMatchForm = ({
  openModal,
  setOpenModal,
  setSelectedReason,
  selectedReason,
  handleSetPassed,
  isPipelineView,
}) => {
  const handleReasonChange = event => {
    setSelectedReason(event.target.value)
  }

  const handleSubmit = event => {
    event.preventDefault()
    setOpenModal(false)
    handleSetPassed()
  }

  return (
    <Modal
      show={openModal}
      onClose={() => setOpenModal(false)}
      className="font-satoshi"
    >
      <div className=" px-8 py-8 rounded-lg shadow-lg">
        <h2 className="text-2xl text-gray-900 font-extrabold leading-tight self-stretch font-bold mb-4">
          Sorry it’s not a match...
        </h2>
        <p className="text-gray-500 mb-4 leading-tight self-stretch">
          Help us understand the reason you are not able to hire this candidate
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="flex items-center mb-2">
              <input
                type="radio"
                name="reason"
                value="Did not pass interview"
                checked={selectedReason === 'Did not pass interview'}
                onChange={handleReasonChange}
                className="form-radio indeterminate:bg-gray-100 text-blue-500"
              />
              <span className="ml-2 text-sm  ">Did not pass interview</span>
            </label>
            <label className="flex items-center mb-2">
              <input
                type="radio"
                name="reason"
                value="Was not a good culture fit"
                checked={selectedReason === 'Was not a good culture fit'}
                onChange={handleReasonChange}
                className="form-radio indeterminate:bg-gray-100 text-blue-500"
              />
              <span className="ml-2 text-sm font-satoshi">
                Was not a good culture fit
              </span>
            </label>
            <label className="flex items-center mb-2">
              <input
                type="radio"
                name="reason"
                value="Found a better candidate"
                checked={selectedReason === 'Found a better candidate'}
                onChange={handleReasonChange}
                className="form-radio indeterminate:bg-gray-100 text-blue-500"
              />
              <span className="ml-2 text-sm font-satoshi">
                Found a better candidate
              </span>
            </label>
            <label className="flex items-center mb-2">
              <input
                type="radio"
                name="reason"
                value="No longer needed / role filled"
                checked={selectedReason === 'No longer needed / role filled'}
                onChange={handleReasonChange}
                className="form-radio indeterminate:bg-gray-100 text-blue-500"
              />
              <span className="ml-2 text-sm font-satoshi">
                No longer needed / role filled
              </span>
            </label>
            <label className="flex items-center mb-2">
              <input
                type="radio"
                name="reason"
                value="Candidate did not show up to interview / was unresponsive"
                checked={
                  selectedReason ===
                  'Candidate did not show up to interview / was unresponsive'
                }
                onChange={handleReasonChange}
                className="form-radio indeterminate:bg-gray-100 text-blue-500"
              />
              <span className="ml-2 text-sm font-satoshi">
                Candidate did not show up to interview / was unresponsive
              </span>
            </label>
            {isPipelineView &&
            <label className="flex items-center mb-2">
              <input
                type="radio"
                name="reason"
                value="Candidate rejected the offer"
                checked={
                  selectedReason ===
                  'Candidate rejected the offer'
                }
                onChange={handleReasonChange}
                className="form-radio indeterminate:bg-gray-100 text-blue-500"
              />
              <span className="ml-2 text-sm font-satoshi">
              Candidate rejected the offer
              </span>
            </label>}
          </div>
          <button
            type="submit"
            className="bg-blue-700 text-white hover:bg-blue-800 focus:outline-none focus:ring focus:ring-Black-500 py-2 px-4 float-right rounded-xl"
          >
            Submit and Close
          </button>
        </form>
      </div>
    </Modal>
  )
}
