import React, { useEffect, useState, useRef } from 'react'
import { FaTimes } from 'react-icons/fa'
import { ReactComponent as DropdownIcon } from '../../../assets/svgs/angle-down-outline.svg'
import ReactCountryFlag from 'react-country-flag'

export function DropdownWithFlag({
  placeholder,
  dropdownItems,
  value,
  onChange,
  setDataChanged
}) {
  const [selectedItems, setSelectedItems] = useState([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef(null)

  useEffect(() => {
    if (value) {
      setSelectedItems(value)
    }
  }, [value])

  const handleItemClick = item => {
    const { value, flag } = item;
    setDataChanged(true)
    if (value === 'none') {
      const isNoneSelected = selectedItems.some(selectedItem => selectedItem.value === 'none');
      const newSelectedItems = isNoneSelected ? [] : [{ value: 'none', flag: null }];
      setSelectedItems(newSelectedItems);
      onChange(newSelectedItems);
      return;
    }
  
    if (selectedItems.some(selectedItem => selectedItem.value === 'none')) {
      return;
    }
  
    const updatedSelectedItems = selectedItems.some(selectedItem => selectedItem.value === value)
      ? selectedItems.filter(selectedItem => selectedItem.value !== value)
      : [...selectedItems, { value, flag }];
  
    setSelectedItems(updatedSelectedItems);
    onChange(updatedSelectedItems);
  };

  const deselectAll = e => {
    e.stopPropagation()
    setSelectedItems([])
    onChange([])
  }

  const toggleDropdown = e => {
    e.stopPropagation()
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const isNoneSelected = selectedItems.some(item => item?.value === 'none')

  return (
    <div
      className="w-full border rounded-lg gap-4 bg-gray-50 text-gray-900 relative"
      ref={dropdownRef}
    >
      <div
        className="flex justify-between items-center w-full px-2 py-1 cursor-pointer"
        onClick={toggleDropdown}
      >
        <div className="h-15 laptop-lg:h-10 text-[14px] ml-2 items-center justify-center flex flex-wrap">
        {selectedItems.length > 0 ? (
            selectedItems.map((item, index) => (
              <React.Fragment key={index}>
                <span className="flex items-center">
                    {item?.value}
                  {item?.flag && (
                    <ReactCountryFlag
                      className="ml-1"
                      countryCode={item?.flag}
                      svg
                    />
                  )}
                </span>
                {index < selectedItems?.length - 1 && (
                  <span className="mx-1">,</span>
                )}
              </React.Fragment>
            ))
          ) : (
            <span className="text-[14px] font-[500] ml-2">{placeholder}</span>
          )}
        </div>

        <div className="flex items-center space-x-2">
          {selectedItems.length > 0 && (
            <button onClick={deselectAll} className="text-red-500 text-sm">
              <FaTimes />
            </button>
          )}
          <DropdownIcon />
        </div>
      </div>
      {isDropdownOpen && (
        <div className="absolute left-0 right-0 mt-1 bg-white border rounded shadow-lg z-10">
          {dropdownItems.map(item => (
            <div
              key={item.value}
              className={`px-4 py-2 cursor-pointer ${isNoneSelected && item.value !== 'none' ? 'text-gray-400' : 'text-gray-900'}`}
              onClick={() => handleItemClick(item)}
            >
              <input
                type="checkbox"
                checked={selectedItems.some(
                  selectedItem => selectedItem.value === item.value,
                )}
                onChange={() => handleItemClick(item)}
                disabled={item.value !== 'none' && isNoneSelected}
                className="mr-2"
              />
              {item.value === 'none' ? 'None' : item.label}{' '}
              {item.flag && (
                <ReactCountryFlag
                  className="ml-1"
                  countryCode={item.flag}
                  svg
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
