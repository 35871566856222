import { useEffect, useState } from 'react'
import { Tabs } from 'flowbite-react'
import { selectSearchHistory } from '../../../redux/currentUserSlice'
import '../SearchCandidates.css'
import SearchHistoryCard from './SearchHistoryCard'
import { useDispatch, useSelector } from 'react-redux';
import { setTabChange, selectTabChange } from '../../../redux/tabChangeSlice'

const SearchTabs = ({ activeSearches, archiveSearches }) => {
  const searchHistories = useSelector(selectSearchHistory)
  const currentTab = useSelector(selectTabChange);
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const tab = currentTab === 'Archived' ? 1 : 0;

  useEffect(()=> {
    const tabChange = activeTab === 0 ? 'Active' : 'Archived';
    dispatch(setTabChange(tabChange));
  }, [activeTab])


  return (
    <Tabs
    style='underline'
      aria-label="Tabs with underline"
      className="tabs-no-outline tabs-no-bottom-border mx-2"
      onActiveTabChange={(tab) => setActiveTab(tab)}
    >
      <Tabs.Item
        active= {tab === 0}
        title={<span className="pr-4">Active Search ({activeSearches.length})</span>}
        className="tabs-item"
      >
        <SearchHistoryCard
          searchHistories={activeSearches}
          isArchive={false}
        />
      </Tabs.Item>
      <Tabs.Item
        active= {tab === 1}
        title={
          <span className="pr-4">Archived ({archiveSearches.length})</span>
        }
        className="tabs-item"
      >
        <SearchHistoryCard
          searchHistories={archiveSearches}
          isArchive={true}
        />
      </Tabs.Item>
    </Tabs>
  )
}

export default SearchTabs
