import Swal from 'sweetalert2'

const showAlert = ({
  icon = 'info',
  title = 'Alert',
  text = '',
  showConfirmButton = true,
  showCloseButton = false,
  showCancelButton = false,
}) => {
  return Swal.fire({
    icon,
    title,
    text,
    showConfirmButton,
    showCloseButton,
    showCancelButton,
    cancelButtonText: 'Cancel',
  })
}

export default showAlert
