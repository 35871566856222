import React, { useEffect, useState } from 'react'
import AdminNavbar from './AdminNavbar'
import Table from '../table/Table'
import { generateColumns } from '../../utilis/helpers/tableHelper'
import { getAllTests, getAllTestsListings } from '../../services/testService'

const TestsList = () => {
  const [tests, setTests] = useState([])
  const [testsListingForEdit, setTestsListingForEdit] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState('')

  const getTests = async () => {
    try {
      const response = await getAllTests(currentPage, query)
      const testListing = await getAllTestsListings()
      if (response.status === 200) {
        const mappedData = response.data.records.map(item => {
          const { id, category, search_history, candidate, company, test } =
            item?.attributes
          return {
            id: id,
            company_name: company.name,
            candidate_email: candidate.email,
            search_history_name: search_history?.name,
            category: category,
            test_id: test?.id,
            candidate_id: candidate?.id,
            company_id: company?.id,
            candidate_name: candidate?.first_name,
          }
        })
        setTestsListingForEdit(testListing?.data || [])
        setTests(mappedData || [])
        setTotalPages(response?.data?.pagy?.total_pages)
        setTotalCount(response?.data?.pagy?.total_count)
      }
    } catch (error) {
      console.error('Error fetching tests:', error)
    }
  }

  useEffect(() => {
    getTests()
  }, [currentPage, query])

  const onSearchTests = (search, value) => {
    setCurrentPage(1)
    setQuery(value)
  }

  const columns = generateColumns(tests)

  return (
    <div className="flex h-screen w-full flex-col bg-[#f9fafb]">
      <AdminNavbar selectedItem="revealed-candidates" />
      <Table
        columns={columns}
        data={tests}
        hideCreateButton={true}
        title={'Test'}
        onSearch={onSearchTests}
        getTests={getTests}
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        setCurrentPage={setCurrentPage}
        testsListingForEdit={testsListingForEdit}
      />
    </div>
  )
}

export default TestsList
