import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'flowbite-react'
import searchIcon from 'assets/svgs/searchicon.svg'
import ArrowRepeatIcon from '../../assets/svgs/arrows-repeat.svg'
import ArrowRepeatBlueIcon from '../../assets/svgs/arrows-repeat-blue.svg'

const TableFilters = ({
  searchQuery,
  handleSearchChange,
  sortstate,
  handleFilterChange,
  disableButton,
  handleCompareAction,
  selectedRows
}) => {
  return (
    <div className="flex flex-row flex-grow ml-2 mb-4">
      <div className={`flex flex-col w-full`}>
        {<div className="relative pt-6 pl-2">
          <div className="absolute inset-y-0 left-0 pt-6 flex items-center pl-6 pointer-events-none">
            <img src={searchIcon} alt="Search Icon" className="w-4 h-4" />
          </div>
          <input
            type="text"
            id="table-search"
            className="block pl-10 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            placeholder="Search Candidate"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>}
          <div className='flex md:flex-row flex-col'>
            <div className={`flex flex-wrap md:pb-6 space-x-4 `}>
              <span className="text-sm  ml-3 font-medium mt-4 text-gray-900 dark:text-gray-300">
                Show Only :
              </span>
              <label className="flex mt-4 items-center">
                <input
                  type="radio"
                  name="filter"
                  value="none"
                  className="radio"
                  onChange={handleFilterChange}
                  checked={sortstate === 'none'}
                />
                <span className="ml-2 text-sm text-gray-900 peer-checked:text-blue-600 peer-checked:font-medium cursor-pointer">
                  In Progress
                </span>
              </label>
              <label className="flex mt-4 items-center">
                <input
                  type="radio"
                  name="filter"
                  value="offered"
                  className="radio"
                  onChange={handleFilterChange}
                  checked={sortstate === 'offered'}
                />
                <span className="ml-2 text-sm text-gray-900 peer-checked:text-blue-600 peer-checked:font-medium cursor-pointer">
                  Offered
                </span>
              </label>
              <label className="flex items-center mt-4">
                <input
                  type="radio"
                  name="filter"
                  value="hired"
                  className="radio"
                  onChange={handleFilterChange}
                  checked={sortstate === 'hired'}
                />
                <span className="ml-2 text-sm text-gray-900 peer-checked:text-blue-600 peer-checked:font-medium cursor-pointer">
                  Hired
                </span>
              </label>
              <label className="flex mt-4 items-center">
                <input
                  type="radio"
                  name="filter"
                  value="passed"
                  className="radio"
                  onChange={handleFilterChange}
                  checked={sortstate === 'passed'}
                />
                <span className="ml-2 text-sm text-gray-900 peer-checked:text-blue-600 peer-checked:font-medium cursor-pointer">
                  Rejected
                </span>
              </label>
              <label className="flex items-center mt-4">
                <input
                  type="radio"
                  name="filter"
                  value="all"
                  className="radio"
                  onChange={handleFilterChange}
                  checked={sortstate === 'all'}
                />
                <span className="ml-4 text-sm  text-gray-900 peer-checked:text-blue-600 peer-checked:font-medium cursor-pointer">
                  All
                </span>
              </label>
            </div>
            <div className='flex-grow' />
            <div className='mr-4 md:mt-0 mt-8 ml-4 md:ml-0'>
              <Tooltip
                content="To use Candidate Comparison feature, you must select at least 2 (two) candidates."
                className={`text-gray-200 w-1/4 text-xs ${disableButton ? 'opacity-80 cursor-not-allowed' : 'hidden'} rounded px-2 py-1`}
                direction="up"
                arrow={true}
                arrowSize={6}
                arrowClassName="bg-gray-800" 
              >
                <button
                  type="button"
                  onClick={() => handleCompareAction?.(disableButton, selectedRows)}
                  disabled={disableButton}
                  className={`flex items-center px-4 py-2 border border-blue-700 rounded-lg
                    ${!disableButton
                      ? 'bg-blue-700 text-white'
                      : 'text-blue-700 bg-white cursor-not-allowed'}`
                  }
                >
                  <img 
                    src={disableButton ? ArrowRepeatBlueIcon : ArrowRepeatIcon} 
                    alt="Compare" 
                    className="w-4 h-4 mr-2"
                  />
                  Compare Candidates
                </button>
              </Tooltip>
            </div>
          </div>

      </div>
    </div>
  )
}

export default TableFilters
