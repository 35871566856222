import React from 'react'
import { Navbar } from 'flowbite-react'
const HomePageNav = () => {
  return (
    <div className="">
      <Navbar fluid className="bg-blue-700 py-2 px-3 text-white">
        <div className="flex items-center justify-between w-full">
          <Navbar.Brand>
            <span className="self-center whitespace-nowrap text-xl font-semibold">
              Quali
            </span>
          </Navbar.Brand>
        </div>
      </Navbar>
    </div>
  )
}

export default HomePageNav
