import axios from 'axios'
import { createBrowserHistory } from 'history'
import { isSessionOrAuthError } from 'utilis/helpers'

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : 'https://rails-app-new-5uuswgvdiq-ww.a.run.app'}`,
})
const history = createBrowserHistory()

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('accessToken')
    if (token) {
      config.headers.Authorization = `${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error?.response) {
      const { status, data } = error?.response;
      if ([401, 500, 422].includes(status) && isSessionOrAuthError(data)) {
        localStorage.removeItem('accessToken');
        history.push('/');
        window.location.reload();
      }
    } else if (error.request) {
      console.error('Request error:', error.request)
    } else {
      console.error('Error:', error.message)
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
