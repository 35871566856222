import React from 'react'

const CustomSpinner = ({ size = 'sm' }) => {
  const spinnerSize = {
    sm: 'w-4 h-4',
    md: 'w-6 h-6',
    lg: 'w-8 h-8',
  }[size]

  return (
    <div
      className={`border-t-4 border-blue-500 border-solid rounded-full animate-spin ${spinnerSize} border-t-transparent`}
      style={{ borderWidth: '2px' }}
    ></div>
  )
}

export default CustomSpinner
