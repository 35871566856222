import React from 'react';
import { Link } from 'react-router-dom';
import testImage from 'assets/svgs/test_black.svg';
import { TruncatedText } from 'utilis/helpers';
import TestCardButton from './TestCardButton';
import TestCardStatus from './TestCardStatus';
import TestCardAdminInfo from './TestCardAdminInfo';
import { useLocation } from 'react-router-dom';

const TestCard = ({ test, currentUser, isOverview, handleCompleteTest }) => {
  const isCandidate = currentUser.role === 'candidate';
  const  isCompanyAdmin = currentUser.role === 'company_admin';
  const location=useLocation()
  return (
    <div key={test.id} className="bg-white rounded-lg shadow-md p-1 sm:p-4 w-full flex flex-col">
      <div className="flex text-left mb-2">
      <div className="mr-2 mt-1">
          <img className="w-4 h-4" src={testImage} alt="Test Icon" />
        </div>
        <p className="font-bold text-base">{test.title}</p>
      </div>
      <p className={`text-left text-sm ${isOverview ? '' : 'text-gray-600 font-normal'}`}>
        <TruncatedText text={test?.description || 'No Description'} limit={50} />
      </p>

      {isCompanyAdmin && <TestCardAdminInfo test={test} isOverview={isOverview} />}
      {isCandidate && <TestCardStatus test={test} />}

      <div className="flex-grow" />

      {isOverview && isCandidate && (
        <TestCardButton
          test={test}
          isOverview={isOverview}
          handleCompleteTest={handleCompleteTest}
          isCandidate={isCandidate}
        />
      )}

      <Link
        to={isOverview && !isCandidate ? '#' : '/candidate-list-of-test'}
        state={{
          Testname: test.title,
          _pathname: location.pathname,
          TestId: test?.id,
        }}
      >
        {!isCandidate && (
          <TestCardButton
            test={test}
            isOverview={isOverview}
            handleCompleteTest={handleCompleteTest}
            isCandidate={isCandidate}
          />
        )}
      </Link>
    </div>
  );
};

export default TestCard;
