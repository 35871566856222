import React from 'react'
import { Link } from 'react-router-dom'
import './HomePage.css'
import HomePageCard from './DetailsCard/HomePageCard'
import HomePageNav from './Navbar/HomePageNav'
function HomePage() {
  return (
    <div className="homepage">
      <HomePageNav/>
      <section className="job-cards xs:mt-0 -mt-8">
        <HomePageCard />
      </section>
    </div>
  )
}

export default HomePage
