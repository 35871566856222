import React, { useEffect, useState, useRef } from 'react'
import { Button, Select, Badge } from 'flowbite-react'
import AppBar from '../Dashboard/AppBar'
import blue_eye_Icon from '../../assets/svgs/blue_eye.svg'
import heartIcon from '../../assets/heart.svg'
import RenameArchive from './SearchHistory/RenameArchiveButton'
import greyHeartIcon from '../../assets/svgs/grey_heart.svg'
import '../../assets/css/search-results.css'
import { AppSideBar } from '../Dashboard/AppSideBar'
import { TopHeader } from './TopHeader'
import '@fortawesome/fontawesome-free/css/all.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { RevealModal } from './RevealModal'
import greyMessagesIcon from '../../assets/svgs/grey_messages_outline.svg'
import prupletag from '../../assets/svgs/purple-messages-outline.svg'
import showAlert from '../generics/Alert'
import { useNavigate } from 'react-router-dom'
import { filter } from 'assets/SearchHistoryIcons'
import { FilterPanel } from './FilterPanel'
import { Breadcrumb } from '../../utilis/helpers/breadcrumbHelper'
import { selectTabChange } from '../../redux/tabChangeSlice'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectTalentSearchData,
  selectTalentSearchQueryParam,
  setTalentSearchData,
} from '../../redux/talentSearchSlice'
import {
  getCurrentUser,
  saveCandidateForSearch,
} from '../../services/userService'
import { selectCompany, setCurrentUser } from '../../redux/currentUserSlice'
import { setSearch } from '../../redux/searchSlice'
import { searchCandidates } from '../../services/candidateService'
import { candidateRevealedStatus } from '../../config/constants'
import CandidateDetailsCard from '../DetailsCard/CandidateDetailsCard'
import whiteHeartIcon from '../../assets/svgs/white-heart.svg'
import whiteEyelIcon from '../../assets/svgs/white-eye.svg'
import { isMobileDevice } from 'utilis/helpers'
import { selectSearch } from '../../redux/searchSlice'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from 'components/generics/loader/Loader'
import useScrollToTop from 'hooks/useScrollToTop'
import ArrowRepeatIcon from '../../assets/svgs/arrows-repeat.svg'
import { PersistentInbox } from 'components/Messaging/PersistentMinimizableInbox'

const SearchResults = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const company = useSelector(selectCompany)
  const talentSearch = useSelector(selectTalentSearchData)
  const talentSearchQueryParam = useSelector(selectTalentSearchQueryParam)
  const [disableSave, setDisableSave] = useState(false)
  const [isShowingSaved, setIsShowingSaved] = useState(false)
  const [isShowingRevealed, setIsShowingRevealed] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [modalOpenReveal, setModalOpenReveal] = useState(false)
  const [alreadyRevealed, setAlreadyRevealed] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [creditScore, setCreditScore] = useState(0)
  const [revealedCandidates, setRevealedCandidates] = useState([])
  const currentTab = useSelector(selectTabChange)
  const search = useSelector(selectSearch)
  const searchResults = talentSearch?.searchData
  const [totalCount, setTotalCount] = useState(0)
  const [historyResults, setHistoryResults] = useState(
    searchResults.search_results || [],
  )
  const [sortMethod, setSortMethod] = useState('');
  const searchHistory = searchResults.search_history
  const initialCompanySearchHistory = useRef(searchHistory)
  const [companySearchHistory, setCompanySearchHistory] = useState(
    initialCompanySearchHistory.current,
  )
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const isMobile = isMobileDevice()
  const [selectedExpertises, setSelectedExpertises] = useState(
    search?.expertise || [],
  )
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  const [perPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const [savedOrRevealed, setSavedOrRevealed] = useState('')
  const [savedCount, setSavedCount] = useState(0)
  const [revealedCount, setRevealedCount] = useState(searchHistory?.attributes?.revealed_candidates_count || 0)
  const breadcrumbItems = ['Quali Directory']
  useEffect(() => {
    setSelectedExpertises(search?.expertise || [])
  }, [search])

  useEffect(() => {
    if (Array.isArray(initialCompanySearchHistory.current)) {
      setCompanySearchHistory(initialCompanySearchHistory.current)
    } else {
      setCompanySearchHistory([initialCompanySearchHistory.current])
    }
  }, [])

  const handleOpenModal = searchResults => {
    setSelectedUserId(searchResults.user?.id)
    setCreditScore(searchResults.credit_score)
    setAlreadyRevealed(isCandidateRevealed(searchResults))
    !isCandidateRevealed(searchResults) && setOpenModal(true)
    isCandidateRevealed(searchResults) && setModalOpenReveal(true)
  }

  const [filterResult, setFilterResult] = useState({})
  const renderCompanySearchHistory = () => {
    const history = Array.isArray(companySearchHistory)
      ? companySearchHistory.find(
          history => history?.id === initialCompanySearchHistory.current?.id,
        ) || initialCompanySearchHistory.current
      : companySearchHistory
    breadcrumbItems.push(currentTab)
    breadcrumbItems.push(history?.attributes?.name)
    const count = isShowingSaved
      ? savedCount
      : isShowingRevealed
        ? revealedCount
        : 0
    return (
      <div className="rounded-lg max-w-full my-4 min-w-fit mr-3 mt-5">
        <Breadcrumb items={breadcrumbItems} />
        <div className="container my-2 w-full">
          <div className="header flex flex-col xl:flex-row justify-between items-start xl:items-center w-full">
            <span className="card-title break-all text-wrap">
              {history?.attributes?.name}
            </span>
            <div className="actions sm:mt-0 mt-2">
              <RenameArchive
                isArchive={false}
                value={history}
                setCompanySearchHistory={setCompanySearchHistory}
                source="searchResults"
                fetchAndUpdateData={fetchAndUpdateData}
              />
            </div>
          </div>
          <div className="text-gray-800 text-base font-medium leading-6 flex items-center mt-2">
            <span>We have</span>
            <span className="text-gray-800 text-base font-bold leading-6 mx-1">
            {searchResults?.total_count}
            </span>
            <span>results</span>
          </div>
          {(isShowingSaved || isShowingRevealed) && (
            <div className="text-gray-500 text-sm font-medium leading-6 ml-1 mt-2">
              <span>You have {isShowingSaved ? 'saved' : 'revealed'}</span>
              <span className="text-gray-800 text-base font-bold leading-6 mx-1">
                {count}
              </span>
              <span className="text-gray-800 text-base font-bold leading-6 mx-1">
                profile{count !== 1 && 's'}
              </span>
            </div>
          )}
          <div className="tip flex flex-col sm:flex-row items-start mt-2">
            <div className="flex items-center mb-2 sm:mb-0 mr-3 xl:mr-0">
              <span className="text-gray-800 text-base font-bold leading-6 font-satoshi-regular">
                Tip:
              </span>
              <img
                src={greyHeartIcon}
                alt="Heart Icon"
                className="mx-1 w-3 h-3 flex-shrink-0"
              />
            </div>
            <div className="flex text-14 items-center">
              <p className="font-satoshi-regular text-sm font-medium leading-6">
                <span className="text-gray-800">Save</span>
                <span className="text-gray-500 text-sm font-medium leading-6 ml-1">
                  candidates to shortlist candidates,
                </span>
                <span className="text-gray-800"> 'See Pipeline'</span>
                <span className="text-gray-500 text-sm font-medium leading-6 ml-1">
                  to track hiring progress
                </span>
              </p>
            </div>
          </div>
          <Badge>
            <div className="flex flex-wrap gap-4 mt-2">
              {searchHistory?.attributes?.skill?.title && (
                <label className="flex items-center bg-[#EDEBFE] text-[#5521B5] rounded-lg">
                  <img
                    src={prupletag}
                    className="w-3 h-3 ml-1"
                    alt="message-outline"
                  />
                  <span className="text-sm px-1 py-1 mr-2 rounded-lg font-normal">
                    {searchHistory.attributes.skill.title}
                  </span>
                </label>
              )}
              {selectedExpertises.map((expertise, index) => (
                <label
                  key={index}
                  className="flex items-center bg-white rounded-lg"
                >
                  <img
                    src={greyMessagesIcon}
                    className="w-3 h-3 ml-1"
                    alt="message-outline"
                  />
                  <span className="gray-900 text-sm px-1 py-1 mr-2 rounded-lg font-normal">
                    {expertise}
                  </span>
                </label>
              ))}
            </div>
          </Badge>
        </div>
      </div>
    )
  }

  const checkHasMore = data => {
    data.length < 10 ? setHasMore(false) : setHasMore(true)
  }

  useEffect(() => {
    if (talentSearch) {
      setTotalCount(searchHistory?.attributes?.search_results?.[0] || 0)
      checkHasMore(talentSearch?.searchData?.search_results)
      setHistoryResults(talentSearch?.searchData?.search_results || [])
      setCompanySearchHistory(talentSearch?.searchData?.search_history)
    }
  }, [talentSearch])

  const fetchAndUpdateData = async (
    dispatchTalent = false,
    defaultPage = false,
    resetSavedOrRevealed = false,
    sortBy = sortMethod ==='' ? 'expertise_match_score' : sortMethod
  ) => {
    try {
      if (page !== 1 || dispatchTalent) {
        setLoading(true)
        const response = await searchCandidates(
          talentSearchQueryParam.toString(),
          defaultPage ? 1 : page,
          resetSavedOrRevealed ? '' : savedOrRevealed,
          sortBy,
          'desc',
        )
        if (response.status === 200) {
          setRevealedCount(response?.data?.results?.search_history?.attributes?.revealed_candidates_count)
          if (dispatchTalent) {
            dispatch(setTalentSearchData(response?.data?.results || []))
          } else {
            const newResults = Array.isArray(
              response?.data?.results?.search_results,
            )
              ? response?.data?.results?.search_results
              : []

            const newResultsWithPage = newResults.map(candidate => ({
              ...candidate,
              page: page,
            }))
            setHistoryResults(prevResults => {
              return [...prevResults, ...newResultsWithPage]
            })

            checkHasMore(newResults)
          }
        }
      }
    } catch (error) {
      console.error('Error searching candidates:', error)
    }
    setLoading(false)
  }

  const isCandidateRevealed = candidate => {
    const { reveal_or_like_status } = candidate
    return [1, 2].includes(reveal_or_like_status) || ['revealed', 'both'].includes(candidate?.user?.latest_hired_passed?.category)
  }

  const isCandidateSaved = candidate => {
    const { reveal_or_like_status } = candidate
    return reveal_or_like_status===0 || reveal_or_like_status===2
  }

  const handleSelection = (type, isSelected) => {
    if (isSelected) {
      setSavedOrRevealed(type)
      fetchRevealOrSavedCandidates(type)
    } else {
      resetPageOnFilterReset()
      setSavedOrRevealed('')
      fetchAndUpdateData(true, true, true)
    }

    setIsShowingSaved(type === 'liked' && isSelected)
    setIsShowingRevealed(type === 'revealed' && isSelected)
  }

  const onSelectRevealed = revealed => {
    handleSelection('revealed', revealed)
  }

  const onSelectSaved = saved => {
    handleSelection('liked', saved)
  }

  const onSaveCandidate = async candidate => {
    setDisableSave(true)
    try {
      const data = {
        company_id: company?.id,
        user_id: candidate?.user?.id,
        search_history_id: searchHistory?.id,
        category: 0,
      }
      const response = await saveCandidateForSearch(data)
      if (response.status === 200 || response.status === 201) {
        !response?.data?.user &&
          savedOrRevealed === 'liked' &&
          setHistoryResults(prevResults =>
            prevResults.filter(data => data?.user?.id !== candidate?.user?.id),
          )
        fetchOnCandidateSave(candidate)
      }
    } catch (error) {
      console.error('Error saving candidate', error)
    } finally {
      setDisableSave(false)
    }
  }

  const fetchOnCandidateSave = async (candidate, updateRevealedIds = false) => {
    const response = await searchCandidates(
      talentSearchQueryParam.toString(),
      candidate?.page || 1,
    )
    if (response.status === 200) {
      const { search_results, search_history } = response?.data?.results
      const filteredCandidate = search_results
        .filter(data => data?.user?.id === candidate?.user?.id)
        .map(data => ({
          ...data,
          page: candidate?.page,
        }))
      setSavedCount(search_history?.attributes?.liked_candidates_count)
      setRevealedCount(search_history?.attributes?.revealed_candidates_count)
      setHistoryResults(prevResults =>
        filteredCandidate.length === 0
          ? updateIds(candidate, search_history, updateRevealedIds)(prevResults)
          : updateFilteredCandidate(filteredCandidate, candidate)(prevResults),
      )
    }
  }

  const updateFilteredCandidate =
    (filteredCandidate, candidate) => prevResults =>
      prevResults.map(data =>
        data?.user?.id === candidate?.user?.id ? filteredCandidate[0] : data,
      )

  const toggleId = (ids, id) =>
    ids.includes(id) ? ids.filter(x => x !== id) : [...ids, id]

  const updateIds = (candidate, searchHistory, isRevealed) => prevResults => {
    const idType = `search_history_${isRevealed ? 'revealed' : 'liked'}_ids`
    const searchHistoryId = searchHistory?.attributes?.id

    return prevResults.map(data => {
      if (data?.user?.id !== candidate?.user?.id) return data

      const currentIds = data[idType] || []
      const updatedIds =
        isRevealed && !currentIds.includes(searchHistoryId)
          ? [...currentIds, searchHistoryId]
          : toggleId(currentIds, searchHistoryId)

      return { ...data, [idType]: updatedIds }
    })
  }
  const fetchRevealOrSavedCandidates = async savedOrRevealed => {
    setLoading(true)
    resetPageOnFilterReset()
    const response = await searchCandidates(
      talentSearchQueryParam.toString(),
      1,
      savedOrRevealed,
    )
    if (response.status === 200) {
      const { search_results, search_history } = response?.data?.results
      setHistoryResults(search_results)
      checkHasMore(search_results)
      setSavedCount(search_history?.attributes?.liked_candidates_count)
      setRevealedCount(search_history?.attributes?.revealed_candidates_count)
    }
    setLoading(false)
  }
  const fetchCurrentUser = async () => {
    try {
      const response = await getCurrentUser()
      dispatch(setCurrentUser(response?.data))
    } catch (err) {
      console.error('Error fetching user', err)
    }
  }

  const handleSortChange = (event) => {
    resetPageOnFilterReset()
    setSortMethod(event.target.value);
    fetchAndUpdateData(true, true, false, event.target.value)
  };

  useEffect(() => {
    fetchAndUpdateData()
  }, [page,searchHistory])

  const resetPageOnFilterReset = () => {
    setSortMethod('expertise_match_score')
    setPage(1)
  }

  const handleSeePipeline = async (flag = false) => {
    if (revealedCount > 0 || flag) {
      const queryParams = new URLSearchParams({
        skill: searchHistory?.attributes?.skill?.title,
        search_name: searchHistory?.attributes?.name,
        is_view_search: true,
        search_id: searchHistory?.id,
      });

      searchHistory?.attributes?.expertise?.forEach(expertise => {
        queryParams.append('expertises[]', expertise);
      });

      const jobPreferences = searchHistory?.attributes?.job_preferences
      if (jobPreferences) {
        Object.keys(jobPreferences).forEach(key => {
          queryParams.append(`job_preferences[${key}]`, jobPreferences[key])
        });
      }

      queryParams.append('page', 1)
      queryParams.append('per_page', 10)
      queryParams.append('profile_status', 'none')

      const response = await searchCandidates(queryParams.toString())

      if (response.status === 200) {
        fetchAndUpdateData()
        navigate('/searched-candidates', {
          state: {
            queryParams: queryParams.toString(),
            searchName: searchHistory?.attributes?.name,
            totalCount: response.data.pagy.total_count,
            pageCount: response.data.pagy.total_pages
          }
        })
      } else {
        console.error('Error searching candidates:', response.statusText)
      }
    }
  }
  useScrollToTop(fetchAndUpdateData)

  return (
    <div className="h-full bg-[#f9fafb] font-satoshi-regular">
      <AppBar />
      <div className="flex flex-col md:flex-row p-[2px] md:p-10 rounded-sm">
        <div className="hidden md:block">
          <AppSideBar />
        </div>
        <div className="flex flex-col w-full md:w-3/5 pl-4  ">
          <TopHeader />
          <div className="border-b-2 border-gray rounded-lg bg-white mt-5 h-95 shadow mr-3">
            <div className=" flex flex-col md:flex-row justify-between items-center border-b border-grey-20 mx-2 md:mx-4">
              <div className="w-full flex flex-col mt-5 px-2">
                {renderCompanySearchHistory()}
                <div className="mr-3 flex justify-between items-center flex-wrap">
                  <div className="flex gap-2">
                    <Button
                      onClick={() => onSelectSaved(!isShowingSaved)}
                      color={isShowingSaved ? 'blue' : 'light'}
                      size="sm"
                      className={`flex items-center justify-center text-black border border-[#E5E7EB] text-sm ${isMobile ? 'py-1' : 'py-2 px-4'} rounded-lg`}
                    >
                      <img
                        src={isShowingSaved ? whiteHeartIcon : heartIcon}
                        alt="Heart Icon"
                        className={`mr-2 ${isShowingSaved ? 'saved-image' : ''}`}
                      />
                      <p
                        className={`${isMobile ? '' : 'px-2'} ${isShowingSaved ? 'text-white' : ''}`}
                      >
                        Saved
                      </p>
                    </Button>

                    <Button
                      onClick={() => onSelectRevealed(!isShowingRevealed)}
                      color={isShowingRevealed ? 'blue' : 'light'}
                      size="sm"
                      className={`flex items-center justify-center text-black border border-[#E5E7EB] text-sm  ${isMobile ? 'py-1' : 'py-2 px-4'} rounded-lg`}
                    >
                      <img
                        src={isShowingRevealed ? whiteEyelIcon : blue_eye_Icon}
                        alt="Reveal Icon"
                        className={`mr-2 ${isShowingRevealed ? 'saved-image' : ''}`}
                      />
                      <p
                        className={`${isMobile ? '' : 'px-2'} ${isShowingRevealed ? 'text-white' : ''}`}
                      >
                        Revealed
                      </p>
                    </Button>
                    <button
                      onClick={handleSeePipeline}
                      className={`bg-blue-700 border-[#1A56DB] border text-white px-5 py-2 rounded-lg flex items-center gap-2 ${
                        revealedCount === 0? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                       disabled={revealedCount=== 0}
                    >
                      <img
                        src={ArrowRepeatIcon}
                        alt="Arrows Repeat"
                        className="w-4 h-4 mr-1"
                      />
                      See Pipeline
                    </button>
                  </div>
                  {searchHistory?.attributes?.applied_filter ?
                  (<Select
                    id="sortResult"
                    name="sortResult"
                    className="hidden laptop-lg:block"
                    value={sortMethod}
                    onChange={handleSortChange}
                  >
                    {searchHistory?.attributes?.applied_filter ?
                      <>
                        <option value="">Sort By: Match Score</option>
                        <option value="job_preference_match_score">Sort By: Preference Score</option>
                      </>
                      :
                      <option value="expertise_match_score">Sort By: Best Match</option>
                    }
                  </Select>
                ):
                (
                  <div className="block bg-gray-50 p-3  text-gray-400 rounded-md border border-gray-300">
                      Sorted By Match Score
                  </div>
                )}
                  <div className="flex items-center below-laptop-lg:block hidden">
                    <Button
                      className="items-center  "
                      onClick={() => setOpenFilterModal(true)}
                    >
                      <img src={filter} alt="filterIcon" className="w-6 h-6" />
                    </Button>
                  </div>
                </div>

                <InfiniteScroll
                  dataLength={historyResults.length}
                  next={() => {
                    setPage(prevPage => prevPage + 1)
                  }}
                  hasMore={hasMore}
                >
                  <CandidateDetailsCard
                    data={historyResults}
                    onSaveCandidate={onSaveCandidate}
                    isCandidateSaved={isCandidateSaved}
                    handleOpenModal={handleOpenModal}
                    isCandidateRevealed={isCandidateRevealed}
                    disableSave={disableSave}
                    isShowingRevealed={isShowingRevealed}
                    isShowingSaved={isShowingSaved}
                    filterResult={filterResult}
                    fetchAndUpdateData={fetchAndUpdateData}
                    searchHistory={searchHistory}
                    handleSeePipeline={handleSeePipeline}
                  />
                  {loading && <Loader />}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
        {selectedUserId && (
          <>
            {revealedCandidates &&
            revealedCandidates.includes(selectedUserId) ? (
              showAlert({
                icon: 'info',
                title: 'Profile Already Revealed',
                text: 'This profile is already revealed.',
                showConfirmButton: false,
              })
            ) : (
              <RevealModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                userId={selectedUserId}
                creditScore={creditScore}
                revealedCandidates={revealedCandidates}
                candidate={historyResults.find(
                  result => result?.user?.id === selectedUserId,
                )}
                alreadyRevealed={alreadyRevealed}
                searchHistory={searchHistory}
                fetchAndUpdateData={fetchAndUpdateData}
                modalOpenReveal={modalOpenReveal}
                setModalOpenReveal={setModalOpenReveal}
                fetchCurrentUser={fetchCurrentUser}
                isCandidateRevealed={isCandidateRevealed}
                historyResults={historyResults}
                setHistoryResults={setHistoryResults}
                fetchOnCandidateSave={fetchOnCandidateSave}
              />
            )}
          </>
        )}
        <FilterPanel
          openFilterModal={openFilterModal}
          setOpenFilterModal={setOpenFilterModal}
          historyResults={historyResults}
          setHistoryResults={setHistoryResults}
          searchHistory={searchHistory}
          setFilterResult={setFilterResult}
          setCompanySearchHistory={setCompanySearchHistory}
          resetPageOnFilterReset={resetPageOnFilterReset}
          fetchAndUpdateData={fetchAndUpdateData}
        />
      </div>
      <PersistentInbox />
    </div>
  )
}

export default SearchResults
