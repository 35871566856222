import React from 'react'
import { AttachementText } from './AttachementText'
import { InitialLetters } from 'components/generics/Initials'
import { AcceptRejectOffer } from './AcceptRejectOffer'
import {
  selectCurrentUser,
} from '../../../../redux/currentUserSlice'
import { useSelector } from 'react-redux'

export const ClientChatTextBubble = ({
  name,
  time,
  messageText,
  userAvatar,
  attachment = null,
  selectedChat
}) => {
  const { currentUser } = useSelector(selectCurrentUser)

  return (
    <div className="flex flex-row justify-start gap-1 pr-20">
      {(currentUser.role==='candidate' && selectedChat?.logo_url )  || (currentUser.role==='company_admin' && selectedChat?.user?.profile_picture_url) ? (
          <img
            src={currentUser.role==='company_admin' ? selectedChat?.user?.profile_picture_url  :selectedChat?.logo_url}
            alt="User Logo"
            className="rounded-full w-8 h-8 md:w-8 md:h-8"
          />
        ) : (
          <div className="flex justify-center items-center p-5 rounded-full text-lg bg-gray-100 border-gray-100 h-8 w-8 md:h-8 md:w-8">
            <InitialLetters
              Name={name}
            />
          </div>
      )}
      <div className="flex flex-col gap-2.5 items-start">
        <div className="flex flex-row gap-1.5">
          <span className="text-[#111928] text-14 font-medium">{name}</span>
          <span className="text-[#6B7280] text-14 font-medium">{time}</span>
        </div>
        {/* <>Tying</> */}
        {attachment && (
          <div className="p-4 bg-[#F3F4F6] rounded-tl-[0px] rounded-tr-[20px] rounded-bl-[20px] rounded-br-[20px] mb-2">
            <AttachementText
              fileName={attachment.filename}
              totalPages={attachment.page_count}
              fileSize={`${attachment.file_size} MB`}
              fileType={attachment.filename.split('.').pop().toUpperCase()}
              attachmentUrl={attachment.url}
            />
          </div>
        )}
        {messageText && (
          <>
          <div className="p-4 bg-[#F3F4F6] rounded-tl-[0px] rounded-tr-[20px] rounded-bl-[20px] rounded-br-[20px] mr-10">
            <span className={`${attachment || messageText.includes('please download, sign, and send it back') ?'text-[#6875F5]' : 'text-[#111928]'} text-sm font-normal`}>
              {messageText}
            </span>
          </div>
          </>
        )}
        {(attachment || messageText.includes('please download, sign, and send it back')) && currentUser?.role ==='candidate' && (
          <>
          <AcceptRejectOffer selectedChat={selectedChat} currUser={currentUser}/>
          </>
        )}
      </div>
    </div>
  )
}
