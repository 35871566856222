import React from 'react'
import { useState, useEffect } from 'react'
import { Button, Modal } from 'flowbite-react'
import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { InitialLetters } from '../generics/Initials'
import {
  selectCompany,
  selectSearchCount,
  setSearchHistoryCount,
} from '../../../src/redux/currentUserSlice'
import { selectUnreadCount } from '../../../src/redux/messageSlice'
import { logoutUser } from '../../lib/api'
import { useDispatch } from 'react-redux'
import useToastMessage from '../../hooks/useToastMessage'
import RevealIcon from '../../assets/blue-reveal-icon.svg'
import { ReactComponent as BlackRevealIcon } from '../../assets/black-reveal-icon.svg'
import { ReactComponent as InboxIcon } from '../../assets/inbox-icon.svg'
import { ReactComponent as TestAssessmentsIcon } from '../../assets/test-assessments-icon.svg'
import { ReactComponent as RedLogoutIcon } from '../../assets/red-logout-icon.svg'
import { ReactComponent as UserIconQualiDirectory } from '../../assets/users-icon.svg'
import { ReactComponent as FilledStarIcon } from '../../assets/filled-star-icon.svg'
import { ReactComponent as PurpleTestsIcon } from '../../assets/purple-tests-icon.svg'
import { ReactComponent as ManageProfile } from '../../assets/svgs/manage-profile.svg'
import { ReactComponent as InboxMessageIcon } from 'assets/svgs/inbox-messages-outline.svg'
import { TruncatedText } from 'utilis/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfinity } from '@fortawesome/free-solid-svg-icons'
import {
  companySearchHistoryCount,
} from '../../services/searchResult'
export const AppSideBar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const toastMessage = useToastMessage()
  const company = useSelector(selectCompany)
  const unreadCount = useSelector(selectUnreadCount)
  const location = useLocation()
  const pathname = location.pathname

  const [logoutModalOpen, setLogoutModalOpen] = useState(false)
  const fetchSearchHistoryCount = useSelector(selectSearchCount)
  const [searchHistoryCount, setSearchHistoriesCount] = useState(fetchSearchHistoryCount || 0)

  useEffect(() => {
    fetchHistoryCount()
  }, [])

  const fetchHistoryCount = async () => {
    const response = await companySearchHistoryCount()
    if (response.data) {
      setSearchHistoriesCount(response.data.search_history_count)
      dispatch(setSearchHistoryCount(response.data.search_history_count))
    }
  }
  const handleLogoutClick = () => {
    setLogoutModalOpen(true)
  }

  const handleLogoutConfirmed = () => {
    setLogoutModalOpen(false)
    logoutUser(dispatch, navigate, toastMessage)
  }

  return (
    <div
      className="flex flex-col h-fit items-center ml-8 p-4 bg-white shadow-md rounded-lg w-48 md:w-60 mb-4 md:mb-0
    "
    >
      <div className="flex flex-row items-center p-1.5 w-full">
        {company.logo_url ? (
          <div>
            <img
              src={company.logo_url}
              alt="Company Logo"
              className="mb-2 w-10 h-10 rounded-full object-cover"
            />
          </div>
        ) : (
          <div className=" flex justify-center items-center p-5 rounded-full text-3xl bg-gray-100 border-gray-100 h-20 w-20 md:h-12 md:w-12">
            <InitialLetters Name={company.name} />
          </div>
        )}

        <div className="flex flex-col ml-4 gap-1">
          <p className="text-md font-bold text-gray-900 max-w-32 text-wrap break-all">
            <TruncatedText text={company && company.name} limit={50} />
          </p>
          <div className="border border-blue-600 flex justify-center items-center rounded-md w-10/12 mt-2">
            <Button
              outline
              size="xs"
              className=" bold text-[#1a56db] font-semibold w-full max-w-[200px] rounded-[4px] border-sm px-3 py-1"
              color={'white'}
              onClick={() => {
                navigate('/manage-profile')
              }}
            >
              <p className="text-blue-700 text-sm font-medium whitespace-nowrap ">
                Manage Profile
              </p>
            </Button>
          </div>
        </div>
      </div>
      <div className="border-t border-b border-gray w-full my-3">
        <div className="flex justify-between space-x-2 w-full my-3">
          <div className="flex flex-col items-center bg-blue-50 rounded-lg w-1/2 justify-center py-2 my-2">
            <span className="text-blue-600">
              <img src={RevealIcon} alt="Reveal Icon" width="20" height="20" />
            </span>
            <span className="text-blue-600 text-sm font-semibold text-center pt-2">
              <FontAwesomeIcon icon={faInfinity} /> Reveals
            </span>
          </div>
          <div className="flex flex-col items-center bg-purple-50 rounded-lg w-1/2 justify-center py-2 my-2">
            <span className="text-purple-600">
              <PurpleTestsIcon />
            </span>
            <span className="text-purple-600 text-sm font-semibold pt-2">
              {company.remaining_test_count} Tests
            </span>
          </div>
        </div>
      </div>
      <div className="mt-4 w-full">
        <ul className="space-y-2">
          <Link to="/overview">
            <li
              className={`flex items-center space-x-2 p-2 rounded-lg ${pathname === '/overview' && ' bg-blue-100'}`}
            >
              <span className="material-icons">
                <FilledStarIcon />
              </span>
              <span>Overview</span>
            </li>
          </Link>
          <Link to="/search">
            <li
              className={`flex justify-between items-center p-2 rounded-lg ${(pathname === '/search' || pathname === '/search-results') && ' bg-blue-100'}`}
            >
              <span className="flex items-center space-x-2">
                <UserIconQualiDirectory />
                <span>Quali Search</span>
              </span>
              <div className="flex justify-between">
                <span className="bg-white text-blue-600 text-sm px-2 rounded-full">
                  {searchHistoryCount}
                </span>
              </div>
            </li>
          </Link>
          <Link to="/revealed-profiles">
            <li
              className={`flex items-center space-x-2 p-2 rounded-lg ${pathname === '/revealed-profiles' && ' bg-blue-100'}`}
            >
              <span className="material-icons">
                <BlackRevealIcon />
              </span>
              <span>Revealed Profiles</span>
            </li>
          </Link>
          <Link to="/test-and-assessment">
            <li
              className={`flex items-center space-x-2 p-2 rounded-lg ${pathname === '/test-and-assessment' && ' bg-blue-100'}`}
            >
              <span className="material-icons">
                <TestAssessmentsIcon />
              </span>
              <span>Test and Assessment</span>
            </li>
          </Link>
          <hr className="border-gray-200 w-full" />
          <Link to="/company-inbox">
            <li
              className={`flex justify-between items-center space-x-2 p-2 rounded-lg mt-6 ${pathname === '/company-inbox' && ' bg-blue-100'}`}
            >
              <span className="flex items-center space-x-2">
                <InboxMessageIcon />
                <span className="text-[#111928] font-[500]">My Inbox</span>
              </span>
              <div className="flex justify-between">
                <span className="bg-white text-blue-600 text-sm px-2 rounded-full">
                  {unreadCount}
                </span>
              </div>
            </li>
          </Link>
          <Link to="/manage-profile">
            <li
              className={`flex items-center space-x-2 p-2 rounded-lg ${pathname === '/manage-profile' && ' bg-blue-100'}`}
            >
              <span className="material-icons">
                <ManageProfile />
              </span>
              <span className="text-[#111928] font-[500]">Manage Profile</span>
            </li>
          </Link>
          <li className="flex items-center space-x-2 text-red-600 p-2">
            <span className="material-icons">
              <RedLogoutIcon />
            </span>
            <div>
              <button onClick={handleLogoutClick}>Log out</button>
            </div>
          </li>
        </ul>
      </div>
      <Modal
        show={logoutModalOpen}
        size="md"
        onClose={() => setLogoutModalOpen(false)}
        popup
        className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-50"
      >
        <div className="p-8 bg-white rounded shadow-md">
          <p className="text-2xl text-center font-bold mb-4">
            Log Out Confirmation
          </p>
          <p className="text-lg text-center mb-8">
            Are you sure you want to log out?
          </p>
          <div className="flex  justify-end">
            <Button
              onClick={() => setLogoutModalOpen(false)}
              className="bg-gray-200 hover:bg-gray-300 mr-2 text-gray-800 font-bold py-2 mr-2 px-4 rounded"
            >
              Cancel
            </Button>
            <Button
              onClick={handleLogoutConfirmed}
              className="bg-red-500 hover:bg-red-700 mr-14 text-white mr-8 font-bold py-2 px-4 mr-14 rounded"
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
