import React from 'react';
const VerificationModal = ({ isOpen, onClose,setIsModalOpen }) => {
  if (!isOpen) return null
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50" onClick={()=>setIsModalOpen(false)}></div>
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-xl relative">
        <button
          onClick={()=>setIsModalOpen(false)}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          &#10005;
        </button>
        <h2 className="text-xl font-semibold mb-4">
          Some documents may need additional fees to verify
        </h2>

        <p className="text-gray-500 mb-6">
          Someone from Quali will reach out to you as soon as possible.
        </p>

        <p className="text-black mb-8">
          Before we verify the document, Quali admins will reach out to you to
          discuss if any costs are incurred. Due to the range of documents and
          nationalities - verification costs can be free or paid.
        </p>

        <div className="text-right">
          <button
            onClick={onClose}
            className="bg-blue-700 text-white font-semibold py-2 px-10 rounded-lg hover:bg-blue-800 transition duration-300"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerificationModal
