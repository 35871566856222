import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../redux/currentUserSlice'
import { IntercomChatBot } from 'components/IntercomChatBot/ChatBotComponent'

const ProtectedRoutes = ({ isAdminRoute }) => {
  const loggedIn = useSelector(selectCurrentUser)
  const accessToken = localStorage.getItem('accessToken')
  const isSuperAdmin = loggedIn?.currentUser?.role === 'super_admin'

  const isValidUser =
    Object.keys(loggedIn).length !== 0 && loggedIn.constructor === Object
  const isAuthenticated = accessToken && isValidUser

  if (isAdminRoute && !isSuperAdmin) {
    return <Navigate to="/admin/login" />
  }
  return (
    <>
      {isAuthenticated ? (
        <IntercomChatBot>
          <Outlet />
        </IntercomChatBot>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  )
}

export default ProtectedRoutes
