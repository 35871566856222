import { Button } from 'flowbite-react'
import { FieldArray } from 'formik'
import { useEffect, useState } from 'react'
import { PreviousCertificationCard } from './PreviousCertification'
import { EditCertificationComponent } from './EditCertification'
import { findExistingCertificateIndex } from './experience-helpers/experienceHelpers'

export const CertificationsComponent = ({
  values,
  userId,
  addedOrModifiedCertificate,
  setAddedOrModifiedCertificate,
  setIsEditing,
  isExpOrCertValuesTouched,
  deleteCertificationHistories,
}) => {
  const [previousCertificate, setPreviousCertificate] = useState(
    values?.certifications || [],
  )
  const [showForm, setShowForm] = useState(false)
  const [isModifyRequested, setIsModifyRequested] = useState(false)
  const [modifyThisCertificate, setModifyThisCertificate] = useState({})
  const [isDeleted, setIsDeleted] = useState(false)
  const [deleteThisCertificate, setDeleteThisCertificate] = useState({})
  const [editingIndex, setEditingIndex] = useState(null)

  const handleAddCertificate = () => {
    setShowForm(true)
    setIsModifyRequested(false)
  }

  useEffect(() => {
    if (isDeleted) {
      if (deleteThisCertificate.certificateId) {
        deleteCertificationHistories(deleteThisCertificate.certificateId)
      }

      const prevCertindex = findExistingCertificateIndex(
        previousCertificate,
        deleteThisCertificate,
      )
      const addedModifiedIndex = findExistingCertificateIndex(
        addedOrModifiedCertificate,
        deleteThisCertificate,
      )
      if (prevCertindex !== -1 && addedModifiedIndex !== -1) {
        const updatedCertificateList = previousCertificate.filter(
          (cert, i) => i !== prevCertindex,
        )
        setPreviousCertificate(updatedCertificateList)
        const updatedAddedOrModifiedCertificate =
          addedOrModifiedCertificate.filter(
            (cert, i) => i !== addedModifiedIndex,
          )
        setAddedOrModifiedCertificate(updatedAddedOrModifiedCertificate)
        setIsDeleted(false)
      } else if (prevCertindex !== -1 && addedModifiedIndex === -1) {
        const updatedWorkCertificateList = previousCertificate.filter(
          (cert, i) => i !== prevCertindex,
        )
        setPreviousCertificate(updatedWorkCertificateList)
        setIsDeleted(false)
      }
      isExpOrCertValuesTouched.current = true
    }
  }, [isDeleted, previousCertificate])

  useEffect(() => {
    if (isModifyRequested) {
      setIsEditing(true)
    } else {
      showForm ? setIsEditing(true) : setIsEditing(false)
    }
  }, [showForm, isModifyRequested])

  useEffect(() => {
    setPreviousCertificate(values?.certifications || [])
  }, [values])

  return (
    <>
      <FieldArray
        name="prevWorkExperience"
        render={() => (
          <>
            {previousCertificate.length > 0 &&
              previousCertificate.map((certificate, index) => (
                <div key={index}>
                  <PreviousCertificationCard
                    key={index}
                    userId={userId}
                    certificate={certificate}
                    setIsModifyRequested={setIsModifyRequested}
                    setModifyThisCertificate={setModifyThisCertificate}
                    setIsDeleted={setIsDeleted}
                    setDeleteThisCertificate={setDeleteThisCertificate}
                    setEditingIndex={setEditingIndex}
                    editingIndex={index}
                  />
                  {isModifyRequested && editingIndex === index && (
                    <EditCertificationComponent
                      certificate={modifyThisCertificate}
                      isModifyRequested={isModifyRequested}
                      setIsModifyRequested={setIsModifyRequested}
                      addedOrModifiedCertificate={addedOrModifiedCertificate}
                      setAddedOrModifiedCertificate={
                        setAddedOrModifiedCertificate
                      }
                      previousCertificate={previousCertificate}
                      setPreviousCertificate={setPreviousCertificate}
                      setShowForm={setShowForm}
                    />
                  )}
                </div>
              ))}
          </>
        )}
      />

      {!isModifyRequested && showForm && (
        <>
          <EditCertificationComponent
            isModifyRequested={isModifyRequested}
            setIsModifyRequested={setIsModifyRequested}
            setShowForm={setShowForm}
            addedOrModifiedCertificate={addedOrModifiedCertificate}
            setAddedOrModifiedCertificate={setAddedOrModifiedCertificate}
            previousCertificate={previousCertificate}
            setPreviousCertificate={setPreviousCertificate}
          />
        </>
      )}
      <div className="flex w-full justify-center">
        <Button
          className="px-6 text-[#1C64F2] text-center font-satoshi-variable cursor-pointer mb-4 hover:bg-transparent border-none shadow-none outline-none focus:outline-none focus:ring-0"
          onClick={handleAddCertificate}
          disabled={showForm || isModifyRequested}
        >
          Add More Certificate(s)
        </Button>
      </div>
    </>
  )
}
