import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import { currentUserReducer } from './redux/currentUserSlice'
import toastReducer from './redux/toastSlice'
import { combineReducers } from 'redux'
import { searchReducer } from './redux/searchSlice'
import { selectTabReducer } from './redux/tabChangeSlice'
import { talentSearchReducer } from './redux/talentSearchSlice'
import { candidateFeedReducer } from './redux/candidateFeedSlice'
import { messageSliceReducer } from './redux/messageSlice'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['toast'],
}

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    currentUser: currentUserReducer,
    toast: toastReducer,
    search: searchReducer,
    selectTab: selectTabReducer,
    talent: talentSearchReducer,
    candidateFeed: candidateFeedReducer,
    messages: messageSliceReducer,
  }),
)

export const store = configureStore({
  reducer: persistedReducer,
})

export const persistor = persistStore(store)
