import React from 'react'
import avatarImg from 'assets/avatar.png'
import { TruncatedText } from 'utilis/helpers'

const CandidateProfile = ({profilePicture, name, location, experience}) => {
  return (
    <div className="flex flex-row items-center xl2:w-full w-1/2">
    <div className="relative flex-shrink-0 below-tablet-xl:mt-5">
      <img
        className="w-16 h-16 sm:w-20 sm:h-20 rounded-full object-cover border-2 border-[#E5E7EB]"
        src={profilePicture ?? avatarImg}
        alt={name}
      />
      <span className="absolute top-1 right-1 sm:top-0 sm:right-3 w-2 h-2 sm:w-3 sm:h-3 bg-green-400 rounded-full"></span>
    </div>
    <div className="ml-2 flex-grow below-tablet-xl:mt-5">
      <p className="text-lg font-extrabold text-gray-900 lg:whitespace-nowrap whitespace-wrap">
        <TruncatedText text={name} limit={25} />
      </p>
      <p className="text-14 font-medium text-gray-600 lg:whitespace-nowrap">
        {experience} years of experience
      </p>
      <p className="text-14 font-medium text-gray-800 mr-0">
        <span className="text-gray-600">From </span>
        {location ? location : 'Not provided'}
      </p>
    </div>
  </div>
  )
}

export default CandidateProfile
