import React, { useEffect, useState } from 'react'
import AdminNavbar from './AdminNavbar'
import axiosInstance from '../../services/axiosConfig'
import Table from '../table/Table'
import { generateColumns } from '../../utilis/helpers/tableHelper'
import moment from 'moment'
import { downloadCandidatesCSV } from 'services/userService'
import { formatDateToUTCTime } from 'utilis/helpers'

const CandidatesList = () => {
  const [candidates, setCandidates] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState('')
  const [moreDetails, setMoreDetails] = useState([])

  const getCandidates = async () => {
    const response = await axiosInstance.get(
      `/api/v1/users?page=${currentPage}&q=${query}`,
    )
    const mappedData = response.data.records.map(item => {
      const {
        email,
        first_name,
        last_name,
        phone_no,
        id,
        residence_country,
        citizenship,
      } = item?.attributes
      return {
        id: id,
        email: email,
        first_name: first_name,
        last_name: last_name,
        phone_number: phone_no,
        country_of_residence: residence_country || 'Not Provided',
        nationality: citizenship || 'Not Provided',
      }
    })

    const mappedMoreData = response.data.records.map(item => {
      const {
        skill_expertise,
        id,
        job_preference,
        certifications,
        industry_name,
        attempted_tests,
        created_at,
      } = item?.attributes
      return {
        ID: id,
        Industry: industry_name || 'Not Provided',
        Skill: skill_expertise?.map(category => category.title).join(', ') || 'Not Provided',
        Expertises:
          skill_expertise
            ?.flatMap(skill => skill?.selected_expertise?.flat())
            ?.join(', ') || 'No Expertises Selected',
        'Salary Expectations': job_preference?.salary_range || 'Not Provided',
        Certifications: certifications || [],
        'Quali Tests':
          attempted_tests.map(test => ({
            Title: test.title,
            'Test Score': test.test_marks,
            'Completion Date': formatDateToUTCTime(test.completion_date),
          })) || [],
        'Registeration Date': formatDateToUTCTime(created_at),
      }
    })

    setMoreDetails(mappedMoreData)
    setCandidates(mappedData || [])
    setTotalPages(response?.data?.pagy?.total_pages)
    setTotalCount(response?.data?.pagy?.total_count)
  }

  useEffect(() => {
    getCandidates()
  }, [currentPage, query])

  const onSearchCandidates = (search, value) => {
    setCurrentPage(1)
    setQuery(value)
  }

  const columns = generateColumns(candidates)

  return (
    <div className="flex h-screen w-full flex-col bg-[#f9fafb]">
      <AdminNavbar selectedItem="candidates" />
      <Table
        columns={columns}
        data={candidates}
        onSearch={onSearchCandidates}
        title={'Candidate'}
        getCandidates={getCandidates}
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        setCurrentPage={setCurrentPage}
        hasMoreDetails
        moreDetails={moreDetails}
        downloadCSV={true}
      />
    </div>
  )
}

export default CandidatesList
