import React from 'react'
const TestCardButton = ({ test, isOverview, handleCompleteTest, isCandidate }) => {
  const buttonClasses = isOverview
    ? 'bg-[#1A56DB] hover:bg-primary-700 text-white'
    : 'bg-[#1A56DB] text-white'
  return (
    <>
      {isCandidate && <button
        className={`block w-full mt-4 font-bold py-2 px-4 rounded-lg ${buttonClasses}`}
        onClick={() => handleCompleteTest(test.test_link, { id: test.id, test_status: 'progress' })}
      >
        {isOverview
          ? test.status === 'sent'
            ? 'Complete Test'
            : 'View Results'
          : 'View'}
      </button>
      }
      {!isCandidate && <button
        className={`block w-full mt-4 font-bold py-2 px-4 rounded-lg ${buttonClasses}`}
      >
        View
      </button>}
    </>
  );
};

export default TestCardButton;
