import * as Yup from 'yup'

export const contactCandidateSchema = Yup.object({
  jobTitle: Yup.string().max(50, 'Job Title cannot exceed 50 characters').required('Job Title is required.'),
  jobDescription: Yup.string()
    .max(500, 'Description cannot exceed 500 characters')
    .required('Job Description is required.'),
  contactMethod: Yup.string()
    .oneOf(['email', 'whatsapp'], 'Please select one contact method.')
    .required('Please select one contact method.'),
})
