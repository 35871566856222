import { Modal } from 'flowbite-react'
import React, { useEffect } from 'react'
import rocketImage from '../../../assets/rocket.gif'
import crossIcon from 'assets/svgs/cross.svg'

export function CongratulationsModal({
  openModal,
  setOpenModal,
  isOfferModal,
}) {
  useEffect(() => {
    if (openModal) {
      const timer = setTimeout(() => {
        setOpenModal(false)
      }, 1500)
      return () => clearTimeout(timer)
    }
  }, [openModal])

  return (
    <>
      <Modal
        show={openModal}
        onClose={() => setOpenModal(false)}
        className="font-satoshi"
        dismissible={true}
      >
        <div className="relative">
          <button
            onClick={() => setOpenModal(false)}
            className="absolute top-5 right-8 text-gray-400 hover:text-gray-600 focus:outline-none"
          >
            <img
              src={crossIcon}
              alt="Close"
              className="cursor-pointer h-[24px] w-[24px]"
            />
          </button>
          <Modal.Body>
            <div className="flex flex-col justify-center items-center gap-9 self-stretch my-9">
              <img src={rocketImage} alt="Badge" className="w-48 h-48" />

              <div className="flex flex-col items-center space-y-2">
                <h2 className="font-satoshi mt-3 text-gray-900 leading-tight text-2xl font-extrabold text-center">
                  Congratulations on behalf of Quali team!
                </h2>
                <p className="font-satoshi text-gray-500 text-center text-base font-normal leading-6">
                  {isOfferModal
                    ? 'We are now sending this offer to candidate and waiting their reply.'
                    : 'We are thrilled they joined your team through us.'}
                </p>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  )
}
