import React from 'react';

const CustomToggleSwitch = ({ checked, onClick, disabled }) => {
  return (
    <div
      onClick={!disabled ? onClick : null}
      className={`relative inline-flex items-center h-6 w-11 rounded-full cursor-pointer transition-colors duration-200 
      ${checked ? (disabled ? 'bg-gray-400' : 'bg-[#1A56DB]') : 'bg-gray-200'}
      ${disabled && 'opacity-50 cursor-not-allowed'}`}
    >
      <span
        className={`inline-block h-5 w-5 transform rounded-full bg-white shadow transition-transform duration-200 
        ${checked ? 'translate-x-5' : 'translate-x-1'}
        ${disabled && 'bg-gray-300'}`}
      />
    </div>
  )
};

export default CustomToggleSwitch;
