import React from 'react'
import { ReactComponent as FemaleCandidate } from '../../../assets/svgs/candidate_female.svg'
import { ReactComponent as MaleCandidate } from '../../../assets/svgs/candidate_male.svg'
import { useNavigate } from 'react-router-dom'

export const CandidateEmptyDashboard = ({isOverviewPage}) => {
  const navigate = useNavigate()

  return (
    <div className='flex flex-col items-center md:mt-12 mb-20'>
      {isOverviewPage &&
      <h1 className='text-gray-900 font-bold text-center text-24 leading-8 mb-2'>Welcome to Quali!</h1>
      }
      <div className='flex'>
        <MaleCandidate className='md:block hidden'/>
        <FemaleCandidate className='-ml-[29px]' />
      </div>
      <p className="font-bold md:text-20 text-14 md:px-0 px-2 leading-9 text-center text-gray-600">
        Forget traditional job hunting; let your ideal job find you.
        <br />
        <span className="font-medium leading-9 text-center">
          Strenghten your profile, showcase your skills, and get noticed by companies on Quali!
        </span>
      </p>
      <button
        className="md:w-[270px] md:h-[51px] block mt-11 font-bold py-2 px-4 rounded-lg text-12 bg-[#1A56DB] text-white hover:bg-blue-600"
        onClick={() => navigate('/candidate-account-overview')}
      >
        <span className='md:text-20 font-medium leading-7 text-left'>
          Review my Quali Profile
        </span>
      </button>
    </div>
  )
}
