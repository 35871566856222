import React, { useEffect, useState } from 'react'
import AppBar from '../AppBar'
import { TopHeader } from '../../Search/TopHeader'
import { AppSideBar } from '../AppSideBar'
import {Candidatesoftests} from '../../../services/candidateService'
import { Breadcrumb } from '../../../../src/utilis/helpers/breadcrumbHelper'
import TestOverview from '../../../components/Dashboard/Overview/TestOverview'
import '../../../assets/css/cborder.css'
import { useLocation } from 'react-router-dom'
import Table from 'components/table/Table'
import { generateColumns } from '../../../utilis/helpers/tableHelper'
import { isMobileDevice } from 'utilis/helpers'
import { Loader } from '../../../utilis/AppLoader'

const CandidateListofTest = () => {
  const { state } = useLocation()
  const { Testname = '', _pathname = '', TestId } = state || {}
  const isMobile = isMobileDevice()
  const [data, setData] = useState({})
  const [candidates, setCandidates] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortState, setSortState] = useState(data?.sortState || '')
  const [sortBy, setSortBy] = useState(data?.sortBy || '')
  const [order, setOrder] = useState(data?.order || '')
  const [query, setQuery] = useState(data?.query || '')
  const [isLoading, setIsLoading] = useState(true)

  const getTestData = async () => {
      setIsLoading(true)
      const response = await Candidatesoftests(TestId, query, sortState, sortBy, order,currentPage)
      if (response.status === 200) {
          const { records = [], pagy = {} } = response?.data
          const mappedData = records.map(({ attributes = {} }) => {
            const { candidate_name, overall_score, sent_date, status } = attributes
            return {
              'Candidate Name': candidate_name,
              [isMobile ? 'Score' : 'Overall Score']: overall_score,
              'Sent Date': sent_date,
              'Status': status
            }
          })
          setData(response?.data)
          setCandidates(mappedData)
          setTotalPages(pagy.total_pages || 1)
          setTotalCount(pagy.total_count || 0)
      } else {
          console.error('Error fetching test data:', response)
      }
      setIsLoading(false)
  }

  const handleSort = (order, column) => {
    if (column === 'Overall Score' ||column === 'Score') {
      setSortBy("test_score")
    }
    if (column === 'Sent Date') {
      setSortBy("sent_date")
    }
    setOrder(order)
  }

  useEffect(() => {
    getTestData()
  }, [currentPage, query, sortState, TestId, order,sortBy])
  const breadcrumbItems = ['Test and Assessment', _pathname === '/all-quali-tests' ? '' : '', Testname].filter(Boolean)
  const columns = generateColumns(candidates)

  return (
    <div className="h-full bg-[#f9fafb] font-satoshi-regular">
      <AppBar />
      <div className="flex flex-col md:flex-row p-2 md:p-10">
        <div className="hidden md:block">
          <AppSideBar />
        </div>
        <div className="flex flex-col w-full sm:w-[65%] sm:pl-4">
          <TopHeader />
          <div className="border-1.5 border-gray-400 rounded-lg bg-white mt-5 mr-4 shadow-md">
            <div className="mt-5 ml-6">
              <Breadcrumb items={breadcrumbItems} />
            </div>
            <TestOverview data={data} Testname={Testname}/>
          </div>
            <div className="mb-10">
              <Table
              columns={columns}
              onSort={setSortState}
              data={candidates}
              onSearch={setQuery}
              title="Candidate"
              getCandidates={getTestData}
              currentPage={currentPage}
              totalPages={totalPages}
              totalCount={totalCount}
              setCurrentPage={setCurrentPage}
              handleSort={handleSort}
              hideEditButton
              hideDeleteButton
              hideCreateButton
              isLoading={isLoading}
              />
            </div>
        </div>
      </div>
    </div>
  )
}

export default CandidateListofTest
