import {
  HiCheckCircle,
  HiExclamationCircle,
  HiExclamation,
  HiOutlineDocumentText,
} from 'react-icons/hi'

const ToastMessageIcon = ({ type }) => {
  const toastTypes = {
    success: {
      icon: <HiCheckCircle />,
      toastColor: 'green',
    },
    error: {
      icon: <HiExclamationCircle />,
      toastColor: 'red',
    },
    warning: {
      icon: <HiExclamation />,
      toastColor: 'orange',
    },
    generic: {
      icon: <HiOutlineDocumentText />,
      toastColor: 'slate',
    },
  }

  const { icon, toastColor } = toastTypes[type] || toastTypes.generic

  return (
    <div
      className={`inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-${toastColor}-100 text-${toastColor}-500 dark:bg-${toastColor}-700 dark:text-${toastColor}-200`}
    >
      {icon}
    </div>
  )
}

export default ToastMessageIcon
