import React, { useEffect, useState } from 'react'
import AppBar from '../Dashboard/AppBar'
import { TopHeader } from './TopHeader'
import { AppSideBar } from '../Dashboard/AppSideBar'
import '../../assets/css/cborder.css'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Table from 'components/table/CandidatePipelineTable'
import { generateColumns } from '../../utilis/helpers/tableHelper'
import {
  selectTalentSearchData,
  setTalentSearchData,
} from '../../redux/talentSearchSlice'
import { searchCandidates } from '../../services/candidateService'
import PipelineCandidateProfile from './modals/PipelineCandidateProfile'
import Comparison from 'pages/Pipeline/CandidateComparison'
import { PersistentInbox } from 'components/Messaging/PersistentMinimizableInbox'
const SearchedCandidateList = () => {
  const { state } = useLocation()
  const dispatch = useDispatch()
  const talentSearch = useSelector(selectTalentSearchData)
  const [totalPages, setTotalPages] = useState(state?.pageCount)
  const [totalCount, setTotalCount] = useState(state?.totalCount)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortBy, setSortBy] = useState('')
  const [order, setOrder] = useState('')
  const [query, setQuery] = useState('')
  const [filterStatus, setFilterStatus] = useState('none')
  const [isLoading, setIsLoading] = useState(true)
  const [openDetailModal, setOpenDetailModal] = useState(false)
  const [candidate, setCandidate] = useState()
  const searchName = state?.searchName || ''
  const [candidateStatusUpdated, setCandidateStatusUpdate] = useState(false)
  const [searchId, setSearchId] = useState(0)
  const [comparisonData, setComparisonData] = useState([])
  const [candidateComparison, SetCandidateComparison] = useState(false)

  const handleFilterChange = value => {
    setFilterStatus(value)
    setCurrentPage(1)
  }

  const fetchCandidates = async () => {
    setIsLoading(true)
    const queryParams = new URLSearchParams(state?.queryParams || '')
    const SearchIdToArchive = new URLSearchParams(queryParams.toString())
    setSearchId(SearchIdToArchive.get('search_id'))
    const params = {
      sort_by: sortBy,
      sort_order: order,
      search_query: query,
      profile_status: filterStatus,
      page: currentPage,
      per_page: 10,
    }

    Object.entries(params).forEach(([key, value]) => {
      if (value) queryParams.set(key, value)
    })

    const response = await searchCandidates(queryParams.toString())

    if (response.status === 200) {
      const { data } = response
      dispatch(setTalentSearchData(data.results || {}))
      setTotalPages(data.pagy?.total_pages || 1)
      setTotalCount(data.pagy?.total_count || 0)
    } else {
      console.error('Error fetching candidates:', response.statusText)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetchCandidates()
  }, [
    state?.queryParams,
    sortBy,
    order,
    query,
    currentPage,
    filterStatus,
    candidateStatusUpdated,
  ])
  const mappedData =
    talentSearch?.searchData?.search_results?.map(item => {
      const {
        user: { first_name, last_name, id },
        expertise_match_score,
        job_preference_match_score,
        user: { latest_hired_passed },
        profile_status,
      } = item
      return {
        pipeline_id: id,
        candidate_name: `${first_name} ${last_name}`,
        'match %': `${expertise_match_score}%`,
        'preference %': `${job_preference_match_score}%`,
        from_search: searchName,
        status: profile_status,
      }
    }) || []
  const columnMap = {
    'preference %': 'job_preference_match_score',
    'match %': 'expertise_match_score',
    status: 'profile_status',
  }
  const handleSort = (order, column) => {
    const sortby = columnMap[column] || column
    setSortBy(sortby)
    setOrder(order)
  }
  const columns = generateColumns(mappedData, searchName)

  const handleAction = (disableButton, selectedRows) => {
    if (!disableButton) {
      const selectedPipelineIds = selectedRows?.map(item => item.pipeline_id)
      const data = talentSearch?.searchData?.search_results?.filter(item =>
        selectedPipelineIds?.includes(item?.user?.id),
      )
      setComparisonData(data)
      SetCandidateComparison(true)
      console.log('Action triggered for selected rows:', data)
    }
  }

  const handleViewProgress = id => {
    const selectedCandidate = talentSearch?.searchData?.search_results?.find(
      candidate => candidate.user.id === id,
    )
    setCandidate(selectedCandidate)
    setOpenDetailModal(true)
  }

  return (
    <div className="h-full bg-[#f9fafb] font-satoshi-regular">
      <AppBar />
      <div className="flex flex-col md:flex-row p-2 md:p-10">
        <div className="hidden md:block">
          <AppSideBar />
        </div>
        <div className="flex flex-col w-full sm:w-[80%] sm:pl-4">
          <TopHeader />
          {candidateComparison ? (
            <Comparison
              candidates={comparisonData}
              SetCandidateComparison={SetCandidateComparison}
              handleViewProgress={handleViewProgress}
            />
          ) : (
            <div className="mb-10">
              <Table
                columns={columns}
                data={mappedData}
                onSearch={setQuery}
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                setCurrentPage={setCurrentPage}
                searchName={searchName}
                handleSort={handleSort}
                onFilterChange={handleFilterChange}
                sortState={filterStatus}
                hideEditButton
                hideDeleteButton
                hideCreateButton
                handleCompareAction={handleAction}
                isLoading={isLoading}
                handleViewProgress={handleViewProgress}
              />
            </div>
          )}
          {openDetailModal && candidate && (
            <PipelineCandidateProfile
              openModal={openDetailModal}
              setOpenModal={setOpenDetailModal}
              candidate={candidate}
              setCandidateStatusUpdate={setCandidateStatusUpdate}
              searchId={searchId}
            />
          )}
        </div>
      </div>
      <PersistentInbox />
    </div>
  )
}

export default SearchedCandidateList
