import axiosInstance from './axiosConfig'
import { AuthEndPoints } from './constants'

export const registerUser = async body => {
  const response = await axiosInstance.post(`${AuthEndPoints.createUser}`, {
    user: body,
  })
  localStorage.setItem('accessToken', response.headers.authorization)
  return response.data.data
}
