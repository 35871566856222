import React from 'react'
import { CandidateMessagingList } from 'components/Messaging/CandidateMessaging/CandidateMessagingList/CandidateMessagingList'

export const EmployerMessagingList = ({
  currentUser,
  avatar,
  chatListing,
  setSelectedChat,
  selectedChat,
  setSearchId

}) => {
  return (
    <CandidateMessagingList
      currentUser={currentUser}
      avatar={avatar}
      chatListing={chatListing}
      setSelectedChat={setSelectedChat}
      selectedChat={selectedChat}
      setSearchId={setSearchId}
      
    />
  )
}
