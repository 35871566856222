import axiosInstance from './axiosConfig'
import { CandidatesEndpoints } from './constants'

export const searchCandidates = async (queryParams, page = 1, savedOrRevealed = '', sort_by = '', sort_order = 'desc') => {
  const response = await axiosInstance.post(
    `${CandidatesEndpoints.searchCandidates}?${queryParams}`,
    {
      page,
      savedOrRevealed,
      sort_by,
      sort_order,
    },
  )
  return response
}

export const filterCandidates = async queryParams => {
  const response = await axiosInstance.get(
    `${CandidatesEndpoints.filterCandidates}?${queryParams}`,
  )
  return response
}
export const Candidatesoftests = async (TestId, query, sortState, sortBy, orderBy, currpage) => {
  const effectiveOrderBy = sortBy && !orderBy ? 'desc' : orderBy;
  const queryParams = [
    `q=${query}`,
    sortState ? `test_status=${sortState}` : '',
    sortBy ? `sort_by=${sortBy}` : '',
    (sortBy && effectiveOrderBy) ? `order_by=${effectiveOrderBy}` : '',
    currpage ? `page=${currpage}` : '' 
  ].filter(Boolean).join('&');

  return await axiosInstance.get(`/api/v1/tests/${TestId}/test_candidates?${queryParams}`);
}

export const getInitialFilters = async search_history_id => {
  const response = await axiosInstance.get(
    `/api/v1/search_filters?search_history_id=${search_history_id}`,
  )
  return response
}

export const sendHiredEmailToCandidate = async data => {
  const response = await axiosInstance.post(
    CandidatesEndpoints.sendHiredEmailToCandidate,
    { revelation: data },
  )
  return response
}

export const deleteFilter = async search_history_id => {
  const response = await axiosInstance.delete(
    `/api/v1/searches/${search_history_id}/delete_search_filter`,
  )
  return response
}
