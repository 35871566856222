import React from 'react';

const UploadFileIcon = ({ color = 'white', width = 13, height = 12, className = '', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13 12"
      fill="none"
      className={className}
      {...props}
    >
      <g clipPath="url(#clip0_10148_1944)">
        <path
          d="M9.3242 2.64222L6.9242 0.180785C6.86847 0.123479 6.80225 0.0780124 6.72936 0.0469903C6.65647 0.0159683 6.57832 0 6.4994 0C6.42048 0 6.34233 0.0159683 6.26944 0.0469903C6.19655 0.0780124 6.13034 0.123479 6.0746 0.180785L3.6746 2.64222C3.56209 2.75777 3.49895 2.91442 3.49907 3.07772C3.49918 3.24101 3.56254 3.39757 3.6752 3.51296C3.78786 3.62834 3.94061 3.6931 4.09982 3.69299C4.25904 3.69287 4.4117 3.62789 4.5242 3.51234L5.9 2.10132V7.3848C5.9 7.54801 5.96321 7.70452 6.07574 7.81993C6.18826 7.93533 6.34087 8.00016 6.5 8.00016C6.65913 8.00016 6.81174 7.93533 6.92426 7.81993C7.03679 7.70452 7.1 7.54801 7.1 7.3848V2.10132L8.4758 3.51234C8.58896 3.62444 8.74052 3.68646 8.89784 3.68506C9.05516 3.68366 9.20565 3.61894 9.31689 3.50485C9.42814 3.39075 9.49124 3.23641 9.49261 3.07507C9.49397 2.91372 9.4335 2.75828 9.3242 2.64222Z"
          fill={color}
        />
        <path
          d="M11.3 7.07712H8.3V7.3848C8.3 7.87441 8.11036 8.34397 7.77279 8.69018C7.43523 9.03638 6.97739 9.23088 6.5 9.23088C6.02261 9.23088 5.56477 9.03638 5.22721 8.69018C4.88964 8.34397 4.7 7.87441 4.7 7.3848V7.07712H1.7C1.38174 7.07712 1.07652 7.20679 0.851472 7.43759C0.626428 7.6684 0.5 7.98143 0.5 8.30784V10.7693C0.5 11.0957 0.626428 11.4087 0.851472 11.6395C1.07652 11.8703 1.38174 12 1.7 12H11.3C11.6183 12 11.9235 11.8703 12.1485 11.6395C12.3736 11.4087 12.5 11.0957 12.5 10.7693V8.30784C12.5 7.98143 12.3736 7.6684 12.1485 7.43759C11.9235 7.20679 11.6183 7.07712 11.3 7.07712ZM9.8 10.7693C9.622 10.7693 9.44799 10.7151 9.29999 10.6137C9.15198 10.5123 9.03663 10.3681 8.96851 10.1995C8.90039 10.0308 8.88257 9.84522 8.91729 9.66617C8.95202 9.48711 9.03774 9.32264 9.1636 9.19355C9.28947 9.06446 9.44984 8.97655 9.62442 8.94094C9.799 8.90532 9.97996 8.9236 10.1444 8.99346C10.3089 9.06333 10.4494 9.18163 10.5483 9.33343C10.6472 9.48522 10.7 9.66368 10.7 9.84624C10.7 10.091 10.6052 10.3258 10.4364 10.4989C10.2676 10.672 10.0387 10.7693 9.8 10.7693Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_10148_1944">
          <rect width="12" height="12" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default UploadFileIcon;
