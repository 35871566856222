import React from 'react'
import { AttachementText } from './AttachementText'
import { InitialLetters } from 'components/generics/Initials'

export const SelfChatTextBubble = ({
  name,
  time,
  messageText,
  userAvatar,
  attachment = null,
}) => {
  return (
    <div className="flex flex-row justify-end gap-1 pl-20">
      <div className="flex flex-col gap-2.5 items-end">
        <div className="flex flex-row gap-1.5">
          <span className="text-[#111928] text-14 font-medium">
            {name} (You)
          </span>
          <span className="text-[#6B7280] text-14 font-medium">{time}</span>
        </div>
        {attachment && (
          <div className="p-4 bg-[#F3F4F6] rounded-tl-[20px] rounded-tr-[0px] rounded-bl-[20px] rounded-br-[20px] mb-2">
            <AttachementText
              fileName={attachment.filename}
              totalPages={attachment.page_count}
              fileSize={`${attachment.file_size} MB`}
              fileType={attachment.filename.split('.').pop().toUpperCase()}
              attachmentUrl={attachment.url}
            />
          </div>
        )}
        {messageText && (
          <div className="p-4 pr-10 bg-[#F3F4F6] rounded-tl-[20px] rounded-tr-[0px] rounded-bl-[20px] rounded-br-[20px]">
            <span className="text-[#111928] text-sm font-normal">
              {messageText}
            </span>
          </div>
        )}
      </div>
      {userAvatar ? (
          <img
            src={userAvatar}
            alt="User Avatar"
            className="rounded-full w-8 h-8 md:w-8 md:h-8"
          />
        ) : (
          <div className="flex justify-center items-center p-5 rounded-full text-lg bg-gray-100 border-gray-100 h-8 w-8 md:h-8 md:w-8">
            <InitialLetters
              Name={name}
            />
          </div>
      )}
    </div>
  )
}
