import React, { createContext, useState } from 'react'

const UserContext = createContext()

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState(null)
    const [companyData, setCompanyData] = useState(null)

    return (
        <UserContext.Provider
            value={{ userData, setUserData, companyData, setCompanyData }}
        >
            {children}
        </UserContext.Provider>
    )
}

export default UserContext
