import * as Yup from 'yup'
import { COUNTRY_CITY } from '../data/registerFormData'
import { NAME_REGEX, PASSWORD_REGEX, PHONE_REGEX } from './constants'

const validGccCountries = [
  'none',
  'Saudi Arabia',
  'United Arab Emirates',
  'Kuwait',
  'Bahrain',
  'Oman',
  'Qatar',
  'all',
]

export const candidatePersonalDetailsSchema = Yup.object().shape({
  citizenship: Yup.string()
    .oneOf(Object.keys(COUNTRY_CITY), 'Invalid citizenship selected')
    .required('Citizenship is required'),
  validGccVisa: Yup.array()
    .of(
      Yup.string().oneOf(
        validGccCountries,
        'Please select a valid GCC Visa status',
      ),
    )
    .min(1, 'Please select at least one GCC country with a valid visa')
    .required('Please select a valid GCC Visa status'),
  dateOfBirth: Yup.date().nullable().required('Date of Birth is required'),
  workCountries: Yup.array()
    .of(Yup.string().oneOf(validGccCountries, 'Invalid country selected'))
    .min(1, 'Please select at least one country')
    .required('Please select countries you are open to work in'),
})

export const candidateExperienceQualificationSchema = Yup.object().shape({
  industry: Yup.string().required('Industry is required'),
  yearsOfExperience: Yup.string().required('Years of experience is required'),
  skillExpertise: Yup.array()
    .of(
      Yup.object().shape({
        skills: Yup.array()
          .min(1, 'Please select at least one skill')
          .required('At least one skill is required'),
        expertises: Yup.object()
          .test(
            'expertises',
            'Please select expertise for each selected skill',
            function (value) {
              const skills = this.parent.skills
              return skills.every(
                skillId => value && value[skillId] && value[skillId].length > 0,
              )
            },
          )
          .required('Expertise is required for selected skills'),
      }),
    )
    .min(1, 'Please select at least one skill with its expertise')
    .required(),
  workExperience: Yup.array().of(
    Yup.object().shape({
      workHistoryId: Yup.string().nullable(),
      currentWorking: Yup.string().required(
        'Current working status is required',
      ),
      jobRole: Yup.string().required('Role or Title is required'),
      companyName: Yup.string().required('Company Name is required'),
      jobDescription: Yup.string()
        .max(2000, 'Description cannot exceed 2000 characters')
        .nullable()
        .notRequired(),
      jobJoinDate: Yup.date().required('Join date is required'),
      jobEndDate: Yup.date().nullable(),
      jobCountry: Yup.string().required('Country is required'),
      jobCity: Yup.string().required('City is required'),
    }),
  ),
  certifications: Yup.array().of(
    Yup.object().shape({
      certificateId: Yup.string().nullable(),
      documentType: Yup.string().required('Document type is required'),
      documentTitle: Yup.string().required('Document title is required'),
      issuedBy: Yup.string().required('Issuing organization is required'),
      dateOfIssue: Yup.date().required('Date of issue is required'),
      file: Yup.mixed()
        .nullable()
        .test('fileType', 'Only PDF files are allowed', value => {
          if (
            value === null ||
            value === undefined ||
            (typeof value === 'string' &&
              (value.startsWith('http://') || value.startsWith('https://')))
          )
            return true
          return value && value.type === 'application/pdf'
        })
        .test('fileSize', 'File size is too large', value => {
          if (
            value === null ||
            value === undefined ||
            (typeof value === 'string' &&
              (value.startsWith('http://') || value.startsWith('https://')))
          )
            return true
          const maxSizeInBytes = 5 * 1024 * 1024
          return value ? value.size <= maxSizeInBytes : true
        }),
    }),
  ),
})

export const PreferenceValidationSchema = Yup.object({
  languages: Yup.array()
    .required('Languages are required')
    .min(1, 'Please select at least one language'),
  salaryRange: Yup.string().required('Salary Range is required'),
  officialCertifications: Yup.string(),
  accommodation: Yup.string().required('Accommodation is required'),
  transportation: Yup.string().required('Transportation is required'),
  flight: Yup.string().required('Flight is required'),
})

export const candidateValidationSchema = Yup.object().shape({
  id: Yup.number().nullable(),
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  phone_no: Yup.string()
    .matches(PHONE_REGEX, 'Invalid contact number format')
    .required('Contact number is required'),
  first_name: Yup.string()
    .required('First name is required')
    .min(2, 'First name must consist of at least 2 characters')
    .max(50, 'First name must not exceed 50 characters')
    .test(
      'isValidFirstName',
      'Invalid name',
      value => !value || NAME_REGEX.test(value.trim()),
    ),
  last_name: Yup.string()
    .required('Last name is required')
    .min(2, 'Last name must consist of at least 2 characters')
    .max(50, 'Last name must not exceed 50 characters')
    .test(
      'isValidLastName',
      'Invalid name',
      value => !value || NAME_REGEX.test(value.trim()),
    ),
  password: Yup.string()
    .matches(PASSWORD_REGEX, 'Password must match the guidelines')
    .required('Password is required'),
  country_of_residence: Yup.string().required('Country is required'),
})

export const CandidateWorkExperienceValidationSchema = Yup.object({
  workHistoryId: Yup.string().nullable(),
  currentWorking: Yup.string().required('Current working status is required'),
  jobRole: Yup.string().required('Role or Title is required'),
  companyName: Yup.string().required('Company Name is required'),
  jobDescription: Yup.string()
    .max(2000, 'Description cannot exceed 2000 characters')
    .nullable()
    .notRequired(),
  jobJoinDate: Yup.date().required('Join date is required'),
  jobEndDate: Yup.date().nullable(),
  jobCountry: Yup.string().required('Country is required'),
  jobCity: Yup.string().required('City is required'),
})

export const CandidateCertificationsValidationSchema = Yup.object({
  certificateId: Yup.string().nullable(),
  documentType: Yup.string().required('Document type is required'),
  documentTitle: Yup.string().required('Document title is required'),
  issuedBy: Yup.string().required('Issuing organization is required'),
  dateOfIssue: Yup.date().required('Date of issue is required'),
  file: Yup.mixed()
    .required('Certification document is required')
    .test('fileType', 'Only PDF files are allowed', value => {
      if (typeof value === 'string') {
        return true
      }
      return value ? value.type === 'application/pdf' : true
    })
    .test('fileSize', 'File size is too large', value => {
      if (typeof value === 'string') {
        return true
      }
      const maxSizeInBytes = 5 * 1024 * 1024
      return value ? value.size <= maxSizeInBytes : true
    }),
})
