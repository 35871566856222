import React, { useEffect, useState } from 'react'
import { EmployerMessagingList } from '../EmployerMessagingList/EmployerMessagingList'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentUser,
  selectCompany,
} from '../../../../redux/currentUserSlice'
import { setProfileStatus, getProfileStatus } from 'services/userService'
import { EmployerOpenMessageView } from '../EmployerOpenMessageView/EmployerOpenMessageView'
import { EmployerMessageCandidateInfo } from '../EmployerMessageCandidateInfo/EmployerMessageCandidateInfo'
import { selectRevealedCandidates } from '../../../../redux/talentSearchSlice'
import {
  deleteConversation,
  getConversation,
  fetchPreviousConversation,
} from 'services/messagingService'
import { addMessage } from '../../../../redux/messageSlice'
export const EmployerInboxView = ({ defaultId, searchId, setSearchId }) => {
  const [candidateStatusUpdated, setCandidateStatusUpdate] = useState(false)
  const chatListing = useSelector(selectRevealedCandidates)
  const specificChat = chatListing.find(
    item => item?.user.id === parseInt(defaultId),
  )
  const [selectedChat, setSelectedChat] = useState(specificChat || null)
  const [sortedMessages, setSortedMessages] = useState(null)
  const [updateflag, setupdateflag] = useState(false)
  const [profileStatus, setStatus] = useState(
    selectedChat?.profile_status || null,
  )
  const [selectedConversation, setSelectedConversation] = useState([])
  const { currentUser } = useSelector(selectCurrentUser)
  const company = useSelector(selectCompany)

  const fetchStatus = async searchParam => {
    try {
      const data = await getProfileStatus(
        selectedChat?.user?.id,
        currentUser?.company?.id,
        searchId || selectedChat?.search_history?.id,
      )
      setStatus(data.status)
    } catch (err) {
      //err
    }
  }
  const handleSetstatus = async status => {
    try {
      const data = {
        profile_status: status,
        user_id: selectedChat?.user?.id,
        company_id: currentUser?.company?.id,
        profile_feedback: '',
        user_name: `${selectedChat?.user?.first_name} ${selectedChat?.user?.last_name}`,
        company_name: currentUser?.company?.name,
        search_history_id: searchId || selectedChat?.search_history?.id,
      }

      const response = await setProfileStatus(data)
      if (response.status === 200) {
        setCandidateStatusUpdate(true)
        setStatus(status)
      }
    } catch (error) {
      console.error('Error hiring candidate', error)
    }
  }
  const dispatch = useDispatch()
  const handleDeleteConversation = async () => {
    try {
      const userId = defaultId ? parseInt(defaultId) : selectedChat?.user?.id
      await deleteConversation(userId)
      dispatch(addMessage([]))
      setSelectedConversation([])
    } catch (error) {}
  }
  const getMessages = async () => {
    try {
      const response = await fetchPreviousConversation(
        defaultId || selectedChat?.user?.id,
      )
      if (response?.status === 200) {
        dispatch(addMessage(response?.data))
      }
    } catch (error) {
      dispatch(addMessage([]))
      setSortedMessages([])
      setSelectedConversation?.([])
    }
  }
  useEffect(() => {
    defaultId &&
      setSelectedChat(
        chatListing.find(item => item?.user?.id === parseInt(defaultId)),
      )
    getMessages()
  }, [defaultId, updateflag])

  return (
    <div className="w-full mr-3 pb-4">
      <div className={` flex flex-col md:flex-row gap-1 mx-4 pt-2`}>
        <EmployerMessagingList
          currentUser={currentUser}
          chatListing={chatListing}
          avatar={company.logo_url}
          setSelectedChat={setSelectedChat}
          setSearchId={setSearchId}
          selectedChat={selectedChat}
        />
        {selectedChat && (
          <>
            <EmployerOpenMessageView
              avatar={company.logo_url}
              chatName={company.name}
              selectedChat={selectedChat}
              selectedConversation={selectedConversation}
              setSelectedConversation={setSelectedConversation}
              currentUser={currentUser}
              setCandidateStatusUpdate={setCandidateStatusUpdate}
              handleSetstatus={handleSetstatus}
            />
            <EmployerMessageCandidateInfo
              candidate={selectedChat}
              yearsOfExperience={selectedChat?.user?.years_of_experience}
              country={selectedChat?.user?.citizenship}
              profilePicture={selectedChat?.user?.profile_picture_url}
              selectedConversation={selectedConversation}
              handleDeleteConversation={handleDeleteConversation}
              selectedChat={selectedChat}
              candidateStatusUpdated={candidateStatusUpdated}
              setCandidateStatusUpdate={setCandidateStatusUpdate}
              fetchStatus={fetchStatus}
              handleSetstatus={handleSetstatus}
              profileStatus={profileStatus}
              searchId={searchId || selectedChat?.search_history?.id}
            />
          </>
        )}
      </div>
    </div>
  )
}
