import React, { useState } from 'react'
import { Card, Button, Radio, Modal } from 'flowbite-react'
import { Link, useLocation } from 'react-router-dom'
import * as yup from 'yup'
import { registerSchema } from '../../validations/registerValidationSchema'
import '../../assets/css/forgot-password.css'
import { Label, TextInput } from 'flowbite-react'
import OTPConfirmation from './OTPConfirmation'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { auth } from '../../firebaseConfig'
import { getUserIdByPhoneNumberOrEmail } from '../../services/userService'
import { generateOtpForEmail } from '../../services/userService'
import useToastMessage from 'hooks/useToastMessage'
import { LOGIN_URLS } from 'config/constants'

function ForgotPasswordForm(props) {
  const [registerData, setRegisterData] = useState({
    email: '',
    contactNumber: '',
  })
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const user = searchParams.get('user')

  const [openModal, setOpenModal] = useState(false)
  const [errors, setErrors] = useState({})
  const [confirmationResult, setConfirmationResult] = useState(false)
  const [selectedMode, setSelectedMode] = useState('')
  const [idUser, setIdUser] = useState('')
  const [resetWithEmail, setResetWithEmail] = useState(false)
  const toastMessage = useToastMessage()

  const sendOtp = async () => {
    try {
      if (selectedMode === 'phone_no' && registerData?.contactNumber) {
        errors.contactNumber
          ? await registerSchema.validate(registerData, { abortEarly: false })
          : setOpenModal(true)

        const user = await getUserIdByPhoneNumberOrEmail({
          user: {
            attribute: selectedMode,
            value: registerData.contactNumber
              ? registerData.contactNumber
              : registerData.email,
          },
        })

        if (user.data.id) {
          window.recaptchaVerifier = new RecaptchaVerifier(
            auth,
            'recaptcha-firebase',
            {
              size: 'invisible',
              callback: () => {
                console.log('recaptcha resolved..')
              },
            },
          )

          setIdUser(user.data.id)
          const confirmationResult = await signInWithPhoneNumber(
            auth,
            registerData.contactNumber,
            window.recaptchaVerifier,
          )
          setConfirmationResult(confirmationResult)
          alert('OTP sent successfully!')
        } else {
          alert("User with this Phone Number Doesn't Exist")
        }
      } else {
        alert('Please select any the option for Resetting the Password')
      }
    } catch (error) {
      if (error.inner) {
        alert("User with this Phone Number Doesn't Exist")
        const validationErrors = {}
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message
        })
        setErrors(validationErrors)
      } else {
        console.error('Error sending OTP:', error)
      }
    }
  }

  const handleSubmit = async () => {
    try {
      await registerSchema.validate(registerData, {
        abortEarly: false,
      })
    } catch (error) {
      if (error.inner) {
        const validationErrors = {}
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message
        })
        setErrors(validationErrors)
      } else {
        console.error('Error submitting form:', error)
      }
    }
  }

  const handleChange = event => {
    const { name, value } = event.target
    setRegisterData(prevState => ({ ...prevState, [name]: value }))
  }

  const handleChangeMode = option => {
    const { value } = option?.target
    setSelectedMode(value)
    onRadioSelection(value)
  }

  const onRadioSelection = value => {
    if (value === 'email') {
      setRegisterData(prevData => ({
        ...prevData,
        contactNumber: '',
      }))
    } else if (value === 'phone_no') {
      setRegisterData(prevData => ({
        ...prevData,
        email: '',
      }))
    }
    setErrors({})
  }

  const handleBlur = async event => {
    const { name, value } = event.target

    try {
      await yup.reach(registerSchema, name).validate(value)
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }))
    } catch (error) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: error.errors[0],
      }))
    }
  }

  const handleEmail = async (resent = false) => {
    try {
      if (registerData?.email) {
        resent && toastMessage('OTP resent successfully!', 'success')
        const response = await generateOtpForEmail(registerData?.email)
        if (response.status === 200) {
          setOpenModal(true)
          setResetWithEmail(true)
        }
      } else {
        toastMessage('Please enter your email and try again', 'error')
      }
    } catch (error) {
      toastMessage(error?.response?.data?.error, 'error')
      console.error('Error sending otp', error)
    }
  }

  const resendOtp = async () => {
    const appVerifier = window.recaptchaVerifier

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        registerData.contactNumber,
        appVerifier,
      )
      setConfirmationResult(confirmationResult)
      alert('OTP resent successfully!')
    } catch (error) {
      if (error.inner) {
        const validationErrors = {}
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message
        })
        setErrors(validationErrors)
      } else {
        console.error('Error resending OTP:', error)
      }
    }
  }

  return (
    <Card className="w-full sm:ml-20 ml-0 border sm:border-gray-200  sm:shadow-md sm:dark:border-gray-700 border-white sm:dark:bg-gray-800 mr-0 sm:mr-10 pt-0 sm:mt-0 sm:pt-2 sm:pb-7 pb-0 sm:px-5 px-0">
      <div id="recaptcha-firebase"></div>
      <p className="text-3xl font-semibold tracking-wide">
        Reset Quali Password
      </p>

      <p className="font-normal text-gray-600">
        Already Have an Account?
        <Link
          to={LOGIN_URLS[user]}
          className="px-1 text-blue-700"
        >
          Login here
        </Link>
      </p>

      <div className="flex flex-col md:flex-row justify-between gap-4">
        <div className="w-full">
          <div className="field-size w-full">
            <Radio
              id="emailOption"
              name="resetMode"
              value="email"
              onChange={handleChangeMode}
            />
            <Label htmlFor="resetMode" className="ml-1">
              By e-mail address
            </Label>
            <TextInput
              className="mt-1"
              id="email"
              type="email"
              name="email"
              value={registerData.email}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter your registered e-mail address"
              disabled={selectedMode !== 'email'}
            />

            {selectedMode === 'email' && errors?.email && (
              <p className="text-xs text-red-500">{errors?.email}</p>
            )}
          </div>

          <div className="field-size mt-6 w-full">
            <Radio
              id="contactNoOption"
              name="resetMode"
              value="phone_no"
              onChange={handleChangeMode}
              className="bg-gray-50 stroke-current text-blue-700 w-4 h-4 flex-shrink-0"
            />

            <Label htmlFor="resetMode" className="ml-1">
              By mobile number
            </Label>
            <TextInput
              id="contactNumber"
              className="mt-1"
              onChange={handleChange}
              name="contactNumber"
              value={registerData.contactNumber}
              onBlur={handleBlur}
              type="tel"
              placeholder="Enter your registered mobile number"
              disabled={selectedMode !== 'phone_no'}
            />

            {selectedMode === 'phone_no' && errors?.contactNumber && (
              <p className="text-xs text-red-500">{errors?.contactNumber}</p>
            )}
          </div>

          <Button
            type="submit"
            className="bg-blue-700 sm:mt-6 mt-6 xs:mt-28 py-2 text-lg w-full"
            onClick={() =>
              selectedMode === 'email' ? handleEmail() : sendOtp()
            }
          >
            Reset Password
          </Button>

          <Modal
            show={openModal}
            theme={{
              content: {
                base: 'bg-transparent w-3/4',
                inner: 'bg-transparent',
              },
            }}
          >
            <div className="flex items-center justify-center">
              <OTPConfirmation
                confirmationResult={confirmationResult}
                handleSubmit={handleSubmit}
                ForgotPasswordForm={true}
                userId={idUser}
                setOpenModal={setOpenModal}
                phoneNo={registerData.contactNumber}
                resetWithEmail={resetWithEmail}
                email={registerData?.email}
                handleEmail={handleEmail}
                resendOtp={resendOtp}
                user={user}
              />
            </div>
          </Modal>
        </div>
      </div>
    </Card>
  )
}

export default ForgotPasswordForm
