import axiosInstance from './axiosConfig'
import { RevealProfileEndPoint } from './constants'

export const revealProfile = async payload => {
  const response = await axiosInstance.post(
    RevealProfileEndPoint.revealProfile,
    { revelation: payload },
  )
  return response
}
