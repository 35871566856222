import React, { useEffect } from 'react'
import Intercom, { shutdown } from '@intercom/messenger-js-sdk'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/currentUserSlice'
import { getIntercomHashForUser } from 'services/userService'

export const IntercomChatBot = ({ children }) => {
  const { currentUser } = useSelector(selectCurrentUser)

  const initializeIntercom = async user => {
    if (user?.id) {
      try {
        const { id, email, first_name, last_name, created_at } = user
        const userName = `${first_name} ${last_name}`
        const response = await getIntercomHashForUser(id)
        const intercom_hash = response.data.intercom_hash

        Intercom({
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          user_id: id,
          name: userName,
          email: email,
          created_at: created_at,
          user_hash: intercom_hash,
        })
      } catch (error) {
        console.error('Error fetching Intercom hash:', error)
      }
    }
  }

  useEffect(() => {
    initializeIntercom(currentUser)

    return () => {
      if (currentUser) {
        shutdown()
      }
    }
  }, [currentUser])

  return <>{children}</>
}
