import React, { useEffect, useState } from 'react'
import CompanyProfileTab from './CompanyProfile'
import { COUNTRY_CITY } from 'data/registerFormData'
import { updateCompany } from 'lib/api'
import { useDispatch } from 'react-redux'
import { setCompany } from '../../../redux/currentUserSlice'
import Loader from 'components/generics/loader/Loader'
import { companyManageProfileSchema } from 'validations/CompanyProfileSchema'

const setCompanyData = companyData => ({
  companyName: companyData?.company?.name || '',
  country: companyData?.company?.country || '',
  city: companyData?.company?.city || '',
  mainIndustry: companyData?.company_industry?.id || '',
  companySize: companyData?.company?.company_size || '',
  companyDescription: companyData?.company?.description || '',
  companyWebsite: companyData?.company?.website_link || '',
  twitter: companyData?.company?.social_network_links?.twitter || '',
  facebook: companyData?.company?.social_network_links?.facebook || '',
  instagram: companyData?.company?.social_network_links?.instagram || '',
  logo_name: companyData?.company?.logo_name || '',
  logo_url: companyData?.company?.logo_url || '',
})

const ManageProfileTab = ({ companyData, activeTab }) => {
  const [profileData, setProfileData] = useState(setCompanyData(companyData))
  const [errors, setErrors] = useState({})
  const [dataChanged, setDataChanged] = useState(false)
  const [cities, setCities] = useState([])
  const [profilePicture, setProfilePicture] = useState('')
  const [loading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setCities(COUNTRY_CITY[companyData?.company?.country] || [])
  }, [companyData])

  useEffect(() => {
    setErrors({})
    setDataChanged(false)
    setProfileData(setCompanyData(companyData))
    setCities(COUNTRY_CITY[companyData?.company?.country] || [])
  }, [activeTab])

  const handleChange = event => {
    const { name, value } = event.target
    setProfileData(prevState => ({ ...prevState, [name]: value }))
    if (name === 'country') {
      setCities(COUNTRY_CITY[value] || [])
      setProfileData(prevState => ({ ...prevState, city: '' }))
    }
    setDataChanged(true)
  }

  const validateForm = async () => {
    const validationErrors = {}
    try {
      await companyManageProfileSchema.validate(profileData, {
        abortEarly: false,
      })
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message
        })
      }
    }
    setErrors(validationErrors)
    return Object.keys(validationErrors).length === 0
  }

  const handleSubmit = async () => {
    if (!dataChanged) {
      return
    }

    const isValid = await validateForm()
    if (!isValid) {
      setErrors(prevErrors => ({
        ...prevErrors,
        saveChanges: 'Your changes could not be saved.',
        success: false,
      }))
      setTimeout(() => {
        setErrors(prevErrors => ({
          ...prevErrors,
          success: false,
          saveChanges: '',
        }))
      }, 2000)
      return
    }

    if (!profileData?.city) {
      setErrors(prevErrors => ({
        ...prevErrors,
        saveChanges: 'Your changes could not be saved.',
        city: 'Please select the city',
        success: false,
      }))
      return
    }

    setIsLoading(true)
    const formData = new FormData()
    formData.append('company[name]', profileData?.companyName.trim())
    formData.append('company[country]', profileData?.country)
    formData.append('company[industry_id]', profileData?.mainIndustry)
    formData.append('company[city]', profileData?.city)
    formData.append('company[company_size]', profileData?.companySize)
    formData.append('company[logo]', profilePicture)
    formData.append('company[description]', profileData?.companyDescription)
    formData.append('company[website_link]', profileData?.companyWebsite)
    formData.append(
      'company[social_network_links][facebook]',
      profileData?.facebook,
    )
    formData.append(
      'company[social_network_links][twitter]',
      profileData?.twitter,
    )
    formData.append(
      'company[social_network_links][instagram]',
      profileData?.instagram,
    )

    try {
      const response = await updateCompany(companyData?.company?.id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      dispatch(setCompany(response))
      setIsLoading(false)
      setDataChanged(false)
      setErrors({
        saveChanges: 'Changes saved! All set.',
        success: true,
      })
      setTimeout(() => {
        setErrors(prevErrors => ({
          ...prevErrors,
          success: false,
          saveChanges: '',
        }))
      }, 2000)
    } catch (error) {
      setIsLoading(false)
      setDataChanged(false)
      setErrors(prevErrors => ({
        ...prevErrors,
        saveChanges: 'Your changes could not be saved.',
        success: false,
      }))
    }
  }

  return (
    <>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
          <Loader />
        </div>
      )}
      <div className="flex flex-row below-767:flex-col mb-5 below-767:items-center">
        <h1 className="text-18 font-bold text-gray-900">Company Profile</h1>
        <div className="ml-auto below-767:ml-0 flex flex-row below-767:flex-col gap-5">
          {errors.saveChanges && (
            <p
              className={`text-14 ${errors?.success ? 'text-green-600' : 'text-red-700'} below-767:ml-0 ml-auto font-[500] mt-2`}
            >
              {errors.saveChanges}
            </p>
          )}
          <button
            className={`text-14 ${dataChanged ? 'bg-primary-700 text-white' : 'bg-white text-[#1A56DB]'} below-767:mt-2 border border-[#1A56DB] font-[500] py-2 lg:px-4 px-9 rounded-lg mb-2 lg:mb-0`}
            onClick={handleSubmit}
          >
            Save Changes
          </button>
        </div>
      </div>
      <CompanyProfileTab
        profileData={profileData}
        handleChange={handleChange}
        errors={errors}
        cities={cities}
        setProfilePicture={setProfilePicture}
        profilePicture={profilePicture}
        setDataChanged={setDataChanged}
      />
    </>
  )
}

export default ManageProfileTab
