import 'rc-slider/assets/index.css'
import React from 'react'
import Slider from 'rc-slider'
import { Button, Tooltip } from 'flowbite-react'
import crossIcon from 'assets/svgs/cross.svg'
import { ScrollableDropdown } from 'components/generics/dropdown/ScrollableDropdown'
import { COUNTRY_CITY } from 'data/registerFormData'
import 'assets/css/scrollable-dropdown.css'

const FilterContent = ({
  filters,
  handleExperienceChange,
  handleSalaryChange,
  handleLanguageChange,
  handleCheckboxChange,
  handleSubmit,
  handleReset,
  openFilterModal,
  setOpenFilterModal,
  handleNationalityChange,
  handleResidenceCountryChange,
}) => {
  const createSliderWithTooltip = Slider.createSliderWithTooltip
  const Range = createSliderWithTooltip(Slider.Range)

  const createMarks = values => {
    const marks = {}
    marks[values[0]] = `${values[0]}`
    marks[values[1]] = `${values[1]}+`
    return marks
  }

  const CustomToggleSwitch = ({ checked, onChange }) => {
    return (
      <div
        onClick={onChange}
        className={`relative inline-flex items-center h-6 w-11 rounded-full cursor-pointer transition-colors duration-200 ease-in-out 
              ${checked ? 'bg-[#1A56DB]' : 'bg-gray-200'}`}
      >
        <span
          className={`inline-block h-5 w-5 transform rounded-full bg-white shadow transition-transform duration-200 ease-in-out 
                ${checked ? 'translate-x-5' : 'translate-x-1'}`}
        />
      </div>
    )
  }

  return (
    <>
      <Tooltip
        content={
          <div>
            <p className="font-semibold text-white text-center text-sm">
              Narrow it down...
            </p>
            <p className="text-gray-400 font-normal text-xs leading-tight">
              Have any specific criteria in mind? Use filters to refine your
              preference score
            </p>
          </div>
        }
        placement="left"
        className="justify-center items-center gap-6 flex-1 rounded-4 bg-gray-800"
      >
        <>
          <p className="text-gray-500 text-base font-medium leading-6">
            Filters
          </p>
          {openFilterModal && (
            <button
              className="absolute top-5 right-3 text-gray-500 hover:text-gray-700"
              onClick={() => setOpenFilterModal(false)}
            >
              <img src={crossIcon} alt="cross" className="cursor-pointer p-1" />
            </button>
          )}
        </>
      </Tooltip>

      <div className="filters-container">
        <form className="space-y-5">
          <div className="col-span-1">
            <label
              htmlFor="experience"
              className="text-14 font-semibold text-gray-900"
            >
              Years of Experience
            </label>
            <div className="p-3">
              <Range
                min={0}
                max={10}
                trackStyle={[{ backgroundColor: '#0a58ca', height: '10px' }]}
                railStyle={{ height: '10px' }}
                handleStyle={{
                  height: '20px',
                  width: '20px',
                  border: '2px solid #ced4da',
                  marginTop: '-5px',
                }}
                value={filters.experienceRange}
                onChange={handleExperienceChange}
                marks={createMarks(filters.experienceRange)}
              />
            </div>
          </div>

          <div className="col-span-1 pt-4">
            <label
              htmlFor="annual-salary"
              className="text-14 font-semibold text-gray-900"
            >
              Annual Salary
            </label>
            <select
              id="annual-salary"
              onChange={handleSalaryChange}
              value={filters.salaryRange}
              className="text-14 mt-2 block w-full pl-3 pr-10 py-2 text-sm bg-gray-50 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md border border-gray-300"
            >
              <option value="" disabled>
                Choose Salary Range
              </option>
              <option value="<24000">{'< 24,000 SAR'}</option>
              <option value="24000-36000">{'24,000 - 36,000 SAR'}</option>
              <option value="36000-48000">{'36,000 - 48,000 SAR'}</option>
              <option value=">48000">{'+ 48,000 SAR'}</option>
            </select>
          </div>

          <div className='w-full'>
            <div className="col-span-1 scrollable-dropdown">
              <label
                htmlFor="citizenship"
                className="text-14 font-semibold text-gray-900"
              >
                Nationality
              </label>
              <ScrollableDropdown
                name="citizenship"
                options={Object.keys(COUNTRY_CITY).map(countryName => ({
                  label: countryName,
                  value: countryName,
                }))}
                onChange={handleNationalityChange}
                value={filters?.citizenship}
                defaultLabel="Select your nationality"
                applyClass={true}
              />
            </div>

            <div className="mt-2 col-span-1 scrollable-dropdown">
              <label
                htmlFor="residence_country"
                className="text-14 font-semibold text-gray-900"
              >
                Living in
              </label>
              <ScrollableDropdown
                name="residence_country"
                options={Object.keys(COUNTRY_CITY).map(countryName => ({
                  label: countryName,
                  value: countryName,
                }))}
                onChange={handleResidenceCountryChange}
                value={filters?.residence_country}
                defaultLabel="Select your residence"
                applyClass={true}
              />
            </div>
          </div>
          <div className="col-span-1 pt-2">
            <label className="text-14 font-semibold text-gray-900">
              Language Proficiency Required
            </label>
            <div className="flex flex-col gap-2 mt-2">
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-indigo-600 rounded-md"
                  name="languagePreference"
                  value="English"
                  checked={filters.languagePreference.includes('English')}
                  onChange={() => handleLanguageChange('English')}
                />
                <span className="text-14 ml-2 text-gray-500 leading-none font-medium">
                  English
                </span>
              </label>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-indigo-600 rounded-md"
                  name="languagePreference"
                  value="Arabic"
                  checked={filters.languagePreference.includes('Arabic')}
                  onChange={() => handleLanguageChange('Arabic')}
                />
                <span className="ml-2 text-gray-500 leading-none text-14 font-medium">
                  Arabic
                </span>
              </label>
            </div>
          </div>

          <div className="col-span-1 pt-2">
            <label className="text-14 font-semibold text-gray-900">
              Professional Certification
            </label>
            <div className="flex items-center justify-between mt-2">
              <label
                htmlFor="certification-required"
                className="leading-tight text-14 text-gray-900"
              >
                Certification Preferred
              </label>
              <CustomToggleSwitch
                checked={
                  filters.certificationRequired === true ||
                  filters.certificationRequired === 'true'
                }
                onChange={() =>
                  handleCheckboxChange(
                    'certificationRequired',
                    !filters.certificationRequired,
                  )
                }
              />
            </div>
          </div>

          <div className="col-span-1 pt-2">
            <label className="text-14 font-semibold text-gray-900">
              Company Benefits
            </label>
            <div className="flex flex-col gap-4 mt-2">
              <div className="col-span-1">
                <label
                  htmlFor="accommodation-offered"
                  className="text-14 font-medium leading-6"
                >
                  Is company-provided housing available?
                </label>
                <div className="flex items-center mt-2 gap-4">
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="accommodation"
                      value="yes"
                      checked={
                        filters.accommodationOffered === true ||
                        filters.accommodationOffered === 'true'
                      }
                      onChange={() =>
                        handleCheckboxChange('accommodationOffered', true)
                      }
                      className="form-radio h-5 w-5 text-indigo-600"
                    />
                    <span className="text-14 text-gray-700">Yes</span>
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="accommodation"
                      value="no"
                      checked={
                        filters.accommodationOffered === false ||
                        filters.accommodationOffered === 'false'
                      }
                      onChange={() =>
                        handleCheckboxChange('accommodationOffered', false)
                      }
                      className="form-radio h-5 w-5 text-indigo-600"
                    />
                    <span className="text-14 text-gray-700">No</span>
                  </label>
                </div>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="iqama-required"
                  className="text-14 font-medium leading-6"
                >
                  Do you require candidates to already have an Iqama or Visa?
                </label>
                <div className="flex items-center mt-2 gap-4">
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="iqama"
                      value="yes"
                      checked={
                        filters.visaRequired === true ||
                        filters.visaRequired === 'true'
                      }
                      onChange={() =>
                        handleCheckboxChange('visaRequired', true)
                      }
                      className="form-radio h-5 w-5 text-indigo-600"
                    />
                    <span className="text-14 text-gray-700">Yes</span>
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="iqama"
                      value="no"
                      checked={
                        filters.visaRequired === false ||
                        filters.visaRequired === 'false'
                      }
                      onChange={() =>
                        handleCheckboxChange('visaRequired', false)
                      }
                      className="form-radio h-5 w-5 text-indigo-600"
                    />
                    <span className="text-14 text-gray-700">No</span>
                  </label>
                </div>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="transportation-offered"
                  className="text-14 font-medium leading-6"
                >
                  Is company-provided transportation to the job site available?
                </label>
                <div className="flex items-center mt-2 gap-4">
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="transportation"
                      value="yes"
                      checked={
                        filters.transportationOffered === true ||
                        filters.transportationOffered === 'true'
                      }
                      onChange={() =>
                        handleCheckboxChange('transportationOffered', true)
                      }
                      className="form-radio h-5 w-5 text-indigo-600"
                    />
                    <span className="text-14 text-gray-700">Yes</span>
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="transportation"
                      value="no"
                      checked={
                        filters.transportationOffered === false ||
                        filters.transportationOffered === 'false'
                      }
                      onChange={() =>
                        handleCheckboxChange('transportationOffered', false)
                      }
                      className="form-radio h-5 w-5 text-indigo-600"
                    />
                    <span className="text-14 text-gray-700">No</span>
                  </label>
                </div>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="other-benefits-offered"
                  className="text-14 font-medium leading-6"
                >
                  Are additional benefits offered (e.g., health insurance,
                  bonuses)?
                </label>
                <div className="flex items-center mt-2 gap-4">
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="otherBenefits"
                      value="yes"
                      checked={
                        filters.additionalBenefitsOffered === true ||
                        filters.additionalBenefitsOffered === 'true'
                      }
                      onChange={() =>
                        handleCheckboxChange('additionalBenefitsOffered', true)
                      }
                      className="form-radio h-5 w-5 text-indigo-600"
                    />
                    <span className="text-14 text-gray-700">Yes</span>
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="otherBenefits"
                      value="no"
                      checked={
                        filters.additionalBenefitsOffered === false ||
                        filters.additionalBenefitsOffered === 'false'
                      }
                      onChange={() =>
                        handleCheckboxChange('additionalBenefitsOffered', false)
                      }
                      className="form-radio h-5 w-5 text-indigo-600"
                    />
                    <span className="text-14 text-gray-700">No</span>
                  </label>
                </div>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="annual-flight-offered"
                  className="text-14 font-medium leading-6"
                >
                  Is an annual flight home provided?
                </label>
                <div className="flex items-center mt-2 gap-4">
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="annualFlight"
                      value="yes"
                      checked={
                        filters.annualFlightOffered === true ||
                        filters.annualFlightOffered === 'true'
                      }
                      onChange={() =>
                        handleCheckboxChange('annualFlightOffered', true)
                      }
                      className="form-radio h-5 w-5 text-indigo-600"
                    />
                    <span className="text-14 text-gray-700">Yes</span>
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="annualFlight"
                      value="no"
                      checked={
                        filters.annualFlightOffered === false ||
                        filters.annualFlightOffered === 'false'
                      }
                      onChange={() =>
                        handleCheckboxChange('annualFlightOffered', false)
                      }
                      className="form-radio h-5 w-5 text-indigo-600"
                    />
                    <span className="text-14 text-gray-700">No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center md:mb-5 md:pb-0 pb-28 gap-5">
            <Button onClick={handleSubmit} color={'blue'}>
              Apply
            </Button>
            <Button
              className="text-14 justify-center items-center rounded-lg border border-gray-300"
              color={'white'}
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default FilterContent
