import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { Button, Accordion as FlowbiteAccordion } from 'flowbite-react'
import { fetchAllIndustries } from 'utilis/helpers/registrationHelpers'
import { candidateExperienceQualificationSchema } from 'validations/CandidateProfileScehma'
import { getCurrentUser } from 'services/userService'
import {
  deleteCertification,
  fetchCertifications,
} from 'services/certificationService'
import SkillExpertise from './skill-expertise/SkillExpertise'
import { CertificationsComponent } from './experience-qualifications/Certifications'
import { WorkExperienceComponent } from './experience-qualifications/WorkExperience'
import {
  deleteWorkHistory,
  fetchWorkHistories,
} from 'services/experienceService'
import {
  getChangedFields,
  transformExpertiseData,
  createTasksForCandidateInfoUpdate,
} from './experience-qualifications/experience-helpers/experienceHelpers'
import 'assets/css/experience-form.css'
import { isMediumMobileDevice } from 'utilis/helpers'
import { CardLayoutExperienceQualification } from './CardLayoutExperienceQualification'
import {
  setCurrentUser,
  selectCurrentUser,
} from '../../../../redux/currentUserSlice'
import Loader from 'components/generics/loader/Loader'

const ExperienceQualificationForm = ({
  tabsRef,
  setSelectedCard = () => {},
  isSignUpView = false,
}) => {
  const dispatch = useDispatch()
  const [industries, setIndustries] = useState([])
  const { currentUser } = useSelector(selectCurrentUser)
  const [skillExpertise, setSkillExpertise] = useState(
    currentUser.skill_expertise,
  )
  const [certificationsList, setCertificationsList] = useState([])
  const [experienceList, setExperienceList] = useState([])
  const [selectedExpertises, setSelectedExpertises] = useState({})
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [addedOrModifiedExperience, setAddedOrModifiedExperience] = useState([])
  const [addedOrModifiedCertificate, setAddedOrModifiedCertificate] = useState(
    [],
  )
  const [deleteExperiencesList, setDeleteExperiencesList] = useState(null)
  const [deleteCertificationList, setDeleteCertificationList] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [isSubmittingForm, setIsSubmittingForm] = useState(false)
  const isMediumMobile = isMediumMobileDevice()
  const formInitialRendered = useRef(true)
  const isExpOrCertValuesTouched = useRef(false)
  const formikInitialValues = useRef({})
  const changesSaved = useRef(false)

  const handleSkillSelection = (
    skills,
    expertises,
    skillExpertise,
    setFieldValue,
  ) => {
    setSelectedExpertises(expertises)
    setFieldValue('skillExpertise', skillExpertise)
  }

  const fetchUserCertifications = async () => {
    try {
      const response = await fetchCertifications(currentUser.id)
      if (response && response.status === 200) {
        const data = response.data

        if (Array.isArray(data)) {
          const formattedCertifications = data.map(cert => ({
            certificateId: cert.id,
            documentType: cert.document_type,
            documentTitle: cert.title,
            issuedBy: cert.issued_by,
            dateOfIssue: cert.issued_date,
            file: cert.file_url || null,
          }))

          setCertificationsList(formattedCertifications)
        } else {
          setCertificationsList([])
        }
      } else {
        setCertificationsList([])
        console.error('Failed to fetch certifications', response.status)
      }
    } catch (error) {
      setCertificationsList([])
      console.error('Error fetching user certifications:', error)
    }
  }

  const fetchUserWorkExperience = async () => {
    try {
      const userWorkHistory = await fetchWorkHistories(currentUser.id)
      if (userWorkHistory && userWorkHistory.status === 200) {
        const data = userWorkHistory.data
        if (Array.isArray(data)) {
          const formattedWorkHistory = data.map(exp => ({
            workHistoryId: exp.id,
            currentJobTitle: exp.job_title,
            currentWorking: exp.is_working ? 'Yes' : 'No',
            jobRole: exp.job_title,
            companyName: exp.company_name,
            jobDescription: exp.description,
            jobJoinDate: exp.start_date,
            jobEndDate: exp.end_date,
            jobCountry: exp.country,
            jobCity: exp.city,
          }))
          setExperienceList(formattedWorkHistory)
        } else {
          setExperienceList([])
        }
      } else {
        setExperienceList([])
        console.error('Failed to fetch work history:', userWorkHistory.status)
      }
    } catch (error) {
      setExperienceList([])
      console.error('Error fetching work history:', error)
    }
  }

  const fetchIndustries = async () => {
    try {
      const response = await fetchAllIndustries()
      if (response.status === 200) {
        setIndustries(response.data)
      }
    } catch (error) {
      console.error('Error fetching industries')
    }
  }

  const fetchCurrentUser = async () => {
    try {
      const response = await getCurrentUser()
      dispatch(setCurrentUser(response?.data))
    } catch (err) {
      console.error('Error fetching user', err)
    }
  }

  const deleteWorkHistories = async ExperiencesList => {
    deleteWorkHistory(currentUser.id, ExperiencesList)
  }

  const deleteCertificationHistories = async CertificationHistories => {
    deleteCertification(
      currentUser.id,
      CertificationHistories || deleteCertificationList,
    )
  }

  const fetchUserExpAndCertData = async () => {
    setIsDataLoading(true)
    try {
      await Promise.all([fetchUserCertifications(), fetchUserWorkExperience()])
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setIsDataLoading(false)
    }
  }

  const handleSubmit = async values => {
    setIsSubmittingForm(true)
    try {
      const updateCandidateTasks = createTasksForCandidateInfoUpdate(
        currentUser,
        setCurrentUser,
        values,
        selectedExpertises,
        addedOrModifiedCertificate,
        addedOrModifiedExperience,
        dispatch,
      )

      for (const updateTask of updateCandidateTasks) {
        try {
          await updateTask()
        } catch (error) {
          console.error('Error executing candidate update task:', error)
        }
      }
      await Promise.all([fetchCurrentUser(), fetchUserExpAndCertData()])

      formInitialRendered.current = false
      isExpOrCertValuesTouched.current = false
      formikInitialValues.current = {
        ...values,
        industry: parseInt(values.industry, 10),
        yearsOfExperience: parseInt(values.yearsOfExperience, 10),
      }

      setAddedOrModifiedCertificate([])
      setAddedOrModifiedExperience([])

      if (isSignUpView) {
        setSelectedCard(3)
      } else {
        changesSaved.current = true
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error)
    } finally {
      setIsSubmittingForm(false)
    }
  }

  const handleInitialValues = values => {
    formikInitialValues.current = {
      industry: currentUser?.industry_id ?? '',
      yearsOfExperience: currentUser?.years_of_experience ?? '',
      skillExpertise: transformExpertiseData(skillExpertise) || [],
      workExperience: experienceList,
      certifications: certificationsList,
    }
    const changedFields = getChangedFields(formikInitialValues.current, values)

    const isDirty = Object.keys(changedFields).length > 0

    return isDirty
  }

  useEffect(() => {
    setSkillExpertise(currentUser.skill_expertise)
  }, [currentUser])

  useEffect(() => {}, [selectedExpertises, skillExpertise])

  useEffect(() => {
    if (
      addedOrModifiedCertificate.length > 0 ||
      addedOrModifiedExperience.length > 0
    ) {
      isExpOrCertValuesTouched.current = true
    }
  }, [addedOrModifiedCertificate, addedOrModifiedExperience])

  useEffect(() => {
    isExpOrCertValuesTouched.current = true
  }, [deleteCertificationList, deleteExperiencesList])

  useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true)
      try {
        await Promise.all([
          fetchIndustries(),
          fetchUserCertifications(),
          fetchUserWorkExperience(),
        ])
      } catch (error) {
        console.error('Error fetching data:', error)
      }
      setIsDataLoading(false)
    }

    fetchData()
  }, [])

  return (
    <>
      {isDataLoading && (
        <div className="sm:w-4/5">
          <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
            <Loader />
          </div>
        </div>
      )}
      {currentUser && !isDataLoading && (
        <Formik
          initialValues={{
            industry: currentUser?.industry_id ?? '',
            yearsOfExperience: currentUser?.years_of_experience ?? '',
            skillExpertise: skillExpertise || [],
            workExperience: experienceList,
            certifications: certificationsList,
          }}
          validateOnChange={true}
          enableReinitialize={true}
          validationSchema={candidateExperienceQualificationSchema}
          onSubmit={values => {
            handleSubmit(values)
          }}
        >
          {({ values, handleChange, isSubmitting, setFieldValue }) => {
            const isDirty = handleInitialValues(values)

            const experienceFormContent = (
              <>
                <div className="flex flex-col justify-start mb-3 gap-y-4">
                  <h1
                    className={`${isMediumMobile ? 'text-xl' : 'text-2xl'} font-bold leading-5 text-black experience-heading-text`}
                  >
                    Experience & Qualifications 💼
                  </h1>
                  <p className="text-gray-600 font-medium text-base leading-5">
                    Quali candidates with detailed experience and certifications
                    are 90% more likely to get noticed by employers.
                  </p>
                  <div className="border-dashed border-b-2 border-[#E3E3E3]" />
                </div>
                <Form disabled={isSubmittingForm}>
                  {!isSignUpView && (
                    <div
                      className={`flex ${isMediumMobile ? 'flex-col' : 'flex-row'} justify-between items-center mt-3 mb-4 work-exp-save-button-container`}
                    >
                      <h1 className="text-lg font-bold leading-5 text-black">
                        Experience & Qualification
                      </h1>
                      <div
                        className={`flex ${isMediumMobile ? 'flex-col gap-1' : 'flex-row gap-2'} items-center`}
                      >
                        {changesSaved.current &&
                          !isExpOrCertValuesTouched.current &&
                          !isDirty && (
                            <div>
                              <p className="text-sm font-bold text-green-700">
                                Changes saved! All set.
                              </p>
                            </div>
                          )}
                        <div className="my-2">
                          <button
                            type="submit"
                            className={`text-14 ${
                              isDirty || isExpOrCertValuesTouched.current
                                ? 'bg-primary-700 text-white'
                                : 'bg-white text-[#1A56DB]'
                            } below-767:mt-2 border border-[#1A56DB] font-[500] py-2 lg:px-4 px-7 rounded-lg mb-2 lg:mb-0 text-nowrap`}
                            disabled={
                              (!isDirty && !isExpOrCertValuesTouched.current) ||
                              isSubmittingForm
                            }
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <FlowbiteAccordion className="mb-4">
                    <FlowbiteAccordion.Panel className="border-none shadow-none p-0">
                      <FlowbiteAccordion.Title className="hover:bg-transparent bg-transparent text-inherit border-none shadow-none outline-none focus:outline-none focus:ring-0">
                        🤹🏻 Skills and Expertise
                      </FlowbiteAccordion.Title>
                      <FlowbiteAccordion.Content className="mt-2 border-none pt-0">
                        <SkillExpertise
                          values={values}
                          industries={industries}
                          skillExpertise={skillExpertise}
                          setSkillExpertise={setSkillExpertise}
                          onSkillSelection={(
                            skills,
                            expertises,
                            skillExpertise,
                          ) =>
                            handleSkillSelection(
                              skills,
                              expertises,
                              skillExpertise,
                              setFieldValue,
                            )
                          }
                          handleChange={handleChange}
                        />
                      </FlowbiteAccordion.Content>
                    </FlowbiteAccordion.Panel>
                  </FlowbiteAccordion>
                  <div className="border-dashed border-b-2 border-[#E3E3E3] mb-4" />

                  <FlowbiteAccordion className="mb-4">
                    <FlowbiteAccordion.Panel className="border-none shadow-none p-0">
                      <FlowbiteAccordion.Title className="hover:bg-transparent bg-transparent text-inherit border-none shadow-none outline-none focus:outline-none focus:ring-0">
                        📝 My Work Experience
                      </FlowbiteAccordion.Title>
                      <FlowbiteAccordion.Content className="border-none pt-0">
                        <WorkExperienceComponent
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          userId={currentUser.id}
                          addedOrModifiedExperience={addedOrModifiedExperience}
                          setAddedOrModifiedExperience={
                            setAddedOrModifiedExperience
                          }
                          deleteWorkHistories={deleteWorkHistories}
                          setIsEditing={setIsEditing}
                          isExpOrCertValuesTouched={isExpOrCertValuesTouched}
                          setDeleteExperiencesList={setDeleteExperiencesList}
                        />
                      </FlowbiteAccordion.Content>
                    </FlowbiteAccordion.Panel>
                  </FlowbiteAccordion>
                  <div className="border-dashed border-b-2 border-[#E3E3E3] mb-4" />

                  <FlowbiteAccordion className="mb-4">
                    <FlowbiteAccordion.Panel className="border-none shadow-none p-0">
                      <FlowbiteAccordion.Title className="hover:bg-transparent bg-transparent text-inherit border-none shadow-none outline-none focus:outline-none focus:ring-0">
                        📄 Certifications
                      </FlowbiteAccordion.Title>
                      <FlowbiteAccordion.Content className="border-none pt-0">
                        <CertificationsComponent
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          userId={currentUser.id}
                          addedOrModifiedCertificate={
                            addedOrModifiedCertificate
                          }
                          setAddedOrModifiedCertificate={
                            setAddedOrModifiedCertificate
                          }
                          setIsEditing={setIsEditing}
                          setDeleteCertificationList={
                            setDeleteCertificationList
                          }
                          isExpOrCertValuesTouched={isExpOrCertValuesTouched}
                          deleteCertificationHistories={
                            deleteCertificationHistories
                          }
                        />
                      </FlowbiteAccordion.Content>
                    </FlowbiteAccordion.Panel>
                  </FlowbiteAccordion>
                  {isSignUpView && (
                    <div className="my-2 flex gap-4 justify-between">
                      <Button
                        className="text-blue-700 bg-white border-2 border-[#1A56DB] px-6"
                        onClick={() => setSelectedCard(prev => prev - 1)}
                      >
                        Back
                      </Button>
                      <Button
                        className="bg-blue-700 px-6"
                        type="submit"
                        disabled={isSubmitting || isEditing}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </Form>
              </>
            )
            return (
              <>
                {isSignUpView ? (
                  <CardLayoutExperienceQualification>
                    {experienceFormContent}
                  </CardLayoutExperienceQualification>
                ) : (
                  <div>
                    <div className="sm:p-4 border-none shadow-none">
                      {experienceFormContent}
                    </div>
                  </div>
                )}
              </>
            )
          }}
        </Formik>
      )}
    </>
  )
}

export default ExperienceQualificationForm
