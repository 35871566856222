import ExperienceQualificationForm from 'components/Dashboard/candidate-profile-overview/CandidateExperienceTab/ExperienceQualificationForm'

export const CandidateExperienceDetails = ({
  setSelectedCard,
  isSignUpView = false,
}) => {
  return (
    <ExperienceQualificationForm
      setSelectedCard={setSelectedCard}
      isSignUpView={isSignUpView}
    />
  )
}
