import React, { useEffect, useState } from 'react'
import AppBar from '../components/Dashboard/AppBar'
import { TopHeader } from '../components/Search/TopHeader'
import { AppSideBar } from '../components/Dashboard/AppSideBar'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../redux/currentUserSlice'
import { QualiTest, HiringFeeds } from '../components'
import { GetStoredtestInformation } from '../services/testService'
import { useLoader } from '../components/generics/loader/UseLoader'
import { Loader } from '../utilis/AppLoader'
import { deleteFeedForUser, getCompanyFeeds} from '../services/feedsService'
import '../assets/css/cborder.css'
import { PersistentInbox } from 'components/Messaging/PersistentMinimizableInbox'
const TestandAssessment = () => {
  const [testdata, setTestData] = useState({})
  const { currentUser } = useSelector(selectCurrentUser)
  const { loading, showLoader, hideLoader } = useLoader()  

  const gettestData = async () => {
    showLoader() 
    try {
      const response = await GetStoredtestInformation()
      if (response.status === 200) {
        setTestData(response.data)
      }
    } catch (error) {
      console.error('Error fetching test data:', error)
     
    } finally {
      hideLoader()  
    }
  }
  const clearUserFeed = async () => {
    const response = await deleteFeedForUser()
    if (response.status === 200) {
      getCompanyFeeds();
    }
  }
  useEffect(() => {
    gettestData()
  }, [])

  return (
    <div className="h-full bg-[#f9fafb] font-satoshi-regular">
      <AppBar />
      <div className="flex flex-col md:flex-row p-2 md:p-10">
        <div className="hidden md:block">
          <AppSideBar />
        </div>
        <div className="flex flex-col w-full md:w-3/4 sm:pl-4">
          <TopHeader />
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <Loader size="large" color="blue" />
            </div>
          ) : (
            <div className="border-1.5 border-gray-400 rounded-lg bg-white mt-5 mr-4 shadow-md">
              <div className="mt-5 ml-6"></div>
              {testdata && <QualiTest data={testdata} />}
              <HiringFeeds userRole={currentUser.role} fetchCompanyFeeds={getCompanyFeeds} clearUserFeed={clearUserFeed} />
            </div>
            
          )}
           <PersistentInbox />
        </div>
      </div>
    </div>
  )
}

export default TestandAssessment
