import { createSlice } from '@reduxjs/toolkit'

const messageSlice = createSlice({
  name: 'messages',
  initialState: {
    messages: [],
    unreadCount: 0,
    typingStatus: {},
    isTyping: false,
    currentPartnerId: null,
    lastMessage: {},
  },
  reducers: {
    addMessage: (state, action) => {
      return {
        ...state,
        messages: action.payload,
      }
    },
    appendMessages: (state, action) => {
      state.messages.push(action.payload)
    },
    updateUnreadCount: (state, action) => {
      state.unreadCount = action.payload
    },
    setTypingStatus: (state, action) => {
      state.isTyping = action.payload.isTyping
      state.currentPartnerId = action.payload.partnerId
    },
    updateTypingStatus: (state, action) => {
      const { userId, isTyping } = action.payload
      if (!state.typingStatus) {
        state.typingStatus = {} // Initialize if it doesn't exist
      }
      state.typingStatus[userId] = isTyping
    },
    updateLastMessage: (state, action) => {
      const { conversationId, lastMessage, time } = action.payload;
      if (!state.lastMessage) {
        state.lastMessage = {}
      }
      state.lastMessage[conversationId] = { lastMessage, time };
    },
  },
})

export const {
  addMessage,
  updateUnreadCount,
  appendMessages,
  setTypingStatus,
  updateTypingStatus,
  updateLastMessage
} = messageSlice.actions

export const messageSliceReducer = messageSlice.reducer

export const selectMessages = state => state.messages.messages
export const selectUnreadCount = state => state.messages.unreadCount
export const selectTypingStatus = state => state.messages.typingStatus
export const selectIsTyping = state => state.messages.isTyping
export const selectCurrentPartnerId = state => state.messages.currentPartnerId
export const selectLastMessage = state => state.messages.lastMessage
