import React, { useEffect, useState } from 'react'
import AdminNavbar from './AdminNavbar'
import { generateColumns, changeTitle } from '../../utilis/helpers/tableHelper'
import { allSkills } from '../../services/skillService'
import Table from '../table/Table'
import { getPaginatedExtertiseList } from '../../services/expertiseService'

const ExpertiseList = () => {
  const [skills, setSkills] = useState([])
  const [expertise, setExpertise] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState('')

  const fetchExpertise = async () => {
    try {
      const response = await allSkills()
      const expertiseResponse = await getPaginatedExtertiseList(
        currentPage,
        query,
      )
      if (response.status === 200) {
        const sortedData = expertiseResponse?.data?.records?.sort(
          (a, b) => b.id - a.id,
        )
        const updatedExpertise = getSkillWithTitle(sortedData, response?.data)
        setSkills(response?.data)
        setExpertise(updatedExpertise || [])
        setTotalPages(expertiseResponse?.data?.pagy?.total_pages)
        setTotalCount(expertiseResponse?.data?.pagy?.total_count)
      }
    } catch (error) {
      console.error('Error fetching skills:', error)
    }
  }

  useEffect(() => {
    fetchExpertise()
  }, [currentPage, query])

  const getSkillWithTitle = (expertise, skills) => {
    const expertiseWithTitle = expertise.map(exp => {
      const skill = skills.find(skl => skl.id === exp.skill_id)
      return {
        ...exp,
        skill: skill ? skill.title : null,
      }
    })

    return expertiseWithTitle
  }

  const onSearchExpertise = (search, value) => {
    setCurrentPage(1)
    setQuery(value)
  }

  const headers = generateColumns(expertise)
  const columns = changeTitle(headers, 'expertise')
  return (
    <div className="flex h-screen w-full flex-col bg-[#f9fafb]">
      <AdminNavbar selectedItem="expertise" />
      <Table
        columns={columns}
        data={expertise}
        title="Expertise"
        getExpertise={fetchExpertise}
        skills={skills}
        onSearch={onSearchExpertise}
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        setCurrentPage={setCurrentPage}
      />
    </div>
  )
}

export default ExpertiseList
