import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfinity } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as PurpleTestsIcon } from '../../../assets/purple-tests-icon.svg'
import RevealIcon from '../../../assets/blue-reveal-icon.svg'

export const AdminOptions = ({ isCompanyAdmin, currentUser }) => {
  if (!isCompanyAdmin) return null

  return (
    <div className="border-b border-gray w-full">
      <div className="flex justify-between space-x-2 w-full">
        <div className="flex flex-col items-center bg-blue-50 rounded-lg w-1/2 justify-center py-2 my-2">
          <span className="text-blue-600">
            <img src={RevealIcon} alt="Reveal Icon" width="20" height="20" />
          </span>
          <span className="text-blue-600 text-sm font-semibold text-center pt-2">
            <FontAwesomeIcon icon={faInfinity} /> Reveals
          </span>
        </div>
        <div className="flex flex-col items-center bg-purple-50 rounded-lg w-1/2 justify-center py-2 my-2">
          <span className="text-purple-600">
            <PurpleTestsIcon />
          </span>
          <span className="text-purple-600 text-sm font-semibold pt-2">
            {currentUser.company.remaining_test_count} Tests
          </span>
        </div>
      </div>
    </div>
  )
}
