import axiosInstance from './axiosConfig'
import { UserPrefrencesEndPoint } from './constants'

export const createPreferences = async (userId, body) => {
  const response = await axiosInstance.post(
    UserPrefrencesEndPoint.createUserPreferences(userId),
    {
      job_preference: body,
    },
  )
  return response
}

export const getUserPreferences = async userId => {
  return await axiosInstance.get(
    UserPrefrencesEndPoint.getUserPrefrences(userId),
  )
}
