import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchRecruitmentOverview } from '../../../services/candidateDashboardService'
import searchImage from '../../../assets/svgs/search_bgcolored.svg'
import messageImage from '../../../assets/svgs/Icon Shapes.svg'
import { CandidateEmptyDashboard } from '../Overview/CandidateEmptyDashboard'
import QualiTest from '../Overview/QualiTest'
import CandidateHiringFeeds from '../Overview/CandidateHiringFeed'
import { editCandidateTest, getSentTests } from 'services/manageTestService'
import { getCurrentUser } from 'services/userService'
import { setCurrentUser } from '../../../redux/currentUserSlice'
import { useDispatch } from 'react-redux'
import { isMobileDevice } from 'utilis/helpers'
import {ensureHttpsUrl} from 'utilis/helpers/index'

export const CandidateRecruitmentOverview = ({updateFlag, testsData, candidateFeeds, clearUserFeed, getCandidateTests}) => {
  const numberToWords = require('number-to-words')
  const [searchCount, setSearchCount] = useState(0)
  const [pendingTestCount, setPendingTestCount] = useState(0)
  const dispatch = useDispatch()
  const isMobile = isMobileDevice()
  const recruitmentOverview = async () => {
    try {
      const response = await fetchRecruitmentOverview()
      if (response.status === 200) {
        setSearchCount(response.data.search_result)
        setPendingTestCount(response.data.pending_test)
      }
    } catch (error) {
      console.log('Error fetching recruitment overview', error)
    }
  }

  useEffect(() => {
    updateFlag && recruitmentOverview()

  }, [updateFlag])

  const handleCompleteTest = async (link, test) => {
    try {
      await editCandidateTest(test)
      await Promise.all ([getSentTests()
      , getCandidateTests()
      , fetchCurrentUser()])
    } catch (error) {
      console.error('Error updating test', error)
    }
    try {
    setTimeout(() => {
      link = ensureHttpsUrl(link);
      if (isMobile || !(navigator.userAgent.search("Chrome"))) {
        window.location.href = link;
      } else {
        window.open(link, '_blank');
      }
    }, 100)
    } catch (error) {
    console.error('Error completing test or navigating to link:', error);
    }
  }
  

  const fetchCurrentUser = async () => {
    try {
      const response = await getCurrentUser()
      dispatch(setCurrentUser(response?.data))
    } catch (err) {
      console.error('Error fetching user', err)
    }
  }

  return (
    <div>
    <div className="mx-2 sm:ml-8 mt-8 sm:mr-5">
      <h1 className="mb-4 text-[#111928] text-xl font-bold ">
        Recruitment overview
      </h1>

      <div className="flex flex-col lg:flex-row gap-8">
        <Link to={'#'} className="flex-1 bg-white rounded-lg shadow px-4 py-6">
          <div className="flex xl:flex-row flex-col items-center lg:px-8 ">
            <img className="mt-1 w-14 h-14" src={searchImage} alt="Search" />
            <div className="ml-4 text-center xl:text-start">
              <p className="font-bold text-2xl mt-2 text-[#111928]">
                {searchCount} Searches
              </p>
              <p className="text-base text-[#6B7280]">
                You appear {searchCount} ({numberToWords.toWords(searchCount)})
                times in search results
              </p>
            </div>
          </div>
        </Link>
        <Link to={'#'} className="flex-1 bg-white rounded-lg shadow px-4 py-6">
          <div className="flex xl:flex-row flex-col lg:px-8 items-center">
            <img className="mt-1 w-14 h-14" src={messageImage} alt="Message" />
            <div className="ml-4 text-center xl:text-start">
              <p className="font-bold text-2xl mt-2 text-[#111928]">
                {pendingTestCount} test
              </p>
              <p className="text-base text-[#6B7280]">
                You have {pendingTestCount} (
                {numberToWords?.toWords(pendingTestCount)}) pending tasks
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
    {searchCount===0 && testsData?.tests?.length===0 ?
      <>
        <CandidateEmptyDashboard isOverviewPage={true}/>
        <CandidateHiringFeeds
        candidateFeeds={candidateFeeds}
        clearUserFeed={clearUserFeed}
        handleCompleteTest={handleCompleteTest}
      />
      </>
      :
        <>
          <QualiTest
            data={testsData}
            handleCompleteTest={handleCompleteTest}
          />
          <CandidateHiringFeeds
            candidateFeeds={candidateFeeds}
            clearUserFeed={clearUserFeed}
            handleCompleteTest={handleCompleteTest}
          />
        </>
      }
    </div>
  )
}
