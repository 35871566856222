import React, { useState, useEffect } from 'react'
import { Modal, Label } from 'flowbite-react'
import { skillsInitialState } from '../../../config/constants'
import useToastMessage from '../../../hooks/useToastMessage'
import { editSkill, createSkill } from '../../../services/skillService'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { skillValidationSchema } from '../../../validations/SkillValidationSchema'

const SkillsEditModal = ({
  isModalOpen,
  closeModal,
  title,
  skillsData,
  getSkills,
  industries,
}) => {
  const [data, setData] = useState(skillsInitialState)
  const toastMessage = useToastMessage()

  useEffect(() => {
    if (skillsData) {
      setData({
        id: skillsData?.id,
        title: skillsData.title,
        industry_id: skillsData.industry_id,
      })
    } else {
      setData(skillsInitialState)
    }
  }, [skillsData])

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (title === 'Edit Skills') {
        delete values?.industry
        const response = await editSkill(values)

        if (response?.status === 200) {
          toastMessage('Skill updated successfully!', 'success')
          closeModal()
          getSkills()
          resetForm()
        }
      } else if (title === 'Create Skills') {
        const response = await createSkill(values)
        if (response?.status === 201) {
          toastMessage('Skill created successfully!', 'success')
          closeModal()
          getSkills()
          resetForm()
        }
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors.join(', ')
        alert(errorMessage)
      }
    }
  }

  return (
    <Modal
      show={isModalOpen}
      onClose={() => closeModal(false)}
      theme={{
        content: {
          base: 'bg-transparent w-3/4',
          inner: 'bg-transparent',
        },
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={data}
        validationSchema={skillValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleSubmit, values, isSubmitting }) => (
          <Form className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {title}
              </h3>
              <button
                onClick={() => closeModal()}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-6 space-y-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label className="block text-sm font-medium text-gray-900 dark:text-white">
                    Skill
                  </label>
                  <Field
                    onChange={handleChange}
                    value={values?.title}
                    type="text"
                    name="title"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Skill"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-red-500 text-xs mt-1"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <div className="w-full">
                    <div className="flex pb-0.5">
                      <Label htmlFor="industry" value="Industry" />
                    </div>
                    <Field
                      onChange={handleChange}
                      value={values?.industry_id}
                      as="select"
                      id="industry"
                      name="industry_id"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="">Select Industry</option>
                      {industries.map(industry => (
                        <option key={industry.id} value={industry.id}>
                          {industry.title}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="industry_id"
                      component="div"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center p-6 space-x-3 rtl:space-x-reverse border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {title.includes('Create') ? 'Create' : 'Update'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default SkillsEditModal
