import PreferencesForm from 'components/Dashboard/candidate-profile-overview/CandidatePreferenceTab/PreferencesForm'

export const CandidatePreferenceDetails = ({
  setSelectedCard,
  isSignUpView = false,
}) => {
  return (
    <PreferencesForm
      setSelectedCard={setSelectedCard}
      isSignUpView={isSignUpView}
    />
  )
}
