import React from 'react'
import CompanyEditModal from './EditModals/CompanyEditModal'
import IndustryEditModal from './EditModals/IndustryEditModal'
import SkillsEditModal from './EditModals/SkillsEditModal'
import ExpertiseEditModal from './EditModals/ExpertiseEditModal'
import TestsEditModal from './EditModals/TestsEditModal'
import UserEditModal from './EditModals/UsersEditModal.jsx'
import ManageTestEditModal from './EditModals/ManageTestEditModal.jsx'

const EditActionModal = ({
  isModalOpen,
  closeModal,
  data,
  title,
  getCompanies,
  industries,
  getIndustries,
  getSkills,
  getExpertise,
  skills,
  tests,
  getTests,
  getCandidates,
  testsListingForEdit,
  getManageTests,
}) => {
  return (
    <>
      {title === 'Edit Company' ? (
        <CompanyEditModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          title={title}
          industries={industries}
          companyData={data}
          getCompanies={getCompanies}
        />
      ) : title === 'Edit Industry' || title === 'Create Industry' ? (
        <IndustryEditModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          title={title}
          industryData={data}
          getIndustries={getIndustries}
        />
      ) : title === 'Edit Skills' || title === 'Create Skills' ? (
        <SkillsEditModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          title={title}
          skillsData={data}
          getSkills={getSkills}
          industries={industries}
        />
      ) : title === 'Edit Expertise' || title === 'Create Expertise' ? (
        <ExpertiseEditModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          title={title}
          skills={skills}
          getExpertise={getExpertise}
          expertiseData={data}
        />
      ) : title === 'Edit Test' ||
        title === 'Create Test Listing' ||
        title === 'Edit Test Listing' ? (
        <TestsEditModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          title={title}
          testsData={data}
          getTests={getTests}
          testsListingForEdit={testsListingForEdit}
        />
      ) : title === 'Edit Candidate' || title === 'Create Candidate' ? (
        <UserEditModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          title={title}
          candidatesData={data}
          getCandidates={getCandidates}
        />
      ) : title === 'Edit Manage Test' ? (
        <ManageTestEditModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          title={title}
          testsData={data}
          getManageTests={getManageTests}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default EditActionModal
