import React from 'react';
import { useLocation } from 'react-router-dom';
import { formatText } from 'utilis/helpers';

const getTagColor = (status) => {
  switch (status?.toLowerCase()) {
    case 'progress':
      return 'bg-blue-100 text-blue-800';
    case 'completed':
    case 'hired':
      return 'bg-green-100 text-green-800';
    case 'passed':
      return 'bg-red-100 text-red-800';
    case 'sent':
      return 'bg-yellow-100 text-yellow-800';
    default:
      return 'bg-yellow-100 text-yellow-800';
  }
};

const StatusButton = ({ status, onClick }) => {
  const location = useLocation();
  const colorClass = getTagColor(status);

  const displayText =
    status?.toLowerCase() !== 'progress'
      ? location.pathname === '/test-list-of-candidiate' && status === 'sent'
        ? 'Pending'
        : formatText(status)
      : 'In Progress';

  return (
    <button
      className={`${colorClass} text-xs font-medium py-1 rounded w-20 text-center`}
      onClick={onClick && onClick}
    >
      {displayText}
    </button>
  );
};

export default StatusButton;
