import React, { useState } from 'react'
import { Card, Button } from 'flowbite-react'
import { useSelector } from 'react-redux'
import image1 from '../../assets/headerImage.jpeg'
import '../../assets/css/overview.css'
import { selectCurrentUser } from '../../redux/currentUserSlice'
import VideoModal from './modals/VideoModal'
import { isMobileDevice } from 'utilis/helpers'
export function TopHeader() {
  const isMobile = isMobileDevice()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const videoUrl = process.env.REACT_APP_VIDEO_URL
  const { currentUser } = useSelector(selectCurrentUser)
  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }
  return (
    <Card
      className="relative h-64 max-w-full bg-cover bg-center mr-3  rounded-[15px] shadow-sm"
      style={{
        backgroundImage: `url(${image1})`,
        height: '260px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div class="custom-overlay">...</div>
      <div className="relative inset-0 flex flex-col items-start justify-center py-4 lg:mt-0 mt-4 text-center text-white">
        <div className="flex flex-row items-center ">
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.8018 6.31507L15.0884 5.60081C14.9375 5.45065 14.8549 5.25044 14.8549 5.03831V4.02771C14.8549 2.71361 13.7857 1.64421 12.4718 1.64421H11.4614C11.2525 1.64421 11.0475 1.5592 10.8998 1.41143L10.1856 0.697172C9.25623 -0.232391 7.74536 -0.232391 6.81596 0.697172L6.10024 1.41143C5.95249 1.5592 5.74754 1.64421 5.53863 1.64421H4.5282C3.21433 1.64421 2.14512 2.71361 2.14512 4.02771V5.03831C2.14512 5.25044 2.06251 5.45065 1.91237 5.60081L1.19824 6.31427C0.74784 6.76475 0.5 7.36381 0.5 8.0002C0.5 8.63659 0.748635 9.23564 1.19824 9.68533L1.91158 10.3996C2.06251 10.5497 2.14512 10.75 2.14512 10.9621V11.9727C2.14512 13.2868 3.21433 14.3562 4.5282 14.3562H5.53863C5.74754 14.3562 5.95249 14.4412 6.10024 14.589L6.81437 15.304C7.27907 15.768 7.88914 16 8.49921 16C9.10927 16 9.71934 15.768 10.184 15.3032L10.8982 14.589C11.0475 14.4412 11.2525 14.3562 11.4614 14.3562H12.4718C13.7857 14.3562 14.8549 13.2868 14.8549 11.9727V10.9621C14.8549 10.75 14.9375 10.5497 15.0884 10.3996L15.8018 9.68612C16.2514 9.23564 16.5 8.63739 16.5 8.0002C16.5 7.36301 16.2522 6.76475 15.8018 6.31507ZM12.1183 7.07222L7.35215 10.2502C7.2179 10.34 7.0638 10.3837 6.91128 10.3837C6.70634 10.3837 6.50298 10.3042 6.34967 10.1509L4.76095 8.56191C4.45035 8.25126 4.45035 7.74914 4.76095 7.43849C5.07154 7.12784 5.57358 7.12784 5.88417 7.43849L7.01216 8.56668L11.2366 5.75018C11.6028 5.50627 12.0953 5.60479 12.3383 5.97026C12.5822 6.33572 12.4837 6.82911 12.1183 7.07222Z"
              fill="#1C64F2"
            />
          </svg>

          <h5 className="text-sm font-satoshi-regular ml-2">
            300+ verified profiles
          </h5>
        </div>
        {currentUser?.role === 'candidate' && (
          <h1 className="font-satoshi-regular font-black text-4xl mt-3 ">
            Explore how Quali works
          </h1>
        )}
        {currentUser?.role === 'company_admin' && (
          <h1 className="font-satoshi-regular font-black text-4xl mt-3 ">
            Explore Quali Talent Directory
          </h1>
        )}

        <p className="font-satoshi-regular font-normal mt-2">
          No roles, no lengthy job boards
        </p>
        <div className={`${isMobile ? 'mb-4' : 'my-6'}`}>
          <Button
            color={'light'}
            size={'xs'}
            onClick={openModal}
            className="mt-6 flex items-center rounded-sm justify-center text-center"
          >
            <svg
              className="h-6 w-6 text-gray-800"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M8.6 5.2A1 1 0 0 0 7 6v12a1 1 0 0 0 1.6.8l8-6a1 1 0 0 0 0-1.6l-8-6Z"
                clipRule="evenodd"
              />
            </svg>
            <p className="mt-1"> Watch how it works</p>
          </Button>
        </div>
      </div>
      <VideoModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        videoSrc={`${videoUrl}`}
      />
    </Card>
  )
}
