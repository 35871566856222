import React, { useRef } from 'react'
import CandidateFeedItem from './CandidateFeedItem'
import { useNavigate } from 'react-router-dom'

const CandidateHiringFeeds = ({ candidateFeeds, clearUserFeed, handleCompleteTest }) => {
  const containerRef = useRef(null)
  const navigate = useNavigate()

  return (
    <div className="sm:mr-5 mb-5 relative">
      <div className="mx-2 sm:ml-8 mt-8 flex flex-col sm:flex-row items-center justify-between">
        <h1 className="mt-4 text-20 text-[#111928] font-semibold">
          Candidate feeds
        </h1>
        <button
          disabled={candidateFeeds?.length === 0}
          onClick={() => clearUserFeed?.()}
          className="text-14 bg-white text-[#9CA3AF] px-4 py-2 border rounded-lg font-semibold mt-4 sm:mt-0"
        >
          Clear Feed
        </button>
      </div>
      {candidateFeeds?.length === 0 ? (
        <div className="flex flex-col items-center mt-3 h-[250px] w-full px-4">
          <p className="text-gray-500 mb-5 text-14 text-center">
            Nothing to show yet. Complete your profile to get noticed by
            employers.
          </p>
          <button
            onClick={() => navigate('/candidate-account-overview')}
            className="text-12 bg-[#1A56DB] text-white font-bold px-10 py-2 rounded-lg hover:bg-blue-600"
          >
            Go to my Quali Profile
          </button>
        </div>
      ) : (
        <div
          ref={containerRef}
          className="mx-2 sm:ml-8 mt-4 overflow-y-auto overflow-x-hidden pb-4"
          style={{ maxHeight: '320px' }}
        >
          {candidateFeeds?.map((feed, index) => (
            <div key={index} className="flex items-center gap-8 mt-4">
              <CandidateFeedItem feed={feed} handleCompleteTest={handleCompleteTest} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CandidateHiringFeeds
