import React from 'react'
import angleUpIcon from 'assets/svgs/angle-up-white-outline.svg'
import angleDownIcon from 'assets/svgs/angle-down-white-outline.svg'
import expandIcon from 'assets/svgs/arrow-up-right-down-left-outline.svg'

export const ExpandButton = ({ onClick, isDisabled }) => (
  <button onClick={onClick} className="text-white" disabled={isDisabled}>
    <img src={expandIcon} alt="expand icon" className="w-5 h-5" />
  </button>
)

export const MinimizeButton = ({ isMinimized, toggleInbox }) => (
  <button onClick={toggleInbox} className="text-white">
    <img
      src={isMinimized ? angleUpIcon : angleDownIcon}
      alt="toggle icon"
      className="w-5 h-5"
    />
  </button>
)
