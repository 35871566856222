import React from 'react'
import AppBar from '../../components/Dashboard/AppBar'
import { CandidateAppSideBar } from '../../components/Dashboard/CandidateSideBar'
import { CandidateInboxView } from 'components/Messaging/CandidateMessaging/CandidateInboxView/CandidateInboxView'
import { useLocation } from 'react-router-dom'
export const CandidateFullScreenInboxView = () => {
  const location = useLocation()
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      candidateId: params.get('candidateId'), 
    }
  }
  const { candidateId } = getQueryParams()
  return (
    <div className="h-full bg-[#f9fafb] font-satoshi-regular">
      <AppBar />
      <div className="flex flex-col md:flex-row p-4 md:p-10 min-h-screen">
        <div className="hidden md:block">
          <CandidateAppSideBar/>
        </div>
        <div className="flex flex-col w-full md:w-5/6 sm:pl-4">
          <CandidateInboxView  defaultId={candidateId}  />
        </div>
      </div>
    </div>
  )
}
