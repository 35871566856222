import { createSlice } from '@reduxjs/toolkit'

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: {
    currentUser: {},
    isLoggedIn: false,
    companySearchHistory: {},
    companySearchHistoryCount: 0,
  },
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload
      state.isLoggedIn = true
    },
    clearCurrentUser(state) {
      state.currentUser = {}
      state.isLoggedIn = false
    },
    setCompany(state, action) {
      state.currentUser['company'] = action.payload
    },
    setSearchHistory(state, action) {
      state.companySearchHistory = action.payload
    },
    setSearchHistoryCount(state, action) {
      state.companySearchHistoryCount = action.payload
    },
  },
})

export const {
  setCurrentUser,
  clearCurrentUser,
  setCompany,
  setSearchHistory,
  setSearchHistoryCount,
} = currentUserSlice.actions
export const currentUserReducer = currentUserSlice.reducer

export const selectCurrentUser = state => state.currentUser
export const selectIsLoggedIn = state => state.isLoggedIn
export const selectCompany = state => state.currentUser.currentUser.company
export const selectSearchHistory = state =>
  state.currentUser.companySearchHistory.data
export const selectSearchCount = state =>
  state.currentUser.companySearchHistoryCount
