import React from 'react'
import 'assets/css/user-settings-tab.css'
import crossIcon from 'assets/svgs/cross.svg'
import { AccountActionButton } from '../account-action-button/AccountActionButton'

export const DeleteAccountPopup = ({
  onClose,
  handleAccountDeleteClick,
  handleTemporarilyClick,
}) => {
  return (
    <div className="bg-[#FDFDFD] flex flex-col justify-center p-8 gap-8 text-black w-[630px] min-w-[630px] min-h-[310px] border rounded-lg relative main-deactivate-popup">
      <button onClick={onClose} className=" absolute top-4 right-5">
        <img
          src={crossIcon}
          alt="Close"
          className="cursor-pointer h-[24px] w-[24px]"
        />
      </button>

      <div className="flex flex-col gap-0.5">
        <span className="text-2xl font-bold main-deactivate-heading">
          Are you sure you want to delete your account?
        </span>
        <span className="text-base font-normal text-[#6B7280] main-deactivate-sub-heading">
          By continuing, you will delete your entire Quali profile and history.
          If you need to take a break, you can Deactivate your account instead.
        </span>
      </div>

      <div className="flex flex-row gap-x-8 gap-y-4 justify-center account-action-buttons">
        <AccountActionButton
          label="Temporarily deactivate"
          onClick={handleTemporarilyClick}
          variant="default"
          fontSize="14"
          minWidth="48"
        />
        <AccountActionButton
          label="Delete my profile "
          onClick={handleAccountDeleteClick}
          variant="danger"
          fontSize="14"
          minWidth="48"
        />
      </div>
    </div>
  )
}
