import React from 'react'
import ProgressBar from './ProgressBar'
import { Button } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'

export const ProfileCompletionBar = ({ percentage, overviewImage }) => {
  const navigate = useNavigate()
  return (
    <div className="flex justify-between border-b-2 h-auto mr-4 px-2 mt-5 border-gray-200 shadow rounded-lg bg-white">
      <div className="flex mb-10 sm:mb-0 md:mb-10 flex-col mt-2 px-2 ">
        <p className="text-gray-500  ">What's next?</p>
        <h1 className="mb-4 text-2xl  font-bold">
          Complete your company profile
        </h1>
        <div className=" text-md  text-gray-600">
          <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
            <ProgressBar percentage={percentage} />
          </div>
          <p className="text-gray-500 mt-3">{percentage}% completed</p>
        </div>
        {percentage !== 100 && (
        <Button
          color={'transparent'}
          size={'sm'}
          className="border mt-2 md:w-full -mb-4 border-[#1a56db] text-[#1a56db] font-semibold w-full max-w-[200px] rounded-[4px]"
          onClick={() => {
            navigate('/complete-company-profile/company')
          }}
          >
            Complete Now
        </Button>
        )}
      </div>
      <div className=" justify-end ml-auto mr-5">
      <img src={overviewImage} className=" w-[80%]  translate-x-10  " alt="Overview" />

    </div>
  </div>
  )
}
