import { Button } from 'flowbite-react'
import { FieldArray } from 'formik'
import { COUNTRY_CITY } from 'data/registerFormData'
import { useEffect, useState } from 'react'
import { PreviousExperienceCard } from './PreviousExperienceCard'
import { EditWorkExperienceComponent } from './EditWorkExperienceComponent'
import { findExistingExperienceIndex } from './experience-helpers/experienceHelpers'

export const WorkExperienceComponent = ({
  values,
  userId,
  addedOrModifiedExperience,
  setAddedOrModifiedExperience,
  setIsEditing,
  isExpOrCertValuesTouched,
  setDeleteExperiencesList,
  deleteWorkHistories,
}) => {
  const [previousExperience, setPreviousExperience] = useState(
    values?.workExperience || [],
  )
  const [cities, setCities] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [isCurrentWorking, setIsCurrentWorking] = useState(false)
  const [isModifyRequested, setIsModifyRequested] = useState(false)
  const [modifyThisExperience, setModifyThisExperience] = useState({})
  const [isDeleted, setIsDeleted] = useState(false)
  const [deleteThisExperience, setDeleteThisExperience] = useState({})
  const [editingIndex, setEditingIndex] = useState(null)

  const handleCurrentWorkingChange = () => {
    if (previousExperience?.length > 0) {
      previousExperience.some(exp => {
        if (exp.currentWorking === 'Yes') {
          setIsCurrentWorking(true)
          return true
        }
        return false
      })
    } else if (
      addedOrModifiedExperience &&
      addedOrModifiedExperience?.length > 0
    ) {
      addedOrModifiedExperience.some(exp => {
        if (exp.currentWorking === 'Yes') {
          setIsCurrentWorking(true)
          return true
        }
        return false
      })
    }
  }

  const handleCountryChange = value => {
    setCities(COUNTRY_CITY[value] || [])
  }

  const handleAddExperience = () => {
    setShowForm(true)
    setIsModifyRequested(false)
  }

  useEffect(() => {
    if (isModifyRequested) {
      setIsEditing(true)
    } else {
      showForm ? setIsEditing(true) : setIsEditing(false)
    }
  }, [showForm, isModifyRequested])

  useEffect(() => {
    if (isDeleted) {
      if (deleteThisExperience.workHistoryId) {
        setDeleteExperiencesList(deleteThisExperience)
        deleteWorkHistories(deleteThisExperience.workHistoryId)
      }

      const prevExpindex = findExistingExperienceIndex(
        previousExperience,
        deleteThisExperience,
      )
      const addedModifiedIndex = findExistingExperienceIndex(
        addedOrModifiedExperience,
        deleteThisExperience,
      )
      if (prevExpindex !== -1 && addedModifiedIndex !== -1) {
        const updatedWorkExperienceList = previousExperience.filter(
          (exp, i) => i !== prevExpindex,
        )
        const updatedAddedOrModifiedExperience =
          addedOrModifiedExperience.filter((exp, i) => i !== addedModifiedIndex)
        setPreviousExperience([...updatedWorkExperienceList])
        setAddedOrModifiedExperience([...updatedAddedOrModifiedExperience])
      } else if (prevExpindex !== -1 && addedModifiedIndex === -1) {
        const updatedWorkExperienceList = previousExperience.filter(
          (exp, i) => i !== prevExpindex,
        )
        setPreviousExperience([...updatedWorkExperienceList])
      }
      setIsDeleted(false)
      isExpOrCertValuesTouched.current = true
    }
  }, [isDeleted, previousExperience])

  useEffect(() => {
    handleCurrentWorkingChange()
  }, [previousExperience, addedOrModifiedExperience])

  useEffect(() => {
    setPreviousExperience(values?.workExperience || [])
  }, [values])

  return (
    <>
      <FieldArray
        name="prevWorkExperience"
        render={() => (
          <>
            {previousExperience.length > 0 &&
              previousExperience.map((work, index) => (
                <div key={index}>
                  <PreviousExperienceCard
                    key={index}
                    userId={userId}
                    work={work}
                    setIsModifyRequested={setIsModifyRequested}
                    setModifyThisExperience={setModifyThisExperience}
                    setIsDeleted={setIsDeleted}
                    setDeleteThisExperience={setDeleteThisExperience}
                    setEditingIndex={setEditingIndex}
                    editingIndex={index}
                    showForm={showForm}
                    isModifyRequested={isModifyRequested}
                    setIsCurrentWorking={setIsCurrentWorking}
                    deleteWorkHistories={deleteWorkHistories}
                  />
                  {isModifyRequested && editingIndex === index && (
                    <EditWorkExperienceComponent
                      handleCountryChange={handleCountryChange}
                      cities={cities}
                      setCities={setCities}
                      workExperience={modifyThisExperience}
                      isModifyRequested={isModifyRequested}
                      setIsModifyRequested={setIsModifyRequested}
                      addedOrModifiedExperience={addedOrModifiedExperience}
                      setAddedOrModifiedExperience={
                        setAddedOrModifiedExperience
                      }
                      previousExperience={previousExperience}
                      setPreviousExperience={setPreviousExperience}
                      setShowForm={setShowForm}
                      isCurrentWorking={isCurrentWorking}
                      setIsCurrentWorking={setIsCurrentWorking}
                    />
                  )}
                </div>
              ))}
          </>
        )}
      />

      {!isModifyRequested && showForm && (
        <>
          <EditWorkExperienceComponent
            handleCountryChange={handleCountryChange}
            cities={cities}
            isModifyRequested={isModifyRequested}
            setIsModifyRequested={setIsModifyRequested}
            addedOrModifiedExperience={addedOrModifiedExperience}
            setAddedOrModifiedExperience={setAddedOrModifiedExperience}
            previousExperience={previousExperience}
            setPreviousExperience={setPreviousExperience}
            setShowForm={setShowForm}
            isCurrentWorking={isCurrentWorking}
            setIsCurrentWorking={setIsCurrentWorking}
          />
        </>
      )}
      <div className="flex w-full justify-center">
        <Button
          className="px-6 text-[#1C64F2] text-center font-satoshi-variable cursor-pointer mb-4 hover:bg-transparent border-none shadow-none outline-none focus:outline-none focus:ring-0"
          onClick={handleAddExperience}
          disabled={showForm || isModifyRequested}
        >
          Add Other Work History
        </Button>
      </div>
    </>
  )
}
