import React, { useCallback, useEffect, useState } from 'react'
import AccountInfo from './AccountInfo'
import {
  PasswordValidation,
  nameValidationSchema,
} from 'validations/registerValidationSchema'
import ChangePassword from './ChangePassword'
import 'react-international-phone/style.css'
import { PhoneInput } from 'react-international-phone'
import 'assets/css/CompanyProfile.css'
import {
  getCurrentUser,
  getUserIdByPhoneNumberOrEmail,
  updateUser,
  verifyPasswordForUser,
} from 'services/userService'
import Loader from 'components/generics/loader/Loader'
import { EMAIL_REGEX } from 'validations/constants'
import * as yup from 'yup'
import 'assets/css/ManageProfile.css'
import { useDispatch } from 'react-redux'
import { setCurrentUser } from '../../../redux/currentUserSlice'
import { updateCompany } from 'lib/api'
import { Modal } from 'flowbite-react'
import OTPConfirmation from 'components/CompanyReister/OTPConfirmation'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { auth } from '../../../firebaseConfig'
import { areObjectsSimilar } from 'utilis/helpers'

const ManageAccount = ({ companyData, activeTab }) => {
  const [profileData, setProfileData] = useState({
    firstName: companyData?.first_name || '',
    lastName: companyData?.last_name || '',
    email: companyData?.email || '',
    contactNumber: companyData?.phone_no || '',
    currentPassword: '',
    password: '',
    repeatPassword: '',
    countryCode: '',
  })
  const [initialState, setInitialState] = useState(profileData)
  const [phone, setPhone] = useState(companyData?.phone_no || '')
  const [errors, setErrors] = useState({})
  const [loading, setIsLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [dataChanged, setDataChanged] = useState(false)
  const [openOtpModal, setOtpOpenModal] = useState(false)
  const [confirmationResult, setConfirmationResult] = useState(false)
  const [idUser, setIdUser] = useState('')
  const [otpVerified, setOtpVerified] = useState(false)
  const [emailOrPhoneChange, setEmailOrPhoneChange] = useState(false)
  const [currentPasswordCorrect, setCurrentPasswordCorrect] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    setInitialState(profileData)
  }, [])

  useEffect(() => {
    clearStates(companyData, false, true)
  }, [activeTab])

  const clearStates = useCallback(
    (data, dataChanged, clearErrors) => {
      setProfileData({
        firstName: data?.first_name || '',
        lastName: data?.last_name || '',
        email: data?.email || '',
        contactNumber: data?.phone_no || '',
        currentPassword: '',
        password: '',
        repeatPassword: '',
        countryCode: '',
      })
      setInitialState({
        firstName: data?.first_name || '',
        lastName: data?.last_name || '',
        email: data?.email || '',
        contactNumber: data?.phone_no || '',
        currentPassword: '',
        password: '',
        repeatPassword: '',
      })
      clearErrors && setErrors({})
      setPhone(data?.phone_no || '')
      setIsLoading(false)
      setSubmitting(false)
      setDataChanged(dataChanged)
      setOtpVerified(false)
    },
    [companyData],
  )

  const validateNameField = async (name, value) => {
    try {
      await nameValidationSchema.validateAt(name, { [name]: value })
      setErrors(prevErrors => {
        const { [name]: _, saveChanges, ...restErrors } = prevErrors
        return restErrors
      })
    } catch (err) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: err.message,
      }))
    }
  }

  const handleChange = event => {
    const { name, value } = event.target
    setProfileData(prevState => ({ ...prevState, [name]: value }))
    setDataChanged(true)

    if (name === 'email' || name === 'repeatPassword') {
      setEmailOrPhoneChange(true)
      checkEmailValid(name, value)
    } else {
      setEmailOrPhoneChange(false)
    }

    if (name === 'firstName' || name === 'lastName') {
      validateNameField(name, value)
    }

    if (name === 'password') {
      onPasswordChange(name, value)
    }

    if (name === 'currentPassword') {
      setEmailOrPhoneChange(true)
      verifyPassword(value)
    }
  }

  const onPasswordChange = async (name, value) => {
    setEmailOrPhoneChange(true)
    if (name === 'password') {
      setProfileData(prevState => ({
        ...prevState,
        [name]: value,
        repeatPassword: '',
      }))
    } else {
      setProfileData(prevState => ({
        ...prevState,
        [name]: value,
      }))
    }

    await validateForm(value)
  }

  const validateForm = async value => {
    try {
      await yup.reach(PasswordValidation, 'password').validate(value)
      setErrors(prevErrors => {
        const { password, repeatPassword, validationErrors, ...restErrors } =
          prevErrors
        return restErrors
      })
    } catch (err) {
      const validationErrors = {}
      err?.inner?.forEach(error => {
        validationErrors[error.path] = error?.message
      })
      setErrors(prevState => ({
        ...prevState,
        validationErrors,
      }))
    }
  }

  const checkEmailValid = (name, value) => {
    if (name === 'email') {
      if (!EMAIL_REGEX.test(value)) {
        setErrors(prevErrors => ({
          ...prevErrors,
          email: 'Please enter a valid email address.',
        }))
      } else {
        setErrors(prevErrors => {
          const { email, saveChanges, ...restErrors } = prevErrors
          return restErrors
        })
      }
    }
  }

  const verifyPassword = async value => {
    try {
      const response = await verifyPasswordForUser(value)
      if (response?.status === 200) {
        setCurrentPasswordCorrect(true)
        setIsLoading(false)
        setErrors(prevErrors => {
          const { currentPassword, success, saveChanges, ...restErrors } =
            prevErrors
          return restErrors
        })
      }
    } catch (error) {
      setIsLoading(false)
      setCurrentPasswordCorrect(false)
      if (error?.response?.status === 401) {
        setErrors(prevErrors => ({
          ...prevErrors,
          currentPassword: 'The password you entered is incorrect',
          success: false,
        }))
      }
    }
  }

  const updateCompanyOnChange = async formData => {
    try {
      const response = await updateCompany(companyData?.company?.id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return response
    } catch (error) {}
  }

  const setSaveChangesMessage = (message, success = false, timeout = 2000) => {
    setErrors(prevErrors => ({
      ...prevErrors,
      saveChanges: message,
      success,
    }))

    setTimeout(() => {
      setErrors(prevErrors => {
        const { saveChanges, ...restErrors } = prevErrors
        return restErrors
      })
    }, timeout)
  }

  const handleSubmit = async () => {
    if (areObjectsSimilar(initialState, profileData)) {
      setDataChanged(false)
      return
    }
    setSubmitting(true)
    profileData?.currentPassword &&
      (await verifyPassword(profileData?.currentPassword))

    if (Object.keys(errors).length > 0 && !errors?.success) {
      if (
        [
          'email',
          'password',
          'currentPassword',
          'repeatPassword',
          'firstName',
          'lastName',
        ].some(key => errors[key])
      ) {
        setSaveChangesMessage('Your changes could not be saved.', false)
      }
      return
    }

    const user = new FormData()
    const company = new FormData()
    profileData?.firstName &&
      user.append('user[first_name]', profileData?.firstName)
    profileData?.lastName &&
      user.append('user[last_name]', profileData?.lastName)
    profileData?.email && user.append('user[email]', profileData?.email)
    profileData?.countryCode &&
      user.append('user[country_code', profileData?.countryCode)
    phone && user.append('user[phone_no]', phone)
    profileData?.password &&
      user.append('user[password]', profileData?.password)
    profileData?.email &&
      company.append('company[contact_email]', profileData?.email)
    phone && company.append('company[contact_number]', phone)

    if (
      (currentPasswordCorrect && emailOrPhoneChange && !otpVerified) ||
      (!profileData.currentPassword && emailOrPhoneChange && !otpVerified)
    ) {
      sendOtp()
    }
    if (emailOrPhoneChange && !otpVerified) {
      return
    }
    if (profileData?.currentPassword && !currentPasswordCorrect) {
      return
    }
    setIsLoading(true)
    try {
      const response = await updateUser(companyData.id, user)
      setIsLoading(false)
      if (response?.status === 200) {
        updateCompanyOnChange(company)
        setSubmitting(false)
        setSaveChangesMessage('Changes saved! All set.', true, 2000)
        setDataChanged(false)
        const currentUser = await getCurrentUser()
        dispatch(setCurrentUser(currentUser?.data))
        setTimeout(() => {
          setErrors({})
        }, 2500)
        clearStates(currentUser?.data, false, false)
      }
    } catch (error) {
      setSubmitting(false)
      setIsLoading(false)
      setSaveChangesMessage('Your changes could not be saved.', false)
    }
  }

  const sendOtp = async () => {
    try {
      setOtpOpenModal(true)

      const user = await getUserIdByPhoneNumberOrEmail({
        user: {
          attribute: 'phone_no',
          value: companyData?.phone_no,
        },
      })

      if (user.data.id) {
        console.log('INSIDE the function')
        if (!window.recaptchaVerifier) {
          console.log('Initializing new reCAPTCHA...')
          window.recaptchaVerifier = new RecaptchaVerifier(
            auth,
            'recaptcha-firebase', // Container ID
            {
              size: 'invisible', // Invisible reCAPTCHA mode
            },
          )
        } else {
          console.log('Resetting existing reCAPTCHA...')
        }
        setIdUser(user.data.id)
        console.log('ProfileData number', profileData)
        const confirmationResult = await signInWithPhoneNumber(
          auth,
          profileData?.contactNumber,
          window.recaptchaVerifier,
        )
        console.log('CONFIRMATION RESULT', confirmationResult)
        setConfirmationResult(confirmationResult)
        alert('OTP sent successfully!')
      } else {
        alert("User with this Phone Number Doesn't Exist")
      }
    } catch (error) {
      if (error.inner) {
        alert("User with this Phone Number Doesn't Exist")
        const validationErrors = {}
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message
        })
        setErrors(validationErrors)
      } else {
        console.error('Error sending OTP:', error)
      }
    }
  }

  const resendOtp = async () => {
    const appVerifier = window.recaptchaVerifier

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        profileData?.contactNumber,
        appVerifier,
      )
      setConfirmationResult(confirmationResult)
      alert('OTP resent successfully!')
    } catch (error) {
      if (error.inner) {
        const validationErrors = {}
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message
        })
        setErrors(validationErrors)
      } else {
        console.error('Error resending OTP:', error)
      }
    }
  }

  useEffect(() => {
    if (otpVerified) {
      handleSubmit()
    }
  }, [otpVerified])

  useEffect(() => {
    if (loading) {
      document.documentElement.style.overflow = 'hidden'
      document.documentElement.style.height = '100vh'
    } else {
      document.documentElement.style.overflow = ''
      document.documentElement.style.height = ''
    }

    return () => {
      document.documentElement.style.overflow = ''
      document.documentElement.style.height = ''
    }
  }, [loading])

  const handlePhoneInputChange = (phone, meta) => {
    setEmailOrPhoneChange(true)
    setDataChanged(true)
    setPhone(phone)
    setProfileData(prevState => ({
      ...prevState,
      contactNumber: phone,
      countryCode: `+${meta?.country?.dialCode}`,
    }))
  }

  const handleOtpVerified = () => {
    setOtpVerified(true)
  }
  return (
    <>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
          <Loader />
        </div>
      )}
      <div id="recaptcha-firebase"></div>
      <div className="flex flex-row below-767:flex-col mb-5 below-767:items-center">
        <h1 className="text-18 font-bold text-gray-900">Account Information</h1>
        <div className="ml-auto below-767:ml-0 flex flex-row below-767:flex-col gap-5">
          {errors.saveChanges && (
            <p
              className={`text-14 ${errors?.success ? 'text-green-600' : 'text-red-700'} below-767:ml-0 ml-auto font-[500] mt-2`}
            >
              {errors.saveChanges}
            </p>
          )}
          <button
            className={`text-14 ${dataChanged ? 'bg-primary-700 text-white' : 'bg-white text-[#1A56DB]'} below-767:mt-2 border border-[#1A56DB] font-[500] py-2 lg:px-4 px-7 rounded-lg mb-2 lg:mb-0`}
            onClick={handleSubmit}
          >
            Save Changes
          </button>
        </div>
      </div>
      <AccountInfo
        profileData={profileData}
        handleChange={handleChange}
        errors={errors}
      />
      <hr className="mt-4 mb-4 border-dashed border-gray-200 border-t-2" />
      <h1 className="mt-2 mb-2 text-16 font-bold text-gray-900">
        Change Password
      </h1>
      <ChangePassword
        profileData={profileData}
        handleChange={handleChange}
        errors={errors}
        setErrors={setErrors}
        submitting={submitting}
      />
      <hr className="mt-4 mb-4 border-dashed border-gray-200 border-t-2" />
      <h1 className="mt-2 mb-2 text-16 font-bold text-gray-900">
        Change Number
      </h1>
      <div className="w-full">
        <h2 className="mt-3 mb-2 text-14 font-bold text-gray-900 font-[500]">
          Contact Number
        </h2>
        <PhoneInput
          defaultCountry="us"
          value={phone}
          onChange={(phone, meta) => handlePhoneInputChange(phone, meta)}
          placeholder="123 4567 890"
        />
        <h2 className="mt-2 mb-2 text-14 font-medium text-gray-500">
          We will re-send confirmation code through this number.
        </h2>
      </div>
      <Modal
        show={openOtpModal}
        theme={{
          content: {
            base: 'bg-transparent w-3/4',
            inner: 'bg-transparent',
          },
        }}
      >
        <div className="flex items-center justify-center">
          <OTPConfirmation
            confirmationResult={confirmationResult}
            handleSubmit={handleSubmit}
            ForgotPasswordForm={true}
            userId={idUser}
            setOpenModal={setOtpOpenModal}
            phoneNo={phone}
            resendOtp={resendOtp}
            handleOtpVerified={handleOtpVerified}
            fromManageProfile={true}
          />
        </div>
      </Modal>
    </>
  )
}

export default ManageAccount
