import React from 'react'

export const CandidateCertifications = ({ certifications }) => {
  return (
    <div className="mt-6">
      <p className="text-gray-900 text-14 font-[500] mb-2">
        Certifications ({certifications?.length})
      </p>

      {certifications.length > 0 ? (
        <>
          {certifications?.map((cert, index) => (
            <div key={index}>
              <span className="text-gray-900 text-14 font-[700]">
                {cert.title}
              </span>
              <span className="text-gray-600 text-14 font-[500] flex items-center">
                {cert.description}
                <a
                  href={cert.link}
                  className="text-[#1A56DB] text-14 font-[500] ml-1"
                >
                  [View]
                </a>
              </span>
            </div>
          ))}
        </>
      ) : (
        <div className="flex justify-center items-center mt-4">
          <span className="text-gray-400 text-14 font-[500]">
            No certification added, yet.
          </span>
        </div>
      )}
    </div>
  )
}
