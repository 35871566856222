import React from 'react';
import { Modal, Button } from 'flowbite-react';

export const LogOutModal = ({ logoutModalOpen, setLogoutModalOpen, handleLogoutConfirmed }) => {
  return (
    <Modal
      show={logoutModalOpen}
      size="md"
      onClose={() => setLogoutModalOpen(false)}
      popup
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-50"
    >
      <div className="p-8 bg-white rounded shadow-md">
        <p className="text-2xl text-center font-bold mb-4">
          Log Out Confirmation
        </p>
        <p className="text-lg text-center mb-8">
          Are you sure you want to log out?
        </p>
        <div className="flex justify-center space-x-4">
          <Button
            onClick={() => setLogoutModalOpen(false)}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
          >
            Cancel
          </Button>
          <Button
            onClick={handleLogoutConfirmed}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

