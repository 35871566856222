import React from 'react'

function ProgressBar({ percentage }) {
  return (
    <div
      className="bg-blue-600 h-1.5 rounded-full dark:bg-blue-500"
      style={{ width: `${percentage}%` }}
    ></div>
  )
}

export default ProgressBar
