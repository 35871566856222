import { Button } from 'flowbite-react'
import React from 'react'
import { useLocation } from 'react-router-dom'

const PaginationFooter = ({
  currentPage,
  totalPages,
  totalCount,
  handleNextPage,
  handlePrevPage,
  handlePageClick,
}) => {
  const location = useLocation()

  const renderPagination = () => {
    const pages = []
    const maxPagesToShow = 3

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <li key={i}>
            <Button
              onClick={() => handlePageClick(i)}
              className={`flex items-center rounded-none justify-center h-7 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${
                currentPage === i
                  ? 'text-blue-600 bg-blue-50 hover:bg-blue-100'
                  : 'dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
              }`}
            >
              {i}
            </Button>
          </li>,
        )
      }
    } else {
      const firstPages = [1, 2]
      const lastPage = totalPages
      firstPages.forEach(page => {
        pages.push(
          <li key={page}>
            <Button
              onClick={() => handlePageClick(page)}
              className={`flex items-center justify-center h-7 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${
                currentPage === page
                  ? 'text-blue-600 bg-blue-50 hover:bg-blue-100'
                  : 'dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
              } rounded-none`}
            >
              {page}
            </Button>
          </li>,
        )
      })
      pages.push(
        <li key="ellipsis">
          <span className="flex items-center justify-center px-3 h-7 leading-tight text-gray-500 bg-white border border-gray-300">
            ...
          </span>
        </li>,
      )
      pages.push(
        <li key={lastPage}>
          <Button
            onClick={() => handlePageClick(lastPage)}
            className={`flex items-center justify-center h-7 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${
              currentPage === lastPage
                ? 'text-blue-600 bg-blue-50 hover:bg-blue-100'
                : 'dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
            } rounded-none`}
          >
            {lastPage}
          </Button>
        </li>,
      )
    }

    return pages
  }

  const renderPaginationInfo = () => {
    let startItem = 0
    let endItem = 0
    const itemsPerPage = location.pathname === '/all-quali-tests' ? 9 : location.pathname === '/test-list-of-candidiate' ||  location.pathname ===  '/searched-candidates' ? 10 :12

    startItem = (currentPage - 1) * itemsPerPage + 1
    endItem = Math.min(totalCount, currentPage * itemsPerPage)

    return (
      <span className="lg:ml-2 lg:px-0 px-16 lg:text-sm text-xs font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block lg:w-full lg:inline lg:w-auto">
        Showing{' '}
        <span className="lg:ml-2 ml-1 lg:mr-2 mr-1 font-semibold text-gray-900 dark:text-white">
          {isNaN(startItem) ? 0 : startItem} -{' '}
          {isNaN(endItem) ? itemsPerPage : endItem}
        </span>{' '}
        of{' '}
        <span className="lg:ml-2 ml-1 font-semibold text-gray-900 dark:text-white">
          {isNaN(totalCount) ? itemsPerPage : totalCount}
        </span>
      </span>
    )
  }

  return (
    <div className="flex lg:flex-row flex-col items-center justify-between py-4 px-6 bg-white border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      {renderPaginationInfo()}
      <nav aria-label="Table navigation">
        <ul className="inline-flex items-center -space-x-px text-sm h-7">
          <li>
            <Button
              onClick={handlePrevPage}
              className={`flex items-center justify-center rounded-r h-7 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${
                currentPage === 1
                  ? 'cursor-not-allowed opacity-50 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                  : ''
              }`}
              disabled={currentPage === 1}
            >
              &lt;
            </Button>
          </li>
          {renderPagination()}
          <li>
            <Button
              onClick={handleNextPage}
              className={`flex items-center justify-center rounded-l h-7 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${
                currentPage === totalPages
                  ? 'cursor-not-allowed opacity-50 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                  : ''
              }`}
              disabled={currentPage === totalPages}
            >
              &gt;
            </Button>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default PaginationFooter
