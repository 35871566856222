import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Label, Checkbox, Button } from 'flowbite-react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { updateAccountInactiveStatus } from 'services/userService'
import { PreferenceValidationSchema } from 'validations/CandidateProfileScehma'
import useToastMessage from 'hooks/useToastMessage'
import {
  createPreferences,
  getUserPreferences,
} from 'services/preferenceService'
import 'assets/css/experience-form.css'
import {
  setCurrentUser,
  selectCurrentUser,
} from '../../../../redux/currentUserSlice'
import { CardLayoutPreferences } from './CardLayoutPreferences'
import { isMediumMobileDevice } from 'utilis/helpers'
import { updateIsSignUpCompleted } from 'services/userService'

const PreferencesForm = ({
  setSelectedCard = () => {},
  isSignUpView = false,
}) => {
  const { currentUser } = useSelector(selectCurrentUser)
  const dispatch = useDispatch()
  const toastMessage = useToastMessage()
  const [userPreferences, setUserPreferences] = useState(null)
  const [isSubmittingForm, setIsSubmittingForm] = useState(false)
  const navigate = useNavigate()
  const isMobile = isMediumMobileDevice()
  const changesSaved = useRef(false)

  const fetchUserPreferences = async () => {
    try {
      const response = await getUserPreferences(currentUser.id)
      if (response.status === 200) {
        const data = response.data[0] || {
          preferred_languages: [],
          accommodation: false,
          transportation: false,
          annual_flight: false,
          salary_range: '',
        }
        setUserPreferences(data)
      }
    } catch (error) {
      console.error('Error fetching user preferences', error)
    }
  }

  const handleSubmit = async values => {
    setIsSubmittingForm(true)
    const { languages, accommodation, transportation, flight, salaryRange } =
      values
    const body_preference = {
      preferred_languages: languages,
      accommodation: accommodation === 'true',
      transportation: transportation === 'true',
      annual_flight: flight === 'true',
      salary_range: salaryRange,
    }
    try {
      const response = await createPreferences(currentUser.id, body_preference)
      if (response.status === 201) {
        await updateIsSignUpCompleted(currentUser?.id, true)
        await updateAccountInactiveStatus(currentUser?.id, false)
        dispatch(setCurrentUser(response.data.user))
        toastMessage('Updated successfully!', 'success')
        isSignUpView ? navigate('/overview') : (changesSaved.current = true)
      } else {
        toastMessage('Error Updating profile', 'error')
      }
    } catch (error) {
      console.error('Error creating preferences', error)
    } finally {
      setIsSubmittingForm(false)
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchUserPreferences()
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [currentUser])

  return (
    <>
      {!userPreferences && <div className="sm:w-4/5"></div>}
      {currentUser && userPreferences && (
        <Formik
          initialValues={{
            languages: userPreferences?.preferred_languages || [],
            salaryRange: userPreferences?.salary_range || '',
            accommodation: userPreferences?.accommodation?.toString() || 'false',
            transportation: userPreferences?.transportation?.toString() || 'false',
            flight: userPreferences?.annual_flight?.toString() || 'false',
          }}
          validationSchema={PreferenceValidationSchema}
          onSubmit={values => {
            handleSubmit(values)
          }}
          enableReinitialize={true}
        >
          {({ values, handleChange, isSubmitting, setFieldValue, dirty }) => {
            const preferenceFormContent = (
              <>
                <div className="flex flex-col gap-y-4 my-3">
                  <h1
                    className={`text-2xl ${
                      isSignUpView
                        ? 'tracking-tight text-gray-900 font-satoshi-variable'
                        : 'font-bold leading-5 text-black'
                    }`}
                  >
                    Your Preferences 👌🏻
                  </h1>
                  <p className="text-gray-600 font-medium text-base leading-5">
                    Please add any additional skills and employment preferences
                    to help us match you with the right opportunities
                  </p>
                  <div className="border-dashed border-b-2 border-[#E3E3E3]" />
                </div>
                <Form disabled={isSubmittingForm}>
                  <div
                    className={`flex flex-row justify-between items-center ${
                      !isSignUpView && 'my-3'
                    }`}
                  >
                    <h1 className="text-lg font-bold leading-5 text-black">
                      {isSignUpView ? 'Your Preferences' : 'Preference'}
                    </h1>
                    {!isSignUpView && (
                      <div
                        className={`flex ${
                          isMobile ? 'flex-col' : 'flex-row gap-2'
                        } items-center save-changes-text-container`}
                      >
                        {changesSaved.current && !dirty && (
                          <div>
                            <p className="text-sm font-bold text-green-700">
                              Changes saved! All set.
                            </p>
                          </div>
                        )}
                        <div className="my-2">
                          <button
                            type="submit"
                            className={`text-14 ${
                              dirty
                                ? 'bg-primary-700 text-white'
                                : 'bg-white text-[#1A56DB]'
                            } below-767:mt-2 border border-[#1A56DB] font-[500] py-2 lg:px-4 px-7 rounded-lg mb-2 lg:mb-0`}
                            disabled={!dirty || isSubmittingForm}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-full flex flex-col gap-2 mt-7">
                    <div className="flex justify-between language-speaks-container">
                      <Label
                        className="text-gray-900 font-medium text-base leading-6"
                        htmlFor="currentJob"
                        value="What languages do you speak ?"
                      />
                      <div className="flex items-center gap-4">
                        {['English', 'Arabic'].map(language => (
                          <div
                            key={language}
                            className="flex items-center gap-2"
                          >
                            <Checkbox
                              id={language}
                              name="languages"
                              value={language}
                              checked={values.languages.includes(language)}
                              onChange={() => {
                                const set = new Set(values.languages)
                                if (set.has(language)) {
                                  set.delete(language)
                                } else {
                                  set.add(language)
                                }
                                setFieldValue('languages', Array.from(set))
                              }}
                            />
                            <Label
                              className="text-gray-900 font-medium text-base leading-6"
                              htmlFor={language}
                            >
                              {language}
                            </Label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <ErrorMessage
                      name="languages"
                      component="div"
                      className="text-red-500 flex justify-end"
                    />
                    <div className="flex flex-row gap-2 mt-2 items-center language-speaks-container">
                      <label
                        htmlFor="salaryRange"
                        className="text-gray-900 font-medium text-base leading-6 flex-1"
                      >
                        What is your minimum preferred salary range (yearly)?
                      </label>

                      <select
                        id="salaryRange"
                        onChange={e => {
                          setFieldValue('salaryRange', e.target.value)
                        }}
                        value={values.salaryRange}
                        className="mt-2 block pl-3 pr-10 py-2 text-sm bg-gray-50 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md border border-gray-300 w-56"
                      >
                        <option value="" disabled>
                          Choose Salary Range
                        </option>
                        <option value="<24000">{'< 24,000 SAR'}</option>
                        <option value="24000-36000">
                          {'24,000 - 36,000 SAR'}
                        </option>
                        <option value="36000-48000">
                          {'36,000 - 48,000 SAR'}
                        </option>
                        <option value=">48000">{'+ 48,000 SAR'}</option>
                      </select>
                    </div>
                    <div className="flex justify-end mr-2">
                      <ErrorMessage
                        name="salaryRange"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div className="border-dashed border-b-2 border-[#E3E3E3] mt-4 mb-6"></div>

                  <div className="w-full flex flex-col gap-2">
                    <h6 className="text-black font-bold text-lg leading-125">
                      🤹🏻 Additional Benefits
                    </h6>
                    <div>
                      <p className="text-gray-600 text-base font-medium leading-relaxed">
                        Must-have means it is absolutely required and you cannot
                        work without it. Remember, flexibility is key to getting
                        the best matches!
                      </p>
                    </div>
                    <div className="my-2">
                      <Label
                        htmlFor="accommodation"
                        value="Accommodation"
                        className="text-gray-900 text-base font-medium leading-relaxed"
                      />
                      <div
                        className={`${
                          isMobile ? 'space-x-2' : 'space-x-5'
                        } mt-1`}
                      >
                        <label>
                          <Field
                            type="radio"
                            name="accommodation"
                            value="true"
                            checked={values.accommodation === 'true'}
                            onChange={() =>
                              setFieldValue('accommodation', 'true')
                            }
                            className="mx-2"
                          />
                          Must Have
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="accommodation"
                            value="false"
                            checked={values.accommodation === 'false'}
                            onChange={() =>
                              setFieldValue('accommodation', 'false')
                            }
                            className="mx-2"
                          />
                          Not Important
                        </label>
                      </div>
                      <ErrorMessage
                        name="accommodation"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="my-2">
                      <Label
                        htmlFor="transportation"
                        value="Transport to job site"
                        className="text-gray-900 text-base font-medium leading-relaxed"
                      />
                      <div
                        className={`${
                          isMobile ? 'space-x-2' : 'space-x-5'
                        } mt-1`}
                      >
                        <label>
                          <Field
                            type="radio"
                            name="transportation"
                            value="true"
                            checked={values.transportation === 'true'}
                            onChange={() =>
                              setFieldValue('transportation', 'true')
                            }
                            className="mx-2"
                          />
                          Must Have
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="transportation"
                            value="false"
                            checked={values.transportation === 'false'}
                            onChange={() =>
                              setFieldValue('transportation', 'false')
                            }
                            className="mx-2"
                          />
                          Not Important
                        </label>
                      </div>
                      <ErrorMessage
                        name="transportation"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="my-2">
                      <Label
                        htmlFor="flight"
                        value="Annual Flight"
                        className="text-gray-900 text-base font-medium leading-relaxed"
                      />
                      <div
                        className={`${
                          isMobile ? 'space-x-2' : 'space-x-5'
                        } mt-1`}
                      >
                        <label>
                          <Field
                            type="radio"
                            name="flight"
                            value="true"
                            checked={values.flight === 'true'}
                            onChange={() => setFieldValue('flight', 'true')}
                            className="mx-2"
                          />
                          Must Have
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="flight"
                            value="false"
                            checked={values.flight === 'false'}
                            onChange={() => setFieldValue('flight', 'false')}
                            className="mx-2"
                          />
                          Not Important
                        </label>
                      </div>
                      <ErrorMessage
                        name="flight"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  {isSignUpView && (
                    <div className="flex gap-4 justify-between my-5">
                      <Button
                        className="text-blue-700 bg-white border-2 border-[#1A56DB] px-6"
                        onClick={() => setSelectedCard(prev => prev - 1)}
                      >
                        Back
                      </Button>

                      <Button
                        className="bg-blue-700 px-6"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </Form>
              </>
            )
            return (
              <>
                {isSignUpView ? (
                  <CardLayoutPreferences>
                    {preferenceFormContent}
                  </CardLayoutPreferences>
                ) : (
                  <div className="pl-4 ml-2">
                    <div className="border-none">{preferenceFormContent}</div>
                  </div>
                )}
              </>
            )
          }}
        </Formik>
      )}
    </>
  )
}

export default PreferencesForm
