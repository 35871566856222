import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isMediumMobileDevice, isSmallMobileDevice } from 'utilis/helpers'
import { selectCurrentUser } from '../../../../redux/currentUserSlice'
import { SettingToggleSwitch } from './toggle-switch/SettingToggleSwitch'
import { DeleteAccountPopup } from './delete-account-popup/DeleteAccountPopup'
import { AccountActionButton } from './account-action-button/AccountActionButton'
import { DeactivateAccountPopup } from './deactivate-account-popup/DeactivateAccountPopup'
import { CurrentPasswordPrompt } from './delete-account-popup/delete-account-password-prompt/CurrentPasswordPrompt'
import { logoutUser } from 'lib/api'
import {
  deleteCandidateAccount,
  updateAccountInactiveStatus,
} from 'services/userService'
import useToastMessage from 'hooks/useToastMessage'
import 'assets/css/user-settings-tab.css'
import { createCandidateFeedback } from 'services/candidateFeedbackService'

export const CandidateSettingTab = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toastMessage = useToastMessage()
  const { currentUser } = useSelector(selectCurrentUser)
  const isCandidate = currentUser?.role === 'candidate'
  const isMobile = isMediumMobileDevice()
  const isSmallMobile = isSmallMobileDevice()
  const [whatsappNoti, setWhatsappNoti] = useState(true)
  const [emailNoti, setEmailNoti] = useState(true)
  const [showDeactivatePopup, setShowDeactivatePopup] = useState(false)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [showPasswordPopup, setShowPasswordPopup] = useState(false)
  const [deleteAccount, setDeleteAccount] = useState(false)
  const [deactivateAccount, setDeactivateAccount] = useState(false)

  const [candidateFeedback, setCandidateFeedback] = useState({
    candidate_email: currentUser?.email,
    reason_deactivating: '',
    feedback: '',
  })

  const handleAccountDeleteClick = useCallback(() => {
    setShowDeletePopup(false)
    setShowPasswordPopup(true)
  }, [])

  const handleTemporarilyClick = useCallback(() => {
    setShowDeletePopup(false)
    setShowDeactivatePopup(true)
  }, [])

  const handleDeleteAccount = useCallback(async () => {
    try {
      const response = await deleteCandidateAccount()
      if (response.status === 200) {
        toastMessage('Your Account Deleted Successfully', 'success')
      }
    } catch (error) {
      toastMessage('Error Deleting Account Please Try Again', 'error')
    }
  }, [toastMessage])

  const handleDeactivateAccount = useCallback(async () => {
    try {
      const feedbackResponse = await createCandidateFeedback(candidateFeedback)
      if (feedbackResponse.status !== 201) {
        throw new Error('Failed to save candidate feedback')
      }

      const deactivateResponse = await updateAccountInactiveStatus(
        currentUser?.id,
        true,
      )
      if (deactivateResponse.status !== 200) {
        throw new Error('Failed to update account status')
      }

      logoutUser(dispatch, navigate, () => {}, false, isCandidate)
      toastMessage('Your Account Deactivated Successfully', 'success')
    } catch (error) {
      toastMessage('Error Deactivating Account Please Try Again', 'error')
    }
  }, [
    candidateFeedback,
    currentUser?.id,
    dispatch,
    isCandidate,
    navigate,
    toastMessage,
  ])

  useEffect(() => {
    if (deactivateAccount && isCandidate) {
      setShowDeactivatePopup(false)
      handleDeactivateAccount()
    }
  }, [deactivateAccount, handleDeactivateAccount, isCandidate])

  useEffect(() => {
    if (deleteAccount && isCandidate) {
      setShowPasswordPopup(false)
      logoutUser(dispatch, navigate, () => {}, false, isCandidate)
      handleDeleteAccount()
    }
  }, [deleteAccount, handleDeleteAccount, isCandidate])

  return (
    <div className="flex flex-col gap-y-6 px-6">
      <h1 className="text-lg font-bold text-black">Settings</h1>
      <div className="flex flex-col gap-y-4">
        <p className="text-base font-bold text-black">Notifications</p>
        <SettingToggleSwitch
          isChecked={whatsappNoti}
          onToggle={() => setWhatsappNoti(!whatsappNoti)}
          label="WhatsApp Notifications"
          description="Turn on WhatsApp notification from Quali"
        />
        <SettingToggleSwitch
          isChecked={emailNoti}
          onToggle={() => setEmailNoti(!emailNoti)}
          label="E-mail Notification"
          description="Turn on e-mail notification from Quali"
        />
        <div className="border-dashed border-b-2 border-[#E3E3E3]" />
      </div>

      <div className="flex flex-col gap-y-4">
        <p
          className={`${isMobile ? 'text-base text-nowrap' : 'text-base'} font-bold text-black deactivation-text-wrap`}
        >
          Account Deactivation and Deletion
        </p>
        <div className="flex flex-row gap-5 account-action-buttons">
          <AccountActionButton
            label="Deactivate Quali Account"
            onClick={() => setShowDeactivatePopup(true)}
            variant="default"
            minWidth={isSmallMobile ? '48' : '60'}
            fontSize={isSmallMobile ? '12' : '16'}
          />
          <AccountActionButton
            label="Delete Quali Account"
            onClick={() => setShowDeletePopup(true)}
            variant="danger"
            minWidth={isSmallMobile ? '48' : '60'}
            fontSize={isSmallMobile ? '12' : '16'}
          />
        </div>
      </div>

      {showDeactivatePopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <DeactivateAccountPopup
            setDeactivateAccount={setDeactivateAccount}
            setCandidateFeedback={setCandidateFeedback}
            onClose={() => setShowDeactivatePopup(false)}
          />
        </div>
      )}

      {showDeletePopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <DeleteAccountPopup
            onClose={() => setShowDeletePopup(false)}
            handleAccountDeleteClick={handleAccountDeleteClick}
            handleTemporarilyClick={handleTemporarilyClick}
            isCandidate={isCandidate}
          />
        </div>
      )}

      {showPasswordPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <CurrentPasswordPrompt
            onClose={() => setShowPasswordPopup(false)}
            setShowPasswordPopup={setShowPasswordPopup}
            setDeleteAccount={setDeleteAccount}
          />
        </div>
      )}
    </div>
  )
}
