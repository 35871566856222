import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'flowbite-react'
import { rename, trash } from '../../../assets/SearchHistoryIcons'
import useSearchHistoryActions from '../../../hooks/useSearchHistoryActions'
import editSearch from '../../../assets/svgs/editSearch.svg'
import { selectSearch } from '../../../redux/searchSlice'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import StartNewSearch from '../modals/StartNewSearch'
const RenameArchive = ({ isArchive, value, setCompanySearchHistory }) => {
  const {
    setSelectedId,
    searchName,
    setSearchName,
    openModal,
    setOpenModal,
    openConfirmModal,
    setOpenConfirmModal,
    handleHistoryUpdate,
    handleDeleteSearchHistory,
    errorMessage,
    setErrorMessage,
  } = useSearchHistoryActions(setCompanySearchHistory)
  const location = useLocation()

  useEffect(() => {
    setErrorMessage({
      searchName: '',
    })
  }, [openModal])

  const handleRenameClick = (name, id) => {
    setSearchName(name)
    setSelectedId(id)
    setOpenModal(true)
  }
  const search = useSelector(selectSearch)
  const [openEditModal, setopenEditModal] = useState(false)
  const [forceRenderKey, setForceRenderKey] = useState(0)
  const handleEditSearch = tab => {
    setopenEditModal(true)
    setForceRenderKey(prevKey => prevKey + 1)
  }
  const handleNameChange = e => {
    const value = e.target.value
    setSearchName(value)

    let errorMessage = ''
    if (value.trim().length > 50) {
      errorMessage = 'Search Name cannot exceed 50 characters'
    } else if (!value) {
      errorMessage = 'Please enter a search name'
    }

    setErrorMessage(prevState => ({
      ...prevState,
      searchName: errorMessage,
    }))
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (searchName.trim() === '') {
      setErrorMessage(prevState => ({
        ...prevState,
        searchName: 'Please enter a search name',
      }))
    } else if (searchName.trim().length > 50) {
      setErrorMessage(prevState => ({
        ...prevState,
        searchName: 'Search Name cannot exceed 50 characters',
      }))
    } else {
      handleHistoryUpdate('rename', event)
    }
  }

  return (
    <>
      <div className="flex items-center md:mt-2 mt-0">
        {!isArchive ? (
          <div className="flex flex-wrap">
            {location.pathname !== '/search' && (
              <button
                onClick={() => handleEditSearch()}
                className="text-blue-700 flex items-center text-base font-bold mr-3"
              >
                <img src={editSearch} alt="editSearch" className="mr-1" /> Edit
                Search
              </button>
            )}
            <button
              onClick={() => handleRenameClick(value.attributes.name, value.id)}
              className="text-[#111928] flex items-center mr-2 text-base font-normal"
            >
              <img src={rename} alt="rename" className="mr-1" /> Rename
            </button>
            <button
              onClick={() => {
                setSelectedId(value.id)
                setOpenConfirmModal(true)
              }}
              className="text-[#111928] flex items-center text-base font-normal"
            >
              <img src={trash} alt="trash" className="mr-1" /> Archive
            </button>

            {openEditModal && (
              <StartNewSearch
                key={forceRenderKey}
                modalOpen={openEditModal}
                setModalOpen={setopenEditModal}
                isNewSearch={false}
                search={search}
              />
            )}
          </div>
        ) : (
          <button
            onClick={() => {
              setSelectedId(value.id)
              setOpenConfirmModal(true)
            }}
            className="text-[#111928] flex items-center text-base font-normal"
          >
            <img src={trash} alt="trash" className="mr-1" /> Delete
          </button>
        )}
      </div>

      <Modal
        show={openModal}
        onClose={() => setOpenModal(false)}
        theme={{
          content: {
            base: 'bg-transparent w-3/4',
          },
        }}
      >
        <Modal.Header>Search Quali Talent Directory</Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <label className="block">
                <div className="flex">
                  <p className="icon-margin"> Search Name </p>
                </div>
                <input
                  type="text"
                  name="search_name"
                  value={searchName}
                  onChange={handleNameChange}
                  placeholder="Type search name"
                  className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                />
                {errorMessage.searchName && (
                  <p className="text-red-500 text-sm mt-1">
                    {errorMessage.searchName}
                  </p>
                )}
              </label>
              <div className="flex justify-end">
                <Button type="submit" color={'blue'}>
                  Update Search Name
                </Button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        className="font-global-styling"
        theme={{
          content: {
            base: 'bg-transparent',
          },
        }}
      >
        <Modal.Header>
          <div className="mx-7 text-[#111928] font-bold ">
            {isArchive ? <>Delete this search?</> : <>Archive this Search?</>}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="px-5 mx-2 text-lg text-[#374151] text-center font-medium">
            {isArchive ? (
              <>
                If you delete this search, it will be removed from your saved
                searches. However, all the candidates revealed through this
                search will still be saved for you.
              </>
            ) : (
              <>Are you sure you want to archive this search?</>
            )}
            <div className="flex justify-center mt-7">
              <Button
                className="py-2"
                onClick={event => {
                  isArchive
                    ? handleDeleteSearchHistory(event)
                    : handleHistoryUpdate('archive', event)
                }}
                type="submit"
                color={'blue'}
              >
                {isArchive ? (
                  <>I understand, delete permanently</>
                ) : (
                  <>Yes, Archive this Search</>
                )}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default RenameArchive
