import React from 'react'
import { faBell, faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, Navbar, Modal, Button } from 'flowbite-react'
import { logoutUser } from '../../lib/api'
import gridImage from '../../assets/grid.png'
import { useSelector } from 'react-redux'
import { selectCompany, selectCurrentUser } from '../../redux/currentUserSlice'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import useToastMessage from '../../hooks/useToastMessage'
import { AppDrawer } from './AppDrawer/AppDrawer'
import 'assets/css/Navbar.css'
import { TruncatedText } from 'utilis/helpers'

const AppBar = () => {
  const { currentUser } = useSelector(selectCurrentUser)
  const companyData = useSelector(selectCompany)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const toastMessage = useToastMessage()
  const isCandidate = currentUser.role === 'candidate'

  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false)

  const handleLogoutClick = () => {
    setLogoutModalOpen(true)
  }

  const handleLogoutConfirmed = () => {
    const isEmployer = currentUser?.role === 'company_admin'
    logoutUser(dispatch, navigate, toastMessage, false, isCandidate, isEmployer)
    setLogoutModalOpen(false)
  }
  return (
    <div className="">
      <Navbar fluid className="bg-blue-700 py-2 sm:px-3 text-white">
        <div className="flex items-center justify-between w-full">
          <Navbar.Brand>
            <span className="self-center whitespace-nowrap lg:ml-0 ml-3 text-xl font-semibold">
              Quali
            </span>
          </Navbar.Brand>
          <div className="flex items-center">
            <div className=" hidden lg:flex items-center gap-6 p-0.5 pr-4 border-r-2 border-white">
              <img src={gridImage} alt="grid" className="w-4 h-4 mx-auto" />
              <FontAwesomeIcon icon={faBell} />
              <FontAwesomeIcon icon={faCircleQuestion} />
            </div>
            <div className="hidden md:flex items-center pr-2 border-r-2 border-white custom-arrow">
              <Dropdown
                label={
                  <span className="text-base flex items-center">English</span>
                }
                color="transparent"
              >
                <Dropdown.Header>
                  <span className="block text-sm">Choose your language</span>
                </Dropdown.Header>
                <Dropdown.Item>Spanish</Dropdown.Item>
                <Dropdown.Item>English</Dropdown.Item>
              </Dropdown>
            </div>
            <div className="md:hidden">
              <AppDrawer />
            </div>
            <div className="hidden md:block custom-arrow">
              <Dropdown
                label={
                  ['candidate', 'admin'].includes(currentUser.role) ? (
                    <TruncatedText
                      text={`${currentUser?.first_name} ${currentUser?.last_name}`}
                      limit={50}
                    />
                  ) : (
                    <TruncatedText text={companyData?.name} limit={50} />
                  )
                }
                color="transparent"
              >
                <Dropdown.Item
                  onClick={() =>
                    navigate(
                      isCandidate
                        ? '/candidate-account-overview'
                        : '/complete-company-profile/company',
                    )
                  }
                >
                  {isCandidate
                    ? 'Complete User Profile'
                    : 'Complete Company Profile'}
                </Dropdown.Item>
                {!isCandidate && (
                  <Dropdown.Item
                    onClick={() => navigate('/complete-company-profile/plans')}
                  >
                    Select your Plan
                  </Dropdown.Item>
                )}
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleLogoutClick}>
                  Sign Out
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>
        </div>
      </Navbar>
      <Modal
        show={logoutModalOpen}
        size="md"
        onClose={() => setLogoutModalOpen(false)}
        popup
        className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-600 bg-opacity-50"
      >
        <div className="p-8 bg-white rounded shadow-md">
          <p className="text-2xl text-center font-bold mb-4">
            Log Out Confirmation
          </p>
          <p className="text-lg text-center mb-8">
            Are you sure you want to log out ?
          </p>
          <div className="flex  justify-end">
            <Button
              onClick={() => setLogoutModalOpen(false)}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold  sm:ml-0 mr-2 py-2 px-4 rounded"
            >
              Cancel
            </Button>
            <Button
              onClick={handleLogoutConfirmed}
              className="bg-red-500 hover:bg-red-700 text-white  font-bold py-2 px-4 rounded sm:mr-14 mr-8
            "
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AppBar
