import React, { useEffect, useState } from 'react'
import AdminNavbar from './AdminNavbar'
import { generateColumns } from '../../utilis/helpers/tableHelper'
import { getPaginatedIndustryList } from '../../services/industryService'
import Table from '../table/Table'

const IndustriesList = () => {
  const [industries, setIndustries] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState('')

  const fetchIndustries = async () => {
    try {
      const response = await getPaginatedIndustryList(currentPage, query)
      if (response.status === 200) {
        const sortedData = response?.data?.records?.sort((a, b) => b.id - a.id)
        setIndustries(sortedData || [])
        setTotalPages(response?.data?.pagy?.total_pages)
        setTotalCount(response?.data?.pagy?.total_count)
      }
    } catch (error) {
      console.error('Error fetching industries:', error)
    }
  }
  useEffect(() => {
    fetchIndustries()
  }, [currentPage, query])

  const onSearchIndustry = (search, value) => {
    setCurrentPage(1)
    setQuery(value)
  }

  const columns = generateColumns(industries)
  return (
    <div className="flex h-screen w-full flex-col bg-[#f9fafb]">
      <AdminNavbar selectedItem="industries" />
      <Table
        columns={columns}
        data={industries}
        title="Industry"
        getIndustries={fetchIndustries}
        onSearch={onSearchIndustry}
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        setCurrentPage={setCurrentPage}
      />
    </div>
  )
}

export default IndustriesList
