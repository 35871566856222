import * as yup from 'yup'

export const testValidationSchema = yup.object().shape({
  test_link: yup.string().required('Test Link is required'),
  title: yup.string().max(50, 'Title cannot exceed 50 characters').required('Test Title is required'),
  test_id: yup.string().required('Test is required'),
  job_title: yup.string().max(50, 'Job Title cannot exceed 50 characters').required('Job Title is required'),
  job_description: yup.string()
  .max(500, 'Description cannot exceed 500 characters')
  .required('Job Description is required.'),
  description: yup.string()
  .max(500, 'Description cannot exceed 500 characters')
  .required('Description is required.'),
})
