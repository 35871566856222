export const chatModuleSizes = {
  INBOX: {
    DEFAULT_WIDTH: 500,
    DEFAULT_HEIGHT: 400,
    EXPANDED_WIDTH: 500,
    EXPANDED_HEIGHT: 500,
  },
  CHAT: {
    DEFAULT_WIDTH: 500,
    DEFAULT_HEIGHT: 500,
    EXPANDED_WIDTH: 500,
    EXPANDED_HEIGHT: 700,
  },
}
