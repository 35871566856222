import React, { useCallback, useState,useEffect, useMemo } from 'react'
import { Resizable } from 're-resizable'
import { chatModuleSizes } from './persistent-inbox-constants'
import messageIcon from 'assets/svgs/messages-outline-white.svg'
import { ChatPopupViewModal } from './ChatPopupViewModal/ChatPopupViewModal'
import { InboxMessageBubble } from './InboxMessageBubble/InboxMessageBubble'
import { ExpandButton, MinimizeButton } from './InboxButtons/InboxButtons'
import { getConversation } from 'services/messagingService'

// used temporary for messages and avatar
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../redux/currentUserSlice'
import { selectRevealedCandidates } from '../../../redux/talentSearchSlice'
import { selectUnreadCount } from '../../../redux/messageSlice'
import { current } from '@reduxjs/toolkit'

export const PersistentMinimizableInbox = () => {
  const { currentUser } = useSelector(selectCurrentUser)
  const profilePic = currentUser?.profile_picture_url
  const inboxListing = useSelector(selectRevealedCandidates)
  const [chatListing, setChatListing] = useState([])
  const isMessageEmpty = useMemo(() => !inboxListing?.length, [inboxListing])
  const unreadCount = useSelector(selectUnreadCount)
  useEffect(() => {
    (currentUser.role==='candidate') && fetchConversation()
  }, [])

  const fetchConversation = async () => {
    const response = await getConversation()
    if (response?.status === 200) {
      setChatListing(response?.data)
    }
  }
  const [inboxState, setInboxState] = useState({
    isMinimized: true,
    isExpanded: false,
    isChatExpanded: false,
    activeChat: null,
    userMessage: '',
  })

  const toggleInbox = useCallback(() => {
    setInboxState(prevState => ({
      ...prevState,
      isMinimized: !prevState.isMinimized,
      isExpanded: false,
    }))
  }, [])

  const toggleExpandInbox = useCallback(() => {
    setInboxState(prevState => ({
      ...prevState,
      isExpanded: !prevState.isExpanded,
    }))
  }, [])

  const handleChatClick = useCallback(chat => {
    setInboxState(prevState => ({
      ...prevState,
      activeChat: chat,
    }))
  }, [])

  const closeChat = useCallback(() => {
    setInboxState(prevState => ({
      ...prevState,
      activeChat: null,
      isChatExpanded: false,
    }))
  }, [])

  const handleUserMessageChange = useCallback(event => {
    const { value } = event.target
    setInboxState(prevState => ({
      ...prevState,
      userMessage: value,
    }))
  }, [])

  const handleSendUserMessage = async () => {
    // Message sending functionality here...
  }

  return (
    <>
      <div className="fixed bottom-0 right-0 z-50 shrink-0 pr-12">
        <div className="flex flex-row justify-between items-center text-white p-4 min-w-[443px] min-h-[72px] rounded-t-lg bg-primary-700 shadow-sm">
          <div className="flex flex-row gap-4 items-center">
            <img src={messageIcon} alt="message icon" className="w-6 h-6" />
            <span className="font-bold">My Inbox</span>
            <div className="flex rounded-full w-[30px] h-[30px] bg-[#C81E1E] justify-center items-center">
              <span className="text-white text-[12px] font-medium">
                {unreadCount}
              </span>
            </div>
          </div>

          <div className="flex flex-row gap-2">
            <ExpandButton
              onClick={toggleExpandInbox}
              isDisabled={inboxState.isMinimized}
            />
            <MinimizeButton
              isMinimized={inboxState.isMinimized}
              toggleInbox={toggleInbox}
            />
          </div>
        </div>

        {!inboxState.isMinimized && (
          <div className="relative">
            <div className="flex overflow-y-auto">
              <Resizable
                defaultSize={{
                  width: chatModuleSizes.INBOX.DEFAULT_WIDTH,
                  height: inboxState.isExpanded
                    ? chatModuleSizes.INBOX.EXPANDED_HEIGHT
                    : chatModuleSizes.INBOX.DEFAULT_HEIGHT,
                }}
                minWidth={chatModuleSizes.INBOX.DEFAULT_WIDTH}
                minHeight={
                  inboxState.isExpanded
                    ? chatModuleSizes.INBOX.EXPANDED_HEIGHT
                    : chatModuleSizes.INBOX.DEFAULT_HEIGHT
                }
                lockAspectRatio={inboxState.isExpanded}
                style={{ background: 'white' }}
              >
                {isMessageEmpty && currentUser.role==='company_admin' ? (
                  <div className="flex flex-grow h-full items-center justify-center">
                    <span className="text-base text-[#585C5F]">
                      You have no messages yet.
                    </span>
                  </div>
                ) : (
                  currentUser.role==='company_admin' ? 
                 ( inboxListing.map(item => (
                    <InboxMessageBubble
                      key={item.user.id}
                      avatar={item?.user?.profile_picture_url || profilePic}
                      senderName={`${item?.user?.first_name} ${item?.user?.last_name}`}
                      message={
                        item?.user?.current_job_title ||
                        'No job title available'
                      }
                      sentTime={item?.lastSignInAt || 'N/A'}
                      unreadCount={item?.unreadMessageCount}
                      handleMessageClick={() => handleChatClick(item)}
                      chat={item}
                    />

                  )))
                  :
                 ( chatListing.map(item => (
                    <InboxMessageBubble
                      key={item?.company_id}
                      avatar={item?.logo_url}
                      senderName={item?.company_name}
                      message={
                        item?.country
                      }
                      sentTime={item?.lastSignInAt || 'N/A'}
                      unreadCount={item?.users[0]?.unread_count}
                      handleMessageClick={() => handleChatClick(item)}
                      chat={item}
                    />

                  )))
                )}
              </Resizable>
            </div>
            <div className="absolute bottom-0 left-0 right-0 h-[62px] bg-gradient-to-r from-[rgba(255,255,255,0.50)] to-white flex-shrink-0 pointer-events-none"></div>
          </div>
        )}
      </div>

      {inboxState.activeChat && (
        <div className="fixed bottom-0 right-[516px] z-50 shrink-0 pr-12">
         {currentUser.role==='company_admin'? 
          <ChatPopupViewModal
            avatar={profilePic}
            chatName={inboxState.activeChat.name}
            userMessage={inboxState.userMessage}
            isChatExpanded={inboxState.isChatExpanded}
            handleCloseChat={closeChat}
            handleExpandChat={() =>
              setInboxState(prevState => ({
                ...prevState,
                isChatExpanded: !prevState.isChatExpanded,
              }))
            }
            handleTextChange={handleUserMessageChange}
            handleSendMessage={handleSendUserMessage}
            selectedChat={inboxState.activeChat}
          />
          :
          <ChatPopupViewModal
          avatar={inboxState.activeChat?.logo_url}
          chatName={inboxState.activeChat?.company_name}
          isChatExpanded={inboxState.isChatExpanded}
          handleCloseChat={closeChat}
          handleExpandChat={() =>
            setInboxState(prevState => ({
              ...prevState,
              isChatExpanded: !prevState.isChatExpanded,
            }))
          }
          handleTextChange={handleUserMessageChange}
          handleSendMessage={handleSendUserMessage}
          selectedChat={inboxState.activeChat}
        />
      }
        </div> 
      )}
    </>
  )
}
