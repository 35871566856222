import { createSlice } from '@reduxjs/toolkit';

export const tabChangeSlice = createSlice({
  name: 'selectedTab',
  initialState: '', 
  reducers: {
    setTabChange: (state, action) => {
      state = action.payload; 
      return action.payload; 
    },
    clearTabChange: state => {
      state = ''; 
      return ''; 
    },
  },
});

export const { setTabChange, clearTabChange } = tabChangeSlice.actions;

export const selectTabReducer = tabChangeSlice.reducer;
export const selectTabChange = state => state.selectTab;
