import axiosInstance from './axiosConfig'
import { TestsEndpoints, ManagestoredTests, CandidateStoredTests } from './constants'
export const getAllTests = async (currentPage, query) => {
  const response = await axiosInstance.get(
    TestsEndpoints.allTests(currentPage, query),
  )
  return response
}
export const GetStoredtestInformation = async () => {
  return await axiosInstance.get(ManagestoredTests.GetTestDetails)
}

export const GetCandidateStoredInformation = async () => {
  return await axiosInstance.get(CandidateStoredTests.GetTestDetails)
}

export const GetPaggyCandidateStoredInformation = async (
  currentPage,
  status,
  sort_by,
  orderBy,
) => {
  const response = await axiosInstance.get(
    CandidateStoredTests.GetPagyTestDetails(currentPage, status, sort_by, orderBy),
  )
  return response
}

export const GetPagyStoredtestInformation = async (
  currentPage,
  query,
  sortstate,
) => {
  const response = await axiosInstance.get(
    ManagestoredTests.GetPagyTestDetails(currentPage, query, sortstate),
  )
  return response
}

export const editTest = async data => {
  const response = await axiosInstance.patch(
    TestsEndpoints.updateTest(data?.id),
    {
      reveal_or_like: {
        test_attributes: { title: data.title, test_link: data.test_link },
      },
    },
  )
  return response
}

export const deleteTest = async testId => {
  const response = await axiosInstance.delete(TestsEndpoints.deleteTest(testId))
  return response
}

export const sendTestToCandidate = async data => {
  const response = await axiosInstance.post(
    TestsEndpoints.sendTestToCandidate,
    data,
  )
  return response
}

export const sendTestsToCandidate = async data => {
  return await axiosInstance.post(
    TestsEndpoints.sendTestsToCandidate,
    data,
  )
}

export const uploadTestResult = async (testId, data) => {
  const response = await axiosInstance.post(
    TestsEndpoints.uploadTestResultFile(testId),
    data,
  )
  return response
}

export const uploadTestsCSV = async data => {
  const response = await axiosInstance.post(
    TestsEndpoints.uploadTestsCSVFile,
    data,
  )
  return response
}

export const sendCompletionToCandidate = async data => {
  const response = await axiosInstance.post(
    TestsEndpoints.testCompletionEmail,
    { test: data },
  )
  return response
}

export const sendPendingTestReminderEmail = async data => {
  return await axiosInstance.post(CandidateStoredTests.sendReminderEmailToCandidate, data)
}

export const getAllTestsListings = async (currentPage, query) => {
  const endpoint = currentPage
    ? TestsEndpoints.getAllTestListing(currentPage, query)
    : TestsEndpoints.createTestListing
  const response = await axiosInstance.get(endpoint)
  return response
}

export const createTestListing = async data => {
  const response = await axiosInstance.post(TestsEndpoints.createTestListing, {
    test: data,
  })
  return response
}

export const updateTestListing = async data => {
  const response = await axiosInstance.patch(
    TestsEndpoints.updateTestListing(data?.id),
    { test: data },
  )
  return response
}
