import React, { useEffect, useState, useRef } from 'react'
import { FaTimes } from 'react-icons/fa'
import { ReactComponent as DropdownIcon } from '../../../assets/svgs/angle-down-outline.svg'
import ReactCountryFlag from 'react-country-flag'

export function MultiselectDropdown({
  placeholder,
  dropdownItems,
  value,
  onChange,
  setDataChanged = () => {},
}) {
  const [selectedItems, setSelectedItems] = useState([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef(null)

  useEffect(() => {
    if (value) {
      setSelectedItems(value)
    }
  }, [value])

  const handleItemClick = item => {
    setDataChanged(true)
    if (item === 'none') {
      if (selectedItems.includes('none')) {
        setSelectedItems([])
        onChange([])
      } else {
        setSelectedItems(['none'])
        onChange(['none'])
      }
    } else {
      if (selectedItems.includes('none')) {
        return
      }

      let updatedSelectedItems
      if (selectedItems.includes(item)) {
        updatedSelectedItems = selectedItems.filter(i => i !== item)
      } else {
        updatedSelectedItems = [...selectedItems, item]
      }

      setSelectedItems(updatedSelectedItems)
      onChange(updatedSelectedItems)
    }
  }

  const deselectAll = e => {
    e.stopPropagation()
    setSelectedItems([])
    onChange([])
  }

  const toggleDropdown = e => {
    e.stopPropagation()
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div
      className="w-full border rounded-lg gap-4 bg-gray-50 text-gray-900 relative"
      ref={dropdownRef}
    >
      <div
        className="flex justify-between items-center w-full px-2 py-1 cursor-pointer"
        onClick={toggleDropdown}
      >
        <div className="h-8 text-[14px] ml-2 items-center justify-center flex">
          {selectedItems.length > 0
            ? selectedItems
                .map(item => (item === 'none' ? 'None' : item))
                .join(', ')
            : placeholder}
        </div>
        <div className="flex items-center space-x-2">
          {selectedItems.length > 0 && (
            <button onClick={deselectAll} className="text-red-500 text-sm">
              <FaTimes />
            </button>
          )}
          <DropdownIcon />
        </div>
      </div>
      {isDropdownOpen && (
        <div className="absolute left-0 right-0 mt-1 bg-white border rounded shadow-lg z-10">
          {dropdownItems.map(item => (
            <div
              key={item.value}
              className={`px-4 py-2 cursor-pointer ${selectedItems.includes('none') && item.value !== 'none' ? 'text-gray-400' : 'text-gray-900'}`}
              onClick={() => handleItemClick(item.value)}
            >
              <input
                type="checkbox"
                checked={selectedItems.includes(item.value)}
                onChange={() => handleItemClick(item.value)}
                disabled={
                  item.value !== 'none' && selectedItems.includes('none')
                }
                className="mr-2"
              />
              {item.value === 'none' ? 'None' : item.label}{' '}
              {item.flag && (
                <ReactCountryFlag
                  className="ml-1"
                  countryCode={item.flag}
                  svg
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
