import axiosInstance from './axiosConfig'
import { IndustriesEndpoints } from './constants'

export const getIndustries = async () => {
  const response = await axiosInstance.get(IndustriesEndpoints.getIndustries)
  return response
}

export const deleteIndustry = async id => {
  const response = await axiosInstance.delete(
    IndustriesEndpoints.deleteIndustry(id),
  )
  return response
}

export const editIndustry = async data => {
  const response = await axiosInstance.patch(
    IndustriesEndpoints.editIndustry(data?.id),
    { industry: data },
  )
  return response
}

export const createIndustry = async data => {
  const response = await axiosInstance.post(IndustriesEndpoints.getIndustries, {
    industry: data,
  })
  return response
}

export const getPaginatedIndustryList = async (page, query) => {
  const response = await axiosInstance.get(
    IndustriesEndpoints.getPaginatedIndustries(page, query),
  )
  return response
}
