import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card } from 'flowbite-react'
import rocketImage from '../../assets/rocket.gif'
import badgeImage from '../../assets/badge.gif'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/currentUserSlice'

const Congratulations = () => {
  const { currentUser } = useSelector(selectCurrentUser)
  const [countdown, setCountdown] = useState(3)
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1)
    }, 1000)

    if (countdown === 0) {
      clearInterval(timer)
      if (currentUser.role === 'candidate') {
        navigate('/user-profile-edit')
      } else if (currentUser.role === 'company_admin') {
        navigate('/complete-company-profile/company')
      }
    }

    return () => clearInterval(timer)
  }, [countdown, navigate, currentUser])

  return (
    <>
      <Card className="w-full h-1/2 mt-10 text-center">
        {countdown < 2 ? (
          <div className="">
            <img
              src={rocketImage}
              alt="Rocket"
              className="w-[182px] h-[182px] mb-6 mt-20 mx-auto"
            />
            <p className="font-extrabold text-2xl ">
              Launching your Quali Account.
            </p>
            <p className="text-gray-400 font-normal py-2 pb-20">
              Connecting you with our verified database.
            </p>
          </div>
        ) : (
          <div className="pb-10">
            <img
              src={badgeImage}
              alt="Badge"
              className="w-[128px] h-[128px]  my-16 mx-auto"
            />
            <p className="font-extrabold text-2xl">
              Congratulations! Your account is now verified.
            </p>
            <p className="text-gray-400 font-normal py-2">
              Please wait while we redirect you...{' '}
              <span className="text-black">{countdown}s</span>
            </p>
          </div>
        )}
      </Card>
    </>
  )
}

export default Congratulations
