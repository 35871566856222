import OTPConfirmation from 'components/CompanyReister/OTPConfirmation'
import { InitialLetters } from 'components/generics/Initials'
import Loader from 'components/generics/loader/Loader'
import { COUNTRY_CITY } from 'data/registerFormData'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { auth } from 'firebaseConfig'
import { FileInput, Label, Modal, Select, TextInput } from 'flowbite-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PhoneInput } from 'react-international-phone'
import {
  TruncatedText,
  addFlagToCountries,
  areArraysNotEqual,
  areNestedObjectsSimilar,
  extractFileName,
  getCurrentFormattedDate,
  isMobileDevice,
} from 'utilis/helpers'
import { EMAIL_REGEX } from 'validations/constants'
import { PasswordValidation } from 'validations/registerValidationSchema'
import * as yup from 'yup'
import 'assets/css/scrollable-dropdown.css'
import { MultiselectDropdown } from 'components/generics/dropdown/multiselectdropdown'
import { GCC_COUNTRIES } from 'config/constants'
import CandidateChangePassword from './CandidateChangePassword'
import {
  getCurrentUser,
  getUserIdByPhoneNumberOrEmail,
  updateUser,
  verifyPasswordForUser,
} from 'services/userService'
import { setCurrentUser } from '../../../../redux/currentUserSlice'
import { useDispatch } from 'react-redux'
import { DropdownWithFlag } from 'components/generics/dropdown/DropdownWithFlag'
import useToastMessage from 'hooks/useToastMessage'
import { ReactDatePickerComponent } from 'components/generics/reactDatePicker/ReactDatePicker'
import 'assets/css/file-input.css'

const CandidateAccountTab = ({
  candidateData,
  activeTab,
  tabsRef,
  getCurrentTab,
}) => {
  const [profileData, setProfileData] = useState({
    firstName: candidateData?.first_name || '',
    lastName: candidateData?.last_name || '',
    email: candidateData?.email || '',
    contactNumber: candidateData?.phone_no || '',
    currentPassword: '',
    password: '',
    repeatPassword: '',
    residence_country: candidateData?.residence_country || '',
    citizenship: candidateData?.citizenship || '',
    profile_picture_url: candidateData?.profile_picture_url || '',
    profile_picture_name: candidateData?.profile_picture_name || '',
    date_of_birth: candidateData?.date_of_birth || getCurrentFormattedDate(),
    validGccVisa: candidateData?.active_visa_in_GCC_country || [],
    openToWork:
      addFlagToCountries(candidateData?.open_to_work_in_countries) || [],
  })
  const [initialState, setInitialState] = useState(profileData)
  const [fileLabel, setFileLabel] = useState(
    extractFileName(candidateData?.profile_picture_name) || 'No file chosen',
  )
  const [preview, setPreview] = useState('')
  const [phone, setPhone] = useState(candidateData?.phone_no || '')
  const [errors, setErrors] = useState({})
  const [loading, setIsLoading] = useState(false)
  const [openOtpModal, setOtpOpenModal] = useState(false)
  const [dataChanged, setDataChanged] = useState(false)
  const [emailOrPhoneChange, setEmailOrPhoneChange] = useState(false)
  const [confirmationResult, setConfirmationResult] = useState(false)
  const [idUser, setIdUser] = useState('')
  const [otpVerified, setOtpVerified] = useState(false)
  const [profilePicture, setProfilePicture] = useState('')
  const [date, setDate] = useState(profileData?.date_of_birth)
  const [submitting, setSubmitting] = useState(false)
  const nationalityOptions = Object.keys(COUNTRY_CITY).map(countryName => ({
    label: countryName,
    value: countryName,
  }))
  const [validGccVisa, setValidGccVisa] = useState(profileData?.validGccVisa)
  const [openToWork, setOpenToWork] = useState(profileData?.openToWork)
  const [isPasswordCorrect, setCorrectPassword] = useState(false)
  const [phoneChanged, setPhoneChange] = useState(false)
  const [emailChanged, setEmailChange] = useState(false)
  const dispatch = useDispatch()
  const isMobile = isMobileDevice()
  const toastMessage = useToastMessage()
  const changesSaved = useRef(false)

  useEffect(() => {
    setInitialState(profileData)
  }, [])

  useEffect(() => {
    clearStates(candidateData, false, true)
  }, [getCurrentTab])

  const clearStates = useCallback(
    (data, dataChanged, clearErrors) => {
      setProfileData({
        firstName: data?.first_name || '',
        lastName: data?.last_name || '',
        email: data?.email || '',
        contactNumber: data?.phone_no || '',
        currentPassword: '',
        password: '',
        repeatPassword: '',
        residence_country: data?.residence_country || '',
        citizenship: data?.citizenship || '',
        profile_picture_url: data?.profile_picture_url || '',
        profile_picture_name: data?.profile_picture_name || '',
        date_of_birth: data?.date_of_birth || getCurrentFormattedDate(),
        validGccVisa: data?.active_visa_in_GCC_country || [],
        openToWork: addFlagToCountries(data?.open_to_work_in_countries) || [],
      })
      setInitialState({
        firstName: data?.first_name || '',
        lastName: data?.last_name || '',
        email: data?.email || '',
        contactNumber: data?.phone_no || '',
        currentPassword: '',
        password: '',
        repeatPassword: '',
        residence_country: data?.residence_country || '',
        citizenship: data?.citizenship || '',
        profile_picture_url: data?.profile_picture_url || '',
        profile_picture_name: data?.profile_picture_name || '',
        date_of_birth: data?.date_of_birth || getCurrentFormattedDate(),
        validGccVisa: data?.active_visa_in_GCC_country || [],
        openToWork: addFlagToCountries(data?.open_to_work_in_countries) || [],
      })
      clearErrors && setErrors({})
      setProfilePicture('')
      setPhone(data?.phone_no || '')
      setIsLoading(false)
      setSubmitting(false)
      setDataChanged(dataChanged)
      setOtpVerified(false)
    },
    [candidateData],
  )

  const handlePhoneInputChange = (phone, country) => {
    setPhoneChange(true)
    setEmailOrPhoneChange(true)
    setDataChanged(true)
    setPhone(phone)
    setProfileData(prevState => ({ ...prevState, ['contactNumber']: phone }))
  }

  const handleChange = event => {
    const { name, value } = event.target
    const trimmedValue = value.replace(/^\s+/, '')
    setDataChanged(true)
    if (name === 'firstName' || name === 'lastName') {
      if (!trimmedValue || trimmedValue.length <= 2) {
        setErrors(prevState => ({
          ...prevState,
          [name]: `Enter a valid ${name === 'firstName' ? 'First Name' : 'Last Name'}`,
        }))
      } else {
        setErrors(prevErrors => {
          const {
            [name === 'firstName' ? 'firstName' : 'lastName']: removedError,
            saveChanges,
            ...restErrors
          } = prevErrors
          return restErrors
        })
      }
    }
    setProfileData(prevState => ({ ...prevState, [name]: trimmedValue }))
    if (name === 'email' || name === 'repeatPassword') {
      setEmailOrPhoneChange(true)
      checkEmailValid(name, value)
    } else {
      setEmailOrPhoneChange(false)
    }
    if (name === 'currentPassword') {
      setEmailOrPhoneChange(true)
      verifyPassword(value)
    }
    name === 'password' && onPasswordChange(name, value)
  }

  const onPasswordChange = async (name, value) => {
    setEmailOrPhoneChange(true)
    if (name === 'password') {
      setProfileData(prevState => ({
        ...prevState,
        [name]: value,
        repeatPassword: '',
      }))
    } else {
      setProfileData(prevState => ({
        ...prevState,
        [name]: value,
      }))
    }

    await validateForm(value)
  }

  const validateForm = async value => {
    try {
      await yup.reach(PasswordValidation, 'password').validate(value)
      setErrors(prevErrors => {
        const { password, validationErrors, ...restErrors } = prevErrors
        return restErrors
      })
    } catch (err) {
      const validationErrors = {}
      err?.inner?.forEach(error => {
        validationErrors[error.path] = error?.message
      })
      setErrors(prevErrors => ({
        ...prevErrors,
        validationErrors,
      }))
    }
  }

  const checkEmailValid = (name, value) => {
    if (name === 'email') {
      if (!EMAIL_REGEX.test(value)) {
        setErrors(prevErrors => ({
          ...prevErrors,
          email: 'Please enter a valid email address.',
        }))
      } else {
        setEmailChange(true)
        setErrors(prevErrors => {
          const { email, saveChanges, ...restErrors } = prevErrors
          return restErrors
        })
      }
    }
  }

  const verifyPassword = async value => {
    try {
      const response = await verifyPasswordForUser(value)
      if (response?.status === 200) {
        setCorrectPassword(true)
        setIsLoading(false)
        setErrors(prevErrors => {
          const { currentPassword, success, saveChanges, ...restErrors } =
            prevErrors
          return restErrors
        })
      }
    } catch (error) {
      setCorrectPassword(false)
      setIsLoading(false)
      if (error?.response?.status === 401) {
        setErrors(prevErrors => ({
          ...prevErrors,
          currentPassword: 'The password you entered is incorrect',
          success: false,
        }))
      }
    }
  }

  const handleSubmit = async () => {
    const areSimilar = areNestedObjectsSimilar(initialState, profileData)
    if (areSimilar) {
      if (
        !profilePicture &&
        !areArraysNotEqual(openToWork, profileData?.openToWork) &&
        !areArraysNotEqual(validGccVisa, profileData?.validGccVisa) &&
        date === profileData?.date_of_birth
      ) {
        return
      }
    }
    setSubmitting(true)
    profileData?.currentPassword &&
      (await verifyPassword(profileData?.currentPassword))
    if (Object.keys(errors).length > 0 && !errors?.success) {
      if (
        [
          'email',
          'password',
          'repeatPassword',
          'currentPassword',
          'firstName',
          'lastName',
        ].some(key => errors[key])
      ) {
        setErrors(prevErrors => ({
          ...prevErrors,
          saveChanges: 'Your changes could not be saved.',
        }))
      }
      return
    }

    const user = new FormData()
    profileData?.firstName &&
      user.append('user[first_name]', profileData?.firstName)
    profileData?.lastName &&
      user.append('user[last_name]', profileData?.lastName)
    profileData?.email && user.append('user[email]', profileData?.email)
    profileData?.residence_country &&
      user.append('user[residence_country]', profileData?.residence_country)
    profileData?.citizenship &&
      user.append('user[citizenship]', profileData?.citizenship)
    date && user.append('user[date_of_birth]', date)
    validGccVisa.length > 0 &&
      validGccVisa.forEach(visa => {
        user.append('user[active_visa_in_GCC_country][]', visa)
      })
    openToWork.length > 0 &&
      openToWork.forEach(country => {
        user.append(
          'user[open_to_work_in_countries][]',
          country?.value || country,
        )
      })
    profilePicture && user.append('user[profile_picture]', profilePicture)
    profileData?.password &&
      user.append('user[password]', profileData?.password)
    phone && user.append('user[phone_no]', phone)

    if (
      (isPasswordCorrect && emailOrPhoneChange && !otpVerified) ||
      (!profileData.currentPassword && emailOrPhoneChange && !otpVerified)
    ) {
      sendOtp()
    }
    if (emailOrPhoneChange && !otpVerified) {
      return
    }

    if (profileData?.currentPassword && !isPasswordCorrect) {
      return
    }

    if (!profileData?.lastName || !profileData?.firstName) {
      return
    }
    try {
      setIsLoading(true)
      const response = await updateUser(candidateData?.id, user)
      if (response?.status === 200) {
        if (phoneChanged && !confirmationResult) {
          setIsLoading(false)
          return
        }
        setIsLoading(false)
        setSubmitting(false)
        setDataChanged(false)
        const currentUser = await getCurrentUser()
        dispatch(setCurrentUser(currentUser?.data))
        changesSaved.current = true
        clearStates(currentUser?.data, true, false)
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error updating user', error)
      const errors = error?.response?.data || {}
      const errorMessages = []
      const labels = {
        first_name: 'First Name',
        last_name: 'Last Name',
      }

      for (const [key, value] of Object.entries(errors)) {
        if (labels[key]) {
          errorMessages.push(`${labels[key]}: ${value}`)
        }
      }
      if (errorMessages.length > 0) {
        toastMessage(errorMessages.join(', '), 'error')
      } else {
        toastMessage('An error occurred while updating the user.', 'error')
      }

      setErrors(prevErrors => ({
        ...prevErrors,
        saveChanges: 'Your changes could not be saved.',
      }))
    }
  }

  const sendOtp = async () => {
    try {
      setOtpOpenModal(true)

      const user = await getUserIdByPhoneNumberOrEmail({
        user: {
          attribute: 'phone_no',
          value: candidateData?.phone_no,
        },
      })

      if (user.data.id) {
        if (!window.recaptchaVerifier) {
          console.log("Initializing new reCAPTCHA...");
          window.recaptchaVerifier = new RecaptchaVerifier(
            auth,
            'recaptcha-firebase',  // Container ID
            {
              size: 'invisible',  // Invisible reCAPTCHA mode
            },
          );
        } else {
          console.log("Resetting existing reCAPTCHA...");
        }
        // if (!window.recaptchaVerifier) {
        //   window.recaptchaVerifier = new RecaptchaVerifier(
        //     auth,
        //     'recaptcha-firebase',
        //     {
        //       size: 'invisible',
        //     },
        //   )  
        // }
        setIdUser(user.data.id)
        const confirmationResult = await signInWithPhoneNumber(
          auth,
          profileData?.contactNumber,
          window.recaptchaVerifier,
        )
        setConfirmationResult(confirmationResult)
        alert('OTP sent successfully!')
      } else {
        alert("User with this Phone Number Doesn't Exist")
      }
    } catch (error) {
      if (error.inner) {
        alert("User with this Phone Number Doesn't Exist")
        const validationErrors = {}
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message
        })
        setErrors(validationErrors)
      } else {
        console.error('Error sending OTP:', error)
      }
    }
  }

  const resendOtp = async () => {
    const appVerifier = window.recaptchaVerifier

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        profileData?.contactNumber,
        appVerifier,
      )
      setConfirmationResult(confirmationResult)
      alert('OTP resent successfully!')
    } catch (error) {
      if (error.inner) {
        const validationErrors = {}
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message
        })
        setErrors(validationErrors)
      } else {
        console.error('Error resending OTP:', error)
      }
    }
  }

  const handleOtpVerified = () => {
    setOtpVerified(true)
  }

  useEffect(() => {
    if (otpVerified) {
      handleSubmit()
    }
  }, [otpVerified])

  useEffect(() => {
    if (loading) {
      document.documentElement.style.overflow = 'hidden'
      document.documentElement.style.height = '100vh'
    } else {
      document.documentElement.style.overflow = ''
      document.documentElement.style.height = ''
    }

    return () => {
      document.documentElement.style.overflow = ''
      document.documentElement.style.height = ''
    }
  }, [loading])

  const handleDateChange = (name, formattedDate) => {
    setDataChanged(true)
    setDate(formattedDate)
  }

  return (
    <div>
      <>
        {loading && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
            <Loader />
          </div>
        )}
        <div id="recaptcha-firebase"></div>
        <div className="flex flex-row below-767:flex-col mb-5 below-767:items-center mt-5">
          <h1 className="text-18 font-bold text-gray-900">
            Account Information
          </h1>
          <div className="ml-auto below-767:ml-0 flex flex-row below-767:flex-col gap-2 items-center">
            {changesSaved.current && !dataChanged && (
              <div>
                <p className="text-sm font-bold text-green-700">
                  Changes saved! All set.
                </p>
              </div>
            )}
            <button
              className={`text-14 ${dataChanged ? 'bg-primary-700 text-white' : 'bg-white text-[#1A56DB]'} below-767:mt-2 border border-[#1A56DB] font-[500] py-2 lg:px-4 px-7 rounded-lg mb-2 lg:mb-0`}
              onClick={handleSubmit}
            >
              Save Changes
            </button>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-4">
          <div className="w-full">
            <div className="pb-0.5">
              <label className="text-[#111928] text-[14px] font-[800] ">
                First Name
              </label>
            </div>
            <TextInput
              id="firstName"
              name="firstName"
              value={profileData.firstName}
              type="text"
              placeholder="Enter your first name"
              onChange={handleChange}
            />
            {errors.firstName && (
              <p className="text-14 text-red-500 font-[500] mt-2">
                {errors.firstName}
              </p>
            )}
          </div>
          <div className="w-full">
            <div className="pb-0.5">
              <label className="text-[#111928] text-[14px] font-[800] ">
                Last Name
              </label>
            </div>

            <TextInput
              id="lastName"
              name="lastName"
              value={profileData.lastName}
              type="text"
              placeholder="Enter your last name"
              onChange={handleChange}
            />
            {errors.lastName && (
              <p className="text-14 text-red-500 font-[500] mt-2">
                {errors.lastName}
              </p>
            )}
          </div>
        </div>

        <div className={`flex flex-col md:flex-row md:gap-4 items-center mt-5`}>
          {preview || profileData?.profile_picture_url ? (
            <div className="flex justify-center items-center h-20 w-20 md:h-20 md:w-20">
              <div className=" h-full w-full overflow-hidden rounded-full">
                <img
                  src={preview || profileData?.profile_picture_url}
                  alt="Company Logo Preview"
                  className="object-cover h-full w-full rounded-full"
                />
              </div>
            </div>
          ) : (
            <div className=" flex justify-center items-center p-5 rounded-full text-3xl bg-gray-100 border-gray-100 h-20 w-20 md:h-20 md:w-20">
              <InitialLetters
                Name={profileData?.firstName + ' ' + profileData?.lastName}
              />
            </div>
          )}
          <div className="w-full">
            <Label value="Profile Picture" className="mb-2 block" />
            <div className="relative">
              <FileInput
                id="file-upload"
                name="ProfilePicture"
                type="file"
                className="opacity-0 absolute inset-0 w-full cursor-pointer remove-pointer-event"
                onChange={event => {
                  const file = event.currentTarget.files[0]
                  if (file) {
                    setDataChanged(true)
                    setFileLabel(file.name)
                    setProfilePicture(file)
                    const reader = new FileReader()
                    reader.onloadend = () => {
                      setPreview(reader.result)
                    }
                    reader.readAsDataURL(file)
                  } else {
                    setFileLabel('No file chosen')
                  }
                }}
                accept="image/png, image/jpg, image/jpeg"
              />
              <div
                className="border rounded-lg w-full cursor-pointer flex items-center bg-[#F9FAFB] border-[#D1D5DB]"
              >
                <button className="bg-gray-900 text-white px-3 py-2 pt-3 rounded-s-lg text-sm">
                  Choose File
                </button>
                <span className="ml-5 text-sm">
                  <TruncatedText text={fileLabel} limit={isMobile ? 15 : 25} />
                </span>
              </div>
              <p className="text-xs text-gray-500 mt-2">Maximum 5 MB</p>
            </div>
          </div>
        </div>

        <div className="w-full mt-2">
          <div className="pb-0.5">
            <label className="text-[#111928] text-[14px] font-[500] ">
              E-mail Address
            </label>
          </div>
          <TextInput
            id="email"
            type="email"
            name="email"
            value={profileData.email}
            onChange={handleChange}
            placeholder="Enter your e-mail address"
          />
          {errors.email && (
            <p className="text-14 text-red-500 font-[500] mt-2">
              {errors.email}
            </p>
          )}
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-4 mt-4">
          <div className="w-full">
            <div className="flex items-center pb-0.5">
              <label className="text-[#111928] text-[14px] font-[500] ">
                Country of Residence
              </label>
            </div>
            <Select
              id="residence_country"
              name="residence_country"
              value={profileData.residence_country}
              onChange={handleChange}
            >
              <option value="" disabled>
                Select Country
              </option>
              {Object.keys(COUNTRY_CITY).map(countryName => (
                <option key={countryName} value={countryName}>
                  {countryName}
                </option>
              ))}
            </Select>
          </div>
        </div>

        <div className="w-full flex flex-col mt-4">
          <Label
            className="text-[#111928] mb-2 text-[16px] font-[500] block"
            htmlFor="dateOfBirth"
            value="Date of Birth"
          />
          <span>
            <ReactDatePickerComponent
              setFieldValue={handleDateChange}
              handleChange={handleChange}
              id="dateOfBirth"
              name="dateOfBirth"
              value={date}
              placeholder={
                window.innerWidth >= 786 ? 'Select Your Date of Birth' : ''
              }
            />
          </span>
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-4 mt-4">
          <div className="w-full">
            <div className="flex items-center pb-0.5">
              <label className="text-[#111928] text-[16px] font-[500] mb-1">
                Your Nationality{' '}
              </label>
            </div>
            <Select
              id="citizenship"
              name="citizenship"
              value={profileData.citizenship}
              onChange={handleChange}
            >
              <option value="" disabled>
                Select Nationality
              </option>
              {nationalityOptions.map(option => (
                <option key={option.value} value={option.label}>
                  {option.label}
                </option>
              ))}
            </Select>
          </div>
        </div>

        <div className="w-full flex flex-col gap-2 my-5">
          <Label
            htmlFor="validGccVisa"
            value="GCC Visa Holder"
            className="text-[#111928] text-[16px] font-[500]"
          />
          <MultiselectDropdown
            placeholder={'None'}
            dropdownItems={GCC_COUNTRIES}
            value={validGccVisa}
            onChange={selectedItems => setValidGccVisa(selectedItems)}
            setDataChanged={setDataChanged}
          />
        </div>

        <div className="w-full flex flex-col gap-2">
          <Label
            htmlFor="openToWorkInCountries"
            value="I am open to work in..."
            className="text-[#111928] text-[16px] font-[500]"
          />
          <DropdownWithFlag
            setDataChanged={setDataChanged}
            placeholder={'None'}
            dropdownItems={GCC_COUNTRIES}
            value={openToWork}
            onChange={selectedItems => setOpenToWork(selectedItems)}
          />
        </div>
        <hr className="mt-4 mb-4 border-dashed border-gray-200 border-t-2" />
        <h1 className="mt-2 mb-2 text-16 font-bold text-[#333333]">
          Change Password
        </h1>
        <CandidateChangePassword
          profileData={profileData}
          handleChange={handleChange}
          errors={errors}
          setErrors={setErrors}
          submitting={submitting}
        />
        <hr className="mt-4 mb-4 border-dashed border-gray-200 border-t-2" />
        <h1 className="mt-2 mb-2 text-16 font-bold text-[#333333]">
          Change Number
        </h1>
        <div className="w-full">
          <h2 className="mt-3 mb-2 text-14 text-gray-900 font-[500]">
            Contact Number
          </h2>
          <PhoneInput
            defaultCountry="us"
            value={phone}
            onChange={(phone, country) =>
              handlePhoneInputChange(phone, country)
            }
            placeholder="123 4567 890"
          />
          <h2 className="mt-2 mb-2 text-14 font-medium text-gray-500">
            We will re-send confirmation code through this number.
          </h2>
        </div>
        <Modal
          show={openOtpModal}
          theme={{
            content: {
              base: 'bg-transparent w-3/4',
              inner: 'bg-transparent',
            },
          }}
        >
          <div className="flex items-center justify-center">
            <OTPConfirmation
              confirmationResult={confirmationResult}
              handleSubmit={handleSubmit}
              ForgotPasswordForm={true}
              userId={idUser}
              setOpenModal={setOtpOpenModal}
              phoneNo={profileData?.contactNumber}
              resendOtp={resendOtp}
              handleOtpVerified={handleOtpVerified}
              fromManageProfile={true}
            />
          </div>
        </Modal>
      </>
    </div>
  )
}

export default CandidateAccountTab
