import React, { useEffect, useState } from 'react'
import AdminNavbar from './AdminNavbar'
import Table from '../table/Table'
import { generateColumns } from '../../utilis/helpers/tableHelper'
import { getIndustries } from '../../services/industryService'
import { getPaginatedCompaniesList } from '../../services/companyService'
import { COMPANY_SIZE } from '../../config/constants'
import moment from 'moment'

const CompaniesList = () => {
  const [companies, setCompanies] = useState([])
  const [industries, setIndustries] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [moreDetails, setMoreDetails] = useState([])
  const [query, setQuery] = useState('')

  const getCompanies = async () => {
    try {
      const mappedQuery = mappedCategoryForSearch()
      const response = await getPaginatedCompaniesList(
        currentPage,
        mappedQuery || '',
      )
      const mappedData = response?.data?.records?.map(item => item?.attributes)
      const sortedData = mappedData.sort((a, b) => b.id - a.id)
      const mappedMoreData = sortedData.map(item => {
        const {
          id,
          company_admin_first_name,
          company_admin_last_name,
          city,
          industry_name,
          remaining_credits,
          created_at,
        } = item
        return {
          ID: id,
          'First Name': company_admin_first_name || 'Not provided',
          'Last Name': company_admin_last_name || 'Not provided',
          City: city || 'Not provided',
          Industry: industry_name || 'Not provided',
          'Remaining Credits': remaining_credits || 0,
          'Registeration Date': moment(created_at).format(
            'MMMM D, YYYY, h:mm A',
          ),
        }
      })
      setMoreDetails(mappedMoreData)
      setCompanies(sortedData)
      setTotalPages(response?.data?.pagy?.total_pages)
      setTotalCount(response?.data?.pagy?.total_count)
    } catch (error) {
      console.error('Error fetching companies:', error)
    }
  }

  const mappedCategoryForSearch = () => {
    let lowercaseQuery = query.toLowerCase()
    if (COMPANY_SIZE.hasOwnProperty(lowercaseQuery)) {
      return COMPANY_SIZE[lowercaseQuery]
    }
    return query
  }
  const fetchIndustries = async () => {
    try {
      const response = await getIndustries()
      if (response.status === 200) {
        setIndustries(response?.data || [])
      }
    } catch (error) {
      console.error('Error fetching industries:', error)
    }
  }
  useEffect(() => {
    getCompanies()
    fetchIndustries()
  }, [currentPage, query])

  const onSearchCompany = (search, value) => {
    setCurrentPage(1)
    setQuery(value)
  }

  const columns = generateColumns(companies)
  return (
    <div className="flex h-screen w-full flex-col bg-[#f9fafb]">
      <AdminNavbar selectedItem="companies" />
      <Table
        columns={columns}
        data={companies}
        getCompanies={getCompanies}
        industries={industries}
        title="Company"
        onSearch={onSearchCompany}
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        setCurrentPage={setCurrentPage}
        hasMoreDetails
        moreDetails={moreDetails}
      />
    </div>
  )
}

export default CompaniesList
