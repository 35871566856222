import {
  Button,
  Card,
  Checkbox,
  Label,
  List,
  Modal,
  Select,
  TextInput,
} from 'flowbite-react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { registerUser } from '../../services/authService'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { auth } from '../../firebaseConfig'
import OTPConfirmation from '../CompanyReister/OTPConfirmation'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { registerCandidateSchema } from '../../validations/registerValidationSchema'
import { COUNTRY_CITY } from '../../data/registerFormData'
import { setCurrentUser } from '../../redux/currentUserSlice'
import { getUserValidity } from '../../services/userService'
import { PASSWORD_REGEX } from '../../validations/constants'
import eyeSlashIcon from '../../assets/eye-slash.svg'
import eyeicon from '../../assets/eye.svg'
import Congratulations from 'components/CompanyReister/Congratulations'
import { PhoneInput } from 'react-international-phone'
import 'assets/css/CompanyProfile.css'
import 'react-international-phone/style.css'

const RegisterForm = () => {
  const [registerData, setRegisterData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    countryCode: '',
    residence_country: '',
    password: '',
    repeatPassword: '',
  })
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [errors, setErrors] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [confirmationResult, setConfirmationResult] = useState(false)
  const [openRedirectionModal, setRedirectionOpenModal] = useState(false)

  const dispatch = useDispatch()

  const sendOtp = async () => {
    try {
      const trimmedRegisterData = {
        ...registerData,
        firstName: registerData.firstName.trim(),
        lastName: registerData.lastName.trim(),
      }
      await registerCandidateSchema.validate(trimmedRegisterData, {
        abortEarly: false,
      })
      if (!acceptTerms) {
        setErrors({ acceptTerms: 'Terms acceptance is required' })
        return
      }
      const {
        firstName,
        lastName,
        email,
        password,
        contactNumber,
        countryCode,
      } = trimmedRegisterData

      const response = await getUserValidity({
        phone_no: contactNumber,
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        role: 'candidate',
        country_code: countryCode,
      })

      if (response.data.valid_user) {
        setOpenModal(true)

        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          'recaptcha-container',
          {
            size: 'invisible',
            callback: () => {
              console.log('recaptcha resolved..')
            },
          },
        )

        const confirmationResult = await signInWithPhoneNumber(
          auth,
          registerData.contactNumber,
          window.recaptchaVerifier,
        )
        setConfirmationResult(confirmationResult)
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors.join(', ')
        alert(errorMessage)
      }
      if (error.inner) {
        const validationErrors = {}
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message
        })
        setErrors(validationErrors)
      } else {
        console.error('Error sending OTP:', error)
      }
    }
  }

  const resendOtp = async () => {
    const appVerifier = window.recaptchaVerifier

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        registerData.contactNumber,
        appVerifier,
      )
      setConfirmationResult(confirmationResult)
      alert('OTP resent successfully!')
    } catch (error) {
      if (error.inner) {
        const validationErrors = {}
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message
        })
        setErrors(validationErrors)
      } else {
        console.error('Error resending OTP:', error)
      }
    }
  }

  const handleChange = event => {
    const { name, value } = event.target
    setRegisterData(prevState => ({ ...prevState, [name]: value }))
  }

  const handlePhoneInputChange = (phone, meta) => {
    setRegisterData(prevState => ({
      ...prevState,
      contactNumber: phone,
      countryCode: `+${meta?.country?.dialCode}`,
    }))
  }

  const handleBlur = async event => {
    const { name, value } = event.target

    const trimmedValue =
      name === 'firstName' || name === 'lastName' ? value.trim() : value

    try {
      await yup.reach(registerCandidateSchema, name).validate(trimmedValue)
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }))
    } catch (error) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: error.errors[0],
      }))
    }
  }
  const isPasswordMatch = () => {
    return registerData.password === registerData.repeatPassword
  }

  const isCorrectPassword = () => {
    return PASSWORD_REGEX.test(registerData.repeatPassword)
  }

  const handleAcceptTermsChange = () => {
    setAcceptTerms(!acceptTerms)
    if (errors.acceptTerms) {
      setErrors(prevErrors => ({
        ...prevErrors,
        acceptTerms: '',
      }))
    }
  }

  const handleSubmit = async () => {
    try {
      const trimmedRegisterData = {
        ...registerData,
        firstName: registerData.firstName.trim(),
        lastName: registerData.lastName.trim(),
      }
      await registerCandidateSchema.validate(trimmedRegisterData, {
        abortEarly: false,
      })
      if (!acceptTerms) {
        setErrors({ acceptTerms: 'Terms acceptance is required' })
        return
      }

      const {
        firstName,
        lastName,
        email,
        password,
        contactNumber,
        residence_country,
        countryCode,
      } = trimmedRegisterData

      const user = await registerUser({
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_no: contactNumber,
        role: 'candidate',
        residence_country: residence_country,
        password: password,
        country_code: countryCode,
      })
      dispatch(setCurrentUser(user))
    } catch (error) {
      if (error.inner) {
        const validationErrors = {}
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message
        })
        setErrors(validationErrors)
      } else {
        console.error('Error submitting form:', error)
      }
    }
  }

  const [showPassword, setShowPassword] = useState(false)
  const [showrepeatedPassword, setshowrepeatedPassword] = useState(false)
  const togglePasswordVisibility = (setState, prevState) => {
    setState(prevState => !prevState)
  }

  return (
    <div className="w-full md:w-[629px] rounded-lg">
      <div id="recaptcha-container"></div>

      <Card className="border sm:border-gray-200  sm:shadow-md sm:dark:border-gray-700 border-white sm:dark:bg-gray-800">
        <div className="flex flex-col gap-3">
          <p className="text-2xl font-bold sm:mt-0 -mt-5 font-satoshi">
            Register as Quali Candidate
          </p>
          <p className="text-[#6B7280]">
            Already have an account?
            <Link to="/user/login" className="px-1 text-blue-700">
              Login here{' '}
            </Link>
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col md:flex-row justify-between gap-4">
            <div className="w-full">
              <div className="pb-0.5">
                <label className="text-[#111928] text-[14px] font-[800] ">
                  First Name
                </label>
              </div>
              <TextInput
                id="firstName"
                name="firstName"
                value={registerData.firstName}
                type="text"
                placeholder="Enter your first name"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.firstName && (
                <p className="text-xs text-red-500">{errors.firstName}</p>
              )}
            </div>
            <div className="w-full">
              <div className="pb-0.5">
                <label className="text-[#111928] text-[14px] font-[800] ">
                  Last Name
                </label>
              </div>

              <TextInput
                id="lastName"
                name="lastName"
                value={registerData.lastName}
                type="text"
                placeholder="Enter your last name"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.lastName && (
                <p className="text-xs text-red-500">{errors.lastName}</p>
              )}
            </div>
          </div>
          <div>
            <div className="w-full">
              <div className="pb-0.5">
                <label className="text-[#111928] text-[14px] font-[800] ">
                  E-mail Address
                </label>
              </div>
              <TextInput
                id="email"
                type="email"
                name="email"
                value={registerData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter your e-mail address"
              />
              {errors.email && (
                <p className="text-xs text-red-500">{errors.email}</p>
              )}
            </div>
          </div>
          <div>
            <div className="pb-0.5">
              <label className="text-[#111928] text-[14px] font-[800] ">
                WhatsApp Number
              </label>
            </div>
            <PhoneInput
              defaultCountry="sa"
              value={registerData?.contactNumber}
              onChange={(phone, meta) => handlePhoneInputChange(phone, meta)}
              placeholder="123 4567 890"
            />
            <p className="text-sm text-gray-500">
              We will send confirmation code through this number or e-mail.
            </p>
            {errors.contactNumber && (
              <p className="text-xs text-red-500">{errors.contactNumber}</p>
            )}
          </div>
          <div className="flex flex-col md:flex-row justify-between gap-4">
            <div className="w-full">
              <div className="flex items-center pb-0.5">
                <label className="text-[#111928] text-[14px] font-[800] ">
                  Country of Residence
                </label>
              </div>
              <Select
                id="residence_country"
                name="residence_country"
                value={registerData.residence_country}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option value="">Select Country</option>
                {Object.keys(COUNTRY_CITY).map(countryName => (
                  <option key={countryName} value={countryName}>
                    {countryName}
                  </option>
                ))}
              </Select>
              {errors.residence_country && (
                <p className="text-xs text-red-500">
                  {errors.residence_country}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between gap-4">
            <div className="w-full">
              <div className="pb-0.5">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Enter Password
                </label>
              </div>
              <div className="relative ">
                <TextInput
                  name="password"
                  value={registerData.password}
                  className="mb-1"
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="********"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-auto"
                  onClick={() => togglePasswordVisibility(setShowPassword)}
                >
                  <img
                    src={showPassword ? eyeicon : eyeSlashIcon}
                    alt={showPassword ? 'Hide password' : 'Show password'}
                    className="h-4 w-4 text-gray-400"
                  />
                </button>
              </div>
              {errors.password && (
                <p className="text-xs text-red-500">{errors.password}</p>
              )}
              <div className="text-sm py-1">
                <p className="font-semibold text-slate-500">
                  Password guideline:
                </p>
                <List>
                  <List.Item>At least 8 characters</List.Item>
                  <List.Item>Include special character</List.Item>
                  <List.Item>Include capitalized characters</List.Item>
                </List>
              </div>
            </div>
            <div className="w-full">
              <div className="pb-0.5">
                <label
                  htmlFor="repeatPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  Repeat Password
                </label>
              </div>
              <div className="relative">
                <input
                  type={showrepeatedPassword ? 'text' : 'password'}
                  name="repeatPassword"
                  value={registerData.repeatPassword}
                  id="repeatPassword"
                  placeholder="********"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mb-1`}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-auto"
                  onClick={() =>
                    togglePasswordVisibility(setshowrepeatedPassword)
                  }
                >
                  <img
                    src={showrepeatedPassword ? eyeicon : eyeSlashIcon}
                    alt={
                      showrepeatedPassword ? 'Hide password' : 'Show password'
                    }
                    className="h-4 w-4 text-gray-400"
                  />
                </button>
              </div>
              {registerData.repeatPassword.length ? (
                <p
                  className={`text-xs ${isPasswordMatch() && isCorrectPassword() ? 'text-green-500' : 'text-red-500'}`}
                >
                  {!isCorrectPassword() ? (
                    <>Password must match the guidelines</>
                  ) : isPasswordMatch() ? (
                    <>Password matched!</>
                  ) : (
                    <>Password didn't match.</>
                  )}
                </p>
              ) : (
                errors.repeatPassword && (
                  <p className="text-xs text-red-500">
                    {errors.repeatPassword}
                  </p>
                )
              )}
            </div>
          </div>
          <div>
            <div className="flex gap-2 py-2">
              <Checkbox
                id="acceptTerms"
                name="acceptTerms"
                checked={acceptTerms}
                onChange={handleAcceptTermsChange}
              />
              <Label
                htmlFor="acceptTerms"
                className="text-sm font-normal text-slate-500"
              >
                By signing up, you are creating a Quali account, and you agree
                to our friends{' '}
                <span className="text-blue-700">Terms of Use</span> and{' '}
                <span className="text-blue-700">Privacy Policy</span>.
              </Label>
            </div>
            {errors.acceptTerms && (
              <p className="text-xs text-red-500">{errors.acceptTerms}</p>
            )}
          </div>
          <Button
            type="submit"
            className="bg-blue-700 w-full md:w-auto"
            onClick={() => sendOtp()}
          >
            Create Account
          </Button>
          <Modal
            show={openModal}
            onClose={() => setOpenModal(false)}
            theme={{
              content: {
                base: 'bg-transparent w-3/4',
                inner: 'bg-transparent',
              },
            }}
          >
            {!openRedirectionModal ? (
              <>
                <div className="flex items-center justify-center">
                  <OTPConfirmation
                    confirmationResult={confirmationResult}
                    handleSubmit={handleSubmit}
                    resendOtp={resendOtp}
                    ForgotPasswordForm={false}
                    phoneNo={registerData.contactNumber}
                    setOpenModal={setOpenModal}
                    setRedirectionOpenModal={setRedirectionOpenModal}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col h-screen font-global-styling">
                  <Congratulations />
                </div>
              </>
            )}
          </Modal>
        </div>
      </Card>
    </div>
  )
}

export default RegisterForm
