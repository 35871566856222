import React from 'react'
import RegisterFields from '../components/CompanyReister/RegisterFields'
import Image from '../components/Image'
import AppBar from '../components/Helper/AppBar'

const Register = () => {
  return (
    <>
      <div
        className="flex w-full flex-col lg:px-20 bg-white lg:bg-[#F9FAFB]"
      >
        <div className="py-7 px-3">
          <AppBar />
        </div>
        <main className="flex">
          <div className="flex w-full items-center gap-20">
            <RegisterFields />
            <div className="hidden md:block lg:block">
              <Image />
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default Register
