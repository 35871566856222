import { React } from 'react'
import { Button, Card, FileInput, Label, TextInput } from 'flowbite-react'
import { updateCompany } from '../../lib/api'
import { companyProfileSchema } from '../../validations/CompanyProfileSchema'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { selectCompany, setCompany } from '../../redux/currentUserSlice'
import { useSelector, useDispatch } from 'react-redux'
import useToastMessage from '../../hooks/useToastMessage'
import '../../assets/css/CompanyProfile.css'
import { InitialLetters } from '../../components/generics/Initials'

const CompanyProfile = ({ setSelectedCard, fromTab, handleTabSelect }) => {
  const companyData = useSelector(selectCompany)
  const dispatch = useDispatch()
  const toastMessage = useToastMessage()
  const handleSubmit = async values => {
    const formData = new FormData()

    formData.append('company[logo]', values.companyLogo)
    formData.append('company[description]', values.companyDescription)
    formData.append('company[website_link]', values.companyWebsite)
    formData.append('company[social_network_links][facebook]', values.facebook)
    formData.append('company[social_network_links][twitter]', values.twitter)
    formData.append(
      'company[social_network_links][instagram]',
      values.instagram,
    )
    try {
      const response = await updateCompany(companyData?.id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      dispatch(setCompany(response))
      setSelectedCard(1)
      toastMessage('Company updated successfully!', 'success')
    } catch (error) {
      toastMessage(error.response?.data?.error, 'error')
      console.error('Error in Updating Company :', error)
    }
  }
  return (
    <>
      {companyData && (
        <Formik
          initialValues={{
            logoPreview: companyData?.logo_url || null,
            companyLogo: companyData?.logo_url || '',
            companyDescription: companyData?.description || '',
            companyWebsite: companyData?.website_link || '',
            facebook: companyData?.social_network_links.facebook || '',
            instagram: companyData?.social_network_links.instagram || '',
            twitter: companyData?.social_network_links.twitter || '',
          }}
          validationSchema={companyProfileSchema}
          onSubmit={values => {
            handleSubmit(values)
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            touched,
            handleChange,
            isSubmitting,
          }) => (
            <div className="w-4/5 mx-auto">
              <Card className="lg:p-4">
                <h5 className="text-2xl font-bold tracking-tight text-gray-900">
                  {companyData.name}, help us get to know you better
                </h5>
                <p className="font-normal text-gray-500">
                  Complete your profile to boost visibility, showcase your
                  employer brand, and attach top talent who fit your culture.
                </p>
                <Form className="mt-4">
                  <div className="w-full md:w-3/4 flex flex-col gap-5">
                    <div className="flex flex-col md:flex-row md:gap-4 items-center">
                      {values.logoPreview ? (
                        <div className="flex justify-center items-center h-20 w-20 md:h-20 md:w-20">
                          <div className=" h-full w-full overflow-hidden rounded-full">
                            <img
                              src={values.logoPreview}
                              alt="Company Logo Preview"
                              className="object-cover h-full w-full rounded-full"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-center items-center p-5 rounded-full text-3xl bg-gray-100 border-gray-100 h-20 w-20  mb-3">
                          <InitialLetters Name={companyData?.name} />
                        </div>
                      )}
                      <div className="w-full">
                        <Label value="Company Logo" className="mb-2 block" />
                        <FileInput
                          id="file-upload"
                          className="mb-2"
                          name="companyLogo"
                          type="file"
                          onChange={event => {
                            const file = event.currentTarget.files[0]
                            if (file) {
                              setFieldValue('companyLogo', file)
                              const reader = new FileReader()
                              reader.onloadend = () => {
                                setFieldValue('logoPreview', reader.result)
                              }
                              reader.readAsDataURL(file)
                            }
                          }}
                          accept="image/png, image/jpg, image/jpeg"
                        />
                        <p className="text-xs text-gray-500">Maximum 5 MB</p>
                        <ErrorMessage
                          name="companyLogo"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="mb-2">
                        <Label
                          htmlFor="companyDescription"
                          value="Company Description"
                        />
                      </div>
                      <Field
                        as={TextInput}
                        id="companyDescription"
                        type="text"
                        name="companyDescription"
                        onChange={handleChange('companyDescription')}
                        value={values.companyDescription}
                        placeholder="Describe your company"
                      />
                      <ErrorMessage
                        name="companyDescription"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div>
                      <Label htmlFor="companyWebsite" value="Company Website" />
                      <Field
                        as={TextInput}
                        id="companyWebsite"
                        type="text"
                        name="companyWebsite"
                        onChange={handleChange('companyWebsite')}
                        value={values.companyWebsite}
                        placeholder="Enter your website link URL"
                      />
                      <ErrorMessage
                        name="companyWebsite"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>

                    <div>
                      <div className="mb-3">
                        <Label
                          htmlFor="socialMedia"
                          value="Social Media"
                          className="socialMediaLabel"
                        />
                      </div>
                      <Field
                        as={TextInput}
                        type="text"
                        name="twitter"
                        placeholder="Twitter Username (@username)"
                        className="mb-3 bg-[#F9FAFB]"
                        onChange={handleChange}
                        value={values.twitter}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="twitter"
                        component="div"
                        className="text-red-500 text-xs -mt-3 mb-3"
                      />
                      <Field
                        as={TextInput}
                        type="text"
                        name="facebook"
                        placeholder="Facebook Page (https://www.facebook.com/link)"
                        className="mb-3 bg-[#F9FAFB]"
                        onChange={handleChange}
                        value={values.facebook}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="facebook"
                        component="div"
                        className="text-red-500 text-xs -mt-3 mb-3"
                      />
                      <Field
                        as={TextInput}
                        type="text"
                        name="instagram"
                        placeholder="Instagram ID (@username)"
                        className="mb-2 bg-[#F9FAFB]"
                        onChange={handleChange}
                        value={values.instagram}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="instagram"
                        component="div"
                        className="text-red-500 text-xs -mt-2"
                      />
                    </div>
                  </div>
                  <div className="my-7 flex flex-col md:flex-row md:justify-end md:items-center md:gap-4">
                    <Button
                      className="bg-blue-700 px-5 py-2 w-full md:w-52 mb-3 md:mb-0"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save Changes
                    </Button>
                    <Button
                      className="px-5 py-2 w-full md:w-52"
                      color="gray"
                      onClick={() =>
                        fromTab ? handleTabSelect(1) : setSelectedCard(1)
                      }
                    >
                      I'll do this later
                    </Button>
                  </div>
                </Form>
              </Card>
            </div>
          )}
        </Formik>
      )}
    </>
  )
}

export default CompanyProfile
