import React from 'react'
import 'assets/css/user-settings-tab.css'

/*
 Note:
  - This component is also used for the Employer Settings Tab:
    - Path: src/components/ManageProfile/EmployerSettingTab/EmployerSettingTab.jsx
  - Any direct changes made here will also reflect in the Employer Settings Tab.
 */
export const AccountActionButton = ({
  label,
  onClick = () => {},
  variant,
  minWidth = '60',
  fontSize = '16',
  isDisabled = false,
}) => {
  const minWidthClass =
    {
      40: 'min-w-40',
      48: 'min-w-48',
      60: 'min-w-60',
      72: 'min-w-72',
    }[minWidth] || 'min-w-60'

  const buttonFontSize =
    {
      12: 'text-xs',
      14: 'text-sm',
      16: 'text-base',
    }[fontSize] || 'text-base'

  const baseClasses = `${buttonFontSize} font-medium lg:px-4 px-7 lg:mb-0 py-2 rounded-lg mb-2 account-action-button-xs account-action-button-sm ${minWidthClass}`
  const variantClasses = isDisabled
    ? 'bg-gray-300 text-gray-500 border border-gray-300'
    : variant === 'danger'
      ? 'bg-[#C81E1E] text-white border border-[#C81E1E]'
      : 'bg-white text-[#C81E1E] border border-[#C81E1E]'
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${baseClasses} ${variantClasses}`}
      disabled={isDisabled}
    >
      {label}
    </button>
  )
}
