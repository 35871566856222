import React, { useEffect, useState, useCallback, useMemo } from 'react'
import AdminNavbar from '../AdminNavbar'
import {
  processTableData,
  excludedColumns,
  columnMappings,
} from './deletion-helpers/account-deletion-helper'
import useToastMessage from 'hooks/useToastMessage'
import { deleteUser } from 'services/userService'
import {
  deleteAccountDeletionRequest,
  getPaginatedDeletionRequests,
} from 'services/accountDeletionRequestService'
import { DeleteConfirmationModal } from './confirmation-modal/DeleteConfirmationModal'
import { AdminEmployerAccountDeletion } from 'components/table/admin-account-deletion/AdminAccountDeletionTable'

export const EmployerAccountDeletionRequests = () => {
  const toastMessage = useToastMessage()
  const [query, setQuery] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [showPopup, setShowPopup] = useState(false)
  const [deletionRequests, setDeletionRequests] = useState([])
  const [deleteUserId, setDeleteUserId] = useState('')

  const { columns, data } = useMemo(
    () => processTableData(deletionRequests, excludedColumns, columnMappings),
    [deletionRequests],
  )

  const fetchDeletionRequests = useCallback(async () => {
    try {
      const response = await getPaginatedDeletionRequests(currentPage, query)
      if (response.status === 200) {
        const sortedData = response?.data?.records?.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        )
        setDeletionRequests(sortedData || [])
        setTotalPages(response?.data?.pagy?.total_pages)
        setTotalCount(response?.data?.pagy?.total_count)
      }
    } catch (error) {
      console.error('Error fetching deletion requests:', error)
    }
  }, [currentPage, query, toastMessage])

  const handleDelete = useCallback(user_id => {
    setShowPopup(true)
    setDeleteUserId(user_id)
  }, [])

  const submitDeleteAccountRequest = useCallback(async () => {
    setShowPopup(false)
    if (deleteUserId) {
      try {
        const response = await deleteUser(deleteUserId)
        if (response.status === 200) {
          toastMessage('Employer Account Deleted Successfully', 'success')
          fetchDeletionRequests()
        }
      } catch (error) {
        toastMessage('Error Deleting Account', 'error')
      }
    }
    setDeleteUserId('')
  }, [deleteUserId, fetchDeletionRequests, toastMessage])

  const handleAccountRequestDelete = useCallback(
    async id => {
      try {
        const response = await deleteAccountDeletionRequest(id)
        if (response.status === 200) {
          toastMessage('Employer Request Successfully Rejected', 'success')
          fetchDeletionRequests()
        }
      } catch (error) {
        toastMessage('Error Rejecting Employer Request', 'error')
      }
    },
    [fetchDeletionRequests, toastMessage],
  )

  const onSearchRequests = useCallback(searchValue => {
    setCurrentPage(1)
    setQuery(searchValue)
  }, [])

  useEffect(() => {
    fetchDeletionRequests()
  }, [fetchDeletionRequests])

  return (
    <div className="flex h-screen w-full flex-col bg-[#f9fafb]">
      <AdminNavbar selectedItem="deletion-requests" />
      <div className="p-4">
        <AdminEmployerAccountDeletion
          columns={columns}
          data={data}
          handleDelete={handleDelete}
          handleSearch={onSearchRequests}
          currentPage={currentPage}
          totalPages={totalPages}
          totalCount={totalCount}
          handleNextPage={() => setCurrentPage(currentPage + 1)}
          handlePrevPage={() => setCurrentPage(currentPage - 1)}
          handlePageClick={setCurrentPage}
          handleAccountRequestDelete={handleAccountRequestDelete}
        />
      </div>
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <DeleteConfirmationModal
            onClose={() => setShowPopup(false)}
            submitDeleteAccountRequest={submitDeleteAccountRequest}
          />
        </div>
      )}
    </div>
  )
}
