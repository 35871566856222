import React, { useState, useEffect } from 'react'
import { Modal, Select, Label } from 'flowbite-react'
import { expertiseInitialState } from '../../../config/constants'
import useToastMessage from '../../../hooks/useToastMessage'
import { editExpertise, createExpertise } from '../../../services/expertiseService'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { expertiseValidationSchema } from '../../../validations/ExpertiseValidationSchema'

const ExpertiseEditModal = ({
  isModalOpen,
  closeModal,
  title,
  skills,
  getExpertise,
  expertiseData,
}) => {
  const [data, setData] = useState(expertiseInitialState)
  const toastMessage = useToastMessage()

  useEffect(() => {
    if (expertiseData) {
      setData({
        id: expertiseData?.id || '',
        title: expertiseData?.title || '',
        skill_id: expertiseData?.skill_id || '',
      })
    } else {
      setData(expertiseInitialState)
    }
  }, [expertiseData])

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (title === 'Edit Expertise') {
        delete values?.industry
        const response = await editExpertise(values)

        if (response?.status === 200) {
          toastMessage('Expertise updated successfully!', 'success')
          closeModal()
          getExpertise()
          resetForm()
        }
      } else if (title === 'Create Expertise') {
        const response = await createExpertise(values)
        if (response?.status === 201) {
          toastMessage('Expertise created successfully!', 'success')
          closeModal()
          getExpertise()
          resetForm()
        }
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors.join(', ')
        alert(errorMessage)
      }
    }
  }

  return (
    <Modal
      show={isModalOpen}
      onClose={() => closeModal(false)}
      theme={{
        content: {
          base: 'bg-transparent w-3/4',
          inner: 'bg-transparent',
        },
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={data}
        validationSchema={expertiseValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleSubmit, values, isSubmitting }) => (
          <Form className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {title}
              </h3>
              <button
                onClick={() => closeModal()}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-6 space-y-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <Label className="block text-sm font-medium text-gray-900 dark:text-white">
                    Expertise
                  </Label>
                  <Field
                    onChange={handleChange}
                    value={values?.title}
                    type="text"
                    name="title"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Expertise"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-red-500 text-xs mt-1"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <div className="w-full">
                    <div className="flex pb-0.5">
                      <Label htmlFor="skill" value="Skill" />
                    </div>
                    <Field
                      onChange={handleChange}
                      value={values?.skill_id}
                      as="select"
                      id="skill"
                      name="skill_id"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="">Select Skill</option>
                      {skills.map(skill => (
                        <option key={skill.id} value={skill.id}>
                          {skill.title}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="skill_id"
                      component="div"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center p-6 space-x-3 rtl:space-x-reverse border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {title.includes('Create') ? 'Create' : 'Update'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ExpertiseEditModal
