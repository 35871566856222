import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'flowbite-react'
import { ProfileCompletenessStatus } from './ProfileCompletenessStatus'
import { selectCurrentUser } from '../../../../redux/currentUserSlice'
import { CandidateProfileDetails } from './CandidateProfileDetails'
import { fetchProfileOverview } from 'services/candidateDashboardService'
import '../../../../assets/css/profile-overview-page.css'

export const ProfileCard = ({ tabsRef }) => {
  const { currentUser } = useSelector(selectCurrentUser)
  const [profileCompletenessObject, setProfileCompletenessObject] = useState([])

  const fetchProfileStatuses = async () => {
    try {
      const response = await fetchProfileOverview()
      if (response.status === 200) {
        setProfileCompletenessObject(response.data.profile_completeness_status)
      }
    } catch (error) {
      console.error('Error fetching profile overview', error)
    }
  }

  useEffect(() => {
    fetchProfileStatuses()
  }, [])

  return (
    <>
      <div className="flex flex-row items-center justify-between mb-2">
        <h1 className=" text-lg font-bold leading-5 text-black ">
          Profile Card
        </h1>
        <div className="border border-blue-600 flex justify-center items-center rounded-md  mt-2">
          <Button
            outline
            size="xs"
            className=" bold  text-[#1a56db] font-semibold w-full max-w-[200px] rounded-[8px] border-sm "
            color={'white'}
            onClick={() => {
              tabsRef.current.setActiveTab(2)
            }}
          >
            <p className="text-blue-700 text-sm font-medium whitespace-nowrap ">
              Edit Profile{' '}
            </p>
          </Button>
        </div>
      </div>

      {/* Rectanle Box */}
      <div className="flex flex-col py-4 px-5 mt-2.5 mb-2 rounded-md bg-[#F6FBFF]">
        <p className=" text-base font-medium text-black">
          This is how your profile will appear to employers.
        </p>
        <p>
          <span className="font-satoshi text-sm font-bold text-black ">
            Tip:{' '}
          </span>
          <span className="font-satoshi text-sm font-medium text-[#4B5563]">
            Fill out your profile to increase your chances of getting noticed by
            employers and land your dream job!
          </span>
        </p>
      </div>
      {/* Profile completeness status */}
      <div className="flex flex-col profile-completeness-container gap-y-4 p-4 mt-4 mb-2">
        {profileCompletenessObject?.map(({ text, status }) => (
          <ProfileCompletenessStatus key={text} text={text} status={status} />
        ))}
      </div>

      {/* User work history card */}
      <div>
        <CandidateProfileDetails candidate={currentUser} />
      </div>
    </>
  )
}
