import React, { useState } from 'react'
import PaginationFooter from './PaginationFooter'
import testImage from 'assets/svgs/test_black.svg'
import { TruncatedText } from 'utilis/helpers'
import { Link, useLocation } from 'react-router-dom'
import searchIcon from 'assets/svgs/searchicon.svg'
import { SORTING_OPTIONS } from '../../config/constants'
const TestPaginantion = ({
  data,
  onSearch,
  onSort,
  currentPage,
  totalPages,
  totalCount,
  setCurrentPage,
  isLoading,
}) => {
  const location = useLocation()
  const [searchQuery, setSearchQuery] = useState('')
  const [sortstate, setsortstate] = useState('recently_added')

  const handleSearchChange = e => {
    const value = e.target.value
    setSearchQuery(value)
    onSearch(value)
  }

  const handleSortChange = e => {
    const value = e.target.value
    setsortstate(value)
    onSort(value)
  }

  const handlePageClick = page => {
    setCurrentPage && setCurrentPage(page)
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage && setCurrentPage(currentPage - 1)
    }
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage && setCurrentPage(currentPage + 1)
    }
  }

  return (
    <div className="relative  shadow-md sm:rounded-lg bg-white">
      <div className="flex flex-row items-center justify-left mb-5 mt-1 ml-5 mr-5">
        <div className="flex flex-col pt-4 lg:w-full w-1/3">
          <div className="relative flex">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <img src={searchIcon} alt="Search Icon" className="w-4 h-4" />
            </div>
            <input
              type="text"
              id="table-search"
              className="block pl-10 md:pr-3 md:py-2 text-sm text-gray-900 border border-gray-300 rounded-l-lg md:w-80 w-44 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Search test name"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <button
              onClick={handleSearchChange}
              className="bg-blue-600 text-white md:px-4 px-2 md:py-2 text-sm rounded-r-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800"
            >
              Search
            </button>
          </div>
          <div className="flex flex-wrap items-center lg:border-t  space-x-4 mt-4">
            <span className="text-sm font-medium mt-4 text-gray-900 dark:text-gray-300">
              Sort by
            </span>
            {SORTING_OPTIONS.map((value) => ( 
              <label key={value} className="flex items-center mt-4">
                <input
                  type="radio"
                  name="sort"
                  value={value}
                  className="radio"
                  onChange={handleSortChange}
                  checked={sortstate === value}
                  disabled={isLoading} // Disable inputs while loading
                />
                <span className="ml-2 text-sm text-gray-900 peer-checked:text-blue-600 peer-checked:font-medium cursor-pointer">
                  {value.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                </span>
              </label>
            ))}
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <tbody>
            {data?.tests?.length === 0 ? (
              <div className="flex items-center justify-center h-full w-full">
                <p className="text-gray-500 mb-6 text-2xl"> No Active Tests</p>
              </div>
            ) : (
              <div className={`grid grid-cols-1 sm:grid-cols-1 ml-12 mr-10 ${totalPages <=1 ? 'mb-8' : 'mb-0'} lg:grid-cols-2 xl:grid-cols-3`}>
                {data?.test?.map(test => (
                  <div
                    key={test.id}
                    className="flex flex-col bg-white rounded-lg border-1/2 mt-4 mb-4 shadow-md sm:p-4 p-3 w-[95%]"
                  >
                    <div className="flex text-left mb-2">
                      <div className="mr-2 mt-1">
                        <img
                          className="w-4 h-4"
                          src={testImage}
                          alt="Search Icon"
                        />
                      </div>
                      <p className="font-bold text-black text-base">
                        {test.title}
                      </p>
                    </div>
                    <>
                      <p className="text-left text-gray-600 font-normal text-sm">
                        <TruncatedText
                          text={test?.description || `No Description`}
                          limit="50"
                        />
                      </p>
                    </>
                    <>
                      <div className="flex justify-between mt-2">
                        <div className="text-right mt-2 text-black font-normal text-sm">
                          <span className="font-normal">
                            {test.tests_sent_count}
                          </span>{' '}
                          Candidates invited
                        </div>
                      </div>
                    </>
                    <>
                    <div className="flex-grow" />
                      <>
                        <Link
                          to="/candidate-list-of-test"
                          state={{
                            Testname: test.title,
                            _pathname: location.pathname,
                            TestId: test?.id,
                          }}
                        >                        
                          <button
                            className={`block w-full mt-4 font-bold py-2 bg-[#1A56DB] text-white px-4 lg:mb-0 mb-2 rounded-lg `}
                          >
                            View
                          </button>
                        </Link>
                      </>
                    </>
                  </div>
                ))}
              </div>
            )}
          </tbody>
        </table>
      )}
      <div className="mb-10" />
     { totalPages >1 && <PaginationFooter
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        handlePageClick={handlePageClick}
        data={data}
      />}
    </div>
  )
}

export default TestPaginantion
