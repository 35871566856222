import React from 'react';
import CertificationIcon from '../../assets/svgs/certificate.svg'

const CertificationsList = ({
  certifications,
  showMoreCertifications,
  setShowMoreCertifications
}) => (
  <div className="col-span-1">
    <div className="flex my-1">
      <img src={CertificationIcon} alt="Certificate Icon" className="my-auto" />
      <h3 className="text-[16px] font-medium text-[#4B5563] px-1">
        Certifications
      </h3>
    </div>
    {certifications?.slice(0, 2).map((certificate, index) => (
      <ul key={index} className="list-none py-1">
        <li className="mb-1 text-black text-14 font-semibold">
          {certificate.title}
        </li>
        <li className="mb-1 text-[#4B5563] text-[14px] font-medium">
          {certificate.issued_by}{' '}
          {certificate.file_url ? (
            <a href={certificate.file_url} className="text-[#1A56DB] font-medium cursor-pointer">
              [View]
            </a>
          ) : null}
        </li>
      </ul>
    ))}
    {certifications?.length > 2 && (
      <>
        {showMoreCertifications ? (
          <>
            {certifications.slice(2).map((certificate, index) => (
              <ul key={index} className="list-none py-1">
                <li className="mb-1 text-black font-semibold">
                  {certificate.title}
                </li>
                <li className="mb-1 text-[#4B5563] text-[14px] font-medium">
                  {certificate.issued_by}{' '}
                  {certificate.file_url ? (
                    <a href={certificate.file_url} className="text-[#1A56DB] font-medium cursor-pointer">
                      [View]
                    </a>
                  ) : null}
                </li>
              </ul>
            ))}
            <span
              className="text-[#1A56DB] font-bold cursor-pointer"
              onClick={() => setShowMoreCertifications(false)}
            >
              See Less
            </span>
          </>
        ) : (
          <span
            className="text-[#1A56DB] font-bold cursor-pointer"
            onClick={() => setShowMoreCertifications(true)}
          >
            See More
          </span>
        )}
      </>
    )}
    {certifications?.length === 0 && (
      <span className="text-14 font-[500] text-gray-900">
        No certifications available.
      </span>
    )}
  </div>
);

export default CertificationsList;
