import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { addToast, removeToastAfterDelay } from '../redux/toastSlice'

const useToastMessage = () => {
  const dispatch = useDispatch()

  const showToastMessage = useCallback(
    (message, type) => {
      const id = Date.now()
      dispatch(addToast({ id, message, type }))
      dispatch(removeToastAfterDelay(id))
    },
    [dispatch],
  )

  return showToastMessage
}

export default useToastMessage
