import React from 'react'
import exclamation from '../../../../assets/svgs/exclamation.svg'
import checkCircle from '../../../../assets/svgs/check-circle.svg'
import '../../../../assets/css/profile-overview-page.css'

export const ProfileCompletenessStatus = ({ text, status = false }) => {
  return (
    <div className="w-full profile-completeness-item">
      <div className="flex flex-row items-center gap-2">
        <img
          className="w-5 h-5"
          src={status ? checkCircle : exclamation}
          alt={status ? 'Check Circle' : 'Exclamation'}
        />
        <p className="text-nowrap">{text}</p>
      </div>
    </div>
  )
}
