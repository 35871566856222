import * as yup from 'yup'
import { NAME_REGEX, PASSWORD_REGEX } from './constants'

export const registerSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .test(
      'isValidFirstName',
      'Invalid name',
      value => !value || NAME_REGEX.test(value),
    ),
  lastName: yup
    .string()
    .required('Last name is required')
    .test(
      'isValidLastName',
      'Invalid name',
      value => !value || NAME_REGEX.test(value),
    ),
  email: yup.string().email('Invalid email').required('Email is required'),
  companyName: yup
    .string()
    .required('Company name is required')
    .min(2, 'Company name must consist of at least 2 characters')
    .max(50, 'Company name must not exceed 50 characters')
    .test(
      'isValidCompanyName',
      'Invalid company name',
      value => !value || NAME_REGEX.test(value),
    ),
  contactNumber: yup
    .string()
    .required('Contact number is required')
    .min(6, 'Contact number is required'),
  country: yup.string().required('Country is required'),
  city: yup.string().required('City is required'),
  mainIndustry: yup.string().required('Main industry is required'),
  companySize: yup.string().required('Company size is required'),
  currentPassword: yup.string().notRequired(),
  password: yup
    .string()
    .required('Password is required')
    .test(
      'isValidPassword',
      'Password must match the guidelines',
      value => !value || PASSWORD_REGEX.test(value),
    ),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Repeat password is required'),
})

export const registerCandidateSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .min(2, 'First name must consist of at least 2 characters')
    .max(50, 'First name must not exceed 50 characters')
    .test(
      'isValidFirstName',
      'Invalid name',
      value => !value || NAME_REGEX.test(value),
    ),
  lastName: yup
    .string()
    .required('Last name is required')
    .min(2, 'Last name must consist of at least 2 characters')
    .max(50, 'Last name must not exceed 50 characters')
    .test(
      'isValidLastName',
      'Invalid name',
      value => !value || NAME_REGEX.test(value),
    ),
  email: yup.string().email('Invalid email').required('Email is required'),
  contactNumber: yup
    .string()
    .required('Contact number is required')
    .min(6, 'Contact number is required'),
  residence_country: yup.string().required('Country is required'),
  password: yup
    .string()
    .required('Password is required')
    .matches(PASSWORD_REGEX, 'Password must follow the required guidelines'),
  repeatPassword: yup
    .string()
    .required('Repeat Password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

export const PasswordValidation = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .matches(PASSWORD_REGEX, 'Password must match the guidelines'),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Repeat password is required'),
})

export const PasswordNotRequiredValidation = yup.object().shape({
  password: yup
    .string()
    .matches(PASSWORD_REGEX, 'Password must match the guidelines'),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

export const nameValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .min(2, 'First name must be at least 2 characters')
    .max(50, 'First name must not exceed 50 characters')
    .test(
      'isValidFirstName',
      'Please Enter a valid name',
      value => !value || NAME_REGEX.test(value),
    ),
  lastName: yup
    .string()
    .required('Last name is required')
    .min(2, 'Last name must be at least 2 characters')
    .max(50, 'Last name must not exceed 50 characters')
    .test(
      'isValidLastName',
      'Please Enter a valid name',
      value => !value || NAME_REGEX.test(value),
    ),
})
