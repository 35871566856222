import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import usersIcon from '../../assets/svgs/users.svg'
import arrowIcon from '../../assets/svgs/arrow.svg'
import '../../assets/css/breadcrumbs.css'

export const Breadcrumb = ({ items = [], comparison = false, SetCandidateComparison = () => {} }) => {
  const location = useLocation()

  if (items.length === 0) {
    return null
  }

  return (
    <div
      className={`breadcrumb-container ${location.pathname === '/candidate-list-of-test' ? 'mb-3' : 'mb-6'}`}
    >
      <img src={usersIcon} alt="User Icon" className="icon ml-1" />
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {index < items.length - 1 ? (
            <>
              {location.pathname === '/all-quali-tests' ||
              location.pathname === '/candidate-list-of-test' ? (
                <Link
                  to="/test-and-assessment"
                  className="item grey text-12 md:text-16"
                  aria-label={`Go to ${item}`}
                >
                  {item}
                </Link>
              ) : (
                <Link
                  to="/search"
                  className="item grey text-12 md:text-16"
                  aria-label={`Go to ${item}`}
                  onClick={e => {
                    if (comparison) {
                      e.preventDefault()
                      SetCandidateComparison?.(false)
                    }
                  }}
                >
                  {item === 'Active' ? 'Active Search' : item}
                </Link>
              )}
            </>
          ) : (
            <p
              className={`item ${(location.pathname === '/candidate-list-of-test' || location.pathname === '/all-quali-tests' || comparison) && 'blue'} text-12 md:text-16 break-all text-wrap`}
            >
              {item}
            </p>
          )}
          {index !== items.length - 1 && (
            <img
              src={arrowIcon}
              alt="Arrow Icon"
              className="arrow"
              aria-hidden="true"
            />
          )}
        </React.Fragment>
      ))}
    </div>
  )
}
