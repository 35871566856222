import React, { useState } from 'react'
import { InfoButtonSidePanel } from 'components/Messaging/EmployerMessaging/EmployerMessageCandidateInfo/InfoButtonSidePanel'
import redCrossIcon from 'assets/svgs/red-x-outline.svg'
import BriefcaseIcon from 'assets/svgs/briefcase.svg'
import { FileInput, Button } from 'flowbite-react';
import { TruncatedText, isMobileDevice } from 'utilis/helpers'
import uploadFileIcon from 'assets/svgs/upload-file-icon.svg'
import { startConversation } from 'services/messagingService'
export const AcceptRejectOffer = ({
  selectedChat,
  currUser
}) => {
  const [fileLabel, setFileLabel] = useState("No file chosen")
  const isMobile = isMobileDevice()
  const [file, setFile] = useState()
  const [acceptOffer, setAcceptOffer] = useState(false)

  const handleSendMessage = async () => {
    const formData = new FormData();
    formData.append(
      'recipient_id',
      selectedChat?.user?.id || selectedChat?.users?.[0]?.user_id,
    )
    formData.append('subject', 'Chat Message');
    if (!acceptOffer && !file) {
      formData.append('body', 'Offer rejected')
    }
    else if (acceptOffer && file) {
      formData.append('attachment', file)
      formData.append('body', 'Offer accepted')
    } else {
      return;
    }
    try {
      const response = await startConversation(formData)
      if (response.status === 201) {
        setFile(null);
        setAcceptOffer(false);
      }
    } catch (error) {
      console.error('Error sending message:', error)
    }
  }

  return (
    <>
      { <div className="flex gap-2">
        <InfoButtonSidePanel
          iconSrc={BriefcaseIcon}
          text="Sign the offer"
          className="border-blue-700 text-white bg-blue-700 w-full -ml-5 min-w-[189px]"
          onClick={() => {setAcceptOffer(true)}}
        />
        <div className='flex-grow'/>
        <InfoButtonSidePanel
          iconSrc={redCrossIcon}
          text="Reject this offer"
          className="border-red-700 text-[#C81E1E] w-full min-w-[189px]"
          onClick={() => {handleSendMessage()}}
        />
      </div>}
      {acceptOffer &&
        <>
          <div className="text-[#111928] text-base -ml-5 font-medium">Upload Offer Letter</div>
          <div className="w-full flex -ml-5 gap-1">
            <div className="relative w-full">
              <FileInput
                id="file-upload"
                name="ProfilePicture"
                type="file"
                className="opacity-0 absolute inset-0 w-full cursor-pointer remove-pointer-event z-10"
                onChange={event => {
                  const file = event.currentTarget.files[0]
                  if (file) {
                    setFile(file);
                    setFileLabel(file.name)
                  }  else {
                    setFileLabel('No file chosen')
                  }
                }}
              />
              <div
                className="border rounded w-full cursor-pointer flex items-center bg-[#F9FAFB] border-[#D1D5DB]"
              >
                <button className="bg-gray-900 text-white px-2 py-2 rounded-s text-sm">
                  Choose File
                </button>
                <span className="ml-3 text-sm">
                  <TruncatedText text={fileLabel} limit={isMobile ? 5 : 15} />
                </span>
              </div>
            </div>
            <Button
              onClick={handleSendMessage}
              color="blue"
              size="sm"
              className="bg-blue-700 mr-1 rounded"
            >
              <img
                src={uploadFileIcon}
                alt="Upload Icon"
                className='ml-1'
              />
              <p className="px-1 mr-1 md:px-2 whitespace-nowrap text-white bg-blue-700 align-items-center">
                Submit
              </p>
            </Button>
          </div>
        </>
      }
    </>
  )
}
