import React from 'react'
import filePdfIcon from 'assets/svgs/file-pdf-outline.svg'
import smallDotIcon from 'assets/svgs/grey-small-dot.svg'

export const FileItemsView = ({ fileName, fileSize, fileType }) => {
  return (
    <div className="flex flex-col gap-1 bg-[#F9FAFB] py-2 px-3 rounded-lg">
      <div className="flex flex-row gap-2 items-center">
        <img
          src={filePdfIcon}
          alt="Pdf icon"
          className="w-4 h-4 md:w-4 md:h-4"
        />
        <span className="text-sm font-medium">{fileName}</span>
      </div>
      <div className="flex flex-row gap-2 items-center">
        <span className="text-xs font-normal text-[#6B7280]">{fileSize}</span>
        <img
          src={smallDotIcon}
          alt="Pdf icon"
          className="w-1 h-1 md:w-1 md:h-1"
        />
        <span className="text-xs font-normal text-[#6B7280]">{fileType}</span>
      </div>
    </div>
  )
}
