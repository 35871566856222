import React, { useState, useEffect } from 'react'
import { Modal, Label } from 'flowbite-react'
import { manageTestInitialState } from '../../../config/constants'
import useToastMessage from '../../../hooks/useToastMessage'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { testStatuses } from '../../../config/constants'
import { manageTestValidationSchema } from '../../../validations/ManageTestValidationSchema'
import { editCandidateTest } from '../../../services/manageTestService'
import { getValueFromLabel } from '../../../config/constants'
import { sendCompletionToCandidate } from '../../../services/testService'
import moment from 'moment'

const ManageTestEditModal = ({
  isModalOpen,
  closeModal,
  title,
  testsData,
  getManageTests,
}) => {
  const [data, setData] = useState(manageTestInitialState)
  const toastMessage = useToastMessage()

  useEffect(() => {
    if (testsData) {
      setData({
        id: testsData?.id,
        test_status: testsData?.status,
        test_marks: testsData?.marks,
      })
    } else {
      setData(manageTestInitialState)
    }
  }, [testsData])

  const handleEmail = async values => {
    try {
      values['email'] = testsData?.manage_test_user_email
      values['test_name'] = testsData?.test_name
      values['test_send_date'] = moment(testsData?.test_send_date).format("dddd, MMMM Do YYYY")
      values['company_user_name'] = testsData?.company_user_name
      values['candidate_first_name'] = testsData?.candidate_first_name

      await sendCompletionToCandidate(values)
      toastMessage('Email sent successfully!', 'success')
      closeModal()
    } catch (error) {
      console.error('Error in handle test:', error)
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors.join(', ')
        alert(errorMessage)
      }
    }
  }

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (title === 'Edit Manage Test') {
        const test_status = getValueFromLabel(testStatuses, values?.test_status)
        values['test_status'] = test_status
        const response = await editCandidateTest(values)
        if (response?.status === 200) {
          toastMessage('Updated successfully!', 'success')
          closeModal()
          getManageTests()
          resetForm()
          test_status === 'completed' && handleEmail(values)
        }
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors.join(', ')
        alert(errorMessage)
      }
    }
  }

  const handleStatusChange = (e, setFieldValue) => {
    const status = e.target.value
    setFieldValue('test_status', status)
    if (status !== 'Completed') {
      setFieldValue('test_marks', 0)
    } else {
      setFieldValue('test_marks', data?.test_marks || 0)
    }
  }

  return (
    <Modal
      show={isModalOpen}
      onClose={() => closeModal(false)}
      theme={{
        content: {
          base: 'bg-transparent w-3/4',
          inner: 'bg-transparent',
        },
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={data}
        validationSchema={manageTestValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleSubmit, values, isSubmitting, setFieldValue }) => (
          <Form className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {title}
              </h3>
              <button
                onClick={() => closeModal()}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-6 space-y-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <div className="w-full">
                    <div className="flex pb-0.5">
                      <Label htmlFor="test_status" value="Test Status" />
                    </div>
                    <Field
                      as="select"
                      id="test_status"
                      name="test_status"
                      onChange={e => handleStatusChange(e, setFieldValue)}
                      value={values.test_status}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="">Select Status</option>
                      {testStatuses.map(status => (
                        <option key={status.id} value={status.label}>
                          {status.label === 'Progress' ? "In Progress":  status.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="test_status"
                      component="div"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <Label htmlFor="test_marks">Test Marks</Label>
                  <Field
                    onChange={handleChange}
                    value={values?.test_marks}
                    type="number"
                    id="test_marks"
                    name="test_marks"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Test Marks"
                    min="0"
                    max="100"
                    disabled={values?.test_status!=='Completed'}
                  />
                  <ErrorMessage
                    name="test_marks"
                    component="div"
                    className="text-red-500 text-xs mt-1"
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center p-6 space-x-3 rtl:space-x-reverse border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {title.includes('Create') ? 'Create' : 'Update'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ManageTestEditModal
