import moment from 'moment'
import { addExpertise, updateUser } from 'services/userService'
import { createWorkHistories } from 'services/experienceService'
import { createCertifications } from 'services/certificationService'

const compareSubmittedwithPrevious = (expA, expB) => {
  return (
    Object.keys(expA).every(key => expA[key] === expB[key]) &&
    Object.keys(expB).every(key => expB[key] === expA[key])
  )
}

export const findExistingExperienceIndex = (array, singleObject) => {
  for (let i = 0; i < array.length; i++) {
    if (compareSubmittedwithPrevious(array[i], singleObject)) {
      return i
    }
  }
  return -1
}

export const replaceExpAtIndex = (expArr, index, newExp) => {
  if (index >= 0 && index < expArr.length) {
    expArr[index] = newExp
  }
}

export const findExpObjectByWorkHistoryId = (array, workHistoryId) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].workHistoryId === workHistoryId) {
      return i
    }
  }
  return -1
}

export const findCertObjectByCertificateId = (array, certificateId) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].certificateId === certificateId) {
      return i
    }
  }
  return -1
}

const selectedCompareSubmittedwithPrevious = (expA, expB) => {
  const keysToCompare = [
    'documentType',
    'documentTitle',
    'issuedBy',
    'dateOfIssue',
  ]
  return keysToCompare.every(key => expA[key] === expB[key])
}

export const findExistingCertificateIndex = (array, singleObject) => {
  for (let i = 0; i < array.length; i++) {
    if (selectedCompareSubmittedwithPrevious(array[i], singleObject)) {
      return i
    }
  }
  return -1
}

export const getChangedFields = (initialValues, currentValues) => {
  return Object.keys(currentValues).reduce((changedFields, key) => {
    if (
      JSON.stringify(initialValues[key]) !== JSON.stringify(currentValues[key])
    ) {
      changedFields[key] = currentValues[key]
    }
    return changedFields
  }, {})
}

export const transformExpertiseData = expertiseData => {
  if (!expertiseData || expertiseData?.length === 0) {
    return []
  }
  return expertiseData.map(item => {
    const skillId =
      item.all_expertises.length > 0
        ? item.all_expertises[0].skill_id.toString()
        : null

    const selectedExpertise = item.selected_expertise || []

    return {
      skills: [skillId],
      expertises: {
        [skillId]: selectedExpertise,
      },
    }
  })
}

export const appendCertificateData = (formData, cert, index) => {
  const fields = [
    { key: 'id', value: cert?.certificateId },
    { key: 'title', value: cert.documentTitle },
    { key: 'document_type', value: cert.documentType },
    { key: 'issued_by', value: cert.issuedBy },
    { key: 'issued_date', value: cert.dateOfIssue },
  ]

  fields.forEach(({ key, value }) => {
    formData.append(`certificate[${index}][${key}]`, value)
  })

  if (cert.file) {
    formData.append(`certificate[${index}][file]`, cert.file)
  }
}

export const transformExperienceData = exp => {
  const startDate = moment(exp.jobJoinDate, 'YYYY/MM')
    .startOf('month')
    .format('YYYY-MM-DD')
  const endDate =
    exp.currentWorking === 'Yes'
      ? null
      : moment(exp.jobEndDate, 'YYYY/MM').startOf('month').format('YYYY-MM-DD')

  return {
    id: exp.workHistoryId || undefined,
    is_working: exp.currentWorking === 'Yes',
    job_title: exp.jobRole,
    company_name: exp.companyName,
    description: exp.jobDescription,
    start_date: startDate,
    end_date: endDate,
    country: exp.jobCountry,
    city: exp.jobCity,
  }
}

/**
 * Creates an array of asynchronous tasks for updating candidate  experience info.
 *  - Prepares tasks that are executed to update a user's expertise,
 *    certifications, and work history.
 *  - Updates the Redux store with the latest candidate information after a successful update.
 *
 * @param {Object} currentUser - current user object.
 * @param {Function} setCurrentUser - Redux action to update the current user in the store.
 * @param {Object} values - Form values containing industry and years of experience.
 * @param {Array} selectedExpertises - An array of selected expertise objects.
 * @param {Array} addedOrModifiedCertificate - An array of certificates that have been added or modified.
 * @param {Array} addedOrModifiedExperience - An array of experiences that have been added or modified.
 * @param {Function} dispatch - The Redux dispatch function.
 * @returns {Array<Function>} An array of functions, each returning a promise, representing the update tasks.
 */
export const createTasksForCandidateInfoUpdate = (
  currentUser,
  setCurrentUser,
  values,
  selectedExpertises,
  addedOrModifiedCertificate,
  addedOrModifiedExperience,
  dispatch,
) => {
  const { industry, yearsOfExperience } = values || {}

  const body = {
    industry_id: parseInt(industry, 10),
    years_of_experience: parseInt(yearsOfExperience, 10),
  }
  return [
    () => addExpertise(currentUser.id, { skill_expertise: selectedExpertises }),
    () =>
      updateUser(currentUser.id, { user: body }).then(response => {
        dispatch(setCurrentUser(response.data))
      })
  ]
}
