import axiosInstance from './axiosConfig'
import { ChatEndpoint } from './constants'

export const startConversation = async payload => {
  return await axiosInstance.post(ChatEndpoint.createConversation, payload)
}

export const fetchPreviousConversation = async id => {
  return await axiosInstance.get(ChatEndpoint.getPreviousConversation(id))
}

export const getConversation = async () => {
  return await axiosInstance.get(ChatEndpoint.createConversation)
}

export const getUnreadMessages = async () => {
  return await axiosInstance.get(ChatEndpoint.getUnreadMessages)
}

export const deleteConversation = async id => {
  return await axiosInstance.delete(ChatEndpoint.deleteConversation(id))
}
