import React, { useEffect, useState } from 'react'
import AppBar from '../components/Dashboard/AppBar'
import { TopHeader } from '../components/Search/TopHeader'
import { AppSideBar } from '../components/Dashboard/AppSideBar'
import overviewImage from '../assets/overview.png'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectCompany,
  selectCurrentUser,
  setCurrentUser,
  setSearchHistory,
} from '../redux/currentUserSlice'
import {
  companyCompletionPercent,
  getEmployerDashboard,
} from '../services/companyService'
import { RecruitmentOverview, QualiTest, HiringFeeds } from '../components'
import { CandidateAppSideBar } from '../components/Dashboard/CandidateSideBar'
import { ProfileCompletionBar } from '../components/Dashboard/ProfileCompletionBar'
import { CandidateRecruitmentOverview } from '../components/Dashboard/candidate-dashboard/CandidateRecruitmentOverview'
import { fetchQualiTests } from 'services/candidateDashboardService'
import { getCurrentUser } from 'services/userService'
import '../assets/css/cborder.css'
import { useNavigate } from 'react-router-dom'
import {
  replaceCandidateFeeds,
  selectCandidateFeeds,
} from '../redux/candidateFeedSlice'
import { deleteFeedForUser, getCandidateFeeds, getCompanyFeeds } from 'services/feedsService'
import useToastMessage from 'hooks/useToastMessage'
import { Loader } from '../utilis/AppLoader'
import { PersistentInbox } from 'components/Messaging/PersistentMinimizableInbox'
import { getUnreadMessages } from 'services/messagingService'
import { updateUnreadCount } from '../redux/messageSlice'

const SearchCandidates = () => {
  const companyData = useSelector(selectCompany)
  const candidateFeeds = useSelector(selectCandidateFeeds)
  const [percentage, setPercentage] = useState(0)
  const [data, setData] = useState({})
  const [testsData, setTestsData] = useState([])
  const [updateFlag, setUpdateFlag] = useState(false)
  const { currentUser } = useSelector(selectCurrentUser)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toastMessage = useToastMessage()
  const [isLoading, setIsLoading] = useState(true)

  const fetchCompletionPercentage = async () => {
    const response = await companyCompletionPercent(companyData.id)
    if (response.data) setPercentage(response.data.percentage_complete)
  }

  const fetchUser = async () => {
    try {
      const response = await getCurrentUser()
      dispatch(setCurrentUser(response?.data))
    } catch (err) {
      if (err?.response?.status === 422) {
        localStorage.removeItem('accessToken')
        navigate('/')
      }
      console.error('Error while fetching user:', err)
    }
  }

  useEffect(() => {
    if (companyData && Object.keys(companyData)?.length > 0) {
      getEmployerData()
      fetchCompletionPercentage()
    }
  }, [])

  const getEmployerData = async () => {
    try {
      setIsLoading(true)
      const response = await getEmployerDashboard(companyData?.id)
      setData(response?.data)
    } catch (error) {
      console.error('Error getting employer data', error)
    } finally {
      setIsLoading(false)
    }
  }

  const getCandidateTests = async () => {
    try {
      const response = await fetchQualiTests()
      if (response.status === 200) {
        response.data.tests = response.data.tests.sort((a, b) => b.id - a.id)
        setTestsData(response.data)
        setUpdateFlag(!updateFlag)
      }
    } catch (error) {
      console.error('Error fetching quali tests', error)
    }
  }

  const fetchCandidateFeeds = async () => {
    const response = await getCandidateFeeds()
 
    if (response.status === 200) {
      const data = response.data.map(obj => obj.attributes)
      dispatch(replaceCandidateFeeds(data))
    }
  }
  const fetchCompanyFeeds = async () => {
    const response= await getCompanyFeeds()
    return response
  }

  useEffect(() => {
    if (currentUser.role === 'candidate') {
      getCandidateTests()
      fetchCandidateFeeds()
    }
  }, [currentUser])

  useEffect(() => {
    fetchUser()
    getMessageCount()
  }, [])
  const getMessageCount = async () => {
    const response = await getUnreadMessages()
    if (response?.status === 200) {
      const { unread_count } = response?.data
      dispatch(updateUnreadCount(unread_count))
    }
  }

  const clearUserFeed = async () => {
    const response = await deleteFeedForUser()
    if (response.status === 200) {
      currentUser.role==='candidate'?
      fetchCandidateFeeds() : fetchCompanyFeeds();
      toastMessage('Feeds cleared successfully!', 'success')
    }
  }

  return (
    <div className="h-full bg-[#f9fafb] font-satoshi-regular">
      <AppBar />
      <div className="flex flex-col md:flex-row p-2 md:p-10">
        <div className="hidden md:block">
          {currentUser.role === 'company_admin' ? (
            <AppSideBar />
          ) : (
            <CandidateAppSideBar />
          )}
        </div>
        <div className="flex flex-col w-full md:w-3/4 sm:pl-4">
          <TopHeader />
          {currentUser.role === 'company_admin' ? (
            <>
              <ProfileCompletionBar
                percentage={percentage}
                overviewImage={overviewImage}
              />
              <PersistentInbox />
              <div className="border-1.5 border-gray-400 rounded-lg bg-white mt-5 mr-4 shadow-md">
                <RecruitmentOverview data={data} />
                {isLoading ? (
                  <div className="flex justify-center items-center h-40">
                    <Loader size="large" color="blue" />
                  </div>
                ) : (
                  <QualiTest
                    data={data}
                    onTestStatusUpdate={getCandidateTests}
                  />
                )}
                <HiringFeeds userRole={currentUser.role} fetchCompanyFeeds={fetchCompanyFeeds}  clearUserFeed={clearUserFeed} />
              </div>
            </>
          ) : (
            <>
              <div className="custom-border mr-3">
                <CandidateRecruitmentOverview
                  updateFlag={updateFlag}
                  testsData={testsData}
                  candidateFeeds={candidateFeeds}
                  clearUserFeed={clearUserFeed}
                  getCandidateTests={getCandidateTests}
                />
                <PersistentInbox />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default SearchCandidates
