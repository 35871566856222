import React, { useState, useMemo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectCompany, selectCurrentUser, selectSearchHistory } from '../../../redux/currentUserSlice';
import useToastMessage from '../../../hooks/useToastMessage';
import crossIcon from '../../../assets/svgs/cross.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { logoutUser } from 'lib/api';
import { LogOutModal } from './LogOutModal';
import { DrawerContent } from './DrawerContent';

export const AppDrawer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastMessage = useToastMessage();
  const { currentUser } = useSelector(selectCurrentUser);
  const company = useSelector(selectCompany);
  const searchHistoryCount = useSelector(selectSearchHistory);
  const location = useLocation();
  const pathname = location.pathname;

  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isCompanyAdmin = useMemo(() => currentUser?.role === 'company_admin', [currentUser]);

  const handleLogoutClick = useCallback(() => {
    setLogoutModalOpen(true);
  }, []);

  const handleLogoutConfirmed = useCallback(() => {
    setLogoutModalOpen(false);
    logoutUser(dispatch, navigate, toastMessage);
  }, [dispatch, navigate, toastMessage]);

  const toggleDrawer = useCallback(() => {
    setDrawerOpen((prevState) => !prevState);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  return (
    <div>
      <button
        onClick={toggleDrawer}
        className="md:hidden p-2 rounded-md text-white top-0 right-2 z-50"
      >
        <FontAwesomeIcon icon={faBars} />
      </button>

      {drawerOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-30"
          onClick={handleCloseDrawer}
        ></div>
      )}

      <div
        className={`fixed top-0 left-0 w-64 h-full bg-white shadow-lg z-40 transition-transform transform ${drawerOpen ? 'translate-x-0' : '-translate-x-full'} md:hidden`}
      >
        <div className="flex flex-col h-full p-4">
          <button
            className="self-end mb-4 text-black"
            onClick={handleCloseDrawer}
          >
            <img src={crossIcon} alt="Close Drawer" />
          </button>
          <DrawerContent
            currentUser={currentUser}
            isCompanyAdmin={isCompanyAdmin}
            company={company}
            searchHistoryCount={searchHistoryCount}
            handleLogoutClick={handleLogoutClick}
          />
        </div>
      </div>

      <div className="hidden md:flex flex-col md:shadow-md bg-white w-80 py-2 px-4">
        <DrawerContent
          currentUser={currentUser}
          isCompanyAdmin={isCompanyAdmin}
          company={company}
          searchHistoryCount={searchHistoryCount}
          handleLogoutClick={handleLogoutClick}
        />
      </div>

      <LogOutModal
        logoutModalOpen={logoutModalOpen}
        setLogoutModalOpen={setLogoutModalOpen}
        handleLogoutConfirmed={handleLogoutConfirmed}
      />
    </div>
  );
};

