import React from 'react'

/*
 Note:
  - This component is also used for the Employer Settings Tab:
    - Path: src/components/ManageProfile/EmployerSettingTab/EmployerSettingTab.jsx
  - Any direct changes made here will also reflect in the Employer Settings Tab.
 */
export const SettingToggleSwitch = ({
  isChecked,
  onToggle = () => {},
  label,
  description,
}) => (
  <div className="flex flex-row gap-x-2 items-start">
    <label className="inline-flex items-center cursor-pointer mt-1">
      <input
        type="checkbox"
        className="sr-only peer focus:outline-none"
        checked={isChecked}
        onChange={onToggle}
      />
      <div className="relative w-11 h-6 bg-gray-200 dark:peer-focus:ring-blue-800 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
    </label>
    <div className="flex flex-col gap-y-0.5">
      <span className="text-base font-medium text-[#111928]">{label}</span>
      <span className="text-sm font-medium text-[#6B7280]">{description}</span>
    </div>
  </div>
)
