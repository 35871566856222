import axiosInstance from './axiosConfig'
import { CompanySearchHistory, SearchesEndPoint } from './constants'

export const companySearchHistory = async (currentPage, query) => {
  return await axiosInstance.get(
    CompanySearchHistory.searchHistory(currentPage, query),
  )
   
}
export const companySearchHistoryCount = async () => {
  return await axiosInstance.get(
    CompanySearchHistory.searchHistoryCount,
  )
}

export const updateSearchHistory = async (id, body) => {
  const response = await axiosInstance.patch(
    SearchesEndPoint.renameArchive(id),
    body,
  )
  return response
}

export const deleteSearchHistory = async (id, body) => {
  const response = await axiosInstance.delete(
    SearchesEndPoint.removeSearch(id),
    body,
  )
  return response
}

export const fetchSearchHistory = async (dispatch, setSearchHistory) => {
  try {
    const response = await companySearchHistory()
    dispatch(setSearchHistory(response.data))
    return response.data.data
  } catch (err) {
    console.error('Error while fetching history:', err)
  }
}

export const fetchSearchHistoryCount = async () => {
  try {
    return await companySearchHistoryCount() 
  } catch (err) {
    console.error('Error while fetching history:', err)
  }
}
