import React, { useEffect, useState } from 'react'
import AdminNavbar from './AdminNavbar'
import Table from '../table/Table'
import { generateColumns } from '../../utilis/helpers/tableHelper'
import { getAllTestsListings } from '../../services/testService'

const CreateTestsListing = () => {
  const [tests, setTests] = useState([])
  const [pagination, setPagination] = useState({
    totalPages: 1,
    totalCount: 0,
    currentPage: 1,
  })
  const [query, setQuery] = useState('')

  const fetchData = async () => {
    try {
      const response = await getAllTestsListings(pagination.currentPage, query)
      if (response.status === 200) {
        const { records, pagy } = response.data
        const sortedData = records.sort((a, b) => b.id - a.id)
        setTests(sortedData || [])
        setPagination({
          ...pagination,
          totalPages: pagy.total_pages,
          totalCount: pagy.total_count,
        })
      }
    } catch (error) {
      console.error('Error fetching tests:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [pagination.currentPage, query])

  const onSearchTestsListing = (search, value) => {
    setQuery(value)
  }

  const columns = generateColumns(tests)

  return (
    <div className="flex h-screen w-full flex-col bg-[#f9fafb]">
      <AdminNavbar selectedItem="tests" />
      <Table
        columns={columns}
        data={tests}
        title={'Test Listing'}
        onSearch={onSearchTestsListing}
        getTests={fetchData}
        currentPage={pagination.currentPage}
        totalPages={pagination.totalPages}
        totalCount={pagination.totalCount}
        setCurrentPage={page =>
          setPagination({ ...pagination, currentPage: page })
        }
        showBulkUploadButton
      />
    </div>
  )
}

export default CreateTestsListing
