import React, { useState, useEffect } from 'react'
import { Label, Modal } from 'flowbite-react'
import { usersInitialState } from '../../../config/constants'
import { updateUser, createUser } from '../../../services/userService'
import useToastMessage from '../../../hooks/useToastMessage'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { candidateValidationSchema } from '../../../validations/CandidateProfileScehma'
import { COUNTRY_CITY } from '../../../data/registerFormData'

const UserEditModal = ({
  isModalOpen,
  closeModal,
  title,
  candidatesData,
  getCandidates,
}) => {
  const [data, setData] = useState(usersInitialState)
  const toastMessage = useToastMessage()

  useEffect(() => {
    if (candidatesData) {
      setData(prevData => ({
        ...prevData,
        id: candidatesData?.id || '',
        first_name: candidatesData?.first_name || '',
        last_name: candidatesData?.last_name || '',
        country_of_residence: candidatesData?.country_of_residence || '',
      }))
    } else {
      setData(usersInitialState)
    }
  }, [candidatesData])

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const trimmedValues = {
        ...values,
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
      }

      if (title === 'Edit Candidate') {
        trimmedValues['email'] = candidatesData?.email
        trimmedValues['password'] = candidatesData?.password
        trimmedValues['phone_no'] = candidatesData?.phone_no
        trimmedValues['residence_country'] = trimmedValues?.country_of_residence
        const response = await updateUser(trimmedValues?.id, {
          user: trimmedValues,
        })

        if (response?.status === 200) {
          toastMessage('User updated successfully!', 'success')
          closeModal()
          getCandidates()
          resetForm()
        }
      } else if (title === 'Create Candidate') {
        const response = await createUser(trimmedValues)

        if (response?.status === 200) {
          toastMessage('User created successfully!', 'success')
          closeModal()
          getCandidates()
          resetForm()
        }
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors.join(', ')
        alert(errorMessage)
      }
    }
  }

  return (
    <Modal
      show={isModalOpen}
      onClose={() => closeModal(false)}
      theme={{
        content: {
          base: 'bg-transparent w-3/4',
          inner: 'bg-transparent',
        },
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={data}
        validationSchema={
          title === 'Edit Candidate'
            ? candidateValidationSchema.pick([
                'first_name',
                'last_name',
                'country_of_residence',
              ])
            : candidateValidationSchema
        }
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleSubmit, values, isSubmitting }) => (
          <Form className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {title}
              </h3>
              <button
                onClick={() => closeModal()}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-6 space-y-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <Label className="block text-sm font-medium text-gray-900 dark:text-white">
                    First Name
                  </Label>

                  <Field
                    onChange={handleChange}
                    type="text"
                    name="first_name"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter your first name"
                    value={values?.first_name}
                  />
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    className="text-red-500 text-xs mt-1"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <Label className="block text-sm font-medium text-gray-900 dark:text-white">
                    Last Name
                  </Label>
                  <Field
                    onChange={handleChange}
                    type="text"
                    name="last_name"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter your last name"
                    value={values?.last_name}
                  />
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    className="text-red-500 text-xs mt-1"
                  />
                </div>
                {title === 'Create Candidate' && (
                  <div className="col-span-6 sm:col-span-3">
                    <Label className="block text-sm font-medium text-gray-900 dark:text-white">
                      E-mail Address
                    </Label>
                    <Field
                      onChange={handleChange}
                      value={values?.email}
                      type="email"
                      name="email"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter your e-mail address"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>
                )}
                {title === 'Create Candidate' && (
                  <div className="col-span-6 sm:col-span-3">
                    <Label className="block text-sm font-medium text-gray-900 dark:text-white">
                      WhatsApp Number
                    </Label>
                    <Field
                      onChange={handleChange}
                      type="text"
                      name="phone_no"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="With country code (e.g. +966xxx)"
                      value={values?.phone_no}
                    />
                    <ErrorMessage
                      name="phone_no"
                      component="div"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>
                )}

                {title === 'Create Candidate' && (
                  <div className="col-span-6 sm:col-span-3">
                    <Label className="block text-sm font-medium text-gray-900 dark:text-white">
                      Password
                    </Label>
                    <Field
                      onChange={handleChange}
                      type="password"
                      name="password"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Password"
                      value={values?.password}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>
                )}
                <div className="col-span-6 sm:col-span-3">
                  <Label className="block text-sm font-medium text-gray-900 dark:text-white">
                    Country of Residence
                  </Label>
                  <Field
                    as="select"
                    onChange={handleChange}
                    type="select"
                    name="country_of_residence"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={values?.country_of_residence || ''}
                  >
                    <option value="">Select Country</option>
                    {Object.keys(COUNTRY_CITY).map(countryName => (
                      <option key={countryName} value={countryName}>
                        {countryName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="country_of_residence"
                    component="div"
                    className="text-red-500 text-xs mt-1"
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center p-6 space-x-3 rtl:space-x-reverse border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {title.includes('Create') ? 'Create' : 'Update'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default UserEditModal
