import React from 'react'
import { TextInput } from 'flowbite-react'
import eyeicon from 'assets/eye.svg'
import eyeSlashIcon from 'assets/eye-slash.svg'

export const PasswordInputField = ({
  id,
  label,
  value,
  showPassword,
  onChange,
  onToggle,
}) => (
  <div className="flex flex-col gap-2.5">
    <div className="pb-0.5">
      <label htmlFor={id} className="text-sm font-medium">
        {label}
      </label>
    </div>
    <div className="relative">
      <TextInput
        name={id}
        value={value}
        className="mb-1"
        id={id}
        type={showPassword ? 'text' : 'password'}
        placeholder="********"
        onChange={onChange}
      />
      <button
        type="button"
        className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-auto"
        onClick={onToggle}
      >
        <img
          src={showPassword ? eyeicon : eyeSlashIcon}
          alt={showPassword ? 'Hide password' : 'Show password'}
          className="h-4 w-4"
        />
      </button>
    </div>
  </div>
)
