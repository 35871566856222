import { AppSideBar } from 'components/Dashboard/AppSideBar'
import ManageAccount from 'components/ManageProfile/Account'
import ManageProfileTab from 'components/ManageProfile/Profile'
import { EmployerSettingTab } from 'components/ManageProfile/EmployerSettingTab/EmployerSettingTab'
import AppBar from '../../components/Dashboard/AppBar'
import { Tabs } from 'flowbite-react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/currentUserSlice'

const ManageProfile = () => {
  const [activeTab, setActiveTab] = useState('account')
  const loggedIn = useSelector(selectCurrentUser)
  const { currentUser } = loggedIn
  const handleTabClick = tab => {
    setActiveTab(tab)
  }

  return (
    <div className="h-full bg-[#f9fafb] font-satoshi-regular">
      <AppBar />
      <div className="flex flex-col md:flex-row p-4 md:p-10 min-h-screen">
        <div className="hidden md:block">
          <AppSideBar />
        </div>
        <div className="flex flex-col w-full md:w-3/5 pl-4">
          <div className="border-b-2 border-gray rounded-lg bg-white h-95 shadow mr-3">
            <div className=" flex flex-col md:flex-row justify-between items-center border-b border-grey-20 mx-4 pb-16">
              <div className="w-full flex flex-col mt-5 px-2">
                <h1 className="mb-2 text-24 font-[700] text-gray-900">
                  Manage Profile
                </h1>
                <hr className="border-gray-200" />
                <Tabs
                  // eslint-disable-next-line react/style-prop-object
                  style="underline"
                  aria-label="Tabs with underline"
                  className="tabs-no-outline tabs-no-bottom-border gap-8 mt-1"
                >
                  <Tabs.Item
                    title={
                      <span
                        className="pr-2 pl-2 text-16 font-[500]"
                        onClick={() => handleTabClick('account')}
                      >
                        Account
                      </span>
                    }
                    className="tabs-item"
                  >
                    <ManageAccount
                      companyData={currentUser}
                      activeTab={activeTab}
                    />
                  </Tabs.Item>
                  <Tabs.Item
                    title={
                      <span
                        className="pr-3 pl-3 text-16 font-[500]"
                        onClick={() => handleTabClick('profile')}
                      >
                        Profile
                      </span>
                    }
                    className="tabs-item"
                  >
                    <ManageProfileTab
                      companyData={currentUser}
                      activeTab={activeTab}
                    />
                  </Tabs.Item>
                  <Tabs.Item
                    title={
                      <span
                        className="pr-3 pl-3 text-16 font-[500]"
                        onClick={() => handleTabClick('settings')}
                      >
                        Settings
                      </span>
                    }
                    className="tabs-item"
                  >
                    <EmployerSettingTab />
                  </Tabs.Item>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageProfile
