import { TextInput } from 'flowbite-react'
import React from 'react'

const AccountInfo = ({ profileData, handleChange, errors }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col md:flex-row justify-between gap-4">
        <div className="w-full">
          <div className="pb-0.5">
            <label className="text-[#111928] text-[14px] font-[500] ">
              First Name
            </label>
          </div>
          <TextInput
            id="firstName"
            name="firstName"
            value={profileData.firstName}
            type="text"
            placeholder="Enter your first name"
            onChange={handleChange}
          />
         {errors?.firstName && (
          <p className="text-14 text-red-500 font-[500]  mt-2">{errors?.firstName}</p>
        )}
        </div>
        <div className="w-full">
          <div className="pb-0.5">
            <label className="text-[#111928] text-[14px] font-[500] ">
              Last Name
            </label>
          </div>

          <TextInput
            id="lastName"
            name="lastName"
            value={profileData.lastName}
            type="text"
            placeholder="Enter your last name"
            onChange={handleChange}
          />
          {errors?.lastName && (
          <p className="text-14 text-red-500 font-[500]  mt-2">{errors?.lastName}</p>
        )}
        </div>
      </div>
      <div>
        <div className="w-full">
          <div className="pb-0.5">
            <label className="text-[#111928] text-[14px] font-[500] ">
              Work E-mail
            </label>
          </div>
          <TextInput
            id="email"
            type="email"
            name="email"
            value={profileData.email}
            onChange={handleChange}
            placeholder="Your work e-mail address"
          />
          {errors.email && (
            <p className="text-14 text-red-500 font-[500] mt-2">{errors.email}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default AccountInfo
