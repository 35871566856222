import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../redux/currentUserSlice'
import { useLocation } from 'react-router-dom'
import FeedItem from './FeedItems'
const HiringFeeds = ({ userRole, fetchCompanyFeeds ,clearUserFeed}) => {
  const [candidateFeeds, setCandidateFeeds] = useState([])
  const { pathname } = useLocation()
  const isOverview = pathname === '/overview'
  const { currentUser } = useSelector(selectCurrentUser)
  const fetchData = async () => {
    const response = await fetchCompanyFeeds()
    const data=response.data
    const arrayFromValues = Object.values(data);
    setCandidateFeeds(arrayFromValues)    
  }
  const handleClearFeed = async () => {
    await clearUserFeed?.()
    fetchData()
  }
  useEffect(() => {
      fetchData()
      
  }, [])

  return (
    <div className="sm:mr-5 mb-5">
      <div className="mx-2 sm:ml-8 mt-8 flex items-center justify-between">
        {currentUser?.role === 'candidate' && (
          <h1 className="mb-4 text-20 text-[#111928] font-semibold">
            Candidate feeds
          </h1>
        )}
        {currentUser?.role === 'company_admin' && (
          <h1 className="mb-4 text-20 text-[#111928] font-semibold">
            {isOverview ? 'Hiring feeds' : 'Test feeds'}
          </h1>
        )}
        <button
         className="text-14 bg-white text-[#9CA3AF] px-4 py-2 border rounded-lg font-semibold"
         onClick={handleClearFeed}
         >
          Clear Feed
        </button>
      </div>
      <div className={`${candidateFeeds.length === 0 ? 'h-auto py-4 mb-14' : 'h-80'} mx-2 sm:ml-8 mt-4 overflow-y-scroll`}>
        {candidateFeeds.length === 0 ? (
          <p className="text-center text-gray-500">No feeds available.</p>
        ) : (
          candidateFeeds.map(feed => (
            <div key={feed.id} className="flex items-center gap-8 mt-4">
              <FeedItem
              currentUser={currentUser}
              notification_message={feed.attributes.notification_message}
              candidate={feed.attributes.candidate}
              date={new Date(feed.attributes.created_at).toLocaleDateString()}
              showButtons={feed.attributes.notification_message.includes("has completed")}
              userRole={userRole}
              test_id={feed.attributes.test_id}
              test_title={feed.attributes.test_title}
              candidate_id={feed.attributes.candidate_id}
            />
            </div>
          ))
        )}
      </div>
    </div>
  )
}
export default HiringFeeds
