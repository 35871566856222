import React, { useState } from 'react'
import 'assets/css/CandidateComparison.css'
import ComparisonCardFooter from './ComparisonCardFooter'
import { CandidateCertifications } from './ComparisonData/CandidateCertifications'
import CandidateTests from './ComparisonData/CandidateTests'
import CallForActionButtons from './ComparisonData/CallForActionButtons'
import CandidateProfile from './ComparisonData/CandidateProfile'
import { convertArrayToObject, roundPercentage } from 'utilis/helpers'

const CandidateComparison = ({ candidate, handleViewProgress }) => {
  const [showAll, setShowAll] = useState(false)

  const handleViewAll = () => {
    setShowAll(!showAll)
  }

  const displayedTests = showAll
    ? candidate?.user?.attempted_tests
    : candidate?.user?.attempted_tests?.slice(0, 3)

  const preferencesObject = convertArrayToObject(
    candidate?.candidate_preferences || [],
  )
  return (
    <div className="flex flex-col sm:w-80 md:w-96 mx-3">
      <div className="bg-[#F9FAFB] p-6 rounded-lg shadow-md mb-4">
        <CandidateProfile
          profilePicture={candidate?.user?.profile_picture_url}
          name={`${candidate?.user?.first_name} ${candidate?.user?.last_name}`}
          experience={candidate?.user?.years_of_experience}
          location={candidate?.user?.citizenship}
        />
        <CallForActionButtons
          candidate={candidate}
          handleViewProgress={handleViewProgress}
        />

        <div className="mt-4 flex justify-around gap-20">
          <div className="flex flex-col items-center ml-2">
            <p className="text-gray-900 text-14 font-[500]">Match Score</p>
            <p className="text-[#3F83F8] text-32 font-[700]">
              {roundPercentage(candidate?.expertise_match_score) || 0}
            </p>
          </div>

          <div className="flex flex-col items-center mr-4">
            <p className="text-gray-900 text-14 font-[500]">Preference Score</p>
            <p className="text-[#3F83F8] text-32 font-[700]">
              {roundPercentage(candidate.job_preference_match_score) || 0}
            </p>
          </div>
        </div>

        <div className="mt-6 flex flex-col items-center">
          <p className="text-gray-900 text-14 font-[500] mb-2">Salary Range</p>
          <p className="text-[#3F83F8] text-18 font-[700]">
            {preferencesObject.salaryRange || 'Not Provided'}
          </p>
        </div>

        <CandidateTests
          qualiTests={candidate?.user?.attempted_tests}
          displayedTests={displayedTests}
          handleViewAll={handleViewAll}
          showAll={showAll}
        />
        <CandidateCertifications
          certifications={candidate?.user?.certifications}
        />
      </div>

      <ComparisonCardFooter
        rejected={candidate?.profile_status === 'passed'}
        offered={candidate?.profile_status === 'offered'}
      />
    </div>
  )
}

export default CandidateComparison
