import React from 'react';
import moment from 'moment';

const CompanyActionInfo = ({ action, actionDate, searchName, candidateName, testName, score, decision }) => {
  const formatDate = (date) => moment.utc(date).format('D MMMM YYYY');

  const getActionMessage = () => {
    switch (action) {
      case 'contacted':
        return `You first messaged this candidate on `;
      case 'test_sent':
        return `You sent the following test ${testName} on `;
      case 'test_completed':
        return `${candidateName} completed ${testName} test on ${formatDate(actionDate)} and scored ${score}`;
      case 'hiring_decision':
        return `You ${decision === 'passed' ? 'rejected' : decision} ${candidateName} on `;
      default:
        return 'Not contacted yet';
    }
  };

  return (
    <div className='flex bg-white h-16 p-4 items-center gap-3 flex-shrink-0 rounded-lg shadow-md w-full'>
      <p className='text-gray-500 text-14 font-medium leading-[21px]'>
        {getActionMessage()}
        {action && action !== 'test_completed' && (
          <span className='text-gray-900 font-medium'>
            {formatDate(actionDate || new Date())}
          </span>
        )}
        {searchName && <span className='text-primary-600 font-bold'>{` for ${searchName}`}</span>}
      </p>
    </div>
  );
};

export default CompanyActionInfo;
