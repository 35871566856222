import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import LoginFields from '../../components/Login/LoginFields'
import AppBar from '../../components/Helper/AppBar'
import { clearCurrentUser } from '../../redux/currentUserSlice'

const AdminLogin = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(clearCurrentUser())
  }, [])
  return (
    <div className="flex h-screen w-full flex-col bg-white lg:bg-[#f9fafb] lg:px-20">
      <div className="p-4 md:px-10 md:pt-8">
        <AppBar />
      </div>
      <main className="flex-grow flex items-center justify-center">
        <div className="flex justify-center w-full md:w-1/2 p-4 md:p-0">
          <LoginFields
            heading="Quali Admin Login"
            registerNavigate="/candidate-register"
            isAdmin={true}
          />
        </div>
      </main>
    </div>
  )
}

export default AdminLogin
