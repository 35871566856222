import React, { useState } from 'react'
import { Modal } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'
import { updateSearchHistory } from 'services/searchResult'
const ArchiveModal = ({ show, setShowModal, searchID }) => {
  const navigate = useNavigate()
  const [selectedValue, setSelectedValue] = useState('')
  const handleHistoryUpdate = async () => {
    const formData = new FormData()
    formData.append('performed_action', 'archive')
    formData.append('search_id', searchID)
    formData.append('reject_candidates', 'true')
    const response = await updateSearchHistory(searchID, formData)
    if (response.status === 200) {
      navigate('/search')
    } else {
      console.log('Error while archiving search')
    }
  }

  const handleSubmit = () => {
    if (selectedValue === 'yes') {
      handleHistoryUpdate()
    }
    setShowModal(false)
  }

  return (
    <Modal
      show={show}
      onClose={() => setShowModal(false)}
      size="2xl"
      className="flex justify-center items-center"
    >
      <Modal.Body>
        <div className="flex justify-end">
          <button
            onClick={() => setShowModal(false)}
            className="text-gray-500 text-2xl hover:text-gray-700"
          >
            ✕
          </button>
        </div>
        <div className="px-4 font-satoshi">
          <h1 className="text-2xl font-bold">
            Congratulations for hiring this candidate!
          </h1>
          <p className="text-gray-500 text-16">
            Would you like to archive this post and reject the remaining
            candidates?
          </p>
          <div className="mt-6 text-14">
            <label className="flex items-center">
              <input
                type="radio"
                name="archive"
                value="yes"
                className="mr-2"
                onChange={e => setSelectedValue(e.target.value)}
              />
              Yes, archive and reject other candidates
            </label>
            <label className="flex items-center mt-2">
              <input
                type="radio"
                name="archive"
                value="no"
                className="mr-2"
                onChange={e => setSelectedValue(e.target.value)}
              />
              No, keep it as is
            </label>
          </div>
          <div className="flex justify-center mt-16"></div>
          <div className="flex justify-end">
            <button
              className="bg-blue-700 text-14 font-normal text-white px-4 py-2 rounded-md"
              onClick={() => handleSubmit()}
            >
              Submit and Close
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ArchiveModal
