import React from 'react'
import { Button, Card } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import EyeIcon from '../../assets/svgs/eye-blacked.svg'
import BriefCase from '../../assets/svgs/briefcase-blacked.svg'
import CalendarMonth from '../../assets/svgs/calendar-month-black.svg'
import PasteIcon from '../../assets/svgs/paste-blacked.svg'
import Clipboard from '../../assets/svgs/cliped-blacked.svg'
import { ADMIN_EMAIL } from 'config/constants'

const Plans = () => {
  const navigate = useNavigate()

  const sendEmailForQuestions = () => {
    window.location.href = `mailto:${ADMIN_EMAIL}`
  }

  return (
    <div className="w-full lg:w-11/11 mx-auto px-4">
      <Card className="p-4">
        <h5 className="text-xl md:text-2xl font-bold leading-tight tracking-tight text-gray-900">
          Get started today with Quali!
        </h5>
        <p className="font-normal text-gray-500 pb-6">
          Take advantage of our limited-time $0 offer.
        </p>
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-6 justify-center items-center text-xs">
          <Card className="w-full border-[#1C64F2] border-2">
            <div className="flex flex-col gap-3 items-center">
              <span className="mb-2 font-bold text-blue-800 text-sm bg-blue-100 px-2 py-1 rounded">
                Limited Time Offer
              </span>
              <h5 className="text-24 font-bold text-gray-900 mb-2">
                Pay-per-Hire
              </h5>
              <span className="text-gray-500 text-center text-2xl font-bold line-through">
                $50
              </span>
              <div className="flex items-baseline">
                <span className="text-gray-900 text-5xl font-extrabold leading-none ">
                  $0/
                </span>
                <span className="text-gray-900 text-4xl font-extrabold leading-[3rem]">
                  month
                </span>
              </div>
              <span className="text-primary-600 text-base font-medium leading-6">
                + success-based placement fee
              </span>
            </div>
            <Button
              className="bg-blue-700 px-6 py-2 mt-4"
              onClick={() => navigate('/overview')}
            >
              Get Started
            </Button>
            <div className="flex flex-col items-center mt-2">
              <span className="text-center text-gray-600 text-base font-bold leading-6 self-stretch font-satoshi-variable">
                Commitment-free, No cards required.
              </span>
              <span className="text-center text-gray-800 text-base font-medium leading-6 self-stretch">
                Only pay us when you hire a candidate!
              </span>
            </div>
            <ul className="my-7 space-y-5">
              <li className="flex space-x-3 items-center">
                <ReactSVG src={EyeIcon} />
                <span className="text-base font-normal leading-tight text-gray-900">
                  Unlimited Candidate Reveals
                </span>
              </li>
              <li className="flex space-x-3 items-center">
                <ReactSVG src={BriefCase} />
                <span className="text-base font-normal leading-tight text-gray-900">
                  Unlimited Talent Directory Search
                </span>
              </li>
              <li className="flex space-x-3 items-center">
                <ReactSVG src={CalendarMonth} />
                <span className="text-base font-normal leading-tight text-gray-900">
                  Unlimited Interview Scheduling
                </span>
              </li>
              <li className="flex space-x-3 items-center">
                <ReactSVG src={PasteIcon} />
                <span className="text-base font-normal leading-tight text-gray-900">
                  60 Quali Test Credit
                </span>
              </li>
              <li className="flex space-x-3 items-center">
                <ReactSVG src={Clipboard} />
                <span className="text-base font-normal leading-tight text-gray-900">
                  Complimentary Document and KYC Verification
                </span>
              </li>
            </ul>
          </Card>
        </div>
        <div className="my-2 flex flex-col md:flex-row gap-4 pt-4">
          <div className="w-full flex flex-col lg:flex-row gap-4 justify-center">
            <Button
              className="px-6 py-2"
              color="gray"
              onClick={() => {
                sendEmailForQuestions()
              }}
            >
              <span className="text-16">Still have questions?</span>
            </Button>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Plans
