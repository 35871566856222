import * as Yup from 'yup'
import { NAME_REGEX, PARAGRAPH_REGEX } from './constants'

export const candidateDeactivateHiredValidationSchema = Yup.object().shape({
  company_name: Yup.string()
    .matches(NAME_REGEX, 'Invalid Employer Name')
    .max(30, 'Employer Name Limit Reached')
    .required('Employer Name is required.'),
  salary: Yup.string()
    .matches(/^\d+$/, 'Salary must be a number.')
    .max(10, 'Salary max Limit Reached')
    .required('Salary is required.'),
})

export const candidateDeactivateOtherValidationSchema = Yup.object().shape({
  feedback: Yup.string()
    .matches(PARAGRAPH_REGEX, 'One or more Invalid Characters')
    .max(300, 'Words Maximum Limit Reached')
    .required('Please provide Reason')
    .test(
      'valid-content',
      'Input cannot consist of only punctuation marks',
      value => value && /[A-Za-zÀ-ÿ]/.test(value),
    ),
})

export const employerDeleteAccountValidationSchema =
  candidateDeactivateOtherValidationSchema
