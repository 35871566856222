import React from 'react'
import { Toast as FlowbiteToast } from 'flowbite-react'
import ToastMessageIcon from './ToastMessageIcon'

const Toast = ({ message, type }) => {
  return (
    <div className="flex flex-col gap-4">
      <FlowbiteToast>
        <ToastMessageIcon type={type} />
        <div className="ml-3 mr-2 text-sm font-normal">{message}</div>
        <FlowbiteToast.Toggle />
      </FlowbiteToast>
    </div>
  )
}

export default Toast
