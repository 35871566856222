import React from 'react'
import { Breadcrumb } from 'utilis/helpers/breadcrumbHelper'
import CandidateComparison from 'components/CandidatePipeline/Comparison/CandidateComparison'

const Comparison = ({
  candidates,
  SetCandidateComparison,
  handleViewProgress,
}) => {
  const breadcrumbItems = ['Candidate Pipeline', 'Comparison']
  return (
    <div className="border-b-2 border-gray rounded-lg bg-white mt-5 h-95 shadow md:mr-3">
      <div className=" flex flex-col md:flex-row justify-between items-center border-b border-grey-20 mx-4">
        <div className="w-full flex flex-col mt-5 px-2">
          <div className="mt-5">
            <Breadcrumb
              items={breadcrumbItems}
              comparison={true}
              SetCandidateComparison={SetCandidateComparison}
            />
          </div>

          <div className="flex flex-col md:flex-row gap-4 flex-wrap mb-[200px] justify-start">
            {candidates.map((candidate, index) => (
              <div key={index}>
                <CandidateComparison
                  candidate={candidate}
                  handleViewProgress={handleViewProgress}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Comparison
