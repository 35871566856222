import { createSlice } from '@reduxjs/toolkit'

export const searchSlice = createSlice({
  name: 'Search',
  initialState: {},
  reducers: {
    setSearch(state, action) {
      state.search = action.payload
    },
    clearSearch(state) {
      state.search = {}
    },
  },
})

export const { setSearch, clearSearch } = searchSlice.actions

export const searchReducer = searchSlice.reducer
export const selectSearch = state => state.search.search
