import { createSlice } from '@reduxjs/toolkit'

const toastSlice = createSlice({
  name: 'toast',
  initialState: [],
  reducers: {
    addToast(state, action) {
      const { id, message, type } = action.payload
      state.push({ id, message, type })
    },
    removeToast(state, action) {
      return state.filter(toast => toast.id !== action.payload.id)
    },
    clearToasts(state) {
      return []
    },
  },
})

export const { addToast, removeToast, clearToasts } = toastSlice.actions

export const removeToastAfterDelay = id => dispatch => {
  setTimeout(() => {
    dispatch(removeToast({ id }))
  }, 3000)
}

export default toastSlice.reducer
