import axiosInstance from './axiosConfig'
import { WorkExperienceEndPoint } from './constants'

export const createWorkHistories = async (userId, body) => {
  const response = await axiosInstance.post(
    WorkExperienceEndPoint.createWorkHistories(userId),
    body,
  )
  return response
}

export const updateWorkHistory = async(userId, experienceId, body) => {
  return await axiosInstance.put(WorkExperienceEndPoint.updateWorkHistory(userId, experienceId), body)
}

export const fetchWorkHistories = async userId => {
  const response = await axiosInstance.get(
    WorkExperienceEndPoint.fetchWorkHistories(userId),
  )
  return response
}

export const deleteWorkHistory = async (userId, workHistoryId) => {
  const response = await axiosInstance.delete(
    WorkExperienceEndPoint.deleteWorkHistory(userId, workHistoryId),
  )
  return response
}
