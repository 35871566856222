import React from 'react'
import messageIcon from 'assets/svgs/messages-outline.svg'
import userIcon from 'assets/svgs/user-outline.svg'
import { Button } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'

const CallForActionButtons = ({candidate, handleViewProgress}) => {
  const navigate=useNavigate()
  return (
    <div className="mt-4 flex gap-3 flex-wrap">
    <Button
      size="sm"
      onClick={() => navigate(`/company-inbox?candidateId=${candidate?.user?.id}`)} 
      className="view-profile-btn bg-primary-700 px-6 py-1"
    >
      <img
        src={messageIcon}
        className="w-4 h-4 mr-2"
        alt="Contact Icon"
      />
      <p className="text-12 font-[500] text-white">Chat</p>
    </Button>
    <Button
      color={'light'}
      size="sm"
      className="view-profile-btn px-6 py-1"
      onClick={() => handleViewProgress?.(candidate?.user?.id)}
    >
      <img
        src={userIcon}
        className="w-4 h-4 mr-2"
        alt="View Profile Icon"
      />
      <p className="text-12 font-[500] text-primary-700">View Profile</p>
    </Button>
  </div>
  )
}

export default CallForActionButtons
