import axiosInstance from './axiosConfig'
import { CandidateDashboardEndPoint } from './constants'

export const fetchRecruitmentOverview = async () => {
  return await axiosInstance.get(
    CandidateDashboardEndPoint.getRecruitmentOverview,
  )
}

export const fetchQualiTests = async () => {
  return await axiosInstance.get(CandidateDashboardEndPoint.getQualiTest)
}

export const fetchProfileOverview = async () => {
  return await axiosInstance.get(CandidateDashboardEndPoint.getOverview)
}
