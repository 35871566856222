import React from 'react'
import Image from '../components/Image'
import AppBar from '../components/Helper/AppBar'
import ForgotPasswordForm from '../components/CompanyReister/ForgotPasswordForm'
import { useLocation } from 'react-router-dom'

const ForgotPassword = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const user = searchParams.get('user')
  return (
    <>
      <div className="flex h-screen w-full flex-col sm:px-20 lg:bg-[#f9fafb]">
        <div className="sm:px-10 px-5 sm:mt-0 mt-7 sm:pt-8">
          <AppBar />
        </div>
        <main className="flex flex-grow items-center">
          <div className="flex w-full items-center justify-center md:-ml-10 2xl:ml-20 2xl:gap-20">
            <ForgotPasswordForm forgotPasswordForm={true} />
            <Image user={user} />
          </div>
        </main>
      </div>
    </>
  )
}

export default ForgotPassword
