import { List } from 'flowbite-react';
import React from 'react';

const PasswordGuidelines = () => (
  <div className="text-sm py-1">
    <p className="font-semibold text-slate-500">Password guideline:</p>
    <List>
      <List.Item>At least 8 characters</List.Item>
      <List.Item>Include special character</List.Item>
      <List.Item>Include capitalized characters</List.Item>
    </List>
  </div>
);

export default PasswordGuidelines;
