import React, { useEffect, useState } from 'react'
import TestPaginantion from '../../../components/table/TestPaginantion'
import { GetPagyStoredtestInformation } from '../../../services/testService'
const PagyQualiTest = () => {
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [data, setData] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState('')
  const [sortstate, setsortstate] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const getTestData = async () => {
    setIsLoading(true)
    try {
      const response = await GetPagyStoredtestInformation(
        currentPage,
        query,
        sortstate,
      )
      if (response.status === 200) {
        setData(response.data)
        setTotalPages(response.data.pagy.total_pages)
        setTotalCount(response.data.pagy.total_count)
      }
    } catch (error) {
      console.error('Error fetching test data:', error)
    } finally {
       setIsLoading(false)
    }
  }

  useEffect(() => {
    getTestData()
  }, [currentPage, query, sortstate])

  const onSearch = value => {
    setQuery(value)
  }
  const onSort = value => {
    setCurrentPage(1)
    setsortstate(value)
  }
  return (
    <div className="mr-3 ">
      <TestPaginantion
        data={data}
        onSearch={onSearch}
        onSort={onSort}
        currentPage={currentPage}
        isLoading={isLoading}
        totalPages={totalPages}
        totalCount={totalCount}
        setCurrentPage={setCurrentPage}
      />
    </div>
  )
}
export default PagyQualiTest
