import React, { useState } from 'react'
import { Modal } from 'flowbite-react'

const FileUploadModal = ({ isOpen, onClose, onUpload, acceptType }) => {
  const [file, setFile] = useState(null)

  const handleFileChange = event => {
    setFile(event.target.files[0])
  }

  const handleUpload = () => {
    if (file) {
      onUpload(file)
      onClose()
    }
  }

  return (
    <Modal show={isOpen} onClose={onClose}>
      <Modal.Header>Upload PDF File</Modal.Header>
      <Modal.Body>
        <input
          type="file"
          accept={acceptType || 'application/pdf'}
          onChange={handleFileChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
          onClick={handleUpload}
        >
          Upload File
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default FileUploadModal
