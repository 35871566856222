import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../services/axiosConfig'
import { setCurrentUser } from '../redux/currentUserSlice'
import showAlert from '../components/generics/Alert'
import useToastMessage from './useToastMessage'
import { updateAccountInactiveStatus } from 'services/userService'

export const useAuth = ({ isAdmin, isCandidate, isEmployer }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toastMessage = useToastMessage()

  const roleToPath = {
    super_admin: '/admin/companies',
    candidate_signup: '/user-profile-edit',
    default: '/overview',
  }

  const roleMapping = {
    candidate: isCandidate,
    company_admin: isEmployer,
    super_admin: isAdmin,
  }

  const initialValues = { login: '', password: '' }

  const handleSubmit = async (values, { setSubmitting }, isEmail) => {
    try {
      localStorage.removeItem('accessToken')

      const param =
        Object.keys(roleMapping).find(role => roleMapping[role]) || ''
      const response = await axiosInstance.post(
        '/login',
        { user: values },
        { params: { role: param } },
      )
      if (response.data.status?.code === 401) {
        const { status } = response.data
        let  errorMessage =  `Invalid ${isEmail ? 'email' : 'whatsapp number'} or  password`
        if (status.lock_info?.locked) {
          const lockTime = new Date(status.lock_info.locked_until)
          errorMessage = `Your account  was locked due to many  unsuccessful attempts to login. Please try again after ${lockTime.toLocaleTimeString()}`
        }

        showAlert({
          icon: 'error',
          title: 'Login Failed',
          text: errorMessage,
        })
        return
      }
      const { authorization } = response.headers

      if (authorization) {
        localStorage.setItem('accessToken', authorization)
        const user = response.data.data
        dispatch(setCurrentUser(user))
        const role = user.role?.toLowerCase()
        let path = roleToPath[role] || roleToPath.default

        if (!isAuthorized(role)) {
          throw new Error(
            `Invalid ${isEmail ? 'email' : 'whatsapp number'} or  password`
          )
        }

        if (
          role === 'candidate' &&
          user?.account_inactive &&
          user.is_signup_completed
        ) {
          await updateAccountInactiveStatus(user?.id, false)
        }

        if (role === 'candidate' && !user.is_signup_completed) {
          path = roleToPath.candidate_signup
          await updateAccountInactiveStatus(user?.id, true)
        }
        navigate(path)
        toastMessage('Logged in successfully!', 'success')
      }
    } catch (error) {
      console.error('Login failed:', error)
      toastMessage(error?.response?.data?.error, 'error')
      showAlert({
        icon: 'error',
        title: 'Login Failed',
        text: `Invalid ${isEmail ? 'email' : 'whatsapp number'} or  password`
      })
    } finally {
      setSubmitting(false)
    }
  }

  const isAuthorized = role => {
    const roleConditions = {
      super_admin: isAdmin,
      company_admin: isEmployer,
      candidate: isCandidate,
    }

    return roleConditions[role] || !Object.values(roleConditions).includes(true)
  }

  return { initialValues, handleSubmit }
}
