import { PASSWORD_REGEX } from '../../validations/constants'
import React, { useState } from 'react'
import { Card, Button, Modal } from 'flowbite-react'
import { Link, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { PasswordValidation } from '../../validations/registerValidationSchema'
import { resetPassword } from '../../services/userService'
import '../../assets/css/password-fields.css'
import ResetPasswordGif from '../../assets/password-reset.gif'
import { Label, List, TextInput } from 'flowbite-react'
import eyeSlashIcon from '../../assets/eye-slash.svg'
import eyeIcon from '../../assets/eye.svg'
import { LOGIN_URLS } from 'config/constants'

function PasswordFields(props) {
  const [registerData, setRegisterData] = useState({
    password: '',
    repeatPassword: '',
  })
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)
  const [errors, setErrors] = useState({})
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()

  const isPasswordMatch = () => {
    return registerData.password === registerData.repeatPassword
  }

  const isCorrectPassword = () => {
    return PASSWORD_REGEX.test(registerData.repeatPassword)
  }

  const handleChange = async event => {
    const { name, value } = event.target
    if (name === 'password') {
      setRegisterData({
        [name]: value,
        repeatPassword: '',
      })
    } else {
      setRegisterData(prevState => ({
        ...prevState,
        [name]: value,
      }))
    }

    await validateForm(value)
  }

  const validateForm = async value => {
    try {
      await yup.reach(PasswordValidation, 'password').validate(value)
      setErrors({})
    } catch (err) {
      const validationErrors = {}
      err?.inner?.forEach(error => {
        validationErrors[error.path] = error?.message
      })
      setErrors(validationErrors)
    }
  }

  const submitForm = async () => {
    setSubmitting(true)
    try {
      const user = await resetPassword({
        user: {
          id:props.userId,
          password: registerData.password,
        },
      })

      if (user.data) {
        setErrors({})
        setRegisterData({
          password: '',
          repeatPassword: '',
        })
        setOpenSuccessModal(true)
        setTimeout(() => {
          navigate(LOGIN_URLS[props.user])
        }, 4000)
      } else {
        alert('No user exists with this Id')
      }
    } catch (error) {
      if (error.inner) {
        const validationErrors = {}
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message
        })
        setErrors(validationErrors)
      } else {
        console.error('Error sending OTP:', error)
      }
    }
  }

  const handleBlur = async event => {
    const { name, value } = event.target

    try {
      await PasswordValidation.validate(registerData, { abortEarly: false })
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }))
    } catch (error) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: error.errors[0],
      }))
    }
  }

  const togglePasswordVisibility = setState => {
    setState(prevState => !prevState)
  }

  return (
    <Card className="w-full ml-0 sm:ml-20  mr-0 sm:mr-10  sm:pt-2 sm:pb-7 sm:px-5">
      <p className="text-3xl font-semibold tracking-wide">
        Reset Quali Password
      </p>

      <p className="font-normal text-gray-600">
        Already Have an Account?
        <Link to="/login" className="px-1 text-blue-700">
          Login here
        </Link>
      </p>

      <div className="flex flex-col md:flex-row justify-between gap-4">
        <div className="w-full">
          <div className="pb-0.5">
            <Label htmlFor="password" value="Enter Password" />
          </div>
          <div className="relative">
            <TextInput
              name="password"
              value={registerData.password}
              className="mb-1"
              id="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="********"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-auto"
              onClick={() => togglePasswordVisibility(setShowPassword)}
            >
              <img
                src={showPassword ? eyeIcon : eyeSlashIcon}
                alt={showPassword ? 'Hide password' : 'Show password'}
                className="h-4 w-4"
              />
            </button>
          </div>
          {errors.password && (
            <p className="text-sm font-semibold text-red-500">{errors.password}</p>
          )}
          <div className="text-sm py-1">
            <p className="font-semibold text-slate-500">Password guideline:</p>
            <List>
              <List.Item>At least 8 characters</List.Item>
              <List.Item>Include special character</List.Item>
              <List.Item>Include capitalized characters</List.Item>
            </List>
          </div>
        </div>
        <div className="w-full">
          <div className="pb-0.5">
            <Label htmlFor="repeatPassword" value="Repeat Password" />
          </div>
          <div className="relative">
            <TextInput
              value={registerData.repeatPassword}
              name="repeatPassword"
              id="repeatPassword"
              type={showRepeatPassword ? 'text' : 'password'}
              placeholder="********"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!registerData?.password}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-auto"
              onClick={() => togglePasswordVisibility(setShowRepeatPassword)}
            >
              <img
                src={showRepeatPassword ? eyeIcon : eyeSlashIcon}
                alt={showRepeatPassword ? 'Hide password' : 'Show password'}
                className="h-4 w-4"
              />
            </button>
          </div>
          {!submitting && errors.repeatPassword ? (
            <p className="text-sm font-semibold text-red-500 py-2">{errors.repeatPassword}</p>
          ) : registerData.repeatPassword.length ? (
            <p
              className={`text-xs ${isPasswordMatch() && isCorrectPassword() ? 'text-green-700' : 'text-red-700'}`}
            >
              {!submitting && !isCorrectPassword() ? (
                <p className='text-sm font-semibold text-red-500 py-2'>Password must match the guidelines</p>
              ) : !submitting && isPasswordMatch() ? (
                <p className='text-sm font-semibold text-[#0E9F6E] py-2'>Password matched!</p>
              ) : (
                !submitting &&  <p className='text-sm font-semibold text-red-500 py-2'></p>
              )}
            </p>
          ) : null}
        </div>
      </div>

      <Button
        type="submit"
        className="bg-blue-700 mt-6 py-2 text-lg"
        onClick={() => submitForm()}
      >
        Reset Password
      </Button>

      <Modal show={openSuccessModal} size="3xl">
        <div className="flex flex-col font-global-styling mb-12 mt-10">
          <div className="flex flex-col lg:flex-row w-full items-center justify-center gap-6 md:gap-20">
            <img
              src={ResetPasswordGif}
              alt="gif not found"
              className="w-[350px] h-[350px]"
            />
          </div>
          <div className="text-center break-normal mb-12 -mt-5">
            <h1 className="password-reset-text">
              Your reset password request is successful
            </h1>
            <p className="login-text-reset">
              Login with your new Quali password to continue
            </p>
          </div>
        </div>
      </Modal>
    </Card>
  )
}

export default PasswordFields
