import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useToastMessage from 'hooks/useToastMessage'
import { selectCurrentUser } from '../../../redux/currentUserSlice'
import { getAccountDeletionRequestStatus } from 'services/userService'
import { isMediumMobileDevice, isSmallMobileDevice } from 'utilis/helpers'
import { createAccountDeletionRequest } from 'services/accountDeletionRequestService'
import { EmployerDeleteAccountPopup } from './delete-account-popup/EmployerDeleteAccountPopup'
import { AccountActionButton } from 'components/Dashboard/candidate-profile-overview/CandidateSettingTab/account-action-button/AccountActionButton'
import { SettingToggleSwitch } from 'components/Dashboard/candidate-profile-overview/CandidateSettingTab/toggle-switch/SettingToggleSwitch'

export const EmployerSettingTab = () => {
  const toastMessage = useToastMessage()
  const { currentUser } = useSelector(selectCurrentUser)
  const isMobile = isMediumMobileDevice()
  const isSmallMobile = isSmallMobileDevice()

  const [whatsappNoti, setWhatsappNoti] = useState(true)
  const [emailNoti, setEmailNoti] = useState(true)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [deleteAccountRequested, setDeleteAccountRequested] = useState(false)

  const handleDeleteAccount = async feedbackValue => {
    setShowDeletePopup(false)
    try {
      const deletionResponse = await createAccountDeletionRequest(feedbackValue)
      if (deletionResponse.status !== 201) {
        throw new Error('Failed to save employer feedback')
      }
      setDeleteAccountRequested(true)
      toastMessage('Account Deletion Requested Successfully', 'success')
    } catch (error) {
      toastMessage('Error Deleting Account Please Try Again', 'error')
    }
  }

  const fetchAccountDeletionStatus = useCallback(async () => {
    try {
      const response = await getAccountDeletionRequestStatus(currentUser?.id)
      if (response?.status === 200) {
        setDeleteAccountRequested(response.data?.deletion_request_submitted)
      }
    } catch (error) {
      console.error('Error fetching account deletion status:', error)
    }
  }, [currentUser?.id])

  useEffect(() => {
    fetchAccountDeletionStatus()
  }, [fetchAccountDeletionStatus])

  return (
    <div className="flex flex-col gap-y-6 px-6">
      <h1 className="text-lg font-bold text-black">Settings</h1>
      <div className="flex flex-col gap-y-4">
        <p className="text-base font-bold text-black">Notifications</p>
        <SettingToggleSwitch
          isChecked={whatsappNoti}
          onToggle={() => setWhatsappNoti(!whatsappNoti)}
          label="WhatsApp Notifications"
          description="Turn on WhatsApp notification from Quali"
        />
        <SettingToggleSwitch
          isChecked={emailNoti}
          onToggle={() => setEmailNoti(!emailNoti)}
          label="E-mail Notification"
          description="Turn on e-mail notification from Quali"
        />
        <div className="border-dashed border-b-2 border-[#E3E3E3]" />
      </div>

      <div className="flex flex-col gap-y-4">
        <p
          className={`${isMobile ? 'text-base text-nowrap' : 'text-base'} font-bold text-black`}
        >
          Account Deletion
        </p>
        <div className="flex flex-row gap-5 account-action-buttons">
          <AccountActionButton
            label="Delete Quali Account"
            onClick={() => setShowDeletePopup(true)}
            variant="danger"
            isDisabled={deleteAccountRequested}
            minWidth={isSmallMobile ? '48' : '60'}
            fontSize={isSmallMobile ? '12' : '16'}
          />
        </div>
      </div>

      {deleteAccountRequested && (
        <div className="w-full">
          <span className="text-base font-medium text-[#585C5F]">
            Account deletion request sent, someone from Quali will reach out to
            you soon.
          </span>
        </div>
      )}

      {showDeletePopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <EmployerDeleteAccountPopup
            onClose={() => setShowDeletePopup(false)}
            handleDeleteAccount={handleDeleteAccount}
          />
        </div>
      )}
    </div>
  )
}
