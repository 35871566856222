import React from 'react'


export const InitialLetters = ({ Name }) => {
  
  const words = Name?.split(/[^a-zA-Z]+/,2)

  
  const initials = words?.map(word => word.charAt(0).toUpperCase()).join('')

  return (
    <div>{initials}</div>
  )
}
