import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalLoading } from '../../../redux/LoaderSlice' // You'll create this later

export const useLoader = (initialState = false) => {
  const [loading, setLoading] = useState(initialState);
  const dispatch = useDispatch();

  const showLoader = useCallback(() => {
    setLoading(true);
  }, []);

  const hideLoader = useCallback(() => {
    setLoading(false);
  }, []);

  const showGlobalLoader = useCallback(() => {
    dispatch(setGlobalLoading(true));
  }, [dispatch]);

  const hideGlobalLoader = useCallback(() => {
    dispatch(setGlobalLoading(false));
  }, [dispatch]);

  return { loading, showLoader, hideLoader, showGlobalLoader, hideGlobalLoader };
};
