import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Image from '../../components/Image'
import LoginFields from '../../components/Login/LoginFields'
import AppBar from '../../components/Helper/AppBar'
import { clearCurrentUser } from '../../redux/currentUserSlice'

const EmployerLogin = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(clearCurrentUser())
  }, [])
  return (
    <div className="flex flex-col h-screen w-full bg-white lg:bg-gray-50">
      <div className="p-4 mt-5 xs:mt-0 md:px-10 md:pt-8">
        <AppBar />
      </div>
      <main className="flex-grow flex items-center justify-center">
        <div className="flex flex-col md:flex-row items-center w-full sm:mt-0 md:px-20">
          <div className="flex justify-center w-full lg:w-1/2">
            <LoginFields
              heading="Quali Employer Login"
              registerNavigate="/register"
              isEmployer={true}
            />
          </div>
          <div className="hidden lg:block flex justify-center w-full mt-8 lg:mt-0 lg:w-1/2">
            <Image />
          </div>
        </div>
      </main>
    </div>
  )
}

export default EmployerLogin
