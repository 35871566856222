import React from 'react'

export const DeactivationRadio = ({
  id,
  name,
  label,
  isChecked,
  onChange,
  children,
}) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-4">
        <input
          id={id}
          type="radio"
          name={name}
          checked={isChecked}
          onChange={onChange}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label htmlFor={id} className="ml-2 text-sm font-medium text-[#111928]">
          {label}
        </label>
      </div>
      {isChecked && <>{children}</>}
    </div>
  )
}
