export const deactivateAccountOptions = {
  HIRED_QUALI: 'hired-quali',
  HIRED_ELSEWHERE: 'hired-elsewhere',
  TAKING_BREAK: 'taking-break',
}

export const deactivateAccountList = [
  {
    ID: deactivateAccountOptions.HIRED_QUALI,
    NAME: 'deactivate-reason-1',
    LABEL: 'I was hired on Quali and am no longer looking for a job.',
  },
  {
    ID: deactivateAccountOptions.HIRED_ELSEWHERE,
    NAME: 'deactivate-reason-2',
    LABEL: 'I was hired elsewhere and am no longer looking for a job.',
  },
  {
    ID: deactivateAccountOptions.TAKING_BREAK,
    NAME: 'deactivate-reason-3',
    LABEL: 'I am taking a break from the job market.',
  },
]
