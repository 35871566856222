export const BUSINESS_SIZES = [
  { value: 'Small', label: 'small businesses 5-19' },
  { value: 'Medium', label: 'medium businesses 20-199' },
  { value: 'Large', label: 'large businesses 200+' },
]
export const GET_IN_TOUCH_EMAIL = 'rami@qualime.ai'

export const GCC_COUNTRIES = [
  { label: 'None', value: 'none', flag: null },
  { label: 'Saudi Arabia', value: 'Saudi Arabia', flag: 'SA' },
  { label: 'United Arab Emirates', value: 'United Arab Emirates', flag: 'AE' },
  { label: 'Kuwait', value: 'Kuwait', flag: 'KW' },
  { label: 'Bahrain', value: 'Bahrain', flag: 'BH' },
  { label: 'Oman', value: 'Oman', flag: 'OM' },
  { label: 'Qatar', value: 'Qatar', flag: 'QA' },
]
export const SORTING_OPTIONS = [
  'alphabetically',
  'most_completed',
  'recently_added',
]

export const companyHeaderMapping = {
  contact_email: 'Email',
  contact_number: 'Phone Number',
  company_size: 'Company Size',
}
export const companyInitialState = {
  id: null,
  name: '',
  description: '',
  contact_email: '',
  contact_number: '',
  country: '',
  city: '',
  industry_id: null,
  company_size: '',
  social_network_links: {
    twitter: '',
    facebook: '',
    instagram: '',
  },
  website_link: '',
  user_id: null,
  created_at: null,
  updated_at: null,
}
export const industryInitialState = {
  id: null,
  title: '',
  details: '',
}
export const skillsInitialState = {
  id: null,
  title: '',
  industry_id: null,
}
export const expertiseInitialState = {
  id: null,
  title: '',
  skill_id: null,
}
export const industryDropdownmenuItems = [
  { path: '/admin/industries', title: 'Industries' },
  { path: '/admin/skills', title: 'Skills' },
  { path: '/admin/expertise', title: 'Expertise' },
]
export const testsInitialState = {
  id: null,
  title: '',
  test_link: '',
  test_id: null,
  description: '',
  job_title: '',
  job_description: '',
}
export const candidateRevealedStatus = ['revealed', 'both']
export const candidateSavedStatus = ['liked', 'both']
export const usersInitialState = {
  id: null,
  email: '',
  phone_no: '',
  first_name: '',
  last_name: '',
  password: '',
  role: 'candidate',
  country_of_residence: '',
}
export const manageTestInitialState = {
  id: null,
  test_status: '',
  test_marks: null,
}
export const testStatuses = [
  { id: 0, label: 'Sent', value: 'sent' },
  { id: 1, label: 'Progress', value: 'progress' },
  { id: 2, label: 'Completed', value: 'completed' },
]
export const getValueFromLabel = (array, label) => {
  const statusObj = array.find(status => status.label === label)
  return statusObj ? statusObj.value : null
}
export const COMPANY_SIZE = {
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
}
export const ADMIN_EMAIL = 'rami@qualime.ai'

export const certificateStaticOption = {
  title: 'Can’t find the certificates you wanted to verify?',
}

export const STATUS_MAP = {
  null: 'In Progress',
  hired: 'Candidate Hired',
  passed: 'Rejected',
}

export const STATUS_COLOR_MAP = {
  passed: 'bg-red-500',
  default: 'bg-green-500',
}

export const VALID_PROFILE_STATUSES = ['offered', 'hired', 'passed']

export const LOGIN_URLS = {
  candidate: '/user/login',
  employer: '/login',
  admin: '/admin/login',
}
