import { useEffect, useRef, useState } from 'react'
import { Field, ErrorMessage, Formik } from 'formik'
import { Select, TextInput, Label, Textarea, Button } from 'flowbite-react'
import { CandidateWorkExperienceValidationSchema } from 'validations/CandidateProfileScehma'
import { COUNTRY_CITY } from 'data/registerFormData'
import {
  findExistingExperienceIndex,
  findExpObjectByWorkHistoryId,
  replaceExpAtIndex,
} from './experience-helpers/experienceHelpers'
import 'assets/css/experience-form.css'
import { ScrollableDropdown } from 'components/generics/dropdown/ScrollableDropdown'
import 'assets/css/scrollable-dropdown.css'
import { ReactDatePickerComponent } from 'components/generics/reactDatePicker/ReactDatePicker'
import { createWorkHistories, updateWorkHistory } from 'services/experienceService'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../../redux/currentUserSlice'
import moment from 'moment'

export const EditWorkExperienceComponent = ({
  handleCountryChange,
  cities,
  setCities,
  workExperience,
  isModifyRequested,
  setIsModifyRequested,
  addedOrModifiedExperience,
  setAddedOrModifiedExperience,
  previousExperience,
  setPreviousExperience,
  setShowForm,
  isCurrentWorking,
  setIsCurrentWorking,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(
    workExperience?.jobCountry || '',
  )
  const [endDateDisable, setEndDateDisable] = useState(
    workExperience?.currentWorking === 'Yes',
  )
  const [isDefaultCity, setIsDefaultCity] = useState(false)
  const setFieldValueRef = useRef(null)
  const prevWorkingStatus = useRef(isCurrentWorking)
  const { currentUser } = useSelector(selectCurrentUser)
  const handleCurrentWorkingChangeWithFormik = (event, setFieldValue) => {
    const { value } = event.target
    setFieldValue('currentWorking', value)
    setEndDateDisable(value === 'Yes')

    if (
      value === 'No' &&
      workExperience &&
      workExperience?.currentWorking === 'Yes'
    ) {
      setIsCurrentWorking(false)
    }
  }

  const handleCurrentWorkingChange = (
    previousExperience,
    addedOrModifiedExperience,
  ) => {
    if (previousExperience?.length > 0) {
      previousExperience.some(exp => {
        if (exp.currentWorking === 'Yes') {
          setIsCurrentWorking(true)
          return true
        }
        return false
      })
    } else if (
      addedOrModifiedExperience &&
      addedOrModifiedExperience?.length > 0
    ) {
      addedOrModifiedExperience.some(exp => {
        if (exp.currentWorking === 'Yes') {
          setIsCurrentWorking(true)
          return true
        }
        return false
      })
    }
  }

  const handleEditFormSubmit = async workExpData => {
    setIsSubmitted(true)

    const workExperience = {
      id: workExpData.workHistoryId,
      company_name: workExpData.companyName,
      job_title: workExpData.jobRole,
      start_date: moment(workExpData.jobJoinDate, 'YYYY/MM').startOf('month').format('YYYY-MM-DD'),
      end_date: moment(workExpData.jobEndDate, 'YYYY/MM').startOf('month').format('YYYY-MM-DD'),
      is_working: workExpData.currentWorking,
      city: workExpData.jobCity,
      country: workExpData.jobCountry,
      description: workExpData.jobDescription,
    }

    const response = workExperience.id ? 
                      await updateWorkHistory(currentUser.id, workExperience.id, {work_experience: workExperience})
                      : await createWorkHistories(currentUser.id, {work_experience: workExperience})

    if (response.status === 201 || response.status === 200){
      workExpData = {
        companyName: response.data.company_name,
        currentWorking: response.data.is_working,
        jobCity: response.data.city,
        jobCountry: response.data.country,
        jobDescription: response.data.description,
        jobEndDate: response.data.end_date,
        jobJoinDate: response.data.start_date,
        jobRole: response.data.job_title,
        workHistoryId: response.data.id,
      }
    }

    if (!isModifyRequested) {
      const updatedNewExperience = [...addedOrModifiedExperience, workExpData]
      setAddedOrModifiedExperience(updatedNewExperience)
      setPreviousExperience([...previousExperience, workExpData])
    }

    // logic for experience modification
    if (isModifyRequested) {
      const addedPrevModifiedIndex = findExpObjectByWorkHistoryId(
        addedOrModifiedExperience,
        workExpData.workHistoryId,
      )
      const prevExpArrIndex = findExpObjectByWorkHistoryId(
        previousExperience,
        workExpData.workHistoryId,
      )
      if (addedPrevModifiedIndex !== -1) {
        replaceExpAtIndex(
          addedOrModifiedExperience,
          addedPrevModifiedIndex,
          workExpData,
        )
        setAddedOrModifiedExperience(addedOrModifiedExperience)
      } else {
        const updatedNewExperience = [...addedOrModifiedExperience, workExpData]
        setAddedOrModifiedExperience(updatedNewExperience)
      }
      replaceExpAtIndex(previousExperience, prevExpArrIndex, workExpData)
      setPreviousExperience(previousExperience)
      handleCurrentWorkingChange(previousExperience, addedOrModifiedExperience)
    }

    if (isModifyRequested && workExpData.workHistoryId === '') {
      const index = findExistingExperienceIndex(
        addedOrModifiedExperience,
        workExperience,
      )
      const prevExpArrIndex = findExistingExperienceIndex(
        previousExperience,
        workExperience,
      )
      if (index !== -1) {
        replaceExpAtIndex(addedOrModifiedExperience, index, workExpData)
        replaceExpAtIndex(previousExperience, prevExpArrIndex, workExpData)
        setAddedOrModifiedExperience(addedOrModifiedExperience)
        setPreviousExperience(previousExperience)
      }
      handleCurrentWorkingChange(previousExperience, addedOrModifiedExperience)
    }
    setShowForm(false)
    setIsModifyRequested(false)
  }

  useEffect(() => {
    if (workExperience?.jobCountry) {
      setCities(COUNTRY_CITY[workExperience?.jobCountry] || [])
    }
  }, [workExperience, setCities])

  useEffect(() => {
    if (endDateDisable) {
      setFieldValueRef.current('jobEndDate', '')
    }
  }, [endDateDisable])
  useEffect(() => {
    if (isDefaultCity && setFieldValueRef.current) {
      setFieldValueRef.current('jobCity', '')
    }
    handleCountryChange(selectedCountry)
    setIsDefaultCity(true)
  }, [selectedCountry])

  return (
    <>
      <Formik
        initialValues={{
          workHistoryId: workExperience?.workHistoryId || '',
          currentWorking: workExperience?.currentWorking || '',
          jobRole: workExperience?.jobRole || '',
          companyName: workExperience?.companyName || '',
          jobDescription: workExperience?.jobDescription || '',
          jobJoinDate: workExperience?.jobJoinDate || '',
          jobEndDate: workExperience?.jobEndDate || '',
          jobCountry: workExperience?.jobCountry || '',
          jobCity: workExperience?.jobCity || '',
        }}
        enableReinitialize={true}
        validationSchema={CandidateWorkExperienceValidationSchema}
        onSubmit={(workExpData, { setSubmitting }) => {
          handleEditFormSubmit(workExpData)
          setSubmitting(false)
        }}
      >
        {({
          values,
          handleChange,
          isSubmitting,
          setFieldValue,
          isValid,
          dirty,
        }) => {
          setFieldValueRef.current = setFieldValue
          return (
            <div className="w-full flex flex-col gap-2">
              <div>
                <div>
                  {/* Role company name and job description */}
                  <div className="flex flex-row gap-10 my-4 edit-experience">
                    <div className="flex flex-col flex-grow">
                      <Label
                        htmlFor="jobRole"
                        className="text-gray-900 text-base font-medium leading-6 mb-2"
                      >
                        Role or Title
                      </Label>
                      <div>
                        <Field
                          as={TextInput}
                          id="jobRole"
                          type="text"
                          name="jobRole"
                          onChange={handleChange}
                          value={values.jobRole}
                          placeholder="Enter your job title"
                        />
                        <ErrorMessage
                          name="jobRole"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>

                    <div className="flex flex-col flex-grow">
                      <Label
                        htmlFor="companyName"
                        className="text-gray-900 text-base font-medium leading-6 mb-2"
                      >
                        Company
                      </Label>
                      <div>
                        <Field
                          as={TextInput}
                          id="companyName"
                          type="text"
                          name="companyName"
                          onChange={handleChange}
                          value={values.companyName}
                          placeholder="Enter the company name"
                        />
                        <ErrorMessage
                          name="companyName"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Job Description */}
                  <div className="flex flex-col flex-grow">
                    <Label
                      htmlFor="jobDescription"
                      className="text-gray-900 text-base font-medium leading-6 mb-2"
                    >
                      Description
                    </Label>
                    <div>
                      <Field
                        as={Textarea}
                        id="jobDescription"
                        type="text"
                        name="jobDescription"
                        onChange={handleChange}
                        value={values.jobDescription}
                        placeholder="Describe your role (max 2,000 chars)"
                        className="min-h-28 max-h-28 resize-none"
                        maxLength="2000"
                      />
                      <ErrorMessage
                        name="jobDescription"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>

                  {/* Current Working */}
                  <div className="w-full flex gap-2 my-5 justify-between edit-experience">
                    <div>
                      <Label
                        htmlFor="currentWorking"
                        className="text-gray-900 font-medium text-base leading-6"
                      >
                        Are you currently working here?
                      </Label>
                    </div>
                    <div className="space-x-5">
                      <Label className="text-gray-900 text-base font-medium leading-4">
                        <Field
                          className="mx-2"
                          type="radio"
                          name="currentWorking"
                          value="Yes"
                          onChange={event =>
                            handleCurrentWorkingChangeWithFormik(
                              event,
                              setFieldValue,
                            )
                          }
                          checked={values.currentWorking === 'Yes'}
                          disabled={isCurrentWorking}
                        />
                        Yes
                      </Label>
                      <Label className="text-gray-900 text-base font-medium leading-4">
                        <Field
                          className="mx-2"
                          type="radio"
                          name="currentWorking"
                          value="No"
                          onChange={event =>
                            handleCurrentWorkingChangeWithFormik(
                              event,
                              setFieldValue,
                            )
                          }
                          checked={values.currentWorking === 'No'}
                        />
                        No
                      </Label>
                    </div>
                  </div>
                  <ErrorMessage
                    name="currentWorking"
                    component="div"
                    className="text-red-500 flex justify-end"
                  />

                  {/* joinDate, endDate, country and city */}
                  <div className="flex flex-row gap-5 my-2 edit-experience">
                    <div className="flex flex-col flex-grow lg:w-48">
                      <Label
                        htmlFor="jobJoinDate"
                        className="text-gray-900 text-base font-medium leading-6 mb-2"
                      >
                        Join Date
                      </Label>
                      <div>
                        <ReactDatePickerComponent
                          id="jobJoinDate"
                          name="jobJoinDate"
                          value={values.jobJoinDate}
                          setFieldValue={setFieldValue}
                          placeholder="MM/YYYY"
                          dateFormat="MM/YYYY"
                          futureDisable={values.jobEndDate}
                          showClearButton={false}
                          showTodayButton={false}
                        />
                        <ErrorMessage
                          name="jobJoinDate"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>

                    <div className="flex flex-col flex-grow lg:w-48">
                      <Label
                        htmlFor="jobEndDate"
                        className={`text-base font-medium leading-6 mb-2 ${endDateDisable ? 'text-gray-400 pointer-events-none' : 'text-gray-900'}`}
                      >
                        End Date
                      </Label>
                      <div className="text-disabled">
                        <ReactDatePickerComponent
                          id="jobEndDate"
                          name="jobEndDate"
                          value={values.jobEndDate}
                          setFieldValue={setFieldValue}
                          placeholder="MM/YYYY"
                          dateFormat="MM/YYYY"
                          isDisabled={endDateDisable}
                          pastDisable={values.jobJoinDate}
                          showClearButton={false}
                          showTodayButton={false}
                        />
                        <ErrorMessage
                          name="jobEndDate"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>

                    <div className="flex flex-col flex-grow scrollable-dropdown text-nowrap lg:w-48">
                      <Label
                        htmlFor="jobCountry"
                        className="text-gray-900 text-base font-medium leading-6 mb-2"
                      >
                        Country
                      </Label>
                      <div>
                        <ScrollableDropdown
                          name="jobCountry"
                          options={Object.keys(COUNTRY_CITY).map(
                            countryName => ({
                              label: countryName,
                              value: countryName,
                            }),
                          )}
                          defaultLabel="Select Country"
                          setSelectedCountry={setSelectedCountry}
                        />
                        <ErrorMessage
                          name="jobCountry"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>

                    <div className="flex flex-col flex-grow text-nowrap lg:w-48">
                      <Label
                        htmlFor="jobCity"
                        className="text-gray-900 text-base font-medium leading-6 mb-2"
                      >
                        City
                      </Label>
                      <div>
                        <Field
                          as={Select}
                          id="jobCity"
                          name="jobCity"
                          onChange={handleChange}
                          value={values.jobCity}
                        >
                          <option value="" label="Select City" />
                          {cities.map(city => (
                            <option key={city} value={city}>
                              {city}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="jobCity"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full justify-center">
                <Button
                  className="px-3 text-[#1C64F2] text-center font-satoshi-variable cursor-pointer mt-2 mb-4 hover:bg-transparent border-none shadow-none outline-none focus:outline-none focus:ring-0"
                  onClick={e => {
                    e.preventDefault()
                    handleEditFormSubmit(values)
                  }}
                  disabled={
                    isSubmitting ||
                    !isValid ||
                    !dirty ||
                    isSubmitted ||
                    (!endDateDisable && !values.jobEndDate)
                  }
                >
                  {isModifyRequested ? 'Modify' : 'Add'}
                </Button>

                <Button
                  className="px-3 text-[#1C64F2] text-center font-satoshi-variable cursor-pointer mt-2 mb-4 hover:bg-transparent border-none shadow-none outline-none focus:outline-none focus:ring-0"
                  onClick={() => {
                    setIsModifyRequested(false)
                    setShowForm(false)
                    setIsCurrentWorking(prevWorkingStatus.current)
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          )
        }}
      </Formik>
    </>
  )
}
