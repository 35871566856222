import { companyHeaderMapping } from '../../config/constants'

export const generateColumns = (data, location,isMobile) => {
  if (data.length === 0) return []

  const firstItem = data[0]
  const filteredKeys = Object.keys(firstItem).filter(key => {
    return !['created_at', 'updated_at'].includes(key)
  })

  let currentExcludedColumns = [...excludedColumns]
  if (location?.pathname === '/test-list-of-candidiate' && isMobile)  {
    currentExcludedColumns = [
      ...currentExcludedColumns,
      'Test Description',
      'SENT date',
      'Status',
    ]
  }

  const columns = filteredKeys.map(key => ({
    header: companyHeaderMapping[key] || key,
    field: key,
    render: value => {
      if (typeof value === 'object' && value !== null) {
        return renderObject(value)
      } else if (key === 'website_link') {
        return (
          <a href={value} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        )
      } else {
        return value
      }
    },
  }))

  return columns.filter(column => !currentExcludedColumns.includes(column.field))
}

const renderObject = obj => (
  <div>
    {Object.keys(obj).map((key, index) => (
      <div key={index}>
        <strong>{key.toUpperCase()}</strong>: {obj[key]}
      </div>
    ))}
  </div>
)
export const changeTitle = (data, value) => {
  return data.map(item => {
    if (item.field === 'title') {
      return {
        ...item,
        header: value,
      }
    }
    return item
  })
}

export const excludedColumns = [
  'id',
  'Test id',
  'Test Link',
  'skill_id',
  'industry_id',
  'social_network_links',
  'website_link',
  'pipeline_id',
  'logo_url',
  'completion_status',
  'description',
  'city',
  'user_id',
  'test_id',
  'manage_test_user_email',
  'company_user_name',
  'test_send_date',
  'result_url',
  'candidate_id',
  'company_id',
  'industry_name',
  'company_admin_first_name',
  'company_admin_last_name',
  'remaining_credits',
]
