import React from 'react'
import { Modal } from 'flowbite-react'
import RegisterFields from '../CompanyReister/RegisterFields'

const CreateActionModal = ({
  isModalOpen,
  closeModal,
  title,
  getCompanies,
}) => {
  return (
    <Modal
      show={isModalOpen}
      onClose={() => closeModal(false)}
      theme={{
        content: {
          base: 'bg-transparent w-3/4',
          inner: 'bg-transparent',
        },
      }}
    >
      <RegisterFields
        isSuperAdmin={true}
        title={title}
        onClose={closeModal}
        getCompanies={getCompanies}
      />
    </Modal>
  )
}

export default CreateActionModal
