import React from 'react'
import { PersistentMinimizableInbox } from './PersistentMinimizableInbox'
import { PersistantProvider } from './PersistantModuleContext'

export const PersistentInbox = () => {
  return (
    <PersistantProvider>
      <PersistentMinimizableInbox />
    </PersistantProvider>
  )
}
