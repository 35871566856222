import axiosInstance from './axiosConfig'
import { CompaniesEndPoint } from './constants'

export const companyCompletionPercent = async id => {
  const response = await axiosInstance.get(
    CompaniesEndPoint.companyCompletionPercent(id),
  )
  return response
}

export const getCompaniesList = async () => {
  const response = await axiosInstance.get(
    CompaniesEndPoint.getCompanies,
  )
  return response
}

export const getPaginatedCompaniesList = async (page, query) => {
  const response = await axiosInstance.get(
    CompaniesEndPoint.getPaginatedCompanies(page, query),
  )
  return response
}

export const deleteCompany = async (id) => {
  const response = await axiosInstance.delete(
    CompaniesEndPoint.deleteCompany(id),
  )
  return response
}

export const editCompany = async (data) => {
  const response = await axiosInstance.patch(
    CompaniesEndPoint.editCompany(data?.id),
    { company: data }
  )
  return response
}

export const getEmployerDashboard = async (companyId) => {
  const response = await axiosInstance.get(
    CompaniesEndPoint.employerDashboard(companyId),
  )
  return response
}
