import React from 'react';
import WorkIcon from '../../assets/svgs/work.svg'
import { ReactComponent as BlueworkIcon } from '../../assets/svgs/work_blue.svg'
import 'assets/css/reveal-candidate-card.css'
import moment from 'moment';
import { Timeline } from 'flowbite-react';

const ExperienceTimeline = ({ workHistories = [], isPipelineView }) => (
  <div>
    <div className="flex my-1 -ml-2">
      <img src={WorkIcon} alt="Work Icon" className="my-auto" />
      <h3 className="text-md font-medium text-[#4B5563] px-1 py-2">
        Experience
      </h3>
    </div>
    <Timeline>
      {workHistories.length === 0 ? (
        <p className="text-center text-gray-500">No experience records available.</p>
      ) : (
        workHistories.map((experience, index) => (
          <React.Fragment key={index}>
            <Timeline.Item className="mb-4">
              <Timeline.Point icon={BlueworkIcon} className="custom-icon-background" />
              <Timeline.Content>
                <Timeline.Title>
                  <p className="font-bold text-base leading-6 md:whitespace-nowrap text-[#111928]">
                    {experience.job_title}{' '}
                    <span className="text-gray-500">at</span>{' '}
                    {experience.company_name}
                  </p>
                </Timeline.Title>
                <Timeline.Body>
                  <p className="text-sm font-medium text-gray-500">
                    {moment(experience.start_date).format('MMM YYYY')} -{' '}
                    {experience.end_date
                      ? moment(experience.end_date).format('MMM YYYY')
                      : 'Present'}
                    <span className="text-gray-400"> | </span>
                    <span className="text-gray-700">
                      {experience.city}, {experience.country}
                    </span>
                  </p>
                  <p className="font-medium text-[#6B7280] text-sm">
                    <ul className="list-disc pl-8">
                      <li>{experience.description}</li>
                    </ul>
                  </p>
                </Timeline.Body>
              </Timeline.Content>
            </Timeline.Item>
          </React.Fragment>
        ))
      )}
    </Timeline>
  </div>
);

export default ExperienceTimeline;
