import React from 'react'
import downloadIcon from 'assets/svgs/download-icon.svg'
import smallDotIcon from 'assets/svgs/grey-small-dot.svg'
import filePdfIcon from 'assets/svgs/pdf.svg'

export const AttachementText = ({
  fileName,
  totalPages,
  fileSize,
  fileType,
  attachmentUrl,
}) => {
  const handleDownload = () => {
    const fullUrl = attachmentUrl
    const link = document.createElement('a')
    link.href = fullUrl
    link.download = fileName
    link.target = '_blank' 
    link.rel = 'noopener noreferrer'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div className="flex flex-row p-2 gap-4 rounded-lg bg-[#F9FAFB] min-w-[239px] min-h-[47px] items-center">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-2">
          <img
            src={filePdfIcon}
            alt="File Icon"
            className="w-5 h-5 md:w-5 md:h-5"
          />
          <span className="text-sm font-medium">{fileName}</span>
        </div>

        <div className="flex flex-row gap-2 items-center">
          <span className="text-xs font-normal text-[#6B7280]">
            {totalPages} Pages
          </span>
          <img
            src={smallDotIcon}
            alt="Pdf icon"
            className="w-1 h-1 md:w-1 md:h-1"
          />
          <span className="text-xs font-normal text-[#6B7280]">{fileSize}</span>
          <img
            src={smallDotIcon}
            alt="Pdf icon"
            className="w-1 h-1 md:w-1 md:h-1"
          />
          <span className="text-xs font-normal text-[#6B7280]">{fileType}</span>
        </div>
      </div>
      <button onClick={handleDownload}>
        <img
          src={downloadIcon}
          alt="Download Icon"
          className="w-4 h-4 md:w-4 md:h-4 cursor-pointer"
        />
      </button>
    </div>
  )
}
