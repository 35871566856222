import React from 'react'
import { useSelector } from 'react-redux'
import timeImage from 'assets/svgs/time.svg'
import { ensureHttpsUrl, extractFeedInfo, formatFeedDate } from 'utilis/helpers'
import { selectCurrentUser } from '../../../redux/currentUserSlice'
import { InitialLetters } from 'components/generics/Initials'

const CandidateFeedItem = ({ feed, handleCompleteTest }) => {
  const { currentUser } = useSelector(selectCurrentUser)

  const {
    assessment_link: assessmentLink,
    notification_message: notificationMessage,
    created_at: createdAt,
    test_id: testId,
    company: company
  } = feed || {}

  const validUrl = ensureHttpsUrl(assessmentLink)
  const notification = extractFeedInfo(notificationMessage)

  const { companyName, message, date } = notification || {}
  const userName = `${currentUser?.first_name} ${currentUser?.last_name}`

  return (
    <div className="flex-1 bg-white rounded-lg shadow pl-4 pr-4 pt-4 relative">
      <div className="flex flex-col lg:flex-row items-center mb-2">
        {company?.logo_url ? (
          <img
            src={company?.logo_url}
            alt="User Logo"
            className="rounded-full mb-2 w-20 h-20 md:w-12 md:h-12"
          />
        ) : (
          <div className="flex justify-center items-center p-5 rounded-full text-2xl bg-gray-100 border-gray-100 h-20 w-20 md:h-12 md:w-12 mb-6">
            <InitialLetters Name={company?.name} />
          </div>
        )}

        <div className="ml-4 mb-2 flex-1">
          <p className="font-bold">
            {companyName && (
              <span className="text-[#111928]">
                {assessmentLink ? `${companyName} (company)` : companyName}{' '}
              </span>
            )}
            <span className="text-[#6B7280]">{message}</span>
            {date && <span className="text-[#111928]"> {date}</span>}
          </p>
          <div className="flex items-center">
            <img
              src={timeImage}
              alt="time"
              className="w-3 h-4 mr-2 rounded-full"
            />
            <p className="text-sm text-gray-500">{formatFeedDate(createdAt)}</p>
          </div>
        </div>

        {assessmentLink && (
          <button
            target="_blank"
            onClick={() => handleCompleteTest(validUrl, {id: testId, test_status: 'progress'})}
            className="text-14 bg-[#1A56DB] text-white font-bold py-2 px-4 lg:px-6 rounded-lg transition-all duration-300 ease-in-out hover:bg-[#004aad] mt-4 md:mt-0 ml-4"
          >
            Complete Test
          </button>
        )}
      </div>
    </div>
  )
}

export default CandidateFeedItem
