import React from 'react';

const BellActiveOutlineIcon = ({ fillColor = "#1A56DB", width = 13, height = 13 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 13 13"
    fill="none"
  >
    <path
      d="M10.5293 6.81935V5.73932C10.5291 4.85289 10.1931 3.99382 9.57844 3.30788C8.96374 2.62195 8.10817 2.15138 7.15689 1.97603V1.10001C7.15689 0.94088 7.08768 0.788265 6.96449 0.67574C6.8413 0.563216 6.67422 0.5 6.5 0.5C6.32578 0.5 6.1587 0.563216 6.03551 0.67574C5.91232 0.788265 5.84311 0.94088 5.84311 1.10001V1.97603C4.89183 2.15138 4.03626 2.62195 3.42156 3.30788C2.80686 3.99382 2.47089 4.85289 2.47066 5.73932V6.69095C2.47071 6.73332 2.47577 6.77557 2.48577 6.81695H2.47066C2.46097 7.31423 2.23808 7.7882 1.8499 8.13698C1.4973 8.4527 1.2823 8.87469 1.24491 9.32441C1.24491 9.65021 1.24491 10.6402 2.2552 10.6402H3.70561C3.896 11.1804 4.26912 11.6516 4.77127 11.9857C5.27341 12.3199 5.8787 12.5 6.5 12.5C7.1213 12.5 7.72659 12.3199 8.22874 11.9857C8.73088 11.6516 9.104 11.1804 9.29439 10.6402H10.7448C11.7551 10.6402 11.7551 9.65021 11.7551 9.32441C11.7175 8.8749 11.5026 8.45315 11.1501 8.13758C10.7624 7.78924 10.5395 7.31601 10.5293 6.81935ZM6.5 11.3003C6.23278 11.3 5.96974 11.2396 5.73409 11.1245C5.49845 11.0094 5.29743 10.8431 5.14878 10.6402H7.85122C7.70257 10.8431 7.50155 11.0094 7.26591 11.1245C7.03026 11.2396 6.76722 11.3 6.5 11.3003ZM10.4413 9.44021H2.55868C2.55868 9.40001 2.55868 9.35861 2.55868 9.32441C2.62851 9.16421 2.73359 9.0188 2.86742 8.8972C3.44922 8.33491 3.77759 7.59298 3.78706 6.81935V5.73932C3.78581 5.06823 4.0665 4.42229 4.57126 3.93465C5.07603 3.44702 5.76638 3.15488 6.5 3.11846C7.23408 3.15429 7.92509 3.44617 8.43041 3.93386C8.93573 4.42156 9.21678 5.06784 9.21557 5.73932V6.69095C9.21573 6.73328 9.22057 6.7755 9.23002 6.81695H9.21557C9.22504 7.59058 9.55341 8.33251 10.1352 8.8948C10.269 9.0164 10.3741 9.16181 10.4439 9.32201C10.4413 9.35861 10.4413 9.40001 10.4413 9.44021Z"
      fill={fillColor}
    />
    <path
      d="M1.81377 5.73932C1.81824 4.61877 2.28447 3.54083 3.11966 2.72005C3.23804 2.60301 3.30067 2.44781 3.29378 2.28858C3.28688 2.12936 3.21101 1.97916 3.08288 1.87103C2.95474 1.7629 2.78483 1.70569 2.61051 1.712C2.4362 1.7183 2.27176 1.78759 2.15338 1.90463C1.09374 2.94736 0.503364 4.31662 0.5 5.73932C0.5 5.89846 0.569207 6.05107 0.692398 6.1636C0.815588 6.27612 0.982669 6.33934 1.15689 6.33934C1.3311 6.33934 1.49819 6.27612 1.62138 6.1636C1.74457 6.05107 1.81377 5.89846 1.81377 5.73932Z"
      fill={fillColor}
    />
    <path
      d="M10.8466 1.90463C10.788 1.84668 10.7175 1.79984 10.639 1.76679C10.5606 1.73373 10.4758 1.71511 10.3895 1.71199C10.3032 1.70887 10.217 1.72131 10.136 1.7486C10.0549 1.77589 9.98057 1.81749 9.91712 1.87103C9.85368 1.92457 9.80239 1.98901 9.76621 2.06065C9.73002 2.13229 9.70964 2.20974 9.70622 2.28858C9.70281 2.36742 9.71643 2.4461 9.7463 2.52014C9.77618 2.59417 9.82172 2.6621 9.88034 2.72005C10.7163 3.54159 11.1826 4.62073 11.1862 5.74232C11.1862 5.90146 11.2554 6.05407 11.3786 6.1666C11.5018 6.27912 11.6689 6.34234 11.8431 6.34234C12.0173 6.34234 12.1844 6.27912 12.3076 6.1666C12.4308 6.05407 12.5 5.90146 12.5 5.74232C12.4996 4.31961 11.9093 2.95035 10.8497 1.90763L10.8466 1.90463Z"
      fill={fillColor}
    />
  </svg>
);

export default BellActiveOutlineIcon;
