import axiosInstance from './axiosConfig'
import { UsersEndPoint, AuthEndPoints } from './constants'

export const getUserValidity = async body => {
  return await axiosInstance.get(`${UsersEndPoint.validateUser}`, {
    params: { user: body },
  })
}

export const updateUser = async (id, body) => {
  return await axiosInstance.patch(`${UsersEndPoint.updateUser}/${id}`, body)
}

export const resetPassword = async body => {
  return await axiosInstance.patch(AuthEndPoints.resetPassword, body)
}

export const getUserIdByPhoneNumberOrEmail = async body => {
  return await axiosInstance.get(`${UsersEndPoint.getUserIdByPhone}`, {
    params: body,
  })
}

export const addExpertise = async (id, body) => {
  return await axiosInstance.post(UsersEndPoint.userExpertise(id), {
    user: body,
  })
}

export const getExpertise = async id => {
  return await axiosInstance.get(UsersEndPoint.getUserExpertise(id))
}

export const saveCandidateForSearch = async data => {
  return await axiosInstance.patch(UsersEndPoint.saveCandidateForSearch, {
    revelation: data,
  })
}

export const getRevealedCandidatesForCompany = async query => {
  return await axiosInstance.get(UsersEndPoint.getRevealedCandidates, {
    params: {
      q: query,
    },
  })
}

export const getProfileStatus = async (revelationId,companyID,searchHistoryId) => {
  try {
    const params = companyID ? { company_id: companyID, search_history_id: searchHistoryId } : { search_history_id: searchHistoryId};
    const response = await axiosInstance.get(`/api/v1/revelations/${revelationId}/get_profile_status`, { params });
    return response.data
  } catch (error) {
    console.error("Error fetching revelation status:", error);
    throw error
  }
}

export const setProfileStatus = async (data) => {
  try {
    let config = {};
    let payload;

    if (data instanceof FormData) {
      // If data is FormData (for file uploads)
      config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      payload = data;
    } else {
      // If data is a regular object
      config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      payload = { revelation: data };
    }

    const response = await axiosInstance.patch(UsersEndPoint.setProfileStatus, payload, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const removeOfferLetter = async data => {
  return await axiosInstance.patch(UsersEndPoint.deleteOfferLetter, data)
}

export const deleteUser = async id => {
  const response = await axiosInstance.delete(UsersEndPoint.deleteUser(id))
  return response
}

export const createUser = async body => {
  return await axiosInstance.post(`${UsersEndPoint.createUser}`, {
    user: body,
  })
}

export const getCurrentUser = async () => {
  return await axiosInstance.get(UsersEndPoint.getCurrentUser)
}

export const getContactedCandidates = async (page, query) => {
  const response = await axiosInstance.get(
    UsersEndPoint.getContactedCandidates(page, query),
  )
  return response
}

export const generateOtpForEmail = async email => {
  return await axiosInstance.post(UsersEndPoint.generateOtpForEmail, {
    email,
  })
}

export const verifyOtpForUser = async (email, otp) => {
  return await axiosInstance.post(UsersEndPoint.verifyOtpForUser, {
    email,
    otp,
  })
}

export const verifyPasswordForUser = async current_password => {
  return await axiosInstance.post(UsersEndPoint.verifyPasswordForUser, {
    current_password,
  })
}

export const downloadCandidatesCSV = async () => {
  return await axiosInstance.get(UsersEndPoint.downloadCandidatesCSV, {
    responseType: 'blob',
    headers: {
      Accept: 'text/csv',
    },
  })
}

export const getIntercomHashForUser = async id => {
  return await axiosInstance.get(UsersEndPoint.getIntercomHashForUser(id))
}

export const deleteCandidateAccount = async () => {
  return await axiosInstance.delete(UsersEndPoint.deleteCandidateAccount)
}

export const updateAccountInactiveStatus = async (id, status) => {
  return await axiosInstance.patch(
    UsersEndPoint.patchAccountInactiveStatus(id),
    {
      account_inactive: status,
    },
  )
}

export const getAccountDeletionRequestStatus = async id => {
  return await axiosInstance.get(
    UsersEndPoint.getAccountDeletionRequestStatus(id),
  )
}

export const updateIsSignUpCompleted = async (id, status) => {
  return await axiosInstance.patch(UsersEndPoint.patchIsSignUpCompleted(id), {
    is_signup_completed: status,
  })
}
