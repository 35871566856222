import React, { useState, useCallback } from 'react'
import CustomToggleSwitch from './CustomToggleSwitch'
import DropdownSearch from 'components/generics/dropdown/DropdownSearch'
import TextButton from 'components/generics/TextButton'
import MessageCaptionIcon from 'components/generics/Icons/MessageCaptionIcon'
import VerificationModal from '../../components/Search/modals/VerifyCertificateModal'

const AdditionalActions = ({
  moreTests,
  setMoreTests,
  tests,
  onSelectTest,
  moreCertificateVerification,
  setMoreCertificateVerification,
  onSelectCertificate,
  certifications,
  handleSubmit,
}) => {
  const [additionalTestDropdowns, setAdditionalTestDropdowns] = useState([])
  const [additionalDocumentDropdowns, setAdditionalDocumentDropdowns] =
    useState([])
  const [selectedTests, setSelectedTests] = useState([])
  const [selectedCertificates, setSelectedCertificates] = useState([])
  const [mainTestSelection, setMainTestSelection] = useState(null)
  const [mainCertificateSelection, setMainCertificateSelection] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleToggle = (
    setStateFunction,
    clearAdditionalDropdowns,
    clearMainDropdown,
  ) => {
    setStateFunction(prev => {
      if (prev) {
        clearAdditionalDropdowns([])
        clearMainDropdown([])
        if (setStateFunction === setMoreTests) {
          setSelectedTests([])
          setMainTestSelection(null)
        } else if (setStateFunction === setMoreCertificateVerification) {
          setSelectedCertificates([])
          setMainCertificateSelection(null)
        }
      }
      return !prev
    })
  }

  const handleAddMoreTests = () => {
    setAdditionalTestDropdowns([...additionalTestDropdowns, { id: Date.now() }])
  }

  const handleRemoveTest = id => {
    setAdditionalTestDropdowns(
      additionalTestDropdowns.filter(dropdown => dropdown.id !== id),
    )
  }

  const handleAddMoreDocuments = () => {
    if (additionalDocumentDropdowns.length < certifications.length - 1) {
      setAdditionalDocumentDropdowns([
        ...additionalDocumentDropdowns,
        { id: Date.now() },
      ])
    }
  }

  const handleRemoveDocument = id => {
    setAdditionalDocumentDropdowns(
      additionalDocumentDropdowns.filter(dropdown => dropdown.id !== id),
    )
  }

  const handleSendTest = async () => {
    if (
      (!mainTestSelection && selectedTests?.length === 0) ||
      (!mainTestSelection && !selectedTests)
    )
      return
    try {
      await handleSubmit()
      setAdditionalTestDropdowns([])
      setSelectedTests([])
      setMainTestSelection(null)
      setMoreTests(false)
      onSelectTest([])
    } catch (error) {
      console.error('Error sending tests:', error)
    }
  }

  const handleVerifyDocumentAndClear = async () => {
    setIsModalOpen(false)
    if (
      (!mainCertificateSelection && selectedCertificates?.length === 0) ||
      (!mainCertificateSelection && !selectedCertificates)
    )
      return
    try {
      await handleSubmit()
      setAdditionalDocumentDropdowns([])
      setSelectedCertificates([])
      setMainCertificateSelection(null)
      setMoreCertificateVerification(false)
      onSelectCertificate([])
    } catch (error) {
      console.error('Error verifying documents:', error)
    }
  }

  const handleTestSelection = useCallback(
    (selectedTest, index) => {
      if (index === 0) {
        setMainTestSelection(selectedTest)
        onSelectTest(prevTests => [
          selectedTest,
          ...(prevTests?.slice(1) || []),
        ])
      } else {
        setSelectedTests(prevTests => {
          const newTests = [...prevTests]
          const existingIndex = newTests.findIndex(
            test => test.id === selectedTest.id,
          )

          if (existingIndex !== -1) {
            newTests.splice(existingIndex, 1)
          } else {
            newTests[index - 1] = selectedTest
          }
          const filteredTests = newTests.filter(Boolean)
          onSelectTest([mainTestSelection, ...filteredTests].filter(Boolean))
          return filteredTests
        })
      }
    },
    [mainTestSelection, onSelectTest],
  )

  const handleCertificateSelection = useCallback(
    (selectedCertificate, index) => {
      if (index === 0) {
        setMainCertificateSelection(selectedCertificate)
        onSelectCertificate(prevCertificate => {
          const certificatesArray = Array.isArray(prevCertificate)
            ? prevCertificate
            : []
          return [selectedCertificate, ...certificatesArray.slice(1)]
        })
      } else {
        setSelectedCertificates(prevCertificates => {
          const newCertificates = [...prevCertificates]
          const existingIndex = newCertificates.findIndex(
            cert => cert.id === selectedCertificate.id,
          )

          if (existingIndex !== -1) {
            newCertificates.splice(existingIndex, 1)
          } else {
            newCertificates[index - 1] = selectedCertificate
          }

          const filteredCertificates = newCertificates.filter(Boolean)
          onSelectCertificate(
            [mainCertificateSelection, ...filteredCertificates].filter(Boolean),
          )
          return filteredCertificates
        })
      }
    },
    [mainCertificateSelection, onSelectCertificate],
  )

  const getAvailableTests = useCallback(
    currentIndex => {
      return tests.filter(
        test =>
          !selectedTests.some(
            (selectedTest, index) =>
              selectedTest.id === test.id && index !== currentIndex,
          ),
      )
    },
    [tests, selectedTests],
  )

  const getAvailableCertifications = useCallback(
    currentIndex => {
      return certifications.filter(
        cert =>
          !selectedCertificates.some(
            (selectedCert, index) =>
              selectedCert.id === cert.id && index !== currentIndex,
          ),
      )
    },
    [certifications, selectedCertificates],
  )

  return (
    <div>
      <div className="my-4 flex justify-between">
        <label className="text-gray-900 text-14 font-[500]">
          Send other test to candidate
        </label>
        <CustomToggleSwitch
          id="tests"
          checked={moreTests}
          onClick={() =>
            handleToggle(setMoreTests, setAdditionalTestDropdowns, onSelectTest)
          }
        />
      </div>
      {moreTests && (
        <div className="flex flex-col gap-2">
          <div className="flex justify-center items-center">
            <DropdownSearch
              title="test"
              options={getAvailableTests(0)}
              onSelectTest={selectedTest =>
                handleTestSelection(selectedTest, 0)
              }
              isPipelineView
              value={mainTestSelection}
            />
          </div>
          {additionalTestDropdowns.map((dropdown, index) => (
            <div key={dropdown.id} className="flex flex-col gap-2">
              <DropdownSearch
                title="test"
                options={getAvailableTests(index + 1)}
                onSelectTest={selectedTest =>
                  handleTestSelection(selectedTest, index + 1)
                }
                isPipelineView
                selectedOptions={selectedTests}
              />
              <TextButton
                text="Remove"
                className="hidden text-red-500 hover:text-red-700 text-12 font-bold leading-5 self-end"
                onClick={() => handleRemoveTest(dropdown.id)}
              />
            </div>
          ))}
          {additionalTestDropdowns.length < tests.length - 1 && (
            <TextButton
              text="Add more"
              className="text-[#1C64F2] align-center text-12 font-bold leading-5 self-end mb-4"
              onClick={handleAddMoreTests}
            />
          )}
          <button
            onClick={handleSendTest}
            className="flex-grow px-3 py-2 gap-2 w-full flex items-center justify-center text-blue-700 text-sm font-medium rounded-lg border border-blue-700 focus:outline-none focus:border-blue-700 md:h-10 h-10"
          >
            <MessageCaptionIcon />
            Ask candidate to complete this test
          </button>
        </div>
      )}
      <div className="my-4 flex justify-between">
        <label className="text-gray-900 text-14 font-[500]">
          Verify Candidate Document
        </label>
        <CustomToggleSwitch
          disabled={certifications?.length === 0}
          id="certifications"
          checked={moreCertificateVerification}
          onClick={() =>
            handleToggle(
              setMoreCertificateVerification,
              setAdditionalDocumentDropdowns,
              onSelectCertificate,
            )
          }
        />
      </div>
      {moreCertificateVerification && (
        <div className="flex flex-col gap-2">
          <div className="flex justify-center items-center">
            <DropdownSearch
              title="Select a candidate document to verify"
              options={getAvailableCertifications(0)}
              onSelectTest={selectedDoc =>
                handleCertificateSelection(selectedDoc, 0)
              }
              isPipelineView
              value={mainCertificateSelection}
            />
          </div>
          {additionalDocumentDropdowns.map((dropdown, index) => (
            <div key={dropdown.id} className="flex flex-col gap-2 mb-3">
              <DropdownSearch
                title="Select a candidate document to verify"
                options={getAvailableCertifications(index + 1)}
                onSelectTest={selectedDoc =>
                  handleCertificateSelection(selectedDoc, index + 1)
                }
                isPipelineView
                selectedOptions={selectedCertificates}
              />
              <TextButton
                text="Remove"
                className="hidden text-red-500 hover:text-red-700 text-12 font-bold leading-5 self-end"
                onClick={() => handleRemoveDocument(dropdown.id)}
              />
            </div>
          ))}
          {additionalDocumentDropdowns.length < certifications.length - 1 && (
            <TextButton
              text="Add more"
              className="text-[#1C64F2] align-center text-12 font-bold leading-5 self-end mb-4"
              onClick={handleAddMoreDocuments}
            />
          )}
          <button
            onClick={()=>setIsModalOpen(true)} 
            className="flex-grow px-3 gap-2 py-2 w-full flex items-center justify-center text-blue-700 text-sm font-medium rounded-lg border border-blue-700 focus:outline-none focus:border-blue-700 md:h-10 h-10"
          >
            <MessageCaptionIcon />
            Verify Document
          </button>

        </div>
      )}
      <VerificationModal
            isOpen={isModalOpen} 
            setIsModalOpen={setIsModalOpen}
            onClose={handleVerifyDocumentAndClear}

          />
    </div>
  )
}

export default AdditionalActions
