import { useEffect, useRef, useState } from 'react'
import { Button, Select, TextInput, FileInput } from 'flowbite-react'
import { Field, ErrorMessage, Formik } from 'formik'
import { CandidateCertificationsValidationSchema } from 'validations/CandidateProfileScehma'
import {
  findCertObjectByCertificateId,
  findExistingCertificateIndex,
  replaceExpAtIndex,
  appendCertificateData
} from './experience-helpers/experienceHelpers'
import 'assets/css/experience-form.css'
import {
  TruncatedText,
  isMobileDevice,
  getFileNameFromGoogleAPIsUrl,
} from 'utilis/helpers'
import { updateCertifications, createCertifications } from '../../../../../services/certificationService'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../../redux/currentUserSlice'
import moment from 'moment'
import { ReactDatePickerComponent } from 'components/generics/reactDatePicker/ReactDatePicker'
import 'assets/css/file-input.css'

export const EditCertificationComponent = ({
  certificate,
  isModifyRequested,
  setIsModifyRequested,
  addedOrModifiedCertificate,
  setAddedOrModifiedCertificate,
  previousCertificate,
  setPreviousCertificate,
  setShowForm,
}) => {
  const isMobile = isMobileDevice()
  const { currentUser } = useSelector(selectCurrentUser)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [fileLabel, setFileLabel] = useState(
    certificate?.file && typeof certificate.file === 'string'
      ? getFileNameFromGoogleAPIsUrl(certificate?.file)
      : 'No file chosen',
  )
  const [isFileChanged, setIsFileChanged] = useState(false)
  const setFieldValueRef = useRef(null)

  const prepareCertificationBody = (addedCertificate) => {
    const certificationBody = new FormData()
    certificationBody.append('certificate[0][id]', addedCertificate.certificateId)
    certificationBody.append('certificate[0][title]', addedCertificate.documentTitle)
    certificationBody.append('certificate[0][document_type]', addedCertificate.documentType)
    certificationBody.append('certificate[0][issued_by]', addedCertificate.issuedBy)
    certificationBody.append('certificate[0][issued_date]', addedCertificate.dateOfIssue)
    if (addedCertificate.file) {
      certificationBody.append('certificate[0][file]', addedCertificate.file)
    }
    else
    {
      console.error('addedCertificate is not a valid object:', addedCertificate)
    }
   
    return certificationBody;
  }
  const handleEditFormSubmit = async (certificateData) => {
    setIsSubmitted(true);
    try {
      await createCertifications(currentUser.id, prepareCertificationBody(certificateData))
    } catch (error) {
      console.error('Error:', error);
    }

    if (!isModifyRequested && certificateData.certificateId === '') {
      const updatedNewCertificate = [
        ...addedOrModifiedCertificate,
        certificateData,
      ]
      setAddedOrModifiedCertificate(updatedNewCertificate)
      setPreviousCertificate([...previousCertificate, certificateData])
      setShowForm(false)
    }
    if (isModifyRequested && certificateData.certificateId !== '') {
      const addedPrevModifiedIndex = findCertObjectByCertificateId(
        addedOrModifiedCertificate,
        certificateData.certificateId,
      )
      const prevCertArrIndex = findCertObjectByCertificateId(
        previousCertificate,
        certificateData.certificateId,
      )
      if (addedPrevModifiedIndex !== -1) {
        replaceExpAtIndex(
          addedOrModifiedCertificate,
          addedPrevModifiedIndex,
          certificateData,
        )
        setAddedOrModifiedCertificate(addedOrModifiedCertificate)
      } else {
        const updatedNewExperience = [
          ...addedOrModifiedCertificate,
          certificateData,
        ]
        setAddedOrModifiedCertificate(updatedNewExperience)
      }
      replaceExpAtIndex(previousCertificate, prevCertArrIndex, certificateData)
      setPreviousCertificate(previousCertificate)
      setIsModifyRequested(false)
    }

    if (isModifyRequested && certificateData.certificateId === '') {
      const index = findExistingCertificateIndex(
        addedOrModifiedCertificate,
        certificateData,
      )
      const prevCertArrIndex = findExistingCertificateIndex(
        previousCertificate,
        certificateData,
      )
      if (index !== -1) {
        replaceExpAtIndex(addedOrModifiedCertificate, index, certificateData)
        replaceExpAtIndex(
          previousCertificate,
          prevCertArrIndex,
          certificateData,
        )
        setAddedOrModifiedCertificate(addedOrModifiedCertificate)
        setPreviousCertificate(previousCertificate)
      }
      setIsModifyRequested(false)
    }
  }

  useEffect(() => {
    const loadFile = async () => {
      if (certificate?.file && isModifyRequested && setFieldValueRef.current) {
        if (typeof certificate.file !== 'string') {
          setFileLabel(certificate.file?.name)
          setFieldValueRef.current('file', certificate.file)
        }
        setIsFileChanged(false)
      }
    }

    loadFile()
  }, [certificate, isModifyRequested])

  return (
    <>
      <Formik
        initialValues={{
          certificateId: certificate?.certificateId || '',
          documentType: certificate?.documentType || '',
          documentTitle: certificate?.documentTitle || '',
          issuedBy: certificate?.issuedBy || '',
          dateOfIssue: certificate?.dateOfIssue || '',
          file: certificate?.file || '',
        }}
        enableReinitialize={true}
        validationSchema={CandidateCertificationsValidationSchema}
        onSubmit={(workExpData, { setSubmitting }) => {
          handleEditFormSubmit(workExpData)
          setSubmitting(false)
        }}
      >
        {({
          values,
          handleChange,
          isSubmitting,
          setFieldValue,
          isValid,
          dirty,
        }) => {
          setFieldValueRef.current = setFieldValue
          return (
            <div className="w-full flex flex-col gap-2 mt-5">
              <div className="flex flex-col gap-5">
                <div className="flex gap-2 edit-certification">
                  <div className="w-full">
                    <Select
                      id={'documentType'}
                      name={'documentType'}
                      onChange={handleChange}
                      value={values.documentType}
                    >
                      <option
                        className="text-gray-500"
                        value=""
                        disabled
                        hidden
                      >
                        Select Document Type
                      </option>
                      <option value="diploma">Diploma</option>
                      <option value="certificate">Certificate</option>
                      <option value="degree">Degree</option>
                    </Select>
                    <ErrorMessage
                      name={'documentType'}
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="w-full">
                    <div className="relative">
                      <FileInput
                        id="file"
                        name="file"
                        type="file"
                        className="opacity-0 absolute inset-0 z-50 w-full cursor-pointer h-full remove-pointer-event"
                        onChange={event => {
                          const file = event.currentTarget.files[0]
                          if (file) {
                            setFileLabel(file.name)
                            setFieldValue('file', file)
                            setIsFileChanged(true)
                          } else {
                            setFileLabel('No file chosen')
                            setIsFileChanged(false)
                          }
                        }}
                        accept=".pdf"
                      />
                      <div
                        className="border rounded-lg w-full cursor-pointer flex items-center bg-[#F9FAFB] border-[#D1D5DB] h-full"
                      >
                        <span className="bg-gray-900 text-white px-3 py-2 pt-3 rounded-s-lg text-sm">
                          Choose File
                        </span>
                        <span className="ml-5 text-sm">
                          <TruncatedText
                            text={fileLabel}
                            limit={isMobile ? 20 : 50}
                          />
                        </span>
                      </div>
                    </div>
                    <ErrorMessage
                      name={'file'}
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>

                <div className="flex justify-between gap-2 edit-certification">
                  <div className="flex flex-1 flex-col">
                    <div>
                      <h6 className="font-satoshi-variable mb-2">
                        Document Title
                      </h6>
                    </div>
                    <div>
                      <div className="w-full">
                        <Field
                          as={TextInput}
                          id={'documentTitle'}
                          name={'documentTitle'}
                          onChange={handleChange}
                          value={values.documentTitle}
                          placeholder="Enter document name (e.g. university diploma, skill certificate, etc.)"
                        />
                        <ErrorMessage
                          name={'documentTitle'}
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-1 flex-col">
                    <div>
                      <h6 className="font-satoshi-variable mb-2">Issued By</h6>
                    </div>
                    <div>
                      <div className="w-full">
                        <Field
                          as={TextInput}
                          id={'issuedBy'}
                          name={'issuedBy'}
                          onChange={handleChange}
                          value={values.issuedBy}
                          placeholder="Enter issuing organization name"
                        />
                        <ErrorMessage
                          name={'issuedBy'}
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <div>
                    <h6 className="font-satoshi-variable">Date of Issue</h6>
                  </div>
                  <div>
                    <ReactDatePickerComponent
                      id={'dateOfIssue'}
                      name={'dateOfIssue'}
                      value={values.dateOfIssue || ''}
                      setFieldValue={setFieldValue}
                      dateFormat={'DD/MM/YYYY'}
                      placeholder={'Select issuance date'}
                    />
                    <ErrorMessage
                      name={'dateOfIssue'}
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-full justify-center">
                <Button
                  className="px-3 text-[#1C64F2] text-center font-satoshi-variable cursor-pointer mt-2 mb-4 hover:bg-transparent border-none shadow-none outline-none focus:outline-none focus:ring-0"
                  onClick={e => {
                    e.preventDefault()
                    handleEditFormSubmit(values)
                  }}
                  disabled={
                    isSubmitting ||
                    !isValid ||
                    (!dirty && !isFileChanged) ||
                    isSubmitted
                  }
                >
                  {isModifyRequested ? 'Modify' : 'Add'}
                </Button>

                <Button
                  className="px-3 text-[#1C64F2] text-center font-satoshi-variable cursor-pointer mt-2 mb-4 hover:bg-transparent border-none shadow-none outline-none focus:outline-none focus:ring-0"
                  onClick={() => {
                    setIsModifyRequested(false)
                    setShowForm(false)
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          )
        }}
      </Formik>
    </>
  )
}
