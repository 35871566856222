import { Button, Card } from 'flowbite-react'
import DeleteIcon from 'assets/svgs/deleteIcon'
import modifyIcon from 'assets/svgs/modify_icon.svg'
import 'assets/css/experience-form.css'
import { isMediumMobileDevice } from 'utilis/helpers'

export const PreviousCertificationCard = ({
  userId,
  certificate,
  setIsModifyRequested,
  setModifyThisCertificate,
  setIsDeleted,
  setDeleteThisCertificate,
  setEditingIndex,
  editingIndex,
}) => {
  const formatDateToMonthYear = dateString => {
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = date.toLocaleString('en-US', { month: 'long' })
    const year = date.getFullYear()
    return `${day} ${month} ${year}`
  }
  const isMediumMobile = isMediumMobileDevice()

  return (
    <>
      <Card className="max-w-sm mb-2">
        <div className="flex flex-row gap-2">
          <div className="flex-grow">
            <h5
              className={`${isMediumMobile ? 'text-xl' : 'text-2xl'} font-bold tracking-tight text-gray-900 dark:text-white`}
            >
              {certificate.documentTitle}{' '}
              <span className="text-gray-500 mr-2">from</span>
              {certificate.issuedBy}
            </h5>
          </div>
          <div className=" flex flex-row work-exp-cert-container">
            <div>
              <Button
                className="p-1 mr-1 mb-2 text-black hover:bg-transparent border-none shadow-none outline-none focus:outline-none focus:ring-0"
                size={'xs'}
                onClick={() => {
                  setIsModifyRequested(true)
                  setModifyThisCertificate(certificate)
                  setEditingIndex(editingIndex)
                }}
              >
                <img src={modifyIcon} alt="eye" />
                <span className="ml-1 font-bold text-md">Modify</span>
              </Button>
            </div>
            <div>
              <Button
                className="p-1 mr-1 mb-2 text-black hover:bg-transparent border-none shadow-none outline-none focus:outline-none focus:ring-0"
                size={'xs'}
                onClick={async () => {
                  setIsDeleted(true)
                  setDeleteThisCertificate(certificate)
                }}
              >
                <DeleteIcon color="grey" />
                <span className="ml-1 font-bold text-md">Remove</span>
              </Button>
            </div>
          </div>
        </div>

        <p className="font-medium text-gray-700">
          Issued {formatDateToMonthYear(certificate?.dateOfIssue)}
        </p>

        <p className="font-normal text-gray-400">{certificate.documentType}</p>
      </Card>
    </>
  )
}
