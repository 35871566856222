import { Modal, ModalHeader } from 'flowbite-react'
import { useState } from 'react'
import React from 'react'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { selectCompany } from '../../../../src/redux/currentUserSlice'
import EmailSentModal from './ContactAlert'

export function WhatsappModal({
  openModal,
  setOpenModal,
  testName,
  testLink,
  handleWhatsapp,
  jobtitle,
  jobdetail,
  candidateName,
}) {
  const company = useSelector(selectCompany)
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const combinedHandleWhatsapp = () => {
    setSuccessModalOpen(true)
    handleWhatsapp()
  }

  return (
    <>
      <Modal
        show={openModal}
        size="2xl"
        onClick={() => setOpenModal(false)}
        onClose={() => setOpenModal(false)}
        popup
        className="flex justify-center items-center"
      >
        <ModalHeader>
          <div className="flex items-center justify-center mb-2">
            <FontAwesomeIcon
              className="lg:ml-56 sm:ml-56 ml-24 mt-8 text-[#25D366]"
              icon={faWhatsapp}
              size="lg"
            />
            <span className="ml-1 mt-8 text-md font-bold text-[#25D366]">
              WhatsApp
            </span>
          </div>
        </ModalHeader>
        <Modal.Body className="max-h-[500px] overflow-y-auto">
          <div className="px-6 max-w-8xl">
            <div className="text-center mb-2"></div>
            <h1 className="text-xl text-center tracking-wide mt-5 mb-8 font-medium">
              Send the following on WhatsApp
            </h1>
            <div className="content text-center mb-6">
              <button
                onClick={combinedHandleWhatsapp}
                className="continue-button bg-[#0d9488] hover:bg-green-700 text-white py-2 px-4 rounded-full mb-4"
              >
                Continue to Chat
              </button>

              {testLink ? (
                <p className="text-left mb-12">
                  Hey <strong>{capitalizeFirstLetter(candidateName)}</strong>,
                  <br />
                  You've been requested by{' '}
                  <strong>{company && company.name}</strong> on Quali for a new
                  job opportunity! They want to talk to you about a job offer as
                  a <strong>{jobtitle}</strong>.
                  <br />
                  <br />
                  <strong>Job Description:</strong>
                  <br />
                  {jobdetail}
                  <br />
                  <br />
                  Additionally, they have included a test for you to complete.
                  Please click here to take the test and proceed with the
                  application.
                  <br />
                  <br />
                  <p className="">
                    {testName} -
                    <a href={testLink} className="text-blue-500 underline">
                      {testLink}
                    </a>
                    <br />
                  </p>
                  <br />
                  Once you complete the test, the company will reach out to you.
                </p>
              ) : (
                <p className="text-left mb-12">
                  Hey <strong>{capitalizeFirstLetter(candidateName)}</strong>,
                  <br />
                  You've been requested by{' '}
                  <strong>{company && company.name}</strong> on Quali for a new
                  job opportunity! They want to talk to you about a job offer as
                  a <strong>{jobtitle}</strong>.
                  <br />
                  <br />
                  <strong>Job Description:</strong>
                  <br />
                  {jobdetail}
                  <br />
                  <br />
                  Reply to this message to speak directly with the company.
                </p>
              )}
            </div>
            <div className="footer text-center mt-6">
              <p className="mb-2 text-gray-600 font-satoshi-regular">
                Don't have WhatsApp yet?
              </p>
              <button className="download-button text-blue-500 underline">
                Download
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {successModalOpen && (
        <EmailSentModal
          successModalOpen={successModalOpen}
          setSuccessModalOpen={setSuccessModalOpen}
          message={
            testLink
              ? 'You just shared candidate a test via'
              : 'You just Contacted a candidate via'
          }
          ContactMode={'WhatsApp'}
        />
      )}
    </>
  )
}
