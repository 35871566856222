export const SessionExpiredResponseData = {
  message: 'Signature has expired',
  notLoggedIn: 'Not Logged in',
  notAuthorized: 'You are not authorized!',
  revokedToken: 'revoked token',
}

export const IndustriesEndpoints = {
  getIndustries: '/api/v1/industries',
  deleteIndustry: id => `/api/v1/industries/${id}`,
  editIndustry: id => `/api/v1/industries/${id}`,
  getPaginatedIndustries: (page, query) =>
    `/api/v1/industries?page=${page}&q=${query}`,
}

export const SkillsEndpoints = {
  getSkills: '/api/v1/searches/get_skills',
  allSkills: '/api/v1/skills',
  editSkill: id => `/api/v1/skills/${id}`,
  deleteSkill: id => `/api/v1/skills/${id}`,
  getPaginatedSkills: (page, query) => `/api/v1/skills?page=${page}&q=${query}`,
}

export const ExpertisesEndpoints = {
  getExpertises: '/api/v1/searches/get_expertises',
  allexpertises: '/api/v1/expertise',
  editExpertise: id => `/api/v1/expertise/${id}`,
  deleteExpertise: id => `/api/v1/expertise/${id}`,
  getPaginatedExpertise: (page, query) =>
    `/api/v1/expertise?page=${page}&q=${query}`,
}

export const CandidatesEndpoints = {
  searchCandidates: '/api/v1/searches/search_candidates',
  filterCandidates: '/api/v1/searches/filter_candidates',
  sendHiredEmailToCandidate: '/api/v1/send_hired_email',
  Candidatesoftests: `/api/v1/tests/test_candidates`,
}

export const CompanySearchHistory = {
  searchHistory: (currentPage, query) =>
    `/api/v1/search_histories?page=${currentPage}&q=${query}`,
  searchHistoryCount: `/api/v1/search_history_count`,
  verifyCertification: id => `/api/v1/searches/${id}/verify_certificate`,
}

export const UsersEndPoint = {
  validateUser: '/api/v1/users/check_user_validity',
  updateUser: '/api/v1/users',
  getUserIdByPhone: '/api/v1/users/find_user_by_phone',
  userExpertise: user_id => `/api/v1/users/${user_id}/user_skill_expertises`,
  getUserExpertise: user_id => `/api/v1/users/${user_id}/user_skill_expertises`,
  saveCandidateForSearch: `/api/v1//revelations/save_candidate `,
  getRevealedCandidates: '/api/v1/users/revealed_candidates',
  setProfileStatus: '/api/v1/revelations/set_profile_status',
  getProfileStatus: 'api/v1/revelations/get_profile_status',
  deleteOfferLetter: '/api/v1/revelations/remove_offer_letter',
  deleteUser: id => `api/v1/users/${id}`,
  createUser: 'api/v1/users',
  getCurrentUser: '/api/v1/users/whoami',
  getContactedCandidates: (page, query) =>
    `/api/v1/revelations/get_revealed_candidates?page=${page}&q=${query}`,
  generateOtpForEmail: '/api/v1/users/generate_otp',
  verifyOtpForUser: '/api/v1/users/verify_otp',
  verifyPasswordForUser: '/api/v1/users/verify_password',
  downloadCandidatesCSV: '/api/v1/users/export_csv',
  getIntercomHashForUser: user_id => `/api/v1/users/${user_id}/intercom_hash`,
  deleteCandidateAccount: '/api/v1/users/delete_account',
  patchAccountInactiveStatus: user_id =>
    `api/v1/users/${user_id}/update_account_inactive`,
  getAccountDeletionRequestStatus: user_id =>
    `/api/v1/users/${user_id}/account_deletion_status`,
  patchIsSignUpCompleted: user_id =>
    `api/v1/users/${user_id}/is_signup_completed`,
}

export const SendRevealMail = {
  sendRevealMail: '/api/v1/send_email',
}

export const SendSMS = {
  sendSMS: '/api/v1/send_sms',
}

export const RevealProfileEndPoint = {
  revealProfile: '/api/v1/revelations',
}

export const CompaniesEndPoint = {
  companyCompletionPercent: id => `/api/v1/companies/${id}/complete_status`,
  getCompanies: '/api/v1/companies',
  deleteCompany: id => `/api/v1/companies/${id}`,
  editCompany: id => `/api/v1/companies/${id}`,
  getPaginatedCompanies: page => `/api/v1/companies?page=${page}`,
  employerDashboard: companyId =>
    `/api/v1/company_dashboard?company_id=${companyId}`,
}

export const ManagestoredTests = {
  GetTestDetails: '/api/v1/candidate_company_tests/test_assessment_dashboard',
  GetPagyTestDetails: (currentPage, query, sortstate) =>
    `/api/v1/candidate_company_tests/test_assessment_dashboard?page=${currentPage}&q=${query}&per_page=${9}&sort_by=${sortstate}`,
}

export const CandidateStoredTests = {
  GetTestDetails: 'api/v1/candidate_company_tests/candidate_filtered_tests',
  GetPagyTestDetails: (currentPage, status, sortBy, orderBy) =>
    `api/v1/candidate_company_tests/candidate_filtered_tests?page=${currentPage}&status=${status}&per_page=${10}&sort_by=${sortBy}&order=${orderBy}`,
  sendReminderEmailToCandidate: '/api/v1/send_reminder_email',
}

export const AuthEndPoints = {
  createUser: '/signup',
  updateUser: '/api/v1/users',
  resetPassword: '/password',
}

export const SearchesEndPoint = {
  renameArchive: id => `/api/v1/searches/${id}`,
  removeSearch: id => `/api/v1/searches/${id}`,
}

export const CertificationsEndPoint = {
  createCertifications: userId => `/api/v1/users/${userId}/certifications`,
  fetchCertifications: userId => `/api/v1/users/${userId}/certifications`,
  deleteCertification: (userId, certificationId) =>
    `/api/v1/users/${userId}/certifications/${certificationId}`,
  verifyCertification: certificateID =>
    `/api/v1/certifications/${certificateID}/verify_certificate`,
}
export const TestsEndpoints = {
  allTests: (page, query) => `/api/v1/revelations?page=${page}&q=${query}`,
  updateTest: id => `/api/v1/revelations/${id}`,
  deleteTest: id => `/api/v1/tests/${id}`,
  sendTestToCandidate: '/api/v1/contact_candidate',
  sendTestsToCandidate: '/api/v1/send_tests_link',
  getAllTestListing: (page, query) => `/api/v1/tests?page=${page}&q=${query}`,
  updateTestListing: id => `/api/v1/tests/${id}`,
  createTestListing: '/api/v1/tests',
  testCompletionEmail: '/api/v1/send_completion_link',
  uploadTestResultFile: id =>
    `/api/v1/candidate_company_tests/${id}/upload_assessment_result`,
  uploadTestsCSVFile: '/api/v1/upload_csv_tests',
}

export const ManageTestEndpoints = {
  getSentTests: (page, query) =>
    `/api/v1/candidate_company_tests?page=${page}&q=${query}`,
  updateCandidateTest: id => `/api/v1/candidate_company_tests/${id}`,
}

export const WorkExperienceEndPoint = {
  createWorkHistories: userId => `/api/v1/users/${userId}/work_histories`,
  fetchWorkHistories: userId => `/api/v1/users/${userId}/work_histories`,
  updateWorkHistory: (userId, expId) =>
    `/api/v1/users/${userId}/work_histories/${expId}`,
  deleteWorkHistory: (userId, workHistoryId) =>
    `/api/v1/users/${userId}/work_histories/${workHistoryId}`,
}

export const UserPrefrencesEndPoint = {
  createUserPreferences: userId => `/api/v1/users/${userId}/job_preferences`,
  getUserPrefrences: userId => `/api/v1/users/${userId}/job_preferences`,
}

export const CandidateDashboardEndPoint = {
  getRecruitmentOverview: '/api/v1/candidate_dashboard/recruitment_overview',
  getQualiTest: '/api/v1/candidate_dashboard/candidate_tests',
  getOverview: '/api/v1/candidate_dashboard/profile_overview',
}

export const CandidateFeedEndpoint = {
  getCandidateFeeds: '/api/v1/feeds/get_candidate_feeds',
  clearFeedForUser: '/api/v1/feeds',
}

export const CompanyFeedEndpoint = {
  getCompanyFeeds: '/api/v1/feeds/get_company_feeds',
}

export const CandidateFeedbackEndpoint = {
  createCandidateFeedback: '/api/v1/candidate_feedbacks',
}

export const AccountDeletionRequestEndpoint = {
  createAccountDeletionRequest: '/api/v1/account_deletion_requests',
  getPaginatedDeletionRequests: (page, query) =>
    `/api/v1/account_deletion_requests?page=${page}&q=${query}`,
  deleteAccountDeletionRequest: id => `/api/v1/account_deletion_requests/${id}`,
}

export const ChatEndpoint = {
  createConversation: '/api/v1/conversations',
  getPreviousConversation: user_id =>
    `api/v1/conversation_with_user?user_id=${user_id}`,
  getUnreadMessages: '/api/v1/conversations/unread_count',
  deleteConversation: user_id =>
    `/api/v1/delete_conversation_with_user?user_id=${user_id}`,
}
