import axiosInstance from './axiosConfig'
import { ManageTestEndpoints } from './constants'

export const getSentTests = async (currentPage, query) => {
  const response = await axiosInstance.get(ManageTestEndpoints.getSentTests(currentPage, query))
  return response
}

export const editCandidateTest = async data => {
  const response = await axiosInstance.patch(
    ManageTestEndpoints.updateCandidateTest(data?.id),
    { test: data },
  )
  return response
}
