import React, { useState, useEffect, useRef, useCallback } from 'react'
import crossIcon from '../../../assets/svgs/cross.svg'
import searchIcon from '../../../assets/svgs/search_outline.svg'
import { useLocation } from 'react-router-dom'
import angleDownSvg from '../../../assets/svgs/angle-down-outline.svg'
import { certificateStaticOption } from 'config/constants'
const DropdownSearch = ({
  title,
  options,
  onSelectTest,
  isSuperAdmin,
  handleChange,
  value,
  isPipelineView,
}) => {
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedOption, setSelectedOption] = useState(value || '')
  const dropdownRef = useRef(null)
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }
  const handleClickOutside = useCallback(event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }, [])
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])
  const filteredOptions = options.filter(option =>
    option?.title?.toLowerCase().includes(searchTerm?.toLowerCase()),
  )
  useEffect(() => {
    if (
      title?.toLowerCase() === 'city' &&
      !options.some(option => option.title === selectedOption)
    ) {
      setSelectedOption('')
    }
  }, [options, title, selectedOption])

  const handleSelectOption = useCallback(
    option => {
      if (onSelectTest) {
        onSelectTest(option)
      }
      if (handleChange) {
        const event = {
          target: {
            name: title?.toLowerCase(),
            value: option?.title,
          },
        }
        handleChange(event)
      }
      setSelectedOption(option?.title)
      setIsOpen(false)
      setSearchTerm('')
    },
    [onSelectTest, handleChange, title],
  )

  const handleClearSearchQuery = () => setSearchTerm('')

  const optionsToDisplay =
    title === 'Select a candidate document to verify' &&
    filteredOptions.length > 0 && !isPipelineView
      ? [...filteredOptions, certificateStaticOption]
      : filteredOptions

  return (
    <div className="relative w-full">
      <label
        htmlFor="input-group-search"
        className={
          isSuperAdmin
            ? 'block text-sm font-medium text-gray-900 dark:text-white'
            : ` ${title !== 'Industry' ? 'ml-1' : ''} text-sm font-medium`
        }
      >
        {title !== 'Country' &&
          title !== 'City' &&
          title !== 'Select a candidate document to verify' &&
          'Select'}{' '}
        <span className="text-gray-900 text-14 font-[500]">{title}</span>
      </label>
      {(title === 'test' ||
        title === 'Select a candidate document to verify' ||
        title === 'Country' ||
        title === 'City') && (
        <div ref={dropdownRef}>
          <div
            className={`dark:bg-gray-700 ${title === 'Country' || title === 'City' ? 'bg-gray-50 px-3' : 'bg-white px-5'} ${selectedOption ? 'text-gray-900' : 'text-gray-500'} focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500 mt-1 text-sm font-medium w-full text-black focus:outline-none rounded-lg py-2.5 text-center flex items-center justify-between border border-gray-300`}
            onClick={toggleDropdown}
          >
            {selectedOption ||
              (title === 'test'
                ? 'Select Quali Test'
                : title === 'Select a candidate document to verify'
                  ? 'Select document'
                  : title === 'Country'
                    ? 'Select Country'
                    : 'Select City')}
            <img
              src={angleDownSvg}
              alt="cross"
              className="filter grayscale brightness-100 "
            />
          </div>
        </div>
      )}
      {title === 'Industry'&& (
        <div
          className={`mt-2 text-sm font-medium w-full text-black bg-gray-50  focus:outline-none rounded-lg ${selectedOption ? 'text-gray-900' : 'text-gray-500'} px-5 py-2.5 text-center flex items-center justify-between border border-gray-300`}
          onClick={toggleDropdown}
        >
          {selectedOption || 'Select Industry'}
          <img src={angleDownSvg} alt="cross" />
        </div>
      )}
      {isOpen && (
        <div
          ref={dropdownRef}
          className="mt-2 w-full z-10 absolute top-full left-0 bg-white rounded-lg shadow dark:bg-gray-700"
        >
          <div>
            <label htmlFor="input-group-search" className="sr-only ">
              Search
            </label>
            {location.pathname !== '/manage-profile' &&
              title !== 'Industry' && title !== 'Select a candidate document to verify' && (
                <div className="relative">
                  <input
                    type="text"
                    id="input-group-search"
                    className="px-5 text-sm font-medium block w-full p-2 pr-10 text-sm placeholder-gray-400 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search"
                    value={searchTerm}
                    onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                    {searchTerm ? (
                      <button
                        onClick={handleClearSearchQuery}
                        className="cursor-pointer p-1"
                      >
                        <img
                          src={crossIcon}
                          alt="cross"
                          className="filter grayscale brightness-200 "
                        />
                      </button>
                    ) : (
                      <img
                        src={searchIcon}
                        alt="Search"
                        className="w-3 h-3 filter grayscale brightness-200 "
                      />
                    )}
                  </div>
                </div>
              )}
          </div>
          <div className="absolute mt-1 w-full z-10 overflow-y-auto left-0 bg-white rounded-lg shadow-lg">
            <ul
              className={`py-1 px-3 overflow-y-auto z-10 ${title === 'Industry' ? 'max-h-56' :title === 'certification'?"max-h-20" : 'max-h-28'} text-sm text-gray-700 dark:text-gray-200`}
              aria-labelledby="dropdownSearchButton"
            >
              {optionsToDisplay.map((option, index) => {
                const isStaticOption = option.title === certificateStaticOption.title
                return (
                  <li key={index}>
                    <div
                      className='flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600'
                      onClick={() => handleSelectOption(option)}
                    >
                      <label
                        htmlFor={`checkbox-item-${index}`}
                        className={`w-full py-2 px-2 text-sm font-semibold rounded ${isStaticOption ? 'text-[#3F83F8]' : 'dark:text-gray-300 text-gray-700'}`}
                      >
                        {option?.title}
                      </label>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

export default DropdownSearch
